import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcWebhookLogDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty({
        description: 'name of the webhook',
    })
    webhook: string;

    @ApiModelProperty({
        description: 'payload sent from the webhook',
        required: false,
    })
    payload?: Record<string, any>;

    @ApiModelProperty({
        description: 'NC request date',
        example: new Date().toISOString(),
    })
    requestDate: string;
}
