<nz-footer class="footer">
    <div class="container">
        <app-footer-menu></app-footer-menu>

        <div
            nzXs="12"
            class="footer__bottom">
            <div class="footer__links">
                <div class="footer__link">&copy; {{ today | date : 'YYYY' }} Scalio. All rights reserved.</div>

                <div class="footer__link"><a [routerLink]="['/pages/terms-of-service']">Terms Of Service</a></div>

                <div class="footer__link"><a [routerLink]="['/pages/privacy-policy']">Privacy Policy</a></div>

                <div class="footer__link"><a [routerLink]="['/faq']">FAQ</a></div>

                <div class="footer__link">
                    <a [routerLink]="['', { outlets: { modal: ['contact-us'] } }]">Contact Us</a>
                </div>
            </div>

            <div class="footer__icons">
                <div class="footer__icon footer__icon-twitter">
                    <a
                        href="https://twitter.com/scaliolabs"
                        target="_blank">
                        <i class="icon icon-social-twitter"></i>
                    </a>
                </div>

                <div class="footer__icon">
                    <a
                        href="https://www.linkedin.com/company/scalio"
                        target="_blank">
                        <i class="icon icon-social-linked-in"></i>
                    </a>
                </div>

                <div class="footer__icon">
                    <a
                        href="https://www.facebook.com/ScalioLabs"
                        target="_blank">
                        <i class="icon icon-social-fb"></i>
                    </a>
                </div>

                <div class="footer__icon">
                    <a
                        href="https://www.instagram.com/scal.io"
                        target="_blank">
                        <i class="icon icon-social-instagram"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</nz-footer>
