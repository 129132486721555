import { Observable } from 'rxjs';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaDwollaPaymentCmsDto } from '../dto/baza-dwolla-payment-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { ulid } from 'ulid';

export enum BazaDwollaPaymentCmsEndpointPaths {
    list = '/baza/dwolla-payment/cms/list',
    getByUlid = '/baza/dwolla-payment/cms/getByUlid',
    sync = '/baza/dwolla-payment/cms/sync',
    fetchAchDetails = '/baza/dwolla-payment/cms/fetchAchDetails',
}

export interface BazaDwollaPaymentCmsEndpoint {
    list(
        request: BazaDwollaPaymentCmsListRequest,
    ): Promise<BazaDwollaPaymentCmsListResponse> | Observable<BazaDwollaPaymentCmsListResponse>;
    getByUlid(request: BazaDwollaPaymentCmsGetByUlidRequest): Promise<BazaDwollaPaymentCmsDto> | Observable<BazaDwollaPaymentCmsDto>;
    sync(request: BazaDwollaPaymentCmsSyncRequest): Promise<BazaDwollaPaymentCmsDto> | Observable<BazaDwollaPaymentCmsDto>;
    fetchAchDetails(
        request: BazaDwollaPaymentCmsFetchAchDetailsRequest,
    ): Promise<BazaDwollaPaymentCmsFetchAchDetailsResponse> | Observable<BazaDwollaPaymentCmsFetchAchDetailsResponse>;
}

export class BazaDwollaPaymentCmsListRequest extends CrudListRequestDto<BazaDwollaPaymentCmsDto> {
    @ApiModelProperty({
        description: 'Investor Account Id',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    investorAccountId?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    ncOfferingId?: string;
}

export class BazaDwollaPaymentCmsListResponse extends CrudListResponseDto<BazaDwollaPaymentCmsDto> {
    @ApiModelProperty({
        type: BazaDwollaPaymentCmsDto,
        isArray: true,
    })
    items: Array<BazaDwollaPaymentCmsDto>;
}

export class BazaDwollaPaymentCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaDwollaPaymentCmsSyncRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaDwollaPaymentCmsFetchAchDetailsRequest {
    @ApiModelProperty({
        description: 'Dwolla Transfer Id. The method will search for payments with given Dwolla Transfer Id',
        example: 'c6a9f41b-0b7b-ed11-814a-d69963ad6ab7',
    })
    @IsString()
    @IsNotEmpty()
    dwollaTransferId: string;
}

export class BazaDwollaPaymentCmsFetchAchDetailsResponse {
    @ApiModelProperty({
        description: 'Dwolla Transfer Id. The endpoint will search for payments with given Dwolla Transfer Id',
        example: 'c6a9f41b-0b7b-ed11-814a-d69963ad6ab7',
    })
    achDwollaTransferId: string;

    @ApiModelProperty({
        description: 'Individual ACH ID assigned by Dwolla',
        example: '9b1675e3-4472-ed',
        required: false,
    })
    individualAchId?: string;
}
