import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

/**
 * Touch operation result
 */
export enum BazaNcDwollaTouchResult {
    /**
     * Investor did not have Dwolla Customer created before; Dwolla Customer created
     */
    Created = 'Created',

    /**
     * Investor already have Dwolla Customer created before
     */
    Exists = 'Exists',

    /**
     * Failed to create Dwolla Customer for some reason; `failureReason` will have details
     */
    Failed = 'Failed',

    /**
     * Failed to create Dwolla Customer because Investor does not have enough data to create a Dwolla account
     */
    NonUsResident = 'NonUsResident',

    /**
     * Failed to create Dwolla Customer because Investor is not verified yet by NC KYC/AML
     */
    NotVerified = 'NotVerified',
}

export class BazaNcDwollaTouchResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDwollaTouchResult),
        description: 'Result of touch dwolla customer operation',
    })
    result: BazaNcDwollaTouchResult;

    @ApiModelProperty({
        description: 'Is Dwolla Customer (and related Dwolla tools) available to use?',
    })
    isDwollaCustomerAvailable: boolean;

    @ApiModelProperty({
        description: 'Is Dwolla Customer in verified status?',
    })
    isDwollaAvailable: boolean;

    @ApiModelProperty({
        required: false,
        description: 'Reason why operation is failed (optional, for Failed only)',
    })
    failedReason?: string;
}
