import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { TradeId } from '../models/trade-id';
import { NorthCapitalAccountId } from '../models/account-id';
import { OrderStatus } from '../models/order-status';
import { CloseId } from '../models/close-id';

export class UpdateTradeStatusRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsEnum(OrderStatus)
    @IsNotEmpty()
    orderStatus: OrderStatus;

    @IsString()
    @IsOptional()
    errDesc?: string;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;

    @IsString()
    @IsOptional()
    closeId?: CloseId;
}
