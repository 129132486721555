import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { Account } from '../../models';
import { AccountState } from '../account';
import { BootstrapState } from '../bootstrap';

@State({
    name: 'DividentAlert',
})
@Injectable()
export class DividendAlertState {
    @Selector([AccountState.account, BootstrapState.initData])
    static show(_: StateContext<unknown>, account: Account, initData: BazaNcBootstrapDto): boolean {
        return (
            (account &&
                !initData?.investorAccount?.status?.isForeign &&
                !initData.investorAccount?.isBankAccountCashOutLinked &&
                initData.isAnyAssetPurchased) ??
            false
        );
    }
}
