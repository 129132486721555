import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { BazaNcOperationType } from '../models/baza-nc-operation-type';
import { TransactionState } from '../../../transaction';
import { ulid } from 'ulid';
import { BazaNcDividendPaymentStatus } from '../../../dividend/src';
import { BazaDwollaPaymentStatus } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcPayoffDistributionPaymentStatus } from '../../../payoffDistribution/src';

/**
 * Payload for Baza NC Operation DTO (Investment)
 * @see BazaNcOperationPayloadDto
 */
export class BazaNcOperationPayloadInvestmentDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcOperationType.Investment, BazaNcOperationType.RewardShare],
        example: BazaNcOperationType.Investment,
        description: 'Operation Type',
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: `(${BazaNcOperationType.Investment}) ID of Transaction for Reprocess request`,
        example: 1,
    })
    id: number;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        description: 'Offering ID',
    })
    offeringId: string;

    @ApiModelProperty({
        description: 'Offering Title',
    })
    offeringTitle: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Transaction State',
        enum: Object.values(TransactionState),
        example: TransactionState.PaymentFunded,
    })
    state: TransactionState;

    @ApiModelProperty({
        description: 'Fee (in U.S. cents)',
    })
    feeCents: number;

    @ApiModelProperty({
        description: 'Total (in U.S. cents)',
    })
    totalCents: number;

    @ApiModelProperty({
        description: 'Number of shared purchases',
    })
    shares: number;

    @ApiModelProperty({
        description: 'Price per shared (in U.S. cents)',
    })
    pricePerShareCents: number;

    @ApiModelProperty({
        description: 'Transaction fee (in U.S. cents)',
    })
    transactionFeesCents: number;
}

/**
 * Payload for Baza NC Operation DTO (Dividend)
 * @see BazaNcOperationPayloadDto
 */
export class BazaNcOperationPayloadDividendDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcOperationType.Dividend],
        example: BazaNcOperationType.Dividend,
        description: 'Operation Type',
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: `(${BazaNcOperationType.Dividend}) ULID of Dividend for Reprocess request`,
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
        description: 'Dividend Status',
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        description: 'Offering ID',
        required: false,
    })
    offeringId?: string;

    @ApiModelProperty({
        description: 'Offering Title',
    })
    offeringTitle: string;
}

/**
 * Payload for Baza NC Operation DTO (Payoff Distribution)
 * @see BazaNcOperationPayloadDto
 */
export class BazaNcOperationPayloadPayoffDistributionDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcOperationType.PayoffDistribution],
        example: BazaNcOperationType.PayoffDistribution,
        description: 'Operation Type',
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: `(${BazaNcOperationType.PayoffDistribution}) ULID of Payoff Distribution for Reprocess request`,
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        description: 'Payoff Distribution Status',
    })
    state: BazaNcPayoffDistributionPaymentStatus;
}

/**
 * Payload for Baza NC Operation DTO (Withdraw)
 * @see BazaNcOperationPayloadDto
 */
export class BazaNcOperationPayloadWithdrawDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcOperationType.Withdraw],
        example: BazaNcOperationType.Withdraw,
        description: 'Operation Type',
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: `(${BazaNcOperationType.Withdraw}) ID of Withdraw for Reprocess request`,
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;
}

/**
 * Payload for Baza NC Operation DTO (Transfer)
 * @see BazaNcOperationPayloadDto
 */
export class BazaNcOperationPayloadTransferDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcOperationType.Transfer],
        example: BazaNcOperationType.Transfer,
        description: 'Operation Type',
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: `(${BazaNcOperationType.Transfer}) ID of Transfer for Reprocess request`,
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;
}

/**
 * Payload (All Types) for Baza NC Operation DTO
 */
export type BazaNcOperationPayloadDto =
    | BazaNcOperationPayloadInvestmentDto
    | BazaNcOperationPayloadDividendDto
    | BazaNcOperationPayloadPayoffDistributionDto
    | BazaNcOperationPayloadWithdrawDto
    | BazaNcOperationPayloadTransferDto;

/**
 * Exported models for Swagger (@ApiExtraModels)
 */
export const bazaNcOperationPayloadSwaggerExports = [
    BazaNcOperationPayloadInvestmentDto,
    BazaNcOperationPayloadDividendDto,
    BazaNcOperationPayloadPayoffDistributionDto,
    BazaNcOperationPayloadWithdrawDto,
    BazaNcOperationPayloadTransferDto,
];

/**
 * Definitions helper for OpenAPI documentation of BazaNcOperationPayloadDto type
 */
export const bazaNcOperationPayloadSwaggerDefinition: () => SchemaObjectMetadata = () => ({
    anyOf: bazaNcOperationPayloadSwaggerExports.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
