import { AfterViewInit, Directive, Input } from '@angular/core';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';

/**
 * The appAmountMask directive is used to add a mask to input fields that accept numeric values.
 * It can be used in conjunction with the nzInputNumber component from ng-zorro-antd.
 *
 * @example
 * <input nz-input-number appAmountMask [maxLength]="10">
 *
 * @example
 * <nz-input-number appAmountMask [maxLength]="10"></nz-input-number>
 */
@Directive({
    selector: '[appAmountMask]',
})
export class AmountMaskDirective implements AfterViewInit {
    /**
     * The maximum number of characters that the input field can accept.
     */
    @Input() maxLength!: number;

    regex = RegExp(/[0-9.]\d*$/);
    textBox: HTMLInputElement;

    constructor(private readonly element: NzInputNumberComponent) {}

    ngAfterViewInit() {
        this.textBox = this.element?.inputElement?.nativeElement;
        if (this.textBox) {
            if (this.maxLength) {
                this.textBox.maxLength = this.maxLength;
            }
            this.textBox.addEventListener('keypress', this.onKeyPress.bind(this));
        }
    }

    /**
     * Handles the key press event on the input element. It prevents certain characters from being entered
     * and limits the number of decimal points that can be entered in the input field.
     *
     * @param $event - the key press event object
     * @returns boolean - true if the key press event is handled successfully, false otherwise
     */
    onKeyPress($event) {
        const valueToTest = (this.textBox.value ?? '') + String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);

        const regexTestResult = this.regex.test(valueToTest);
        const decimalOccurences = (valueToTest.match(/[.]/g) || []).length;

        if (!regexTestResult || decimalOccurences > 1) {
            $event.preventDefault();
            return false;
        }
        return true;
    }
}
