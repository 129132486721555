import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { NewsDto } from '../dto/news.dto';
import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';

export enum NewsEndpointPaths {
    list = '/baza-content-types/news/list',
    getById = '/baza-content-types/news/id/:id',
    getByUrl = '/baza-content-types/news/url/:url',
}

export interface NewsEndpoint {
    list(request: NewsListRequest): Promise<NewsListResponse> | Observable<NewsListResponse>;
    getById(id: number): Promise<NewsDto> | Observable<NewsDto>;
    getByUrl(url: string): Promise<NewsDto> | Observable<NewsDto>;
}

export class NewsListRequest extends CrudListRequestQueryDto {}

export class NewsListResponse extends CrudListResponseDto<NewsDto> {
    @ApiModelProperty({
        type: NewsDto,
        isArray: true,
    })
    items: Array<NewsDto>;
}
