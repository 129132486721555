import { Injectable } from '@angular/core';
import { BazaI18nDetector } from '../baza-i18n-detector';
import { Observable, of } from 'rxjs';
import { BazaI18nNgConfig } from '../baza-i18n-ng.config';
import { ProjectLanguage } from '@scaliolabs/baza-core-shared';
import { BazaLocalStorageService } from '../../../baza-common/lib/services/baza-local-storage.service';

@Injectable()
export class LocalStorageCurrentLanguageDetector implements BazaI18nDetector {
    constructor(private readonly moduleConfig: BazaI18nNgConfig, private readonly localStorage: BazaLocalStorageService) {}

    detect(): Observable<ProjectLanguage | undefined> {
        return of(this.localStorage.get(this.moduleConfig.localStorageKey) as ProjectLanguage);
    }
}
