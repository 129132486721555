import { Observable, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { BazaNgApiOnlineStatusService } from '../services/baza-ng-api-online-status.service';
import { isBazaErrorResponse } from '@scaliolabs/baza-core-shared';

export const GENERIC_RETRY_STRATEGIES_INJECT: {
    apiOnlineStatusService: BazaNgApiOnlineStatusService,
} = {
    apiOnlineStatusService: undefined,
};

export const DEFAULT_RETRY_REQUEST_DURATION = 1000;
export const REPORT_OFFLINE_AFTER_ATTEMPTS_COUNT = 5;

export const genericRetryStrategy = ({
    retryDuration = DEFAULT_RETRY_REQUEST_DURATION,
    excludedStatusCodes = [],
}: {
    retryDuration?: number,
    excludedStatusCodes?: Array<number>,
} = {
    retryDuration: DEFAULT_RETRY_REQUEST_DURATION,
}) => (attempts: Observable<any>) => {
    let countAttempts = 0;

    return attempts.pipe(
        mergeMap((error) => {
            if (isBazaErrorResponse(error)) {
                return throwError(error);
            }

            if (! (!! error && 'status' in error && error.status > 0)) {
                countAttempts++;
            }

            if (countAttempts === REPORT_OFFLINE_AFTER_ATTEMPTS_COUNT) {
                if (GENERIC_RETRY_STRATEGIES_INJECT.apiOnlineStatusService) {
                    GENERIC_RETRY_STRATEGIES_INJECT.apiOnlineStatusService.markApiAsOffline();
                }
            }

            if (!! error && error.status) {
                if (! excludedStatusCodes.find(e => e === error.status)) {
                    return throwError(error);
                }
            }

            return timer(retryDuration);
        }),
        finalize(() => {
            if (GENERIC_RETRY_STRATEGIES_INJECT.apiOnlineStatusService) {
                GENERIC_RETRY_STRATEGIES_INJECT.apiOnlineStatusService.markApiAsOnline();
            }
        }),
    );
};
