import { BazaPasswordValidator } from '../models/baza-password-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaPasswordValidatorDto {
    @ApiModelProperty({
        description: 'Failed validators',
        type: 'string',
        enum: Object.values(BazaPasswordValidator),
    })
    type: BazaPasswordValidator;

    @ApiModelProperty({
        description: 'Label for validator',
    })
    label: string;
}
