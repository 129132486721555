import { TradeId } from '../models/trade-id';
import { ArchiveStatus } from '../models/archive-status';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

export class UpdateTradeDocArchiveStatusRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    documentFileReferenceCode: string;

    @IsEnum(ArchiveStatus)
    @IsNotEmpty()
    archiveStatus: ArchiveStatus;
}
