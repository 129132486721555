import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { PartyId } from '../models/party-id';

export class UploadPartyDocumentRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsString()
    @IsOptional()
    createdIpAddress?: string;

    @IsOptional()
    userfile0?: File | unknown;
}
