import { AccountDto } from '../../../../baza-account/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaReferralCampaignDto } from './baza-referral-campaign.dto';

export class BazaReferralCodeDto {
    @ApiModelProperty({
        description: 'ID',
    })
    id: number;

    @ApiModelProperty({
        description: 'Is code active?',
    })
    isActive: boolean;

    @ApiModelProperty({
        description: 'Is code default? (for accounts only)',
    })
    isDefault: boolean;

    @ApiModelProperty({
        description: 'Created At',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Last time when someone joined with this referral code',
        required: false,
    })
    dateLastUsedAt: string | undefined;

    @ApiModelProperty({
        description: 'Associated with account (optional, one of Account or Campaign)',
        required: false,
    })
    associatedWithAccount?: AccountDto;

    @ApiModelProperty({
        description: 'Associated with campaign (optional, one of Account or Campaign)'
    })
    associatedWithCampaign?: BazaReferralCampaignDto;

    @ApiModelProperty({
        description: 'Code (lowercased code version)',
    })
    code: string;

    @ApiModelProperty({
        description: 'Code (Display version of code)',
    })
    codeDisplay: string;

    @ApiModelProperty({
        description: 'How many times code used?',
    })
    usedCount: number;
}
