import { LinkAccount, LinkFundingSource, LinkTransfer } from './link';
import { CardDetail } from './card-detail';
import { DwollaAmount } from './dwolla-amount';
import { DwollaBankAccountType } from './dwolla-bank-account-type';
import { DwollaFundingSourceType } from './dwolla-funding-source-type';
import { DwollaFundingSourceStatus } from './dwolla-funding-source-status';

export enum DwollaFundingSourceChannel {
    ACH = 'ach',
    RealTimePayments = 'real-time-payments',
    Wire = 'wire',
}

export class DwollaFundingSourceLinks {
    self: LinkFundingSource;
    balance: LinkFundingSource;
    'transfer-send': LinkTransfer;
    'with-available-balance': LinkFundingSource;
    'transfer-receive': LinkTransfer;
    account: LinkAccount;
}

export class DwollaFundingSource {
    _links: DwollaFundingSourceLinks;
    id: string;
    status: DwollaFundingSourceStatus;
    type: DwollaFundingSourceType;
    bankAccountType: DwollaBankAccountType;
    name: string;
    created: string;
    removed: boolean;
    channels: Array<DwollaFundingSourceChannel>;
    bankName: string;
    balance?: DwollaAmount;
    cardDetails?: CardDetail;
    fingerprint?: string;
}
