<nz-alert
    *ngIf="showDividendAlert$ | async"
    class="dividend-alert"
    [nzShowIcon]="false"
    [nzBanner]="true"
    [nzMessage]="alertMsg"></nz-alert>
<ng-template #alertMsg>
    <div>
        Add a bank account in your Payout Method to receive dividends.
        <button
            nz-button
            nzType="text"
            (click)="navigateToAccount()">
            Add Account
        </button>
    </div>
</ng-template>
