import { OfferingId } from '../models/offering-id';
import { TemplateId } from '../models/template-id';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UpdateSubscriptionsForOfferingRequest {
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    templateId: TemplateId;

    @IsString()
    @IsNotEmpty()
    templatename: string;

    @IsString()
    @IsOptional()
    updatedIpAddress?: string;
}
