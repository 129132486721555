import { Injectable } from '@angular/core';
import { BazaI18nNgService } from '../services/baza-i18n-ng.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BazaHttpHeaders } from '@scaliolabs/baza-core-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class BazaI18nNgHttpInterceptor implements HttpInterceptor {
    constructor(private readonly bazaI18n: BazaI18nNgService, private readonly ngEndpoint: BazaDataAccessService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        return next.handle(
            req.clone({
                setHeaders: {
                    [BazaHttpHeaders.HTTP_HEADER_I18N_LANGUAGE]: this.bazaI18n.currentLanguage as string,
                },
            }),
        );
    }
}
