import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationPortfolioTransactionType } from '../models/baza-nc-integration-portfolio-transaction-type';
import { BazaNcIntegrationPortfolioTransactionDividendEntityDto } from './baza-nc-integration-portfolio-transaction-dividend-entity.dto';

export class BazaNcIntegrationPortfolioTransactionDividendDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationPortfolioTransactionType.Dividends],
    })
    type: BazaNcIntegrationPortfolioTransactionType.Dividends;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty()
    entity: BazaNcIntegrationPortfolioTransactionDividendEntityDto;
}
