import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { TradeId } from '../models/trade-id';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';


export class CcFundMovementRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    amount: string;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;
}
