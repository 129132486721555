export const WebUiSummaryEnI18n = {
    title: 'Summary',
    numOfShares: 'Number of shares',
    pricePerShare: 'Price per share',
    transactionFee: 'Transaction fee',
    transactionFeePopover:
        'This is a due diligence fee paid to cover costs associated with investigating, evaluating and purchasing underlying portfolio assets',
    paymentMethodFee: 'Payment method fee',
    total: 'Total',
};
