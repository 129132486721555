import { VFCheckEnI18n } from '../check/i18n/vf-check.i18n';
import { VFFileUploadEnI18n } from '../file-upload/i18n/vf-file-upload-i18n';
import { VFInfoEnI18n } from '../stepper-info/i18n/vf-stepper-info.i18n';
import { VFInvestorEnI18n } from '../stepper-investor/i18n/vf-stepper-investor.i18n';
import { VFEnI18n } from './vf.18n';

export const BazaWebVFEnI18n = {
    dwvf: {
        vf: VFEnI18n,
        check: VFCheckEnI18n,
        info: VFInfoEnI18n,
        investor: VFInvestorEnI18n,
        fileUpload: VFFileUploadEnI18n,
    },
};
