import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { TagCmsDto } from '../dto/tag-cms.dto';
import { Type } from 'class-transformer';

export enum TagsCmsEndpointPaths {
    create = '/baza-content-types/cms/tags/create',
    update = '/baza-content-types/cms/tags/update',
    delete = '/baza-content-types/cms/tags/delete',
    list = '/baza-content-types/cms/tags/list',
    getAll = '/baza-content-types/cms/tags/getAll',
    getById = '/baza-content-types/cms/tags/getById',
    setSortOrder = '/baza-content-types/cms/tags/setSortOrder',
}

export interface TagsCmsEndpoint {
    create(request: TagsCmsCreateRequest): Promise<TagCmsDto> | Observable<TagCmsDto>;
    update(request: TagsCmsUpdateRequest): Promise<TagCmsDto> | Observable<TagCmsDto>;
    delete(request: TagsCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: TagsCmsListRequest): Promise<TagsCmsListResponse> | Observable<TagsCmsListResponse>;
    getAll(request?: Partial<TagsCmsCreateRequest>): Promise<Array<TagCmsDto>> | Observable<Array<TagCmsDto>>;
    getById(request: TagsCmsGetByIdRequest): Promise<TagCmsDto> | Observable<TagCmsDto>;
    setSortOrder(request: TagsCmsSetSortOrderRequest): Promise<TagsCmsSetSortOrderResponse> | Observable<TagsCmsSetSortOrderResponse>;
}

export class TagsCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isPublished?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsOptional()
    seo?: BazaSeoDto;
}

export class TagsCmsCreateRequest {
    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isPublished?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsOptional()
    seo?: BazaSeoDto;
}

export class TagsCmsUpdateRequest extends TagsCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TagsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TagsCmsListRequest extends CrudListRequestDto<TagCmsDto> {}

export class TagsCmsListResponse extends CrudListResponseDto<TagCmsDto> {
    @ApiModelProperty({
        type: TagCmsDto,
        isArray: true,
    })
    items: Array<TagCmsDto>;
}

export class TagsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TagsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class TagsCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<TagCmsDto> {
    @ApiModelProperty()
    entity: TagCmsDto;

    @ApiModelProperty({
        type: TagCmsDto,
        isArray: true,
    })
    affected: Array<TagCmsDto>;
}
