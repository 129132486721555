import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDateString, IsInt, IsOptional, IsPositive, IsString } from 'class-validator';
import { Transform } from 'class-transformer';

export class CrudListRequestQueryDto {
    @ApiModelProperty({
        description: 'Page (from 1 to ...)',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    @Transform(({ value }) => parseInt(value, 10))
    index?: number;

    @ApiModelProperty({
        description: 'Items per page. Provide 0 or negative value to get all entities',
        required: false,
    })
    @IsInt()
    @IsOptional()
    @Transform(({ value }) => parseInt(value, 10))
    size?: number;

    @ApiModelProperty({
        description: 'Search by query string',
        required: false,
    })
    @IsString()
    @IsOptional()
    queryString?: string;

    @ApiModelProperty({
        description: 'Filter records by Date From',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateFrom?: string;

    @ApiModelProperty({
        description: 'Filter records by Date To',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateTo?: string;
}
