import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { generateRandomHexString } from '@scaliolabs/baza-core-shared';

interface FormValue {
    value: string;
}

interface State {
    htmlId: string;
    form: UntypedFormGroup;
    isPasswordVisible: boolean;
}

@Component({
    selector: 'baza-password-input',
    templateUrl: './baza-password-input.component.html',
    styleUrls: ['./baza-password-input.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazaPasswordInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() customErrorPassword: string;
    @Input() required: boolean;

    public state: State = {
        htmlId: generateRandomHexString(8),
        form: this.fb.group({
            value: [undefined, []],
        }),
        isPasswordVisible: false,
    };

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/ban-types
    private onChange: Function;

    // eslint-disable-next-line @typescript-eslint/ban-types
    private onTouched: Function;

    constructor(public ngControl: NgControl, private readonly fb: UntypedFormBuilder) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.state.form.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
            .subscribe((next: FormValue) => this.onChange(next.value));
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state.form.patchValue(formValue);
    }

    get dynamicPlaceholder(): string {
        return this.placeholder ? this.placeholder : this.label;
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.state.form.disable() : this.state.form.enable();
    }

    writeValue(value: string): void {
        this.patchFormValue = {
            value,
        };
    }

    togglePasswordVisibility(): void {
        this.state = {
            ...this.state,
            isPasswordVisible: !this.state.isPasswordVisible,
        };
    }
}
