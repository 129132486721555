import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcIntegrationSubscriptionCsvRow<T = unknown> {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty()
    account: string;

    @ApiModelProperty()
    sent: string;

    @ApiModelProperty()
    subscribed: string;

    @ApiModelProperty()
    accreditedInvestor: string | boolean;
}
