import { AbstractControl, ValidationErrors } from '@angular/forms';

export const lowercaseValidator = (): ((AbstractControl) => ValidationErrors | null) => {
    return (control: AbstractControl): ValidationErrors | null => {
        const targetValue = control.value;

        if (!targetValue) {
            return null;
        }

        const regex = RegExp(/[a-z]/gm);

        return regex.test(targetValue) ? null : { lowercase: true };
    };
};
