import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
import { retryWhen, tap } from 'rxjs/operators';
import { genericRetryStrategy } from '@scaliolabs/baza-core-ng';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { ContactsDataAccess } from '@scaliolabs/baza-content-types-data-access';
import { ContactsSubjectDto } from '@scaliolabs/baza-content-types-shared';

import { ContactUsFx, ContactUsStartApp } from './actions';

export type ContactUsSubject = ContactsSubjectDto;

export interface ContactUsStateModel {
    subjects: ContactUsSubject[];
}

@State<ContactUsStateModel>({
    name: 'contactUs',
    defaults: {
        subjects: [],
    },
})
@Injectable()
export class ContactUsState {
    @Selector()
    static subjects(state: ContactUsStateModel) {
        return state.subjects;
    }

    constructor(private dataAccess: ContactsDataAccess, private effectsUtil: EffectsUtil) {}

    @Action(ContactUsStartApp)
    startApp(ctx: StateContext<ContactUsStateModel>) {
        return this.dataAccess
            .subjects()
            .pipe(tap((subjects) => ctx.patchState({ subjects })))
            .pipe(retryWhen(genericRetryStrategy()), this.effectsUtil.tryCatchNone$());
    }

    @Action(ContactUsFx)
    contactUsFx(ctx: StateContext<ContactUsStateModel>, action: ContactUsFx) {
        return this.dataAccess.send(action.data).pipe(retryWhen(genericRetryStrategy()), this.effectsUtil.tryCatchNone$());
    }
}
