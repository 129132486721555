import { AbstractControl, ValidationErrors } from '@angular/forms';

type RestrictedCharsValidatorOptions = {
    /**
     * Whether letters are allowed or not
     * @defaultValue true
     */
    allowLetters?: boolean;

    /**
     * Whether numbers are allowed or not
     * @defaultValue true
     */
    allowNumbers?: boolean;

    /**
     * Whether spaces are allowed or not
     * @defaultValue true
     */
    allowSpaces?: boolean;

    /**
     * An optional array of characters to allow in the value.
     * @defaultValue []
     */
    bypassChars?: string[];
};

/**
 * Composable validator that ensures a form control's value only contains allowed characters.
 *
 * @param options - An object that contains options to control de behavior of the validator.
 * @param options.allowLetters - Whether letters are allowed or not, default is true.
 * @param options.allowNumbers - Whether numbers are allowed or not, default is true.
 * @param options.allowSpaces - Whether spaces are allowed or not, default is true.
 * @param options.bypassChars - An optional array of characters to allow in the value, default is [].
 *
 * @returns A validation function that returns a ValidationErrors object or null.
 *
 * @example valid
 *
 * const control = new FormControl('SomeText', restrictedCharsValidator());
 * console.log(control.errors); // null
 *
 * @example invalid
 *
 * const control = new FormControl('some-text', restrictedCharsValidator());
 * console.log(control.errors); // { restrictedChars: true }
 *
 * @example disable numbers & spaces
 *
 * const control = new FormControl('some text 00', restrictedCharsValidator({ allowNumbers: false, allowSpaces: false }));
 * console.log(control.errors); // { restrictedChars: true }
 *
 * @example bypass characters
 *
 * const control = new FormControl('#some-text$', restrictedCharsValidator({ bypassChars: ['#', '-', '$'] }));
 * console.log(control.errors); // null
 */
export const restrictedCharsValidator = (options?: RestrictedCharsValidatorOptions) => {
    const defaultOptions: RestrictedCharsValidatorOptions = {
        allowLetters: true,
        allowNumbers: true,
        allowSpaces: true,
        bypassChars: [],
    };

    options = Object.assign({}, defaultOptions, options);

    return ({ value }: AbstractControl): ValidationErrors | null => {
        if (!value) {
            return null;
        }

        const { letters, numbers, spaces, bypassed } = {
            letters: options.allowLetters ? 'a-zA-Z' : '',
            numbers: options.allowNumbers ? '0-9' : '',
            spaces: options.allowSpaces ? ' ' : '',
            bypassed: options.bypassChars.join(''),
        };

        const pattern = `^[${letters}${numbers}${spaces}${bypassed}]+$`;

        const regex = RegExp(pattern, 'gm');

        return regex.test(value) ? null : { restrictedChars: true };
    };
};
