import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  PageCmsDto,
  PagesCmsCreateRequest,
  PagesCmsDeleteRequest,
  PagesCmsEndpoint,
  PagesCmsEndpointPaths,
  PagesCmsGetByIdRequest,
  PagesCmsListRequest,
  PagesCmsListResponse,
  PagesCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class PagesCmsDataAccess implements PagesCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: PagesCmsCreateRequest): Observable<PageCmsDto> {
    return this.http.post(PagesCmsEndpointPaths.create, request);
  }

  update(request: PagesCmsUpdateRequest): Observable<PageCmsDto> {
    return this.http.post(PagesCmsEndpointPaths.update, request);
  }

  delete(request: PagesCmsDeleteRequest): Observable<void> {
    return this.http.post(PagesCmsEndpointPaths.delete, request);
  }

  list(request: PagesCmsListRequest): Observable<PagesCmsListResponse> {
    return this.http.post(PagesCmsEndpointPaths.list, request);
  }

  getById(request: PagesCmsGetByIdRequest): Observable<PageCmsDto> {
    return this.http.post(PagesCmsEndpointPaths.getById, request);
  }
}
