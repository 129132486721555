import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountRole } from '../../../../baza-account/src';

export class BazaReferralAccountCsvDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty()
    fullName: string;

    @ApiModelProperty({
        required: false,
    })
    firstName?: string;

    @ApiModelProperty({
        required: false,
    })
    lastName?: string;

    @ApiModelProperty()
    isEmailConfirmed: boolean;

    @ApiModelProperty()
    role: AccountRole;

    @ApiModelProperty()
    referralCode?: string;

    @ApiModelProperty({
        description: 'Signed up with referral code',
        required: false,
    })
    signedUpWithReferralCode?: string;
}
