import { BazaNcIntegrationPerkDto } from '../dto/baza-nc-integration-perk.dto';
import { Observable } from 'rxjs';

export enum BazaNcIntegrationPerkEndpointPaths {
    list = '/baza-nc-integration/listings/:listingId/perks',
    getById = '/baza-nc-integration/perks/get/:id',
}

export interface BazaNcIntegrationPerkEndpoint {
    getById(id: number): Promise<BazaNcIntegrationPerkDto> | Observable<BazaNcIntegrationPerkDto>;
    list(listingId: number): Promise<Array<BazaNcIntegrationPerkDto>> | Observable<Array<BazaNcIntegrationPerkDto>>;
}
