import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaContentDto } from '../../../../../shared/src';
import {
    BazaSeoDto,
    BazaSocialNetworkDto,
    BazaSocialNetworksDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { TeamMemberCmsDto } from '../dto/team-member-cms.dto';
import { Observable } from 'rxjs';
import { Type } from 'class-transformer';

export enum TeamsMemberCmsEndpointPaths {
    create = '/baza-content-types/cms/teams/members/create',
    update = '/baza-content-types/cms/teams/members/update',
    delete = '/baza-content-types/cms/teams/members/delete',
    getById = '/baza-content-types/cms/teams/members/getById',
    list = '/baza-content-types/cms/teams/members/list',
    setSortOrder = '/baza-content-types/cms/teams/members/setSortOrder',
}

export interface TeamsMemberCmsEndpoint {
    create(request: TeamsMemberCmsCreateRequest): Promise<TeamMemberCmsDto> | Observable<TeamMemberCmsDto>;
    update(request: TeamsMemberCmsUpdateRequest): Promise<TeamMemberCmsDto> | Observable<TeamMemberCmsDto>;
    delete(request: TeamsMemberDeleteRequest): Promise<void> | Observable<void>;
    getById(request: TeamsMemberGetByIdRequest): Promise<TeamMemberCmsDto> | Observable<TeamMemberCmsDto>;
    list(request: TeamsMemberListRequest): Promise<TeamsMemberListResponse> | Observable<TeamsMemberListResponse>;
    setSortOrder(
        request: TeamsMemberSetSortOrderRequest,
    ): Promise<TeamsMemberSetSortOrderResponse> | Observable<TeamsMemberSetSortOrderResponse>;
}

export class TeamsMemberEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    bio: BazaContentDto;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    fullImageS3Key?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    previewImageS3Key?: string;

    @ApiModelProperty()
    @ValidateNested({ each: true })
    @Type(() => BazaSocialNetworkDto)
    @IsArray()
    @IsNotEmpty()
    networks: BazaSocialNetworksDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class TeamsMemberCmsCreateRequest extends TeamsMemberEntityBody {}

export class TeamsMemberCmsUpdateRequest extends TeamsMemberEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TeamsMemberDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TeamsMemberGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TeamsMemberListRequest extends CrudListRequestDto<TeamMemberCmsDto> {}
export class TeamsMemberListResponse extends CrudListResponseDto<TeamMemberCmsDto> {}

export class TeamsMemberSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class TeamsMemberSetSortOrderResponse extends CrudSetSortOrderResponseDto<TeamMemberCmsDto> {
    @ApiModelProperty()
    entity: TeamMemberCmsDto;

    @ApiModelProperty({
        type: TeamMemberCmsDto,
        isArray: true,
    })
    affected: Array<TeamMemberCmsDto>;
}
