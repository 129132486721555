import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BazaLinkUtilSharedService, BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { ClearPurchaseState, PurchaseState } from '../data-access';

@Component({
    selector: 'app-purchase-done',
    templateUrl: './done.component.html',
})
export class PurchaseDoneComponent implements OnDestroy {
    cart$ = this.store.select(PurchaseState.cart);
    numberOfShares$ = this.store.select(PurchaseState.numberOfShares);
    purchaseStart$ = this.store.select(PurchaseState.purchaseStart);

    i18nBasePath = 'dwpf.done';
    constructor(
        private readonly store: Store,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnDestroy(): void {
        this.store.dispatch(new ClearPurchaseState());
    }

    public get contactLinkConfig(): string {
        return `${this.i18nBasePath}.support.linkConfig`;
    }

    public get portfolioLinkConfig(): string {
        return `${this.i18nBasePath}.actions.proceedToPortfolio.linkConfig`;
    }
}
