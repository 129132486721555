import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, of, take, tap } from 'rxjs';
import { MktPageDto } from '@scaliolabs/baza-content-types-shared';
import { MktPagesDataAccess } from '@scaliolabs/baza-content-types-data-access';

@Injectable({ providedIn: 'root' })
export class BazaMktSectionsStore {
    readonly sections$: Observable<MktPageDto[]>;
    private sectionSubject: BehaviorSubject<MktPageDto[]>;

    constructor(private dataAccess: MktPagesDataAccess) {
        this.sectionSubject = new BehaviorSubject<MktPageDto[]>([]);
        this.sections$ = this.sectionSubject.asObservable();
        this.loadSections();
    }

    private loadSections(): void {
        this.dataAccess
            .get()
            .pipe(
                catchError((err) => {
                    return of([]);
                }),
                tap((resp) => this.sectionSubject.next(resp)),
                take(1),
            )
            .subscribe();
    }
}
