import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  EventCmsDto,
  EventsCmsCreateRequest,
  EventsCmsDeleteRequest,
  EventsCmsEndpoint,
  EventsCmsEndpointPaths,
  EventsCmsGetByIdRequest,
  EventsCmsListRequest,
  EventsCmsListResponse,
  EventsCmsSetSortOrderRequest,
  EventsCmsSetSortOrderResponse,
  EventsCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class EventsCmsDataAccess implements EventsCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: EventsCmsCreateRequest): Observable<EventCmsDto> {
    return this.http.post(EventsCmsEndpointPaths.create, request);
  }

  update(request: EventsCmsUpdateRequest): Observable<EventCmsDto> {
    return this.http.post(EventsCmsEndpointPaths.update, request);
  }

  delete(request: EventsCmsDeleteRequest): Observable<void> {
    return this.http.post(EventsCmsEndpointPaths.delete, request);
  }

  list(request: EventsCmsListRequest): Observable<EventsCmsListResponse> {
    return this.http.post(EventsCmsEndpointPaths.list, request);
  }

  getById(request: EventsCmsGetByIdRequest): Observable<EventCmsDto> {
    return this.http.post(EventsCmsEndpointPaths.getById, request);
  }

  setSortOrder(request: EventsCmsSetSortOrderRequest): Observable<EventsCmsSetSortOrderResponse> {
    return this.http.post(EventsCmsEndpointPaths.setSortOrder, request);
  }
}
