export const WebUiWithdrawFundsModalEnI18n = {
    title: 'Withdraw Funds',
    subtitle: 'Withdrawal Balance: {{ amount }}',
    cashOut: {
        heading: 'Withdraw To',
        actions: {
            edit: 'Edit',
        },
    },
    actions: {
        transfer: 'Withdraw Funds',
    },
};
