import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcPayoffDistributionTransactionDto } from '../dto/baza-nc-payoff-distribution-transaction.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ulid } from 'ulid';
import { Observable } from 'rxjs';
import { BazaNcPayoffDistributionTransactionStatus } from '../models/baza-nc-payoff-distribution-transaction-status';
import { BazaNcPayoffDistributionTransactionCsvDto } from '../dto/baza-nc-payoff-distribution-transaction-csv.dto';
import { BazaNcPayoffDistributionFailureReasonDto } from '../dto/baza-nc-payoff-distribution-failure-reason.dto';
import { BazaNcPayoffDistributionPaymentStatus } from '../models/baza-nc-payoff-distribution-payment-status';

export enum BazaNcPayoffDistributionTransactionCmsEndpointPaths {
    create = '/baza-nc/cms/payoff-distribution/transaction/create',
    delete = '/baza-nc/cms/payoff-distribution/transaction/delete',
    list = '/baza-nc/cms/payoff-distribution/transaction/list',
    getByUlid = '/baza-nc/cms/payoff-distribution/transaction/getByUlid',
    process = '/baza-nc/cms/payoff-distribution/transaction/process',
    reprocess = '/baza-nc/cms/payoff-distribution/transaction/reprocess',
    sync = '/baza-nc/cms/payoff-distribution/transaction/sync',
    sendProcessConfirmation = '/baza-nc/cms/payoff-distribution/transaction/sendProcessConfirmation',
    exportToCsv = '/baza-nc/cms/payoff-distribution/transaction/exportToCsv',
}

export interface BazaNcPayoffDistributionTransactionCmsEndpoint {
    create(
        request: BazaNcPayoffDistributionTransactionCmsCreateRequest,
    ): Promise<BazaNcPayoffDistributionTransactionDto> | Observable<BazaNcPayoffDistributionTransactionDto>;
    delete(request: BazaNcPayoffDistributionTransactionCmsDeleteRequest): Promise<void> | Observable<void>;
    list(
        request: BazaNcPayoffDistributionTransactionCmsListRequest,
    ): Promise<BazaNcPayoffDistributionTransactionCmsListResponse> | Observable<BazaNcPayoffDistributionTransactionCmsListResponse>;
    getByUlid(
        request: BazaNcPayoffDistributionTransactionCmsGetByUlidRequest,
    ): Promise<BazaNcPayoffDistributionTransactionDto> | Observable<BazaNcPayoffDistributionTransactionDto>;
    sendProcessConfirmation(
        request: BazaNcPayoffDistributionTransactionCmsSendProcessConfirmationRequest,
        ...args: unknown[]
    ): Promise<void> | Observable<void>;
    process(
        request: BazaNcPayoffDistributionTransactionCmsProcessRequest,
        ...args: unknown[]
    ): Promise<BazaNcPayoffDistributionTransactionCmsProcessResponse> | Observable<BazaNcPayoffDistributionTransactionCmsProcessResponse>;
    reprocess(
        request: BazaNcPayoffDistributionTransactionCmsReprocessRequest,
    ): Promise<BazaNcPayoffDistributionTransactionCmsReprocessResponse> | Observable<BazaNcPayoffDistributionTransactionCmsReprocessResponse>;
    sync(request: BazaNcPayoffDistributionTransactionCmsSyncRequest): Promise<void> | Observable<void>;
    exportToCsv(request: BazaNcPayoffDistributionTransactionCmsExportToCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcPayoffDistributionTransactionCmsCreateRequest {
    @ApiModelProperty({
        description: 'Title for PayoffDistribution Transaction',
    })
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class BazaNcPayoffDistributionTransactionCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionCmsListRequest extends CrudListRequestDto<BazaNcPayoffDistributionTransactionDto> {}

export class BazaNcPayoffDistributionTransactionCmsListResponse extends CrudListResponseDto<BazaNcPayoffDistributionTransactionDto> {}

export class BazaNcPayoffDistributionTransactionCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionCmsSendProcessConfirmationRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionCmsProcessRequest {
    @ApiModelProperty({
        description: 'Token from Confirmation email',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    token: string;

    @ApiModelProperty({
        description: 'Current Admin Account password',
    })
    @IsString()
    password: string;

    @ApiModelProperty({
        description: 'If true, API will wait for finishing transaction before returning 200 OK response',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean = false;
}

export class BazaNcPayoffDistributionTransactionCmsProcessResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionTransactionStatus),
    })
    newStatus: BazaNcPayoffDistributionTransactionStatus;
}

export class BazaNcPayoffDistributionTransactionCmsExportToCsvRequest extends CrudExportToCsvRequest<
    BazaNcPayoffDistributionTransactionCsvDto,
    BazaNcPayoffDistributionTransactionCmsListRequest
> {}

export class BazaNcPayoffDistributionTransactionCmsReprocessRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Entries ULID (ID)',
        example: ulid(),
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    entryULIDs: Array<string>;
}

export class BazaNcPayoffDistributionTransactionCmsReprocessResponseEntry {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
    })
    status: BazaNcPayoffDistributionPaymentStatus;

    @ApiModelProperty({
        description: 'Failure Reason (if failed)',
    })
    failureReason?: BazaNcPayoffDistributionFailureReasonDto;
}

export class BazaNcPayoffDistributionTransactionCmsReprocessResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionTransactionStatus),
    })
    newStatus: BazaNcPayoffDistributionTransactionStatus;

    @ApiModelProperty({
        type: BazaNcPayoffDistributionTransactionCmsReprocessResponseEntry,
        isArray: true,
        description: 'Updated entries statuses and possible failure reasons',
    })
    entries?: Array<BazaNcPayoffDistributionTransactionCmsReprocessResponseEntry>;
}

export class BazaNcPayoffDistributionTransactionCmsSyncRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        description: 'If true, API will wait for finishing transaction before returning 200 OK response',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean;
}
