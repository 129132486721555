import { TradeId } from '../models/trade-id';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class FundReturnRequestRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    requestedBy: string;

    @IsString()
    @IsNotEmpty()
    reason: string;

    @IsString()
    @IsOptional()
    notes: string;

    @IsString()
    @IsOptional()
    createdIpAddress: NorthCapitalIpAddress;
}
