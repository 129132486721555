import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOperationType } from '@scaliolabs/baza-nc-shared';
import { BazaDwollaPaymentStatus } from '@scaliolabs/baza-dwolla-shared';

/**
 * Filters Settings for Operation List (Withdraw, i.e. Cash Out)
 */
export class BazaNcIntegrationOperationStatusesWithdrawDto {
    @ApiModelProperty({
        type: 'string',
        example: [BazaNcOperationType.Withdraw],
        enum: [BazaNcOperationType.Withdraw],
    })
    type: BazaNcOperationType.Withdraw;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaDwollaPaymentStatus),
    })
    statuses: Array<BazaDwollaPaymentStatus>;
}
