import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class SelectSchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty({
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    values: Record<string, string>;
}

export class SelectSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.Select],
    })
    type: BazaNcIntegrationSchemaType.Select;

    @ApiModelProperty()
    payload: SelectSchemaDefinitionPayload;
}
