export enum PaymentButtonStyleEnum {
    Simple = 'Simple',
    Bordered = 'Bordered',
}

export interface PFVFSharedConfig {
    vfLink?: string;
    back?: string;
    redirect?: string;
    dwollaSupport?: boolean;
}

export const LEGACY_NC_MODE_QUERY_PARAM = 'nc';
