import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsOptional } from 'class-validator';
import { Transform } from 'class-transformer';

/**
 * Query Params for Plaid Link Request
 */
export class BazaPlaidLinkRequestDto {
    @ApiModelProperty({
        description: 'Enable this flag if you would like to use Redirect URL after finishing Plaid Flow',
        required: false,
    })
    @IsBoolean()
    @Transform(({ value }) => {
        return ['true', '1', 'on', true].includes(value || '');
    })
    @IsOptional()
    withRedirectUrl?: boolean;
}
