import { BazaNcIntegrationFeedCmsPostDto } from '../dto/baza-nc-integration-feed-cms-post.dto';
import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationFeedPostType } from '../models/baza-nc-integration-feed-post-type';
import { CrudListRequestDto, CrudSetSortOrderRequestDto } from '@scaliolabs/baza-core-shared';
import { CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { bazaSeoDefault, BazaSeoDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationFeedDisplay } from '../models/baza-nc-integration-feed-display';
import { Type } from 'class-transformer';

export enum FeedCmsPaths {
    create = '/baza-nc-integration/cms/feed/cms/posts/create',
    update = '/baza-nc-integration/cms/feed/cms/posts/update',
    delete = '/baza-nc-integration/cms/feed/cms/posts/delete',
    list = '/baza-nc-integration/cms/feed/cms/posts/list',
    getById = '/baza-nc-integration/cms/feed/cms/posts/getById',
    setSortOrder = '/baza-nc-integration/cms/feed/cms/posts/setSortOrder',
}

export interface BazaNcIntegrationFeedCmsEndpoint {
    create(request: FeedCmsPostsCreateRequest): Promise<FeedCmsPostsCreateResponse> | Observable<FeedCmsPostsCreateResponse>;
    update(request: FeedCmsPostsUpdateRequest): Promise<FeedCmsPostsUpdateResponse> | Observable<FeedCmsPostsUpdateResponse>;
    delete(request: FeedCmsPostsDeleteRequest): Promise<FeedCmsPostsDeleteResponse> | Observable<FeedCmsPostsDeleteResponse>;
    list(request: FeedCmsPostsListRequest): Promise<FeedCmsPostsListResponse> | Observable<FeedCmsPostsListResponse>;
    getById(request: FeedCmsPostsGetByIdRequest): Promise<FeedCmsPostsGetByIdResponse> | Observable<FeedCmsPostsGetByIdResponse>;
    setSortOrder(request: FeedCmsSetSortOrderRequest): Promise<void> | Observable<void>;
}

export class FeedCmsPostsEntityBody {
    @ApiModelProperty({
        description: 'Is post published?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty({
        description: 'Display post to non-authenticated users',
    })
    @IsBoolean()
    @IsNotEmpty()
    allowPublicAccess: boolean;

    @ApiModelProperty({
        description: 'Post Type',
        type: 'string',
        enum: Object.values(BazaNcIntegrationFeedPostType),
    })
    @IsEnum(BazaNcIntegrationFeedPostType)
    @IsNotEmpty()
    type: BazaNcIntegrationFeedPostType;

    @ApiModelProperty({
        description: 'Title',
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty({
        description: 'Intro',
        required: false,
    })
    @IsString()
    @IsOptional()
    intro?: string;

    @ApiModelProperty({
        description: 'HTML contents of post',
        required: false,
    })
    @IsString()
    @IsOptional()
    contents?: string;

    @ApiModelProperty({
        description: 'Preview image (S3 AWS FieldValue)',
        required: false,
    })
    @IsString()
    @IsOptional()
    previewImage?: string;

    @ApiModelProperty({
        description: 'Preview video (S3 AWS FieldValue)',
        required: false,
    })
    @IsString()
    @IsOptional()
    previewVideo?: string;

    @ApiModelProperty({
        description: 'Tags',
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds: Array<number>;

    @ApiModelProperty({
        default: bazaSeoDefault(),
    })
    @ValidateNested()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;

    @ApiModelProperty({
        description: 'Listing ID',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    listingId?: number;

    @ApiModelProperty({
        description: 'Display mode',
        required: false,
        enum: Object.values(BazaNcIntegrationFeedDisplay),
        type: 'string',
    })
    @IsEnum(BazaNcIntegrationFeedDisplay)
    @IsOptional()
    display?: BazaNcIntegrationFeedDisplay;
}

export class FeedCmsPostsCreateRequest extends FeedCmsPostsEntityBody {}
export type FeedCmsPostsCreateResponse = BazaNcIntegrationFeedCmsPostDto;

export class FeedCmsPostsUpdateRequest extends FeedCmsPostsEntityBody {
    @ApiModelProperty({
        description: 'ID of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FeedCmsPostsUpdateResponse = BazaNcIntegrationFeedCmsPostDto;

export class FeedCmsPostsDeleteRequest {
    @ApiModelProperty({
        description: 'ID of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FeedCmsPostsDeleteResponse = void;

export class FeedCmsPostsListRequest extends CrudListRequestDto<BazaNcIntegrationFeedCmsPostDto> {}
export class FeedCmsPostsListResponse extends CrudListResponseDto<BazaNcIntegrationFeedCmsPostDto> {}

export class FeedCmsPostsGetByIdRequest {
    @ApiModelProperty({
        description: 'ID of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FeedCmsPostsGetByIdResponse = BazaNcIntegrationFeedCmsPostDto;

export class FeedCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}
