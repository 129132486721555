import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOperationDirection } from '../models/baza-nc-operation-direction';
import { BazaNcOperationPayloadDto, bazaNcOperationPayloadSwaggerDefinition } from './baza-nc-operation-payload.dto';

export class BazaNcOperationDto {
    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        description: 'Payload for Operation',
        ...bazaNcOperationPayloadSwaggerDefinition(),
    })
    payload: BazaNcOperationPayloadDto;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcOperationDirection),
        example: BazaNcOperationDirection.In,
        description: 'Cash-In or Cash-Out operation?',
    })
    direction: BazaNcOperationDirection;

    @ApiModelProperty({
        description: 'Amount',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;
}
