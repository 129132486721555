import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcCreditCardDetailsDto } from './baza-nc-credit-card-details.dto';

export class BazaNcCreditCardDto {
    @ApiModelProperty({
        description: 'Is credit card linked?',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        description: 'Credit Card (if isAvailable is true)',
        required: false,
    })
    card?: BazaNcCreditCardDetailsDto;
}
