import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzCardModule } from 'ng-zorro-antd/card';

@Component({
    selector: 'app-regcf-overview',
    templateUrl: './regcf-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzCardModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegCfOverviewComponent {
    @Output()
    updateLimit = new EventEmitter<void>();

    public i18nBasePath = 'uic.regCfOverview';

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
