import { IsBoolean, IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowTransactionType } from '../models/baza-nc-purchase-flow-transaction-type';
import { generateRandomHexString } from '@scaliolabs/baza-core-shared';

export class PurchaseFlowDto {
    @ApiModelProperty({
        description: 'Trade Id. You should store it and use for purchase flow steps',
    })
    @IsString()
    @IsNotEmpty()
    id: string;

    @ApiModelProperty({
        description: 'Session Lock Update Id. Use it to validate that your request is actually sent from same device',
        example: generateRandomHexString(8),
    })
    @IsString()
    @IsNotEmpty()
    updateId: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        description: 'Transaction type (ACH or WIRE). Transaction type cannot be changed in future for the purchase.',
        default: BazaNcPurchaseFlowTransactionType.ACH,
    })
    transactionType: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description: 'Are documents signed?',
    })
    @IsBoolean()
    @IsNotEmpty()
    areDocumentsSigned: boolean;

    @ApiModelProperty({
        description: 'DocuSign document url',
        required: false,
    })
    @IsString()
    @IsOptional()
    docuSignUrl?: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
    })
    @IsNumber()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty({
        description: 'Fee (as cents)',
    })
    @IsNumber()
    @IsNotEmpty()
    fee: number;

    @ApiModelProperty({
        description: 'Total, amount + fees (as cents)',
    })
    @IsNumber()
    @IsNotEmpty()
    total: number;

    @ApiModelProperty({
        description: 'Number of shares',
    })
    @IsNumber()
    @IsNotEmpty()
    numberOfShares: number;

    @ApiModelProperty({
        description:
            'ISO Date. Displays how long purchase flow will be active. Each /session request will prolong active session for additional 10 minutes.',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    willBeActiveUpTo: string;

    @ApiModelProperty({
        description: 'Transaction fees amount.',
    })
    @IsNumber()
    @IsOptional()
    transactionFeesCents?: number;

    @ApiModelProperty({
        description: 'Transaction fees.',
    })
    @IsNumber()
    @IsOptional()
    transactionFees?: number;
}
