import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsNotEmpty, IsOptional, IsString, Length, MaxLength, MinLength } from 'class-validator';
import { Observable } from 'rxjs';
import { AccountDto } from '../dto/account.dto';
import { BazaAccountConstants } from '../baza-account.constants';
import { AccountSettingsDto } from '../dto/account-settings.dto';
import { AuthDto } from '../../../../baza-auth/src';
import { BazaAccountRegisterOptions } from '../models/baza-account-register-options';

export enum AccountEndpointPaths {
    currentAccount = '/baza-account/currentAccount',
    registerAccount = '/baza-account/registerAccount',
    registerAccountOptions = '/baza-account/registerAccountOptions',
    confirmEmail = '/baza-account/confirmEmail',
    sendConfirmEmailLink = '/baza-account/sendConfirmEmailLink',
    changePassword = '/baza-account/changePassword',
    sendResetPasswordLink = '/baza-account/sendResetPasswordLink',
    resetPassword = '/baza-account/resetPassword',
    sendChangeEmailLink = '/baza-account/sendChangePasswordLink',
    changeEmail = '/baza-account/changeEmail',
    updateAccountSettings = '/baza-account/updateAccountSettings',
    updateProfile = '/baza-account/updateProfile',
    sendDeactivateAccountLink = '/baza-account/sendDeactivateAccountLink',
    deactivateAccount = '/baza-account/deactivateAccount',
    accountExists = '/baza-account/accountExists',
}

export interface AccountEndpoint {
    currentAccount(...args: unknown[]): Promise<CurrentAccountResponse> | Observable<CurrentAccountResponse>;
    registerAccount(
        request: RegisterAccountRequest,
        ...args: unknown[]
    ): Promise<RegisterAccountResponse> | Observable<RegisterAccountResponse>;
    registerAccountOptions(...args: unknown[]): Promise<BazaAccountRegisterOptions> | Observable<BazaAccountRegisterOptions>;
    confirmEmail(request: ConfirmEmailRequest, ...args: unknown[]): Promise<ConfirmEmailResponse> | Observable<ConfirmEmailResponse>;
    sendConfirmEmailLink(
        request: SendConfirmEmailLinkRequest,
        ...args: unknown[]
    ): Promise<SendConfirmEmailLinkResponse> | Observable<SendConfirmEmailLinkResponse>;
    changePassword(
        request: ChangePasswordRequest,
        ...args: unknown[]
    ): Promise<ChangePasswordResponse> | Observable<ChangePasswordResponse>;
    sendResetPasswordLink(
        request: SendResetPasswordLinkRequest,
        ...args: unknown[]
    ): Promise<SendResetPasswordLinkResponse> | Observable<SendResetPasswordLinkResponse>;
    resetPassword(request: ResetPasswordRequest, ...args: unknown[]): Promise<ResetPasswordResponse> | Observable<ResetPasswordResponse>;
    sendChangeEmailLink(request: SendChangeEmailLinkRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    changeEmail(request: ChangeEmailRequest, ...args: unknown[]): Promise<ChangeEmailResponse> | Observable<ChangeEmailResponse>;
    updateSettings(
        request: SetAccountSettingsRequest,
        ...args: unknown[]
    ): Promise<SetAccountSettingsResponse> | Observable<SetAccountSettingsResponse>;
    updateProfile(request: UpdateProfileRequest, ...args: unknown[]): Promise<UpdateProfileResponse> | Observable<UpdateProfileResponse>;
    sendDeactivateAccountLink(request: SendDeactivateAccountLinkRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    deactivateAccount(request: DeactivateAccountRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    accountExists(request: AccountExistsRequest, ...args: unknown[]): Promise<AccountExistsResponse> | Observable<AccountExistsResponse>;
}

export type CurrentAccountResponse = AccountDto;

export class RegisterAccountRequest {
    @ApiModelProperty({
        description: 'Referral or Invite code',
        required: false,
    })
    @IsString()
    @IsOptional()
    referralCode?: string;

    @ApiModelProperty({
        required: true,
        description: 'Account Email.',
        maxLength: BazaAccountConstants.EMAIL_MAX_LENGTH,
    })
    @IsEmail(
        {},
        {
            message: 'Email is invalid',
        },
    )
    @IsString()
    @MaxLength(BazaAccountConstants.EMAIL_MAX_LENGTH)
    @IsNotEmpty()
    email: string;

    @ApiModelProperty({
        required: true,
        description: 'Account first name.',
        maxLength: BazaAccountConstants.USER_NAME_MAX_LENGTH,
    })
    @IsString()
    @MaxLength(BazaAccountConstants.USER_NAME_MAX_LENGTH)
    @IsNotEmpty()
    firstName: string;

    @ApiModelProperty({
        required: true,
        description: 'Account last name.',
        maxLength: BazaAccountConstants.USER_NAME_MAX_LENGTH,
    })
    @IsString()
    @MaxLength(BazaAccountConstants.USER_NAME_MAX_LENGTH)
    @IsNotEmpty()
    lastName: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    password: string;

    @ApiModelProperty({
        required: false,
        description: 'Optional field to perform password/confirm check for UI',
    })
    @IsString()
    @IsOptional()
    confirmPassword?: string;
}

export type RegisterAccountResponse = void;

export class ConfirmEmailRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    token: string;
}

export type ConfirmEmailResponse = AuthDto;

export class SendConfirmEmailLinkRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    email: string;
}

export type SendConfirmEmailLinkResponse = void;

export class ChangePasswordRequest {
    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    oldPassword: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    newPassword: string;

    @ApiModelProperty({
        required: false,
        description: 'Optional field to perform password/confirm check for UI',
    })
    @IsString()
    @IsOptional()
    confirmPassword?: string;
}

export type ChangePasswordResponse = void;

export class SendResetPasswordLinkRequest {
    @ApiModelProperty()
    @IsEmail(
        {},
        {
            message: 'Email is invalid',
        },
    )
    @IsString()
    @IsNotEmpty()
    email: string;
}

export type SendResetPasswordLinkResponse = void;

export class ResetPasswordRequest {
    @ApiModelProperty()
    @Length(BazaAccountConstants.TOKEN_LENGTH)
    @IsString()
    @IsNotEmpty()
    token: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    newPassword: string;

    @ApiModelProperty({
        required: false,
        description: 'Optional field to perform password/confirm check for UI',
    })
    @IsString()
    @IsOptional()
    confirmPassword?: string;
}

export type ResetPasswordResponse = AuthDto;

export class UpdateProfileRequest {
    @ApiModelProperty({
        required: false,
        description: 'Account first name.',
        maxLength: BazaAccountConstants.USER_NAME_MAX_LENGTH,
    })
    @IsString()
    @MaxLength(BazaAccountConstants.USER_NAME_MAX_LENGTH)
    @IsOptional()
    firstName?: string;

    @ApiModelProperty({
        required: false,
        description: 'Account last name.',
        maxLength: BazaAccountConstants.USER_NAME_MAX_LENGTH,
    })
    @IsString()
    @MaxLength(BazaAccountConstants.USER_NAME_MAX_LENGTH)
    @IsOptional()
    lastName?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    phone?: string;
}

export type UpdateProfileResponse = AccountDto;

export type SetAccountSettingsRequest<T = AccountSettingsDto> = Partial<T>;
export type SetAccountSettingsResponse<T = AccountSettingsDto> = T;

export class SendChangeEmailLinkRequest {
    @ApiModelProperty({
        description: 'New email',
        required: true,
        maxLength: BazaAccountConstants.EMAIL_MAX_LENGTH,
    })
    @IsEmail()
    @IsString()
    @MaxLength(BazaAccountConstants.EMAIL_MAX_LENGTH)
    @IsNotEmpty()
    newEmail: string;

    @ApiModelProperty({
        description: 'Current password',
    })
    @IsString()
    password: string;
}

export class ChangeEmailRequest {
    @ApiModelProperty({
        description: 'Token from sendChangePasswordRequest response',
    })
    @IsString()
    token: string;
}

export type ChangeEmailResponse = AuthDto;

export class SendDeactivateAccountLinkRequest {
    @ApiModelProperty({
        description: 'Current password',
    })
    @IsString()
    password: string;
}

export class DeactivateAccountRequest {
    @ApiModelProperty({
        description: 'Token from sendDeactivateAccountLink response',
    })
    @IsString()
    token: string;
}

export class AccountExistsRequest {
    @ApiModelProperty({
        description: 'Account Email',
    })
    @IsString()
    email: string;
}

export class AccountExistsResponse {
    @ApiModelProperty({
        description: 'Has active account',
    })
    hasActiveAccount: boolean;

    @ApiModelProperty({
        description: 'Has deactivated accounts',
    })
    hasDeactivatedAccounts: boolean;
}
