import { Link } from './link';
import { DwollaAmount } from './dwolla-amount';
import { DwollaClearing } from './transfer-details';
import { DwollaACHDetails } from './dwolla-ach-details';
import { DwollaProcessingChannel } from './dwolla-processing-channel';

export class DwollaMassPaymentItem {
    _links: Record<'destination', Link>;
    amount: DwollaAmount;
    metadata?: {
        [key: string]: string;
    };
    clearing?: DwollaClearing;
    correlationId?: string;
    achDetails?: DwollaACHDetails;
    processingChannel?: DwollaProcessingChannel;
}
