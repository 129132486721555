import { WebUiPaymentAccountBalanceEnI18n } from '../payment-account-balance/i18n/payment-account-balance.i18n';
import { WebUiAddFundsFormEnI18n } from '../add-funds-form/i18n/webui-add-funds-form.i18n';
import { WebUiAddFundsModalEnI18n } from '../add-funds-modal/i18n/webui-add-funds-modal.i18n';
import { WebUiAddFundsNotificationEnI18n } from '../add-funds-notification/i18n/webui-add-funds-notification.i18n';
import { WebUiAddBankFormEnI18n } from '../add-bank-form/i18n/add-bank-form.i18n';
import { WebUiAddCardFormEnI18n } from '../add-card-form/i18n/add-card-form.i18n';
import { WebUiMarketingBannerEnI18n } from '../banner';
import { WebUiMarketingBulletsEnI18n } from '../bullets/i18n/bullets.i18n';
import { WebUiMarketingCTAEnI18n } from '../cta/i18n/cta.i18n';
import { WebUiMarketingHeroEnI18n } from '../hero/i18n/hero.i18n';
import { WebUiMarketingInvestEnI18n } from '../invest/i18n/invest.i18n';
import { WebUiMarketingTextImageEnI18n } from '../text-image/i18n/text-image.i18n';
import { WebUiPaymentBankAccountEnI18n } from '../payment-bank-account/i18n/payment-bank-account.i18n';
import { WebUiPaymentBankAccountModalEnI18n } from '../payment-bank-account-modal/i18n/payment-bank-account-modal.i18n';
import { WebUiPaymentCardEnI18n } from '../payment-card/i18n/payment-card.i18n';
import { WebUiPaymentCardModalEnI18n } from '../payment-card-modal/i18n/payment-card-modal.i18n';
import { WebUiPersonalInfoEnI18n } from '../personal-info/i18n/personal-info.i18n';
import { WebUiSummaryEnI18n } from '../summary/i18n/summary.i18n';
import { WebUiWithdrawFundsFormEnI18n } from '../withdraw-funds-form/i18n/webui-withdraw-funds-form.i18n';
import { WebUiWithdrawFundsModalEnI18n } from '../withdraw-funds-modal/i18n/webui-withdraw-funds-modal.i18n';
import { WebUiWithdrawFundsNotificationEnI18n } from '../withdraw-funds-notification/i18n/webui-withdraw-funds-notification.i18n';
import { WebUiRegCfConsentEnI18n } from '../regcf-consent/i18n/regcf-consent.i18n';
import { WebUiRegCfOverviewEnI18n } from '../regcf-overview/i18n/regcf-overview.i18n';
import { WebUiInvestorProfileEnI18n } from '../investor-profile/i18n/investor-profile-i18n';
import { WebUiKycDisapprovedAlertEnI18n } from '../kyc-disapproved-alert/i18n/kyc-disapproved-alert.i18n';
import { WebUiPaymentItemBankEnI18n } from '../payment-item-bank/i18n/payment-item-bank.i18n';
import { WebUiPaymentItemCardEnI18n } from '../payment-item-card/i18n/payment-item-card.i18n';

export const BazaWebUiEnI18n = {
    uic: {
        addBankForm: WebUiAddBankFormEnI18n,
        addCardForm: WebUiAddCardFormEnI18n,
        addFunds: {
            form: WebUiAddFundsFormEnI18n,
            modal: WebUiAddFundsModalEnI18n,
            notification: WebUiAddFundsNotificationEnI18n,
        },
        investorProfile: WebUiInvestorProfileEnI18n,
        kycDisapprovedAlert: WebUiKycDisapprovedAlertEnI18n,
        marketing: {
            banner: WebUiMarketingBannerEnI18n,
            bullets: WebUiMarketingBulletsEnI18n,
            cta: WebUiMarketingCTAEnI18n,
            hero: WebUiMarketingHeroEnI18n,
            invest: WebUiMarketingInvestEnI18n,
            txtImg: WebUiMarketingTextImageEnI18n,
        },
        paymentAccountBalance: WebUiPaymentAccountBalanceEnI18n,
        paymentBank: WebUiPaymentBankAccountEnI18n,
        paymentBankModal: WebUiPaymentBankAccountModalEnI18n,
        paymentCard: WebUiPaymentCardEnI18n,
        paymentCardModal: WebUiPaymentCardModalEnI18n,
        paymentItemBank: WebUiPaymentItemBankEnI18n,
        paymentItemCard: WebUiPaymentItemCardEnI18n,
        personal: WebUiPersonalInfoEnI18n,
        regCfConsent: WebUiRegCfConsentEnI18n,
        regCfOverview: WebUiRegCfOverviewEnI18n,
        summary: WebUiSummaryEnI18n,
        withdrawFunds: {
            form: WebUiWithdrawFundsFormEnI18n,
            modal: WebUiWithdrawFundsModalEnI18n,
            notification: WebUiWithdrawFundsNotificationEnI18n,
        },
    },
};
