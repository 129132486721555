import { AccountVerificationStep } from './../models/step.enum';
import { AccountVerificationPersonalInformationDto } from './personal-information.dto';
import { AccountVerificationInvestorProfileDto } from './investor-profile.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { GetAccountResponse, KYCStatus, PartyDetails } from '../../../transact-api';

export class AccountVerificationDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountVerificationStep),
    })
    currentStep: AccountVerificationStep;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(KYCStatus),
        description: 'KYC Status',
    })
    kycStatus?: KYCStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(KYCStatus),
        description: 'Indicate that additional document has been requested from user',
    })
    shouldRequestAdditionalDocuments?: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(KYCStatus),
        description: 'Indicate that SSN document has been requested from user',
    })
    shouldRequestSSNDocument?: boolean;

    @ApiModelProperty({
        type: () => AccountVerificationPersonalInformationDto,
    })
    personalInformation?: AccountVerificationPersonalInformationDto;

    @ApiModelProperty({
        type: () => AccountVerificationInvestorProfileDto,
    })
    investorProfile?: AccountVerificationInvestorProfileDto;

    @ApiModelProperty({
        type: () => PartyDetails,
        description: 'Source party from North Capital API. WILL BE NOT AVAILABLE AT PRODUCTION',
    })
    _debugAccount?: GetAccountResponse;

    @ApiModelProperty({
        type: () => PartyDetails,
        description: 'Source account from North Capital API. WILL BE NOT AVAILABLE AT PRODUCTION',
    })
    _debugParty?: PartyDetails;
}

