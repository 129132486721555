import { TransactionState, NcTransactionDto } from '@scaliolabs/baza-nc-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationListingsDto } from '../../../../listings/src';

export class BazaNcIntegrationInvestmentDto {
    @ApiModelProperty({
        description: 'Should be displayed as primary card? (Primary cards displays full information including dividends)',
        example: true,
    })
    isPrimaryCard: boolean;

    @ApiModelProperty({
        description: 'Date First Trade created for Listing',
        example: new Date().toISOString(),
    })
    createdAt: string;

    @ApiModelProperty({
        description: 'Date Last Trade created for Listing',
        example: new Date().toISOString(),
        required: false,
    })
    updatedAt?: string;

    @ApiModelProperty()
    listing: BazaNcIntegrationListingsDto;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
    })
    lastTransactionState: TransactionState;

    @ApiModelProperty()
    purchasedShares: number;

    @ApiModelProperty({
        example: '120.55',
    })
    purchasedAmountTotal: string;

    @ApiModelProperty({
        example: 12055,
    })
    purchasedAmountTotalCents: number;

    @ApiModelProperty({
        description: 'will return a transaction dto which is for the last failed transaction for the user on the specified offering',
        required: false,
    })
    lastFailedTransaction?: NcTransactionDto;
}
