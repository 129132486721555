import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalFloatAsString } from '../common-types/north-capital-float-as-string';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { NorthCapitalAccountId } from '../models/account-id';
import { OfferingId } from '../models/offering-id';
import { TradeId } from '../models/trade-id';

export class ExternalFundMoveRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    NickName: string;

    @IsString()
    @IsNotEmpty()
    amount: NorthCapitalFloatAsString;

    @IsString()
    @IsNotEmpty()
    description: string;

    @IsString()
    @IsNotEmpty()
    checkNumber: TradeId;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;
}
