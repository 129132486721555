export enum BazaNcSearchOperator {
    Equal = '$eq',
    NotEqual = '$ne',
    Exists = '$exists',
    NotExists = '$null',
    In = '$in',
    NotIn = '$nin',
    LessThan = '$lt',
    LessThanOrEqual = '$lte',
    GreaterThan = '$gt',
    GreaterThanOrEqual = '$gte',
}
