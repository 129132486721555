import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BazaI18nNgDefaultService } from '../services/baza-i18n-ng-default.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { BazaI18nNgService } from '../services/baza-i18n-ng.service';
import { ProjectLanguage } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaI18nPrefetchResolver implements Resolve<true> {
    constructor(
        private readonly detector: BazaI18nNgDefaultService,
        private readonly service: BazaI18nNgService,
    ) {}

    resolve(): Observable<true> {
        let detected: ProjectLanguage;

        return this.detector.detectDefaultLanguage().pipe(
            tap((language) => detected = language),
            switchMap((language) => this.service.fetchTranslations(language)),
            tap(() => this.service.useLanguage(detected)),
            map(() => true),
        )
    }
}
