import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaCmsDataAccessConfig } from './baza-cms-data-access.config';

interface ForRootOptions {
    injects: Array<unknown>;
    useFactory: (...args: Array<unknown>) => BazaCmsDataAccessConfig;
}

export { ForRootOptions as BazaNgEndpointModuleForRootOptions };

// @dynamic
@NgModule({
    providers: [],
})
export class BazaCmsDataAccessModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaCmsDataAccessModule> {
        return {
            ngModule: BazaCmsDataAccessModule,
            providers: [
                {
                    provide: BazaCmsDataAccessConfig,
                    useFactory: (...injects) => options.useFactory(...injects),
                },
            ],
        };
    }
}
