import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { BazaWebUtilSharedService, LoaderService, UtilModule } from '@scaliolabs/baza-web-utils';
import { IFRAME_EVENTS, NC_ORIGIN_URL } from './models';

@Component({
    selector: 'app-iframe-integration',
    templateUrl: './iframe-integration.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule],
})
export class IframeIntegrationComponent<T extends string = string> implements OnDestroy {
    @Input()
    url: string;

    @Input()
    events?: Array<T> = [];

    @Input()
    classList?: string;

    @Output()
    iframeEvent = new EventEmitter<T>();

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly loader: LoaderService,
        private readonly wts: BazaWebUtilSharedService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.loader.show();
        }
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.wts.removeEventListener(window, IFRAME_EVENTS.message, this.onMessageHandler);
        }
    }

    onLoadHandler = (e: any) => {
        if (!isPlatformBrowser(this.platformId) || !e?.target?.src) return;

        this.wts.attachEventListener(window, IFRAME_EVENTS.message, this.onMessageHandler);
        this.loader.hide();
    };

    onMessageHandler = (e: any) => {
        if (!e) return;

        const evOrigin = e['origin'];
        const evData = e['data'];

        if (this.events?.length > 0) {
            if (this.events.includes(evData as T)) {
                this.iframeEvent.next(evData as T);
            }
        } else {
            if (evData && evOrigin && evOrigin.includes(NC_ORIGIN_URL) && typeof evData === 'string') {
                this.iframeEvent.next(evData as T);
            }
        }
    };
}
