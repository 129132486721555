import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
    BazaNcBankAccountAchDto,
    BazaNcBootstrapDto,
    BazaNcCreditCardDto,
    BazaNcDwollaCustomerDetailDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { PaymentMethodsConfig, PaymentEditModalsContentType, PaymentMethodType } from '../../data-access';

@Component({
    selector: 'app-payment-edit',
    templateUrl: './payment-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentEditComponent {
    @Input()
    selectedPaymentMethod: PaymentMethodType;

    @Input()
    creditCardResponse?: BazaNcCreditCardDto;

    @Input()
    bankAccountResponse: BazaNcBankAccountAchDto;

    @Input()
    dwollaCustomerDetail: BazaNcDwollaCustomerDetailDto;

    @Input()
    isPurchaseAboveLimit: boolean;

    @Input()
    hasEnoughFunds: boolean;

    @Input()
    config: PaymentMethodsConfig;

    @Input()
    initData: BazaNcBootstrapDto;

    @Output()
    handlePaymentEditCancel = new EventEmitter();

    @Output()
    public selectPaymentMethod: EventEmitter<PaymentMethodType> = new EventEmitter();

    @Output()
    public submitAddForm: EventEmitter<PaymentMethodType> = new EventEmitter();

    public modalContentType: PaymentEditModalsContentType = PaymentEditModalsContentType.paymentList;
    MODAL_CONTENT_TYPE = PaymentEditModalsContentType;
    public modalTitle: string;
    public paymentMethodType: PaymentMethodType;

    constructor(public readonly wts: BazaWebUtilSharedService, private readonly cdr: ChangeDetectorRef) {}

    public onBackClick() {
        if (
            (!this.wts.isDwollaAvailable(this.initData) || !this.hasEnoughFunds) &&
            !this.wts.isNCBankAccountAvailable(this.initData) &&
            !this.wts.isCardAvailable(this.initData)
        ) {
            this.handlePaymentEditCancel.emit();
        } else if (this.modalContentType === PaymentEditModalsContentType.paymentAdd) {
            this.modalContentType = PaymentEditModalsContentType.paymentList;
        }
    }

    public onModalTitleChange(title: string) {
        this.modalTitle = title;
        this.cdr.detectChanges();
    }

    public onSubmitAddForm(paymentMethodType: PaymentMethodType) {
        this.submitAddForm.emit(paymentMethodType);
    }

    public onPaymentMethodChange(paymentMethod: PaymentMethodType) {
        this.selectPaymentMethod.emit(paymentMethod);
    }

    public onAddUpdatePaymentMethodClick(paymentMethodType: PaymentMethodType) {
        this.paymentMethodType = paymentMethodType;

        if (this.paymentMethodType === PaymentMethodType.bankAccount) {
            this.submitAddForm.emit(this.paymentMethodType);
        } else {
            this.modalContentType = PaymentEditModalsContentType.paymentAdd;
        }
    }
}
