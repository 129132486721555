import { AttachmentImageSetDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingTestimonialBaseDto } from './baza-nc-integration-listing-testimonial-base.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcIntegrationListingTestimonialDto extends BazaNcIntegrationListingTestimonialBaseDto {
    @ApiModelProperty({
        description: 'Image',
    })
    image: AttachmentImageSetDto;
}
