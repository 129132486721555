import { KYCStatus } from './kyc-status';
import { AmlStatus } from './aml-status';
import { PartyId } from './party-id';
import { Domicile } from './domicile';
import { EmpStatus } from './emp-status';
import type { NorthCapitalDate, NorthCapitalDateTime } from '../common-types';
import { EsignStatus } from './esign-status';

export class PartyDetails {
    partyId: PartyId;
    kycStatus: KYCStatus;
    KYCstatus: KYCStatus;
    amlStatus: AmlStatus;
    amlDate: NorthCapitalDate;
    firstName: string;
    lastName: string;
    domicile: Domicile;
    socialSecurityNumber: string;
    dob: string;
    primAddress1: string;
    primAddress2: string;
    primCity: string;
    primState: string;
    primZip: string;
    primCountry: string;
    emailAddress: string;
    emailAddress2: string;
    phone: string;
    phone2: string | null;
    occupation: string;
    associatedPerson: null;
    empStatus: EmpStatus;
    empName: string;
    empCountry: string;
    empAddress1: string;
    empAddress2: string;
    empCity: string;
    empState: string;
    empZip: string;
    invest_to: string;
    currentAnnIncome: string;
    avgAnnIncome: string;
    currentHouseholdIncome: string;
    avgHouseholdIncome: string;
    householdNetworth: string;
    tags: string;
    notes: string;
    esignStatus: EsignStatus;
    documentKey: string;
    field1: string;
    field2: string;
    field3: string;
    createdDate: NorthCapitalDateTime;
    updatedDate: NorthCapitalDateTime;
}
