import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum BazaReferralCodeEndpointPaths {
    countUsage = 'baza-core/referral-codes/countUsage/:code',
}

export interface BazaReferralCodeEndpoint {
    countReferralCode(code: string, ...args: unknown[]): Promise<BazaReferralCodeCountResponse> | Observable<BazaReferralCodeCountResponse>;
}

export class BazaReferralCodeCountResponse {
    @ApiModelProperty({
        description: 'Returns the number of times the code was used',
    })
    total: number;
}
