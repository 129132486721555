export enum BazaNcIntegrationSchemaType {
    Text = 'Text',
    TextMulti = 'TextMulti',
    TextArea = 'TextArea',
    HTML = 'HTML',
    Email = 'Email',
    Select = 'Select',
    Checkbox = 'Checkbox',
    Date = 'Date',
    DateRange = 'DateRange',
    Time = 'Time',
    Number = 'Number',
    Rate = 'Rate',
    Slider = 'Slider',
    Switcher = 'Switcher',
    File = 'File',
    FileMulti = 'FileMulti',
    Image = 'Image',
    ImageMulti = 'ImageMulti',
    KeyValue = 'KeyValue',
    DependantArray = 'DependantArray',
}

export const bazaNcIntegrationSchemaNumberTypes: Array<BazaNcIntegrationSchemaType> = [
    BazaNcIntegrationSchemaType.Number,
    BazaNcIntegrationSchemaType.Rate,
    BazaNcIntegrationSchemaType.Slider,
];

export const bazaNcIntegrationSchemaHtmlTypes: Array<BazaNcIntegrationSchemaType> = [BazaNcIntegrationSchemaType.HTML];

export const bazaNcIntegrationSchemaTextTypes: Array<BazaNcIntegrationSchemaType> = [
    BazaNcIntegrationSchemaType.Text,
    BazaNcIntegrationSchemaType.TextArea,
    BazaNcIntegrationSchemaType.Email,
];

export const bazaNcSchemaBooleanTypes: Array<BazaNcIntegrationSchemaType> = [
    BazaNcIntegrationSchemaType.Checkbox,
    BazaNcIntegrationSchemaType.Switcher,
];

export const bazaNcSchemaDateTypes: Array<BazaNcIntegrationSchemaType> = [BazaNcIntegrationSchemaType.Date];

export const bazaNcSchemaEnumTypes: Array<BazaNcIntegrationSchemaType> = [BazaNcIntegrationSchemaType.Select];
