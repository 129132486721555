import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcDividendPaymentSource } from '../models/baza-nc-dividend-payment-source';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import { OfferingId } from '../../../../transact-api';
import { ulid } from 'ulid';

export class BazaNcDividendTransactionEntryCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: '12/01/2022',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: '12/01/2022',
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: '12/01/2022',
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        description: 'Date',
        example: '12/01/2022',
    })
    date: string;

    @ApiModelProperty({
        description: 'Offering',
        required: false,
    })
    offering?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering ID',
        required: false,
    })
    ncOfferingId?: OfferingId;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcDividendPaymentSource),
    })
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Account ID)',
    })
    ncAccountId: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Party ID)',
    })
    ncPartyId: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaNcDividendPaymentStatus),
        example: BazaNcDividendPaymentStatus.Pending,
    })
    status: BazaNcDividendPaymentStatus;
}
