<ng-container *ngTemplateOutlet="isPaymentLinked ? linkedPayment : unlinkedPayment"></ng-container>

<ng-template #linkedPayment>
    <div
        class="payment-section payment-section-inverse payment-section-item"
        [ngClass]="{
            'payment-section-with-details': (details$ | async).length > 0
        }"
        (click)="onContainerClicked($event)">
        <div class="payment-item-content">
            <div
                class="payment-item-content__header payment-item-content__bold"
                *ngIf="headerComponent">
                <ng-container *ngTemplateOutlet="headerComponent.content"></ng-container>
            </div>

            <ul
                class="payment-item-details"
                *ngIf="details$ | async as details">
                <li
                    class="payment-item-details__item"
                    *ngFor="let detailComponent of details">
                    <ng-container *ngTemplateOutlet="detailComponent.content"></ng-container>
                </li>
            </ul>
        </div>

        <div
            #actionsContainer
            class="payment-actions">
            <ng-container *ngTemplateOutlet="actionsComponent?.content"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #unlinkedPayment>
    <button
        class="payment-btn payment-btn-inverse payment-btn-item"
        [ngClass]="{
            'payment-btn-with-details': (details$ | async).length > 0,
            'payment-btn-bordered': style === PAYMENT_BUTTON_STYLE.Bordered
        }"
        nz-button
        nzBlock
        [disabled]="isDisabled"
        (click)="onContainerClicked($event)">
        <div class="payment-item-content">
            <div
                class="payment-item-content__header"
                *ngIf="headerComponent">
                <ng-container *ngTemplateOutlet="headerComponent.content"></ng-container>
            </div>

            <ul
                class="payment-item-details"
                *ngIf="details$ | async as details">
                <li
                    class="payment-item-details__item"
                    *ngFor="let detailComponent of details">
                    <ng-container *ngTemplateOutlet="detailComponent.content"></ng-container>
                </li>
            </ul>
        </div>

        <div class="payment-arrow">
            <i class="icon icon-angle-right icon-wt"></i>
        </div>
    </button>
</ng-template>
