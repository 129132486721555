import { TransactionState } from '@scaliolabs/baza-nc-shared';

export enum PortfolioTransactionTypes {
    Successful = 'Successful',
    Pending = 'Pending',
    Failed = 'Failed',
}

export enum PortfolioTransactionTableTypes {
    Successful = 'Successful',
    Pending = 'Pending',
    Failed = 'Failed',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
}

export const portfolioTransactionStates: { [key in PortfolioTransactionTypes]: TransactionState[] } = {
    Successful: [TransactionState.PaymentConfirmed, TransactionState.UnwindSettled],
    Pending: [TransactionState.Created, TransactionState.PendingPayment, TransactionState.PaymentFunded, TransactionState.UnwindPending],
    Failed: [
        TransactionState.None,
        TransactionState.PaymentCancelled,
        TransactionState.PaymentRejected,
        TransactionState.UnwindCanceled,
        TransactionState.PaymentReturned,
    ],
};

export const portfolioTransactionTableStates: { [key in PortfolioTransactionTableTypes]: TransactionState[] } = {
    Successful: [TransactionState.PaymentConfirmed, TransactionState.UnwindSettled],
    Pending: [TransactionState.Created, TransactionState.PendingPayment, TransactionState.PaymentFunded, TransactionState.UnwindPending],
    Failed: [TransactionState.PaymentReturned],
    Rejected: [TransactionState.PaymentRejected],
    Cancelled: [TransactionState.PaymentCancelled, TransactionState.UnwindCanceled],
};
