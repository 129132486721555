import { Observable } from 'rxjs';
import { TagDto } from '../dto/tag.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { BazaSeoDto } from '@scaliolabs/baza-core-shared';

export enum TagEndpointPaths {
    getAll = '/baza-content-types/tags/all',
    getById = '/baza-content-types/tags/id/:id',
    getByUrl = '/baza-content-types/tags/url/:url',
}

export interface TagEndpoint {
    getAll(request: TagsListRequest): Promise<Array<TagDto>> | Observable<Array<TagDto>>;
    getById(id: number): Promise<TagDto> | Observable<TagDto>;
    getByUrl(url: string): Promise<TagDto> | Observable<TagDto>;
}

export class TagsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isPublished?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsOptional()
    seo?: BazaSeoDto;
}

export class TagsListRequest extends TagsEntityBody {}
