import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { AccountSettingsDto } from '../../../../baza-account/src';
import { JwtPayload } from './jwt-payload.dto';
import { Type } from 'class-transformer';

export class AuthDto<T = AccountSettingsDto> {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    accessToken: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    refreshToken: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => JwtPayload)
    @IsNotEmpty()
    jwtPayload: JwtPayload;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => AccountSettingsDto)
    @IsNotEmpty()
    accountSettings: T;
}
