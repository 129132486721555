import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcIntegrationPortfolioAssetsRequest,
    BazaNcIntegrationPortfolioAssetsResponse,
    BazaNcIntegrationPortfolioEndpoint,
    PortfolioEndpointPaths,
    BazaNcIntegrationPortfolioStatementsRequest,
    BazaNcIntegrationPortfolioStatementsResponse,
    BazaNcIntegrationPortfolioTotalStatsDto,
    BazaNcIntegrationPortfolioTransactionsRequest,
    BazaNcIntegrationPortfolioTransactionsResponse,
} from '@scaliolabs/baza-nc-integration-shared';

@Injectable()
export class BazaPortfolioDataAccess implements BazaNcIntegrationPortfolioEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns aggregated numbers for Total block in Portfolio
     */
    total(): Observable<BazaNcIntegrationPortfolioTotalStatsDto> {
        return this.http.get(PortfolioEndpointPaths.total);
    }

    /**
     * Returns Assets List (v1)
     * @param request
     */
    assets(request: BazaNcIntegrationPortfolioAssetsRequest): Observable<BazaNcIntegrationPortfolioAssetsResponse> {
        return this.http.post(PortfolioEndpointPaths.assets, request);
    }

    /**
     * Returns Assets List (v2)
     * @param request
     */
    assetsTotal(request: BazaNcIntegrationPortfolioAssetsRequest): Observable<BazaNcIntegrationPortfolioAssetsResponse> {
        return this.http.post(PortfolioEndpointPaths.assetsTotal, request);
    }

    /**
     * DEPRECATED - Returns list of transactions
     * @deprecated
     * @param request
     */
    transactions(request: BazaNcIntegrationPortfolioTransactionsRequest): Observable<BazaNcIntegrationPortfolioTransactionsResponse> {
        return this.http.post(PortfolioEndpointPaths.transactions, request);
    }

    /**
     * Returns list of Statement Documents of Purchased Offerings
     * @param request
     */
    statements(request: BazaNcIntegrationPortfolioStatementsRequest): Observable<BazaNcIntegrationPortfolioStatementsResponse> {
        return this.http.post(PortfolioEndpointPaths.statements, request);
    }
}
