import { Injectable } from '@angular/core';
import {
    BazaMaintenanceCmsDisableRequest,
    BazaMaintenanceCmsEnableRequest,
    BazaMaintenanceCmsEndpoint,
    BazaMaintenanceCmsEndpointPaths,
    BazaMaintenanceDto,
    BazaMaintenanceMessageDto,
    BazaMaintenanceSetMessageRequest,
} from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaCmsDataAccessService } from '../../../../../ng/src';

@Injectable()
export class BazaMaintenanceCmsDataAccess implements BazaMaintenanceCmsEndpoint {
    constructor(private readonly ngEndpoint: BazaCmsDataAccessService) {}

    status(): Observable<BazaMaintenanceDto> {
        return this.ngEndpoint.get<BazaMaintenanceDto>(BazaMaintenanceCmsEndpointPaths.status);
    }

    enable(request: BazaMaintenanceCmsEnableRequest): Observable<BazaMaintenanceDto> {
        return this.ngEndpoint.post<BazaMaintenanceDto>(BazaMaintenanceCmsEndpointPaths.enable, request);
    }

    disable(request: BazaMaintenanceCmsDisableRequest): Observable<BazaMaintenanceDto> {
        return this.ngEndpoint.post<BazaMaintenanceDto>(BazaMaintenanceCmsEndpointPaths.disable, request);
    }

    getMessage(): Observable<BazaMaintenanceMessageDto> {
        return this.ngEndpoint.get<BazaMaintenanceMessageDto>(BazaMaintenanceCmsEndpointPaths.getMessage);
    }

    setMessage(request: BazaMaintenanceSetMessageRequest): Observable<BazaMaintenanceMessageDto> {
        return this.ngEndpoint.post<BazaMaintenanceMessageDto>(BazaMaintenanceCmsEndpointPaths.setMessage, request);
    }
}
