import { Injectable } from '@angular/core';
import { BazaNgCoreConfig } from '../baza-ng-core.config';
import { Application, BazaCore } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNgCurrentApplicationService {
    constructor(
        private readonly moduleConfig: BazaNgCoreConfig,
    ) {}

    get application(): Application {
        return this.moduleConfig.application;
    }

    get project(): BazaCore.Project {
        return this.moduleConfig.project;
    }
}
