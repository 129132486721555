import { IsDefined, IsNotEmptyObject } from 'class-validator';
import { DwollaAmount } from '../models/dwolla-amount';

export class VerifyMicroDepositsRequest {
    @IsDefined()
    @IsNotEmptyObject()
    amount1: DwollaAmount;

    @IsDefined()
    @IsNotEmptyObject()
    amount2: DwollaAmount;
}
