import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-withdraw-funds-notification',
    templateUrl: './withdraw-funds-notification.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzModalModule, NzButtonModule],
})
export class WithdrawFundsNotificationComponent {
    @Output()
    isVisibleChange = new EventEmitter<boolean>();

    i18nBasePath = 'uic.withdrawFunds.notification';

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    closeNotification(): void {
        this.isVisibleChange.emit(false);
    }
}
