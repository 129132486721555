import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtilModule } from '@scaliolabs/baza-web-utils';

@Component({
    selector: 'app-payment-radio',
    templateUrl: './payment-radio.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule],
})
export class PaymentRadioComponent {
    @Input()
    isDisabled: boolean;

    @Input()
    isSelected: boolean;
}
