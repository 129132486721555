import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { IssuerId, NorthCapitalAccountId, PartyId } from '../../../transact-api';

export class ImportTradeHistoryRequestDto {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncAccountId: NorthCapitalAccountId;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncPartyId: PartyId;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncIssuerId: IssuerId;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncIssuerName: string;
}
