import { BazaNcIntegrationEliteInvestorsDto } from '../dto/baza-nc-integration-elite-investors.dto';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';

export enum BazaNcIntegrationEliteInvestorsCmsEndpointPaths {
    include = '/baza-ng-integration/cms/elite-investors/include',
    exclude = '/baza-ng-integration/cms/elite-investors/exclude',
    list = '/baza-ng-integration/cms/elite-investors/list',
    getById = '/baza-ng-integration/cms/elite-investors/getById',
    syncAccount = '/baza-ng-integration/cms/elite-investors/syncAccount',
    syncAllAccounts = '/baza-ng-integration/cms/elite-investors/syncAllAccounts',
}

export interface BazaNcIntegrationEliteInvestorsCmsEndpoint {
    include(
        request: BazaNcIntegrationEliteInvestorsCmsIncludeRequest,
    ): Promise<BazaNcIntegrationEliteInvestorsCmsIncludeResponse> | Observable<BazaNcIntegrationEliteInvestorsCmsIncludeResponse>;
    exclude(
        request: BazaNcIntegrationEliteInvestorsCmsExcludeRequest,
    ): Promise<BazaNcIntegrationEliteInvestorsCmsExcludeResponse> | Observable<BazaNcIntegrationEliteInvestorsCmsExcludeResponse>;
    list(
        request: BazaNcIntegrationEliteInvestorsCmsListRequest,
    ): Promise<BazaNcIntegrationEliteInvestorsCmsListResponse> | Observable<BazaNcIntegrationEliteInvestorsCmsListResponse>;
    getById(
        request: BazaNcIntegrationEliteInvestorsCmsGetByIdRequest,
    ): Promise<BazaNcIntegrationEliteInvestorsCmsGetByIdResponse> | Observable<BazaNcIntegrationEliteInvestorsCmsGetByIdResponse>;
    syncAccount(request: BazaNcIntegrationEliteInvestorsCmsSyncAccountRequest): Promise<void> | Observable<void>;
    syncAllAccounts(): Promise<void> | Observable<void>;
}

export class BazaNcIntegrationEliteInvestorsCmsIncludeRequest {
    @ApiModelProperty({
        description: ' ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;

    @ApiModelProperty({
        description: 'Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export type BazaNcIntegrationEliteInvestorsCmsIncludeResponse = BazaNcIntegrationEliteInvestorsDto;

export class BazaNcIntegrationEliteInvestorsCmsExcludeRequest {
    @ApiModelProperty({
        description: ' ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;

    @ApiModelProperty({
        description: 'Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export type BazaNcIntegrationEliteInvestorsCmsExcludeResponse = void;

export class BazaNcIntegrationEliteInvestorsCmsListRequest extends CrudListRequestDto<BazaNcIntegrationEliteInvestorsDto> {
    @ApiModelProperty({
        description: ' ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationEliteInvestorsCmsListResponse extends CrudListResponseDto<BazaNcIntegrationEliteInvestorsDto> {}

export class BazaNcIntegrationEliteInvestorsCmsGetByIdRequest {
    @ApiModelProperty({
        description: 'ID of Elite Investors Entity',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type BazaNcIntegrationEliteInvestorsCmsGetByIdResponse = BazaNcIntegrationEliteInvestorsDto;

export class BazaNcIntegrationEliteInvestorsCmsSyncAccountRequest {
    @ApiModelProperty({
        description: 'Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}
