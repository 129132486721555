import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { BazaNcBankAccountType, BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { GetDefaultBankAccount, PurchaseState, PFSharedService } from '../../../data-access';
import { DwollaBankAccountPayoutComponent } from './bank-account/bank-account-payout.component';

@Component({
    selector: 'app-payout-methods',
    templateUrl: './dwolla-payout-methods.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, DwollaBankAccountPayoutComponent, NzGridModule, NzSkeletonModule],
})
export class DwollaPayoutMethodsComponent {
    @Input()
    initData?: BazaNcBootstrapDto;

    @Input()
    isAnyAssetPurchased = false;

    @Input()
    header?: TemplateRef<any>;

    dwollaDefaultCashOutAccount$ = this.store.select(PurchaseState.dwollaDefaultCashOutAccount);

    constructor(public readonly dss: PFSharedService, private readonly store: Store, private readonly wts: BazaWebUtilSharedService) {
        this.loadData();
    }

    loadData() {
        this.store.dispatch(new GetDefaultBankAccount(BazaNcBankAccountType.CashOut, { throwError: false }));
    }

    onRefreshCashOutData() {
        this.wts.refreshInitData$.next();
        this.loadData();
    }
}
