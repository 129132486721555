import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingTestimonialBaseDto } from './baza-nc-integration-listing-testimonial-base.dto';

export class BazaNcIntegrationListingTestimonialCmsDto extends BazaNcIntegrationListingTestimonialBaseDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty({
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty()
    image: AttachmentDto;
}
