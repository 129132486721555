import { Observable } from 'rxjs';
import { IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, MaxLength, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { JwtPayload } from '../dto/jwt-payload.dto';
import { AccountRole, AccountSettingsDto } from '../../../../baza-account/src';
import { Type } from 'class-transformer';

export enum AuthEndpointPaths {
    auth = '/baza-auth/auth',
    verifyCredentials = '/baza-auth/verifyCredentials',
    verify = '/baza-auth/verify',
    refreshToken = '/baza-auth/refreshToken',
    invalidateToken = '/baza-auth/invalidateToken',
    invalidateAllTokens = '/baza-auth/invalidateAllTokens',
    invalidateByRefreshToken = '/baza-auth/invalidateByRefreshToken',
}

export interface AuthEndpoint {
    auth(request: AuthRequest, ...args: unknown[]): Promise<AuthResponse> | Observable<AuthResponse>;
    verifyCredentials(request: AuthVerifyCredentials, ...args: unknown[]): Promise<boolean> | Observable<boolean>;
    verify(request: VerifyRequest): Promise<VerifyResponse> | Observable<VerifyResponse>;
    refreshToken(request: RefreshTokenRequest, ...args: unknown[]): Promise<RefreshTokenResponse> | Observable<RefreshTokenResponse>;
    invalidateToken(
        request: InvalidateTokenRequest,
        ...args: unknown[]
    ): Promise<InvalidateTokenResponse> | Observable<InvalidateTokenResponse>;
    invalidateAllTokens(...args: unknown[]): Promise<InvalidateAllTokensResponse> | Observable<InvalidateAllTokensResponse>;
    invalidateByRefreshToken(
        request: InvalidateByRefreshTokenRequest,
        ...args: unknown[]
    ): Promise<InvalidateByRefreshTokenResponse> | Observable<InvalidateByRefreshTokenResponse>;
}

export class AuthRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    password: string;

    @ApiModelProperty()
    @IsEnum(AccountRole)
    @IsOptional()
    requireRole?: AccountRole;
}

export class AuthVerifyCredentials {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    password: string;
}

export class AuthResponse {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    accessToken: string;

    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    refreshToken: string;

    @ApiModelProperty({
        type: () => JwtPayload,
    })
    @ValidateNested()
    @Type(() => JwtPayload)
    @IsNotEmpty()
    jwtPayload: JwtPayload;

    @ApiModelProperty({
        type: () => AccountSettingsDto,
    })
    @ValidateNested()
    @Type(() => AccountSettingsDto)
    @IsNotEmpty()
    accountSettings: AccountSettingsDto;
}

export class VerifyRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    jwt: string;

    @ApiModelProperty()
    @IsEnum(AccountRole)
    @IsOptional()
    requireRole?: AccountRole;
}

export type VerifyResponse = void;

export class RefreshTokenRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    refreshToken: string;
}

export class RefreshTokenResponse {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    accessToken: string;

    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    refreshToken: string;

    @ApiModelProperty({
        type: () => JwtPayload,
    })
    @ValidateNested()
    @Type(() => JwtPayload)
    @IsNotEmpty()
    jwtPayload: JwtPayload;

    @ApiModelProperty({
        type: () => AccountSettingsDto,
    })
    @ValidateNested()
    @Type(() => AccountSettingsDto)
    @IsNotEmpty()
    accountSettings: AccountSettingsDto;
}

export class InvalidateTokenRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    jwt: string;
}

export type InvalidateTokenResponse = void;
export type InvalidateAllTokensResponse = void;

export class InvalidateByRefreshTokenRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    refreshToken: string;
}

export type InvalidateByRefreshTokenResponse = void;
