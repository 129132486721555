import { BazaDwollaDistributionDto } from './baza-dwolla-distribution.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDwollaInvestorAccountDto } from '../../../../dwolla-investor-account/src';

export class BazaDwollaDistributionCmsDto extends BazaDwollaDistributionDto {
    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaDwollaInvestorAccountDto;
}
