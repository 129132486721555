import { OfferingId } from '../models/offering-id';
import { NorthCapitalAccountId } from '../models/account-id';
import { TradeId } from '../models/trade-id';
import { IsNotEmpty, IsString } from 'class-validator';

export class SendSubscriptionDocumentRequest {
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;
}
