import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BazaLinkUtilSharedService } from '@scaliolabs/baza-web-utils';
@Component({
    selector: 'app-back-link',
    templateUrl: './back-link.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class BackLinkComponent {
    @Input()
    text?: string;

    @Input()
    url?: string;

    @Input()
    showLeaveConfirmation?: boolean;

    @Output()
    backEvent?: EventEmitter<void> = new EventEmitter();

    constructor(private readonly router: Router, private readonly uts: BazaLinkUtilSharedService) {}

    back() {
        if (this.showLeaveConfirmation) {
            this.backEvent.emit();
        } else {
            this.navigate();
        }
    }

    navigate() {
        this.backEvent.emit();
        if (this.url) {
            this.router.navigateByUrl(this.url);
        }
    }
}
