import { Injectable } from '@angular/core';
import { PhoneCountryCodesRepositoryDto } from '@scaliolabs/baza-core-shared';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { BazaPhoneCountryCodesService } from '../services/baza-phone-country-codes.service';
import { tap } from 'rxjs/operators';

// Consider to use the constants as unique key for routes in order to avoid duplicate usages in same routes for different static-components
export const BAZA_PHONE_COUNTRY_CODES_RESOLVER_KEY = 'bazaPhoneCountryCodes;'

type ResolveData = PhoneCountryCodesRepositoryDto;

export { ResolveData as BazaPhoneCountryCodesResolveData };

let lastUpdated: Date = undefined;

@Injectable()
export class BazaPhoneCountryCodesResolver implements Resolve<ResolveData> {
    constructor(
        private readonly service: BazaPhoneCountryCodesService,
    ) {}

    resolve(): Observable<ResolveData> {
        return ! lastUpdated || (lastUpdated.getTime() - (new Date().getTime())) > this.service.ttlMilliseconds
            ? this.service.refresh().pipe(
                tap(() => lastUpdated = new Date()),
            )
            : of(this.service.repository);
    }
}
