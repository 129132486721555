import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { PaymentItemActionsComponent } from '../payment-item-actions/payment-item-actions.component';
import { PaymentItemDetailComponent } from '../payment-item-detail/payment-item-detail.component';
import { PaymentItemHeaderComponent } from '../payment-item-header/payment-item-header.component';
import { PaymentItemComponent } from './payment-item.component';

const COMPONENTS = [PaymentItemComponent, PaymentItemHeaderComponent, PaymentItemDetailComponent, PaymentItemActionsComponent];
const NZ_MODULES = [NzButtonModule, NzCollapseModule, NzNoAnimationModule];
@NgModule({
    declarations: [...COMPONENTS],
    imports: [CommonModule, RouterModule, UtilModule, ...NZ_MODULES],
    exports: [...COMPONENTS],
})
export class PaymentItemModule {}
