export enum BazaNcOfferingHealthCheckIssue {
    // Investor Account was not created
    MissingInvestorAccount = 'MissingInvestorAccount',

    // Trade is missing in local DB
    MissingTrade = 'MissingTrade',

    // Unit Price, Total Shares or Total Amount is not synced
    TradeDataMismatch = 'TradeDataMismatch',

    // Trade Status is not synced
    TradeStatusMismatch = 'TradeStatusMismatch',

    // Trade exists in CMS but is not found on North Capital
    UnknownTrade = 'UnknownTrade',

    // Trade is owned by wrong Investor Account
    TradeAssignedToWrongInvestorAccount = 'TradeAssignedToWrongInvestorAccount',

    // Cancelled trades should not appears in DB
    TradeCancelledButStillInDatabase = 'TradeCancelledButStillInDatabase',
}
