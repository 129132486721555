import { BazaDwollaPaymentHistoryDto } from './baza-dwolla-payment-history.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaDwollaPaymentStatus } from '../models/baza-dwolla-payment-status';
import { BazaDwollaPaymentType } from '../models/baza-dwolla-payment-type';
import { bazaDwollaCorrelationId } from '../utils/baza-dwolla-correlation-id.util';
import { BazaDwollaPaymentFailureReasonDto } from './baza-dwolla-payment-failure-reason.dto';
import { BazaDwollaPaymentChannel } from '../models/baza-dwolla-payment-channel';
import { BazaDwollaPaymentPayload } from '../models/baza-dwolla-payment-payload';

export class BazaDwollaPaymentDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: new Date().toISOString(),
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: new Date().toISOString(),
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: new Date().toISOString(),
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Channel',
        example: BazaDwollaPaymentChannel.Balance,
        enum: Object.values(BazaDwollaPaymentChannel),
    })
    channel: BazaDwollaPaymentChannel;

    @ApiModelProperty({
        description: 'Type of Dwolla Payment',
        enum: Object.values(BazaDwollaPaymentType),
        type: 'string',
    })
    type: BazaDwollaPaymentType;

    @ApiModelProperty({
        description: 'Correlation Id (Unique ID)',
        example: bazaDwollaCorrelationId({
            type: BazaDwollaPaymentType.PurchaseShares,
            ncTradeId: '123456789',
        }),
    })
    correlationId: string;

    @ApiModelProperty({
        description: 'Dwolla payment payload',
        required: false,
    })
    payload?: BazaDwollaPaymentPayload;

    @ApiModelProperty({
        type: 'string',
        description: 'NC Trade Id',
        example: '123456789',
        required: false,
    })
    ncTradeId?: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaPaymentStatus;

    @ApiModelProperty({
        type: BazaDwollaPaymentHistoryDto,
        isArray: true,
        description: 'History of Dwolla Payment',
    })
    history: Array<BazaDwollaPaymentHistoryDto>;

    @ApiModelProperty({
        description: 'Failure Reason (optional)',
        required: false,
    })
    failureReason?: BazaDwollaPaymentFailureReasonDto;
}
