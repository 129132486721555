import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { FundStatus } from '../models/fund-status';

export class UpdateExternalFundMoveStatusRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    RefNum: number;

    @IsEnum(FundStatus)
    @IsNotEmpty()
    fundStatus: FundStatus;

    @IsString()
    @IsOptional()
    error?: string;
}
