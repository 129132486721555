import { Observable } from 'rxjs';
import { BazaNcTaxDocumentCmsDto } from '../dto/baza-nc-tax-document-cms.dto';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';

export enum BazaNcTaxDocumentCmsEndpointPaths {
    create = '/baza-nc/cms/tax-documents/create',
    update = '/baza-nc/cms/tax-documents/update',
    delete = '/baza-nc/cms/tax-documents/delete',
    list = '/baza-nc/cms/tax-documents/list',
    getById = '/baza-nc/cms/tax-documents/getById',
}

export interface BazaNcTaxDocumentCmsEndpoint {
    create(request: BazaNcTaxDocumentCmsCreateRequest): Promise<BazaNcTaxDocumentCmsDto> | Observable<BazaNcTaxDocumentCmsDto>;
    update(request: BazaNcTaxDocumentCmsUpdateRequest): Promise<BazaNcTaxDocumentCmsDto> | Observable<BazaNcTaxDocumentCmsDto>;
    delete(request: BazaNcTaxDocumentCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: BazaNcTaxDocumentCmsListRequest): Promise<BazaNcTaxDocumentCmsListResponse> | Observable<BazaNcTaxDocumentCmsListResponse>;
    getById(request: BazaNcTaxDocumentCmsGetByIdRequest): Promise<BazaNcTaxDocumentCmsDto> | Observable<BazaNcTaxDocumentCmsDto>;
}

export class BazaNcTaxDocumentEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    description?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    fileS3Key: string;
}

export class BazaNcTaxDocumentCmsCreateRequest extends BazaNcTaxDocumentEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;
}

export class BazaNcTaxDocumentCmsUpdateRequest extends BazaNcTaxDocumentEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcTaxDocumentCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcTaxDocumentCmsListRequest extends CrudListRequestDto<BazaNcTaxDocumentCmsDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;
}

export class BazaNcTaxDocumentCmsListResponse extends CrudListResponseDto<BazaNcTaxDocumentCmsDto> {
    @ApiModelProperty({
        type: BazaNcTaxDocumentCmsDto,
        isArray: true,
    })
    items: Array<BazaNcTaxDocumentCmsDto>;
}

export class BazaNcTaxDocumentCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
