import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type AlertType = 'success' | 'info' | 'warning' | 'error';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
    @Input()
    alertType: AlertType | undefined;

    @Input()
    appLevel: boolean | undefined;

    @Input()
    hideCloseButton: boolean | undefined;

    @Input()
    message: string | undefined;
}
