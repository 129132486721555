import { DwollaUsStates } from '../models/dwolla-us-states';
import { IsNotEmpty, IsString, IsOptional, IsEnum, IsDateString } from 'class-validator';
import { CreateReceiveOnlyCustomerRequest } from './create-receiveonly-customer.request';

export class CreatePersonalCustomerRequest extends CreateReceiveOnlyCustomerRequest {
    @IsString()
    @IsNotEmpty()
    address1: string;

    @IsString()
    @IsOptional()
    address2?: string;

    @IsString()
    @IsNotEmpty()
    city: string;

    @IsEnum(DwollaUsStates)
    @IsNotEmpty()
    state: DwollaUsStates;

    @IsString()
    @IsNotEmpty()
    postalCode: string;

    @IsDateString()
    @IsNotEmpty()
    dateOfBirth: string;

    @IsString()
    @IsNotEmpty()
    ssn: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsString()
    @IsOptional()
    correlationId?: string;
}
