import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

type NorthCapitalDate = string;

export class BazaDwollaInvestorAccountPartyDetailsDto {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    firstName: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    lastName: string;

    @ApiModelProperty({
        description: 'Social security number',
    })
    @IsString()
    @IsNotEmpty()
    ssn: string;

    @ApiModelProperty({
        description: 'Date of birth',
    })
    @IsString()
    @IsNotEmpty()
    dateOfBirth: NorthCapitalDate;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialCountry: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialCity: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialState: string;

    @ApiModelProperty({
        description: 'zipcode',
    })
    @IsString()
    residentialZipCode: string;

    @ApiModelProperty({
        description: 'Primary address 1',
    })
    @IsString()
    @IsNotEmpty()
    residentialStreetAddress1: string;

    @ApiModelProperty({
        description: 'Primary address 2',
    })
    @IsString()
    residentialStreetAddress2: string;
}
