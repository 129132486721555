import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BazaVersionNgConfig, BazaVersionNgStrategy } from '../baza-version-ng.config';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class XBazaVersionNgHttpInterceptor implements HttpInterceptor {
    constructor(private readonly moduleConfig: BazaVersionNgConfig, private readonly ngEndpoint: BazaDataAccessService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        const type = this.moduleConfig.strategy.type;

        switch (this.moduleConfig.strategy.type) {
            default: {
                console.error(`[XBazaVersionNgHttpInterceptor] Unknown version check strategy "${type}"`);

                return next.handle(req);
            }

            case BazaVersionNgStrategy.Disabled: {
                return next.handle(req);
            }

            case BazaVersionNgStrategy.SkipVersion: {
                return next.handle(
                    req.clone({
                        setHeaders: {
                            [this.moduleConfig.xHttpHeaderVersion]: this.moduleConfig.xHttpHeaderSkipVersion,
                        },
                    }),
                );
            }

            case BazaVersionNgStrategy.ProvideVersion: {
                return next.handle(
                    req.clone({
                        setHeaders: {
                            [this.moduleConfig.xHttpHeaderVersion]: this.moduleConfig.strategy.version,
                        },
                    }),
                );
            }
        }
    }
}
