import { IsString, IsNotEmpty, IsOptional, IsDefined, IsNotEmptyObject, ValidateIf, Length } from 'class-validator';
import { ControllerAddress, ControllerPassport } from './create-business-customer.request';

export class CreateBeneficialOwnerRequest {
    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @Length(9)
    @IsString()
    @IsOptional()
    ssn?: string;

    @IsString()
    @IsNotEmpty()
    dateOfBirth: string;

    @IsString()
    @IsNotEmpty()
    address: ControllerAddress;

    @IsDefined()
    @IsNotEmptyObject()
    @ValidateIf((item) => typeof item.ssn === 'undefined' || item.ssn === null)
    @IsOptional()
    passport?: ControllerPassport;
}
