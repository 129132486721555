import {
    BazaCommentListRequest,
    CrudListRequestQueryDto,
    CrudListResponseDto,
    BazaCommentsCreateRequest,
    BazaCommentsResponse,
    BazaCommentsListResponse,
} from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingsDto } from '../dto/baza-nc-integration-listings.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { Transform, Type } from 'class-transformer';

export enum BazaNcIntegrationListingsEndpointPaths {
    list = '/baza-nc-integration/listings/list',
    getById = '/baza-nc-integration/listings/getById/:id',
    getBySlug = '/baza-nc-integration/listings/getBySlug/:slug',
    getByOfferingId = '/baza-nc-integration/listings/getByOfferingId/:offeringId',
    commentList = '/baza-nc-integration/listings/comments/list',
    saveComment = '/baza-nc-integration/listings/comments/create',
}

export interface BazaNcIntegrationListingsEndpoint {
    list(
        request: BazaNcIntegrationListingsListRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsListResponse> | Observable<BazaNcIntegrationListingsListResponse>;

    getById(
        request: BazaNcIntegrationListingsGetByIdRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsDto> | Observable<BazaNcIntegrationListingsDto>;

    getBySlug(
        request: BazaNcIntegrationListingsGetBySlugRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsDto> | Observable<BazaNcIntegrationListingsDto>;

    getByOfferingId(
        request: BazaNcIntegrationListingsGetByOfferingIdRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsDto> | Observable<BazaNcIntegrationListingsDto>;

    saveComment(request: BazaCommentsCreateRequest, ...args: unknown[]): Promise<BazaCommentsResponse> | Observable<BazaCommentsResponse>;

    commentsList(request: BazaCommentListRequest): Promise<BazaCommentsListResponse> | Observable<BazaCommentsListResponse>;
}

export class BazaNcIntegrationListingsListRequest extends CrudListRequestQueryDto {
    @ApiModelProperty({
        description: 'Display favorites-only listings',
    })
    @IsBoolean()
    @IsOptional()
    @Type(() => Boolean)
    @Transform(({ value }) => {
        return ['true', '1', 'on'].includes((value || '').toString().toLowerCase());
    })
    isFavorite?: boolean;

    @ApiModelProperty({
        description: 'Will reverse the listing order from ASC to DESC',
    })
    @IsBoolean()
    @IsOptional()
    @Type(() => Boolean)
    @Transform(({ value }) => {
        return ['true', '1', 'on'].includes((value || '').toString().toLowerCase());
    })
    reverse?: boolean;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        description: 'Filter by Listings with specified Schema (Schema Title)',
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    @Type(() => String)
    @Transform(({ value }) => {
        if (value && typeof value === 'string' && value.trim().length > 0) {
            return value.split(',');
        } else {
            return undefined;
        }
    })
    schemas?: Array<string>;
}

export class BazaNcIntegrationListingsListResponse extends CrudListResponseDto<BazaNcIntegrationListingsDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationListingsDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationListingsDto>;
}

export class BazaNcIntegrationListingsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @Transform(({ value }) => parseInt(value, 10))
    id: number;
}

export class BazaNcIntegrationListingsGetBySlugRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    slug: string;
}

export class BazaNcIntegrationListingsGetByOfferingIdRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    offeringId: string;
}
