import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-mini',
    templateUrl: './footer-mini.component.html',
    styleUrls: ['./footer-mini.component.less'],
})
export class FooterMiniComponent {
    today = new Date();
}
