import { AttachmentImageSetDto } from '@scaliolabs/baza-core-shared';
import { BazaNcOfferingStatus, OfferingId } from '@scaliolabs/baza-nc-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationListingsBaseDto } from './baza-nc-integration-listings-base.dto';
import { TimelineDto } from '@scaliolabs/baza-content-types-shared';
import { BazaNcIntegrationSubscribeStatus } from '../../../../subscription/src/lib/models/baza-nc-integration-subscribe-status';
import { BazaNcIntegrationListingDocumentsDto } from './baza-nc-integration-listings-document.dto';

export class BazaNcIntegrationListingsDto extends BazaNcIntegrationListingsBaseDto {
    @ApiModelProperty({
        description: 'Is listing available?',
    })
    isPublished: boolean;

    @ApiModelProperty()
    cover: AttachmentImageSetDto;

    @ApiModelProperty({
        description: 'Images',
        type: AttachmentImageSetDto,
        isArray: true,
    })
    images: Array<AttachmentImageSetDto>;

    @ApiModelProperty({
        description: 'Timeline',
        type: TimelineDto,
        isArray: true,
    })
    timeline: Array<TimelineDto>;

    /**
     * Favorite
     */

    @ApiModelProperty({
        description: 'Is Listing added to favorites?',
    })
    isFavorite: boolean;

    @ApiModelProperty({
        description: 'Date added to favorite',
        required: false,
    })
    dateAddedToFavorite?: string;

    /**
     * Subscriptions
     */

    @ApiModelProperty({
        description: 'Subscription status',
        type: 'string',
        enum: Object.values(BazaNcIntegrationSubscribeStatus),
        required: false,
    })
    subscribeStatus?: BazaNcIntegrationSubscribeStatus;

    @ApiModelProperty({
        description: 'Is current user subscribed to notifications for this listing?',
    })
    isSubscribed: boolean;

    /**
     * These fields duplicate information from Offering DTO. It's requirement for web implementation
     */

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
        enum: Object.values(BazaNcOfferingStatus),
    })
    status: BazaNcOfferingStatus;

    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    offeringId: OfferingId;

    @ApiModelProperty({
        description: 'Price Per Share (as cents)',
    })
    pricePerShareCents: number;

    @ApiModelProperty({
        description: 'Number of shares available to purchase (total)',
    })
    numSharesAvailable: number;

    @ApiModelProperty({
        description: 'Total percents funded',
    })
    percentFunded: number;

    @ApiModelProperty({
        description: 'Total amount (as cents)',
    })
    totalSharesValueCents: number;

    @ApiModelProperty({
        description: 'Minimal number of shares to start purchase',
    })
    numSharesMin: number;

    @ApiModelProperty({
        description: 'List of document attachments.',
        isArray: true,
        type: BazaNcIntegrationListingDocumentsDto,
    })
    statements: Array<BazaNcIntegrationListingDocumentsDto>;

    @ApiModelProperty({
        description: 'List of documents.',
        isArray: true,
        type: BazaNcIntegrationListingDocumentsDto,
    })
    documents: Array<BazaNcIntegrationListingDocumentsDto>;
}
