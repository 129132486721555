import { Observable } from 'rxjs';
import { MktPageDto } from '../dto/mkt-page.dto';

export enum MktPagesEndpointPaths {
    get = '/baza-content-types/marketing/get',
}

export interface MktPageEndpoint {
    get(id: number): Promise<MktPageDto[]> | Observable<MktPageDto[]>;
}
