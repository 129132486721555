/* eslint-disable no-useless-escape */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BazaNcAccountVerificationDataAccess } from '@scaliolabs/baza-nc-data-access';
import { AccountVerificationDto, ListStatesResponseDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, EffectsUtil } from '@scaliolabs/baza-web-utils';
import { Observable, empty } from 'rxjs';
import { concat, tap } from 'rxjs/operators';

import { VFConfig, Verification, FormResources, PersonalInformation } from '../models';
import {
    ApplyInvestorProfile,
    ApplyPersonalInfo,
    ClearVerificationState,
    GetListStates,
    LoadFormResources,
    LoadVerification,
    SetVFLinksConfig,
    UploadDocument,
    UploadPersonalInfoSSNDocument,
} from './actions';
import { StoreOptions } from '@ngxs/store/src/symbols';

export interface VerificationStateModel {
    formResources: FormResources;
    listStates: ListStatesResponseDto;
    verification: Verification | null;
    vfConfig: VFConfig;
}

const initState: StoreOptions<VerificationStateModel> = {
    name: 'verification',
    defaults: {
        formResources: null,
        listStates: null,
        verification: null,
        vfConfig: null,
    },
};

const i18nBasePath = 'dwvf.vf.notifications';

@State<VerificationStateModel>(initState)
@Injectable()
export class VerificationState {
    constructor(
        private readonly dataAccess: BazaNcAccountVerificationDataAccess,
        private readonly datePipe: DatePipe,
        private readonly effectsUtil: EffectsUtil,
        private readonly wts: BazaWebUtilSharedService,
    ) {}

    @Selector()
    static formResources(state: VerificationStateModel): FormResources {
        return state.formResources;
    }

    @Selector()
    static listStates(state: VerificationStateModel): ListStatesResponseDto {
        return state.listStates;
    }

    @Selector()
    static personal(state: VerificationStateModel): PersonalInformation {
        return state.verification?.personalInformation;
    }

    @Selector()
    static verification(state: VerificationStateModel): Verification {
        return state.verification;
    }

    @Selector()
    static vfConfig(state: VerificationStateModel): VFConfig {
        return state.vfConfig;
    }

    @Action(ApplyPersonalInfo, { cancelUncompleted: true })
    ApplyPersonalInfo(ctx: StateContext<VerificationStateModel>, action: ApplyPersonalInfo): Observable<AccountVerificationDto> {
        const uploadRequest =
            !action.data.hasSsn && action.files && action.files.length > 0
                ? this.dataAccess.uploadPersonalInformationSSNDocument(action.files[0] as unknown as File)
                : empty();

        // transform date into NC date format
        const info = {
            ...action.data,
            dateOfBirth: this.datePipe.transform(action.data.dateOfBirth, 'MM-dd-yyyy'),
        };

        return this.dataAccess.applyPersonalInformation(info).pipe(
            concat(uploadRequest),
            tap((response: Verification) => {
                return ctx.patchState({ verification: this.parseDateFromNc(response) });
            }),
            this.effectsUtil.tryCatch$(this.wts.getI18nLabel(i18nBasePath, 'apply_personal_info_success'), ''),
        );
    }

    @Action(ApplyInvestorProfile, { cancelUncompleted: true })
    ApplyInvestorProfile(ctx: StateContext<VerificationStateModel>, action: ApplyInvestorProfile): Observable<AccountVerificationDto> {
        return this.dataAccess.applyInvestorProfile(action.data).pipe(
            tap((response: Verification) => {
                return ctx.patchState({ verification: this.parseDateFromNc(response) });
            }),
            this.effectsUtil.tryCatch$(this.wts.getI18nLabel(i18nBasePath, 'apply_investor_profile_success'), ''),
        );
    }

    @Action(ClearVerificationState, { cancelUncompleted: true })
    ClearVerificationState(ctx: StateContext<VerificationStateModel>): void {
        ctx.setState(initState.defaults);
    }

    @Action(GetListStates, { cancelUncompleted: true })
    GetListStates(ctx: StateContext<VerificationStateModel>, action: GetListStates): Observable<ListStatesResponseDto> {
        return this.dataAccess.listStates({ country: action.country }).pipe(
            tap((response: ListStatesResponseDto) => {
                return ctx.patchState({ listStates: response });
            }),
        );
    }

    @Action(LoadFormResources, { cancelUncompleted: true })
    LoadFormResources(ctx: StateContext<VerificationStateModel>): Observable<FormResources> {
        return this.dataAccess.formResources().pipe(
            tap((response) => {
                return ctx.patchState({ formResources: response });
            }),
        );
    }

    @Action(LoadVerification, { cancelUncompleted: true })
    LoadVerification(ctx: StateContext<VerificationStateModel>): Observable<Verification> {
        return this.dataAccess.index().pipe(
            tap((response: Verification) => {
                return ctx.patchState({ verification: this.parseDateFromNc(response) });
            }),
            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'load_verification_fail')),
        );
    }

    @Action(UploadDocument, { cancelUncompleted: true })
    UploadDocument(ctx: StateContext<VerificationStateModel>, action: UploadDocument): Observable<AccountVerificationDto> {
        return this.dataAccess.uploadPersonalInformationDocument(action.file as unknown as File).pipe(
            tap((response: Verification) => {
                return ctx.patchState({ verification: this.parseDateFromNc(response) });
            }),
            this.effectsUtil.tryCatch$(
                this.wts.getI18nLabel(i18nBasePath, 'upload_doc_success'),
                this.wts.getI18nLabel(i18nBasePath, 'upload_doc_fail'),
            ),
        );
    }

    @Action(UploadPersonalInfoSSNDocument, { cancelUncompleted: true })
    UploadPersonalInfoSSNDocument(
        ctx: StateContext<VerificationStateModel>,
        action: UploadPersonalInfoSSNDocument,
    ): Observable<AccountVerificationDto> {
        return this.dataAccess.uploadPersonalInformationSSNDocument(action.file as unknown as File).pipe(
            tap((response: Verification) => {
                return ctx.patchState({ verification: this.parseDateFromNc(response) });
            }),
            this.effectsUtil.tryCatch$(
                this.wts.getI18nLabel(i18nBasePath, 'upload_ssn_doc_success'),
                this.wts.getI18nLabel(i18nBasePath, 'upload_ssn_doc_fail'),
            ),
        );
    }

    // parse Date from NC format
    private parseDateFromNc(verification: Verification): Verification {
        const data = Object.assign({}, verification);

        if (data && data.personalInformation && data.personalInformation.dateOfBirth) {
            data.personalInformation = {
                ...data.personalInformation,
                dateOfBirth: data.personalInformation.dateOfBirth.replace(/\-/g, '/'),
            };
        }
        return data;
    }

    @Action(SetVFLinksConfig, { cancelUncompleted: true })
    SetVFLinksConfig(ctx: StateContext<VerificationStateModel>, action: SetVFLinksConfig): void {
        ctx.patchState({ vfConfig: action.vfConfig });
    }
}
