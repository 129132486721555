import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcOperationDto } from '../dto/baza-nc-operation.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BazaNcOperationType } from '../models/baza-nc-operation-type';
import { IsArray, IsEnum, IsOptional, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { TransactionState } from '../../../transaction';
import { BazaNcDividendPaymentStatus } from '../../../dividend/src';
import { BazaDwollaPaymentStatus } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcPayoffDistributionPaymentStatus } from '../../../payoffDistribution/src';

export enum BazaNcOperationEndpointPaths {
    list = '/baza-nc/operation/list',
    sumOperations = '/baza-nc/operation/sumOperations',
    stats = '/baza-nc/operation/stats',
}

export interface BazaNcOperationEndpoint {
    list(
        request: BazaNcOperationListRequest,
        ...args: unknown[]
    ): Promise<BazaNcOperationListItemsResponse> | Observable<BazaNcOperationListItemsResponse>;
    stats(...args: unknown[]): Promise<BazaNcOperationStatsResponse> | Observable<BazaNcOperationStatsResponse>;
    sumTransactions(
        request: BazaNcOperationListRequest,
        ...args: unknown[]
    ): Promise<BazaNcOperationSumResponse> | Observable<BazaNcOperationSumResponse>;
}

export class BazaNcOperationListRequest extends CrudListRequestQueryDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcOperationType),
        required: false,
        description: 'Filter by Operation Type. Should be passed as comma-separated string',
        example: `${BazaNcOperationType.Investment},${BazaNcOperationType.Withdraw}`,
    })
    @IsEnum(BazaNcOperationType, { each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    types?: Array<BazaNcOperationType>;

    @ApiModelProperty({
        type: 'string',
        required: false,
        isArray: true,
        description:
            'Filter Investments and Dividends by related Offering IDs.  Please note that this filter will be applied only to Investment and Dividend entries; any other entries will not be affected by this filter',
    })
    @IsString({ each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    offeringIds?: Array<string>;

    @ApiModelProperty({
        type: 'string',
        required: false,
        isArray: true,
        description: 'Filter Investments by corresponding schema names. this filter will be applied to all user investments',
    })
    @IsString({ each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    schema?: Array<string>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
        required: false,
        isArray: true,
        description:
            'Filter Investments by Transaction State. Please note that this filter will be applied only to Investment entries; any other entries will not be affected by this filter',
    })
    @IsEnum(TransactionState, { each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    transactionStates?: Array<TransactionState>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
        required: false,
        isArray: true,
        description:
            'Filter Dividends by Dividend Status. Please note that this filter will be applied only to Dividend entries; any other entries will not be affected by this filter',
    })
    @IsEnum(BazaNcDividendPaymentStatus, { each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    dividendStatuses?: Array<BazaNcDividendPaymentStatus>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        required: false,
        isArray: true,
        description:
            'Filter distributions by Payoff Status. Please note that this filter will be applied only to Payoff entries; any other entries will not be affected by this filter',
    })
    @IsEnum(BazaNcPayoffDistributionPaymentStatus, { each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    payOffDistributionStatuses?: Array<BazaNcPayoffDistributionPaymentStatus>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaPaymentStatus),
        required: false,
        isArray: true,
        description:
            'Filter Withdraw and Transfer by Dwolla Payment Status. Please note that this filter will be applied only to Withdraw and Transfer entries; any other entries will not be affected by this filter',
    })
    @IsEnum(BazaDwollaPaymentStatus, { each: true })
    @IsArray()
    @Type(() => String)
    @IsOptional()
    dwollaPaymentStatuses?: Array<BazaDwollaPaymentStatus>;
}

export class BazaNcOperationStatsEntry {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcOperationType),
        description: 'Operation Type',
        example: BazaNcOperationType.Investment,
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        type: 'number',
        description: 'Total number of Operations with given Operation Type',
        example: 0,
    })
    total: number;

    @ApiModelProperty({
        description: 'Offering IDs (for Investments or Dividends entries) available for given Operation Type',
        isArray: true,
        type: 'string',
        required: false,
    })
    offeringIds?: Array<string>;
}

export class BazaNcOperationStatsResponse {
    @ApiModelProperty({
        type: 'number',
        description: 'Total number of Operations',
        example: 0,
    })
    total: number;

    @ApiModelProperty({
        type: BazaNcOperationStatsEntry,
        isArray: true,
        description: 'Count Stats by Operation Type',
    })
    types: Array<BazaNcOperationStatsEntry>;

    @ApiModelProperty({
        description: 'Offering IDs with Investments or Dividends entries',
        isArray: true,
        type: 'string',
    })
    offeringIds: Array<string>;
}

export class BazaNcOperationListResponse extends CrudListResponseDto<BazaNcOperationDto> {
    @ApiModelProperty({
        type: BazaNcOperationDto,
        isArray: true,
    })
    items: Array<BazaNcOperationDto>;
}

export class BazaNcOperationListItemsResponse {
    @ApiModelProperty({
        type: BazaNcOperationDto,
        isArray: true,
    })
    items: Array<BazaNcOperationDto>;
}

export class BazaNcOperationSumResponse {
    @ApiModelProperty({
        description: 'Returns the sum of operations',
    })
    total: number;
}
