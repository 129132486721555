import { TestimonialDto } from './testimonial.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class TestimonialCmsDto extends TestimonialDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty({
        required: false,
    })
    imageS3AwsKey?: string;
}
