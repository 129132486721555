export enum BazaNcInvestorAccountErrorCodes {
    NcInvestorAccountNotFound = 'NcInvestorAccountNotFound',
    NcInvestorAccountIsNotAvailableForAccount = 'NcInvestorAccountIsNotAvailableForAccount',
    NcInvestorAccountUpdatePartyResidentialCityIsInvalid = 'NcInvestorAccountUpdatePartyResidentialCityIsInvalid',
    NcInvestorAccountUpdatePartyInvalidPayload = 'NcInvestorAccountUpdatePartyInvalidPayload',
}

export const bazaNcInvestorAccountErrorCodesEnI18n = {
    [BazaNcInvestorAccountErrorCodes.NcInvestorAccountNotFound]: 'Issuer account not found',
    [BazaNcInvestorAccountErrorCodes.NcInvestorAccountIsNotAvailableForAccount]: 'Investor account is not available yet',
    [BazaNcInvestorAccountErrorCodes.NcInvestorAccountUpdatePartyResidentialCityIsInvalid]: 'Please enter a valid residential city name.',
    [BazaNcInvestorAccountErrorCodes.NcInvestorAccountUpdatePartyInvalidPayload]:
        'invalid payload provided for updating investor party details.',
};
