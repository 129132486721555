import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsNotEmpty, IsString } from 'class-validator';

export class ListStatesResponseStateCmsDto {
    @ApiModelProperty({
        type: 'string',
    })
    title: string;

    @ApiModelProperty({
        type: 'string',
        description: 'State/Area. List of available states you may fetch with /list-states endpoint',
    })
    value: string;
}

export class ListStatesResponseCmsDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsNotEmpty()
    country: string;

    @ApiModelProperty({
        isArray: true,
        type: () => ListStatesResponseStateCmsDto,
        description: 'States/Areas',
    })
    @IsArray()
    @IsNotEmpty()
    states: Array<ListStatesResponseStateCmsDto>;
}
