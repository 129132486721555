import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazaWebUtilSharedService, MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { BulletsType } from './models/config';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
    selector: 'app-bullets',
    templateUrl: './bullets.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzGridModule],
})
export class BulletsComponent extends MktSectionCommonFields {
    @Input()
    public type: BulletsType = BulletsType.text;

    @Input()
    index = 0;

    constructor(public readonly wts: BazaWebUtilSharedService) {
        super();
    }

    public get isTextBullets() {
        return this.type === BulletsType.text;
    }

    public get i18nBasePath() {
        return `uic.marketing.bullets${this.isTextBullets ? '.textBullets' : '.imageBullets'}.section${this.index}`;
    }

    public get bullets() {
        const bulletsi18nPath = `${this.i18nBasePath}${this.isTextBullets ? '.bullets' : '.images'}`;
        return this.wts.getI18nLabel(bulletsi18nPath);
    }
}
