import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class StorageService {
    constructor(@Inject(PLATFORM_ID) private platformId, @Inject('LOCALSTORAGE') private storage: Storage) {}

    setItem(key: string, data: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.storage.setItem(key, data);
        }
    }

    setObject(key: string, object) {
        if (isPlatformBrowser(this.platformId)) {
            this.storage.setItem(key, JSON.stringify(object));
        }
    }

    getItem(key: string): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return this.storage.getItem(key);
        }
        return null;
    }

    getObjectSafe<T>(key: string): T | null {
        if (isPlatformBrowser(this.platformId)) {
            if (!this.hasKey(key)) {
                return {} as T;
            }
            try {
                return JSON.parse(this.storage.getItem(key));
            } catch (err) {
                return null;
            }
        }
        return null;
    }

    getObject<T>(key: string): T | null {
        if (isPlatformBrowser(this.platformId)) {
            if (!this.hasKey(key)) {
                return null;
            }
            try {
                return JSON.parse(this.storage.getItem(key));
            } catch (err) {
                return null;
            }
        }
        return null;
    }

    removeItem(key: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.storage.removeItem(key);
        }
    }

    hasKey(key: string): boolean {
        if (isPlatformBrowser(this.platformId)) {
            return !!this.storage.getItem(key);
        }
        return false;
    }
}
