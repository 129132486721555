import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { KYCStatus, NorthCapitalAccountId, PartyId } from '../../../transact-api';

export class KycLogDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    createdAt: string;

    @ApiModelProperty()
    userId: number;

    @ApiModelProperty()
    userEmail: string;

    @ApiModelProperty()
    userFullName: string;

    @ApiModelProperty()
    ncAccountId: NorthCapitalAccountId;

    @ApiModelProperty()
    ncPartyId: PartyId;

    @ApiModelProperty()
    previousKycStatus: KYCStatus;

    @ApiModelProperty()
    newKycStatus: KYCStatus;

    @ApiModelProperty()
    kycDetails: unknown;
}
