import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowCreditCardDto } from '../dto/baza-nc-purchase-flow-credit-card.dto';
import { BazaCreditCardMonth, BazaCreditCardType } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive, IsString, MinLength } from 'class-validator';

export enum BazaNcPurchaseFlowCreditCardEndpointPaths {
    setCreditCard = '/baza-nc/purchase-flow/credit-card/setCreditCard',
    getCreditCard = '/baza-nc/purchase-flow/credit-card/getCreditCard',
    deleteCreditCard = '/baza-nc/purchase-flow/credit-card/deleteCreditCard',
    validateCreditCard = '/baza-nc/purchase-flow/credit-card/validateCreditCard',
}

export interface BazaNcPurchaseFlowCreditCardEndpoint {
    setCreditCard(
        request: BazaNcPurchaseFlowSetCreditCardRequest,
        ...args: unknown[]
    ): Promise<BazaNcPurchaseFlowCreditCardDto> | Observable<BazaNcPurchaseFlowCreditCardDto>;

    getCreditCard(
        ...args: unknown[]
    ): Promise<BazaNcPurchaseFlowGetCreditCardResponse> | Observable<BazaNcPurchaseFlowGetCreditCardResponse>;

    deleteCreditCard(
        ...args: unknown[]
    ): Promise<BazaNcPurchaseFlowDeleteCreditCardResponse> | Observable<BazaNcPurchaseFlowDeleteCreditCardResponse>;

    validateCreditCard(
        request: BazaNcPurchaseFlowValidateCreditCardRequest,
    ): Promise<BazaNcPurchaseFlowValidateCreditCardResponse> | Observable<BazaNcPurchaseFlowValidateCreditCardResponse>;
}

export class BazaNcPurchaseFlowSetCreditCardRequest {
    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '12345567890123456',
    })
    @IsString()
    @IsNotEmpty()
    creditCardNumber: string;

    @ApiModelProperty({
        description: 'Credit Card Holder Name',
        example: 'JOHN DOE',
    })
    @IsString()
    @IsNotEmpty()
    creditCardholderName: string;

    @ApiModelProperty({
        type: 'number',
        description: 'Expire Month (1 - Jan, 2 - Feb, ..., 12 - Dec)',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    creditCardExpireMonth: BazaCreditCardMonth;

    @ApiModelProperty({
        type: 'number',
        description: 'Expire Year (21, 22, ...)',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    creditCardExpireYear: number;

    @ApiModelProperty({
        description: 'Credit Card CVV. CVV code will not be returned later from any existing endpoint!',
    })
    @MinLength(3)
    @IsString()
    @IsNotEmpty()
    creditCardCvv: string;
}

export class BazaNcPurchaseFlowGetCreditCardResponse {
    @ApiModelProperty({
        description: 'Is credit card available?',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        description: 'Credit Card Details',
        required: false,
    })
    creditCard?: BazaNcPurchaseFlowCreditCardDto;
}

export class BazaNcPurchaseFlowDeleteCreditCardResponse {
    @ApiModelProperty({
        description: 'Contains TRUE account had credit card before set',
    })
    isDeleted: boolean;
}

export class BazaNcPurchaseFlowValidateCreditCardRequest {
    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '12345567890123456',
    })
    @IsString()
    @IsNotEmpty()
    creditCardNumber: string;
}

export class BazaNcPurchaseFlowValidateCreditCardResponse {
    @ApiModelProperty({
        description: 'Is credit card valid?',
    })
    isValid: boolean;

    @ApiModelProperty({
        description: 'Credit Card Type',
        required: false,
        enum: Object.values(BazaCreditCardType),
        example: BazaCreditCardType.Visa,
    })
    creditCardType?: BazaCreditCardType;
}
