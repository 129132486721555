import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowTransactionType } from '../models/baza-nc-purchase-flow-transaction-type';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional } from 'class-validator';

export class ReProcessPaymentDto {
    @ApiModelProperty({
        type: 'number',
        description: 'Transaction Id',
        required: true,
    })
    @IsNumber()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        description: 'Transaction type. only passed when transaction type has changed',
        default: BazaNcPurchaseFlowTransactionType.ACH,
        required: false,
    })
    @IsEnum(BazaNcPurchaseFlowTransactionType)
    @IsOptional()
    transactionType?: BazaNcPurchaseFlowTransactionType;
}
