import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { BazaNcOfferingDto } from '../dto/baza-nc-offering.dto';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaNcOfferingDetailsDto } from '../dto/baza-nc-offering-details.dto';
import { BazaNcOfferingHealthCheckIssueDto } from '../dto/baza-nc-offering-health-check-issue.dto';
import { BazaNcOfferingListItemDto } from '../dto/baza-nc-offering-list-item.dto';

export enum BazaNcOfferingCmsEndpointPaths {
    list = '/baza-nc/offerings/list',
    listAll = '/baza-nc/offerings/listAll',
    getByNcOfferingId = '/baza-nc/offerings/getByNcOfferingId',
    ncOfferingDetails = '/baza-nc/offerings/ncOfferingDetails',
    syncAllOfferings = '/baza-nc/offerings/syncAllOfferings',
    syncOfferingTransactions = '/baza-nc/offerings/syncOfferingTransactions',
    getOfferingTransactionSyncStatus = '/baza-nc/offerings/getOfferingTransactionSyncStatus',
    importNcOffering = '/baza-nc/offerings/importNcOffering',
    forgetNcOffering = '/baza-nc/offerings/forgetNcOffering',
    healthCheck = '/baza-nc/offerings/healthCheck',
}

export interface BazaNcOfferingCmsEndpoint {
    list(request: NcOfferingListRequest): Promise<NcOfferingListResponse> | Observable<NcOfferingListResponse>;
    listAll(): Promise<Array<BazaNcOfferingListItemDto>> | Observable<Array<BazaNcOfferingListItemDto>>;
    getByNcOfferingId(request: NcOfferingGetByIdRequest): Promise<NcOfferingGetByIdResponse> | Observable<NcOfferingGetByIdResponse>;
    ncOfferingDetails(request: NcOfferingDetailsRequest): Promise<NcOfferingDetailsResponse> | Observable<NcOfferingDetailsResponse>;
    syncOfferingTransactions(
        request: NcSyncOfferingTransactionsRequest,
    ): Promise<NcSyncOfferingTransactionsResponse> | Observable<NcSyncOfferingTransactionsResponse>;
    getOfferingTransactionSyncStatus(
        request: NcGetOfferingTransactionsSyncStatusRequest,
    ): Promise<NcGetOfferingTransactionsSyncStatusResponse> | Observable<NcGetOfferingTransactionsSyncStatusResponse>;
    syncAllOfferings(): Promise<NcOfferingSyncAllOfferingsResponse> | Observable<NcOfferingSyncAllOfferingsResponse>;
    importNcOffering(request: NcOfferingImportRequest, ...args: unknown[]): Promise<BazaNcOfferingDto> | Observable<BazaNcOfferingDto>;
    forgetNcOffering(request: NcOfferingForgetRequest): Promise<void> | Observable<void>;
    healthCheck(request: NcOfferingHealthCheckRequest): Promise<NcOfferingHealthCheckResponse> | Observable<NcOfferingHealthCheckResponse>;
}

export class NcOfferingListRequest extends CrudListRequestDto<BazaNcOfferingDto> {}

export class NcOfferingListResponse extends CrudListResponseDto<BazaNcOfferingDto> {
    @ApiModelProperty({
        type: BazaNcOfferingDto,
        isArray: true,
    })
    items: Array<BazaNcOfferingDto>;
}

export class NcOfferingGetByIdRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export type NcOfferingGetByIdResponse = BazaNcOfferingDto;

export class NcOfferingDetailsRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export class NcSyncOfferingTransactionsRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export class NcGetOfferingTransactionsSyncStatusRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export type NcOfferingDetailsResponse = BazaNcOfferingDetailsDto;

export type NcSyncOfferingTransactionsResponse = void;

export type NcOfferingSyncAllOfferingsResponse = void;

export class NcGetOfferingTransactionsSyncStatusResponse {
    @ApiModelProperty({
        description: 'Total number of investor Accounts',
    })
    @IsNumber()
    @IsNotEmpty()
    totalNumberOfAccounts: number;

    @ApiModelProperty({
        description: 'the number of investor accounts whose trades are corrently being processed',
    })
    @IsNumber()
    @IsNotEmpty()
    progressByInvestorAccount: number;

    @ApiModelProperty({
        description: 'total number of trades being processed/sync',
    })
    @IsNumber()
    @IsNotEmpty()
    totalTradesCount: number;

    @ApiModelProperty({
        description: 'progress Percentage by investor account',
    })
    @IsString()
    @IsNotEmpty()
    percentageByInvestorAccount: string;

    @ApiModelProperty({
        description: 'date the process started',
    })
    startDate: string;

    @ApiModelProperty({
        description: 'date the syncing finished',
    })
    finishTimeSeconds: number;

    @ApiModelProperty({
        description: 'processed transactions count',
    })
    processedTransactionsCount: number;
}

export class NcOfferingImportRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export class NcOfferingForgetRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export class NcOfferingHealthCheckRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;
}

export class NcOfferingHealthCheckResponse {
    @ApiModelProperty({
        description: 'Is Offering Healthy?',
    })
    healthy: boolean;

    @ApiModelProperty({
        description: 'List of issues',
        type: BazaNcOfferingHealthCheckIssueDto,
        isArray: true,
    })
    issues: Array<BazaNcOfferingHealthCheckIssueDto>;
}
