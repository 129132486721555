import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class ListStatesResponseStateDto {
    @ApiModelProperty({
        type: 'string',
    })
    title: string;

    @ApiModelProperty({
        type: 'string',
        description: 'State/Area. List of available states you may fetch with /list-states endpoint',
    })
    value: string;
}

export class ListStatesResponseDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsNotEmpty()
    country: string;

    @ApiModelProperty({
        isArray: true,
        type: () => ListStatesResponseStateDto,
        description: 'States/Areas',
    })
    @IsArray()
    @ValidateNested()
    @Type(() => ListStatesResponseStateDto)
    @IsNotEmpty()
    states: Array<ListStatesResponseStateDto>;
}
