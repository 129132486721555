import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, finalize, of } from 'rxjs';
import { FormResources } from '../models';
import { LoadFormResources, VerificationState } from '../store';
import { LoaderService } from '@scaliolabs/baza-web-utils';

@Injectable({ providedIn: 'root' })
export class FormResourcesResolver implements Resolve<FormResources> {
    constructor(private readonly store: Store, private readonly loaderService: LoaderService) {}

    resolve(): Observable<FormResources> {
        this.loaderService.show();
        const resourcesData = this.store.selectSnapshot(VerificationState.formResources);

        if (resourcesData) {
            this.loaderService.hide();
            return of(resourcesData);
        } else {
            return resourcesData
                ? of(resourcesData)
                : this.store.dispatch(new LoadFormResources()).pipe(
                      finalize(() => {
                          this.loaderService.hide();
                      }),
                  );
        }
    }
}
