import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'priceNoRound',
})
// show price with cents only when there are cents
export class PriceNoRoundPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: string | number): string {
        return !isNaN(value as number) ? this.currencyPipe.transform(value, 'USD', '$', '0.0') : (value as string);
    }
}
