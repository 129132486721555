import { sharesSchemaDefinition, sharesType } from '../types/shares.type';
import { MoneyAsCents, moneyAsCentsSchemaDefinition } from '../types/money-as-cents.type';
import { DateType, dateTypeSchemaDefinition } from '../types/date.type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { OfferingId, TransactionState } from '@scaliolabs/baza-nc-shared';
import { BazaNcIntegrationPortfolioTransactionType } from '../models/baza-nc-integration-portfolio-transaction-type';
import { IsIn, IsInt, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';

export class BazaNcIntegrationPortfolioTransactionInvestmentEntityDto {
    @ApiModelProperty({
        description: 'Portfolio Id for transaction',
        example: 1,
    })
    @IsPositive()
    @IsInt()
    id: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Listing Title',
    })
    @IsString()
    name: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
        description: 'Transaction State',
    })
    @IsIn(Object.values(TransactionState))
    state: TransactionState;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationPortfolioTransactionType),
        description: 'Transaction Type',
    })
    @IsIn(Object.values(BazaNcIntegrationPortfolioTransactionType))
    type: BazaNcIntegrationPortfolioTransactionType;

    @ApiModelProperty({
        ...dateTypeSchemaDefinition(),
    })
    @IsString()
    createdAt: DateType;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Total Transaction Amount in cents',
        example: 50000,
    })
    @IsPositive()
    @IsNumber()
    amountCents: MoneyAsCents;

    @ApiModelProperty({
        description: 'Total (in U.S. cents)',
    })
    @IsPositive()
    @IsInt()
    totalCents: number;

    @ApiModelProperty({
        description: 'Price per shared (in U.S. cents)',
    })
    @IsPositive()
    @IsInt()
    pricePerShareCents: number;

    @ApiModelProperty({
        ...sharesSchemaDefinition(),
        description: 'Total Shares in Transaction',
        example: 1,
    })
    @IsPositive()
    @IsInt()
    shares: sharesType;

    @ApiModelProperty({
        description: 'Transaction fee (in U.S. cents)',
    })
    @IsPositive()
    @IsInt()
    transactionFeesCents: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering ID',
        required: false,
    })
    @IsString()
    @IsOptional()
    offeringId?: OfferingId;
}
