import { PagesCmsCreateRequest } from './endpoints/pages-cms.endpoint';

export class BazaContentTypeBootstrapPageRequest {
    /**
     * Unique SID for page
     */
    $sid: string;

    /**
     * Use updateId to force update page for deployed instances
     */
    $updateId: number;

    /**
     * CMS Create Page request
     */
    request: PagesCmsCreateRequest;
}

export class BazaContentTypePagesConfig {
    /**
     * Allows administrators to add / remove pages with CMS
     */
    allowModifyPagesList: boolean;

    /**
     * Maximum header image width
     */
    maxHeaderImageWidth: number;

    /**
     * Bootstrap pages on application bootstrap (use it instead of migrations).
     * You MUST provide pageUniqueSid for bootstrapped images.
     */
    bootstrapPages: Array<BazaContentTypeBootstrapPageRequest>;
}

export function bazaContentTypePagesDefaultConfig(): BazaContentTypePagesConfig {
    return {
        maxHeaderImageWidth: 720,
        allowModifyPagesList: true,
        bootstrapPages: [],
    };
}
