import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaE2eMailAttachmentDto {
    @ApiModelProperty({
        description: 'Filename of Attachment',
        example: 'report.csv',
    })
    filename: string;

    @ApiModelProperty({
        description: 'Contents (Raw)',
        example: `ID,Name\n1,Annnie\n2,Lulu\n3,Irelia`,
    })
    content: string;

    @ApiModelProperty({
        description: 'Content-Type',
        example: 'text/csv',
    })
    contentType: string;
}

export class BazaE2eMailDto {
    @ApiModelProperty({
        description: 'Subject',
    })
    subject: string;

    @ApiModelProperty({
        description: 'To',
        example: 'bar@scal.io',
        isArray: true,
    })
    to: Array<string>;

    @ApiModelProperty({
        description: 'Message Body (HTML)',
    })
    messageBodyHtml: string;

    @ApiModelProperty({
        description: 'Message Body (TEXT)',
    })
    messageBodyText: string;

    @ApiModelProperty({
        type: BazaE2eMailAttachmentDto,
        isArray: true,
        description: 'Attachments',
        required: false,
    })
    attachments?: Array<BazaE2eMailAttachmentDto>;
}
