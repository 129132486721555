import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BazaAccountDataAccess } from '@scaliolabs/baza-core-data-access';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import {
    AccountInit,
    AccountUnset,
    ConfirmEmail,
    DeactivateAccount,
    RegisterAccount,
    ResendConfirmation,
    ResetPassword,
    SendDeactivateAccountLink,
    SendResetPasswordLink,
    UpdateAccount,
    UpdatePassword,
} from './actions';
import { DEACTIVATE_ACCOUNT_FAIL, DEACTIVATE_ACCOUNT_SUCCESS, RESEND_CONFIRMATION_SUCCESS } from './messages';
import { Account } from '../../models';

export interface StateModel {
    account: Account;
}

@State<StateModel>({
    name: 'account',
    defaults: {
        account: null,
    },
})
@Injectable()
export class AccountState {
    @Selector()
    static account(state: StateModel) {
        return state.account;
    }

    constructor(private dataAccess: BazaAccountDataAccess, private effectsUtil: EffectsUtil) {}

    @Action(AccountInit)
    accountInit(ctx: StateContext<StateModel>) {
        return this.dataAccess.currentAccount().pipe(
            tap((account) => {
                ctx.patchState({ account: account });
            }),
        );
    }

    @Action(AccountUnset)
    accountUnset(ctx: StateContext<StateModel>) {
        ctx.patchState({ account: undefined });
    }

    @Action(ConfirmEmail)
    confirmEmail(_: StateContext<StateModel>, action: ConfirmEmail) {
        return this.dataAccess.confirmEmail(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(DeactivateAccount, { cancelUncompleted: true })
    deactivateAccount(_: StateContext<void>, action: DeactivateAccount) {
        return this.dataAccess
            .deactivateAccount(action.data)
            .pipe(this.effectsUtil.tryCatch$(DEACTIVATE_ACCOUNT_SUCCESS, DEACTIVATE_ACCOUNT_FAIL));
    }

    @Action(RegisterAccount)
    registerAccount(_: StateContext<StateModel>, action: RegisterAccount) {
        return this.dataAccess.registerAccount(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(ResendConfirmation)
    resendConfirmation(_: StateContext<StateModel>, action: ResendConfirmation) {
        return this.dataAccess.sendConfirmEmailLink(action.data).pipe(this.effectsUtil.tryCatchSuccess$(RESEND_CONFIRMATION_SUCCESS));
    }

    @Action(ResetPassword)
    resetPassword(_: StateContext<StateModel>, action: ResetPassword) {
        return this.dataAccess.resetPassword(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(SendDeactivateAccountLink)
    sendDeactivateAccountLink(_: StateContext<StateModel>, action: SendDeactivateAccountLink) {
        return this.dataAccess.sendDeactivateAccountLink(action.data);
    }

    @Action(SendResetPasswordLink)
    sendResetPasswordLink(_: StateContext<StateModel>, action: SendResetPasswordLink) {
        return this.dataAccess.sendResetPasswordLink(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(UpdateAccount)
    updateAccount(ctx: StateContext<StateModel>, action: UpdateAccount) {
        return this.dataAccess.updateProfile(action.data).pipe(
            tap((account) => ctx.patchState({ account: account })),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(UpdatePassword)
    updatePassword(_: StateContext<StateModel>, action: UpdatePassword) {
        return this.dataAccess.changePassword(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }
}
