import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { I18nLinkConfig } from '../../models/i18n-links-interface';
import { BazaLinkUtilSharedService } from '../../services';

@Directive({
    selector: '[appLinkify]',
})
export class I18nLinkifyDirective implements AfterViewInit, OnDestroy {
    @Input()
    linkConfigPath: string;

    // Reference article: https://medium.com/ngconf/reapplying-an-angular-directive-on-dom-changes-6cce7c390896
    private observer = new MutationObserver(() => this.handleDynamicLinks());
    private processing = false;

    constructor(
        private readonly renderer: Renderer2,
        private readonly element: ElementRef<HTMLElement>,
        private readonly uts: BazaLinkUtilSharedService,
    ) {}

    ngAfterViewInit() {
        this.handleDynamicLinks();
        this.registerListenerForDomChanges();
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }

    handleDynamicLinks() {
        if (!this.processing) {
            this.processing = true;
            const thisEl = this.element.nativeElement;
            const linkConfigs: Array<I18nLinkConfig> = this.uts.getI18nLinkConfigs(this.linkConfigPath);

            linkConfigs.forEach((linkConfig: I18nLinkConfig) => {
                const linkNode = thisEl.querySelector('[data-link="' + linkConfig.key + '"]') as HTMLElement;
                if (linkNode) {
                    const newLinkNode = this.createNewLinkNode(linkConfig, linkNode);
                    if (newLinkNode) {
                        thisEl.replaceChild(newLinkNode, linkNode);
                    }
                }
            });

            this.processing = false;
        }
    }

    private createNewLinkNode(linkConfig: I18nLinkConfig, linkNode?: HTMLElement) {
        const newLinkNode = this.renderer.createElement('a') as HTMLAnchorElement;
        newLinkNode.className = 'linkify';

        // assign custom data attributes
        Object.keys(linkNode?.dataset)?.forEach((attributeKey) => {
            const attributeVal = linkNode?.dataset[attributeKey];
            if (attributeVal) {
                newLinkNode.setAttribute(`data-${attributeKey}`, attributeVal);
            }
        });
        newLinkNode.textContent = this.uts.getUrlText(linkConfig.link);

        newLinkNode.addEventListener('click', () => {
            this.uts.navigate(linkConfig.link);
        });

        return newLinkNode;
    }

    private registerListenerForDomChanges() {
        const attributes = false;
        const childList = true;
        const subtree = true;
        this.observer.observe(this.element.nativeElement, { attributes, childList, subtree });
    }
}
