import { MarketingPagesCmsCreateRequest } from './endpoints/mkt-page-cms.endpoint';

export class BazaContentTypeBootstrapMktPageRequest {
    /**
     * Unique SID for page
     */
    $sid: string;

    /**
     * Use updateId to force update page for deployed instances
     */
    $updateId: number;

    /**
     * CMS Create Page request
     */
    request: MarketingPagesCmsCreateRequest;
}

export class BazaContentTypeMktConfig {
    /**
     * Allows administrators to add / remove pages with CMS
     */
    allowModifyPagesList: boolean;

    /**
     * Maximum header image width
     */
    maxHeaderImageWidth: number;

    /**
     * Bootstrap pages on application bootstrap (use it instead of migrations).
     * You MUST provide pageUniqueSid for bootstrapped images.
     */
    bootstrapPages: Array<BazaContentTypeBootstrapMktPageRequest>;
}

export function bazaContentTypeMktPageConfig(): BazaContentTypeMktConfig {
    return {
        maxHeaderImageWidth: 720,
        allowModifyPagesList: true,
        bootstrapPages: [],
    };
}
