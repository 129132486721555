import { PageDto } from './page.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class PageCmsDto extends PageDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    headerImageAwsS3Key: string;
}
