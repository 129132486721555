import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { PageDto, PagesEndpoint, PagesEndpointPaths } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class PagesDataAccess implements PagesEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    get(url: string): Observable<PageDto> {
        return this.http.get(replacePathArgs(PagesEndpointPaths.get, { url }));
    }

    getById(id: number): Observable<PageDto> {
        return this.http.get(replacePathArgs(PagesEndpointPaths.getById, { id }));
    }
}
