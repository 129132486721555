import { Domicile } from '../models/domicile';
import { EntityType } from '../models/entity-type';
import { NorthCapitalDate, NorthCapitalYesNoBoolean } from '../common-types';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class CreateEntityRequest {
    @IsEnum(Domicile)
    @IsNotEmpty()
    domicile: Domicile;

    @IsString()
    @IsNotEmpty()
    entityName: string;

    @IsEnum(EntityType)
    @IsOptional()
    entityType?: EntityType;

    @IsString()
    @IsOptional()
    entityDesc?: string;

    @IsString()
    @IsOptional()
    ein?: string;

    @IsString()
    @IsNotEmpty()
    primCountry: string;

    @IsString()
    @IsNotEmpty()
    primAddress1: string;

    @IsString()
    @IsOptional()
    primAddress2?: string;

    @IsString()
    @IsNotEmpty()
    primCity: string;

    @IsString()
    @IsNotEmpty()
    primState: string;

    @IsString()
    @IsNotEmpty()
    primZip: string;

    @IsString()
    @IsNotEmpty()
    emailAddress: string;

    @IsString()
    @IsOptional()
    emailAddress2?: string;

    @IsInt()
    @IsOptional()
    phone?: number;

    @IsInt()
    @IsOptional()
    phone2?: number;

    @IsInt()
    @IsOptional()
    totalAssets?: number;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    ownersAI?: NorthCapitalYesNoBoolean;

    @IsEnum(KYCStatus)
    @IsOptional()
    KYCstatus?: KYCStatus;

    @IsEnum(AmlStatus)
    @IsOptional()
    AMLstatus?: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    tags?: string;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsString()
    @IsOptional()
    formationDate?: NorthCapitalDate;
}
