export enum AccountVerificationStep {
    PhoneNumber = 'PhoneNumber',
    LegalName = 'LegalName',
    DateOfBirth = 'DateOfBirth',
    SSN = 'SSN',
    RequestSSNDocument = 'RequestSSNDocument',
    Citizenship = 'Citizenship',
    ResidentialAddress = 'ResidentialAddress',
    RequestDocuments = 'RequestDocuments',
    InvestorProfile = 'InvestorProfile',
    Completed = 'Completed',
}
