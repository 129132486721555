import { Injectable } from '@angular/core';
import type {
    ChangePasswordRequest,
    ChangePasswordResponse,
    RegisterAccountRequest,
    RegisterAccountResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
    AccountEndpoint,
    ConfirmEmailRequest,
    ConfirmEmailResponse,
    CurrentAccountResponse,
    SendConfirmEmailLinkRequest,
    SendConfirmEmailLinkResponse,
    SendResetPasswordLinkRequest,
    SendResetPasswordLinkResponse,
    SetAccountSettingsRequest,
    SetAccountSettingsResponse,
    UpdateProfileResponse,
    ChangeEmailResponse,
    AccountExistsRequest,
    AccountExistsResponse,
} from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import {
    AccountEndpointPaths,
    BazaAccountRegisterOptions,
    ChangeEmailRequest,
    DeactivateAccountRequest,
    SendChangeEmailLinkRequest,
    SendDeactivateAccountLinkRequest,
    UpdateProfileRequest,
} from '@scaliolabs/baza-core-shared';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaAccountDataAccess implements AccountEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    changePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
        return this.http.post(AccountEndpointPaths.changePassword, request);
    }

    confirmEmail(request: ConfirmEmailRequest): Observable<ConfirmEmailResponse> {
        return this.http.post(AccountEndpointPaths.confirmEmail, request);
    }

    currentAccount(): Observable<CurrentAccountResponse> {
        return this.http.get(AccountEndpointPaths.currentAccount);
    }

    registerAccount(request: RegisterAccountRequest): Observable<RegisterAccountResponse> {
        return this.http.post(AccountEndpointPaths.registerAccount, request);
    }

    registerAccountOptions(): Observable<BazaAccountRegisterOptions> {
        return this.http.get(AccountEndpointPaths.registerAccountOptions);
    }

    resetPassword(request: ResetPasswordRequest): Observable<ResetPasswordResponse> {
        return this.http.post(AccountEndpointPaths.resetPassword, request);
    }

    sendConfirmEmailLink(request: SendConfirmEmailLinkRequest): Observable<SendConfirmEmailLinkResponse> {
        return this.http.post(AccountEndpointPaths.sendConfirmEmailLink, request);
    }

    sendResetPasswordLink(request: SendResetPasswordLinkRequest): Observable<SendResetPasswordLinkResponse> {
        return this.http.post(AccountEndpointPaths.sendResetPasswordLink, request);
    }

    sendChangeEmailLink(request: SendChangeEmailLinkRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.sendChangeEmailLink, request, {
            responseType: 'text',
        });
    }

    changeEmail(request: ChangeEmailRequest): Observable<ChangeEmailResponse> {
        return this.http.post<ChangeEmailResponse>(AccountEndpointPaths.changeEmail, request);
    }

    updateSettings(request: SetAccountSettingsRequest): Observable<SetAccountSettingsResponse> {
        return this.http.post(AccountEndpointPaths.updateAccountSettings, request);
    }

    updateProfile(request: UpdateProfileRequest): Observable<UpdateProfileResponse> {
        return this.http.post(AccountEndpointPaths.updateProfile, request);
    }

    sendDeactivateAccountLink(request: SendDeactivateAccountLinkRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.sendDeactivateAccountLink, request);
    }

    deactivateAccount(request: DeactivateAccountRequest): Observable<void> {
        return this.http.post(AccountEndpointPaths.deactivateAccount, request);
    }

    accountExists(request: AccountExistsRequest): Observable<AccountExistsResponse> {
        return this.http.post(AccountEndpointPaths.accountExists, request);
    }
}
