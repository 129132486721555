import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'numberTwoDigits',
})
export class NumberTwoDigitsPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: string | number): string {
        return this.decimalPipe.transform(value, '1.2-2');
    }
}
