import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPayoffDistributionPaymentSource } from '../models/baza-nc-payoff-distribution-payment-source';
import { ulid } from 'ulid';
import { BazaNcInvestorAccountDto } from '../../../../investor-account';
import { BazaNcPayoffDistributionPaymentStatus } from '../models/baza-nc-payoff-distribution-payment-status';
import { OfferingId } from '../../../../transact-api';
import { DwollaTransferId } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcPayoffDistributionFailureReasonDto } from './baza-nc-payoff-distribution-failure-reason.dto';

export class BazaNcPayoffDistributionTransactionEntryDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: new Date().toISOString(),
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: new Date().toISOString(),
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: new Date().toISOString(),
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcPayoffDistributionPaymentSource),
    })
    source: BazaNcPayoffDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaNcInvestorAccountDto;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        example: BazaNcPayoffDistributionPaymentStatus.Pending,
    })
    status: BazaNcPayoffDistributionPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        description: 'Can be updated (edited)?',
    })
    canBeUpdated: boolean;

    @ApiModelProperty({
        description: 'Can be deleted (edited)?',
    })
    canBeDeleted: boolean;

    @ApiModelProperty({
        description: 'Failure Reason (for Failed PayoffDistributions only)',
    })
    failureReason?: BazaNcPayoffDistributionFailureReasonDto;

    @ApiModelProperty({
        type: 'string',
        description: 'Dwolla Transfer ID (for Dwolla PayoffDistributions)',
        required: false,
    })
    dwollaTransferId?: DwollaTransferId;
}
