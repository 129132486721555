import { Observable } from 'rxjs';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { FaqDto } from '../dto/faq.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export enum FaqCmsEndpointPaths {
    list = '/baza-content-types/cms/faq/list',
    create = '/baza-content-types/cms/faq/create',
    update = '/baza-content-types/cms/faq/update',
    remove = '/baza-content-types/cms/faq/remove',
    getById = '/baza-content-types/cms/faq/getById',
    setSortOrder = '/baza-content-types/cms/faq/setSortOrder',
}

export interface FaqCmsEndpoint {
    list(request: FaqCmsListRequest): Promise<FaqCmsListResponse> | Observable<FaqCmsListResponse>;
    create(request: FaqCmsCreateRequest): Promise<FaqCmsCreateResponse> | Observable<FaqCmsCreateResponse>;
    update(request: FaqCmsUpdateRequest): Promise<FaqCmsUpdateResponse> | Observable<FaqCmsUpdateResponse>;
    remove(request: FaqCmsRemoveRequest): Promise<FaqCmsRemoveResponse> | Observable<FaqCmsRemoveResponse>;
    getById(request: FaqCmsGetByIdRequest): Promise<FaqCmsGetByIdResponse> | Observable<FaqCmsGetByIdResponse>;
    setSortOrder(request: FaqCmsSetSortOrderRequest): Promise<FaqCmsSetSortOrderResponse> | Observable<FaqCmsSetSortOrderResponse>;
}

export class FaqCmsListRequest extends CrudListRequestDto<FaqDto> {}

export class FaqCmsListResponse extends CrudListResponseDto<FaqDto> {}

export class BazaContentTypesFaqEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    categoryId?: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    question: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    answer: string;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class FaqCmsCreateRequest extends BazaContentTypesFaqEntityBody {}
export type FaqCmsCreateResponse = FaqDto;

export class FaqCmsUpdateRequest extends BazaContentTypesFaqEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FaqCmsUpdateResponse = FaqDto;

export class FaqCmsRemoveRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FaqCmsRemoveResponse = void;

export class FaqCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type FaqCmsGetByIdResponse = FaqDto;

export class FaqCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}
export class FaqCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<FaqDto> {}
