import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaContentDto } from '../../../../../shared/src';
import { CategoryListDto } from '../../../../categories/src';
import { BazaSeoDto } from '@scaliolabs/baza-core-shared';

export class PageDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    primaryCategory: CategoryListDto;

    @ApiModelProperty({
        type: CategoryListDto,
        isArray: true,
    })
    additionalCategories: Array<CategoryListDto>;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    url: string;

    @ApiModelProperty({
        required: false,
    })
    headerImageUrl?: string;

    @ApiModelProperty()
    contents: BazaContentDto;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
