import { JobDto } from './job.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class JobApplicationDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty()
    job: JobDto;

    @ApiModelProperty()
    firstName: string;

    @ApiModelProperty()
    lastName: string;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty({
        required: false,
    })
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    phone?: string;
}
