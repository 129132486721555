import { CustodialAccountRequestId } from '../models/custodial-account-request-id';
import { CustodialAccountRequestStatus } from '../models/custodial-account-request-status';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

export class UpdateCustodialAccountRequestRequest {
    @IsNotEmpty()
    @IsString()
    custAccRequestID: CustodialAccountRequestId;

    @IsEnum(CustodialAccountRequestStatus)
    @IsString()
    custAccRequestStatus: CustodialAccountRequestStatus;

    @IsNotEmpty()
    @IsString()
    notes: string;

    @IsNotEmpty()
    @IsString()
    updatedIpAddress: NorthCapitalIpAddress;
}
