import { IsArray, IsDefined, IsNotEmptyObject, IsOptional } from 'class-validator';
import { DwollaAmount } from '../models/dwolla-amount';
import { DwollaTransferLinks } from '../models/dwolla-transfer-links';
import { DwollaFacilitatorFee } from '../models/dwolla-facilitator-fee';
import { DwollaClearing } from '../models/transfer-details';
import { DwollaACHDetails } from '../models/dwolla-ach-details';
import { DwollaRTPDetails } from '../models/dwolla-rtp-details';
import { DwollaProcessingChannel } from '../models/dwolla-processing-channel';

export class CreateTransferRequest {
    @IsDefined()
    @IsNotEmptyObject()
    _links: DwollaTransferLinks;

    @IsDefined()
    @IsNotEmptyObject()
    amount: DwollaAmount;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    metadata?: {
        [key: string]: string;
    };

    @IsArray()
    @IsOptional()
    fees?: Array<DwollaFacilitatorFee>;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    clearing?: DwollaClearing;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    achDetails?: DwollaACHDetails;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    rtpDetails?: DwollaRTPDetails;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    correlationId?: string;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    processingChannel?: DwollaProcessingChannel;
}
