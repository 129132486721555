import { IsInt, IsOptional, IsPositive } from 'class-validator';

export class GetLabelListFilterRequest {
    @IsPositive()
    @IsInt()
    @IsOptional()
    limit?: number;

    @IsPositive()
    @IsInt()
    @IsOptional()
    offset?: number;
}
