export const WebUiRegCfConsentEnI18n = {
    title: `Investment Consent`,
    agreement: `I hereby represent and warrant that, with respect to making, or the decision to make, an investment in any security made available through this Site:`,
    clauses: [
        {
            label: `I agree to the <a data-link="tosLink"></a> and <a data-link="privacyLink"></a>`,
            linkConfig: [
                {
                    key: 'tosLink',
                    link: {
                        extLink: {
                            link: 'bazaCommon.links.termsOfService',
                            isCMSLink: true,
                            text: 'Terms of Service',
                        },
                    },
                },
                {
                    key: 'privacyLink',
                    link: {
                        extLink: {
                            link: 'bazaCommon.links.privacyPolicy',
                            isCMSLink: true,
                            text: 'Privacy Policy',
                        },
                    },
                },
            ],
        },
        {
            label: `I have obtained, reviewed, and understand <a data-link="eduMaterialsLink"></a>`,
            linkConfig: [
                {
                    key: `eduMaterialsLink`,
                    link: {
                        extLink: {
                            link: `https://www.google.com`,
                            text: `Baza's Educational Materials`,
                        },
                    },
                },
            ],
        },
        {
            label: `I understand and acknowledge that this investment involves a high degree of risk, including a substantial risk of loss, and that I should not participate in the investment unless I can afford to lose the entire amount of my investment`,
        },
    ],
    cta: {
        text: `Continue`,
        btnClass: `ant-btn-primary ant-btn-lg`,
    },
};
