import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CategoryListDto } from './category-list.dto';

export class CategoriesDto {
    @ApiModelProperty()
    level: number;

    @ApiModelProperty({
        required: false,
    })
    category?: CategoryListDto;

    @ApiModelProperty({
        type: CategoriesDto,
        isArray: true,
    })
    children: Array<CategoriesDto>;
}
