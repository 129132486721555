export const bazaSeoKnownMetaTags: Array<string> = [
    'keywords',
    'description',
    'subject',
    'copyright',
    'language',
    'robots',
    'revised',
    'abstract',
    'topic',
    'summary',
    'classification',
    'author',
    'designer',
    'copyright',
    'reply-to',
    'owner',
    'url',
    'identifier-URL',
    'directory',
    'category',
    'coverage',
    'distribution',
    'rating',
    'revisit-after',
    'apple-mobile-web-app-capable',
    'apple-touch-fullscreen',
    'apple-mobile-web-app-status-bar-style',
    'format-detection',
    'viewport',
    'apple-mobile-web-app-status-bar-style',
    'format-detection',
];
