import { IsDateString, IsInt, IsOptional, IsPositive } from 'class-validator';

export class GetWebhookListForWebhookSubscriptionRequest {
    @IsPositive()
    @IsInt()
    @IsOptional()
    limit?: number;

    @IsPositive()
    @IsInt()
    @IsOptional()
    offset?: number;

    @IsDateString()
    @IsOptional()
    startDate?: string;

    @IsDateString()
    @IsOptional()
    endDate?: string;
}
