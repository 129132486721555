import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'priceCents',
})
// always show price with cents
export class PriceCentsPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: string | number): string {
        return this.currencyPipe.transform(value, 'USD', '$', '0.2-2');
    }
}
