import { BazaNcTaxDocumentDto } from './baza-nc-tax-document.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcTaxDocumentCmsDto extends BazaNcTaxDocumentDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    fileS3Key: string;
}
