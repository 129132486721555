import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { LoaderComponent } from './loader.component';
import { LoaderService } from './loader.service';

@NgModule({
    imports: [CommonModule, LayoutModule, NzSpinModule, OverlayModule],
    declarations: [LoaderComponent],
    exports: [LoaderComponent],
    providers: [LoaderService],
})
export class LoaderModule {}
