import { PercentsIntegerType } from '../types/percents-integer.type';
import { MoneyAsCents, moneyAsCentsSchemaDefinition } from '../types/money-as-cents.type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcIntegrationPortfolioTotalStatsDto {
    @ApiModelProperty({
        type: 'number',
        description: 'Total number of share holdings.',
    })
    totalHoldings: number;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Total holdings purchase value in cents.',
    })
    totalHoldingAmountCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Total current value in cents.',
    })
    totalHoldingCurrentValueCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Total return value in cents.',
    })
    totalReturnValueCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Total Return in Percentage.',
    })
    totalReturnPercent: PercentsIntegerType;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        description: 'Dividends',
    })
    totalDividendCents: MoneyAsCents;
}
