import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Min } from 'class-validator';

export class CalculateRegulationLimitsRequestDto {
    @ApiModelProperty({
        description: 'Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    offeringId: string;

    @ApiModelProperty({
        description: 'boolean value to tell api to save the calculations or not',
    })
    @IsBoolean()
    @IsNotEmpty()
    saveInput: boolean;

    @ApiModelProperty({
        description:
            'Annual income In cents for the investor , entered by the investor himself, in order to work both yearlyIncome and netWorth should be present in request',
        required: false,
    })
    @IsPositive({ message: 'Yearly income must be a positive number' })
    @IsInt()
    @IsOptional()
    @Min(0)
    yearlyIncomeCents?: number;

    @ApiModelProperty({
        description:
            'Annual Household income In cents for the investor , entered by the investor himself, in order to work yearlyIncome OR houseHoldIncome and netWorth should be present in request',
        required: false,
    })
    @IsPositive({ message: 'Household income must be a positive number' })
    @IsInt()
    @IsOptional()
    @Min(0)
    currentHouseholdIncomeCents?: number;

    @ApiModelProperty({
        description:
            'current Net worth value  In cents for the investor , entered by the investor himself, in order to work both netWorth and yearlyIncome should be present in request',
        required: false,
    })
    @IsPositive({ message: 'Net worth must be a positive number' })
    @IsInt()
    @IsOptional()
    @Min(0)
    netWorthCents?: number;

    @ApiModelProperty({
        description: 'Amount of external RegCf investments for the user  In cents , specific to regCF investment ',
        required: false,
    })
    @IsInt()
    @IsOptional()
    @Min(0)
    externalRegCfInvestmentsCents?: number;
}
