<div class="retry-list">
    <h4 class="retry-list__title">Payment Method</h4>

    <div
        class="retry-list__methods"
        [class.retry-list__methods-single]="!hasMultiplePaymentMethods">
        <ng-container *ngIf="hasBankAccount">
            <button
                class="payment-btn"
                nz-button
                nzBlock
                [ngClass]="{ 'payment-btn-borderless': !hasMultiplePaymentMethods }"
                (click)="onBankAccountSelect()">
                <div class="retry-list-details">
                    <span class="retry-list-details__title">{{ bankAccountResponse?.details?.accountName }}</span>
                    <span class="retry-list-details__description">{{ bankAccountResponse?.details?.accountType }}</span>
                </div>

                <div
                    class="payment-arrow"
                    *ngIf="hasMultiplePaymentMethods">
                    <app-payment-radio [isSelected]="selectedPaymentMethod === PAYMENT_METHOD_TYPE.bankAccount"></app-payment-radio>
                </div>
            </button>
        </ng-container>

        <ng-container *ngIf="hasCreditCard">
            <button
                class="payment-btn"
                nz-button
                nzBlock
                [ngClass]="{ 'payment-btn-borderless': !hasMultiplePaymentMethods }"
                (click)="onCreditCardSelect()">
                <div class="retry-list-details">
                    <span class="retry-list-details__title">
                        {{ creditCardResponse?.card?.cardType }} {{ creditCardResponse?.card?.creditCardNumber | lastDigits : 8 }}
                    </span>

                    <span class="retry-list-details__fee">{{ formattedCardPaymentFee }}</span>
                </div>

                <div
                    class="payment-arrow"
                    *ngIf="hasMultiplePaymentMethods">
                    <app-payment-radio [isSelected]="selectedPaymentMethod === PAYMENT_METHOD_TYPE.creditCard"></app-payment-radio>
                </div>
            </button>
        </ng-container>
    </div>
</div>
