import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaAuthNgConfig } from './baza-auth-ng.config';
import { JwtVerifyGuard } from './guards/jwt-verify.guard';
import { BazaAuthDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { JwtRequireUserGuard } from './guards/jwt-require-user.guard';
import { JwtRequireAdminGuard } from './guards/jwt-require-admin.guard';
import { JwtRequireRoleGuard } from './guards/jwt-require-role.guard';
import { JwtRequireAclGuard } from './guards/jwt-require-acl.guard';
import { AuthValidatorsService } from './services/auth-validators.service';
import { BazaNgCoreModule } from '../../baza-common/lib/baza-ng-core.module';
import { JwtRequireNoAuthGuard } from './guards/jwt-require-no-auth.guard';
import { JwtRequireAuthGuard } from './guards/jwt-require-auth.guard';

interface ForRootOptions {
    deps?: Array<unknown>;
    useFactory: (...deps) => BazaAuthNgConfig;
}

export { ForRootOptions as BazaAuthNgModuleForRootOptions };

/**
 * BazaAuthNgModule minimal set of shared services, guards and interceptors which
 * you need to implement Authentication in your application.
 *
 * @see JwtVerifyGuard
 * @see JwtRequireAdminGuard
 * @see JwtRequireUserGuard
 * @see JwtRequireAclGuard
 * @see JwtService
 *
 * @example
 *
 * Default configuration can be replaced with bazaWebBundleConfigBuilder helper.
 *
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               //Configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 */

// @dynamic
@NgModule({
    imports: [BazaNgCoreModule, BazaAuthDataAccessModule],
    providers: [
        JwtVerifyGuard,
        JwtRequireAdminGuard,
        JwtRequireRoleGuard,
        JwtRequireUserGuard,
        JwtRequireAclGuard,
        JwtRequireAuthGuard,
        JwtRequireNoAuthGuard,
        AuthValidatorsService,
    ],
})
export class BazaAuthNgModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaAuthNgModule> {
        return {
            ngModule: BazaAuthNgModule,
            providers: [
                {
                    provide: BazaAuthNgConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
