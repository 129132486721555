<div
    class="container check-account"
    *ngIf="result$ | async; else skeleton">
    <ng-container *ngIf="showDisclaimer">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="check-account__col">
                <div class="check-account__box">
                    <div class="check-account__icon">
                        <svg-icon-sprite src="icons-avatar"></svg-icon-sprite>
                    </div>

                    <ng-container *ngIf="!hasStarted">
                        <div
                            class="check-account__title"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'newVf.title') | sanitizeHtml"
                            data-cy="verification-check__title"></div>

                        <div
                            class="check-account__descr"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'newVf.descr')"></div>
                    </ng-container>

                    <ng-container *ngIf="hasStarted">
                        <div
                            class="check-account__title"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'continueVf.title') | sanitizeHtml"
                            data-cy="verification-check__title"></div>

                        <div
                            class="check-account__descr"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'continueVf.descr')"></div>
                    </ng-container>

                    <a
                        class="check-account__continue"
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        (click)="continueCheckVerification()"
                        data-cy="verification-check__submit">
                        {{ wts.getI18nLabel(i18nBasePath, !hasStarted ? 'newVf.actions.continue' : 'continueVf.actions.continue') }}
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
