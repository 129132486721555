import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AuthResponse, JwtPayload } from '@scaliolabs/baza-core-shared';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { BazaDocumentCookieStorageService, BazaLocalStorageService, BazaSessionStorageService } from '../../../baza-common';

export interface BazaJWT {
    accessToken: string;
    refreshToken: string;
}

export class BazaNoJwtAvailableError extends Error {
    constructor() {
        super('JWT is not available yet');
    }
}

/**
 * Ng Service which is responsible for storing current JWT (Auth) session.
 *
 * JwtService contains information about current JWT + Access/Refresh Token, JWT
 * payload object and information is JWT verified last time or not.
 *
 * JwtService can store tokens in window.localStorage, window.sessionStorage and
 * cookies. You can enable multiple storage at once.
 *
 * JwtPayload will not contains any data which can become outdated at some point.
 * All custom data which can be updated / modified will be stored in `settings`
 * field of AccountDTO.
 */
@Injectable({
    providedIn: 'root',
})
export class JwtService {
    private _jwt$: BehaviorSubject<BazaJWT | undefined> = new BehaviorSubject<BazaJWT | undefined>(undefined);
    private _jwtPayload$: BehaviorSubject<JwtPayload | undefined> = new BehaviorSubject<JwtPayload | undefined>(undefined);
    private _jwtVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly localStorage: BazaLocalStorageService,
        private readonly sessionStorage: BazaSessionStorageService,
        private readonly documentCookie: BazaDocumentCookieStorageService,
    ) {
        this.bootstrapJwtFromDocumentCookie();
        this.bootstrapJwtFromLocalStorage();
        this.bootstrapJwtFromSessionStorage();
    }

    /**
     * Attempts to bootstrap JWT from window.localStorage
     */
    bootstrapJwtFromLocalStorage(): void {
        if (this.moduleConfig.jwtStorages.localStorage.enabled) {
            try {
                if (isPlatformBrowser(this.platformId)) {
                    const jwt = this.localStorage.get(this.moduleConfig.jwtStorages.localStorage.jwtKey);
                    const jwtPayload = this.localStorage.get(this.moduleConfig.jwtStorages.localStorage.jwtPayloadKey);

                    if (jwt && jwtPayload) {
                        this._jwtPayload$.next(JSON.parse(jwtPayload));
                        this._jwt$.next(JSON.parse(jwt));
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        }
    }

    /**
     * Attempts to bootstrap JWT from window.sessionStorage
     */
    bootstrapJwtFromSessionStorage(): void {
        if (this.moduleConfig.jwtStorages.sessionStorage.enabled) {
            try {
                if (isPlatformBrowser(this.platformId)) {
                    const jwt = this.sessionStorage.get(this.moduleConfig.jwtStorages.sessionStorage.jwtKey);
                    const jwtPayload = this.sessionStorage.get(this.moduleConfig.jwtStorages.sessionStorage.jwtPayloadKey);

                    if (jwt && jwtPayload) {
                        this._jwtPayload$.next(JSON.parse(jwtPayload));
                        this._jwt$.next(JSON.parse(jwt));
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        }
    }

    /**
     * Attempts to bootstrap JWT from document.cookie
     */
    bootstrapJwtFromDocumentCookie(): void {
        if (this.moduleConfig.jwtStorages.documentCookieStorage.enabled) {
            try {
                const jwtKey = this.moduleConfig.jwtStorages.documentCookieStorage.jwtKey;
                const jwtPayloadKey = this.moduleConfig.jwtStorages.documentCookieStorage.jwtPayloadKey;

                if (this.documentCookie.check(jwtKey) && this.documentCookie.check(jwtPayloadKey)) {
                    const jwt = this.documentCookie.get(jwtKey);
                    const jwtPayload = this.documentCookie.get(jwtPayloadKey);

                    if (jwt && jwtPayload) {
                        this._jwtPayload$.next(JSON.parse(jwtPayload));
                        this._jwt$.next(JSON.parse(jwt));
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        }
    }

    /**
     * Returns current JWT (BazaJWT).
     * If JWT is not available, method will throw an error.
     */
    get jwt(): BazaJWT {
        if (!this.hasJwt()) {
            throw new BazaNoJwtAvailableError();
        }

        return this._jwt$.getValue();
    }

    /**
     * Returns current JWT (BazaJWT) in observable way
     */
    get jwt$(): Observable<BazaJWT | undefined> {
        return this._jwt$.asObservable();
    }

    /**
     * Returns JWT verification status in observable way
     */
    get jwtVerified$(): Observable<boolean> {
        return this._jwtVerified$.asObservable();
    }

    /**
     * Returns JWT payload.
     * If JWT is not available, method will throw an error.
     */
    get jwtPayload(): JwtPayload {
        if (!this.hasJwt()) {
            throw new BazaNoJwtAvailableError();
        }

        return this._jwtPayload$.getValue();
    }

    /**
     * Returns JWT payload in observable way
     */
    get jwtPayload$(): Observable<JwtPayload | undefined> {
        return this._jwtPayload$.asObservable();
    }

    /**
     * Set Up JWT.
     *
     * Application should use this method when user is successfully signed in or
     * fetched JWT some other way (for example, after resetting password)
     *
     * @param jwt
     * @param jwtPayload
     */
    setJwt(jwt: BazaJWT, jwtPayload: JwtPayload): void {
        this._jwt$.next(jwt);
        this._jwtPayload$.next(jwtPayload);

        if (isPlatformBrowser(this.platformId)) {
            if (this.moduleConfig.jwtStorages.localStorage.enabled) {
                this.localStorage.set(this.moduleConfig.jwtStorages.localStorage.jwtKey, JSON.stringify(jwt));
                this.localStorage.set(this.moduleConfig.jwtStorages.localStorage.jwtPayloadKey, JSON.stringify(jwtPayload));
            }

            if (this.moduleConfig.jwtStorages.sessionStorage.enabled) {
                this.sessionStorage.set(this.moduleConfig.jwtStorages.sessionStorage.jwtKey, JSON.stringify(jwt));
                this.sessionStorage.set(this.moduleConfig.jwtStorages.sessionStorage.jwtPayloadKey, JSON.stringify(jwtPayload));
            }

            if (this.moduleConfig.jwtStorages.documentCookieStorage.enabled) {
                this.documentCookie.set(this.moduleConfig.jwtStorages.documentCookieStorage.jwtKey, JSON.stringify(jwt));
                this.documentCookie.set(this.moduleConfig.jwtStorages.documentCookieStorage.jwtPayloadKey, JSON.stringify(jwtPayload));
            }
        }
    }

    /**
     * Set Up JWT with AuthResponse
     *
     * @see BazaAuthDataAccess
     * @see BazaAuthDataAccess.auth
     *
     * @param authResponse
     */
    setJwtWithAuthResponse(authResponse: AuthResponse): void {
        this.setJwt(
            {
                accessToken: authResponse.accessToken,
                refreshToken: authResponse.refreshToken,
            },
            authResponse.jwtPayload,
        );
    }

    /**
     * Rewrite accessToken in current JWT session
     * If JWT is not available, method will throw an error.
     *
     * @param accessToken
     */
    refreshAccessToken(accessToken: string): void {
        this.setJwt(
            {
                ...this.jwt,
                accessToken,
            },
            this.jwtPayload,
        );
    }

    /**
     * Returns true if JWT is available
     */
    hasJwt(): boolean {
        return !!this._jwt$.getValue();
    }

    /**
     * Mark current JWT as verified
     * Used mostly in guards from baza-auth-ng subpackage
     */
    markJwtAsVerified(): void {
        this._jwtVerified$.next(true);
    }

    /**
     * Mark current JWT as unverified
     * Used mostly in guards from baza-auth-ng subpackage
     */
    markJwtAsUnverifiedOrOutdated(): void {
        this.destroy();
    }

    /**
     * Destroy (forget) JWT
     *
     * Please note that you must invalidate tokens using specific endpoints of
     * Baza Auth API. API should be informed that client destroy a session for
     * security reasons.
     */
    destroy(): void {
        this._jwt$.next(undefined);
        this._jwtPayload$.next(undefined);
        this._jwtVerified$.next(false);

        if (isPlatformBrowser(this.platformId)) {
            if (this.moduleConfig.jwtStorages.localStorage.enabled) {
                this.localStorage.remove(this.moduleConfig.jwtStorages.localStorage.jwtKey);
                this.localStorage.remove(this.moduleConfig.jwtStorages.localStorage.jwtPayloadKey);
            }

            if (this.moduleConfig.jwtStorages.sessionStorage.enabled) {
                this.sessionStorage.remove(this.moduleConfig.jwtStorages.sessionStorage.jwtKey);
                this.sessionStorage.remove(this.moduleConfig.jwtStorages.sessionStorage.jwtPayloadKey);
            }
        }

        if (this.moduleConfig.jwtStorages.documentCookieStorage.enabled) {
            this.documentCookie.delete(this.moduleConfig.jwtStorages.documentCookieStorage.jwtKey);
            this.documentCookie.delete(this.moduleConfig.jwtStorages.documentCookieStorage.jwtPayloadKey);
        }
    }
}
