import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retryWhen, switchMap, tap } from 'rxjs/operators';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { JwtService } from '../services/jwt.service';
import { BazaAuthDataAccess } from '@scaliolabs/baza-core-data-access';
import { AuthErrorCodes, VerifyRequest } from '@scaliolabs/baza-core-shared';
import { BazaError, isBazaErrorResponse } from '@scaliolabs/baza-core-shared';
import { BazaNgErrorHandlerService } from '../../../baza-common/lib/services/baza-ng-error-handler.service';
import { genericRetryStrategy } from '../../../baza-common/lib/util/generic-retry-strategy.util';
import { isPlatformServer } from '@angular/common';

/**
 * Helper Interface for Route Data object
 * Implement it in Route's Data object or use it just as documentation source
 */
export interface BazaBazaJwtVerifyGuardRouteConfig {
    jwtVerifyGuard: Partial<BazaJwtVerifyGuardConfig>;
}

/**
 * Configuration for JwtVerifyGuard
 *
 * Default configuration can be replaced with route data, global injectable constant
 * or with bazaWebBundleConfigBuilder helper.
 *
 * Default configuration can be replaced with bazaWebBundleConfigBuilder helper.
 *
 * @see JwtVerifyGuard
 *
 * @example
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               verifyJwtGuardConfig: {
 *                   // Your configuration
 *               },
 *               // Additional required configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 */
export class BazaJwtVerifyGuardConfig {
    /**
     * Delay time between JWT verification. After successful JWT token verification,
     * JwtVerifyGuard will perform new verifications after specific delay.
     */
    verifySameJwtThrottle: number;

    /**
     * If JWT Token verification failed, user will be automatically navigated to
     * the page specified here.
     */
    redirect?: (
        route: ActivatedRouteSnapshot,
        verifyRequest: VerifyRequest,
    ) => {
        commands: any[];
        navigationExtras?: UrlCreationOptions;
    };
}

let lastTimeVerified: Date;

/**
 * JWT Verify guard which will perform JWT Token verification when visiting
 * current or child routes. If JWT token is not valid, the guard will redirect
 * user to Sign In page configured in BazaJwtVerifyGuardConfig.
 *
 * JWT verification by default has some throttle, i.e. token verification
 * will not be performed if it was already executed not so much long time before.
 *
 * Guard can be used with canActivate or canActivateChild configurations.
 *
 * @see BazaJwtVerifyGuardConfig
 * @see BazaBazaJwtVerifyGuardRouteConfig
 *
 * @example
 * If you need to replace redirect URL on global level, you can do it somewhere
 * in you AppModule:
 *
 * ```typescript
 * import { BAZA_WEB_BUNDLE_GUARD_CONFIGS } from '@scaliolabs/baza-core-web`
 *
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.verifyJwtGuardConfig.verifySameJwtThrottle = 300; // every 300 seconds
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.verifyJwtGuardConfig.redirect = () => ({
 *     commands: ['/'],
 *     navigationExtras: {
 *         queryParams: {
 *             signIn: 1,
 *         },
 *     },
 * });
 * ```
 *
 * @example
 * You can override configuration for guard with route Data object implements BazaBazaJwtVerifyGuardRouteConfig
 * interface
 *
 * ```typescript
 * import { MyComponent } from './my/my.component';
 * import { ActivatedRouteSnapshot, Routes } from '@angular/router';
 * import { BazaBazaJwtVerifyGuardRouteConfig, JwtRequireAdminGuard, JwtRequireAdminGuardRouteConfig, JwtVerifyGuard } from './libs/baza-core-ng/src';
 *
 * export const myRoutes: Routes = [
 * {
 *        path: 'my',
 *        component: MyComponent,
 *        canActivateChild: [JwtVerifyGuard],
 *        data: {
 *            jwtVerifyGuard: {
 *                verifySameJwtThrottle: 300, // every 300 seconds
 *                redirect: (route: ActivatedRouteSnapshot) => ({
 *                    commands: ['/'],
 *                    navigationExtras: {
 *                        queryParams: {
 *                            login: 1,
 *                        },
 *                    },
 *                }),
 *            },
 *        } as BazaBazaJwtVerifyGuardRouteConfig,
 *    },
 * ];
 * ```
 *
 * @example
 * If you need to replace configuration on global level, you can override default
 * configuration with bazaWebBundleConfigBuilder.
 *
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               verifyJwtGuardConfig: {
 *                   // Your configuration
 *               },
 *               // Additional required configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 */
@Injectable()
export class JwtVerifyGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly router: Router,
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly appErrorHandler: BazaNgErrorHandlerService,
        private readonly jwtService: JwtService,
        private readonly endpoint: BazaAuthDataAccess,
        @Inject(PLATFORM_ID) private readonly platformId: string,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verifyJwt(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.verifyJwt(childRoute);
    }

    private config(route: ActivatedRouteSnapshot): BazaBazaJwtVerifyGuardRouteConfig {
        const moduleConfig = this.moduleConfig.verifyJwtGuardConfig;
        const customConfig: Partial<BazaJwtVerifyGuardConfig> = ((route.data || {}) as BazaBazaJwtVerifyGuardRouteConfig).jwtVerifyGuard;

        return {
            jwtVerifyGuard: {
                ...moduleConfig,
                ...customConfig,
            },
        };
    }

    private verifyJwt(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        if (this.jwtService.hasJwt()) {
            const config = this.config(route);

            if (lastTimeVerified) {
                const now = new Date().getTime();
                const last = lastTimeVerified.getTime();

                if (now - last <= config.jwtVerifyGuard.verifySameJwtThrottle) {
                    return true;
                }
            }

            const verifyRequest: VerifyRequest = {
                jwt: this.jwtService.jwt.accessToken,
            };

            const fail = () => {
                if (config.jwtVerifyGuard.redirect) {
                    const redirect = config.jwtVerifyGuard.redirect(route, verifyRequest);

                    return of(this.router.createUrlTree(redirect.commands, redirect.navigationExtras));
                } else {
                    return of(false);
                }
            };

            return this.endpoint.verify(verifyRequest).pipe(
                retryWhen(genericRetryStrategy()),
                tap(() => (lastTimeVerified = new Date())),
                switchMap(() => {
                    this.jwtService.markJwtAsVerified();

                    return of(true);
                }),
                catchError((err: BazaError) => {
                    if (!this.jwtService.hasJwt()) {
                        return throwError(err);
                    }

                    if (isBazaErrorResponse(err) && [AuthErrorCodes.AuthInvalidJwt, AuthErrorCodes.AuthJwtExpired].includes(err.code)) {
                        return fail();
                    } else if (isBazaErrorResponse(err) && [AuthErrorCodes.AuthInvalidCredentials].includes(err.code)) {
                        this.jwtService.destroy();

                        return fail();
                    } else {
                        return throwError(err);
                    }
                }),
                this.appErrorHandler.pipe(),
            );
        } else {
            return true;
        }
    }
}
