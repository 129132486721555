export const WebUiAddFundsFormEnI18n = {
    fields: {
        amount: {
            label: 'Amount',
            validators: {
                required: 'Please enter the amount to be transferred',
            },
        },
    },
    alerts: {
        amountWarning: `Funds won't be immediately added to your balance. If you want to make a purchase right away, you can choose an alternative payment method.`,
        transferError: {
            label: `The funds were not added to the account. <br/> Please try again later or reach out to <a data-link="contactLink"></a>.`,
            linkConfig: {
                key: 'contactLink',
                link: {
                    extLink: {
                        link: 'bazaContentTypes.contacts.email',
                        isCMSLink: true,
                        isMailLink: true,
                        text: 'technical support',
                    },
                },
            },
        },
    },
};
