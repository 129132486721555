import { IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { DwollaBankAccountType } from '../models/dwolla-bank-account-type';

export class UpdateFundingSourceRequest {
    @IsString()
    @IsNotEmpty()
    name: string;

    @Length(9)
    @IsNumberString()
    @IsOptional()
    routingNumber?: string;

    @IsNumberString()
    @IsOptional()
    accountNumber?: string;

    @IsEnum(DwollaBankAccountType)
    @IsOptional()
    bankAccountType?: DwollaBankAccountType;
}
