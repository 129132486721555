import { AccountType } from '../models/account-type';
import { EntityType } from '../models/entity-type';
import { DomesticYN } from '../models/domestic-yn';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { SuitabilityScore } from '../models/suitability-score';
import { AccreditedStatus } from '../models/accredited-status';
import { Allow } from '../models/allow';
import { ApprovalStatus } from '../models/approval-status';
import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { NorthCapitalDateTime, NorthCapitalYesNoBoolean } from '../common-types';

export class CreateAccountRequest {
    @IsString()
    @IsNotEmpty()
    accountRegistration: string;

    @IsEnum(AccountType)
    @IsNotEmpty()
    type: AccountType;

    @IsEnum(EntityType)
    @IsOptional()
    entityType?: EntityType;

    @IsNotEmpty()
    domesticYN: DomesticYN;

    @IsString()
    @IsNotEmpty()
    streetAddress1: string;

    @IsString()
    @IsOptional()
    streetAddress2?: string;

    @IsString()
    @IsNotEmpty()
    city: string;

    @IsString()
    @IsNotEmpty()
    state: string;

    @IsString()
    @IsNotEmpty()
    zip: string;

    @IsString()
    @IsNotEmpty()
    country: string;

    @IsString()
    @IsOptional()
    email?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsInt()
    @IsOptional()
    taxID?: number;

    @IsEnum(KYCStatus)
    @IsNotEmpty()
    KYCstatus: KYCStatus;

    @IsEnum(AmlStatus)
    @IsNotEmpty()
    AMLstatus: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: NorthCapitalDateTime;

    @IsEnum(SuitabilityScore)
    @IsOptional()
    suitabilityScore?: SuitabilityScore;

    @IsString()
    @IsOptional()
    suitabilityDate?: NorthCapitalDateTime;

    @IsString()
    @IsOptional()
    suitabilityApprover?: string;

    @IsEnum(AccreditedStatus)
    @IsNotEmpty()
    AccreditedStatus: AccreditedStatus;

    @IsEnum(Allow)
    @IsOptional()
    Allow?: Allow;

    @IsString()
    @IsOptional()
    AIdate?: NorthCapitalDateTime;

    @IsNumber()
    @IsOptional()
    '506cLimit'?: number;

    @IsInt()
    @IsOptional()
    accountTotalLimit?: number;

    @IsInt()
    @IsOptional()
    singleInvestmentLimit?: number;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    associatedAC?: NorthCapitalYesNoBoolean;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    syndicate?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    tags?: string;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsEnum(ApprovalStatus)
    @IsNotEmpty()
    approvalStatus: ApprovalStatus;

    @IsString()
    @IsOptional()
    approvalPrincipal?: string;

    @IsString()
    @IsOptional()
    approvalLastReview?: NorthCapitalDateTime;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;
}
