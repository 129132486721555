import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BazaVersionNgConfig, BazaVersionNgStrategy } from '../baza-version-ng.config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BazaError, isBazaErrorResponse } from '@scaliolabs/baza-core-shared';
import { VersionErrorCodes } from '@scaliolabs/baza-core-shared';
import { Router } from '@angular/router';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class XBazaVersionRedirectNgHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly moduleConfig: BazaVersionNgConfig,
        private readonly ngEndpoint: BazaDataAccessService,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        if (this.moduleConfig.strategy.type === BazaVersionNgStrategy.ProvideVersion && this.moduleConfig.strategy.redirectTo) {
            const redirectTo = this.moduleConfig.strategy.redirectTo;

            return next.handle(req).pipe(
                catchError((err: HttpErrorResponse) => {
                    if (isBazaErrorResponse(err.error) && (err.error as BazaError).code === VersionErrorCodes.VersionIsOutdated) {
                        this.router.navigate(redirectTo, {
                            skipLocationChange: true,
                        });
                    }

                    return throwError(err);
                }),
            );
        } else {
            return next.handle(req);
        }
    }
}
