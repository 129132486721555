import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString, Min } from 'class-validator';
import { OfferingId } from '../../../transact-api';

export class BazaNcUiOfferingDto {
    @ApiModelProperty({
        required: false,
        type: 'string',
    })
    @IsString()
    @IsOptional()
    ncOfferingId?: OfferingId;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    ncTargetAmount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsNumber()
    @IsNotEmpty()
    ncMinAmount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    ncMaxAmount: number;

    @ApiModelProperty()
    @Min(0)
    @IsInt()
    @IsNotEmpty()
    ncCurrentValueCents: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    ncMaxSharesPerAccount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsNumber()
    @IsNotEmpty()
    ncUnitPrice: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncStartDate: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncEndDate: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncSubscriptionOfferingId: string;

    @ApiModelProperty({
        required: false,
        type: 'number',
    })
    @IsNumber()
    @IsOptional()
    ncOfferingFees?: number;

    @ApiModelProperty({
        required: false,
        type: 'number',
    })
    @IsNumber()
    @IsOptional()
    daysToReleaseFailedTrades?: number;
}
