import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

export class BazaNcIntegrationPortfolioDocumentsDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Document Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Document Description',
    })
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Document URL.',
    })
    @IsString()
    @IsNotEmpty()
    documentUrl: string;
}

export class BazaNcIntegrationPortfolioStatementsDto {
    @ApiModelProperty({
        type: BazaNcIntegrationPortfolioDocumentsDto,
        description: 'Documents List.',
        isArray: true
    })
    statements: Array<BazaNcIntegrationPortfolioDocumentsDto>;

    @ApiModelProperty({
        description: 'Listing ID',
    })
    id: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Listing SID',
    })
    @IsString()
    @IsNotEmpty()
    sid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Listing Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;
}
