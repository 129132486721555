import { Injectable } from '@angular/core';
import { BazaI18nDetector } from '../baza-i18n-detector';
import { Observable, of } from 'rxjs';
import { BAZA_COMMON_I18N_CONFIG, ProjectLanguage } from '@scaliolabs/baza-core-shared';

@Injectable()
export class DefaultCurrentLanguageDetector implements BazaI18nDetector {
    detect(): Observable<ProjectLanguage | undefined> {
        return of(BAZA_COMMON_I18N_CONFIG.defaultLanguage);
    }
}
