import { PartyId } from '../models/party-id';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UploadEntityDocumentRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsNotEmpty()
    userfile0: File | unknown;

    @IsString()
    @IsOptional()
    createdIpAddress: string;
}
