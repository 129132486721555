import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcLimitsDto {
    @ApiModelProperty({
        description: 'Limits for ACH Transfers in cents (500.00$ => 50000)',
        example: 10000000,
    })
    maxACHTransferAmountCents: number;

    @ApiModelProperty({
        description: 'Limits for Credit Card Transfers in cents (500.00$ => 50000',
        example: 500000,
    })
    maxCreditCardTransferAmountCents: number;

    @ApiModelProperty({
        description: 'Fee for Credit Card Transfers',
        example: 3.5,
    })
    creditCardPaymentsFee: number;

    @ApiModelProperty({
        description: 'Max Account Balance Transfer Amount (Dwolla)',
        example: 1000000,
    })
    maxDwollaTransferAmount: number;
}
