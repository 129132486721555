import { AccountDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcIntegrationEliteInvestorsDto {
    @ApiModelProperty({
        description: 'ID',
    })
    id: number;

    @ApiModelProperty({
        description: 'Joined date',
    })
    dateJoinedAt: string;

    @ApiModelProperty({
        description: 'Account details',
    })
    account: AccountDto;
}
