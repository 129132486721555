import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Application, clientApplications } from '../../../../baza-common/src';
import { AccountRole } from '../models/account-role';

export class AccountCsvDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    dateCreated: string;

    @ApiModelProperty()
    dateUpdated: string;

    @ApiModelProperty()
    lastSignIn: string;

    @ApiModelProperty({
        description: 'Client (application) used to register account',
        enum: [...clientApplications],
    })
    signedUpWithClient: Application;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty()
    fullName: string;

    @ApiModelProperty({
        required: false,
    })
    firstName?: string;

    @ApiModelProperty({
        required: false,
    })
    lastName?: string;

    @ApiModelProperty()
    isEmailConfirmed: boolean;

    @ApiModelProperty()
    role: AccountRole;
}
