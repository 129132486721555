import { DwollaTransferStatus } from '../../../../dwolla-api/src';

export enum BazaDwollaPaymentStatus {
    Pending = 'Pending',
    Processed = 'Processed',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export const bazaDwollaToPaymentStatus: Array<{
    dwolla: DwollaTransferStatus;
    status: BazaDwollaPaymentStatus;
}> = [
    {
        dwolla: DwollaTransferStatus.Pending,
        status: BazaDwollaPaymentStatus.Pending,
    },
    {
        dwolla: DwollaTransferStatus.Processed,
        status: BazaDwollaPaymentStatus.Processed,
    },
    {
        dwolla: DwollaTransferStatus.Failed,
        status: BazaDwollaPaymentStatus.Failed,
    },
    {
        dwolla: DwollaTransferStatus.Cancelled,
        status: BazaDwollaPaymentStatus.Cancelled,
    },
];
