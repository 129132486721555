import { ModuleWithProviders, NgModule } from '@angular/core';
import { XBazaVersionNgHttpInterceptor } from './http-interceptors/x-baza-version-ng.http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BazaVersionNgConfig } from './baza-version-ng.config';
import { XBazaVersionRedirectNgHttpInterceptor } from './http-interceptors/x-baza-version-redirect-ng.http-interceptor';

interface ForRootOptions {
    deps: Array<unknown>;
    useFactory: (...deps) => BazaVersionNgConfig;
}

export { ForRootOptions as BazaVersionNgModuleForRootOptions };

// @dynamic
@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: XBazaVersionNgHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: XBazaVersionRedirectNgHttpInterceptor, multi: true },
    ],
})
export class BazaVersionNgModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaVersionNgModule> {
        return {
            ngModule: BazaVersionNgModule,
            providers: [
                {
                    provide: BazaVersionNgConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
