import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaAccountConstants } from '@scaliolabs/baza-core-shared';

export class AccountVerificationInvestorProfileDto {
    @ApiModelProperty({
        type: 'boolean',
    })
    @IsBoolean()
    @IsNotEmpty()
    isAccreditedInvestor: boolean;

    @ApiModelProperty({
        type: 'boolean',
    })
    @IsBoolean()
    @IsNotEmpty()
    isAssociatedWithFINRA: boolean;

    @ApiModelProperty({
        type: 'string',
        description: 'describe the relationship with member of household',
    })
    @IsString()
    @IsOptional()
    @MaxLength(100, {
        always: false,
    })
    finraRelationship?: string;

    @ApiModelProperty({
        description: 'What is your current annual household income (including your spouse)? (Income)',
        required: false,
    })
    @IsNumber()
    @IsOptional()
    currentAnnualHouseholdIncome?: number;

    @ApiModelProperty({
        description: 'What is your net worth (excluding primary residence)? (Worth)',
        required: false,
    })
    @IsNumber()
    @IsOptional()
    netWorth?: number;

    @ApiModelProperty({
        type: 'boolean',
        description: 'Dwolla consent checkbox is checked or not',
        required: false,
    })
    @IsOptional()
    @IsBoolean()
    dwollaConsentProvided?: boolean;
}
