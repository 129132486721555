import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  TeamMemberCmsDto,
  TeamsMemberCmsCreateRequest,
  TeamsMemberCmsEndpoint,
  TeamsMemberCmsEndpointPaths,
  TeamsMemberCmsUpdateRequest,
  TeamsMemberDeleteRequest,
  TeamsMemberGetByIdRequest,
  TeamsMemberListRequest,
  TeamsMemberListResponse,
  TeamsMemberSetSortOrderRequest,
  TeamsMemberSetSortOrderResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class TeamsMembersCmsDataAccess implements TeamsMemberCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: TeamsMemberCmsCreateRequest): Observable<TeamMemberCmsDto> {
    return this.http.post(TeamsMemberCmsEndpointPaths.create, request);
  }

  update(request: TeamsMemberCmsUpdateRequest): Observable<TeamMemberCmsDto> {
    return this.http.post(TeamsMemberCmsEndpointPaths.update, request);
  }

  delete(request: TeamsMemberDeleteRequest): Observable<void> {
    return this.http.post(TeamsMemberCmsEndpointPaths.delete, request);
  }

  getById(request: TeamsMemberGetByIdRequest): Observable<TeamMemberCmsDto> {
    return this.http.post(TeamsMemberCmsEndpointPaths.getById, request);
  }

  list(request: TeamsMemberListRequest): Observable<TeamsMemberListResponse> {
    return this.http.post(TeamsMemberCmsEndpointPaths.list, request);
  }

  setSortOrder(request: TeamsMemberSetSortOrderRequest): Observable<TeamsMemberSetSortOrderResponse> {
    return this.http.post(TeamsMemberCmsEndpointPaths.setSortOrder, request);
  }
}
