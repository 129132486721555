import { TagDto } from '../../../../tags/src';
import { CategoryListDto } from '../../../../categories/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentImageSetDto, BazaSeoDto } from '@scaliolabs/baza-core-shared';

export class TimelineDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty({
        description: 'Is available for Public API?',
    })
    isPublished: boolean;

    @ApiModelProperty({
        description: 'Tags',
        type: TagDto,
        isArray: true,
    })
    tags: Array<TagDto>;

    @ApiModelProperty({
        description: 'Categories',
        type: CategoryListDto,
        isArray: true,
    })
    categories: Array<CategoryListDto>;

    @ApiModelProperty({
        title: 'Date. All entries will be sorted by Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        title: 'Title',
    })
    title: string;

    @ApiModelProperty({
        description: 'Description (Plain Text, could be used as short version)',
        required: false,
    })
    textDescription?: string;

    @ApiModelProperty({
        description: 'Description (HTML)',
        required: false,
    })
    htmlDescription?: string;

    @ApiModelProperty({
        description: 'Image (Optional)',
        required: false,
    })
    image?: AttachmentImageSetDto;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    metadata: Record<string, string>;

    @ApiModelProperty({
        description: 'SEO',
    })
    seo: BazaSeoDto;
}
