import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class SessionDocusignDto {
    @ApiModelProperty({
        description: 'Are documents signed for current session?',
    })
    @IsBoolean()
    @IsNotEmpty()
    areDocumentsSigned: boolean;

    @ApiModelProperty({
        description: 'DocuSign URL',
        required: false,
    })
    @IsString()
    @IsOptional()
    docuSignUrl?: string;
}
