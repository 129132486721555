import { NgModule } from '@angular/core';
import { BazaDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { BazaNcPurchaseFlowBankAccountDataAccess } from './services/baza-nc-purchase-flow-bank-account.data-access';
import { BazaNcInvestorAccountDataAccess } from './services/baza-nc-investor-account.data-access';
import { BazaNcPurchaseFlowCreditCardDataAccess } from './services/baza-nc-purchase-flow-credit-card.data-access';
import { BazaNcPurchaseFlowDataAccess } from './services/baza-nc-purchase-flow.data-access';
import { BazaNcPurchaseFlowLimitsDataAccess } from './services/baza-nc-purchase-flow-limits.data-access';
import { BazaNcTransactionsDataAccess } from './services/baza-nc-transactions.data-access';
import { BazaNcAccountVerificationDataAccess } from './services/baza-nc-account-verification.data-access';
import { BazaNcTaxDocumentDataAccess } from './services/baza-nc-tax-document.data-access';
import { BazaNcDividendDataAccess } from './services/baza-nc-dividend.data-access';
import { BazaNcDwollaDataAccess } from './services/baza-nc-dwolla.data-access';
import { BazaNcBankAccountsDataAccess } from './services/baza-nc-bank-accounts.data-access';
import { BazaNcBootstrapDataAccess } from './services/baza-nc-bootstrap.data-access';
import { BazaNcTransferDataAccess } from './services/baza-nc-transfer.data-access';
import { BazaNcWithdrawalDataAccess } from './services/baza-nc-withdrawal.data-access';
import { BazaNcOperationDataAccess } from './services/baza-nc-operation.data-access';
import { BazaNcCreditCardDataAccess } from './services/baza-nc-credit-card.data-access';
import {BazaNcRecurringTransactionsDataAccess} from "./services/baza-nc-recurring-transactions.data-access";

@NgModule({
    imports: [BazaDataAccessModule],
    providers: [
        BazaNcAccountVerificationDataAccess,
        BazaNcInvestorAccountDataAccess,
        BazaNcPurchaseFlowDataAccess,
        BazaNcPurchaseFlowBankAccountDataAccess,
        BazaNcPurchaseFlowCreditCardDataAccess,
        BazaNcPurchaseFlowLimitsDataAccess,
        BazaNcTransactionsDataAccess,
        BazaNcTaxDocumentDataAccess,
        BazaNcDividendDataAccess,
        BazaNcDwollaDataAccess,
        BazaNcBankAccountsDataAccess,
        BazaNcBootstrapDataAccess,
        BazaNcTransferDataAccess,
        BazaNcWithdrawalDataAccess,
        BazaNcOperationDataAccess,
        BazaNcCreditCardDataAccess,
        BazaNcRecurringTransactionsDataAccess,
    ],
})
export class BazaNcDataAccessModule {}
