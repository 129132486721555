import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { CategoryDto } from '../dto/category.dto';
import { Observable } from 'rxjs';
import { CategoriesDto } from '../dto/categories.dto';
import { BazaSeoDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaContentDto } from '../../../../../shared/src';
import { CategoryListDto } from '../dto/category-list.dto';
import { Type } from 'class-transformer';

export enum CategoriesCmsEndpointPaths {
    create = '/baza-content-types/categories/cms/create',
    update = '/baza-content-types/categories/cms/update',
    delete = '/baza-content-types/categories/cms/delete',
    getAll = '/baza-content-types/categories/cms/getAll',
    getById = '/baza-content-types/categories/cms/getById/:id',
    setSortOrder = '/baza-content-types/categories/cms/setSortOrder',
}

export interface CategoriesCmsEndpoint {
    create(request: CategoriesCmsCreateRequest): Promise<CategoryDto> | Observable<CategoryDto>;
    update(request: CategoriesUpdateRequest): Promise<CategoryDto> | Observable<CategoryDto>;
    delete(request: CategoriesDeleteRequest): Promise<void> | Observable<void>;
    getAll(): Promise<CategoriesDto> | Observable<CategoriesDto>;
    getById(id: number): Promise<CategoryDto> | Observable<CategoryDto>;
    setSortOrder(
        request: CategoriesCmsSetSortOrderRequest,
    ): Promise<BazaContentTypesCmsSetSortOrderResponse> | Observable<BazaContentTypesCmsSetSortOrderResponse>;
}

export class BazaContentTypeCategoriesCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isActive: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    parentId?: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class CategoriesCmsCreateRequest extends BazaContentTypeCategoriesCmsEntityBody {}

export class CategoriesUpdateRequest extends BazaContentTypeCategoriesCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class CategoriesDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class CategoriesCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class BazaContentTypesCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<CategoryListDto> {
    @ApiModelProperty()
    entity: CategoryListDto;

    @ApiModelProperty({
        type: CategoryListDto,
        isArray: true,
    })
    affected: Array<CategoryListDto>;
}
