import { BazaNcBankAccountDto } from './bank-account';
import { BazaNcOfferingRegulationOptions, BazaNcOfferingRegulationType } from './offering';
import { uniq } from 'lodash';

type BankAccountPick = Pick<BazaNcBankAccountDto, 'accountName' | 'accountType' | 'accountNickName' | 'type'>;

/**
 * Predefined charsets
 */
export enum NcCharset {
    // QWERTY Space
    En = 'En',

    // Latin1 Space
    Latin1 = 'Latin1',
}

/**
 * QWERTY-only EN Keyboard charset
 */
export const ncCharsetEn = `1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM§±=!@#$%^&*()_+{}":|<>?~\`[];'\\/.,\`- \n\r`;

/**
 * Latin1 Charset - should be used for Legacy support
 * @see https://www.ic.unicamp.br/~stolfi/EXPORT/www/ISO-8859-1-Encoding.html
 */
export const ncCharsetLatin1 = `
    ! " # $ % & ' ( ) * + , - . /
    0 1 2 3 4 5 6 7 8 9 : ; < = > ? @
    A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
    [ \\ ] ^ _ \`
    a b c d e f g h i j k l m n o p q r s t u v w x y z
    { | } ~

    ¡ ¢ £ ¤ ¥ ¦ § ¨ © ª « ¬ ­ ® ¯
    ° ± ² ³ ´ µ ¶ · ¸ ¹ º » ¼ ½ ¾ ¿

    À Á Â Ã Ä Å Æ Ç È É Ê Ë Ì Í Î Ï Ð Ñ Ò Ó Ô Õ Ö
    ×
    Ø Ù Ú Û Ü Ý Þ
    ß
    à á â ã ä å æ ç è é ê ë ì í î ï ð ñ ò ó ô õ ö
    ÷
    ø ù ú û ü ý þ
    ÿ
`;

/**
 * Predefined charsets collection
 */
const ncCharsets = {
    [NcCharset.En]: ncCharsetEn,
    [NcCharset.Latin1]: ncCharsetLatin1,
};

/**
 * Default list of excluded for Charset validation fields
 */

export const ncDisableCharsetCheckForFields = [];

/**
 * Configuration
 */
interface Config {
    /**
     * Maximum number of retries for NC Requests in case it's returned with `undefined` body
     * By default feature is enabled for E2E environment
     */
    maxE2eRequestRetries: number;

    /**
     * Enables retries for NC Requests in case it's returned with `undefined` body
     * By default feature is enabled for E2E environment
     */
    enableRetriesForNonE2eEnvironments: boolean;

    /**
     * Enables log for failed NC Request retries
     * By default feature is enabled
     */
    enableLogFailedRequestRetries: boolean;

    /**
     * Enables charset filtering for request body
     */
    enableCharsetFiltering: boolean;

    /**
     * Enables auto-trim in request dto strings inside objects & arrays
     */
    enableAutoTrim: boolean;

    /**
     * Automatically replaces \t with "  " string.
     * Should not be used with `enableAutoTrim` option in same time.
     */
    fixTabSymbolBug: boolean;

    /**
     * Disables charset check for specific fields
     */
    disableCharsetCheckForFields: Array<string>;

    /**
     * Disables transaction fees for offering CMS API
     */
    withTransactionFeeFeature: boolean;

    /**
     * Mask SSN field in responses
     * Example: "12345679" -> "****5679"
     */
    maskSSNFields: boolean;

    /**
     * Mask Credit Card fields in responses (Credit Card number will show only last 4 digits)
     */
    maskCreditCardFields: boolean;

    /**
     * Mask Bank Account Details fields (Account and Routing number will show only last 4 digits)
     */
    maskBankDetailsFields: boolean;

    /**
     * North Capital may returns non-integer values for available number of shares and minimal number of shares to
     * purchase.
     *
     * If option enabled, API will throw an error if there will be a case of non-integer minimal or available number of
     * shares. Option is enabled by default.
     */
    strictIntegerChecks: boolean;

    /**
     * Enables or disables Dwolla features in the API
     */
    withDwollaFeatures: boolean;

    /**
     * To support automatic dwolla customer create/update for the investor account
     */
    enableDwollaSupport: boolean;

    /**
     * Enables auto-creating Dwolla Customers when processing Dividends
     */
    dwollaAutoTouchCustomers: boolean;

    /**
     * Enables Legacy Bank Account system, i.e. managing bank accounts with following endpoints:
     *  - /baza-nc/purchase-flow/getPlaidLink
     *  - /baza-nc/purchase-flow/getBankAccount
     *  - /baza-nc/purchase-flow/setBankAccount
     *  - /baza-nc/purchase-flow/deleteBankAccount
     */
    enableLegacyBankAccountsSystem: boolean;

    /**
     * Enables synchronization between Legacy Bank Account System and New Bank Account System
     * For most cases you should keep it disabled
     */
    enableLegacyBankSyncWithNBAS: boolean;

    /**
     * Enables automatic cancelling of failed trades.
     *
     * If disabled, it hides "daysToReleaseFailedTrades" field from
     * listings and registry
     */
    enableAutoCancellingFailedTrades: boolean;

    /**
     * Bank Account Name Factory
     * The factory is used when adding new bank accounts w/o explicit name
     * @param bankAccount
     */
    bankAccountNameFactory: (bankAccount: BankAccountPick) => string;

    /**
     * If enabled, Bank Accounts will be forced to be secure saved im DB
     * You should not disable this flag unless you have a real reason to have
     * access to real Account and Routing Numbers
     */
    forceSecureBankAccounts: boolean;

    /**
     * If enabled, Client can send email notification about offering status
     * to users who have subscribed
     */
    enableEmailNotificationForOfferingStatus: boolean;

    /**
     * Additional delay before executing request
     * It's useful for Sandbox / E2E environments due of specific issues with NC DB Infrastructure
     */
    requestsDelay: number;

    /**
     * Enables deprecated Purchase Flow Credit Card API
     * Disabled by default
     */
    enablePurchaseFlowLegacyCreditCardFeature: boolean;

    /**
     * Specify list of supported regulation types for offerings
     * the value can be an array of 3 regulation tyeps . if nothing specified only RegA will be the default supported regulation
     */
    supportedNcRegulationTypes: Array<BazaNcOfferingRegulationType>;

    /**
     * Specify the options for regulation for example calculation logic and properties
     */
    offeringRegulationOptions: BazaNcOfferingRegulationOptions;

    /**
     * Enables NC Api cahcing for reducing the number of calls to nc api - as part of Api optimization
     * default value will be true/enabled
     * */
    enableNcApiCaching: boolean;

    /**
     * Enables storing NC api call logs into our SQL database , not advised for production environments unless for debugging purposes
     * suggested to enable it for test environments
     * default disabled
     */
    storeNcRequestLogsIntoDb: boolean;

    /**
     * Enables storing NC webhook call logs into our SQL database including the webhook name and payload, not advised for production environments unless for debugging purposes
     * suggested to enable it for test environments
     * default disabled
     */
    storeNcWebhookLogsIntoDb: boolean;
}

const CONFIG: {
    /**
     * Allowed characters to use in request body.
     * Option `enableCharsetFiltering` should be enabled.
     */
    charset: Array<string>;
} & Config = {
    charset: [],
    enableCharsetFiltering: true,
    enableAutoTrim: true,
    fixTabSymbolBug: false,
    withTransactionFeeFeature: true,
    disableCharsetCheckForFields: [...ncDisableCharsetCheckForFields],
    maskSSNFields: true,
    maskCreditCardFields: true,
    maskBankDetailsFields: true,
    strictIntegerChecks: true,
    withDwollaFeatures: true,
    enableDwollaSupport: true,
    dwollaAutoTouchCustomers: true,
    enableLegacyBankAccountsSystem: false,
    enableLegacyBankSyncWithNBAS: false,
    bankAccountNameFactory: (bankAccount) => `${bankAccount.accountName}'s ${bankAccount.accountType}`,
    maxE2eRequestRetries: 5,
    enableRetriesForNonE2eEnvironments: false,
    enableLogFailedRequestRetries: true,
    enableAutoCancellingFailedTrades: false,
    forceSecureBankAccounts: true,
    enableEmailNotificationForOfferingStatus: false,
    enablePurchaseFlowLegacyCreditCardFeature: false,
    requestsDelay: 0,
    supportedNcRegulationTypes: [BazaNcOfferingRegulationType.RegA],
    offeringRegulationOptions: {
        regCfUseNcAnnaulHouseholdIncome: false,
    },
    enableNcApiCaching: true,
    storeNcRequestLogsIntoDb: false,
    storeNcWebhookLogsIntoDb: false,
};

/**
 * Use predefined charset
 *
 * @see NcCharset
 */
export function bazaNcSetCharset(charset: NcCharset): void {
    CONFIG.charset = uniq((ncCharsets[`${charset}`] + ' \r\n').split(''));
}

/**
 * Set custom charset.
 *
 * @param charset
 */
export function bazaNcSetCustomCharset(charset: string): void {
    CONFIG.charset = uniq((charset + ' \r\n').split(''));
}

/**
 * Returns current NC Charset config
 */
export function bazaNcApiConfig(updateConfig?: Partial<Config>): {
    /**
     * Allowed characters to use in request body.
     * Option `enableCharsetFiltering` should be enabled.
     */
    charset: Array<string>;
} & Config {
    if (updateConfig) {
        Object.assign(CONFIG, updateConfig);
    }

    return {
        ...CONFIG,
        enableLegacyBankSyncWithNBAS: CONFIG.enableLegacyBankAccountsSystem ? CONFIG.enableLegacyBankSyncWithNBAS : false,
    };
}

bazaNcSetCharset(NcCharset.En);
