import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';

export enum BazaNcIntegrationFavoriteEndpointPaths {
    include = '/baza-ng-integration/cms/favorite/include',
    exclude = '/baza-ng-integration/cms/favorite/exclude',
}

export interface BazaNcIntegrationFavoriteEndpoint {
    include(request: BazaNcIntegrationFavoriteIncludeRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    exclude(request: BazaNcIntegrationFavoriteExcludeRequest, ...args: unknown[]): Promise<void> | Observable<void>;
}

export class BazaNcIntegrationFavoriteIncludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationFavoriteExcludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}
