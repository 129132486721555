import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Observable } from 'rxjs';

export enum AccountCmsResetPasswordEndpointPaths {
    requestResetPassword = '/baza-account/cms/reset-password/requestResetPassword',
    requestResetPasswordAllAccounts = '/baza-account/cms/reset-password/requestResetPasswordAllAccounts',
}

export interface AccountCmsResetPasswordEndpoint {
    requestResetPassword(request: RequestResetPasswordCmsAccountRequest): Promise<void> | Observable<void>;
    requestResetPasswordAllAccounts(request: RequestAllAccountsResetPasswordCmsAccountRequest): Promise<void> | Observable<void>;
}

export class RequestResetPasswordCmsAccountRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty({
        description: 'Subject',
    })
    @IsString()
    @IsNotEmpty()
    subject: string;

    @ApiModelProperty({
        description: 'Message body in emails',
    })
    @IsString()
    @IsNotEmpty()
    message: string;

    @ApiModelProperty({
        description: 'This message will be displayed to user in attempt to sign in with old password',
    })
    @IsString()
    attemptToSignInErrorMessage: string;
}

export class RequestAllAccountsResetPasswordCmsAccountRequest {
    @ApiModelProperty({
        description: 'Subject',
    })
    @IsString()
    @IsNotEmpty()
    subject: string;

    @ApiModelProperty({
        description: 'Message body in emails',
    })
    @IsString()
    @IsNotEmpty()
    message: string;

    @ApiModelProperty({
        description: 'This message will be displayed to user in attempt to sign in with old password',
    })
    @IsString()
    attemptToSignInErrorMessage: string;

    @ApiModelProperty({
        description: 'Run request in synchronous mode. It is usefull for E2E testing, do not use the flag for common usage',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    synchronous?: boolean;
}
