import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { LoaderComponent } from './loader.component';
import { BazaLoadingService, LoadingHandler } from '@scaliolabs/baza-core-ng';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private overlayRef: OverlayRef | null = null;

    private isDisplayed = false;
    private loaders: Array<LoadingHandler> = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
        private loading: BazaLoadingService,
        private overlay: Overlay,
    ) {
        this.loading.isLoading$.subscribe(() => {
            setTimeout(() => {
                this.update();
            });
        });
    }

    show() {
        this.loaders.push(this.loading.addLoading());

        this.update();
    }

    hide() {
        const loading = this.loaders.pop();

        if (loading) {
            loading.complete();
        }

        this.update();
    }

    update(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (this.isDisplayed && !this.loading.isLoading) {
            if (this.overlayRef) {
                this.overlayRef.detach();
                this.overlayRef.dispose();

                requestAnimationFrame(() => {
                    this.overlayRef = null;
                });
            }

            this.isDisplayed = false;
        } else if (!this.isDisplayed && this.loading.isLoading) {
            if (this.overlayRef) {
                return;
            }

            const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

            const overlayConfig = new OverlayConfig({
                positionStrategy,
                hasBackdrop: true,
                backdropClass: 'ant-modal-mask',
            });

            const portal = new ComponentPortal(LoaderComponent);
            this.overlayRef = this.overlay.create(overlayConfig);

            this.overlayRef.attach(portal);

            this.isDisplayed = true;
        }
    }
}
