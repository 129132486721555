import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationSubscriptionDto } from '../dto/baza-nc-integration-subscription.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcIntegrationSubscriptionCsvRow } from '../dto/baza-nc-integration-subscription-csv.dto';

export enum BazaNcIntegrationSubscriptionCmsEndpointPaths {
    list = '/baza-nc-integration/cms/subscription/list',
    include = '/baza-nc-integration/cms/subscription/include',
    exclude = '/baza-nc-integration/cms/subscription/exclude',
    exportToCsv = '/baza-nc-integration/cms/subscription/exportToCsv',
    notifyUsers = '/baza-nc-integration/cms/subscription/notifyUsers',
}

export interface BazaNcIntegrationSubscriptionCmsEndpoint {
    list(
        request: BazaNcIntegrationSubscriptionCmsListRequest,
    ): Promise<BazaNcIntegrationSubscriptionCmsListResponse> | Observable<BazaNcIntegrationSubscriptionCmsListResponse>;
    include(
        request: BazaNcIntegrationSubscriptionCmsIncludeRequest,
    ): Promise<BazaNcIntegrationSubscriptionCmsIncludeResponse> | Observable<BazaNcIntegrationSubscriptionCmsIncludeResponse>;
    exclude(
        request: BazaNcIntegrationSubscriptionCmsExcludeRequest,
    ): Promise<BazaNcIntegrationSubscriptionCmsExcludeResponse> | Observable<BazaNcIntegrationSubscriptionCmsExcludeResponse>;
    exportToCsv(request: BazaNcIntegrationSubscriptionCmsExportToCsvRequest): Promise<string> | Observable<string>;
    notifyUsers(
        request: BazaNcIntegrationSubscriptionCmsNotifyUsersRequest,
    ): Promise<BazaNcIntegrationSubscriptionCmsNotifyUsersResponse> | Observable<BazaNcIntegrationSubscriptionCmsNotifyUsersResponse>;
}

export class BazaNcIntegrationSubscriptionCmsListRequest extends CrudListRequestDto<BazaNcIntegrationSubscriptionDto> {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    listingId?: number;
}

export class BazaNcIntegrationSubscriptionCmsListResponse extends CrudListResponseDto<BazaNcIntegrationSubscriptionDto> {}

export class BazaNcIntegrationSubscriptionCmsIncludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;

    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    email: string;
}

export type BazaNcIntegrationSubscriptionCmsIncludeResponse = BazaNcIntegrationSubscriptionDto;

export class BazaNcIntegrationSubscriptionCmsExcludeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;

    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    email: string;
}

export type BazaNcIntegrationSubscriptionCmsExcludeResponse = void;

export class BazaNcIntegrationSubscriptionCmsExportToCsvRequest extends CrudExportToCsvRequest<
    BazaNcIntegrationSubscriptionCsvRow,
    BazaNcIntegrationSubscriptionCmsListRequest
> {}

export class BazaNcIntegrationSubscriptionCmsNotifyUsersRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export type BazaNcIntegrationSubscriptionCmsNotifyUsersResponse = void;
