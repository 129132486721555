import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { DividendAlertState } from '@scaliolabs/sandbox-web/data-access';

@UntilDestroy()
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
    public layout: string;
    public openedMenu: boolean;
    public wrapper: string;

    showDividendAlert$ = this.store.select(DividendAlertState.show);

    constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly store: Store) {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event) => event instanceof NavigationEnd),
                tap(() => {
                    // close menu on route change
                    this.openedMenu = false;
                }),
                map(() => {
                    return this.route;
                }),
                map((response) => {
                    while (response.firstChild) {
                        response = response.firstChild;
                    }
                    return response;
                }),
                filter((response) => response.outlet === 'primary'),
                mergeMap((response) => response.data),
            )
            .subscribe((response) => {
                this.layout = response.layout || 'full';
                this.wrapper = response.wrapper;
            });
    }
}
