import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';
import { CategoriesDto, CategoriesEndpoint, CategoriesEndpointPaths, CategoryDto } from '@scaliolabs/baza-content-types-shared';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class CategoriesDataAccess implements CategoriesEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    get(urlOrId: string | number): Observable<CategoryDto> {
        return this.http.get(
            replacePathArgs(CategoriesEndpointPaths.get, {
                urlOrId,
            }),
        );
    }

    getAll(): Observable<CategoriesDto> {
        return this.http.get(CategoriesEndpointPaths.getAll);
    }
}
