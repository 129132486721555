import { BazaNgCoreModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { Application, getBazaProjectCodeName, getBazaProjectName } from '@scaliolabs/baza-core-shared';
import { BazaWebBundleConfig } from '../baza-web-bundle.config';

export const BAZA_NG_CORE_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaNgCoreModuleForRootOptions = (config) => ({
    deps: [],
    useFactory: () => ({
        application: Application.WEB,
        project: {
            name: getBazaProjectName(),
            codeName: getBazaProjectCodeName(),
        },
        ngEnv: config.environment,
        defaultMessageDurationMilliseconds: 5000,
        localStorageKeyPrefix: `${getBazaProjectCodeName().toLowerCase()}Web`,
        sessionStorageKeyPrefix: `${getBazaProjectCodeName().toLowerCase()}Web`,
    }),
});
