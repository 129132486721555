import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaCreditCardType } from '@scaliolabs/baza-core-shared';

/**
 * Credit Card Details
 */
export class BazaNcCreditCardDetailsDto {
    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '9094',
    })
    creditCardNumber: string;

    @ApiModelProperty({
        description: 'Card Type',
        example: BazaCreditCardType.Visa,
    })
    cardType: BazaCreditCardType;
}
