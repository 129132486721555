import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { BazaReferralCodeDto } from '../dto/baza-referral-code.dto';
import { BazaReferralCampaignDto } from '../dto/baza-referral-campaign.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { Observable } from 'rxjs';

export enum BazaReferralCampaignCmsEndpointPaths {
    list = '/baza-core/cms/referral-codes/campaigns/list',
    getById = '/baza-core/cms/referral-codes/campaigns/getById',
    create = '/baza-core/cms/referral-codes/campaigns/create',
    edit = '/baza-core/cms/referral-codes/campaigns/edit',
    delete = '/baza-core/cms/referral-codes/campaigns/delete',
    exportListToCSV = '/baza-core/cms/referral-codes/campaigns/exportListToCSV',
}

export interface BazaReferralCampaignCmsEndpoint {
    list(request: ReferralCampaignCmsListRequest): Promise<ReferralCampaignCmsListResponse> | Observable<ReferralCampaignCmsListResponse>;
    getById(
        request: ReferralCampaignCmsGetByIdRequest,
    ): Promise<ReferralCampaignCmsGetByIdResponse> | Observable<ReferralCampaignCmsGetByIdResponse>;
    create(request: ReferralCampaignCmsCreateRequest): Promise<BazaReferralCampaignDto> | Observable<BazaReferralCampaignDto>;
    edit(request: ReferralCampaignCmsEditRequest): Promise<BazaReferralCampaignDto> | Observable<BazaReferralCampaignDto>;
    delete(request: ReferralCampaignCmsDeleteRequest): Promise<void> | Observable<void>;
    exportListToCSV(request: ReferralCampaignCmsExportListToCsvRequest): Promise<string> | Observable<string>;
}

export class ReferralCampaignCmsListRequest extends CrudListRequestDto<BazaReferralCampaignDto> {}

export class ReferralCampaignCmsListResponse extends CrudListResponseDto<BazaReferralCampaignDto> {
    @ApiModelProperty({
        type: BazaReferralCampaignDto,
        isArray: true,
    })
    items: Array<BazaReferralCampaignDto>;
}

export class ReferralCampaignCmsGetByIdRequest {
    @ApiModelProperty({
        required: true,
    })
    @IsNumber()
    id: number;

    @ApiModelProperty({
        description: 'If true will returns all existing referral codes in response',
        required: false,
    })
    @IsOptional()
    withFullListOfReferralCodes?: boolean;
}

export class ReferralCampaignCmsGetByIdResponse {
    @ApiModelProperty()
    campaign: BazaReferralCampaignDto;

    @ApiModelProperty({
        type: BazaReferralCodeDto,
        isArray: true,
    })
    referralCodes?: Array<BazaReferralCodeDto>;
}

export class ReferralCampaignCmsEntityBody {
    @ApiModelProperty()
    @IsString()
    name: string;

    @ApiModelProperty()
    @IsBoolean()
    isActive: boolean;
}

export class ReferralCampaignCmsCreateRequest extends ReferralCampaignCmsEntityBody {}

export class ReferralCampaignCmsEditRequest extends ReferralCampaignCmsEntityBody {
    @ApiModelProperty()
    @IsNumber()
    @IsNotEmpty()
    id: number;
}

export class ReferralCampaignCmsDeleteRequest {
    @ApiModelProperty()
    @IsNumber()
    @IsNotEmpty()
    id: number;
}

export class ReferralCampaignCmsExportListToCsvRequest extends CrudExportToCsvRequest<
    BazaReferralCampaignDto,
    ReferralCampaignCmsListRequest
> {}
