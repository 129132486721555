import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import {
    BazaNcBankAccountGetDefaultByTypeRequest,
    BazaNcBankAccountLinkOnSuccessRequest,
    BazaNcBankAccountType,
    BazaNcTransferRequest,
    BazaNcWithdrawRequest,
    CalculateRegulationLimitsRequestDto,
    DestroySessionDto,
    PurchaseFlowDto,
    PurchaseFlowInvestorRegulationLimitsDto,
    PurchaseFlowSessionDto,
    ReProcessPaymentDto,
} from '@scaliolabs/baza-nc-shared';
import { PFConfig } from '../models';

// purchase flow
export class GetPurchaseStats {
    static readonly type = '[Purchase] Get Purchase Stats';

    constructor(public offeringId: string, public requestedAmountCents: number) {}
}
export class CancelPurchase {
    static readonly type = '[Purchase] Cancel Purchase';

    constructor(public data: DestroySessionDto) {}
}
export class ClearPurchaseState {
    static readonly type = '[Purchase] ClearPurchaseState';
}

export class ClearPurchaseStats {
    static readonly type = '[Purchase] ClearPurchaseStats';
}

export class GetLimits {
    static readonly type = '[Purchase] GetLimits';
}

export class GetLimitsForPurchase {
    static readonly type = '[Purchase] GetLimitsForPurchase';

    constructor(public requestedAmountCents: number, public offeringId: string) {}
}

export class SelectEntity {
    static readonly type = '[Purchase] Select Entity';

    constructor(public entityId: number) {}
}

export class UpdateCart {
    static readonly type = '[Purchase] Update Cart';

    constructor(public entity: BazaNcIntegrationListingsDto) {}
}

export class StartCart {
    static readonly type = '[Purchase] Start Cart';
}

export class StartPurchase {
    static readonly type = '[Purchase] Start Purchase';

    constructor(public data: PurchaseFlowSessionDto) {}
}

export class SubmitPurchase {
    static readonly type = '[Purchase] Submit Purchase';

    constructor(public tradeId: string) {}
}

export class PatchStartPurchase {
    static readonly type = '[Purchase] Patch StartPurchase';

    constructor(public purchaseStart: PurchaseFlowDto) {}
}

export class ReprocessPayment {
    static readonly type = '[Purchase] ReprocessPayment Payment';

    constructor(public data: ReProcessPaymentDto) {}
}

// wallet
export class LoadAccountBalance {
    static readonly type = '[Purchase] LoadAccountBalance';
}
export class InvestorAccountTouch {
    static readonly type = '[Purchase] InvestorAccountTouch';
}

// cash-in / cash-out
export class PlaidOnSuccessLink {
    static readonly type = '[Purchase] PlaidOnSuccessLink';

    constructor(public data: BazaNcBankAccountLinkOnSuccessRequest) {}
}

export class GetDefaultBankAccount {
    static readonly type = '[Purchase] GetDefaultBankAccount';
    constructor(public type: BazaNcBankAccountType, public request: BazaNcBankAccountGetDefaultByTypeRequest) {}
}

// add / withdraw funds
export class TransferFunds {
    static readonly type = '[Purchase] TransferFunds';

    constructor(public transferRequest: BazaNcTransferRequest) {}
}

export class WithdrawFunds {
    static readonly type = '[Purchase] WithdrawFunds';

    constructor(public withdrawRequest: BazaNcWithdrawRequest) {}
}

// ach bank
export class LoadBankAccount {
    static readonly type = '[Purchase] LoadBankAccount';
}

// stripe card
export class UnlinkCreditCardStripe {
    static readonly type = '[Purchase] UnlinkCreditCardStripe';
}

export class LoadCreditCardStripe {
    static readonly type = '[Purchase] LoadCreditCardStripe';
}

export class UpsertCreditCardStripe {
    static readonly type = '[Purchase] UpsertCreditCardStripe';
}

// reg-cf limits
export class RecalculateLimits {
    static readonly type = '[Purchase] RecalculateLimits';

    constructor(public regCfLimitRequest: CalculateRegulationLimitsRequestDto) {}
}

export class ResetRegCFLimitFromStats {
    static readonly type = '[Purchase] ResetRegCFLimitFromStats';

    constructor(public regCfStatLimits: PurchaseFlowInvestorRegulationLimitsDto) {}
}

// plaid links
export class LoadPlaidPluginLink {
    static readonly type = '[Purchase] LoadPlaidPluginLink';
}

export class LoadPlaidLegacyLink {
    static readonly type = '[Purchase] LoadPlaidLegacyLink';
}

// stripe link
export class LoadNCStripeLink {
    static readonly type = '[Purchase] LoadNCStripeLink';
}

// configs
export class SetPFConfig {
    static readonly type = '[Purchase] SetPFConfig';

    constructor(public pfConfig: PFConfig) {}
}
