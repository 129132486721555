import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class DependentArraySchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty({
        description: 'Title for DropDown selector',
    })
    dropDownTitle: string;

    @ApiModelProperty({
        description: 'Values in Drop Down selector',
        type: 'string',
        isArray: true,
    })
    dropDownValues: Array<string>;

    @ApiModelProperty({
        description: 'Title for Text field',
    })
    textFieldTitle: string;
}

export class DependentArraySchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.DependantArray],
    })
    type: BazaNcIntegrationSchemaType.DependantArray;

    @ApiModelProperty()
    payload: DependentArraySchemaDefinitionPayload;
}
