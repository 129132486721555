import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MktPageDto } from '@scaliolabs/baza-content-types-shared';
import { BazaWebUtilSharedService, MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { HowItWorksCardComponent } from '../how-it-works-card';

@Component({
    selector: 'app-how-it-works',
    templateUrl: './how-it-works.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzGridModule, HowItWorksCardComponent],
})
export class HowItWorksComponent extends MktSectionCommonFields {
    @Input()
    hiwSections: MktPageDto[];

    constructor(public readonly wts: BazaWebUtilSharedService) {
        super();
    }
}
