<div
    class="text-image"
    [class.text-image-right]="isTextOnRight(wts.getI18nLabel(i18nBasePath, 'txtPlacement'))">
    <div class="container text-image__container">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="text-image__col text-image__col-text">
                <div class="text-image__wrap">
                    <div
                        class="text-image__subtitle"
                        [innerHtml]="label"></div>

                    <div
                        class="text-image__heading"
                        *ngIf="header"
                        [innerHtml]="header | sanitizeHtml"></div>

                    <div
                        class="text-image__descr"
                        *ngIf="description"
                        [innerHtml]="description | sanitizeHtml"></div>
                </div>
            </div>

            <div
                nz-col
                nzXs="12"
                class="text-image__col text-image__col-img"
                *ngIf="imgSafeStyle"
                [style.background-image]="imgSafeStyle"></div>
        </div>
    </div>
</div>
