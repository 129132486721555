import { BazaNcPayoffDistributionPaymentStatus } from '../models/baza-nc-payoff-distribution-payment-status';
import {
    BazaNcPayoffDistributionPaymentHistoryActions,
    bazaNcPayoffDistributionPaymentHistoryActionsSwagger,
} from '../models/baza-nc-payoff-distribution-payment-history-action';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcPayoffDistributionHistoryDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaNcPayoffDistributionPaymentStatus;

    @ApiModelProperty({
        ...bazaNcPayoffDistributionPaymentHistoryActionsSwagger(),
        description: 'Action & Action Details',
    })
    action: BazaNcPayoffDistributionPaymentHistoryActions;
}
