import { BazaPlaidEnvironment } from '../models/baza-plaid-environment';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

/**
 * Plaid Link Token Response
 * Use the DTO for Response DTOs of your Plaid Link integrations
 * @see BazaPlaidService.getPlaidLink
 */
export class BazaPlaidLinkResponseDto {
    @ApiModelProperty({
        description: 'Plaid Link Token',
    })
    linkToken: string;

    @ApiModelProperty({
        description: 'Plaid Environment',
    })
    environment: BazaPlaidEnvironment;
}
