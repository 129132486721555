import { Observable } from 'rxjs';
import { BazaAwsFileDto } from '../dto/baza-aws-file.dto';
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum BazaAwsEndpointPaths {
    upload = '/baza-aws/upload',
    delete = '/baza-aws/delete',
    presignedUrl = '/baza-aws/presignedUrl',
}

export interface BazaAwsEndpoint {
    upload(file: File | unknown): Promise<BazaAwsUploadResponse> | Observable<BazaAwsUploadResponse>;
    delete(request: BazaAwsDeleteRequest): Promise<void> | Observable<void>;
    presignedUrl(request: BazaAwsPresignedUrlRequest): Promise<string> | Observable<string>;
}

export type BazaAwsUploadResponse = BazaAwsFileDto;

export class BazaAwsDeleteRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    s3ObjectId: string;
}

export class BazaAwsPresignedUrlRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    s3ObjectId: string;

    @ApiModelProperty({
        required: false,
    })
    @IsNumber()
    @IsOptional()
    ttlSeconds?: number;
}
