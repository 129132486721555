import { BazaNcOfferingDetailsDto } from '../../offering';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsString } from 'class-validator';

export enum NcOfferingUiStatus {
    NotFound = 'NotFound',
    Used = 'Used',
    Available = 'Available',
}

export class NcOfferingUiDetailsRequest {
    @ApiModelProperty()
    @IsString()
    ncOfferingId: string;
}

export class NcOfferingUiDetailsResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(NcOfferingUiStatus),
    })
    status: NcOfferingUiStatus;

    @ApiModelProperty({
        required: false,
    })
    details?: BazaNcOfferingDetailsDto;
}
