export const PaymentPayoutMethodsEnI18n = {
    emptyStates: {
        new: {
            title: `Payment Methods`,
            descr: `You will see your linked payment method here after your first Purchase`,
        },
        inProgress: {
            title: `Payment Methods`,
            descr: `Please complete your profile verification to access the Payment Methods`,
            btnCTA: {
                label: `Update Your Profile`,
            },
        },
    },
    actions: {
        btnCTA: {
            label: `Update Your Profile`,
        },
    },
};
