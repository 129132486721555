import { LoginData } from '../../models';

export class Login {
    static readonly type = '[Auth] Login';

    constructor(public readonly data: LoginData) {}
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class ClearUser {
    static readonly type = '[Auth] ClearUser';
}

export class StartApp {
    static readonly type = '[Auth] Start';
}
