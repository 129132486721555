import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'number',
})
export class NumberPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: string | number): string {
        return this.decimalPipe.transform(value, '1.0-2');
    }
}
