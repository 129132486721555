import { Observable } from 'rxjs';
import { StatsRequestDto } from '../dto/stats-request.dto';
import { StatsDto } from '../dto/stats.dto';
import { PurchaseFlowSessionDto } from '../dto/session.dto';
import { ValidateResponseDto } from '../dto/validate-response.dto';
import { PurchaseFlowDto } from '../dto/purchase-flow.dto';
import { SubmitPurchaseFlowDto } from '../dto/submit-purchase-flow.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty } from 'class-validator';
import { DestroySessionDto } from '../dto/destroy-session.dto';
import { PurchaseFlowPersonalInfoDto } from '../dto/personal-info.dto';
import { ReProcessPaymentDto } from '../dto/reprocess-payment.dto';
import { PurchaseFlowCurrentDto } from '../dto/purchase-flow-current.dto';
import { OfferingId } from '../../../transact-api';
import { RegulationLimitsDto } from '../dto/regulation-limits.dto';
import {CalculateRegulationLimitsRequestDto} from "../dto/calculate-regulation-limits-request.dto";

export enum BazaNcPurchaseFlowEndpointPaths {
    current = '/baza-nc/purchase-flow/current/:offeringId',
    stats = '/baza-nc/purchase-flow/stats',
    validate = '/baza-nc/purchase-flow/validate',
    session = '/baza-nc/purchase-flow/session',
    submit = '/baza-nc/purchase-flow/submit',
    reprocessPayment = '/baza-nc/purchase-flow/reprocessPayment',
    destroy = '/baza-nc/purchase-flow/destroy',
    getPersonalInfo = '/baza-nc/purchase-flow/getPersonalInfo',
    calculateRegulationLimits = '/baza-nc/purchase-flow/calculateRegulationLimits',
}

export interface BazaNcPurchaseFlowEndpoint {
    current(offeringId: OfferingId, ...args: unknown[]): Promise<PurchaseFlowCurrentDto> | Observable<PurchaseFlowCurrentDto>;
    stats(request: StatsRequestDto, ...args: unknown[]): Promise<StatsDto> | Observable<StatsDto>;
    calculateRegulationLimits(
        request: CalculateRegulationLimitsRequestDto,
        ...args: unknown[]
    ): Promise<RegulationLimitsDto> | Observable<RegulationLimitsDto>;
    validate(request: PurchaseFlowSessionDto, ...args: unknown[]): Promise<ValidateResponseDto> | Observable<ValidateResponseDto>;
    session(request: PurchaseFlowSessionDto, ...args: unknown[]): Promise<PurchaseFlowDto> | Observable<PurchaseFlowDto>;
    submit(
        request: SubmitPurchaseFlowDto,
        ...args: unknown[]
    ): Promise<PurchaseFlowSubmitResponse> | Observable<PurchaseFlowSubmitResponse>;
    destroy(request: DestroySessionDto, ...args: unknown[]): Promise<PurchaseFlowDestroyResponse> | Observable<PurchaseFlowDestroyResponse>;
    getPersonalInfo(...args: unknown[]): Promise<PurchaseFlowPersonalInfoDto> | Observable<PurchaseFlowPersonalInfoDto>;
    reprocessPayment(
        request: ReProcessPaymentDto,
        ...args: unknown[]
    ): Promise<ReProcessPaymentResponse> | Observable<ReProcessPaymentResponse>;
}

export class PurchaseFlowSubmitResponse {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    success: boolean;
}

export class PurchaseFlowDestroyResponse {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    success: boolean;
}

export class ReProcessPaymentResponse {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    success: boolean;
}
