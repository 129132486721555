import { Injectable } from '@angular/core';
import { I18nEndpoint, BazaI18nEndpointPaths, BazaI18nGetRequest, BazaI18nGetResponse } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaI18nDataAccess implements I18nEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    get(request: BazaI18nGetRequest): Observable<BazaI18nGetResponse> {
        return this.ngEndpoint.post<BazaI18nGetResponse>(BazaI18nEndpointPaths.get, request);
    }
}
