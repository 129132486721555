import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { BazaNcIntegrationSubscribeResponse } from '../models/baza-nc-integration-subscribe-response';
import { BazaNcIntegrationUnsubscribeResponse } from '../models/baza-nc-integration-unsubscribe-response';

export enum BazaNcIntegrationSubscriptionEndpointPaths {
    subscribe = '/baza-nc-integration/subscription/subscribe',
    unsubscribe = '/baza-nc-integration/subscription/unsubscribe',
}

export interface BazaNcIntegrationSubscriptionEndpoint {
    subscribe(
        request: BazaNcIntegrationSubscribeToRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationSubscribedResponse> | Observable<BazaNcIntegrationSubscribedResponse>;
    unsubscribe(
        request: BazaNcIntegrationUnsubscribeRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationUnsubscribedResponse> | Observable<BazaNcIntegrationUnsubscribedResponse>;
}

export class BazaNcIntegrationSubscribeToRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationSubscribedResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationSubscribeResponse),
        description:
            'Subscribe operation response. "Subscribed" - user had no subscriptions before and subscribed; "AlreadySubscribed" - user already has subscription',
    })
    status: BazaNcIntegrationSubscribeResponse;
}

export class BazaNcIntegrationUnsubscribeRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationUnsubscribedResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationSubscribeResponse),
        description:
            'Unsubscribe operation response. "Unsubscribed" - user had subscription before and unsubsribed; "AlreadyUnsubscribed" - user had subscription before and already unsubscribed; "NotSubscribed" - user had no subscription before',
    })
    status: BazaNcIntegrationUnsubscribeResponse;
}
