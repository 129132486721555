import { FirstEntryType } from '../models/first-entry-type';
import { RelatedEntryType } from '../models/related-entry-type';
import { LinkType } from '../models/link-type';
import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';

export class CreateLinkRequest {
    @IsEnum(FirstEntryType)
    @IsNotEmpty()
    firstEntryType: FirstEntryType;

    @IsString()
    @IsNotEmpty()
    firstEntry: string;

    @IsEnum(RelatedEntryType)
    @IsNotEmpty()
    relatedEntryType: RelatedEntryType;

    @IsString()
    @IsNotEmpty()
    relatedEntry: string;

    @IsEnum(LinkType)
    @IsNotEmpty()
    linkType: LinkType;

    @Min(0)
    @Max(1)
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    primary_value: 0 | 1;

    @IsString()
    @IsOptional()
    notes?: string;
}
