const REGEX_PROJECT_CODENAME = /^[a-zA-Z_\d+]{0,8}$/;

/**
 * Baza Project Configuration (Interface)
 */
export interface BazaCommonProjectConfig {
    projectName: string;
    projectCodeName: string;
    clientName: string;
}

/**
 * Returns default Baza Project configuration
 */
function defaultBazaProject(): BazaCommonProjectConfig {
    return {
        projectName: 'Baza',
        projectCodeName: 'BAZA',
        clientName: 'Baza Client',
    };
}

/**
 * Baza Project Configuration actual location
 * TODO: Remove global usage of BAZA_PROJECT constant but keep it for a while - current projects may still use it
 */
const BAZA_PROJECT: BazaCommonProjectConfig = defaultBazaProject();

/**
 * Partially updates Baza Client Configuration
 * @param config
 */
export function configureBazaProject(config: Partial<BazaCommonProjectConfig>): void {
    const newConfig = {
        ...BAZA_PROJECT,
        ...config,
    };

    if (!REGEX_PROJECT_CODENAME.test(newConfig.projectCodeName)) {
        throw new Error('Baza Project CodeName should contains matches "/^[a-zA-Z_\\d+]{0,8}$/" regex');
    }

    Object.assign(BAZA_PROJECT, config);
}

/**
 * Returns Project Name
 */
export function getBazaProjectName(): string {
    return BAZA_PROJECT.projectName;
}

/**
 * Returns Project CodeName.
 * Use Project CodeName for local storage keys, environment variables & anything else
 */
export function getBazaProjectCodeName(): string {
    return BAZA_PROJECT.projectCodeName;
}

/**
 * Returns Client Name
 */
export function getBazaClientName(): string {
    return BAZA_PROJECT.clientName;
}
