import { OfferingId } from '../models/offering-id';
import { IsNotEmpty, IsString } from 'class-validator';


export class AddSubscriptionsForOfferingRequest {
    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;
}
