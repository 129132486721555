import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';
import { NzDateMode } from 'ng-zorro-antd/date-picker';

export class DateRangeSchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty({
        description: 'Select the scope of the date range selection',
        required: false,
    })
    mode?: any;

    @ApiModelProperty({
        description: 'Display a button to quickly select the current date',
        required: false,
    })
    showToday?: boolean;

    @ApiModelProperty({
        description: 'Allow selection of specific time in addition to date',
        required: false,
    })
    showTime?: boolean;

    @ApiModelProperty({
        description: 'Display a button to select the current time when "Show time picker" is enabled',
        required: false,
    })
    showNow?: boolean;
}

export class DateRangeSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.DateRange],
    })
    type: BazaNcIntegrationSchemaType.DateRange;

    @ApiModelProperty()
    payload: DateRangeSchemaDefinitionPayload;
}
