import { CommonModule } from '@angular/common';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { AccountPaymentPayoutConfig } from '../../data-access';
import { DwollaPaymentMethodsComponent } from './payment-methods';
import { DwollaPayoutMethodsComponent } from './payout-methods';

@Component({
    selector: 'app-payment-payout-methods',
    templateUrl: './payment-payout-methods.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, DwollaPaymentMethodsComponent, DwollaPayoutMethodsComponent, NzEmptyModule, NzButtonModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentPayoutMethodsComponent implements OnInit {
    @Input()
    dwollaSupport = true;

    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    config: AccountPaymentPayoutConfig;

    @Output()
    redirectToVFClicked = new EventEmitter<void>();

    i18nBasePath = 'dwacc.paymentAndPayout';
    @ContentChild('paymentMethodHeader') paymentMethodHeader: TemplateRef<any>;
    @ContentChild('payoutMethodHeader') payoutMethodHeader: TemplateRef<any>;

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    ngOnInit(): void {
        this.checkConfig();
    }

    private checkConfig() {
        const defaultConfig: AccountPaymentPayoutConfig = {
            paymentMethodsConfig: {
                accountBalanceConfig: {
                    showAccountBalance: this.dwollaSupport ?? true,
                    showCashInAccount: this.dwollaSupport ?? true,
                },
                bankConfig: {
                    showBankAccount: true,
                    sectionHeader: null,
                },
                cardConfig: {
                    showCreditCard: true,
                    sectionHeader: null,
                },
            },
            payoutMethodsConfig: {
                showPayoutSection: true,
                isAnyAssetPurchased: false,
            },
        };

        this.config = this.wts.mergeConfig(defaultConfig, this.config);
    }
}
