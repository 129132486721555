import { Injectable } from '@angular/core';
import { getBazaClientName, getBazaProjectCodeName, getBazaProjectName } from '@scaliolabs/baza-core-shared';

export const BAZA_NG_PROJECT_INJECT = {
    projectName: getBazaProjectName(),
    projectCodeName: getBazaProjectCodeName(),
    clientName: getBazaClientName(),
};

@Injectable({
    providedIn: 'root',
})
export class BazaNgProjectService {
    get projectName(): string {
        return BAZA_NG_PROJECT_INJECT.projectName;
    }

    get projectCodeName(): string {
        return BAZA_NG_PROJECT_INJECT.projectCodeName;
    }

    get clientName(): string {
        return BAZA_NG_PROJECT_INJECT.clientName;
    }
}
