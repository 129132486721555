import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MktSectionCommonFields } from '@scaliolabs/baza-web-utils';

@Component({
    selector: 'app-how-it-works-card',
    templateUrl: './how-it-works-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HowItWorksCardComponent extends MktSectionCommonFields {
    @Input()
    index!: number;
}
