import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { IsArray, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BazaNcIntegrationSchemaTabField } from './baza-nc-integration-schema-tab-field';
import { Type } from 'class-transformer';

/**
 * Tab for Schema
 */
export class BazaNcIntegrationSchemaTab {
    @ApiModelProperty({
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    id: string;

    @ApiModelProperty({
        description: 'Tab Title',
    })
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description: 'Fields array',
        type: BazaNcIntegrationSchemaTabField,
        isArray: true,
        required: false,
    })
    @IsArray()
    @IsOptional()
    @Type(() => BazaNcIntegrationSchemaTabField)
    fields?: Array<BazaNcIntegrationSchemaTabField>;
}
