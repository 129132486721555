import { IsString, IsOptional, IsInt, IsPositive } from 'class-validator';
import { DwollaTransferStatus } from '../models/transfer-status';

export class GetTransferListForAccountRequest {
    @IsOptional()
    @IsString()
    search?: string;

    @IsOptional()
    @IsString()
    status?: DwollaTransferStatus;

    @IsOptional()
    @IsInt()
    @IsPositive()
    limit?: number;

    @IsOptional()
    @IsInt()
    @IsPositive()
    offset?: number;
}
