import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AccountState, ClearInitData, FeedHasAnyUpdates, FeedState, Logout } from '@scaliolabs/sandbox-web/data-access';
import { ClearPurchaseState } from '@scaliolabs/baza-web-purchase-flow';
import { ClearVerificationState } from '@scaliolabs/baza-web-verification-flow';
import { interval, shareReplay } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
    user$ = this.store.select(AccountState.account);
    hasAnyUpdates$ = this.store.select(FeedState.hasAnyUpdates).pipe(shareReplay());

    @Input()
    openedMenu: boolean;

    @Output()
    openedMenuChange = new EventEmitter<boolean>();

    constructor(private readonly store: Store, private router: Router) {}

    ngOnInit(): void {
        this.startUpdatesInterval();
    }

    // onLogout
    onLogout(): void {
        this.store.dispatch(new ClearInitData());
        this.store.dispatch(new ClearPurchaseState());
        this.store.dispatch(new ClearVerificationState());
        this.store.dispatch(new Logout()).subscribe(() => this.router.navigate(['/']));
    }

    // onMenuToggle
    onMenuToggle(): void {
        this.openedMenuChange.emit(!this.openedMenu);
    }

    private startUpdatesInterval(): void {
        interval(120000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new FeedHasAnyUpdates());
            });
    }
}
