import { NzUploadFile } from 'ng-zorro-antd/upload';
import { VFConfig, InvestorProfile, PersonalInformation } from '../models';

export class ApplyPersonalInfo {
    static readonly type = '[Verification] ApplyPersonalInfo';

    constructor(public data: PersonalInformation, public files: NzUploadFile[]) {}
}

export class ApplyInvestorProfile {
    static readonly type = '[Verification] ApplyInvestorProfile';

    constructor(public data: InvestorProfile) {}
}

export class ClearVerificationState {
    static readonly type = '[Verification] ClearVerificationState';
}

export class GetListStates {
    static readonly type = '[Verification] GetListStates';

    constructor(public country: string) {}
}

export class LoadFormResources {
    static readonly type = '[Verification] LoadFormResources';
}

export class LoadVerification {
    static readonly type = '[Verification] LoadVerification';
}

export class UploadDocument {
    static readonly type = '[Verification] UploadDocument';

    constructor(public file: NzUploadFile) {}
}

export class UploadPersonalInfoSSNDocument {
    static readonly type = '[Verification] UploadPersonalInfoSSNDocument';

    constructor(public file: NzUploadFile) {}
}

export class SetVFLinksConfig {
    static readonly type = '[Verification] SetVFLinksConfig';

    constructor(public vfConfig: VFConfig) {}
}
