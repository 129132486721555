import { BazaNcDividendPaymentStatus, BazaNcOperationType, TransactionState } from '@scaliolabs/baza-nc-shared';
import {
    BazaNcIntegrationPortfolioAssetDto,
    BazaNcIntegrationPortfolioStatementsDto,
    BazaNcIntegrationPortfolioTotalStatsDto,
} from '@scaliolabs/baza-nc-integration-shared';
import { BazaDwollaPaymentStatus } from '@scaliolabs/baza-dwolla-shared';

export enum ClientTransactionStatuses {
    Pending = 'Pending',
    Refunded = 'Refunded',
    Completed = 'Completed',
    Canceled = 'Canceled,',
    Failed = 'Failed',
}

export interface TransactionUnifiedDto {
    type: BazaNcOperationType;
    createdAt: string;
    amountCents: number;
    totalCents?: number;
    transactionFeesCents?: number;
    shares?: number;
    pricePerShareCents?: number;
    offeringId?: string;
    state: TransactionState | BazaNcDividendPaymentStatus | BazaDwollaPaymentStatus;
    name?: string;
    id?: number;
    ulid?: string;
}

export type Asset = BazaNcIntegrationPortfolioAssetDto;
export type PortfolioTotal = BazaNcIntegrationPortfolioTotalStatsDto;
export type Statement = BazaNcIntegrationPortfolioStatementsDto;
export type Transaction = TransactionUnifiedDto;

export const itemsOnPage = 12;

// TODO: CB:22Oct2021: TECH:DEBT: Remove string when all fields are specified.
export type ClientTransactionStatusesMap = Record<TransactionState, ClientTransactionStatuses | string>;
export type DividendsTransactionStatusesMap = Record<BazaNcDividendPaymentStatus, string>;

export const TransactionStatusMappins: ClientTransactionStatusesMap = {
    CREATED: 'Created',
    INVESTMENT_IS_CONFIRMED: ClientTransactionStatuses.Completed,
    NONE: 'Unknown',
    PAYMENT_CANCELLED: 'Cancelled',
    PAYMENT_FUNDED: 'Funds Received',
    PAYMENT_REJECTED: 'Rejected',
    PAYMENT_RETURNED: ClientTransactionStatuses.Failed,
    PENDING_PAYMENT: ClientTransactionStatuses.Pending,
    UNWIND_CANCELLED: 'Refund Cancelled',
    UNWIND_PENDING: 'Pending Refund',
    UNWIND_SETTLED: ClientTransactionStatuses.Refunded,
};

export const DividendsStatusMapping: DividendsTransactionStatusesMap = {
    Pending: 'Pending',
    Processed: 'Paid',
    Cancelled: 'Cancelled',
    Failed: 'Failed',
};
