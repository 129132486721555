import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcTaxDocumentDto } from '../dto/baza-nc-tax-document.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Type } from 'class-transformer';

export enum BazaNcTaxDocumentEndpointPaths {
    get = '/baza-nc/tax-documents/id/:id',
    list = '/baza-nc/tax-documents/list',
}

export interface BazaNcTaxDocumentEndpoint {
    get(request: BazaNcTaxDocumentGetRequest, ...args: unknown[]): Promise<BazaNcTaxDocumentDto> | Observable<BazaNcTaxDocumentDto>;
    list(
        request: BazaNcTaxDocumentListRequest,
        ...args: unknown[]
    ): Promise<BazaNcTaxDocumentListResponse> | Observable<BazaNcTaxDocumentListResponse>;
}

export class BazaNcTaxDocumentGetRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    @Type(() => Number)
    id: number;
}

export class BazaNcTaxDocumentListRequest extends CrudListRequestQueryDto {}

export class BazaNcTaxDocumentListResponse extends CrudListResponseDto<BazaNcTaxDocumentDto> {
    @ApiModelProperty({
        type: BazaNcTaxDocumentDto,
        isArray: true,
    })
    items: Array<BazaNcTaxDocumentDto>;
}
