import { IsString, IsOptional, IsInt, IsPositive, IsEmail } from 'class-validator';
import { DwollaCustomerStatus } from '../models/customer-status';

export class GetCustomerListRequest {
    @IsOptional()
    @IsInt()
    @IsPositive()
    limit?: number;

    @IsOptional()
    @IsInt()
    @IsPositive()
    offset?: number;

    @IsOptional()
    @IsString()
    search?: string;

    @IsOptional()
    @IsString()
    @IsEmail()
    email?: string;

    @IsOptional()
    @IsString()
    status?: DwollaCustomerStatus;

}
