import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountVerificationPersonalInformationDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
    selector: 'app-personal-info',
    templateUrl: './personal-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzPopoverModule],
})
export class PersonalInfoComponent {
    @Input()
    info: AccountVerificationPersonalInformationDto;

    @Output()
    editPersonalInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

    public i18nBasePath = 'uic.personal';

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    public get formattedAddress(): string {
        let address = `${this.info?.residentialStreetAddress1}, `;

        if (this.info?.residentialStreetAddress2) {
            address += `${this.info?.residentialStreetAddress2}, `;
        }

        address += `${this.info?.residentialCity}, ${this.info?.residentialState ? this.info?.residentialState : ''} ${
            this.info?.residentialZipCode
        } ${this.info?.residentialCountry}`;

        return address;
    }

    public editPersonalInformation() {
        this.editPersonalInfo.emit(true);
    }
}
