import { Observable } from 'rxjs';
import { AuthMasterPasswordDto } from '../dto/auth-master-password.dto';

export enum AuthMasterPasswordEndpointPaths {
    enable = '/baza-auth/auth/master-password/enable',
    disable = '/baza-auth/auth/master-password/disable',
    current = '/baza-auth/auth/master-password/current',
    refresh = '/baza-auth/auth/master-password/refresh',
}

export interface AuthMasterPasswordEndpoint {
    enable(): Promise<void> | Observable<void>;
    disable(): Promise<void> | Observable<void>;
    current(): Promise<AuthMasterPasswordDto> | Observable<AuthMasterPasswordDto>;
    refresh(): Promise<AuthMasterPasswordDto> | Observable<AuthMasterPasswordDto>;
}
