import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { PartyId } from '../models/party-id';

export class UpdateKycAmlRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    noOfqns: number;

    @IsString()
    @IsNotEmpty()
    idNumber: string;
}
