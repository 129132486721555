import { BazaVersionNgModuleForRootOptions, BazaVersionNgStrategy } from '@scaliolabs/baza-core-ng';
import { BazaHttpHeaders } from '@scaliolabs/baza-core-shared';
import { BazaWebBundleConfig } from '../baza-web-bundle.config';

export const BAZA_NG_VERSION_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaVersionNgModuleForRootOptions = (config) => ({
    deps: [],
    useFactory: () => ({
        strategy: {
            type: BazaVersionNgStrategy.ProvideVersion,
            version: config.builtOnVersion,
            redirectTo: [config.links.updateNeededUrl],
        },
        xHttpHeaderVersion: BazaHttpHeaders.HTTP_HEADER_VERSION,
        xHttpHeaderSkipVersion: BazaHttpHeaders.HTTP_HEADER_VERSION_SKIP,
    }),
});

