import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';

export class ResetInvestorAccountRequestDto {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;
}
