import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { TestimonialDto } from '../dto/testimonial.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';

export enum TestimonialsEndpointPaths {
    list = '/baza-content-types/testimonials/list',
    getAll = '/baza-content-types/testimonials/all',
    getById = '/baza-content-types/testimonials/id/:id',
    getByUrl = '/baza-content-types/testimonials/url/:url',
}

export interface TestimonialsEndpoint {
    list(request: TestimonialsListRequest): Promise<TestimonialsListResponse> | Observable<TestimonialsListResponse>;
    getAll(): Promise<Array<TestimonialDto>> | Observable<Array<TestimonialDto>>;
    getById(id: number): Promise<TestimonialDto> | Observable<TestimonialDto>;
    getByUrl(url: string): Promise<TestimonialDto> | Observable<TestimonialDto>;
}

export class TestimonialsListRequest extends CrudListRequestQueryDto {}

export class TestimonialsListResponse extends CrudListResponseDto<TestimonialDto> {
    @ApiModelProperty({
        type: TestimonialDto,
        isArray: true,
    })
    items: Array<TestimonialDto>;
}
