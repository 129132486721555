import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BazaPhoneCountryCodesDataAccessModule } from '@scaliolabs/baza-core-data-access';

import { PhoneCodeState } from './state';

@NgModule({
    imports: [NgxsModule.forFeature([PhoneCodeState]), BazaPhoneCountryCodesDataAccessModule],
})
export class PhoneCodeStateModule {}
