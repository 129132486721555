import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    BazaNcBankAccountAchDto,
    BazaNcBootstrapDto,
    BazaNcCreditCardDto,
    BazaNcLimitsDto,
    BazaNcPurchaseFlowTransactionType,
} from '@scaliolabs/baza-nc-shared';
import { GetLimits, LoadBankAccount, LoadCreditCardStripe, PurchaseState, PaymentMethodType } from '@scaliolabs/baza-web-purchase-flow';
import { TransactionUnifiedDto } from '@scaliolabs/sandbox-web/data-access';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-retry-nc-payment',
    templateUrl: './retry-nc-payment.component.html',
    styleUrls: ['./retry-nc-payment.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryNcPaymentComponent implements OnInit {
    @Input()
    set initData(value: BazaNcBootstrapDto) {
        this.initData$.next(value);
    }
    private initData$: BehaviorSubject<BazaNcBootstrapDto> = new BehaviorSubject<BazaNcBootstrapDto>(null);

    bankAccount$ = this.store.select(PurchaseState.bankAccount);
    creditCard$ = this.store.select(PurchaseState.creditCardStripe);
    limits$ = this.store.select(PurchaseState.limits);

    @Input() isVisible!: boolean;
    @Input() transaction!: TransactionUnifiedDto;

    @Output() isVisibleChange = new EventEmitter<boolean>();
    @Output() retry = new EventEmitter<BazaNcPurchaseFlowTransactionType>();

    selectedPaymentMethod!: PaymentMethodType | null;
    dataStream$: Observable<{
        initData: BazaNcBootstrapDto;
        bank: BazaNcBankAccountAchDto;
        card: BazaNcCreditCardDto;
        limits: BazaNcLimitsDto;
    }>;

    constructor(private readonly store: Store) {}

    ngOnInit(): void {
        this.initializeDataStream();
    }

    initializeDataStream() {
        this.dataStream$ = this.store.dispatch([new LoadBankAccount(), new LoadCreditCardStripe(), new GetLimits()]).pipe(
            switchMap(() => {
                return combineLatest([this.initData$, this.bankAccount$, this.creditCard$, this.limits$]).pipe(
                    map(([initData, bank, card, limits]) => ({
                        initData,
                        bank,
                        card,
                        limits,
                    })),
                    skipWhile((pair) => !pair?.initData || !pair?.bank || !pair?.card || !pair.limits),
                    distinctUntilChanged(),
                    debounceTime(0),
                    tap((pair) => {
                        const isBankAvailable = pair?.bank?.isAvailable;
                        const isCardAvailable = pair?.card?.isAvailable;

                        if (!this.selectedPaymentMethod) {
                            this.selectedPaymentMethod = isBankAvailable
                                ? PaymentMethodType.bankAccount
                                : isCardAvailable
                                ? PaymentMethodType.creditCard
                                : null;
                        }
                    }),
                );
            }),
        );
    }

    onClosed() {
        this.isVisibleChange.emit(false);
    }

    onPaymentMethodChange(paymentMethod: PaymentMethodType) {
        this.selectedPaymentMethod = paymentMethod;
    }

    onPay() {
        const transactionTypes: Record<PaymentMethodType, BazaNcPurchaseFlowTransactionType> = {
            bankAccount: BazaNcPurchaseFlowTransactionType.ACH,
            creditCard: BazaNcPurchaseFlowTransactionType.CreditCard,
            accountBalance: BazaNcPurchaseFlowTransactionType.AccountBalance,
        };

        this.retry.emit(transactionTypes[this.selectedPaymentMethod]);
    }
}
