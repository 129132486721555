import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PurchaseFlowLimitsDto } from './purchase-flow-limits.dto';
import { PurchaseFlowInvestorRegulationLimitsDto } from './purchase-flow-investor-regulation-limits.dto';

export class StatsDto {
    @ApiModelProperty({
        required: false,
        description: 'Additional message',
    })
    message?: string;

    @ApiModelProperty({
        description: 'How many shares can purchase current user at this time?',
    })
    canPurchase: number;

    @ApiModelProperty({
        description: 'Maximum number of shares for offering',
    })
    numSharesMax: number;

    @ApiModelProperty({
        description: 'Total purchased amount ($)',
    })
    totalPurchasedAmount: number;

    @ApiModelProperty({
        description: 'Total purchased amount (as cents)',
    })
    totalPurchasedAmountCents: number;

    @ApiModelProperty({
        description: 'How many shares left to purchase?',
    })
    totalOfferingSharesRemaining: number;

    @ApiModelProperty({
        description: 'How many shares available for user? (Maximum Shares per Account - Total Purchased)',
    })
    numSharesAvailableForUser: number;

    @ApiModelProperty({
        description: 'How many shares purchases by user?',
    })
    numSharesAlreadyPurchasedByUser: number;

    @ApiModelProperty({
        description: 'How many shares reserved by other users?',
    })
    numSharesPurchasedByOtherUsers: number;

    @ApiModelProperty({
        description: 'How many shares reserved by other users? (i.e. currently purchasing)',
    })
    numSharesReservedByOtherUsers: number;

    @ApiModelProperty({
        description: 'Minimum number of shares purchaseable for a offering.',
        type: PurchaseFlowLimitsDto,
        isArray: true,
    })
    numMinPurchaseAbleShares: number;

    @ApiModelProperty({
        description: 'Additional information about available transaction types for requested amount (if provided)',
        type: PurchaseFlowLimitsDto,
        isArray: true,
    })
    availableTransactionTypes?: Array<PurchaseFlowLimitsDto>;

    @ApiModelProperty({
        description: 'Boolean for a valid purchase on requested offering.',
    })
    isValidPurchase: boolean;

    @ApiModelProperty({
        description: 'Information about user regulation limits (based on reg types RegA, RegD, RegCF)',
        type: PurchaseFlowInvestorRegulationLimitsDto,
        required: false,
    })
    regulationLimits?: PurchaseFlowInvestorRegulationLimitsDto;
}
