import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaCreditCardType } from '../models/baza-credit-card-type';
import { Observable } from 'rxjs';
import { BazaCreditCardDto } from '../dto/baza-credit-card.dto';
import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { BazaCreditCardMonth } from '../models/baza-credit-card-month';
import { BazaCreditCardExpiredResponse } from '../models/baza-credit-card-expired-response';

export enum BazaCreditCardEndpointPaths {
    validate = '/baza-core/credit-card/validate',
    details = '/baza-core/credit-card/details',
    isExpired = '/baza-core/credit-card/isExpired',
}

export interface BazaCreditCardEndpoint {
    validate(request: BazaCreditCardValidateRequest): Promise<BazaCreditCardValidateResponse> | Observable<BazaCreditCardValidateResponse>;
    details(request: BazaCreditCardDetailsRequest): Promise<BazaCreditCardDetailsResponse> | Observable<BazaCreditCardDetailsResponse>;
    isExpired(
        request: BazaCreditCardIsExpiredRequest,
    ): Promise<BazaCreditCardIsExpiredResponse> | Observable<BazaCreditCardIsExpiredResponse>;
}

export class BazaCreditCardValidateRequest {
    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '4026000000000000',
    })
    @IsString()
    creditCardNumber: string;

    @ApiModelProperty({
        required: false,
        description: 'Accept only some set of credit cards',
        isArray: true,
        type: 'string',
        enum: Object.values(BazaCreditCardType),
    })
    @IsEnum(BazaCreditCardType, { each: true })
    @IsOptional()
    accept?: Array<BazaCreditCardType>;
}

export class BazaCreditCardValidateResponse {
    @ApiModelProperty({
        description: 'Is valid?',
    })
    isValid: boolean;

    @ApiModelProperty({
        required: false,
        description:
            'Detected Credit Card Type. Note: if you provide "accept" array in request, the field will be also filled (but "isValid" flag will be false)',
        type: 'string',
        enum: Object.values(BazaCreditCardType),
    })
    creditCardType?: BazaCreditCardType;
}

export class BazaCreditCardDetailsRequest {
    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '4026000000000000',
    })
    @IsString()
    creditCardNumber: string;

    @ApiModelProperty({
        required: false,
        description: 'Accept only some set of credit cards',
        isArray: true,
        type: 'string',
        enum: Object.values(BazaCreditCardType),
    })
    @IsEnum(BazaCreditCardType, { each: true })
    @IsOptional()
    accept?: Array<BazaCreditCardType>;
}

export class BazaCreditCardDetailsResponse {
    @ApiModelProperty({
        description: 'Is valid?',
    })
    isValid: boolean;

    @ApiModelProperty({
        required: false,
        description: 'Credit Card Details',
    })
    creditCard?: BazaCreditCardDto;
}

export class BazaCreditCardIsExpiredRequest {
    @ApiModelProperty({
        type: 'number',
        description: 'Expire Month (1 - Jan, 2 - Feb, ..., 12 - Dec)',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    creditCardExpireMonth: BazaCreditCardMonth;

    @ApiModelProperty({
        type: 'number',
        description: 'Expire Year (21, 22, ...)',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    creditCardExpireYear: number;
}

export class BazaCreditCardIsExpiredResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaCreditCardExpiredResponse),
    })
    result: BazaCreditCardExpiredResponse;
}
