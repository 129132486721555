import { JobDto } from './job.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class JobCmsDto extends JobDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    countApplications: number;
}
