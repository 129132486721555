import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaNcDividendTransactionStatus } from '../models/baza-nc-dividend-transaction-status';

export class BazaNcDividendTransactionCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: '12/01/2022',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: '12/01/2022',
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: '12/01/2022',
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaNcDividendTransactionStatus),
        example: BazaNcDividendTransactionStatus.Draft,
    })
    status: BazaNcDividendTransactionStatus;

    @ApiModelProperty({
        description: 'Transaction Title',
        example: 'Dividend Transaction 1',
    })
    title: string;
}
