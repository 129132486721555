export enum OrderStatus {
    Created = 'CREATED',
    Pending = 'PENDING',
    Settled = 'SETTLED',
    Canceled = 'CANCELED',
    Rejected = 'REJECTED',
    Funded = 'FUNDED',
    UnwindCreated = 'UNWIND CREATED',
    UnwindPending = 'UNWIND PENDING',
    UnwindSettled = 'UNWIND SETTLED',
    UnwindCanceled = 'UNWIND CANCELED',
}
