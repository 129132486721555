import { AttachmentDto, BazaSeoDto, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsInt,
    IsNotEmpty,
    IsObject,
    IsOptional,
    IsPositive,
    IsString,
    ValidateNested,
} from 'class-validator';
import { Observable } from 'rxjs';
import { TimelineCmsDto } from '../dto/timeline-cms.dto';
import { Type } from 'class-transformer';

export enum TimelineCmsEndpointPaths {
    create = '/baza-content-types/cms/timeline/create',
    update = '/baza-content-types/cms/timeline/update',
    delete = '/baza-content-types/cms/timeline/delete',
    list = '/baza-content-types/cms/timeline/list',
    getById = '/baza-content-types/cms/timeline/getById',
}

export interface TimelineCmsEndpoint {
    create(request: TimelineCmsCreateRequest): Promise<TimelineCmsDto> | Observable<TimelineCmsDto>;
    update(request: TimelineCmsUpdateRequest): Promise<TimelineCmsDto> | Observable<TimelineCmsDto>;
    delete(request: TimelineCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: TimelineCmsListRequest): Promise<TimelineCmsListResponse> | Observable<TimelineCmsListResponse>;
    getById(request: TimelineCmsGetByIdRequest): Promise<TimelineCmsDto> | Observable<TimelineCmsDto>;
}

export class TimelineCmsEntityBody {
    @ApiModelProperty({
        description: 'Is available for Public API?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty({
        description:
            'Timeline Category (Group). If specified, Timeline will not be displayed in Timeline Content Type' +
            'CMS and could be associated with different items',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    categoryId?: number;

    @ApiModelProperty({
        description: 'Tags',
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds: Array<number>;

    @ApiModelProperty({
        description: 'Tags',
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    categoryIds: Array<number>;

    @ApiModelProperty({
        title: 'Date. All entries will be sorted by Date',
        example: new Date().toISOString(),
    })
    @IsDateString()
    @IsNotEmpty()
    date: string;

    @ApiModelProperty({
        title: 'Title',
    })
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description: 'Description (Plain Text, could be used as short version)',
        required: false,
    })
    @IsOptional()
    @IsString()
    textDescription?: string;

    @ApiModelProperty({
        description: 'Description (HTML)',
        required: false,
    })
    @IsOptional()
    @IsString()
    htmlDescription?: string;

    @ApiModelProperty({
        description: 'Image Attachment (Optional)',
        required: false,
    })
    @ValidateNested()
    @Type(() => AttachmentDto)
    @IsObject()
    @IsOptional()
    imageAttachment?: AttachmentDto;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, string>;

    @ApiModelProperty({
        description: 'SEO',
    })
    @Type(() => BazaSeoDto)
    @IsObject()
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class TimelineCmsCreateRequest extends TimelineCmsEntityBody {}

export class TimelineCmsUpdateRequest extends TimelineCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TimelineCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TimelineCmsListRequest extends CrudListRequestDto<TimelineCmsDto> {
    @ApiModelProperty({
        description:
            'Timeline Category (Group). If specified, Timeline will not be displayed in Timeline Content Type' +
            'CMS and could be associated with different items',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    categoryId?: number;
}

export class TimelineCmsListResponse extends CrudListResponseDto<TimelineCmsDto> {
    @ApiModelProperty({
        type: TimelineCmsDto,
        isArray: true,
    })
    items: Array<TimelineCmsDto>;
}

export class TimelineCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
