export const WebUiAddBankFormEnI18n = {
    form: {
        fields: {
            accountName: {
                label: 'Account Holder Full Name',
                validators: {
                    restrictedChars: 'Special characters are not allowed, only spaces and hyphen (-) are permitted',
                },
            },
            accountType: {
                label: 'Account Type',
                options: {
                    checking: 'Checking',
                    savings: 'Savings',
                },
                placeholder: 'Select One',
            },
            accountNumber: {
                label: 'Account Number',
                validators: {
                    minlength: 'Account Number has to be a minimum of {{ length }} symbols',
                },
            },
            accountRoutingNumber: {
                label: 'Routing Number',
                validators: {
                    onlynumbers: 'Only numbers are accepted',
                    minlength: 'The length should be exactly {{ min }} digits',
                    maxlength: 'The length should be exactly {{ max }} digits',
                    routingNumber: 'It should be a valid routing number',
                },
            },
        },
        genericValidators: {
            required: '{{ fieldLabel }} is required',
        },
    },
};
