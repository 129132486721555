import { Observable } from 'rxjs';
import { PurchaseFlowBankAccountDto } from '../dto/bank-account.dto';
import { PurchaseFlowPlaidLinkDto } from '../dto/plaid-link.dto';
import { PurchaseFlowSetBankAccountDetailsDto } from '../dto/set-bank-account.dto';

export enum BazaNcPurchaseFlowBankAccountEndpointPaths {
    getPlaidLink = '/baza-nc/purchase-flow/getPlaidLink',
    getBankAccount = '/baza-nc/purchase-flow/getBankAccount',
    setBankAccount = '/baza-nc/purchase-flow/setBankAccount',
    deleteBankAccount = '/baza-nc/purchase-flow/deleteBankAccount',
}

export interface BazaNcPurchaseFlowBankAccountEndpoint {
    getPlaidLink(...args: unknown[]): Promise<PurchaseFlowPlaidLinkDto> | Observable<PurchaseFlowPlaidLinkDto>;
    getBankAccount(...args: unknown[]): Promise<PurchaseFlowBankAccountDto> | Observable<PurchaseFlowBankAccountDto>;
    setBankAccount(
        request: PurchaseFlowSetBankAccountDetailsDto,
        ...args: unknown[]
    ): Promise<PurchaseFlowBankAccountDto> | Observable<PurchaseFlowBankAccountDto>;
    deleteBankAccount(...args: unknown[]): Promise<void> | Observable<void>;
}
