import { Injectable } from '@angular/core';
import {
    BazaNcTransferEndpoint,
    BazaNcTransferEndpointPaths,
    BazaNcTransferListRequest,
    BazaNcTransferListResponse,
    BazaNcTransferReprocessRequest,
    BazaNcTransferRequest,
} from '@scaliolabs/baza-nc-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';
import { BazaDwollaPaymentDto } from '@scaliolabs/baza-dwolla-shared';

@Injectable()
export class BazaNcTransferDataAccess implements BazaNcTransferEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns list of transfers
     * @param request
     */
    list(request: BazaNcTransferListRequest): Observable<BazaNcTransferListResponse> {
        return this.http.get(BazaNcTransferEndpointPaths.list, request);
    }

    /**
     * Transfer Funds from Bank Account into dwolla balance
     * @param request
     */
    transfer(request: BazaNcTransferRequest): Observable<BazaDwollaPaymentDto> {
        return this.http.post(BazaNcTransferEndpointPaths.transfer, request);
    }

    /**
     * Reprocess failed cash-in request
     * @param request
     */
    reprocess(request: BazaNcTransferReprocessRequest): Observable<BazaDwollaPaymentDto> {
        return this.http.post(BazaNcTransferEndpointPaths.reprocess, request);
    }
}
