import { Injectable } from '@angular/core';
import { BazaNcIntegrationSubscriptionEndpoint, BazaNcIntegrationSubscriptionEndpointPaths, BazaNcIntegrationSubscribeToRequest, BazaNcIntegrationSubscribedResponse, BazaNcIntegrationUnsubscribeRequest, BazaNcIntegrationUnsubscribedResponse } from '@scaliolabs/baza-nc-integration-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcIntegrationSubscriptionDataAccess implements BazaNcIntegrationSubscriptionEndpoint {
    constructor(
        private readonly http: BazaDataAccessService,
    ) {}

    subscribe(request: BazaNcIntegrationSubscribeToRequest): Observable<BazaNcIntegrationSubscribedResponse> {
        return this.http.post(BazaNcIntegrationSubscriptionEndpointPaths.subscribe, request);
    }

    unsubscribe(request: BazaNcIntegrationUnsubscribeRequest): Observable<BazaNcIntegrationUnsubscribedResponse> {
        return this.http.post(BazaNcIntegrationSubscriptionEndpointPaths.unsubscribe, request);
    }
}
