<nz-footer class="footer footer-mini">
    <div class="container">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12">
                <div class="footer__copy">
                    &copy; {{ today | date: 'YYYY' }} Scalio. All rights reserved.
                    <div class="footer__copy-divider"></div>
                    Need help? Check our
                    <a [routerLink]="['/faq']">FAQ</a>
                    or
                    <a [routerLink]="['', { outlets: { modal: ['contact-us'] } }]">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</nz-footer>
