import { BazaSeoDto, BazaSocialNetworksDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { BazaContentDto } from '../../../../../shared/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class TeamMemberDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    url: string;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    bio: BazaContentDto;

    @ApiModelProperty({
        required: false,
    })
    fullImageUrl?: string;

    @ApiModelProperty({
        required: false,
    })
    previewImageUrl?: string;

    @ApiModelProperty()
    networks: BazaSocialNetworksDto;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
