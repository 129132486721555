import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { bazaSeoDefault, BazaSeoDto } from '@scaliolabs/baza-core-shared';
import { TagDto } from '@scaliolabs/baza-content-types-shared';
import { BazaNcIntegrationFeedPostType } from '../models/baza-nc-integration-feed-post-type';
import { BazaNcIntegrationListingsListItemDto } from '../../../../listings/src';

export class BazaNcIntegrationFeedPostDto {
    @ApiModelProperty({
        description: 'ID of the post',
    })
    id: number;

    @ApiModelProperty({
        description: 'Date published',
    })
    datePublishedAt: string;

    @ApiModelProperty({
        description: 'Post type',
        type: 'string',
        enum: Object.values(BazaNcIntegrationFeedPostType),
    })
    type: BazaNcIntegrationFeedPostType;

    @ApiModelProperty({
        description: 'Title of the post',
    })
    title: string;

    @ApiModelProperty({
        description: 'Small text for post preview',
        required: false,
    })
    intro: string;

    @ApiModelProperty({
        description: 'Full contents of the post. Contains HTML.',
        required: false,
    })
    contents: string;

    @ApiModelProperty({
        description: 'Image which should be displayed in feed. Image will also be presented when previewVideoUrl is specified',
        required: false,
    })
    previewImageUrl: string;

    @ApiModelProperty({
        description: 'Video which should be displayed in feed. Use "previewImageUrl" value for video preview and do not display video if "previewImageUrl" is not set',
        required: false,
    })
    previewVideoUrl: string;

    @ApiModelProperty({
        description: 'Count of applause',
    })
    applause: number;

    @ApiModelProperty({
        description: 'Indicated that post is applause by current user',
    })
    isApplause: boolean;

    @ApiModelProperty({
        description: 'Tags',
        type: TagDto,
        isArray: true,
    })
    tags: Array<TagDto>;

    @ApiModelProperty({
        default: bazaSeoDefault(),
    })
    seo: BazaSeoDto;

    @ApiModelProperty({
        description: 'Post should be displayed as locked?',
    })
    isLocked: boolean;

    @ApiModelProperty({
        description: 'Associated Listing',
        required: false,
    })
    listing?: BazaNcIntegrationListingsListItemDto;
}
