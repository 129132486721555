import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  TeamMemberDto,
  TeamsMemberEndpoint,
  TeamsMemberEndpointPaths,
  TeamsMemberGetAllResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';
import { Injectable } from '@angular/core';

@Injectable()
export class TeamsMembersDataAccess implements TeamsMemberEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  getAll(): Observable<TeamsMemberGetAllResponse> {
    return this.http.get(TeamsMemberEndpointPaths.getAll);
  }

  getById(id: number): Observable<TeamMemberDto> {
    return this.http.get(
      replacePathArgs(TeamsMemberEndpointPaths.getById, {
        id,
      }),
    );
  }

  getByUrl(url: string): Observable<TeamMemberDto> {
    return this.http.get(
      replacePathArgs(TeamsMemberEndpointPaths.getByUrl, {
        url,
      }),
    );
  }
}
