import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Application, clientApplications } from '../../../../baza-common/src';

export class BazaReferralCodeUsageCsvDto {
    @ApiModelProperty({
        description: 'Joined (Used) at'
    })
    dateJoinedAt: string;

    @ApiModelProperty({
        description: 'Referral Code',
    })
    referralCode: string;

    @ApiModelProperty({
        description: 'Joined account (email + fullName)'
    })
    joinedAccount: string;

    @ApiModelProperty({
        type: 'string',
        enum: [...clientApplications],
    })
    joinedWithClient: Application;
}
