import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaInviteCodeModels } from '../models/baza-invite-code.models';

export class BazaInviteCodeCmsDto {
    @ApiModelProperty({
        description: 'ID'
    })
    id: number;

    @ApiModelProperty({
        description: 'Code (uniqie identificator)'
    })
    code: string;

    @ApiModelProperty({
        description: 'Code (to display & share)'
    })
    codeDisplay: string;

    @ApiModelProperty({
        description: 'ID of user who owns the code',
        required: false,
    })
    ownerUserId?: number;

    @ApiModelProperty({
        description: 'Full name of user who owns the code',
        required: false,
    })
    ownerUserFullName?: string;

    @ApiModelProperty({
        description: `How many times code can be used? (${BazaInviteCodeModels.UNLIMITED_CONSUME_LIMIT} for unlimited usage)`,
        required: false,
    })
    consumeLimit?: number;

    @ApiModelProperty({
        description: `How many times code can be used? (${BazaInviteCodeModels.UNLIMITED_CONSUME_LIMIT} for unlimited usage)`,
        required: false,
    })
    usedCount?: number;

    @ApiModelProperty({
        description: 'Is invite code already completely consumed?'
    })
    isConsumed: boolean;
}
