import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { AlertComponent } from './alert.component';
import { ALERT_MODULE_CONFIG, AlertService, AlertServiceConfig } from './alert.service';

@NgModule({
    imports: [CommonModule, NzAlertModule, PortalModule],
    declarations: [AlertComponent],
    providers: [AlertService],
    exports: [AlertComponent],
})
export class AlertModule {
    static forRoot(config?: AlertServiceConfig): ModuleWithProviders<AlertModule> {
        return {
            ngModule: AlertModule,
            providers: [
                {
                    provide: ALERT_MODULE_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
