import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  TestimonialDto,
  TestimonialsEndpoint,
  TestimonialsEndpointPaths,
  TestimonialsListRequest,
  TestimonialsListResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class TestimonialsDataAccess implements TestimonialsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: TestimonialsListRequest): Observable<TestimonialsListResponse> {
    return this.http.get(TestimonialsEndpointPaths.list, request);
  }

  getAll(): Observable<Array<TestimonialDto>> {
    return this.http.get(TestimonialsEndpointPaths.getAll);
  }

  getById(id: number): Observable<TestimonialDto> {
    return this.http.get(replacePathArgs(TestimonialsEndpointPaths.getById, { id }));
  }

  getByUrl(url: string): Observable<TestimonialDto> {
    return this.http.get(replacePathArgs(TestimonialsEndpointPaths.getByUrl, { url }));
  }
}
