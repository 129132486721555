import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { MktPageCmsDto } from '../dto/mkt-page-cms.dto';

export enum MktPagesCmsEndpointPaths {
    create = '/baza-content-types/cms/marketing/create',
    update = '/baza-content-types/cms/marketing/update',
    delete = '/baza-content-types/cms/marketing/delete',
    list = '/baza-content-types/cms/marketing/list',
    getById = '/baza-content-types/cms/marketing/getById',
}

export interface MktPageCmsEndpoint {
    create(request: MarketingPagesCmsCreateRequest): Promise<MktPageCmsDto> | Observable<MktPageCmsDto>;
    update(request: MarketingPagesCmsUpdateRequest): Promise<MktPageCmsDto> | Observable<MktPageCmsDto>;
    delete(request: MarketingPagesCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: MarketingPagesCmsListRequest): Promise<MarketingPagesCmsListResponse> | Observable<MarketingPagesCmsListResponse>;
    getById(request: MarketingPagesCmsGetByIdRequest): Promise<MktPageCmsDto> | Observable<MktPageCmsDto>;
}

export class MarketingPagesCmsEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    pageUniqueSid?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    section: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    label?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    header: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    description: string;
}

export class MarketingPagesCmsCreateRequest extends MarketingPagesCmsEntityBody {}

export class MarketingPagesCmsUpdateRequest extends MarketingPagesCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class MarketingPagesCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class MarketingPagesCmsListRequest extends CrudListRequestDto<MktPageCmsDto> {}

export class MarketingPagesCmsListResponse extends CrudListResponseDto<MktPageCmsDto> {
    @ApiModelProperty({
        type: MktPageCmsDto,
        isArray: true,
    })
    items: Array<MktPageCmsDto>;
}

export class MarketingPagesCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
