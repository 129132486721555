import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    IsArray,
    IsBoolean,
    IsEmail,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsObject,
    IsOptional,
    IsPositive,
    IsString,
    MaxLength,
    MinLength,
} from 'class-validator';
import { Observable } from 'rxjs';
import { BazaAccountConstants } from '../baza-account.constants';
import { AccountRole } from '../models/account-role';
import { AccountCsvDto } from '../dto/account-csv.dto';
import { AccountCmsDto } from '../dto/account-cms.dto';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';

export enum AccountCmsEndpointPaths {
    registerAdminAccount = '/baza-account/cms/registerAdminAccount',
    registerUserAccount = '/baza-account/cms/registerUserAccount',
    listAccounts = '/baza-account/cms/listAccounts',
    assignAdminAccess = '/baza-account/cms/assignAdminAccess',
    revokeAdminAccess = '/baza-account/cms/revokeAdminAccess',
    getAccountById = '/baza-account/cms/getAccountById',
    getAccountByEmail = '/baza-account/cms/getAccountByEmail',
    deleteAccountById = '/baza-account/cms/deleteAccountById',
    confirmEmailAccountById = '/baza-account/cms/confirmEmailAccountById',
    updateAccount = '/baza-account/cms/updateAccount',
    exportToCsv = '/baza-account/cms/exportToCsv',
    deactivateAccount = '/baza-account/cms/deactivateAccount',
    changeEmail = '/baza-account/cms/changeEmail',
}

export interface AccountCmsEndpoint {
    registerAdminAccount(
        request: RegisterAdminAccountCmsRequest,
    ): Promise<RegisterAdminAccountCmsResponse> | Observable<RegisterAdminAccountCmsResponse>;
    registerUserAccount(
        request: RegisterUserAccountCmsRequest,
    ): Promise<RegisterUserAccountCmsResponse> | Observable<RegisterUserAccountCmsResponse>;
    listAccounts(request: ListAccountsCmsRequest): Promise<ListAccountsCmsResponse> | Observable<ListAccountsCmsResponse>;
    assignAdminAccess(
        request: AssignAdminAccessCmsRequest,
    ): Promise<AssignAdminAccessCmsResponse> | Observable<AssignAdminAccessCmsResponse>;
    revokeAdminAccess(
        request: RevokeAdminAccessCmsRequest,
        ...args: unknown[]
    ): Promise<RevokeAdminAccessCmsResponse> | Observable<RevokeAdminAccessCmsResponse>;
    getAccountById(request: GetAccountByIdCmsRequest): Promise<GetAccountByIdCmsResponse> | Observable<GetAccountByIdCmsResponse>;
    getAccountByEmail(
        request: GetAccountByEmailCmsRequest,
    ): Promise<GetAccountByEmailCmsResponse> | Observable<GetAccountByEmailCmsResponse>;
    deleteAccountById(
        request: DeleteAccountByIdCmsRequest,
    ): Promise<DeleteAccountByIdCmsResponse> | Observable<DeleteAccountByIdCmsResponse>;
    confirmEmailAccountById(
        request: ConfirmEmailAccountByIdCmsRequest,
        ...args: unknown[]
    ): Promise<ConfirmEmailAccountByIdCmsResponse> | Observable<ConfirmEmailAccountByIdCmsResponse>;
    updateAccount(request: UpdateAccountCmsRequest): Promise<UpdateAccountCmsResponse> | Observable<UpdateAccountCmsResponse>;
    exportToCsv(request: ExportToCsvAccountRequest): Promise<string> | Observable<string>;
    deactivateAccount(
        request: DeactivateAccountCmsRequest,
    ): Promise<DeactivateAccountCmsResponse> | Observable<DeactivateAccountCmsResponse>;
    changeEmail(request: ChangeEmailAccountCmsRequest): Promise<ChangeEmailAccountCmsResponse> | Observable<ChangeEmailAccountCmsResponse>;
}

export class RegisterAdminAccountCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN, {
        always: false,
    })
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX, {
        always: false,
    })
    @IsString()
    @IsOptional()
    password?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    passwordConfirm?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    firstName?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    lastName?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    profileImages?: Array<string>;

    @ApiModelProperty({
        description: 'Custom account metadata',
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, unknown>;
}

export type RegisterUserAccountCmsResponse = AccountCmsDto;

export class RegisterUserAccountCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN, {
        always: false,
    })
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX, {
        always: false,
    })
    @IsString()
    @IsOptional()
    password?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    passwordConfirm?: string;

    @ApiModelProperty()
    @IsString()
    firstName: string;

    @ApiModelProperty()
    @IsString()
    lastName: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    profileImages?: Array<string>;

    @ApiModelProperty({
        description: 'Custom account metadata',
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, unknown>;
}

export type RegisterAdminAccountCmsResponse = AccountCmsDto;

export class ListAccountsCmsRequest extends CrudListRequestDto<AccountCmsDto> {
    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(AccountRole),
        required: false,
    })
    @IsEnum(AccountRole, { each: true })
    @IsArray()
    @IsOptional()
    roles?: Array<AccountRole>;
}

export class ListAccountsCmsResponse extends CrudListResponseDto<AccountCmsDto> {}

export class RevokeAdminAccessCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export type RevokeAdminAccessCmsResponse = void;

export class AssignAdminAccessCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export type AssignAdminAccessCmsResponse = void;

export class GetAccountByIdCmsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type GetAccountByIdCmsResponse = AccountCmsDto;

export class GetAccountByEmailCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export type GetAccountByEmailCmsResponse = AccountCmsDto;

export class DeleteAccountByIdCmsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type DeleteAccountByIdCmsResponse = void;

export class ConfirmEmailAccountByIdCmsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        description: 'Set is as false to remove confirmation',
    })
    @IsBoolean()
    @IsOptional()
    confirmed?: boolean;
}

export type ConfirmEmailAccountByIdCmsResponse = AccountCmsDto;

export class UpdateAccountCmsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN, {
        always: false,
    })
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX, {
        always: false,
    })
    @IsString()
    @IsOptional()
    password?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    passwordConfirm?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    firstName?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    lastName?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    phone?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    profileImages?: Array<string>;

    @ApiModelProperty({
        description: 'Custom account metadata',
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, unknown>;
}

export type UpdateAccountCmsResponse = AccountCmsDto;

export class ExportToCsvAccountRequest extends CrudExportToCsvRequest<AccountCsvDto, ListAccountsCmsRequest> {}

export class DeactivateAccountCmsRequest {
    @ApiModelProperty({
        description: 'Email of an account to deactivate',
    })
    @IsString()
    email: string;
}

export type DeactivateAccountCmsResponse = AccountCmsDto;

export class ChangeEmailAccountCmsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ChangeEmailAccountCmsResponse = AccountCmsDto;
