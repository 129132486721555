import { Inject, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';
import { NzModalComponent } from 'ng-zorro-antd/modal';
import { debounceTime, fromEvent, take } from 'rxjs';

@Injectable()
export class BazaFormValidatorService {
    formGroupRef: UntypedFormGroup;
    formEl: HTMLFormElement;
    invalidFormScrollContainerRef: NzModalComponent | HTMLElement = null;

    scrollContainer: HTMLElement;
    scrollElement: Window | HTMLElement;

    constructor(@Inject(WINDOW) readonly windowRef: Window) {}

    public isFormValid(
        formGroup: UntypedFormGroup,
        formEl: HTMLFormElement = null,
        invalidFormScrollContainerRef: NzModalComponent | HTMLElement = null,
    ) {
        this.formGroupRef = formGroup;
        this.formEl = formEl;
        this.invalidFormScrollContainerRef = invalidFormScrollContainerRef;

        if (this.invalidFormScrollContainerRef) {
            if (this.invalidFormScrollContainerRef['modal'] ?? false) {
                this.scrollContainer = (this.invalidFormScrollContainerRef as NzModalComponent)?.getModalRef()?.getElement() ?? null;
            } else {
                this.scrollContainer = (this.invalidFormScrollContainerRef as HTMLElement) ?? null;
            }
        }

        const isFormValid = this.formGroupRef?.valid;
        if (!isFormValid) {
            this.formGroupControls?.forEach((control) => {
                if (control?.invalid) {
                    control?.markAsDirty();
                    control?.updateValueAndValidity();
                }
            });

            if (this.formEl) {
                this.scrollToFirstInvalidControl();
            }
        }

        return isFormValid;
    }

    public isFormFilledAndValid(formGroup: UntypedFormGroup): boolean {
        this.formGroupRef = formGroup;
        const formControls = Object.values(this.formGroupRef?.controls) ?? [];
        const requiredFields = formControls?.filter((field) => this.isFieldRequired(field));
        const requiredFieldsAreValid = requiredFields?.every((control) => control?.valid);

        return this.formGroupRef?.pristine && this.formGroupRef?.untouched && requiredFieldsAreValid;
    }

    private get formGroupControls() {
        return Object.values(this.formGroupRef?.controls) ?? [];
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.formEl?.querySelector('.ng-invalid');

        this.scrollElement = this.invalidFormScrollContainerRef ? this.scrollContainer : this.windowRef;
        this.scrollElement?.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth',
        });

        fromEvent(this.scrollElement, 'scroll')
            ?.pipe(debounceTime(100), take(1))
            ?.subscribe(() => firstInvalidControl?.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 150;
        const controlElTop = controlEl?.getBoundingClientRect()?.top;

        if (this.invalidFormScrollContainerRef) {
            const containerTop = this.scrollContainer?.getBoundingClientRect()?.top;
            const absoluteControlElTop = controlElTop + this.scrollContainer?.scrollTop;
            return absoluteControlElTop - containerTop - labelOffset;
        } else {
            const absoluteControlElTop = controlElTop + this.windowRef?.scrollY;
            return absoluteControlElTop - labelOffset;
        }
    }

    private isFieldRequired(formControl: AbstractControl) {
        if (formControl?.validator) {
            const validator = formControl?.validator({} as AbstractControl);
            return !formControl.disabled && (validator?.required ?? false);
        }
        return false;
    }
}
