import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaPasswordValidatorDto } from './baza-password-validator.dto';

export class BazaPasswordResourcesDto {
    @ApiModelProperty({
        description: 'Validators',
        type: BazaPasswordValidatorDto,
        isArray: true,
    })
    validators: Array<BazaPasswordValidatorDto>;

    @ApiModelProperty({
        description: 'Min password length',
    })
    minLength: number;

    @ApiModelProperty({
        description: 'Max password length',
    })
    maxLength: number;
}
