import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcKycStatus } from '../models/baza-nc-kyc-status.enum';
import { BazaPurchaseFlowTransactionType } from '../../../../types/baza-dwolla-purchase-flow-transaction-type';

export class BazaDwollaInvestorAccountStatusDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcKycStatus),
        description: 'KYC status for NC',
    })
    nc: BazaNcKycStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcKycStatus),
        description: 'KYC status for Dwolla',
    })
    dwolla: BazaNcKycStatus;

    @ApiModelProperty({
        type: 'string',
        description: 'Is Dwolla Customer created & available for Investor?',
    })
    isDwollaAvailable: boolean;

    @ApiModelProperty({
        type: 'boolean',
        description: 'Whether is a foreign investor or not',
    })
    isForeign: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaPurchaseFlowTransactionType),
        description: 'Default Payment Method',
        default: BazaPurchaseFlowTransactionType.ACH,
    })
    defaultPaymentMethod: BazaPurchaseFlowTransactionType;
}
