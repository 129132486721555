export enum AccountType {
    Entity = 'entity',
    Individual = 'individual',
    IRA = 'ira',
    JTWROS = 'jtwros',
    ROTH = 'roth',
    SepIRA = 'sepira',
    TIC = 'tic',
    Joint = 'joint',
}
