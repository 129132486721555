import { BazaNcOfferingDto } from '@scaliolabs/baza-nc-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationSchemaDto } from '../../../../schema/src';
import { ulid } from 'ulid';
import { BazaSeoDto } from '@scaliolabs/baza-core-shared';

export class BazaNcIntegrationListingsBaseDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'ULID',
        example: ulid(),
    })
    sid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: new Date().toISOString(),
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: new Date().toISOString(),
    })
    dateUpdatedAt: string;

    @ApiModelProperty({
        description: 'Listing Name',
    })
    title: string;

    @ApiModelProperty({
        description: 'Listing Description',
    })
    description: string;

    @ApiModelProperty({
        description: 'Offering Details',
    })
    offering: BazaNcOfferingDto;

    @ApiModelProperty({
        description: 'Number of shares required to automatically join user to Elite Investors',
    })
    sharesToJoinEliteInvestors?: number;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    metadata: Record<string, string>;

    @ApiModelProperty({
        description: 'Schema used for values field',
        required: false,
    })
    schema?: BazaNcIntegrationSchemaDto;

    @ApiModelProperty({
        description: 'Schema Id',
        required: false,
    })
    schemaId?: number;

    @ApiModelProperty({
        description: 'Custom properties',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    properties: Record<string, any>;

    @ApiModelProperty({
        description: 'Full seo object',
    })
    seo: BazaSeoDto;

    @ApiModelProperty()
    pageTitle?: string;

    @ApiModelProperty()
    slug?: string;
}
