import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ContactsCmsDto } from '../dto/cms/contacts-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';

export enum ContactsCmsEndpointPaths {
    list = '/baza-content-types/contacts/cms/list',
    getById = '/baza-content-types/contacts/cms/getById',
    markAsProcessed = '/baza-content-types/contacts/cms/markAsProcessed',
    markAsUnprocessed = '/baza-content-types/contacts/cms/markAsUnprocessed',
}

export interface ContactsCmsEndpoint {
    list(request: ContactsCmsListRequest): Promise<ContactsCmsListResponse> | Observable<ContactsCmsListResponse>;
    getById(request: ContactsGetByIdRequest): Promise<ContactsGetByIdResponse> | Observable<ContactsGetByIdResponse>;
    markAsProcessed(request: ContactsCmsMarkAsProcessedRequest): Promise<void> | Observable<void>;
    markAsUnprocessed(request: ContactsCmsMarkAsUnprocessedRequest): Promise<void> | Observable<void>;
}

export class ContactsCmsListRequest extends CrudListRequestDto<ContactsCmsDto> {}

export class ContactsCmsListResponse extends CrudListResponseDto<ContactsCmsDto> {
    @ApiModelProperty({
        type: ContactsCmsDto,
        isArray: true,
    })
    items: Array<ContactsCmsDto>;
}

export class ContactsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ContactsGetByIdResponse = ContactsCmsDto;

export class ContactsCmsMarkAsProcessedRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ContactsCmsMarkAsUnprocessedRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
