import { IsBoolean, IsOptional, IsString, Matches } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Domicile, NorthCapitalDate } from '../../../transact-api';
import {
    bazaNcAccountVerificationErrorCodesI18n,
    BazaNorthCapitalAccountVerificationErrorCodes,
} from '../error-codes/baza-north-capital-account-verification.error-codes';

export const NC_VALID_CITY_REGEX = new RegExp('^[^0-9]+$');

export class PersonalInformationApplyRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    firstName?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    lastName?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    dateOfBirth?: NorthCapitalDate;

    @ApiModelProperty({
        type: 'boolean',
    })
    @IsBoolean()
    @IsOptional()
    hasSsn?: boolean;

    @ApiModelProperty({
        type: 'string',
        required: false,
    })
    @IsString()
    @IsOptional()
    ssn?: string;

    @ApiModelProperty({
        type: 'string',
        required: false,
    })
    @IsString()
    @IsOptional()
    phone?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    phoneCountryCode?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    phoneCountryNumericCode?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Domicile),
    })
    @IsString()
    @IsOptional()
    citizenship?: Domicile;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    residentialStreetAddress1?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    residentialStreetAddress2?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Should be a valid city name("New York", "Las Vegas").',
    })
    @IsString()
    @IsOptional()
    @Matches(NC_VALID_CITY_REGEX, {
        message: () =>
            bazaNcAccountVerificationErrorCodesI18n[
                BazaNorthCapitalAccountVerificationErrorCodes.NcAccountVerificationResidentialCityIsInvalid
            ],
    })
    residentialCity?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'You can send empty string or "N/A" to implicit that state is not available for selected country',
    })
    @IsString()
    @IsOptional()
    residentialState?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    residentialZipCode?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsOptional()
    residentialCountry?: string;
}
