import { Observable } from 'rxjs';
import { BazaDwollaPaymentDto } from '@scaliolabs/baza-dwolla-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { IsNotEmpty, IsNumberString, IsOptional } from 'class-validator';
import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';

export enum BazaNcWithdrawEndpointPaths {
    list = '/baza-nc/withdraw/list',
    withdraw = '/baza-nc/withdraw',
    reprocess = '/baza-nc/withdraw/reprocess',
}

export interface BazaNcWithdrawEndpoint {
    list(
        request: BazaNcWithdrawListRequest,
        ...args: unknown[]
    ): Promise<BazaNcWithdrawListResponse> | Observable<BazaNcWithdrawListResponse>;
    withdraw(request: BazaNcWithdrawRequest, ...args: unknown[]): Promise<BazaDwollaPaymentDto> | Observable<BazaDwollaPaymentDto>;
    reprocess(
        request: BazaNcWithdrawReprocessRequest,
        ...args: unknown[]
    ): Promise<BazaDwollaPaymentDto> | Observable<BazaDwollaPaymentDto>;
}

export class BazaNcWithdrawListRequest extends CrudListRequestQueryDto {}

export class BazaNcWithdrawListResponse extends CrudListResponseDto<BazaDwollaPaymentDto> {
    @ApiModelProperty({
        type: BazaDwollaPaymentDto,
        isArray: true,
    })
    items: Array<BazaDwollaPaymentDto>;
}

export class BazaNcWithdrawRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'The amount to be withdrawn',
        example: '14.33',
        required: false,
    })
    @IsNumberString()
    @IsOptional()
    amount?: string;
}

export class BazaNcWithdrawReprocessRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'The ULID of the failed withdraw request',
        example: ulid(),
        required: true,
    })
    @IsNotEmpty()
    ulid: string;
}
