import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { TradeId } from '../models/trade-id';

export class GetTradeRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsOptional()
    tradeId?: TradeId;
}
