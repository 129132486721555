import { BazaDwollaDistributionHistoryDto } from './baza-dwolla-distribution-history.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaDwollaDistributionPaymentSource } from '../models/baza-dwolla-distribution-payment-source';
import { BazaDwollaDistributionPaymentStatus } from '../models/baza-dwolla-distribution-payment-status';

export class BazaDwollaDistributionDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaDwollaDistributionPaymentSource),
    })
    source: BazaDwollaDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering ID',
        required: false,
    })
    offeringId?: string;

    @ApiModelProperty({
        description: 'Offering Title',
        required: false,
    })
    offeringTitle?: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaDistributionPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaDistributionPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        type: BazaDwollaDistributionHistoryDto,
        isArray: true,
        description: 'Payment status updates history',
    })
    history: Array<BazaDwollaDistributionHistoryDto>;
}
