import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaNcDividendPaymentSource } from '../models/baza-nc-dividend-payment-source';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import { OfferingId } from '../../../../transact-api';

export class BazaNcDividendCmsCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcDividendPaymentSource),
    })
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Account ID)',
    })
    ncAccountId: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Party ID)',
    })
    ncPartyId: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        description: 'Offering',
        required: false,
    })
    offering?: string;
}
