import { AfterViewInit, Directive } from '@angular/core';
import IMask from 'imask';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

/**
 * The DateMaskDirective can be used to enable a date input mask.
 * It will format the date input as "MM/DD/YYYY" and will limit the date input to
 * a date range (1-12 for month, 1-31 for day, 1900-9999 for year).
 *
 * @example
 * <nz-date-picker
 *   nzPlaceHolder="Select Date"
 *   appDateMask></nz-date-picker>
 *
 * @example
 * <input appDateMask />
 */
@Directive({
    selector: '[appDateMask]',
})
export class DateMaskDirective implements AfterViewInit {
    maskDate = {
        mask: 'mm/dd/yyyy',
        placeholderChar: 'MM/DD/YYYY',
        lazy: true,
        overwrite: true,
        autofix: true,
        blocks: {
            mm: { mask: IMask.MaskedRange, from: 1, to: 12, maxLength: 2 },
            dd: { mask: IMask.MaskedRange, from: 1, to: 31, maxLength: 2 },
            yyyy: { mask: IMask.MaskedRange, from: 1900, to: 9999, maxLength: 4 },
        },
    };

    constructor(private readonly element: NzDatePickerComponent) {}

    ngAfterViewInit() {
        const textBox: HTMLInputElement = this.element.getInput();
        if (textBox) {
            IMask(textBox, this.maskDate);
            textBox.maxLength = 10;
        }
    }
}
