import { PaymentPayoutMethodsEnI18n } from '../ui-components/account/i18n/payment-payout-methods.i18n';
import { PaymentBalanceEnI18n } from '../ui-components/account/payment-methods/account-balance/i18n/dwac-payment-balance.i18n';
import { PayoutBankEnI18n } from '../ui-components/account/payout-methods/bank-account/18n/dwac-payout-bank.i18n';

export const BazaWebAccountEnI18n = {
    dwacc: {
        paymentAndPayout: PaymentPayoutMethodsEnI18n,
        payment: {
            balance: PaymentBalanceEnI18n,
        },
        payout: {
            bank: PayoutBankEnI18n,
        },
    },
};
