import { OfferingId } from '../models/offering-id';
import { NorthCapitalDate, NorthCapitalYesNoBoolean } from '../common-types';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class AddDocumentsForOfferingRequest {
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    documentFileReferenceCode: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsString()
    @IsOptional()
    templateName?: string;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    approval?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    supervisorname?: string;

    @IsString()
    @IsOptional()
    date?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    createdIpAddress?: NorthCapitalIpAddress;

    @IsNotEmpty()
    userfile0: File | unknown;
}
