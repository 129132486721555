import { Observable } from 'rxjs';
import { BazaE2eMailDto } from '../dto/baza-e2e-mail.dto';
import { AccountRole } from '../../../../baza-account/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEnum, IsNotEmpty, IsString } from 'class-validator';

export enum BazaE2eErrorCodes {
    BazaE2eIsNotTestEnvironment = 'BazaE2eIsNotTestEnvironment',
}

export enum BazaE2eEndpointPaths {
    flush = '/baza-e2e/flush',
    flushDatabase = '/baza-e2e/flushDatabase',
    flushRedis = '/baza-e2e/flushRedis',
    flushMailbox = '/baza-e2e/flushMailbox',
    mailbox = '/baza-e2e/mailbox',
    enable2FAForAccountRoles = '/baza-e2e/enable2FAForAccountRoles',
    enableFeature = '/baza-e2e/enableFeature',
    disableFeature = '/baza-e2e/disableFeature',
    setEnv = '/baza-e2e/setEnv',
    resetEnv = '/baza-e2e/resetEnv',
}

export interface BazaE2eEndpoint {
    flush(): Promise<void> | Observable<void>;
    flushDatabase(): Promise<void> | Observable<void>;
    flushRedis(): Promise<void> | Observable<void>;
    flushMailbox(): Promise<void> | Observable<void>;
    mailbox(): Promise<Array<BazaE2eMailDto>> | Observable<Array<BazaE2eMailDto>>;
    enable2FAForAccountRoles(request: BazaE2eEnable2FAForAccountRolesRequest): Promise<void> | Observable<void>;
    enableFeature(request: BazaE2eEnableFeatureRequest): Promise<void> | Observable<void>;
    disableFeature(request: BazaE2eDisableFeatureRequest): Promise<void> | Observable<void>;
    setEnv(request: Record<string, string>): Promise<void> | Observable<void>;
    resetEnv(): Promise<void> | Observable<void>;
}

export class BazaE2eEnable2FAForAccountRolesRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountRole),
    })
    @IsEnum(AccountRole, { each: true })
    @IsArray()
    @IsNotEmpty()
    accountRoles: Array<AccountRole>;
}

export class BazaE2eEnableFeatureRequest {
    @ApiModelProperty({
        description: 'Feature ID',
    })
    @IsString()
    @IsNotEmpty()
    feature: string;
}

export class BazaE2eDisableFeatureRequest {
    @ApiModelProperty({
        description: 'Feature ID',
    })
    @IsString()
    @IsNotEmpty()
    feature: string;
}
