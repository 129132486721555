import { AttachmentDto, CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderByUlidRequestDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingTestimonialCmsDto } from '../dto/baza-nc-integration-listing-testimonial-cms.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export enum BazaNcIntegrationListingTestimonialCmsEndpointPaths {
    create = '/baza-nc-integration/cms/listing-testimonials/create',
    update = '/baza-nc-integration/cms/listing-testimonials/update',
    delete = '/baza-nc-integration/cms/listing-testimonials/delete',
    list = '/baza-nc-integration/cms/listing-testimonials/list',
    getByUlid = '/baza-nc-integration/cms/listing-testimonials/getByUlid',
    setSortOrder = '/baza-nc-integration/cms/listing-testimonials/setSortOrder',
}

export interface BazaNcIntegrationListingTestimonialCmsEndpoint {
    create(
        request: BazaNcIntegrationListingTestimonialCmsCreateRequest,
    ): Promise<BazaNcIntegrationListingTestimonialCmsDto> | Observable<BazaNcIntegrationListingTestimonialCmsDto>;
    update(
        request: BazaNcIntegrationListingTestimonialCmsUpdateRequest,
    ): Promise<BazaNcIntegrationListingTestimonialCmsDto> | Observable<BazaNcIntegrationListingTestimonialCmsDto>;
    delete(request: BazaNcIntegrationListingTestimonialCmsDeleteRequest): Promise<void> | Observable<void>;
    list(
        request: BazaNcIntegrationListingTestimonialCmsListRequest,
    ): Promise<BazaNcIntegrationListingTestimonialCmsListResponse> | Observable<BazaNcIntegrationListingTestimonialCmsListResponse>;
    getByUlid(
        request: BazaNcIntegrationListingTestimonialCmsGetByUlidRequest,
    ): Promise<BazaNcIntegrationListingTestimonialCmsDto> | Observable<BazaNcIntegrationListingTestimonialCmsDto>;
    setSortOrder(request: BazaNcIntegrationListingTestimonialCmsSetSortOrderRequest): Promise<void> | Observable<void>;
}

export class BazaNcIntegrationListingTestimonialEntityBody {
    @ApiModelProperty({
        description: 'Is available for Public API?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty({
        description: 'Image',
    })
    @ValidateNested()
    @Type(() => AttachmentDto)
    @IsNotEmpty()
    image: AttachmentDto;

    @ApiModelProperty({
        description: 'Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        description: 'Role',
    })
    @IsString()
    @IsNotEmpty()
    role: string;

    @ApiModelProperty({
        description: 'Contents (Testimonial)',
    })
    @IsString()
    @IsNotEmpty()
    contents: string;
}

export class BazaNcIntegrationListingTestimonialCmsCreateRequest extends BazaNcIntegrationListingTestimonialEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    listingUlid: string;
}

export class BazaNcIntegrationListingTestimonialCmsUpdateRequest extends BazaNcIntegrationListingTestimonialEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcIntegrationListingTestimonialCmsDeleteRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcIntegrationListingTestimonialCmsListRequest extends CrudListRequestDto<BazaNcIntegrationListingTestimonialCmsDto> {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    listingUlid: string;
}

export class BazaNcIntegrationListingTestimonialCmsListResponse extends CrudListResponseDto<BazaNcIntegrationListingTestimonialCmsDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationListingTestimonialCmsDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationListingTestimonialCmsDto>;
}

export class BazaNcIntegrationListingTestimonialCmsGetByUlidRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcIntegrationListingTestimonialCmsSetSortOrderRequest extends CrudSetSortOrderByUlidRequestDto {}
