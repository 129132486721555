import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaReferralCampaignDto {
    @ApiModelProperty({
        description: 'ID',
    })
    id: number;

    @ApiModelProperty({
        description: 'Created At',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Last date joined by some of referral code associated with campaign',
        required: false,
    })
    dateLastJoinedByReferralCode: string | undefined;

    @ApiModelProperty({
        description: 'Campaign Name',
    })
    name: string;

    @ApiModelProperty({
        description: 'If campaign is not active, no referral codes related to campaign will works',
    })
    isActive: boolean;

    @ApiModelProperty({
        description: 'How many referral codes usage inside campaign',
    })
    usedCount: number;
}
