import { Injectable } from '@angular/core';
import { BazaPhoneCountryCodesEndpoint, BazaPhoneCountryCodesEndpointPaths, PhoneCountryCodesDto, PhoneCountryCodesRepositoryDto } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaPhoneCountryCodesDataAccess implements BazaPhoneCountryCodesEndpoint {
    constructor(
        private readonly ngEndpoint: BazaDataAccessService,
    ) {}

    repository(): Observable<PhoneCountryCodesRepositoryDto> {
        return this.ngEndpoint.get(BazaPhoneCountryCodesEndpointPaths.repository);
    }

    getByNumericCode(numericCode: string): Observable<PhoneCountryCodesDto> {
        return this.ngEndpoint.get(this.ngEndpoint.path(BazaPhoneCountryCodesEndpointPaths.getByNumericCode, {
            numericCode,
        }));
    }
}
