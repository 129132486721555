import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzModalModule, NzButtonModule],
})
export class ConfirmModalComponent {
    @Input()
    cancelBtnText = 'Cancel';

    @Input()
    confirmBtnText = 'Confirm';

    @Input()
    confirmBtnDanger = true;

    @Output()
    confirm = new EventEmitter<boolean>();

    @Output()
    cancel = new EventEmitter<boolean>();
}
