export const PurchasePaymentEnI18n = {
    title: 'Submit Payment',
    checklist: {
        accountVerified: 'Account verification complete',
        documentSigned: 'Subscription document signed',
        paymentMethodAdded: 'Payment method added',
    },
    authorization: {
        label: `By clicking ‘Submit Payment’ you authorize this transaction and agree to our <a data-link="tosLink"></a> and <a data-link="eftDisclosureLink"></a>`,
        linkConfig: [
            {
                key: 'tosLink',
                link: {
                    extLink: {
                        link: 'bazaCommon.links.termsOfService',
                        isCMSLink: true,
                        text: 'Terms of Service',
                    },
                },
            },
            {
                key: 'eftDisclosureLink',
                link: {
                    extLink: {
                        link: 'bazaCommon.links.eftDisclosure',
                        isCMSLink: true,
                        text: 'EFT Disclosure',
                    },
                },
            },
        ],
    },
    actions: {
        submit: 'Submit Payment',
        back: 'Back',
    },
};
