import { RewardShareStatus } from '../models/reward-share-status';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDate, IsEmail, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

export class RewardShareDto {
    @ApiModelProperty({
        description: 'Reward share id',
        required: true,
    })
    @IsNumber()
    id: number;

    @ApiModelProperty({
        description: 'Offering id',
        required: true,
    })
    @IsString()
    offeringId: string;

    @ApiModelProperty({
        description: 'Number of reward shares',
        required: true,
    })
    @IsNumber()
    numberOfShares: number;

    @ApiModelProperty({
        description: 'Investor account email',
        required: true,
    })
    @IsEmail()
    investorEmail: string;

    @ApiModelProperty({
        description: 'Reward share status',
        required: true,
    })
    @IsEnum(() => RewardShareStatus)
    status: RewardShareStatus;

    @ApiModelProperty({
        description: 'Reward share error message',
        required: false,
    })
    @IsOptional()
    @IsString()
    error?: string;

    @ApiModelProperty({
        description: 'Reward share createdAt',
        required: true,
    })
    @IsDate()
    createdAt: Date;

    @ApiModelProperty({
        description: 'Reward share updatedAt',
        required: true,
    })
    @IsDate()
    updatedAt: Date;
}
