import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcInvestorAccountStatusDto } from './baza-nc-investor-account-status.dto';
import { BazaNcPurchaseFlowTransactionType } from '../../../purchase-flow';
import { IsArray, IsOptional, IsString } from 'class-validator';
import { DwollaFailureReasonDetail } from '@scaliolabs/baza-dwolla-shared';

export class BazaNcInvestorAccountDto {
    @ApiModelProperty({
        description: 'Investor Account Id (not same as userId (Account Id!))',
    })
    id: number;

    @ApiModelProperty({
        description: 'Account Id',
    })
    userId: number;

    @ApiModelProperty({
        description: 'Email Address',
    })
    userEmail: string;

    @ApiModelProperty({
        description: 'Account Full Name',
    })
    userFullName: string;

    @ApiModelProperty({
        description: 'NC Account Id',
        example: 'A123456',
    })
    northCapitalAccountId: string;

    @ApiModelProperty({
        description: 'NC Party Id',
        example: 'P123456',
    })
    northCapitalPartyId: string;

    @ApiModelProperty({
        description: 'Is Account Verification in Progress?',
    })
    isAccountVerificationInProgress: boolean;

    @ApiModelProperty({
        description: 'Is Account Verification Completed?',
    })
    isAccountVerificationCompleted: boolean;

    @ApiModelProperty({
        description: 'Is Investor Account Verified (KYC/AML)?',
    })
    isInvestorVerified: boolean;

    @ApiModelProperty({
        description: 'Is Investor Accredited? (from second step of Account Verification flow)',
    })
    isAccreditedInvestor: boolean;

    @ApiModelProperty({
        description: 'Is Cash In (Purchase, Cash-In) or NC ACH (Purchase) Bank Account available?',
    })
    isBankAccountLinked: boolean;

    @ApiModelProperty({
        description: 'Is NC ACH (Purchase) Bank Account available?',
    })
    isBankAccountNcAchLinked: boolean;

    @ApiModelProperty({
        description: 'Is Cash In (Purchase, Cash-In) Bank Account available?',
    })
    isBankAccountCashInLinked: boolean;

    @ApiModelProperty({
        description: 'Is Cash Out (Withdraw Dividends) Bank Account available?',
    })
    isBankAccountCashOutLinked: boolean;

    @ApiModelProperty({
        description: 'Is credit card linked?',
    })
    isCreditCardLinked: boolean;

    @ApiModelProperty({
        description: 'Is payment method linked?',
    })
    isPaymentMethodLinked: boolean;

    @ApiModelProperty({
        description: 'SSN Document FileName',
        required: false,
    })
    ssnDocumentFileName?: string;

    @ApiModelProperty({
        description: 'SSN Document ID',
        required: false,
    })
    ssnDocumentId?: string;

    @ApiModelProperty({
        description: 'Dwolla Customer ID',
        required: false,
    })
    dwollaCustomerId?: string;

    @ApiModelProperty({
        description: 'Error message for last failed attempt to create a Dwolla Customer for Investor Account',
        required: false,
    })
    dwollaCustomerError?: string;

    @ApiModelProperty({
        description: 'Verification Status of Dwolla Customer',
        required: false,
    })
    dwollaCustomerVerificationStatus?: string;

    @ApiModelProperty({
        description: 'Is Dwolla Balance Available to use for purchases?',
        required: false,
    })
    isDwollaAvailable?: boolean;

    @ApiModelProperty({
        description: 'Is Deactivated',
        required: false,
    })
    isDeactivated?: boolean;

    @ApiModelProperty({
        type: () => BazaNcInvestorAccountStatusDto,
        description: 'Investor account status',
        required: false,
    })
    status?: BazaNcInvestorAccountStatusDto;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        description: 'Default Payment Method',
        default: BazaNcPurchaseFlowTransactionType.ACH,
    })
    defaultPaymentMethod: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description: 'List of Dwolla Customer validation errors',
        required: false,
        type: 'string',
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    dwollaCustomerVerificationFailureReasons: Array<DwollaFailureReasonDetail>;
}
