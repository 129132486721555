import { BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaContentDto } from '../../../../../shared/src';
import { CategoryListDto } from '../../../../categories/src';

export class NewsDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'URL to testimonial (shortcut to seo slug)',
        required: false,
    })
    url?: string;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty({
        required: false,
    })
    datePublishedAt?: string;

    @ApiModelProperty()
    primaryCategory: CategoryListDto;

    @ApiModelProperty({
        type: CategoryListDto,
        isArray: true,
    })
    additionalCategories: Array<CategoryListDto>;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    link?: string;

    @ApiModelProperty({
        required: false,
    })
    imageUrl?: string;

    @ApiModelProperty({
        required: false,
    })
    previewImageUrl?: string;

    @ApiModelProperty()
    contents: BazaContentDto;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
