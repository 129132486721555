import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { OfferingId } from '../models/offering-id';
import { IssuerType } from '../models/issuer-type';
import { NorthCapitalDate } from '../common-types';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class UpdateOfferingRequest {
    @IsString()
    @IsOptional()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    issueName?: string;

    @IsEnum(IssuerType)
    @IsOptional()
    issueType?: IssuerType;

    @IsNumber()
    @IsOptional()
    targetAmount?: number;

    @IsNumber()
    @IsOptional()
    minAmount?: number;

    @IsNumber()
    @IsOptional()
    maxAmount?: number;

    @IsNumber()
    @IsOptional()
    unitPrice?: number;

    @IsString()
    @IsOptional()
    startDate?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    endDate?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    offeringText?: string;

    @IsString()
    @IsOptional()
    stampingText?: string;

    @IsString()
    @IsOptional()
    updatedIPaddress?: NorthCapitalIpAddress;
}
