export enum BazaContentTypesOpenApi {
    Categories = 'Baza Content Types Categories',
    Contacts = 'Baza Content Types Contacts',
    FAQ = 'Baza Content Types FAQ',
    Newsletters = 'Baza Content Types Newsletters',
    Team = 'Baza Content Types Team',
    Testimonials = 'Baza Content Types Testimonials',
    Blog = 'Baza Content Types Blog',
    Tags = 'Baza Content Types Tags',
    News = 'Baza Content Types News',
    Pages = 'Baza Content Types Pages',
    Jobs = 'Baza Content Types Jobs',
    JobApplications = 'Baza Content Types Job Applications',
    Events = 'Baza Content Types Events',
    Timeline = 'Baza Content Types Timeline',
    MarketingPages = 'Baza Content Types Marketing Pages',
}

export enum BazaContentTypesCmsOpenApi {
    Categories = 'Baza Content Types CMS Categories',
    Contacts = 'Baza Content Types CMS Contacts',
    ContactsSubjects = 'Baza Content Types CMS Contact Subjects',
    FAQ = 'Baza Content Types CMS FAQ',
    Newsletters = 'Baza Content Types CMS Newsletters',
    Team = 'Baza Content Types CMS Team',
    Testimonials = 'Baza Content Types CMS Testimonials',
    Blog = 'Baza Content Types CMS Blog',
    Tags = 'Baza Content Types CMS Tags',
    News = 'Baza Content Types CMS News',
    Pages = 'Baza Content Types CMS Pages',
    Jobs = 'Baza Content Types CMS Jobs',
    JobApplications = 'Baza Content Types CMS Job Applications',
    Events = 'Baza Content Types CMS Events',
    Timeline = 'Baza Content Types CMS Timeline',
    MarketingPages = 'Baza ContentTypes CMS Marketing Pages',
}

export const bazaContentTypesApiTags = Object.values(BazaContentTypesOpenApi);
export const bazaContentTypesCmsApiTags = Object.values(BazaContentTypesCmsOpenApi);
