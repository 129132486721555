import { Injectable } from '@angular/core';
import { BazaNgCoreConfig } from '../baza-ng-core.config';

@Injectable()
export class BazaLocalStorageService {
    private _fallback = {};

    constructor(private readonly moduleConfig: BazaNgCoreConfig) {}

    get(key: string): string {
        const prefixedKey = this.getPrefixedKey(key);

        if (window && window.localStorage) {
            return window.localStorage.getItem(prefixedKey);
        } else {
            return this._fallback[`${prefixedKey}`];
        }
    }

    set(key: string, value: string): void {
        const prefixedKey = this.getPrefixedKey(key);

        if (window && window.localStorage) {
            window.localStorage.setItem(prefixedKey, value);
        } else {
            return this._fallback[`${prefixedKey}`];
        }
    }

    remove(key: string): void {
        const prefixedKey = this.getPrefixedKey(key);

        if (window && window.localStorage) {
            window.localStorage.setItem(prefixedKey, null);
        } else {
            delete this._fallback[`${prefixedKey}`];
        }
    }

    private getPrefixedKey(key: string) {
        return `${this.moduleConfig.localStorageKeyPrefix}-${key}`;
    }
}
