import { BazaNcSearchRequestBaseDto, BazaNcSearchRequestDto } from '../dto/baza-nc-search-request.dto';
import { BazaNcSearchResponseDto } from '../dto/baza-nc-search-response.dto';
import { Observable } from 'rxjs';
import { BazaNcSearchRangesResponseDto } from '../dto/baza-nc-search-ranges-response.dto';

export enum BazaNcSearchEndpointPaths {
    search = '/baza-nc-integration/search',
    searchULIDs = '/baza-nc-integration/search/searchULIDs',
    searchOfferingIds = '/baza-nc-integration/search/offeringIds',
    ranges = '/baza-nc-integration/search/ranges',
}

export interface BazaNcSearchEndpoint {
    search(request: BazaNcSearchRequestDto, ...args: unknown[]): Promise<BazaNcSearchResponseDto> | Observable<BazaNcSearchResponseDto>;
    searchULIDs(request: BazaNcSearchRequestBaseDto, ...args: unknown[]): Promise<Array<string>> | Observable<Array<string>>;
    searchOfferingIds(request: BazaNcSearchRequestBaseDto, ...args: unknown[]): Promise<Array<string>> | Observable<Array<string>>;
    ranges(): Promise<BazaNcSearchRangesResponseDto> | Observable<BazaNcSearchRangesResponseDto>;
}
