import { BazaJwtRequireAclGuardConfig } from './guards/jwt-require-acl.guard';
import { BazaJwtRequireRoleGuardConfig } from './guards/jwt-require-role.guard';
import { BazaJwtVerifyGuardConfig } from './guards/jwt-verify.guard';
import { BazaJwtHttpInterceptorConfig } from './http-interceptors/jwt.http-interceptor';
import { Injectable } from '@angular/core';
import { BazaJwtRequiredNoAuthGuardConfig } from './guards/jwt-require-no-auth.guard';
import { BazaJwtRequiredAuthGuardConfig } from './guards/jwt-require-auth.guard';

/**
 * Disabled JWT storage
 */
interface StorageNotEnabled {
    enabled: false;
}

/**
 * Configuration for JWT local storage
 */
interface JwtLocalStorage {
    enabled: true;
    jwtKey: string;
    jwtPayloadKey: string;
}

/**
 * Configuration for JWT Session storage
 */
interface JwtSessionStorage {
    enabled: true;
    jwtKey: string;
    jwtPayloadKey: string;
}

/**
 * Configuration for DocumentCookie storage
 */
interface JwtDocumentCookieStorage {
    enabled: true;
    jwtKey: string;
    jwtPayloadKey: string;
}

/**
 * Configuration for BazaAuthNgModule
 *
 * @see BazaAuthNgModule
 */
@Injectable()
export class BazaAuthNgConfig {
    /**
     * If true, JwtHttpInterceptor and anything else will attempts to fetch new
     * access token if current one is outdated
     *
     * @defaultValue true
     */
    autoRefreshToken: boolean;

    /**
     * JWT Storages configuration
     */
    jwtStorages: {
        /**
         * Local Storage JWT configuration
         *
         * @see StorageNotEnabled
         * @see JwtLocalStorage
         */
        localStorage: StorageNotEnabled | JwtLocalStorage;

        /**
         * Session Storage JWT configuration
         *
         * @see StorageNotEnabled
         * @see JwtSessionStorage
         */
        sessionStorage: StorageNotEnabled | JwtSessionStorage;

        /**
         * Document Cookie Storage JWT configuration
         *
         * @see StorageNotEnabled
         * @see JwtDocumentCookieStorage
         */
        documentCookieStorage: StorageNotEnabled | JwtDocumentCookieStorage;
    };

    /**
     * Configuration for JwtVerifyGuard
     *
     * @see JwtVerifyGuard
     */
    verifyJwtGuardConfig: BazaJwtVerifyGuardConfig;

    /**
     * Configuration for JwtHttpInterceptor
     *
     * @see JwtHttpInterceptor
     */
    jwtHttpInterceptorConfig: BazaJwtHttpInterceptorConfig;

    /**
     * Configuration for JwtRequireRoleGuard
     *
     * @see JwtRequireRoleGuard
     */
    requireRoleGuardConfig: BazaJwtRequireRoleGuardConfig;

    /**
     * Configuration for JwtRequireAclGuard
     *
     * @see JwtRequireAclGuard
     */
    requireAclGuardConfig?: BazaJwtRequireAclGuardConfig;

    /**
     * Configuration for JwtRequireAuthGuard
     *
     * @see JwtRequireAuthGuard
     */
    requireAuthGuardConfig: BazaJwtRequiredAuthGuardConfig;

    /**
     * Configuration for JwtRequireNoAuthGuard
     *
     * @see JwtRequireAclGuard
     */
    requireNoAuthGuardConfig: BazaJwtRequiredNoAuthGuardConfig;
}
