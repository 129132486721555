import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEnum, IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';
import { BazaAccountConstants } from '../../../../baza-account/src';
import { Observable } from 'rxjs';
import { Auth2FAVerificationMethod } from '../models/auth-2fa-verification-method';

export enum Auth2FASettingsEndpointPaths {
    config = '/baza-auth/2fa/config',
    enable2FAEmail = '/baza-auth/2fa/account-settings/email/enable',
    disable2FAEmail = '/baza-auth/2fa/account-settings/email/disable',
    enable2FAGoogle = '/baza-auth/2fa/account-settings/google-authenticator/enable',
    disable2FAGoogle = '/baza-auth/2fa/account-settings/google-authenticator/disable',
}

export interface Auth2FASettingsEndpoint {
    config(...args: unknown[]): Promise<Auth2FAConfigResponse> | Observable<Auth2FAConfigResponse>;
    enable2FAEmail(request: Auth2FAUpdateSettingsRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    disable2FAEmail(request: Auth2FAUpdateSettingsRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    enable2FAGoogle(request: Auth2FAUpdateSettingsRequest, ...args: unknown[]): Promise<void> | Observable<void>;
    disable2FAGoogle(request: Auth2FAUpdateSettingsRequest, ...args: unknown[]): Promise<void> | Observable<void>;
}

export class Auth2FAConfigResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
        isArray: true,
        description: 'Allowed 2FA methods',
    })
    @IsEnum(Auth2FAVerificationMethod, { each: true })
    @IsArray()
    @IsNotEmpty()
    allowed2FAMethods: Array<Auth2FAVerificationMethod>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
        isArray: true,
        description: '2FA methods which are enabled by default',
    })
    @IsEnum(Auth2FAVerificationMethod, { each: true })
    @IsArray()
    @IsNotEmpty()
    forced2FAMethods: Array<Auth2FAVerificationMethod>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
        isArray: true,
        description: '2FA methods which are enabled for current account',
    })
    @IsEnum(Auth2FAVerificationMethod, { each: true })
    @IsArray()
    @IsNotEmpty()
    enabled2FAMethods: Array<Auth2FAVerificationMethod>;
}

export class Auth2FAUpdateSettingsRequest {
    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    password: string;
}
