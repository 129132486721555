import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class AttachmentImageSetDto {
    @ApiModelProperty()
    xs: string;

    @ApiModelProperty()
    sm: string;

    @ApiModelProperty()
    md: string;

    @ApiModelProperty()
    xl: string;
}
