import { NorthCapitalAccountId } from '../models/account-id';
import { AiMethod } from '../models/ai-method';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class RequestAiVerificationRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsEnum(AiMethod)
    @IsNotEmpty()
    aiMethod: AiMethod;

    @IsString()
    @IsOptional()
    notes?: string;
}
