import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    ContactsCmsEndpoint,
    ContactsCmsEndpointPaths,
    ContactsCmsListRequest,
    ContactsCmsListResponse,
    ContactsCmsMarkAsProcessedRequest,
    ContactsCmsMarkAsUnprocessedRequest,
    ContactsGetByIdRequest,
    ContactsGetByIdResponse,
} from '@scaliolabs/baza-content-types-shared';

@Injectable()
export class ContactsCmsDataAccess implements ContactsCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    list(request: ContactsCmsListRequest): Observable<ContactsCmsListResponse> {
        return this.http.post(ContactsCmsEndpointPaths.list, request);
    }

    getById(request: ContactsGetByIdRequest): Observable<ContactsGetByIdResponse> {
        return this.http.post(ContactsCmsEndpointPaths.getById, request);
    }

    markAsProcessed(request: ContactsCmsMarkAsProcessedRequest): Observable<void> {
        return this.http.post(ContactsCmsEndpointPaths.markAsProcessed, request);
    }

    markAsUnprocessed(request: ContactsCmsMarkAsUnprocessedRequest): Observable<void> {
        return this.http.post(ContactsCmsEndpointPaths.markAsUnprocessed, request);
    }
}
