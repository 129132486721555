import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { BazaNcSearchOperatorGreaterThan } from './operators/gt';
import { BazaNcSearchOperatorNotIn } from './operators/not-in';
import { BazaNcSearchOperatorEqual } from './operators/eq';
import { BazaNcSearchOperatorLessThan } from './operators/lt';
import { BazaNcSearchOperatorIn } from './operators/in';
import { BazaNcSearchOperatorLessThanOrEqual } from './operators/lte';
import { BazaNcSearchOperatorGreaterThanOrEqual } from './operators/gte';
import { BazaNcSearchOperatorNotEqual } from './operators/ne';
import { BazaNcSearchOperatorNotExists } from './operators/exists';
import { BazaNcSearchOperatorExists } from './operators/null';

export type BazaNcSearchOperators =
    | BazaNcSearchOperatorEqual
    | BazaNcSearchOperatorNotEqual
    | BazaNcSearchOperatorExists
    | BazaNcSearchOperatorNotExists
    | BazaNcSearchOperatorIn
    | BazaNcSearchOperatorNotIn
    | BazaNcSearchOperatorLessThan
    | BazaNcSearchOperatorLessThanOrEqual
    | BazaNcSearchOperatorGreaterThan
    | BazaNcSearchOperatorGreaterThanOrEqual;

export const allBazaNcSearchOperators = [
    BazaNcSearchOperatorEqual,
    BazaNcSearchOperatorNotEqual,
    BazaNcSearchOperatorExists,
    BazaNcSearchOperatorNotExists,
    BazaNcSearchOperatorIn,
    BazaNcSearchOperatorNotIn,
    BazaNcSearchOperatorLessThan,
    BazaNcSearchOperatorLessThanOrEqual,
    BazaNcSearchOperatorGreaterThan,
    BazaNcSearchOperatorGreaterThanOrEqual,
];

const bazaNcSearchOperatorDescriptionSwagger = [
    { type: BazaNcSearchOperatorEqual, description: 'Field Value is equal to specified value' },
    { type: BazaNcSearchOperatorNotEqual, description: 'Field Value is not equal to specified value' },
    { type: BazaNcSearchOperatorExists, description: 'Field Value is not null or undefined' },
    { type: BazaNcSearchOperatorNotExists, description: 'Field Value is null or undefined' },
    { type: BazaNcSearchOperatorIn, description: 'Field Value is equal to some of specified values' },
    { type: BazaNcSearchOperatorNotIn, description: 'Field Value is not equal to some of specified values' },
    { type: BazaNcSearchOperatorLessThan, description: 'Field Value is less than specified value' },
    { type: BazaNcSearchOperatorLessThanOrEqual, description: 'Field Value is less or equal to specified value' },
    { type: BazaNcSearchOperatorGreaterThan, description: 'Field Value is greater than specified value' },
    { type: BazaNcSearchOperatorGreaterThanOrEqual, description: 'Field Value is greated or equal to specified value' },
];

export const bazaNcSearchOperatorsSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: allBazaNcSearchOperators.map(($ref) => ({
        $ref: getSchemaPath($ref),
        description: bazaNcSearchOperatorDescriptionSwagger.find((next) => next.type === $ref),
    })),
});
