import { BazaProgressStatusCategoryType } from '../types/baza-progress-status-category.type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class BazaProgressStatusItemDto {
    @ApiModelProperty({
        description: 'title of in progress operation',
    })
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description: 'category of in progress operation',
    })
    @IsString()
    @IsNotEmpty()
    category: BazaProgressStatusCategoryType;

    @ApiModelProperty({
        description: 'Total number of items in progress',
    })
    @IsNumber()
    @IsNotEmpty()
    totalCount: number;

    @ApiModelProperty({
        description: 'the number of Items already progressed',
    })
    @IsNumber()
    @IsNotEmpty()
    progressedItems: number;

    @ApiModelProperty({
        description: 'progress Percentage',
    })
    @IsString()
    @IsNotEmpty()
    percentage: string;

    @ApiModelProperty({
        description: 'date the process started',
    })
    startDate: string;

    @ApiModelProperty({
        description: 'time it took in seconds to finish the process',
    })
    finishTimeSeconds?: number;

    @ApiModelProperty({
        description: 'date the process finished',
    })
    endDate?: string;
}
