import { Application, ProjectLanguage } from '../../../../baza-common/src';

export class AccountSettingsDto {
    language: Array<{
        application: Application;
        language: ProjectLanguage | undefined;
    }>;
}

export function defaultAccountSettings(): AccountSettingsDto {
    return {
        language: [],
    };
}
