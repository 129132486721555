import { TradeId } from '../../../transact-api';
import { BazaNcReportStatus } from '../models/baza-nc-report-status';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';

const BAZA_DWOLLA_ACH_ID_LENGTH = 36;

export class BazaNcReportDto {
    @ApiModelProperty({
        description: 'ULID',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: new Date().toISOString(),
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        required: false,
        description: 'Date Funded At',
        example: new Date().toISOString(),
    })
    dateFundedAt?: string;

    @ApiModelProperty({
        required: false,
        description: 'Date Reported At',
        example: new Date().toISOString(),
    })
    dateReportedAt?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'NC Trade Id',
        example: '1289001',
    })
    tradeId: TradeId;

    @ApiModelProperty({
        description: 'Individual ACH ID assigned by Dwolla',
        example: '9b1675e3',
        required: false,
        minLength: BAZA_DWOLLA_ACH_ID_LENGTH,
        maxLength: BAZA_DWOLLA_ACH_ID_LENGTH,
    })
    achId?: string;

    @ApiModelProperty({
        description: 'Investor Account',
        example: 'JOHN SMITH',
        required: false,
    })
    investorName?: string;

    @ApiModelProperty({
        description: 'NC Offering Name',
        example: 'My Offering',
        required: false,
    })
    offeringName?: string;

    @ApiModelProperty({
        description: 'Total Amount',
        example: '50.00',
        required: false,
    })
    amount?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcReportStatus),
        example: BazaNcReportStatus.ReadyToReport,
    })
    status: BazaNcReportStatus;

    @ApiModelProperty({
        description: 'Can be sent to NC Escrow OPS?',
    })
    canBeSent: boolean;

    @ApiModelProperty({
        description: 'Can be re-sent to NC Escrow OPS?',
    })
    canBeResent: boolean;

    @ApiModelProperty({
        description: 'Can be synced with Dwolla?',
    })
    canBySynced: boolean;
}
