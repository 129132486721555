import { BazaNcOperationDirection } from './baza-nc-operation-direction';

/**
 * Operation Types
 */
export enum BazaNcOperationType {
    Investment = 'Investment',
    Dividend = 'Dividends',
    PayoffDistribution = 'Payoff',
    Withdraw = 'Withdraw',
    Transfer = 'Transfer',
    RewardShare = 'Reward Share',
}

/**
 * Map Type -> Direction for Operations
 */
export const mapBazaNcOperationTypeToDirection: Record<BazaNcOperationType, BazaNcOperationDirection> = {
    [BazaNcOperationType.Investment]: BazaNcOperationDirection.Out,
    [BazaNcOperationType.Dividend]: BazaNcOperationDirection.In,
    [BazaNcOperationType.PayoffDistribution]: BazaNcOperationDirection.In,
    [BazaNcOperationType.Withdraw]: BazaNcOperationDirection.Out,
    [BazaNcOperationType.Transfer]: BazaNcOperationDirection.In,
    [BazaNcOperationType.RewardShare]: BazaNcOperationDirection.In,
};

/**
 * Aliases for Operation Types
 * May be used as i18n for each Operation Type for FE implementation
 */
export const bazaNcOperationTypeAliases: Record<BazaNcOperationType, string> = {
    [BazaNcOperationType.Investment]: 'Investments',
    [BazaNcOperationType.Dividend]: 'Dividends',
    [BazaNcOperationType.PayoffDistribution]: 'Payoff Distributions',
    [BazaNcOperationType.Withdraw]: 'Cash Out',
    [BazaNcOperationType.Transfer]: 'Cash In',
    [BazaNcOperationType.RewardShare]: 'Reward Share',
};
