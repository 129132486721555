import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  EventDto,
  EventsEndpoint,
  EventsEndpointPaths,
  EventsListRequest,
  EventsListResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class EventsDataAccess implements EventsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: EventsListRequest): Observable<EventsListResponse> {
    return this.http.get(EventsEndpointPaths.list, request);
  }

  getAll(): Observable<Array<EventDto>> {
    return this.http.get(EventsEndpointPaths.getAll);
  }

  getById(id: number): Observable<EventDto> {
    return this.http.get(replacePathArgs(EventsEndpointPaths.getById, { id }));
  }

  getByUrl(url: string): Observable<EventDto> {
    return this.http.get(replacePathArgs(EventsEndpointPaths.getByUrl, { url }));
  }
}
