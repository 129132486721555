import { FaqDto } from '../dto/faq.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';

export enum FaqEndpointPaths {
    list = '/baza-content-types/faq/list',
}

export interface FaqEndpoint {
    list(): Promise<FaqListResponse> | Observable<FaqListResponse>;
}

export class FaqListResponse {
    @ApiModelProperty({
        type: FaqDto,
        isArray: true,
        description: 'FAQ Q/A entries',
    })
    entries: Array<FaqDto>;
}
