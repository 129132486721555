import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOfferingStatus } from '../models/baza-nc-offering-status';
import { IssuerId, IssuerType, OfferingId, OfferingStatus } from '../../../transact-api';

export class BazaNcOfferingDto {
    @ApiModelProperty({
        description: 'ID (local id)',
    })
    id: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
        enum: Object.values(BazaNcOfferingStatus),
    })
    status: BazaNcOfferingStatus;

    @ApiModelProperty({
        type: 'string',
        description: 'Percents funded',
        enum: Object.values(BazaNcOfferingStatus),
    })
    percentsFunded: string;

    @ApiModelProperty({
        description: 'Offering Name',
    })
    ncOfferingName: string;

    @ApiModelProperty({
        type: 'number',
        description: 'Current Value as Cents',
    })
    ncCurrentValueCents: number;

    @ApiModelProperty({
        description: 'If true, ncTargetAmount will not be available to end users',
    })
    ncIsTargetAmountHidden: boolean;

    @ApiModelProperty({
        description: 'Placeholder for ncTargetAmount',
    })
    ncTargetAmountPlaceholder?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'North Capital Offering ID',
    })
    ncOfferingId: OfferingId;

    @ApiModelProperty({
        description: 'North Capital Issuer ID (Issuer ID that is generated by the API when an Issuer is created(createIssuer))',
    })
    ncIssuerId: IssuerId;

    @ApiModelProperty({
        description: 'North Capital Issue Name (Name of the Offering)',
    })
    ncIssueName: string;

    @ApiModelProperty({
        type: 'string',
        description: 'North Capital Issuer Type (Type of Offering: Equity, Debt, Hybrid, Fund)',
        enum: Object.values(IssuerType),
    })
    ncIssueType: IssuerType;

    @ApiModelProperty({
        description: 'North Capital Target Amount (The Target Amount of the Raise (should be LESS than Maximum Amount))',
    })
    ncTargetAmount: number;

    @ApiModelProperty({
        description: 'North Capital Minimum Amount (The Minimum amount that can be invested at one time)',
    })
    ncMinAmount: number;

    @ApiModelProperty({
        description: 'North Capital Max Amount (The Maximum amount of the offering (cap amount))',
    })
    ncMaxAmount: number;

    @ApiModelProperty({
        description: 'Max shares per account for total purchases',
    })
    ncMaxSharesPerAccount: number;

    @ApiModelProperty({
        description:
            'North Capital Unit Price (Price per Unit (unit/share price). The investment can only be made in increments of this number.)',
    })
    ncUnitPrice: number;

    @ApiModelProperty({
        description:
            'North Capital Fees (Fees per share). Unit Price for NC will be incremented by this value for Offering and decremented for webhooks and transaction sync.',
    })
    ncOfferingFees?: number;

    @ApiModelProperty({
        description: 'North Capital Start Date (Offering Start Date)',
    })
    ncStartDate: string;

    @ApiModelProperty({
        description: 'North Capital End Date (Offering End Date)',
    })
    ncEndDate: string;

    @ApiModelProperty({
        description: 'North Capital Total Shares',
    })
    ncTotalShares: number;

    @ApiModelProperty({
        description: 'North Capital Remaining Shares',
    })
    ncRemainingShares: number;

    @ApiModelProperty({
        type: 'string',
        description: 'North Capital Offering Status (Optional Approval Status of the offering)',
        enum: Object.values(OfferingStatus),
    })
    ncOfferingStatus: OfferingStatus;

    @ApiModelProperty({
        description: 'North Capital Offering Text (A brief description of the offering.)',
    })
    ncOfferingText: string;

    @ApiModelProperty({
        description: 'Stamping Text For Offerings Images',
    })
    ncStampingText: string;

    @ApiModelProperty({
        description: 'IP of last user who updated offering',
    })
    ncUpdatedIPAddress: string;

    @ApiModelProperty({
        description: 'DocuSign Template IDs for Subscription document',
        type: 'string',
        isArray: true,
    })
    ncSubscriptionOfferingIds: Array<string>;

    @ApiModelProperty({
        description: 'DocuSign Template Id (Main Document)',
    })
    ncSubscriptionOfferingId: string;

    @ApiModelProperty({
        description: 'Days required for a failed trade to expire and released',
    })
    daysToReleaseFailedTrades: number;
}
