import { BazaWebBundleConfig } from './baza-web-bundle.config';
import { BazaMaintenanceNgModuleForRootOptions, BazaNgCoreModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BazaNgEndpointModuleForRootOptions } from '@scaliolabs/baza-core-data-access';
import { BazaI18nNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { TranslateModuleConfig } from '@ngx-translate/core/public_api';
import { BazaVersionNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BazaPhoneCountryCodesNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BazaAuthNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BazaSentryNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BAZA_NG_AUTH_MODULE_CONFIG } from './module-configs/baza-ng-auth.module-config';
import { BAZA_NG_CORE_MODULE_CONFIG } from './module-configs/baza-ng-core.module-config';
import { BAZA_NG_ENDPOINT_MODULE_CONFIG } from './module-configs/baza-ng-endpoint.module-config';
import { BAZA_NG_I18_MODULE_CONFIG, NX_I18N_MODULE_CONFIG } from './module-configs/baza-ng-i18n.module-config';
import { BAZA_NG_MAINTENANCE_MODULE_CONFIG } from './module-configs/baza-ng-maintenance.module-config';
import { BAZA_NG_VERSION_MODULE_CONFIG } from './module-configs/baza-ng-version.module-config';
import { BAZA_PHONE_COUNTRY_CODES_NG_MODULE_CONFIG } from './module-configs/baza-phone-country-codes.module-config';
import { BAZA_NG_SENTRY_MODULE_CONFIG } from './module-configs/baza-ng-sentry.module-config';

export interface BazaWebBundleModuleConfigs {
    BazaNgCoreModule: (config: BazaWebBundleConfig) => BazaNgCoreModuleForRootOptions;
    BazaAuthNgModule: (config: BazaWebBundleConfig) => BazaAuthNgModuleForRootOptions;
    BazaNgEndpointModule: (config: BazaWebBundleConfig) => BazaNgEndpointModuleForRootOptions;
    BazaSentryNgModule: (config: BazaWebBundleConfig) => BazaSentryNgModuleForRootOptions;
    BazaI18nNgModule: (config: BazaWebBundleConfig) => BazaI18nNgModuleForRootOptions;
    TranslateModule: (config: BazaWebBundleConfig) => TranslateModuleConfig;
    BazaVersionNgModule: (config: BazaWebBundleConfig) => BazaVersionNgModuleForRootOptions;
    BazaPhoneCountryCodesNgModule: (config: BazaWebBundleConfig) => BazaPhoneCountryCodesNgModuleForRootOptions;
    BazaMaintenanceNgModule: (config: BazaWebBundleConfig) => BazaMaintenanceNgModuleForRootOptions;
}

export const defaultBazaWebBundleModuleConfigs: BazaWebBundleModuleConfigs = {
    BazaAuthNgModule: BAZA_NG_AUTH_MODULE_CONFIG,
    BazaNgCoreModule: BAZA_NG_CORE_MODULE_CONFIG,
    BazaNgEndpointModule: BAZA_NG_ENDPOINT_MODULE_CONFIG,
    BazaI18nNgModule: BAZA_NG_I18_MODULE_CONFIG,
    TranslateModule: NX_I18N_MODULE_CONFIG,
    BazaMaintenanceNgModule: BAZA_NG_MAINTENANCE_MODULE_CONFIG,
    BazaVersionNgModule: BAZA_NG_VERSION_MODULE_CONFIG,
    BazaPhoneCountryCodesNgModule: BAZA_PHONE_COUNTRY_CODES_NG_MODULE_CONFIG,
    BazaSentryNgModule: BAZA_NG_SENTRY_MODULE_CONFIG,
};
