import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { SetVFLinksConfig, VFConfig } from './data-access';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationComponent implements OnInit {
    @Input()
    dwollaSupport = true;

    currentTab: number;
    i18nBasePath = 'dwvf.vf';
    backLinkUrl: string;
    showLeaveConfirmationModal = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly router: Router,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnInit(): void {
        this.checkConfig();
    }

    checkConfig() {
        const routeQueryParams = this.route.snapshot?.queryParams;
        const config: VFConfig = {
            vfLink: this.wts.getI18nLabel(this.i18nBasePath, 'links.vfLink'),
            back: routeQueryParams?.back ?? this.wts.getI18nLabel(this.i18nBasePath, 'links.defaultRedirect'),
            redirect: routeQueryParams?.redirect ?? null,
            dwollaSupport: this.dwollaSupport,
        };

        this.backLinkUrl = config.back;
        this.store.dispatch(new SetVFLinksConfig(config));
    }

    onConfirm() {
        this.router.navigateByUrl(this.backLinkUrl);
    }
}
