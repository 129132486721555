export const WebUiPaymentItemBankEnI18n = {
    editMode: {
        title: 'Bank Account',
        hint: 'Used to purchase shares',
        details: {
            name: 'Account holder name:',
            number: 'Account number:',
            type: 'Account type:',
        },
        actions: {
            update: 'Update',
        },
    },
    addMode: {
        plaid: 'Bank Account',
    },
};
