import { BazaNcDividendImportCsvEntryError } from '../models/baza-nc-dividend-import-csv-entry-error';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcDividendImportCsvEntryErrorDto {
    @ApiModelProperty({
        description: 'CSV Line (excepts header)',
    })
    row: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendImportCsvEntryError),
        description: 'Error type',
    })
    reason: BazaNcDividendImportCsvEntryError;

    @ApiModelProperty({
        description: 'Exception (for "Exception" reason only)',
        required: false,
    })
    exception?: string;
}
