import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { AccountRole } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { JwtService } from '../services/jwt.service';

/**
 * Helper Interface for Route Data object
 * Implement it in Route's Data object or use it just as documentation source
 */
export interface BazaJwtRequireRoleGuardRouteConfig<T = AccountRole> {
    jwtRequireRoleGuard: Partial<BazaJwtRequireRoleGuardConfig> & {
        roles: Array<T>;
    }
}

/**
 * Configuration for JwtRequireRoleGuard
 *
 * @example
 * Replace default guard configuration with bazaWebBundleConfigBuilder helper.
 *
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               requireRoleGuardConfig: {
 *                   // Your configuration
 *               },
 *               // Additional required configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 *
 * @example
 * Replace default guard redirect with global injectable constant:
 *
 * ```typescript
 * import { BAZA_WEB_BUNDLE_GUARD_CONFIGS } from '@scaliolabs/baza-core-web`
 *
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireRoleGuardConfig.redirect = () => ({
 *     commands: ['/'],
 *     navigationExtras: {
 *         queryParams: {
 *             signIn: 1,
 *         },
 *     },
 * })
 * ```
 */
export type BazaJwtRequireRoleGuardConfig = {
    /**
     * If JwtRequireRoleGuard fails, user will be redirected to specific route
     *
     * @param route
     * @param requestedRoles List or roles requested by guard
     */
    redirect?: (route: ActivatedRouteSnapshot, requestedRoles: Array<AccountRole>) => {
        commands: any[];
        navigationExtras?: UrlCreationOptions;
    };
}

/**
 * Common guard which will require that account has one of specified roles
 * List of allowed roles should be set with `roles` field of route's Data object
 *
 * Guard can be used with canActivate or canActivateChild configurations.
 *
 * @see AccountRole
 * @see JwtRequireUserGuard
 * @see JwtRequireAdminGuard
 *
 * @example
 * ```typescript
 *   import { Routes } from '@angular/router';
 *   import { JwtRequireRoleGuard } from '@scaliolabs/baza-core-ng';
 *   import { MyComponent } from './components/my/my.component';
 *   import { MyAcl } from '@scaliolabs/my-shared';
 *   import { AccountRole } from '@scaliolabs/baza-core-shared';
 *
 *  export const myRoutes: Routes = [{
 *      path: 'my-route',
 *      component: MyComponent,
 *      canActivateChild: [
 *          JwtRequireRoleGuard,
 *      ],
 *      data: {
 *          jwtRequireRoleGuard: {
 *              roles: [AccountRole.User, AccountRole.Admin],
 *              // Optionally - you can set up custom redirect
 *              redirect: ((route: ActivatedRouteSnapshot, requestedRoles: Array<AccountRole>) => ({
 *                  commands: ['/'],
 *                  navigationExtras: {
 *                      queryParams: {
 *                          login: 1,
 *                      },
 *                  },
 *              }),
 *          },
 *      },
 * }];
 * ```
 */
@Injectable()
export class JwtRequireRoleGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly jwtService: JwtService,
        private readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validatedRoles(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validatedRoles(childRoute);
    }

    private config(route: ActivatedRouteSnapshot): BazaJwtRequireRoleGuardRouteConfig {
        const moduleConfig = this.moduleConfig.requireRoleGuardConfig;
        const customConfig = ((route.data || {}) as BazaJwtRequireRoleGuardRouteConfig).jwtRequireRoleGuard;

        return {
            jwtRequireRoleGuard: {
                ...moduleConfig,
                ...customConfig,
            },
        };
    }

    private validatedRoles(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const config = this.config(route);

        if (! this.jwtService.hasJwt()) {
            return this.fail(route);
        }

        if (! config.jwtRequireRoleGuard.roles.some((role) => this.jwtService.jwtPayload.accountRole === role)) {
            return this.fail(route);
        }

        return true;
    }

    private fail(route: ActivatedRouteSnapshot): UrlTree | false {
        const config = this.config(route);

        if (config.jwtRequireRoleGuard.redirect) {
            const url = config.jwtRequireRoleGuard.redirect(route, config.jwtRequireRoleGuard.roles);

            return this.router.createUrlTree(url.commands, url.navigationExtras);
        } else {
          return false;
        }
    }
}
