import { BazaCoreE2eFixtures, BazaCoreE2eFixturesAdditionalOptions } from '../models/baza-core-e2e-fixtures';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsArray, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export enum BazaE2eFixturesEndpointErrorCodes {
    BazaE2eFixturesNotFound = 'BazaE2eFixturesNotFound',
}

export enum BazaE2eFixturesEndpointPaths {
    up = '/baza-e2e-fixtures/up',
}

export interface BazaE2eFixturesEndpoint {
    up(request: BazaE2eFixturesUpRequest): Promise<void> | Observable<void>;
}

export class BazaE2eFixturesUpRequest<T = unknown> {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaCoreE2eFixtures),
        isArray: true,
        description:
            'Only core fixtures described in API documentation. You may add additional e2e fixtures with defineE2eFixture function',
    })
    @IsArray()
    @IsNotEmpty()
    fixtures: Array<T>;

    @ApiModelProperty({
        description: 'Source spec. Source spec will be logged and could be used for debug reasons',
        required: true,
    })
    @IsString()
    @IsNotEmpty()
    specFile: string;

    @ApiModelProperty({
        type: () => BazaCoreE2eFixturesAdditionalOptions,
        required: false,
    })
    @ValidateNested()
    @Type(() => BazaCoreE2eFixturesAdditionalOptions)
    @IsOptional()
    additionalOptions?: BazaCoreE2eFixturesAdditionalOptions;
}
