import { HealthIndicatorResult } from '@nestjs/terminus/dist/health-indicator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNumber } from 'class-validator';

export type BazaHealthIndicatorResult = HealthIndicatorResult;

export enum healthCheckItems {
    writable_tmp_dir = 'writable_tmp_dir',
    memory_heap = 'memory_heap',
    memory_rss = 'memory_rss',
    database = 'database',
    kafka = 'kafka',
    baza_docs = 'baza_docs',
    baza_redoc = 'baza_redoc',
}

export class HealthDto {
    @ApiModelProperty({
        description: 'Simple enum to show everything is Ok or Not',
    })
    @IsNumber()
    status: number;

    @ApiModelProperty({
        required: false,
        description: 'A json of all items, that checked by health-check',
    })
    info?: BazaHealthIndicatorResult;

    @ApiModelProperty({
        required: false,
        description: 'Custom error, appears instead of throwing 503 error',
    })
    error?: BazaHealthIndicatorResult;
}
