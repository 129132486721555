import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcTaxDocumentDto {
    @ApiModelProperty()
    readonly id: number;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty({
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    description?: string;

    @ApiModelProperty()
    fileUrl: string;
}
