// Warning: it can be lowercase or capitalized.
// Consider to capitalize or .toUpperCase() values before comparing values

export enum KYCStatus {
    None = '',
    Pending = 'Pending',
    Approved = 'Approved',
    AutoApproved = 'Auto Approved',
    ManuallyApproved = 'Manually Approved',
    Disapproved = 'Disapproved',
    NeedMoreInfo = 'Need More Info',
}
