import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcPayoffDistributionDto } from '../dto/baza-nc-payoff-distribution.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BazaNcPayoffDistributionPaymentStatus } from '../models/baza-nc-payoff-distribution-payment-status';
import { IsNotEmpty, IsString } from 'class-validator';
import { ulid } from 'ulid';

export enum BazaNcPayoffDistributionEndpointPaths {
    list = '/baza-nc/payoff-distribution/list',
    getByUlid = '/baza-nc/payoff-distribution/get/:ulid',
    totalAmount = '/baza-nc/payoff-distribution/total',
    reprocess = '/baza-nc/payoff-distribution/reprocess',
}

export interface BazaNcPayoffDistributionEndpoint {
    list(
        request: BazaNcPayoffDistributionListRequest,
        ...args: unknown[]
    ): Promise<BazaNcPayoffDistributionListResponse> | Observable<BazaNcPayoffDistributionListResponse>;
    getByUlid(ulid: string, ...args: unknown[]): Promise<BazaNcPayoffDistributionDto> | Observable<BazaNcPayoffDistributionDto>;
    totalAmount(...args: unknown[]): Promise<BazaNcPayoffDistributionTotalAmountResponse> | Observable<BazaNcPayoffDistributionTotalAmountResponse>;
    reprocess(request: BazaNcPayoffDistributionReprocessRequest, ...args: unknown[]): Promise<BazaNcPayoffDistributionDto> | Observable<BazaNcPayoffDistributionDto>;
}

export class BazaNcPayoffDistributionListRequest extends CrudListRequestDto<BazaNcPayoffDistributionDto> {
    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        required: false,
    })
    status?: Array<BazaNcPayoffDistributionPaymentStatus>;
}

export class BazaNcPayoffDistributionListResponse extends CrudListResponseDto<BazaNcPayoffDistributionDto> {
    @ApiModelProperty({
        type: BazaNcPayoffDistributionDto,
        isArray: true,
    })
    items: Array<BazaNcPayoffDistributionDto>;
}

export class BazaNcPayoffDistributionTotalAmountResponse {
    @ApiModelProperty({
        example: '12.55',
        description: 'Total Amount',
    })
    totalAmount: string;

    @ApiModelProperty({
        example: 1255,
        description: 'Total Amount (as cents)',
    })
    totalAmountCents: number;
}

export class BazaNcPayoffDistributionReprocessRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}
