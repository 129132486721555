import { DocumentId } from '../models/document-id';
import { SignedStatus } from '../models/signed-status';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

export class UpdateDocuSignStatusRequest {
    @IsString()
    @IsNotEmpty()
    documentId: DocumentId;

    @IsEnum(SignedStatus)
    @IsNotEmpty()
    signedStatus: SignedStatus;
}
