export const WebUiMarketingBannerEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="ctaLink"></a>`,
            linkConfig: {
                key: `ctaLink`,
                link: {
                    appLink: {
                        commands: ['/items'],
                        text: 'View Offering',
                    },
                },
            },
        },
    },
};
