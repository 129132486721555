import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { BackToTopComponent } from './back-to-top.component';
import { NgModule } from '@angular/core';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';

@NgModule({
    imports: [CommonModule, NzBackTopModule, NzIconModule],
    exports: [BackToTopComponent],
    declarations: [BackToTopComponent],
    providers: [],
})
export class BackToTopModule {}
