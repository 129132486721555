import { bazaContentTypeDependencies, BazaContentTypes } from './baza-content-types';
import { BazaContentTypeCategoriesConfig, bazaContentTypeCategoriesDefaultConfig } from '../../../libs/categories/src';
import { BazaContentTypeNewslettersConfig, bazaContentTypeNewslettersDefaultConfig } from '../../../libs/newsletters/src';
import { BazaContentTypeTeamsConfig, bazaContentTypeTeamsDefaultConfig } from '../../../libs/teams/src';
import { BazaContentTypeBlogConfig, bazaContentTypeBlogDefaultConfig } from '../../../libs/blog/src';
import { BazaContentTypeTestimonialsConfig, bazaContentTypeTestimonialsDefaultConfig } from '../../../libs/testimonials/src';
import { BazaContentTypeNewsConfig, bazaContentTypeNewsDefaultConfig } from '../../../libs/news/src';
import {
    BazaContentTypeBootstrapPageRequest,
    BazaContentTypePagesConfig,
    bazaContentTypePagesDefaultConfig,
} from '../../../libs/pages/src';
import { BazaContentTypeEventsConfig, bazaContentTypeEventsDefaultConfig } from '../../../libs/events/src';
import { BazaContentTypeJobsConfig, bazaContentTypeJobsDefaultConfig } from '../../../libs/jobs/src';
import { BazaContentTypeTimelineConfig, bazaContentTypeTimelineDefaultConfig } from '../../../libs/timeline/src';
import { BazaContentTypeMktConfig, bazaContentTypeMktPageConfig } from '../../../libs/mkt-pages/src';

export class BazaContentTypesConfiguration {
    enabled: Array<BazaContentTypes>;
    configs: BazaContentTypesConfigurations;
}

export class BazaContentTypesConfigurations {
    categories: BazaContentTypeCategoriesConfig;
    newsletters: BazaContentTypeNewslettersConfig;
    teams: BazaContentTypeTeamsConfig;
    blog: BazaContentTypeBlogConfig;
    testimonials: BazaContentTypeTestimonialsConfig;
    news: BazaContentTypeNewsConfig;
    pages: BazaContentTypePagesConfig;
    marketingPages: BazaContentTypeMktConfig;
    events: BazaContentTypeEventsConfig;
    jobs: BazaContentTypeJobsConfig;
    timeline: BazaContentTypeTimelineConfig;
}

export function defaultBazaContentTypesConfiguration(): BazaContentTypesConfiguration {
    return {
        enabled: Object.values(BazaContentTypes),
        configs: {
            categories: bazaContentTypeCategoriesDefaultConfig(),
            newsletters: bazaContentTypeNewslettersDefaultConfig(),
            teams: bazaContentTypeTeamsDefaultConfig(),
            blog: bazaContentTypeBlogDefaultConfig(),
            testimonials: bazaContentTypeTestimonialsDefaultConfig(),
            news: bazaContentTypeNewsDefaultConfig(),
            pages: bazaContentTypePagesDefaultConfig(),
            marketingPages: bazaContentTypeMktPageConfig(),
            events: bazaContentTypeEventsDefaultConfig(),
            jobs: bazaContentTypeJobsDefaultConfig(),
            timeline: bazaContentTypeTimelineDefaultConfig(),
        },
    };
}

export const BAZA_CONTENT_TYPES_CONFIGURATION: BazaContentTypesConfiguration = defaultBazaContentTypesConfiguration();

export function bazaContentTypesConfigure(
    configs: (defaults: BazaContentTypesConfiguration) => Partial<BazaContentTypesConfiguration>,
): void {
    Object.assign(BAZA_CONTENT_TYPES_CONFIGURATION, configs(defaultBazaContentTypesConfiguration()));
}

export function isBazaContentTypeEnabled(contentType: BazaContentTypes): boolean {
    for (const enabledContentType of BAZA_CONTENT_TYPES_CONFIGURATION.enabled) {
        const def = bazaContentTypeDependencies.find((d) => d.type === enabledContentType);

        if (enabledContentType === contentType || def.deps.includes(contentType)) {
            return true;
        }
    }

    return false;
}

export function bazaContentTypeBootstrapPages(requests: Array<BazaContentTypeBootstrapPageRequest>): void {
    BAZA_CONTENT_TYPES_CONFIGURATION.configs.pages.bootstrapPages.push(...requests);
}
