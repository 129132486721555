import { IssuerId } from '../models/issuer-id';
import { AccountTypeCheckingSaving } from '../models/account-type-checking-saving';
import { EntityTypeExtended } from '../models/entity-type-extended';
import { NorthCapitalReverseDate, NorthCapitalYesNoBoolean } from '../common-types';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreateIssuerAccountRequest {
    @IsString()
    @IsNotEmpty()
    issuerId: IssuerId;

    @IsString()
    @IsNotEmpty()
    accountType: AccountTypeCheckingSaving;

    @IsString()
    @IsNotEmpty()
    companyName: string;

    @IsString()
    @IsNotEmpty()
    companyState: string;

    @IsEnum(EntityTypeExtended)
    @IsOptional()
    entityType?: EntityTypeExtended;

    @IsString()
    @IsNotEmpty()
    companyTaxID: number;

    @IsString()
    @IsOptional()
    accountMiddleInitial?: string;

    @IsString()
    @IsOptional()
    socialSecurityNumber?: string;

    @IsString()
    @IsOptional()
    dob?: NorthCapitalReverseDate;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    residentUS?: NorthCapitalYesNoBoolean;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    citizenUS?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    addressline1?: string;

    @IsString()
    @IsOptional()
    addressline2?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    zip?: string;

    @IsString()
    @IsOptional()
    country?: string;

    @IsString()
    @IsOptional()
    additionalInfo?: string;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;

    @IsString()
    @IsNotEmpty()
    issuingCountry: string;
}
