import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { NcTransactionDto } from '../dto/nc-transaction.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { NcOfferingInvestmentDto } from '../dto/nc-offering-investment.dto';

export enum BazaNcTransactionEndpointPaths {
    list = '/baza-nc/transactions/list',
    getById = '/baza-nc/transactions/getById',
    investments = '/baza-nc/transactions/investments',
}

export interface BazaNcTransactionEndpoint {
    list(
        request: BazaNcTransactionListRequest,
        ...args
    ): Promise<BazaNcTransactionListResponse> | Observable<BazaNcTransactionListResponse>;
    getById(
        request: BazaNcTransactionGetByIdRequest,
    ): Promise<BazaNcTransactionGetByIdResponse> | Observable<BazaNcTransactionGetByIdResponse>;
    investments(
        request: BazaNcTransactionInvestmentsListRequest,
        ...args
    ): Promise<BazaNcTransactionInvestmentsResponse> | Observable<BazaNcTransactionInvestmentsResponse>;
}

export class BazaNcTransactionGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type BazaNcTransactionGetByIdResponse = NcTransactionDto;

export class BazaNcTransactionListRequest extends CrudListRequestDto<NcTransactionDto> {}
export class BazaNcTransactionListResponse extends CrudListResponseDto<NcTransactionDto> {}

export class BazaNcTransactionInvestmentsListRequest extends CrudListRequestDto<NcOfferingInvestmentDto> {}

export class BazaNcTransactionInvestmentsResponse extends CrudListResponseDto<NcOfferingInvestmentDto> {
    @ApiModelProperty({
        type: NcOfferingInvestmentDto,
        isArray: true,
    })
    items: Array<NcOfferingInvestmentDto>;
}
