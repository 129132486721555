import { NewsDto } from './news.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class NewsCmsDto extends NewsDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty({
        required: false,
    })
    imageAwsS3Key?: string;

    @ApiModelProperty({
        required: false,
    })
    previewAwsS3Key?: string;
}
