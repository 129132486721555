<nz-header>
    <a
        class="logo"
        [routerLink]="['/']">
        <img
            src="/assets/images/logo-black.svg"
            alt="SANDBOX" />
    </a>

    <button
        class="menu-icon"
        title="Menu"
        [class.main-menu-open]="openedMenu"
        (click)="this.onMenuToggle()">
        <span class="line line-1"></span>
        <span class="line line-2"></span>
        <span class="line line-3"></span>
    </button>

    <ul
        class="main-menu"
        nz-menu
        nzMode="horizontal"
        nzTheme="light"
        [class.main-menu-open]="openedMenu"
        *ngIf="{ user: user$ | async } as data">
        <li
            nz-menu-item
            nzMatchRouter>
            <a [routerLink]="['/items']">Listings</a>
        </li>

        <li
            nz-menu-item
            nzMatchRouter>
            <a [routerLink]="['/news']">The Latest</a>

            <span
                *ngIf="(hasAnyUpdates$ | async) === true"
                class="menu-indicator"></span>
        </li>

        <ng-container *ngIf="data.user">
            <li
                nz-menu-item
                nzMatchRouter>
                <a [routerLink]="['/portfolio']">My Portfolio</a>
            </li>

            <li
                nz-menu-item
                nzMatchRouter>
                <a [routerLink]="['/favorites']">Favorites</a>
            </li>

            <li
                class="hide-mobile"
                data-cy="header-user-menu"
                nz-submenu
                [nzTitle]="titleTpl">
                <ul>
                    <li
                        nz-menu-item
                        nzMatchRouter>
                        <a [routerLink]="['/account']">Account</a>
                    </li>

                    <li
                        data-cy="header-user-menu-logout"
                        nz-menu-item>
                        <a
                            role="button"
                            (click)="onLogout()">
                            Log Out
                        </a>
                    </li>
                </ul>
            </li>

            <ng-template #titleTpl>
                <span class="submenu-custom-title">
                    Welcome, {{ data.user.firstName }}
                    <i class="icon icon-down icon-wt dropdown-icon"></i>
                </span>
            </ng-template>

            <li
                class="hide-desktop"
                nz-menu-item
                nzMatchRouter>
                <a [routerLink]="['/account']">Account</a>
            </li>

            <li
                class="hide-desktop"
                nz-menu-divider></li>

            <li
                class="hide-desktop"
                data-cy="header-user-menu-logout"
                nz-menu-item>
                <a
                    role="button"
                    (click)="onLogout()">
                    Log Out
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="!data.user">
            <li
                class="hide-mobile"
                data-cy="header__sign-in"
                nz-menu-item
                nzMatchRouter>
                <a
                    [routerLink]="['', { outlets: { modal: ['auth', 'login'] } }]"
                    [state]="{ skipScroll: true }">
                    Sign In
                </a>
            </li>

            <li
                class="hide-mobile"
                data-cy="header__sign-up"
                nz-menu-item
                nzMatchRouter>
                <button
                    nz-button
                    nzType="primary"
                    type="button"
                    [routerLink]="['', { outlets: { modal: ['auth', 'signup'] } }]"
                    [state]="{ skipScroll: true }">
                    Sign Up
                </button>
            </li>

            <li
                class="hide-desktop"
                nz-menu-item
                nzMatchRouter>
                <div class="main-menu__wrap">
                    <button
                        nz-button
                        nzSize="large"
                        type="button"
                        [routerLink]="['', { outlets: { modal: ['auth', 'login'] } }]"
                        [state]="{ skipScroll: true }">
                        Sign In
                    </button>

                    <button
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        type="button"
                        [routerLink]="['', { outlets: { modal: ['auth', 'signup'] } }]"
                        [state]="{ skipScroll: true }">
                        Sign Up
                    </button>
                </div>
            </li>
        </ng-container>
    </ul>
</nz-header>
