export enum BazaDwollaInvestorAccountErrorCodes {
    DwollaInvestorAccountNotFound = 'DwollaInvestorAccountNotFound',
    DwollaInvestorAccountIsNotAvailableForAccount = 'DwollaInvestorAccountIsNotAvailableForAccount',
    DwollaInvestorAccountUpdatePartyResidentialCityIsInvalid = 'DwollaInvestorAccountUpdatePartyResidentialCityIsInvalid',
    DwollaInvestorAccountUpdatePartyInvalidPayload = 'DwollaInvestorAccountUpdatePartyInvalidPayload',
}

export const bazaDwollaInvestorAccountErrorCodesEnI18n = {
    [BazaDwollaInvestorAccountErrorCodes.DwollaInvestorAccountNotFound]: 'Issuer account not found',
    [BazaDwollaInvestorAccountErrorCodes.DwollaInvestorAccountIsNotAvailableForAccount]: 'Investor account is not available yet',
    [BazaDwollaInvestorAccountErrorCodes.DwollaInvestorAccountUpdatePartyResidentialCityIsInvalid]: 'Please enter a valid residential city name.',
    [BazaDwollaInvestorAccountErrorCodes.DwollaInvestorAccountUpdatePartyInvalidPayload]:
        'invalid payload provided for updating investor party details.',
};
