import {
    AttachmentDto,
    BazaCommentCmsCreateResponse,
    BazaCommentCmsDeleteRequest,
    BazaCommentCmsListResponse,
    BazaCommentListRequest,
    BazaCommentsCmsCreateRequest,
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingsCmsDto } from '../dto/baza-nc-integration-listings-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    ArrayNotEmpty,
    IsArray,
    IsBoolean,
    IsIn,
    IsInt,
    IsNotEmpty,
    IsObject,
    IsOptional,
    IsPositive,
    IsString,
    ValidateNested,
} from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcOfferingStatus, BazaNcUiOfferingDto } from '@scaliolabs/baza-nc-shared';
import { BazaNcIntegrationListingsListItemDto } from '../dto/baza-nc-integration-listings-list-item.dto';
import { BazaNcIntegrationSchemaDto } from '../../../../schema/src';
import { BazaNcIntegrationListingCmsDocumentsDto } from '../dto/baza-nc-integration-listings-document-cms.dto';
import { Type } from 'class-transformer';

export enum BazaNcIntegrationListingsCmsEndpointPaths {
    create = '/baza-nc-integration/cms/baza-nc-integration/listings/create',
    update = '/baza-nc-integration/cms/baza-nc-integration/listings/update',
    delete = '/baza-nc-integration/cms/baza-nc-integration/listings/delete',
    list = '/baza-nc-integration/cms/baza-nc-integration/listings/list',
    listAll = '/baza-nc-integration/cms/baza-nc-integration/listings/listAll',
    getById = '/baza-nc-integration/cms/baza-nc-integration/listings/getById',
    getByUlid = '/baza-nc-integration/cms/baza-nc-integration/listings/getByUlid',
    changeStatus = '/baza-nc-integration/cms/baza-nc-integration/listings/changeStatus',
    setSortOrder = '/baza-nc-integration/cms/baza-nc-integration/listings/setSortOrder',
    commentsList = '/baza-nc-integration/cms/baza-nc-integration/listings/comments/list',
    createComment = '/baza-nc-integration/cms/baza-nc-integration/listings/comments/create',
    deleteComment = '/baza-nc-integration/cms/baza-nc-integration/listings/comments/delete',
}

export interface BazaNcIntegrationListingsCmsEndpoint {
    create(
        request: BazaNcIntegrationListingsCmsCreateRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsCmsDto> | Observable<BazaNcIntegrationListingsCmsDto>;

    update(
        request: BazaNcIntegrationListingsCmsUpdateRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationListingsCmsDto> | Observable<BazaNcIntegrationListingsCmsDto>;

    delete(request: BazaNcIntegrationListingsCmsDeleteRequest): Promise<void> | Observable<void>;

    list(
        request: BazaNcIntegrationListingsCmsListRequest,
    ): Promise<BazaNcIntegrationListingsCmsListResponse> | Observable<BazaNcIntegrationListingsCmsListResponse>;

    listAll(): Promise<Array<BazaNcIntegrationListingsListItemDto>> | Observable<Array<BazaNcIntegrationListingsListItemDto>>;

    getById(
        request: BazaNcIntegrationListingsCmsGetByIdRequest,
    ): Promise<BazaNcIntegrationListingsCmsDto> | Observable<BazaNcIntegrationListingsCmsDto>;

    getByUlid(
        request: BazaNcIntegrationListingsCmsGetByUlidRequest,
    ): Promise<BazaNcIntegrationListingsCmsDto> | Observable<BazaNcIntegrationListingsCmsDto>;

    changeStatus(request: BazaNcIntegrationChangeStatusRequest): Promise<void> | Observable<void>;

    setSortOrder(
        request: BazaNcIntegrationListingsCmsSetSortOrderRequest,
    ): Promise<BazaNcIntegrationListingsCmsSortOrderResponse> | Observable<BazaNcIntegrationListingsCmsSortOrderResponse>;

    commentsList(
        request: BazaCommentListRequest,
        ...args: unknown[]
    ): Promise<BazaCommentCmsListResponse> | Observable<BazaCommentCmsListResponse>;

    saveComment(
        request: BazaCommentsCmsCreateRequest,
        ...args: unknown[]
    ): Promise<BazaCommentCmsCreateResponse> | Observable<BazaCommentCmsCreateResponse>;

    deleteComment(request: BazaCommentCmsDeleteRequest): Promise<void> | Observable<void>;
}

export class BazaNcIntegrationListingsCmsEntityBody {
    @ApiModelProperty({
        description: 'Is listing available for public API?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => AttachmentDto)
    @IsNotEmpty()
    cover: AttachmentDto;

    @ApiModelProperty({
        type: AttachmentDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => AttachmentDto)
    @ArrayNotEmpty()
    @IsNotEmpty()
    images: Array<AttachmentDto>;

    @ApiModelProperty({
        description: 'Number of shares required to automatically join user to Elite Investors',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    sharesToJoinEliteInvestors?: number;

    @ApiModelProperty({
        description: 'Offering Details',
    })
    @ValidateNested()
    @Type(() => BazaNcUiOfferingDto)
    @IsNotEmpty()
    offering: BazaNcUiOfferingDto;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, string>;

    @ApiModelProperty({
        description: 'Schema (as JSON) used for values field',
        required: false,
    })
    @ValidateNested()
    @Type(() => BazaNcIntegrationSchemaDto)
    @IsOptional()
    schema?: BazaNcIntegrationSchemaDto;

    @ApiModelProperty({
        description: 'Schema (as ID) used for values field',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    schemaId?: number;

    @ApiModelProperty({
        description: 'Schema values',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
        required: false,
    })
    @IsObject()
    @IsOptional()
    properties?: Record<string, unknown>;

    @ApiModelProperty({
        description: 'Statement Documents',
        type: BazaNcIntegrationListingCmsDocumentsDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => BazaNcIntegrationListingCmsDocumentsDto)
    @IsArray()
    statements: Array<BazaNcIntegrationListingCmsDocumentsDto>;

    @ApiModelProperty({
        description: 'Documents',
        type: BazaNcIntegrationListingCmsDocumentsDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => BazaNcIntegrationListingCmsDocumentsDto)
    @IsArray()
    @IsOptional()
    documents?: Array<BazaNcIntegrationListingCmsDocumentsDto>;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsOptional()
    seo?: BazaSeoDto;
}

export class BazaNcIntegrationListingsCmsCreateRequest extends BazaNcIntegrationListingsCmsEntityBody {}

export class BazaNcIntegrationListingsCmsUpdateRequest extends BazaNcIntegrationListingsCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationListingsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationListingsCmsListRequest extends CrudListRequestDto<BazaNcIntegrationListingsCmsDto> {
    @ApiModelProperty({
        type: 'string',
        isArray: true,
        description: 'Filter by Listings with specified Schema (Schema Title)',
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    schemas?: Array<string>;
}

export class BazaNcIntegrationListingsCmsListResponse extends CrudListResponseDto<BazaNcIntegrationListingsCmsDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationListingsCmsDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationListingsCmsDto>;
}

export class BazaNcIntegrationListingsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationListingsCmsGetByUlidRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcIntegrationListingsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class BazaNcIntegrationListingsCmsSortOrderResponse extends CrudSetSortOrderResponseDto {}

export class BazaNcIntegrationChangeStatusRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
        enum: Object.values(BazaNcOfferingStatus),
    })
    @IsIn(Object.values(BazaNcOfferingStatus))
    newStatus: BazaNcOfferingStatus;
}
