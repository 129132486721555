import { Injectable } from '@angular/core';
import { BazaRegistryPublicSchema, RegistryPublicNode, RegistryType } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaRegistryNgService {
    private _publicSchema: BazaRegistryPublicSchema;

    set publicSchema(publicSchema: BazaRegistryPublicSchema) {
        this._publicSchema = publicSchema;
    }

    get publicSchema(): BazaRegistryPublicSchema {
        return this._publicSchema;
    }

    node(id: string): RegistryPublicNode {
        const result = this.publicSchema.find((k) => k.id === id);

        return result || {
            id,
            node: {
                name: 'NOT FOUND',
                optional: true,
                type: RegistryType.String,
                public: true,
                hiddenFromList: false,
                defaults: '–',
                value: '–',
            },
        };
    }

    value(id: string): any {
        return this.node(id).node.value;
    }
}
