import { AccountDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationSubscribeStatus } from '../models/baza-nc-integration-subscribe-status';
import { BazaNcIntegrationSubscribeMessages } from '../models/baza-nc-integration-subscribe-messages';
import { BazaNcIntegrationListingsCmsDto } from '../../../../listings/src';
import { BazaNcInvestorAccountDto } from '@scaliolabs/baza-nc-shared';

export class BazaNcIntegrationSubscriptionDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    account: AccountDto;

    @ApiModelProperty({ required: false })
    investorAccount?: BazaNcInvestorAccountDto;

    @ApiModelProperty()
    listing: BazaNcIntegrationListingsCmsDto;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationSubscribeStatus),
    })
    status: BazaNcIntegrationSubscribeStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationSubscribeMessages),
        isArray: true,
    })
    sent: Array<BazaNcIntegrationSubscribeMessages>;
}
