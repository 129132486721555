export enum BazaContentTypes {
    Blogs = 'Blogs',
    Categories = 'Categories',
    Contacts = 'Contacts',
    Events = 'Events',
    Faq = 'Faq',
    Jobs = 'Jobs',
    News = 'News',
    Newsletters = 'Newsletters',
    Pages = 'Pages',
    Press = 'Press',
    Marketing = 'Marketing',
    Teams = 'Teams',
    Testimonials = 'Testimonials',
    Tags = 'Tags',
    Timeline = 'Timeline',
    Feed = 'Feed',
}

export const bazaContentTypeDependencies: Array<{
    type: BazaContentTypes;
    deps: Array<BazaContentTypes>;
}> = [
    {
        type: BazaContentTypes.Blogs,
        deps: [],
    },
    {
        type: BazaContentTypes.Categories,
        deps: [],
    },
    {
        type: BazaContentTypes.Contacts,
        deps: [],
    },
    {
        type: BazaContentTypes.Events,
        deps: [],
    },
    {
        type: BazaContentTypes.Faq,
        deps: [],
    },
    {
        type: BazaContentTypes.Feed,
        deps: [],
    },
    {
        type: BazaContentTypes.Jobs,
        deps: [],
    },
    {
        type: BazaContentTypes.News,
        deps: [],
    },
    {
        type: BazaContentTypes.Newsletters,
        deps: [],
    },
    {
        type: BazaContentTypes.Pages,
        deps: [],
    },
    {
        type: BazaContentTypes.Marketing,
        deps: [],
    },
    {
        type: BazaContentTypes.Press,
        deps: [],
    },
    {
        type: BazaContentTypes.Teams,
        deps: [],
    },
    {
        type: BazaContentTypes.Testimonials,
        deps: [],
    },
    {
        type: BazaContentTypes.Tags,
        deps: [],
    },
    {
        type: BazaContentTypes.Timeline,
        deps: [BazaContentTypes.Tags, BazaContentTypes.Categories],
    },
];
