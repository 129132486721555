import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    ContactsEndpoint,
    ContactsEndpointPaths,
    ContactsSendRequest,
    ContactsSubjectsResponse,
} from '@scaliolabs/baza-content-types-shared';

@Injectable()
export class ContactsDataAccess implements ContactsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    subjects(): Observable<ContactsSubjectsResponse> {
        return this.http.get(ContactsEndpointPaths.subjects);
    }

    send(request: ContactsSendRequest): Observable<void> {
        return this.http.post(ContactsEndpointPaths.send, request);
    }
}
