export class BazaContentTypeTeamsConfig {
    maxFullImageWidth: number;
    maxPreviewImageWidth: number;
}

export function bazaContentTypeTeamsDefaultConfig(): BazaContentTypeTeamsConfig {
    return {
        maxFullImageWidth: 720,
        maxPreviewImageWidth: 320,
    };
}
