import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowErrorCodes } from '../error-codes/baza-nc-purchase-flow.error-codes';
import { Type } from 'class-transformer';

export class RegulationLimitsDetailsDto {
    @ApiModelProperty({
        description: 'Error code',
        type: 'string',
        required: false,
    })
    errorCode?: BazaNcPurchaseFlowErrorCodes;

    @ApiModelProperty({
        required: false,
        description: 'Error message or any message sent to FE',
    })
    message?: string;
}
export class RegulationLimitsDto {
    @ApiModelProperty({
        description: 'The Limit number for the user to purchase shares for the this specific offering In cents',
    })
    limitCents: number;

    @ApiModelProperty({
        description: 'The Limit number for the user to purchase shares for the this specific offering',
        type: () => RegulationLimitsDetailsDto,
    })
    @Type(() => RegulationLimitsDetailsDto)
    details: RegulationLimitsDetailsDto;
}
