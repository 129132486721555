import { BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class TestimonialDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'URL to testimonial (shortcut to seo slug)',
        required: false,
    })
    url?: string;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty({
        required: false,
    })
    title?: string;

    @ApiModelProperty({
        required: false,
    })
    company?: string;

    @ApiModelProperty({
        required: false,
    })
    link?: string;

    @ApiModelProperty({
        required: false,
    })
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    email?: string;

    @ApiModelProperty({
        required: false,
    })
    imageUrl?: string;

    @ApiModelProperty()
    quote: string;

    @ApiModelProperty()
    preview: string;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
