import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive } from 'class-validator';
import { BazaCommentStatus } from '../../../../../../../baza-core-shared/src/libs/baza-comments/src/lib/enum/baza-comments-status.enum';
import { CrudListRequestDto } from '../../../../baza-crud/src/lib/dto/crud-list-request.dto';
import { CrudListResponseDto } from '../../../../baza-crud/src/lib/dto/crud-list-response.dto';
import { Type } from 'class-transformer';

export class BazaCmsCommentsDto {
    @ApiModelProperty({
        description: 'Comment Id',
    })
    @IsOptional()
    id?: number;

    @ApiModelProperty({
        description: 'Target Entity to Relation',
    })
    relationTo?: string;

    @ApiModelProperty({
        description: 'Relation Id',
    })
    relationId: number;

    @ApiModelProperty({
        description: 'Comment Status',
    })
    @IsNotEmpty()
    status: string | BazaCommentStatus;

    @ApiModelProperty({
        description: 'Comment',
    })
    @IsOptional()
    comment: string;

    @ApiModelProperty({
        description: 'Issuer Type',
    })
    @IsOptional()
    issuerType?: string;

    @ApiModelProperty({
        description: 'Moderator',
    })
    moderatedBy?: number;

    @ApiModelProperty({
        description: 'Relation Name',
    })
    @IsOptional()
    relationName: string;

    @ApiModelProperty({
        description: 'Rejected Text',
    })
    @IsOptional()
    rejectedText?: string;

    @ApiModelProperty({
        description: 'Comment Parent Id',
    })
    @IsOptional()
    parentId?: number;

    @ApiModelProperty({
        description: 'Comment Account Id',
    })
    @IsOptional()
    accountId?: number;

    @ApiModelProperty({
        description: 'User name',
    })
    @IsOptional()
    userName?: string;

    @ApiModelProperty({
        description: 'Moderator name',
    })
    @IsOptional()
    moderatorName?: string;

    @ApiModelProperty({
        description: 'User title',
        nullable: true,
    })
    userTitle: string;

    @ApiModelProperty({
        description: 'Comment create date',
    })
    @IsOptional()
    createdAt?: Date;

    @ApiModelProperty({
        description: 'Comment update date',
    })
    @IsOptional()
    updatedAt?: Date;
}

export class BazaCommentsStatusNotificationRequest extends BazaCmsCommentsDto {
    @ApiModelProperty({
        description: 'Account',
    })
    account: {
        firstName: string;
        email: string;
        fullName: string;
    };
}

export class BazaCommentsCmsCreateRequest {
    @ApiModelProperty({
        description: 'Comment Id',
    })
    @IsOptional()
    id?: number;

    @ApiModelProperty({
        description: 'Relation Id',
    })
    @IsNumber()
    @IsInt()
    @IsNotEmpty()
    relationId: number;

    @ApiModelProperty({
        description: 'Target Entity to Relation',
    })
    @IsOptional()
    relationTo?: string;

    @ApiModelProperty({
        description: 'Comment',
    })
    @IsNotEmpty()
    comment: string;

    @ApiModelProperty({
        description: 'Relation Name',
    })
    @IsNotEmpty()
    relationName: string;

    @ApiModelProperty({
        description: 'Comment Account Id',
    })
    @IsOptional()
    accountId?: number;

    @ApiModelProperty({
        description: 'User title',
    })
    @IsNotEmpty()
    userTitle: string;

    @ApiModelProperty({
        description: 'Parent id (entity who belongs this reply)',
    })
    @IsOptional()
    parentId?: number;

    @ApiModelProperty({
        description: 'Reject Text)',
    })
    @IsOptional()
    rejectedText?: string;
    @ApiModelProperty({
        description: 'Moderator Id',
    })
    @IsPositive()
    @IsInt()
    moderatedBy: number;

    @ApiModelProperty({
        description: 'Comment status',
    })
    @IsNotEmpty()
    status: string | BazaCommentStatus;
}

export class BazaCommentCmsCreateResponse extends BazaCmsCommentsDto {
    @ApiModelProperty({
        description: 'Issuer Type',
    })
    @IsOptional()
    issuerType: string;
}

export class BazaCommentCmsListRequest extends CrudListRequestDto<BazaCmsCommentsDto> {
    @ApiModelProperty({
        description: 'Entity Id',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    entityId?: number;
}

export class BazaCommentListRequest extends CrudListRequestDto<BazaCmsCommentsDto> {
    @ApiModelProperty({
        description: 'Entity Id',
        required: true,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    entityId: number;

    @ApiModelProperty({
        description: 'Page index',
        required: false,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    index: number;

    @ApiModelProperty({
        description: 'Page size',
        required: false,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    size: number;
}

export class BazaCommentCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaCommentCmsListResponse extends CrudListResponseDto<BazaCmsCommentsDto> {}

export class BazaCommentsListDto {
    @ApiModelProperty({
        description: 'Comment Id',
    })
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        description: 'Target Entity to Relation',
    })
    @IsNotEmpty()
    relationTo: string;

    @ApiModelProperty({
        description: 'Relation Id',
    })
    @IsNotEmpty()
    relationId: number;

    @ApiModelProperty({
        description: 'Comment Status',
    })
    @IsNotEmpty()
    status: string | BazaCommentStatus;

    @ApiModelProperty({
        description: 'Comment',
    })
    @IsOptional()
    comment: string;

    @ApiModelProperty({
        description: 'Moderator',
    })
    moderatedBy?: number;

    @ApiModelProperty({
        description: 'Name of item',
    })
    @IsNotEmpty()
    relationName: string;

    @ApiModelProperty({
        description: 'Comment Parent Id',
    })
    @IsOptional()
    parentId?: number;

    @ApiModelProperty({
        description: 'Comment Account Id',
    })
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty({
        description: 'User name',
    })
    @IsOptional()
    userName?: string;

    @ApiModelProperty({
        description: 'Moderator name',
    })
    @IsOptional()
    moderatorName?: string;

    @ApiModelProperty({
        description: 'User title',
        nullable: true,
    })
    @IsNotEmpty()
    userTitle: string;

    @ApiModelProperty({
        description: 'Comment create date',
    })
    @IsNotEmpty()
    createdAt: Date;

    @ApiModelProperty({
        description: 'Comment update date',
    })
    @IsOptional()
    updatedAt?: Date;
}

export type ThreadResponse = {
    relationId: number;
    discussion: BazaCommentsListDto[];
};
