import { Injectable } from '@angular/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NgFormStatusEnum } from '../enums/form-status.enum';

@Injectable({
    providedIn: 'root',
})
export class BazaActionsFormBuilderService {
    private _submitActionComponent: NzButtonComponent;
    private _formStatus: NgFormStatusEnum;

    constructor() {
        this._formStatus = NgFormStatusEnum.VALID;
    }

    setSubmitActionButton(submitActionComponent: NzButtonComponent) {
        this._submitActionComponent = submitActionComponent;
    }

    getSubmitActionButton(): NzButtonComponent {
        return this._submitActionComponent;
    }

    set entryFormStatus(status: NgFormStatusEnum) {
        this._formStatus = status;
    }

    get entryFormStatus() {
        return this._formStatus;
    }
}
