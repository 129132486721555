import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { PaymentItemActionsComponent } from '../payment-item-actions/payment-item-actions.component';
import { PaymentItemDetailComponent } from '../payment-item-detail/payment-item-detail.component';
import { PaymentItemHeaderComponent } from '../payment-item-header/payment-item-header.component';
import { PaymentButtonStyleEnum } from '@scaliolabs/baza-web-utils';

@Component({
    selector: 'app-payment-item',
    templateUrl: './payment-item.component.html',
})
export class PaymentItemComponent implements AfterContentInit {
    @Input()
    isDisabled!: boolean;

    @Input()
    isPaymentLinked!: boolean;

    @Input()
    style: PaymentButtonStyleEnum;

    @Output()
    clickContainer = new EventEmitter<void>();

    @ViewChild('actionsContainer') actionsContainer!: ElementRef<HTMLDivElement>;

    @ContentChild(PaymentItemHeaderComponent)
    headerComponent!: PaymentItemHeaderComponent;

    @ContentChild(PaymentItemActionsComponent)
    actionsComponent!: PaymentItemActionsComponent;

    @ContentChildren(PaymentItemDetailComponent)
    detailComponents!: QueryList<PaymentItemDetailComponent>;

    details$!: Observable<PaymentItemDetailComponent[]>;
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    constructor(private readonly cdr: ChangeDetectorRef) {}

    ngAfterContentInit(): void {
        this.details$ = this.detailComponents?.changes?.pipe(
            startWith([]),
            map(() => this.detailComponents?.toArray()),
        );

        setTimeout(() => this.cdr.detectChanges());
    }

    /**
     * Emits click event to parent component only when the
     * event is triggered by the container itself
     * and the input "isPaymentLinked" is false.
     *
     * stopPropagation is used to prevent triggering the
     * handlers for the collapsible area and open the panel
     * when the container is clicked.
     *
     */
    onContainerClicked(event: MouseEvent): void {
        event.stopPropagation();

        if (this.isPaymentLinked) return;

        const actionWasClicked = this.actionsContainer?.nativeElement?.contains(event.target as HTMLElement);

        if (!actionWasClicked && !this.isPaymentLinked) {
            this.clickContainer.emit();
        }
    }
}
