import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { BazaNcPurchaseFlowTransactionType } from '../models/baza-nc-purchase-flow-transaction-type';
import { generateRandomHexString } from '@scaliolabs/baza-core-shared';

export class PurchaseFlowSessionDto {
    @ApiModelProperty({
        description: 'Session Lock Update Id. Use it to validate that your request is actually sent from same device',
        required: false,
        example: generateRandomHexString(8),
    })
    @IsString()
    @IsOptional()
    updateId?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        description: 'Transaction type. Transaction type can be changed in future for the purchase.',
        default: BazaNcPurchaseFlowTransactionType.ACH,
        required: false,
    })
    @IsEnum(BazaNcPurchaseFlowTransactionType)
    @IsOptional()
    transactionType?: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description: 'If true DocuSign URL will be generated',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    requestDocuSignUrl?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    offeringId: string;

    @ApiModelProperty()
    @IsPositive()
    @IsNumber()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    numberOfShares: number;
}
