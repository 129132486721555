import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountDto } from '../../../../baza-account/src';
import { BazaReferralCodeDto } from './baza-referral-code.dto';
import { Application, clientApplications } from '../../../../baza-common/src';

export class BazaReferralCodeUsageDto {
    @ApiModelProperty({
        description: 'Joined (Used) at'
    })
    dateJoinedAt: string;

    @ApiModelProperty({
        description: 'Referral Code',
    })
    referralCode: BazaReferralCodeDto;

    @ApiModelProperty({
        description: 'Joined account DTO'
    })
    joinedAccount: AccountDto;

    @ApiModelProperty({
        type: 'string',
        enum: [...clientApplications],
    })
    joinedWithClient: Application;
}
