import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BazaI18nDataAccess } from '@scaliolabs/baza-core-data-access';
import { retryWhen, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { BazaI18nNgConfig } from '../baza-i18n-ng.config';
import { TranslateService } from '@ngx-translate/core';
import { BAZA_COMMON_I18N_CONFIG, ProjectLanguage } from '@scaliolabs/baza-core-shared';
import { BazaLocalStorageService } from '../../../baza-common/lib/services/baza-local-storage.service';
import { genericRetryStrategy } from '../../../baza-common/lib/util/generic-retry-strategy.util';
import { BazaNgCurrentApplicationService } from '../../../baza-common/lib/services/baza-ng-current-application.service';

export type BazaI18nNgLanguage = ProjectLanguage | undefined;

@Injectable()
export class BazaI18nNgService {
    private _nextLanguage$: Subject<void> = new Subject<void>();

    private _cached: {
        [app: string]: {
            [language: string]: Observable<unknown>;
        };
    } = {};
    private _currentLanguage$: BehaviorSubject<BazaI18nNgLanguage> = new BehaviorSubject(BAZA_COMMON_I18N_CONFIG.defaultLanguage);

    constructor(
        private readonly injector: Injector,
        private readonly moduleConfig: BazaI18nNgConfig,
        private readonly i18nEndpoint: BazaI18nDataAccess,
        private readonly currentApp: BazaNgCurrentApplicationService,
        private readonly localStorage: BazaLocalStorageService,
    ) {}

    get currentLanguage(): BazaI18nNgLanguage {
        return this._currentLanguage$.getValue();
    }

    get currentLanguage$(): Observable<BazaI18nNgLanguage> {
        return this._currentLanguage$.asObservable();
    }

    useLanguage(language: ProjectLanguage): void {
        const ngxTranslate = this.injector.get(TranslateService);

        this.setTranslations(language).subscribe(() => ngxTranslate.use(language));
    }

    setTranslations(language: ProjectLanguage): Observable<any> {
        this._nextLanguage$.next();

        return this.fetchTranslations(language).pipe(
            tap(() => this.localStorage.set(this.moduleConfig.localStorageKey, language)),
            tap(() => this._currentLanguage$.next(language)),
            takeUntil(this._nextLanguage$),
        );
    }

    fetchTranslations(language: ProjectLanguage): Observable<any> {
        const app = this.currentApp.application;

        if (!(this._cached[`${app}`] && this._cached[`${app}`][`${language}`])) {
            if (!this._cached[`${app}`]) {
                this._cached[`${app}`] = {};
            }

            this._cached[`${app}`][`${language}`] = this.i18nEndpoint
                .get({
                    app,
                    language,
                })
                .pipe(retryWhen(genericRetryStrategy()), shareReplay(1));
        }

        return this._cached[`${app}`][`${language}`];
    }
}
