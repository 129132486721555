import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContactsSubjectDto } from '../public/contacts-subject.dto';

export class ContactsSubjectCmsDto extends ContactsSubjectDto {
    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'If disable subject will not be appeared for actual users'
    })
    isEnabled: boolean;
}
