import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

export class BazaAwsFileDto {
    @ApiModelProperty({
        description: 'AWS key'
    })
    @IsString()
    @IsNotEmpty()
    s3ObjectId: string;

    @ApiModelProperty({
        description: 'Time limited presigned url which can be used to instantly display upload result. Note: BE should never save the url and should always generate presigned urls every time!'
    })
    @IsString()
    @IsNotEmpty()
    presignedTimeLimitedUrl: string;
}
