import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import {
    BazaNcIntegrationListingsDataAccessModule,
    BazaNcIntegrationSubscriptionDataAccessModule,
    BazaNcIntegrationFavoriteDataAccessModule,
    BazaNcIntegrationSearchDataAccessModule,
} from '@scaliolabs/baza-nc-integration-data-access';
import { ItemState } from './state';

@NgModule({
    imports: [
        NgxsModule.forFeature([ItemState]),
        BazaNcIntegrationListingsDataAccessModule,
        BazaNcIntegrationSubscriptionDataAccessModule,
        BazaNcIntegrationFavoriteDataAccessModule,
        BazaNcIntegrationSearchDataAccessModule,
    ],
})
export class ItemStateModule {}
