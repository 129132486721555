import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';

export class DeleteCreditCardRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    updatedIpAddress: string;
}
