import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountVerificationStep } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, Message, catchErrorMessage } from '@scaliolabs/baza-web-utils';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UploadDocument, UploadPersonalInfoSSNDocument } from '../data-access';

@Component({
    selector: 'app-verification-file-upload',
    templateUrl: './file-upload.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationFileUploadComponent {
    @Input()
    verificationStatus: AccountVerificationStep;

    @Output()
    closeModal = new EventEmitter<void>();

    public message$: Observable<Message | undefined>;
    public fileList: NzUploadFile[];
    i18nBasePath = 'dwvf.fileUpload';

    constructor(private readonly store: Store, public readonly wts: BazaWebUtilSharedService) {}

    // onFileUpload
    public onFileUpload = (file: NzUploadFile): boolean => {
        this.fileList = [file];
        return false;
    };

    // onFormSubmit
    public onFormSubmit(): void {
        const request =
            this.verificationStatus === AccountVerificationStep.RequestDocuments
                ? this.store.dispatch(new UploadDocument(this.fileList[0]))
                : this.store.dispatch(new UploadPersonalInfoSSNDocument(this.fileList[0]));

        this.message$ = request.pipe(
            tap(() => {
                this.wts.refreshInitData$.next();
                this.closeModal.emit();
            }),
            catchErrorMessage,
        );
    }
}
