export class ContactUsStartApp {
    static readonly type = '[Contact Us] App start';
}

export interface ContactUsFxDTO {
    name: string;
    email: string;
    subjectId: number;
    message: string;
}

export class ContactUsFx {
    static readonly type = '[Contact Us] Submit';

    constructor(public data: ContactUsFxDTO) {}
}
