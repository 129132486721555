import { BazaNcPayoffDistributionDto } from './baza-nc-payoff-distribution.dto';
import { BazaNcInvestorAccountDto } from '../../../../investor-account';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcPayoffDistributionCmsDto extends BazaNcPayoffDistributionDto {
    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaNcInvestorAccountDto;
}
