export const PaymentBalanceEnI18n = {
    title: 'Account Balance',
    details: {
        prefix: 'Current balance:',
        hint: 'Fund your account',
    },
    alerts: {
        walletSupport: {
            label: `There was an issue creating your wallet. We're sorry for the inconvenience. Please <a data-link="walletLink"></a> for technical support.`,
            linkConfig: {
                key: 'walletLink',
                link: {
                    extLink: {
                        link: 'bazaContentTypes.contacts.email',
                        isCMSLink: true,
                        isMailLink: true,
                        text: 'contact us',
                    },
                },
            },
        },
    },
    actions: {
        addFunds: 'Add funds',
    },
    linkedBank: {
        title: 'Bank Account',
        hint: 'Used to fund your account balance',
        actions: {
            update: 'Update',
        },
    },
};
