import { BazaNcIntegrationListingsBaseDto } from './baza-nc-integration-listings-base.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto, BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingCmsDocumentsDto } from './baza-nc-integration-listings-document-cms.dto';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class BazaNcIntegrationListingsCmsDto extends BazaNcIntegrationListingsBaseDto implements CrudSortableEntity {
    @ApiModelProperty({
        description: 'Is listing available for public API?',
    })
    isPublished: boolean;

    @ApiModelProperty({
        description: 'Category ID is internal field used to link Content Types with Listings',
    })
    categoryId: number;

    @ApiModelProperty({
        description: 'Cover as Attachment DTO',
    })
    cover: AttachmentDto;

    @ApiModelProperty({
        description: 'Images as List of Attachment DTO',
    })
    images: Array<AttachmentDto>;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'Statement Documents',
        type: BazaNcIntegrationListingCmsDocumentsDto,
        isArray: true,
    })
    statements: Array<BazaNcIntegrationListingCmsDocumentsDto>;

    @ApiModelProperty({
        description: 'Documents',
        type: BazaNcIntegrationListingCmsDocumentsDto,
        isArray: true,
    })
    documents: Array<BazaNcIntegrationListingCmsDocumentsDto>;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
