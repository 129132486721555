<ng-container
    *ngIf="{
        item: cart$ | async,
        numberAndStats: dataStream$ | async
    } as data">
    <ng-container *ngIf="data.item && data.numberAndStats; else skeleton">
        <div class="purchase-details">
            <div class="steps-content">
                <div
                    nz-row
                    nzGutter="30"
                    class="purchase__row">
                    <div
                        nz-col
                        nzXs="12"
                        class="purchase__info">
                        <div class="purchase__heading">
                            {{ wts.getI18nLabel(i18nBasePath, 'title') }}
                        </div>

                        <div class="purchase__descr">
                            {{ wts.getI18nLabel(i18nBasePath, 'description') }}
                        </div>

                        <div class="purchase__subheading">
                            {{ wts.getI18nLabel(i18nBasePath, 'subtitle') }}
                        </div>

                        <div class="payment-info">
                            <div class="payment-info__img">
                                <img
                                    class="img-responsive"
                                    src="{{ data.item?.cover?.md }}"
                                    [alt]="data.item?.title" />
                            </div>

                            <div class="payment-info__descr">
                                <span class="payment-info__title">{{ data.item?.title }}</span>

                                <ng-container *ngIf="data.numberAndStats?.stats; else skeleton">
                                    <p
                                        class="payment-info__subtext"
                                        [class.payment-info__subtext-warning]="showSharePickerError">
                                        <ng-container *ngIf="showSharePickerError; else minShares">
                                            {{ data.numberAndStats?.stats?.message }}
                                        </ng-container>
                                    </p>

                                    <ng-template #minShares>
                                        {{
                                            wts.getI18nLabel(i18nBasePath, 'shares.min', {
                                                min: data.item?.numSharesMin | i18nPlural : shareMapping
                                            })
                                        }}
                                        <br class="hide-tablet hide-desktop" />
                                        {{
                                            wts.getI18nLabel(i18nBasePath, 'shares.max', {
                                                max: data.numberAndStats?.stats?.canPurchase | i18nPlural : shareMapping
                                            })
                                        }}
                                    </ng-template>

                                    <ng-container
                                        *ngIf="
                                            !(
                                                (item?.schema?.regulationType === OFFERING_REGULATION_TYPES.RegD &&
                                                    !this.wts.isAccreditedInvestor(initData)) ||
                                                (appAndItemSupportsRegCF(item) && this.wts.isForeignInvestor(initData))
                                            )
                                        ">
                                        <div class="share-picker">
                                            <button
                                                class="share-picker__btn share-picker__btn-left"
                                                nz-button
                                                [disabled]="numberOfShares === data.item?.numSharesMin || numberOfShares === 0"
                                                (click)="decreaseShares()">
                                                <i class="icon icon-minus"></i>
                                            </button>

                                            <nz-input-number
                                                class="share-picker__input"
                                                [nzPrecision]="0"
                                                [(ngModel)]="numberOfShares"
                                                [disabled]="numberOfShares === 0"
                                                [class.share-picker__input-error]="
                                                    this.numberOfShares &&
                                                    (this.numberOfShares < data.item?.numSharesMin ||
                                                        this.numberOfShares > data.numberAndStats?.stats?.canPurchase)
                                                "
                                                (nzBlur)="
                                                    checkNumberOfShares(data.item?.numSharesMin, data.numberAndStats?.stats?.canPurchase)
                                                "
                                                (keyup.enter)="
                                                    checkNumberOfShares(data.item?.numSharesMin, data.numberAndStats?.stats?.canPurchase)
                                                "></nz-input-number>

                                            <button
                                                class="share-picker__btn share-picker__btn-right"
                                                nz-button
                                                [disabled]="
                                                    numberOfShares === data.numberAndStats?.stats?.canPurchase ||
                                                    data.item?.numSharesMin > data.numberAndStats?.stats?.canPurchase
                                                "
                                                (click)="increaseShares(data.numberAndStats?.stats)">
                                                <i class="icon icon-plus"></i>
                                            </button>
                                            <span class="share-picker__multiplier">
                                                x {{ data.item?.pricePerShareCents / 100 | priceCents }}
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div
                            class="purchase-details__kyc"
                            *ngIf="verification?.kycStatus === KycStatus.Disapproved">
                            <app-kyc-disapproved-alert (redirectCTAClicked)="onEditPersonalInfo()"></app-kyc-disapproved-alert>
                        </div>

                        <div class="purchase-details__personal">
                            <app-personal-info
                                [info]="verification?.personalInformation"
                                (editPersonalInfo)="onEditPersonalInfo()"></app-personal-info>
                        </div>
                    </div>

                    <div
                        nz-col
                        nzXs="12"
                        class="purchase__summary">
                        <ng-container *ngIf="data?.numberAndStats?.stats; else skeleton">
                            <app-summary
                                *ngIf="
                                    !(
                                        (item?.schema?.regulationType === OFFERING_REGULATION_TYPES.RegD &&
                                            !wts.isAccreditedInvestor(initData)) ||
                                        (appAndItemSupportsRegCF(item) && wts.isForeignInvestor(initData))
                                    )
                                "
                                [entity]="cart$ | async"
                                [purchaseStart]="purchaseStart$ | async"
                                [numberOfShares]="numberOfShares ?? 1"
                                [stats]="stats$ | async"
                                [withImage]="false"></app-summary>

                            <app-regcf-overview
                                *ngIf="showRegCFOverview"
                                (updateLimit)="onUpdateLimitClick(data.numberAndStats?.stats?.regulationLimits)"></app-regcf-overview>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div
                nz-row
                nzGutter="30">
                <div
                    nz-col
                    nzXs="12"
                    class="purchase__info">
                    <div class="steps-action">
                        <button
                            class="btn-angle-right"
                            nz-button
                            nzSize="large"
                            nzType="primary"
                            type="submit"
                            [disabled]="showSharePickerError"
                            (click)="onNextButtonClick()">
                            {{ wts.getI18nLabel(i18nBasePath, 'actions.next') }}
                        </button>
                    </div>
                </div>
            </div>

            <app-regcf-consent
                *ngIf="showRegCfConsentModal"
                (consentCancelled)="showRegCfConsentModal = false"
                (consentAccepted)="onConsentAccepted()"></app-regcf-consent>

            <app-regcf-limit
                *ngIf="showRegCfLimitModal"
                [offeringId]="data.item?.offeringId"
                [regCfLimits]="data.numberAndStats?.stats?.regulationLimits"
                [showLowRegCfLimitWarning]="data.numberAndStats?.stats?.canPurchase === 0"
                (limitCancelled)="showRegCfLimitModal = false"
                (limitAccepted)="onLimitAccepted()"></app-regcf-limit>
        </div>
    </ng-container>
</ng-container>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
