<div class="invest">
    <div class="container invest__container">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="invest__col">
                <div
                    class="invest__heading"
                    *ngIf="header"
                    [innerHtml]="header | sanitizeHtml"></div>

                <div
                    class="invest__subheading"
                    *ngIf="description"
                    [innerHtml]="description | sanitizeHtml"></div>
            </div>
        </div>
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="invest__col invest__col-cards"
                *ngFor="let card of investSections; let i = index">
                <app-invest-card
                    [icon]="getIcon(i)"
                    [header]="card.header"
                    [description]="card.description"></app-invest-card>
            </div>
        </div>
    </div>
</div>
