import { IsDateString, IsInt, IsNumberString, IsOptional, IsPositive, IsString } from 'class-validator';
import { DwollaTransferStatus } from '../models/transfer-status';

export class GetTransferListForCustomerRequest {
    @IsString()
    @IsOptional()
    search?: string;

    @IsNumberString()
    @IsOptional()
    startAmount?: string;

    @IsNumberString()
    @IsOptional()
    endAmount?: string;

    @IsDateString()
    @IsOptional()
    startDate?: string;

    @IsDateString()
    @IsOptional()
    endDate?: string;

    @IsString()
    @IsOptional()
    status?: DwollaTransferStatus;

    @IsInt()
    @IsPositive()
    @IsOptional()
    limit?: number;

    @IsInt()
    @IsPositive()
    @IsOptional()
    offset?: number;
}
