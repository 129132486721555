import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsOptional } from 'class-validator';
export class MktPageDto {
    @ApiModelProperty()
    id?: number;

    @ApiModelProperty()
    section: string;

    @ApiModelProperty()
    label: string;

    @ApiModelProperty()
    header: string;

    @ApiModelProperty()
    description?: string;
}
