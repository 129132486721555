import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { JobApplicationEndpoint, JobApplicationEndpointPaths, JobApplicationSendRequest } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class JobApplicationsDataAccess implements JobApplicationEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  send(request: JobApplicationSendRequest): Observable<void> {
    return this.http.post(JobApplicationEndpointPaths.send, request);
  }
}
