import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { TestimonialCmsDto } from '../dto/testimonial-cms.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export enum TestimonialsCmsEndpointPaths {
    create = '/baza-content-types/cms/testimonials/create',
    update = '/baza-content-types/cms/testimonials/update',
    delete = '/baza-content-types/cms/testimonials/delete',
    list = '/baza-content-types/cms/testimonials/list',
    getById = '/baza-content-types/cms/testimonials/getById',
    setSortOrder = '/baza-content-types/cms/testimonials/setSortOrder',
}

export interface TestimonialsCmsEndpoint {
    create(request: TestimonialsCmsCreateRequest): Promise<TestimonialCmsDto> | Observable<TestimonialCmsDto>;
    update(request: TestimonialsCmsUpdateRequest): Promise<TestimonialCmsDto> | Observable<TestimonialCmsDto>;
    delete(request: TestimonialsCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: TestimonialsCmsListRequest): Promise<TestimonialsCmsListResponse> | Observable<TestimonialsCmsListResponse>;
    getById(request: TestimonialsCmsGetByIdRequest): Promise<TestimonialCmsDto> | Observable<TestimonialCmsDto>;
    setSortOrder(
        request: TestimonialsCmsSetSortOrderRequest,
    ): Promise<TestimonialsCmsSetSortOrderResponse> | Observable<TestimonialsCmsSetSortOrderResponse>;
}

export class TestimonialsCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    company?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    link?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsEmail()
    @IsOptional()
    email?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    imageS3AwsKey?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    quote: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    preview: string;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class TestimonialsCmsCreateRequest extends TestimonialsCmsEntityBody {}

export class TestimonialsCmsUpdateRequest extends TestimonialsCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TestimonialsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TestimonialsCmsListRequest extends CrudListRequestDto<TestimonialCmsDto> {}

export class TestimonialsCmsListResponse extends CrudListResponseDto<TestimonialCmsDto> {
    @ApiModelProperty({
        type: TestimonialCmsDto,
        isArray: true,
    })
    items: Array<TestimonialCmsDto>;
}

export class TestimonialsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class TestimonialsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class TestimonialsCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<TestimonialCmsDto> {
    @ApiModelProperty()
    entity: TestimonialCmsDto;

    @ApiModelProperty({
        type: TestimonialCmsDto,
        isArray: true,
    })
    affected: Array<TestimonialCmsDto>;
}
