import { CommonModule, ViewportScroller } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
    BazaNcBankAccountExport,
    BazaNcBankAccountGetDefaultByTypeResponse,
    BazaNcBankAccountLinkOnSuccessRequest,
    BazaNcBankAccountType,
    BazaNcBootstrapDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaLinkUtilSharedService, BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { AccountBalanceExitReason, PFSharedService } from '../../../../data-access';
import { PaymentItemModule } from '@scaliolabs/baza-web-ui-components';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';

@UntilDestroy()
@Component({
    selector: 'app-bank-account-payout',
    templateUrl: './bank-account-payout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, RouterModule, UtilModule, PaymentItemModule, NzAlertModule, NzButtonModule],
})
export class DwollaBankAccountPayoutComponent implements AfterViewInit {
    @Input()
    initData?: BazaNcBootstrapDto;

    @Input()
    dwollaDefaultCashOutAccount?: BazaNcBankAccountGetDefaultByTypeResponse;

    @Input()
    isAnyAssetPurchased? = false;

    @Output()
    refreshCashOutData = new EventEmitter<void>();

    i18nBasePath = 'dwacc.payout.bank';

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly viewPortScroller: ViewportScroller,
        private readonly route: ActivatedRoute,
        public readonly dss: PFSharedService,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngAfterViewInit(): void {
        if (this.route.snapshot?.fragment === 'payout-method') {
            setTimeout(() => {
                this.viewPortScroller.scrollToAnchor('payout-method');
            }, 3000);
        }
    }

    get walletSupportLinkConfig(): string {
        return `${this.i18nBasePath}.alerts.walletSupport.linkConfig`;
    }

    get withdrawalSupportLinkConfig(): string {
        return `${this.i18nBasePath}.alerts.withdrawalSupport.linkConfig`;
    }

    public onPayoutContainerClicked() {
        this.dss.processAccountBalanceCreation({
            isDwollaAvailable: this.wts.isDwollaAvailable(this.initData),
            onExit: (reason) => {
                switch (reason) {
                    case AccountBalanceExitReason.DwollaBalanceAlreadyAvailable:
                        this.triggerDwollaPlaidFlow();
                        break;
                }
            },
            onInvestorTouched: (response) => {
                const isDwollaAvailable = response?.isDwollaAvailable ?? false;

                this.dss.showDwollaAccountCreationError$.next(!isDwollaAvailable);
                this.cdr.detectChanges();

                if (isDwollaAvailable) {
                    this.triggerDwollaPlaidFlow();
                }
            },
        });
    }

    triggerDwollaPlaidFlow() {
        this.dss.processPlaidPluginFlow({
            onSuccess: (publicToken, identity) => {
                const linkAccountReq: BazaNcBankAccountLinkOnSuccessRequest = {
                    publicToken: publicToken,
                    type: BazaNcBankAccountType.CashOut,
                    export: [BazaNcBankAccountExport.Dwolla],
                    setAsDefault: true,
                    static: true,
                    secure: true,
                    identity: identity,
                };

                this.dss.processDwollaLinkBankAccount(linkAccountReq, () => {
                    this.refreshCashOutData.emit();
                });
            },
            onExit: () => {
                this.refreshCashOutData.emit();
            },
        });
    }
}
