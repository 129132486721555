import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaPlaidAccountDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Unqiue account id',
    })
    id: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Name of account',
    })
    name: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Type of account',
    })
    subtype: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Last 4 digits/masking',
    })
    mask: string;
}

export class BazaPlaidInstitutionDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Institute Plaid id',
    })
    institution_id: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Bank name',
    })
    name: string;
}

export class BazaPlaidOnSuccessDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Public Token from success callback of Plaid SDK',
    })
    public_token: string;

    @ApiModelProperty({
        type: 'string',
        description: 'User selected Account Id.',
    })
    account_id: string;

    @ApiModelProperty({
        description: 'Account object',
        required: false,
    })
    account: BazaPlaidAccountDto;

    @ApiModelProperty({
        description: 'List of accounts associated',
        required: false,
    })
    accounts: Array<BazaPlaidAccountDto>;

    @ApiModelProperty({
        description: 'Bank details',
        required: false,
    })
    institution: BazaPlaidInstitutionDto;
}
