import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudSortableUlidEntity } from '@scaliolabs/baza-core-shared';

export class BazaNcIntegrationListingTestimonialBaseDto implements CrudSortableUlidEntity {
    @ApiModelProperty({
        description: 'ULID (ID)',
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Sort Order (by default list is sorted)',
    })
    sortOrder: number;

    @ApiModelProperty({
        description: 'Name',
    })
    name: string;

    @ApiModelProperty({
        description: 'Role',
    })
    role: string;

    @ApiModelProperty({
        description: 'Contents (Testimonial)',
    })
    contents: string;
}
