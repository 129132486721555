import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FaqDataAccess } from '@scaliolabs/baza-content-types-data-access';
import { FaqListResponse } from '@scaliolabs/baza-content-types-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import { Faq } from '../../models';
import { GetFaq } from './actions';

export interface FaqStateModel {
    items: Faq[];
}

@State<FaqStateModel>({
    name: 'faq',
    defaults: {
        items: [],
    },
})
@Injectable()
export class FaqState {
    @Selector()
    static items(state: FaqStateModel): Faq[] {
        return state ? state.items : [];
    }

    constructor(private readonly effectsUtil: EffectsUtil, private readonly faqService: FaqDataAccess) {}

    @Action(GetFaq, { cancelUncompleted: true })
    getFaq(ctx: StateContext<FaqStateModel>) {
        return this.faqService.list().pipe(
            tap((response: FaqListResponse) => {
                ctx.patchState({ items: response.entries });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
