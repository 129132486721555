import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordValidatorComponent } from './password-validator.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PasswordValidatorComponent],
    exports: [PasswordValidatorComponent],
})
export class PasswordValidatorModule {}
