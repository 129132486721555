import { DwollaTransferStatus } from '@scaliolabs/baza-dwolla-shared';

export enum BazaNcPayoffDistributionPaymentStatus {
    Pending = 'Pending',
    Processed = 'Processed',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export const bazaNcPayoffDistributionNonDraftPaymentStatuses: Array<BazaNcPayoffDistributionPaymentStatus> = [
    BazaNcPayoffDistributionPaymentStatus.Processed,
    BazaNcPayoffDistributionPaymentStatus.Failed,
    BazaNcPayoffDistributionPaymentStatus.Cancelled,
];

export const bazaNcPayoffDistributionProcessedPaymentStatuses: Array<BazaNcPayoffDistributionPaymentStatus> = [
    BazaNcPayoffDistributionPaymentStatus.Failed,
    BazaNcPayoffDistributionPaymentStatus.Cancelled,
    BazaNcPayoffDistributionPaymentStatus.Processed,
];
export const bazaNcPayoffDistributionReprocessablePaymentStatuses: Array<BazaNcPayoffDistributionPaymentStatus> = [
    BazaNcPayoffDistributionPaymentStatus.Failed,
    BazaNcPayoffDistributionPaymentStatus.Cancelled,
];

export const bazaNcPayoffDistributionUpdatablePaymentStatuses: Array<BazaNcPayoffDistributionPaymentStatus> = [
    BazaNcPayoffDistributionPaymentStatus.Pending,
    ...bazaNcPayoffDistributionReprocessablePaymentStatuses,
];

export const bazaNcPayoffDistributionMapDwollaTransferToPaymentStatuses: Array<{
    dwolla: DwollaTransferStatus;
    status: BazaNcPayoffDistributionPaymentStatus;
}> = [
    {
        dwolla: DwollaTransferStatus.Pending,
        status: BazaNcPayoffDistributionPaymentStatus.Pending,
    },
    {
        dwolla: DwollaTransferStatus.Processed,
        status: BazaNcPayoffDistributionPaymentStatus.Processed,
    },
    {
        dwolla: DwollaTransferStatus.Failed,
        status: BazaNcPayoffDistributionPaymentStatus.Failed,
    },
    {
        dwolla: DwollaTransferStatus.Cancelled,
        status: BazaNcPayoffDistributionPaymentStatus.Cancelled,
    },
];
