import { NcTransactionDto } from './nc-transaction.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class NcTransactionCsvDto extends NcTransactionDto {
    @ApiModelProperty({
        description: 'Amount (in U.S. $)'
    })
    amount: string;

    @ApiModelProperty({
        description: 'Fee (in U.S. $)',
    })
    fee: string;

    @ApiModelProperty({
        description: 'Total (in U.S. $)'
    })
    total: string;

    @ApiModelProperty()
    pricePerShare: string;
}
