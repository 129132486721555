import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { JobApplicationDto } from '../dto/job-application.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';

export enum JobApplicationCmsEndpointPaths {
    list = '/baza-content-types/cms/job-applications/list',
    getById = '/baza-content-types/cms/job-applications/getById',
    delete = '/baza-content-types/cms/job-applications/delete',
}

export interface JobApplicationCmsEndpoint {
    list(request: JobApplicationCmsListRequest): Promise<JobApplicationCmsListResponse> | Observable<JobApplicationCmsListResponse>;
    getById(request: JobApplicationCmsGetByIdRequest): Promise<JobApplicationDto> | Observable<JobApplicationDto>;
    delete(request: JobApplicationCmsDeleteRequest): Promise<void> | Observable<void>;
}

export class JobApplicationCmsListRequest extends CrudListRequestDto<JobApplicationDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    jobId: number;
}

export class JobApplicationCmsListResponse extends CrudListResponseDto<JobApplicationDto> {
    @ApiModelProperty({
        type: JobApplicationDto,
        isArray: true,
    })
    items: Array<JobApplicationDto>;
}

export class JobApplicationCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class JobApplicationCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
