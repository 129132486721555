export const WebUiAddCardFormEnI18n = {
    form: {
        fields: {
            ccName: {
                label: 'Name on card',
                validators: {
                    restrictedChars: 'Special characters are not allowed, only spaces and hyphen (-) are permitted',
                },
            },
            ccNumber: {
                label: 'Card number',
                validators: {
                    onlynumbers: 'Only numbers are accepted',
                    minlength: 'Card Number has to be a minimum of {{ min }} digits',
                    maxlength: 'Card Number has to be maximum of {{ max }} digits',
                },
            },
            expireDate: {
                label: 'Expiry date',
            },
            cvv: {
                label: 'CVV',
                validators: {
                    onlynumbers: 'Only numbers are accepted',
                    minlength: 'CVV has to be a minimum of {{ min }} digits',
                    maxlength: 'CVV has to be maximum of {{ max }} digits',
                },
            },
        },
        genericValidators: {
            required: '{{ fieldLabel }} is required',
        },
    },
};
