import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-payment-header',
    templateUrl: './payment-header.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class PaymentHeaderComponent {
    @Input()
    isBackBtnVisible = false;

    @Input()
    isClosable = true;

    @Output()
    backClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    closeClicked: EventEmitter<void> = new EventEmitter();
}
