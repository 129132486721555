import { AttachmentImageType, AttachmentSizeSetItem, AttachmentThumbnailRequest, AttachmentThumbnailResponse } from './attachment-image';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentImageSetDto } from '../dto/attachment-image-set.dto';

export const ATTACHMENT_IMAGE_MIME_TYPES = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/webp',
];

export interface AttachmentFile {
    fieldname: string;
    originalname: string;
    encoding: string;
    mimetype: string;
    size: number;
    destination: string;
    filename: string;
    path: string;
    buffer: Buffer;
}

export enum AttachmentType {
    None = 'None',
    Image = 'Image',
    ImageWithThumbnail = 'ImageWithThumbnail',
    ImageSet = 'ImageSet',
}

export type AttachmentRequest =
    { type: AttachmentType.None; payload: AttachmentNoneUpload } |
    { type: AttachmentType.Image; payload: AttachmentImageUpload } |
    { type: AttachmentType.ImageWithThumbnail; payload: AttachmentImageWithThumbnailUpload } |
    { type: AttachmentType.ImageSet; payload: AttachmentImageSetUpload }
;

export class AttachmentNoneUpload {
    @ApiModelProperty({
        required: false,
        type: 'string',
        isArray: true,
    })
    acceptMimeTypes?: Array<string>;

    @ApiModelProperty({
        required: false,
    })
    minFileSize?: number;

    @ApiModelProperty({
        required: false,
    })
    maxFileSize?: number;
}

export class AttachmentImageUpload {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AttachmentImageType),
        default: AttachmentImageType.JPEG,
        required: false,
    })
    type?: AttachmentImageType;

    @ApiModelProperty({
        required: false,
    })
    minFileSize?: number;

    @ApiModelProperty({
        required: false,
    })
    maxFileSize?: number;

    @ApiModelProperty({
        required: false,
    })
    maxWidth?: number;

    @ApiModelProperty({
        required: false,
    })
    withoutProgressive?: boolean;

    @ApiModelProperty({
        required: false,
        minimum: 0,
        maximum: 100,
    })
    quality?: number;
}

export class AttachmentImageWithThumbnailUpload {
    @ApiModelProperty()
    minFileSize?: number;

    @ApiModelProperty()
    maxFileSize?: number;

    @ApiModelProperty()
    withoutProgressive?: boolean;

    @ApiModelProperty({
        type: AttachmentThumbnailRequest,
        isArray: true,
    })
    original: Array<AttachmentThumbnailRequest>;

    @ApiModelProperty({
        type: AttachmentThumbnailRequest,
        isArray: true,
    })
    thumbnails: Array<AttachmentThumbnailRequest>;
}

export class AttachmentImageSetUpload {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AttachmentImageType),
        default: AttachmentImageType.JPEG,
        required: false,
    })
    type?: AttachmentImageType;

    @ApiModelProperty({
        required: false,
    })
    minFileSize?: number;

    @ApiModelProperty({
        required: false,
    })
    maxFileSize?: number;

    @ApiModelProperty({
        required: false,
    })
    withoutProgressive?: boolean;

    @ApiModelProperty({
        required: false,
        type: AttachmentSizeSetItem,
        isArray: true,
    })
    sizesSet?: Array<AttachmentSizeSetItem>;

    @ApiModelProperty({
        required: false,
        minimum: 0,
        maximum: 100,
    })
    quality?: number;

    @ApiModelProperty({
        required: false,
        default: 1.6 / 1,
    })
    ratio?: number;
}

export type AttachmentNonePayloads = { type: AttachmentType.None; payload?: undefined };
export type AttachmentImagePayloads = { type: AttachmentType.Image; payload: AttachmentImagePayload };
export type AttachmentImageWithThumbnailPayloads = { type: AttachmentType.ImageWithThumbnail; payload: AttachmentImageWithThumbnailPayload };
export type AttachmentImageSetPayloads = { type: AttachmentType.ImageSet; payload: AttachmentImageSetPayload | AttachmentImageSetDto };

export type AttachmentPayload =
    AttachmentNonePayloads |
    AttachmentImagePayloads |
    AttachmentImageWithThumbnailPayloads |
    AttachmentImageSetPayloads
;

export class AttachmentImagePayload {
    @ApiModelProperty()
    width: number;

    @ApiModelProperty()
    height: number;
}

export class AttachmentImageWithThumbnailPayload {
    @ApiModelProperty({
        description: 'Original Image',
    })
    original: Array<AttachmentThumbnailResponse>;

    @ApiModelProperty({
        description: 'Thumbnail images',
        type: AttachmentThumbnailResponse,
        isArray: true,
    })
    thumbnails: Array<AttachmentThumbnailResponse>;
}

export class AttachmentImageSetPayloadItem {
    @ApiModelProperty({
        required: false,
    })
    s3ObjectId?: string;

    @ApiModelProperty({
        required: false,
    })
    url?: string;
}

export class AttachmentImageSetPayload {
    xs: AttachmentImageSetPayloadItem;
    sm: AttachmentImageSetPayloadItem;
    md: AttachmentImageSetPayloadItem;
    xl: AttachmentImageSetPayloadItem;
}
