import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { BazaReferralAccountDto } from '../dto/baza-referral-account.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BazaReferralAccountCsvDto } from '../dto/baza-referral-account-csv.dto';

export enum BazaReferralAccountCmsEndpointPaths {
    list = '/baza-core/cms/referral-codes/accounts/list',
    exportListToCSV = '/baza-core/cms/referral-codes/accounts/exportListToCSV',
}

export interface BazaReferralAccountCmsEndpoint {
    list(
        request: BazaReferralAccountCmsListRequest,
    ): Promise<BazaReferralAccountCmsListResponse> | Observable<BazaReferralAccountCmsListResponse>;
    exportListToCSV(request: BazaReferralAccountCmsExportToCsvListRequest): Promise<string> | Observable<string>;
}

export class BazaReferralAccountCmsListRequest extends CrudListRequestDto<BazaReferralAccountDto> {}

export class BazaReferralAccountCmsListResponse extends CrudListResponseDto<BazaReferralAccountDto> {
    @ApiModelProperty({
        type: BazaReferralAccountDto,
        isArray: true,
    })
    items: Array<BazaReferralAccountDto>;
}

export class BazaReferralAccountCmsExportToCsvListRequest extends CrudExportToCsvRequest<
    BazaReferralAccountCsvDto,
    BazaReferralAccountCmsListRequest
> {}
