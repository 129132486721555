import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { TradeId } from '../models/trade-id';

export class UploadTradeDocumentRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsNotEmpty()
    userfile0?: File | unknown;

    @IsString()
    @IsOptional()
    createdIpAddress: string;
}
