import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lastThreeDigits',
})
export class LastThreeDigitsPipe implements PipeTransform {
    transform(value: string): string {
        const len = value.length;
        return value.length < 4 ? value : `*****${value.substring(len - 4)}`;
    }
}
