export class BazaContentTypeTestimonialsConfig {
    maxFullImageWidth: number;
    maxPreviewImageWidth: number;
}

export function bazaContentTypeTestimonialsDefaultConfig(): BazaContentTypeTestimonialsConfig {
    return {
        maxFullImageWidth: 720,
        maxPreviewImageWidth: 320,
    };
}
