import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaSeoMetaDto } from './baza-seo-meta.dto';
import { IsArray, IsOptional, IsString } from 'class-validator';
import { Type } from 'class-transformer';

export class BazaSeoDto {
    @ApiModelProperty({
        description: 'Title for HEAD TITLE element',
        required: false,
    })
    @IsString()
    @IsOptional()
    pageTitle?: string;

    @ApiModelProperty({
        description: 'Slug',
        required: false,
    })
    @IsString()
    @IsOptional()
    slug?: string;

    @ApiModelProperty({
        description: 'Additional meta tags',
    })
    @IsArray()
    @Type(() => BazaSeoMetaDto)
    meta: Array<BazaSeoMetaDto>;
}
