import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationInvestmentDto } from '../dto/baza-nc-integration-investment.dto';
import { Observable } from 'rxjs';

export enum BazaNcIntegrationInvestmentsEndpointPaths {
    investments = '/baza-nc-integrations/investments',
}

export interface BazaNcIntegrationInvestmentsEndpoint {
    investments(
        request: BazaNcIntegrationsInvestmentsListRequest,
        ...args
    ): Promise<BazaNcIntegrationsInvestmentsListResponse> | Observable<BazaNcIntegrationsInvestmentsListResponse>;
}

export class BazaNcIntegrationsInvestmentsListRequest extends CrudListRequestDto<BazaNcIntegrationInvestmentDto> {}
export class BazaNcIntegrationsInvestmentsListResponse extends CrudListResponseDto<BazaNcIntegrationInvestmentDto> {}
