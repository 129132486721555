import { StatusLive } from '../models/status-live';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaEnvironments } from '../../../../baza-common/src';

export class StatusDto {
    @ApiModelProperty({
        description: 'Live status (OK)',
        enum: Object.values(StatusLive),
    })
    live: StatusLive;

    @ApiModelProperty({
        description: 'Memory usage (MB)',
    })
    memoryUsageMB: number;

    @ApiModelProperty({
        description: 'Client IP',
    })
    clientIp: string;

    @ApiModelProperty({
        description: 'Git version (GIT_VERSION)',
    })
    gitVersion: string;

    @ApiModelProperty({
        description: 'Project version (GIT_VERSION)',
    })
    apiVersion: string;

    @ApiModelProperty({
        description: 'Project version (GIT_VERSION)',
        isArray: true,
        type: 'string',
    })
    supportedApiVersions: Array<string>;

    @ApiModelProperty({
        description: 'Current API environment',
        type: 'string',
        enum: Object.values(BazaEnvironments),
    })
    bazaEnvironment: BazaEnvironments;

    @ApiModelProperty({
        description: 'Is production environment?',
    })
    isProductionEnvironment: boolean;

    @ApiModelProperty({
        description: 'Is E2E environment?',
    })
    isE2EEnvironment: boolean;

    @ApiModelProperty({
        description: 'BAZA API Start Counter',
    })
    startsCounter: number;

    @ApiModelProperty({
        description: 'BAZA API Last Started Date',
    })
    lastStartedDate: string;

    @ApiModelProperty({
        description: 'BAZA API up-time, calculated from lastStartedDate',
    })
    upTime: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        milliseconds: number;
    };
}
