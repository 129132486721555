import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-payment-item-actions',
    templateUrl: './payment-item-actions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentItemActionsComponent {
    @ViewChild(TemplateRef)
    content: TemplateRef<HTMLElement>;
}
