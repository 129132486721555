import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { OfferingId } from '../../../transact-api';

export class BazaNcInvestorAccountSyncStatusDto {
    @ApiModelProperty({
        description: 'Total count of transactions added to CMS',
    })
    countTransactions: number;

    @ApiModelProperty({
        description: 'Offerings affected by sync',
        type: 'string',
        isArray: true,
    })
    affectedOfferingIds: Array<OfferingId>;

    @ApiModelProperty({
        description: 'Is Bank Account linked?',
    })
    isBankAccountLinked: boolean;

    @ApiModelProperty({
        description: 'Is Credit Card linked?',
    })
    isCreditCardLinked: boolean;
}
