import { BazaNcOfferingHealthCheckIssue } from '../models/baza-nc-offering-health-check-issue';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcOfferingHealthCheckIssueDto {
    @ApiModelProperty({
        description: 'Source (usually North Capital Trade ID)',
        example: 'Trade (#124567903)',
    })
    source: string;

    @ApiModelProperty({
        description: 'Issue Type',
        example: BazaNcOfferingHealthCheckIssue.MissingTrade,
        type: 'string',
        enum: Object.values(BazaNcOfferingHealthCheckIssue),
    })
    issue: BazaNcOfferingHealthCheckIssue;
}
