import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreateIssuerRequest {
    @IsString()
    @IsNotEmpty()
    issuerName: string;

    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsEmail()
    @IsNotEmpty()
    email: string;

    @IsString()
    @IsOptional()
    phoneNumber?: string;

    @IsString()
    @IsOptional()
    createdIpAddress?: NorthCapitalIpAddress;
}
