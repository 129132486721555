import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaMailTemplateAttachment {
    @ApiModelProperty({
        description: 'Example File Name of Attachment',
        example: 'escrow-01-01-2023.csv',
    })
    example: string;

    @ApiModelProperty({
        description: 'Content Type',
        example: 'text/csv',
    })
    contentType: string;
}

export class BazaMailTemplateVariable {
    @ApiModelProperty({
        description: 'Field (Variable Name)',
        example: 'accountName',
    })
    field: string;

    @ApiModelProperty({
        description: 'Variable Description',
        example: 'Baza Account Full Name (First Name + Last Name)',
    })
    description: string;

    @ApiModelProperty({
        description: 'Marked as optional',
        example: false,
    })
    optional: boolean;

    @ApiModelProperty({
        description: 'Is Array?',
        example: false,
        required: false,
    })
    isArray?: boolean;

    @ApiModelProperty({
        type: () => BazaMailTemplateVariable,
        isArray: true,
        description: '(for object only) Fields definitions for Object-typed variable',
        required: false,
    })
    variables?: Array<BazaMailTemplateVariable>;
}

export class BazaMailTemplateRootVariable extends BazaMailTemplateVariable {
    @ApiModelProperty({
        description: 'Part of Default Template Variables which are applied to every Mail Template',
    })
    isDefault: boolean;
}

export class BazaMailTemplate {
    @ApiModelProperty({
        description: 'Name. Name is used as ID of Mail Template',
        example: 'BazaAccountCmsChangeEmail',
    })
    name: string;

    @ApiModelProperty({
        description: 'Title (Human-Readable Name). Used for Documentation Purposes',
        example: 'Change Email',
    })
    title: string;

    @ApiModelProperty({
        description: 'Description (Human-Readable Description). Used for Documentation Purposes',
        example: 'Email sent to proceed change email flow',
    })
    description: string;

    @ApiModelProperty({
        description: 'Tag. Used for Documentation Purposes to group Mail Templates into Groups',
        example: 'Auth',
    })
    tag: string;

    @ApiModelProperty({
        description:
            'File Path (Relative from apps/i18n/en/mail-templates). Used for Mail Transports which are using Files for generating emails',
        example: 'baza-account/{app}/change-email/change-email.{type}.hbs',
    })
    filePath: string;

    @ApiModelProperty({
        type: BazaMailTemplateRootVariable,
        isArray: true,
        description: 'Template Variables. Used for Validation and  Documentation Purposes.',
    })
    variables: Array<BazaMailTemplateRootVariable>;

    @ApiModelProperty({
        type: () => BazaMailTemplateAttachment,
        isArray: true,
        description: 'Associated Attachments. Used for Documentation Purposes',
        required: false,
    })
    attachments?: Array<BazaMailTemplateAttachment>;
}
