import { FundStatus } from '../../dto/models/fund-status';
import { IsEnum, IsInt, IsNotEmpty, IsPositive } from 'class-validator';

export class UpdateCcFundMoveStatusRequest {
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    RefNum: number;

    @IsEnum(FundStatus)
    @IsNotEmpty()
    fundStatus: FundStatus;
}
