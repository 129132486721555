import { Injectable } from '@angular/core';
import {
    BazaNcIntegrationListingsCmsEndpointPaths,
    BazaNcIntegrationListingsDto,
    BazaNcIntegrationListingsEndpoint,
    BazaNcIntegrationListingsEndpointPaths,
    BazaNcIntegrationListingsGetByIdRequest,
    BazaNcIntegrationListingsGetByOfferingIdRequest,
    BazaNcIntegrationListingsGetBySlugRequest,
    BazaNcIntegrationListingsListRequest,
    BazaNcIntegrationListingsListResponse,
} from '@scaliolabs/baza-nc-integration-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';
import {
    BazaCommentListRequest,
    replacePathArgs,
    BazaCommentsCreateRequest,
    BazaCommentsListResponse,
    BazaCommentsResponse,
} from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNcIntegrationListingsDataAccess implements BazaNcIntegrationListingsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    list(request: BazaNcIntegrationListingsListRequest): Observable<BazaNcIntegrationListingsListResponse> {
        const qp: Record<string, unknown> = {
            ...request,
        };

        if (Array.isArray(request.schemas) && request.schemas.length) {
            qp.schemas = request.schemas.join(',');
        }

        return this.http.get(BazaNcIntegrationListingsEndpointPaths.list, qp);
    }

    getById(request: BazaNcIntegrationListingsGetByIdRequest): Observable<BazaNcIntegrationListingsDto> {
        return this.http.get(
            replacePathArgs(BazaNcIntegrationListingsEndpointPaths.getById, {
                id: request.id,
            }),
        );
    }

    getBySlug(request: BazaNcIntegrationListingsGetBySlugRequest): Observable<BazaNcIntegrationListingsDto> {
        return this.http.get(
            replacePathArgs(BazaNcIntegrationListingsEndpointPaths.getBySlug, {
                slug: request.slug,
            }),
        );
    }

    getByOfferingId(request: BazaNcIntegrationListingsGetByOfferingIdRequest): Observable<BazaNcIntegrationListingsDto> {
        return this.http.get(
            replacePathArgs(BazaNcIntegrationListingsEndpointPaths.getByOfferingId, {
                offeringId: request.offeringId,
            }),
        );
    }

    commentsList(request: BazaCommentListRequest): Observable<BazaCommentsListResponse> {
        return this.http.get(BazaNcIntegrationListingsEndpointPaths.commentList, request);
    }

    saveComment(request: BazaCommentsCreateRequest): Observable<BazaCommentsResponse> {
        return this.http.post(BazaNcIntegrationListingsEndpointPaths.saveComment, request);
    }
}
