import { PartyRequestId } from '../models/party-request-id';
import { PartyId } from '../models/party-id';
import { InvestorId } from '../models/investor-id';
import { IsNotEmpty, IsString } from 'class-validator';

export class RequestUploadPartyDocumentRequest {
    @IsString()
    @IsNotEmpty()
    requestId: PartyRequestId;

    @IsString()
    @IsNotEmpty()
    investorId: PartyId | InvestorId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: string;

    @IsNotEmpty()
    userfile0: File | unknown;
}
