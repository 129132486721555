import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcRangesDate {
    @ApiModelProperty({
        example: new Date().toISOString(),
    })
    min: string;

    @ApiModelProperty({
        example: new Date().toISOString(),
    })
    max: string;
}
