import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaDwollaInvestorAccountDto } from '../dto/baza-dwolla-investor-account.dto';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaDwollaInvestorAccountCsvDto } from '../dto/baza-dwolla-investor-account-csv.dto';
import { BazaDwollaInvestorAccountSyncStatusDto } from '../dto/baza-dwolla-investor-account-sync-status.dto';
import { BazaDwollaInvestorAccountPartyDetailsDto } from '../dto/baza-dwolla-investor-account-party-details.dto';
import { BazaDwollaInvestorAccountUpdatePersonalInformationRequest } from '../dto/baza-dwolla-investor-account-update-party-details.request';

export enum BazaDwollaInvestorAccountCmsEndpointPaths {
    getInvestorAccount = '/baza-dwolla/investor-account/cms/getInvestorAccount',
    getInvestorAccountByUserId = '/baza-dwolla/investor-account/cms/getInvestorAccountByUserId',
    getInvestorAccountPersonalInfo = '/baza-dwolla/investor-account/cms/getInvestorAccountPersonalInfo',
    updateInvestorAccountPersonalInfo = '/baza-dwolla/investor-account/cms/updateInvestorAccountPersonalInfo',
    listInvestorAccounts = '/baza-dwolla/investor-account/cms/listInvestorAccounts',
    addInvestorAccount = '/baza-dwolla/investor-account/cms/addInvestorAccount',
    removeInvestorAccount = '/baza-dwolla/investor-account/cms/removeInvestorAccount',
    exportInvestorAccountsToCsv = '/baza-dwolla/investor-account/cms/exportInvestorAccountsToCsv',
}

export interface BazaDwollaInvestorAccountCmsEndpoint {
    getInvestorAccount(
        request: DwollaGetInvestorAccountRequest,
    ): Promise<DwollaGetInvestorAccountResponse> | Observable<DwollaGetInvestorAccountResponse>;
    getInvestorAccountByUserId(
        request: DwollaGetInvestorAccountByUserIdRequest,
    ): Promise<DwollaGetInvestorAccountByUserIdResponse> | Observable<DwollaGetInvestorAccountByUserIdResponse>;
    listInvestorAccounts(
        request: DwollaListInvestorAccountRequest,
    ): Promise<DwollaListInvestorAccountResponse> | Observable<DwollaListInvestorAccountResponse>;
    addInvestorAccount(
        request: DwollaAddInvestorAccountRequest,
    ): Promise<DwollaAddInvestorAccountResponse> | Observable<DwollaAddInvestorAccountResponse>;
    removeInvestorAccount(
        request: DwollaRemoveInvestorAccountRequest,
    ): Promise<DwollaRemoveInvestorAccountResponse> | Observable<DwollaRemoveInvestorAccountResponse>;
    exportInvestorAccountsToCsv(request: DwollaExportToCsvInvestorAccountRequest): Promise<string> | Observable<string>;
    getInvestorAccountPersonalInfo(
        request: DwollaGetInvestorAccountPersonalInfoRequest,
    ): Promise<DwollaGetInvestorAccountPersonalInfoResponse> | Observable<DwollaGetInvestorAccountPersonalInfoResponse>;
    updateInvestorAccountPersonalInfo(request: BazaDwollaInvestorAccountUpdatePersonalInformationRequest): Promise<void> | Observable<void>;
}

export class DwollaGetInvestorAccountRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type DwollaGetInvestorAccountResponse = BazaDwollaInvestorAccountDto;

export class DwollaGetInvestorAccountByUserIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;
}

export class DwollaGetInvestorAccountPersonalInfoRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isUpdate?: boolean;
}

export type DwollaGetInvestorAccountByUserIdResponse = BazaDwollaInvestorAccountDto;

export type DwollaGetInvestorAccountPersonalInfoResponse = BazaDwollaInvestorAccountPartyDetailsDto;

export class DwollaListInvestorAccountRequest extends CrudListRequestDto<BazaDwollaInvestorAccountDto> {}
export class DwollaListInvestorAccountResponse extends CrudListResponseDto<BazaDwollaInvestorAccountDto> {}

export class DwollaAddInvestorAccountRequest {
    @ApiModelProperty({
        description: 'Account ID',
        example: 1,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty({
        description: 'Dwolla Account ID',
        example: 'A12345',
    })
    @IsString()
    @IsNotEmpty()
    ncAccountId: string;

    @ApiModelProperty({
        description: 'Dwolla Party ID',
        example: 'P12345',
    })
    @IsString()
    @IsNotEmpty()
    ncPartyId: string;

    @ApiModelProperty({
        required: false,
        description: 'Automatically sync transactions & payment methods after adding investor account',
    })
    @IsBoolean()
    @IsOptional()
    sync?: boolean;
}

export class DwollaAddInvestorAccountResponse {
    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaDwollaInvestorAccountDto;

    @ApiModelProperty({
        description: 'Sync Results (only if "sync" is true)',
        required: false,
    })
    syncStatus?: BazaDwollaInvestorAccountSyncStatusDto;
}

export class DwollaRemoveInvestorAccountRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;
}

export type DwollaRemoveInvestorAccountResponse = void;

export class DwollaExportToCsvInvestorAccountRequest extends CrudExportToCsvRequest<
    BazaDwollaInvestorAccountCsvDto,
    DwollaListInvestorAccountRequest
> {}
