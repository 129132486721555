import { BazaDwollaDistributionImportCsvEntryError } from '../models/baza-dwolla-distribution-import-csv-entry-error';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaDwollaDistributionImportCsvEntryErrorDto {
    @ApiModelProperty({
        description: 'CSV Line (excepts header)',
    })
    row: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaDistributionImportCsvEntryError),
        description: 'Error type',
    })
    reason: BazaDwollaDistributionImportCsvEntryError;

    @ApiModelProperty({
        description: 'Exception (for "Exception" reason only)',
        required: false,
    })
    exception?: string;
}
