import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsEnum, IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';
import { BazaAccountConstants } from '../../../../baza-account/src';
import { Observable } from 'rxjs';
import { AuthResponse } from './auth.endpoint';
import { Auth2FAVerificationMethod } from '../models/auth-2fa-verification-method';

export enum Auth2FAEndpointPaths {
    auth2FA = '/baza-auth/2fa/auth',
    auth2FAVerify = '/baza-auth/2fa/verify',
    auth2FAMethods = '/baza-auth/auth2FAMethods',
    generate2FAGoogleQrCode = '/baza-auth/2fa/google-authenticator/generate-qrcode',
}

export interface Auth2FAEndpoint {
    auth2FA(request: Auth2FARequest, ...args: unknown[]): Promise<void> | Observable<void>;
    auth2FAVerify(request: Auth2FAVerifyRequest, ...args: unknown[]): Promise<AuthResponse> | Observable<AuthResponse>;
    auth2FAMethods(
        request: Auth2FAMethodsRequest,
        ...args: unknown[]
    ): Promise<Auth2FAMethodsResponse> | Observable<Auth2FAMethodsResponse>;
    generate2FAGoogleQrCode(
        request: AuthGoogle2FAQrCodeRequest,
        ...args: unknown[]
    ): Promise<AuthGoogle2FAQrCodeResponse> | Observable<AuthGoogle2FAQrCodeResponse>;
}

export class Auth2FARequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
        description: '2FA Verification Method',
    })
    @IsEnum(Auth2FAVerificationMethod)
    @IsNotEmpty()
    method: Auth2FAVerificationMethod;

    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    password: string;
}

export class Auth2FAVerifyRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty({
        description: 'Verification code sent with email',
    })
    @IsString()
    @IsNotEmpty()
    code: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
        description: '2FA Verification Method',
    })
    @IsEnum(Auth2FAVerificationMethod)
    @IsNotEmpty()
    method?: Auth2FAVerificationMethod;
}

export class Auth2FAMethodsRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty()
    @MinLength(BazaAccountConstants.V_PASSWORD_MIN)
    @MaxLength(BazaAccountConstants.V_PASSWORD_MAX)
    @IsString()
    @IsNotEmpty()
    password: string;
}

export class Auth2FAMethodsResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Auth2FAVerificationMethod),
    })
    @IsEnum(Auth2FAVerificationMethod)
    @IsNotEmpty()
    methods: Array<Auth2FAVerificationMethod>;
}

export class AuthGoogle2FAQrCodeRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export class AuthGoogle2FAQrCodeResponse {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    qrcodeDataURL: string;
}
