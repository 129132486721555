import { BazaNcDividendDto } from './baza-nc-dividend.dto';
import { BazaNcInvestorAccountDto } from '../../../../investor-account';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcDividendCmsDto extends BazaNcDividendDto {
    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaNcInvestorAccountDto;
}
