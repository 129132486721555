import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { JwtService } from '@scaliolabs/baza-core-ng';
import { BazaDwollaPaymentDto, DwollaAmount } from '@scaliolabs/baza-dwolla-shared';
import {
    BazaNcBankAccountsDataAccess,
    BazaNcCreditCardDataAccess,
    BazaNcDwollaDataAccess,
    BazaNcPurchaseFlowBankAccountDataAccess,
    BazaNcPurchaseFlowDataAccess,
    BazaNcPurchaseFlowLimitsDataAccess,
    BazaNcTransferDataAccess,
    BazaNcWithdrawalDataAccess,
} from '@scaliolabs/baza-nc-data-access';
import { BazaNcIntegrationListingsDataAccess } from '@scaliolabs/baza-nc-integration-data-access';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import {
    BazaNcBankAccountAchDto,
    BazaNcBankAccountGetDefaultByTypeResponse,
    BazaNcBankAccountLinkOnSuccessResponse,
    BazaNcBankAccountType,
    BazaNcCreditCardDto,
    BazaNcCreditCardLinkResponse,
    BazaNcCreditCardUnlinkResponse,
    BazaNcDwollaCustomerDetailDto,
    BazaNcDwollaTouchResponse,
    BazaNcLimitsDto,
    BazaNcPurchaseFlowLimitsForPurchaseResponse,
    PurchaseFlowDestroyResponse,
    PurchaseFlowDto,
    PurchaseFlowPlaidLinkDto,
    PurchaseFlowSubmitResponse,
    ReProcessPaymentResponse,
    RegulationLimitsDto,
    StatsDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaPlaidLinkResponseDto } from '@scaliolabs/baza-plaid-shared';
import { BazaWebUtilSharedService, EffectsUtil, StorageService } from '@scaliolabs/baza-web-utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PFConfig } from '../models';
import {
    CancelPurchase,
    ClearPurchaseState,
    ClearPurchaseStats,
    GetDefaultBankAccount,
    GetLimits,
    GetLimitsForPurchase,
    GetPurchaseStats,
    InvestorAccountTouch,
    LoadAccountBalance,
    LoadBankAccount,
    LoadCreditCardStripe,
    LoadNCStripeLink,
    LoadPlaidLegacyLink,
    LoadPlaidPluginLink,
    PatchStartPurchase,
    PlaidOnSuccessLink,
    ReprocessPayment,
    ResetRegCFLimitFromStats,
    SelectEntity,
    SetPFConfig,
    StartCart,
    StartPurchase,
    SubmitPurchase,
    TransferFunds,
    UnlinkCreditCardStripe,
    UpdateCart,
    UpsertCreditCardStripe,
    RecalculateLimits,
    WithdrawFunds,
} from './actions';

export interface PurchaseStateModel {
    // purchase flow
    cart: BazaNcIntegrationListingsDto | null;
    purchaseStart: PurchaseFlowDto | null;
    stats: StatsDto | null;
    numberOfShares: number | null;
    limits: BazaNcLimitsDto;
    limitForPurchase: BazaNcPurchaseFlowLimitsForPurchaseResponse;

    // wallet
    dwollaCustomerDetail: BazaNcDwollaCustomerDetailDto;
    dwollaAccountBalance: DwollaAmount;
    dwollaInvestorTouchResponse: BazaNcDwollaTouchResponse;

    // cash-in / cash-out
    dwollaDefaultCashInAccount: BazaNcBankAccountGetDefaultByTypeResponse;
    dwollaDefaultCashOutAccount: BazaNcBankAccountGetDefaultByTypeResponse;

    // ach bank
    bankAccount: BazaNcBankAccountAchDto;

    // stripe card
    creditCardStripe: BazaNcCreditCardDto;

    // reg-cf limits
    regCfLimit: RegulationLimitsDto;

    // plaid config
    plaidPluginLink: BazaPlaidLinkResponseDto;
    plaidLegacyLink: string;

    // stripe config
    stripeLink: BazaNcCreditCardLinkResponse;

    // configs
    pfConfig: PFConfig;
}

const initState = {
    name: 'purchase',
    defaults: {
        // purchase flow
        cart: undefined,
        purchaseStart: null,
        stats: null,
        numberOfShares: null,
        limits: null,
        limitForPurchase: null,

        // wallet
        dwollaCustomerDetail: null,
        dwollaAccountBalance: null,
        dwollaInvestorTouchResponse: null,

        // cash-in / cash-out
        dwollaDefaultCashInAccount: null,
        dwollaDefaultCashOutAccount: null,

        // ach bank
        bankAccount: null,

        // stripe card
        creditCardStripe: null,

        // reg-cf limits
        regCfLimit: null,

        // plaid config
        plaidPluginLink: null,
        plaidLegacyLink: null,

        // stripe config
        stripeLink: null,

        // configs
        pfConfig: null,
    },
};

const i18nBasePath = 'dwpf.notifications';

@State<PurchaseStateModel>(initState)
@Injectable()
export class PurchaseState {
    constructor(
        private readonly bankAccountDataAccessService: BazaNcPurchaseFlowBankAccountDataAccess,
        private readonly bazaNcBankAccountsDataAccess: BazaNcBankAccountsDataAccess,
        public readonly dwollaDataAccess: BazaNcDwollaDataAccess,
        private readonly dataAccess: BazaNcPurchaseFlowDataAccess,
        private readonly effectsUtil: EffectsUtil,
        private readonly jwtService: JwtService,
        private readonly limitsDataAccess: BazaNcPurchaseFlowLimitsDataAccess,
        private readonly storageService: StorageService,
        private readonly itemDataAccess: BazaNcIntegrationListingsDataAccess,
        private readonly bazaNcTransferDataAccess: BazaNcTransferDataAccess,
        private readonly bazaNcWithdrawDataAccess: BazaNcWithdrawalDataAccess,
        private readonly wts: BazaWebUtilSharedService,
        private readonly ccDataAccess: BazaNcCreditCardDataAccess,
    ) {}

    // #region Selectors

    // purchase flow
    @Selector()
    static cart(state: PurchaseStateModel): BazaNcIntegrationListingsDto {
        return state.cart;
    }

    @Selector()
    static purchaseStart(state: PurchaseStateModel): PurchaseFlowDto {
        return state.purchaseStart;
    }

    @Selector()
    static stats(state: PurchaseStateModel): StatsDto {
        return state.stats;
    }

    @Selector()
    static numberOfShares(state: PurchaseStateModel): number {
        return state.numberOfShares;
    }

    @Selector()
    static limits(state: PurchaseStateModel): BazaNcLimitsDto {
        return state.limits;
    }

    @Selector()
    static limitsForPurchase(state: PurchaseStateModel): BazaNcPurchaseFlowLimitsForPurchaseResponse {
        return state.limitForPurchase;
    }

    // wallet
    @Selector()
    static dwollaCustomerDetail(state: PurchaseStateModel): BazaNcDwollaCustomerDetailDto {
        return state.dwollaCustomerDetail;
    }

    @Selector()
    static accountBalance(state: PurchaseStateModel): DwollaAmount {
        return state.dwollaAccountBalance;
    }

    @Selector()
    static dwollaInvestorTouchResponse(state: PurchaseStateModel): BazaNcDwollaTouchResponse {
        return state.dwollaInvestorTouchResponse;
    }

    // cash-in / cash-out
    @Selector()
    static dwollaDefaultCashInAccount(state: PurchaseStateModel): BazaNcBankAccountGetDefaultByTypeResponse {
        return state.dwollaDefaultCashInAccount;
    }

    @Selector()
    static dwollaDefaultCashOutAccount(state: PurchaseStateModel): BazaNcBankAccountGetDefaultByTypeResponse {
        return state.dwollaDefaultCashOutAccount;
    }

    // ach bank

    @Selector()
    static bankAccount(state: PurchaseStateModel): BazaNcBankAccountAchDto {
        return state.bankAccount;
    }

    // stripe card
    @Selector()
    static creditCardStripe(state: PurchaseStateModel): BazaNcCreditCardDto {
        return state.creditCardStripe;
    }

    // reg-cf limits
    @Selector()
    static regCfLimit(state: PurchaseStateModel): RegulationLimitsDto {
        return state.regCfLimit;
    }

    // plaid config
    @Selector()
    static plaidPluginLink(state: PurchaseStateModel): BazaPlaidLinkResponseDto {
        return state.plaidPluginLink;
    }

    @Selector()
    static plaidLegacyLink(state: PurchaseStateModel): string {
        return state.plaidLegacyLink;
    }

    // stripe config
    @Selector()
    static stripeLink(state: PurchaseStateModel): BazaNcCreditCardLinkResponse {
        return state.stripeLink;
    }

    // configs
    @Selector()
    static pfConfig(state: PurchaseStateModel): PFConfig {
        return state.pfConfig;
    }
    // #endregion

    // #region Actions

    // purchase flow
    @Action(GetPurchaseStats, { cancelUncompleted: true })
    GetPurchaseStats(ctx: StateContext<PurchaseStateModel>, action: GetPurchaseStats): Observable<StatsDto> {
        return this.dataAccess
            .stats({
                offeringId: action.offeringId,
                requestedAmountCents: action.requestedAmountCents,
                withReservedShares: false,
                withReservedByOtherUsersShares: false,
            })
            .pipe(
                tap((response: StatsDto) => {
                    ctx.patchState({ stats: response });
                }),
                this.effectsUtil.tryCatchError$(''),
            );
    }

    @Action(CancelPurchase, { cancelUncompleted: true })
    CancelPurchase(ctx: StateContext<PurchaseStateModel>, action: CancelPurchase): Observable<PurchaseFlowDestroyResponse> {
        return this.dataAccess.destroy(action.data).pipe(
            tap(() => ctx.patchState({ purchaseStart: null })),
            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'cancel_purchase_fail')),
        );
    }

    @Action(ClearPurchaseState, { cancelUncompleted: true })
    ClearPurchaseState(ctx: StateContext<PurchaseStateModel>): void {
        ctx.setState(initState.defaults);
    }

    @Action(ClearPurchaseStats, { cancelUncompleted: true })
    ClearPurchaseStats(ctx: StateContext<PurchaseStateModel>): void {
        ctx.patchState({ stats: null });
    }

    @Action(GetLimits, { cancelUncompleted: true })
    GetLimits(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcLimitsDto> {
        return this.limitsDataAccess.limits().pipe(
            tap((response: BazaNcLimitsDto) => {
                ctx.patchState({ limits: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(GetLimitsForPurchase, { cancelUncompleted: true })
    GetLimitsForPurchase(
        ctx: StateContext<PurchaseStateModel>,
        { requestedAmountCents, offeringId },
    ): Observable<BazaNcPurchaseFlowLimitsForPurchaseResponse> {
        return this.limitsDataAccess.limitsForPurchase({ requestedAmountCents, offeringId }).pipe(
            tap((response: BazaNcPurchaseFlowLimitsForPurchaseResponse) => {
                ctx.patchState({ limitForPurchase: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(SelectEntity, { cancelUncompleted: true })
    SelectEntity(ctx: StateContext<PurchaseStateModel>, action: SelectEntity): Observable<BazaNcIntegrationListingsDto> {
        return this.itemDataAccess.getById({ id: action.entityId }).pipe(
            tap((response: BazaNcIntegrationListingsDto) => {
                this.storageService.setObject('cart', action.entityId);

                return ctx.patchState({ cart: response });
            }),
        );
    }

    @Action(UpdateCart, { cancelUncompleted: true })
    UpdateCart(ctx: StateContext<PurchaseStateModel>, action: UpdateCart): void {
        this.storageService.setObject('cart', action.entity.id);
        ctx.patchState({ cart: action.entity });
    }

    @Action(StartCart, { cancelUncompleted: true })
    StartCart(ctx: StateContext<PurchaseStateModel>): void {
        const entityId = this.storageService.getObject<string>('cart') || null;

        if (this.jwtService.hasJwt() && entityId !== null) {
            ctx.dispatch(new SelectEntity(Number(entityId)));
        } else {
            ctx.patchState({ cart: null });
        }
    }

    @Action(StartPurchase, { cancelUncompleted: true })
    StartPurchase(ctx: StateContext<PurchaseStateModel>, action: StartPurchase): Observable<PurchaseFlowDto> {
        ctx.patchState({ numberOfShares: action.data?.numberOfShares });

        return this.dataAccess.session(action.data).pipe(
            tap((response: PurchaseFlowDto) => {
                ctx.patchState({ purchaseStart: response });
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }

    @Action(SubmitPurchase, { cancelUncompleted: true })
    SubmitPurchase(ctx: StateContext<PurchaseStateModel>, action: SubmitPurchase): Observable<PurchaseFlowSubmitResponse> {
        return this.dataAccess
            .submit({ id: action.tradeId })
            .pipe(this.effectsUtil.tryCatch$(this.wts.getI18nLabel(i18nBasePath, 'submit_purchase_success'), ''));
    }

    @Action(PatchStartPurchase, { cancelUncompleted: true })
    PatchStartPurchase(ctx: StateContext<PurchaseStateModel>, action: PatchStartPurchase): void {
        ctx.patchState({ numberOfShares: action.purchaseStart.numberOfShares });

        const purchaseStart = { ...ctx.getState().purchaseStart, ...action.purchaseStart };
        ctx.patchState({ purchaseStart: purchaseStart });
    }

    @Action(ReprocessPayment, { cancelUncompleted: true })
    reprocessPayment(ctx: StateContext<PurchaseStateModel>, action: ReprocessPayment): Observable<ReProcessPaymentResponse> {
        return this.dataAccess
            .reprocessPayment(action.data)
            .pipe(
                this.effectsUtil.tryCatch$(
                    this.wts.getI18nLabel(i18nBasePath, 'purchase_reprocess_success'),
                    this.wts.getI18nLabel(i18nBasePath, 'purchase_reprocess_fail'),
                ),
            );
    }

    // wallet
    @Action(LoadAccountBalance, { cancelUncompleted: true })
    LoadAccountBalance(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcDwollaCustomerDetailDto> {
        return this.dwollaDataAccess.current().pipe(
            tap((response: BazaNcDwollaCustomerDetailDto) => {
                ctx.patchState({ dwollaCustomerDetail: response, dwollaAccountBalance: response?.balance ?? null });
            }),
            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'load_account_balance_fail')),
        );
    }

    @Action(InvestorAccountTouch, { cancelUncompleted: true })
    InvestorAccountTouch(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcDwollaTouchResponse> {
        return this.dwollaDataAccess.touch().pipe(
            tap((response: BazaNcDwollaTouchResponse) => {
                ctx.patchState({ dwollaInvestorTouchResponse: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    // cash-in / cash-out
    @Action(PlaidOnSuccessLink, { cancelUncompleted: false })
    PlaidOnSuccessLink(
        ctx: StateContext<PurchaseStateModel>,
        action: PlaidOnSuccessLink,
    ): Observable<BazaNcBankAccountLinkOnSuccessResponse> {
        return this.bazaNcBankAccountsDataAccess.linkOnSuccess(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(GetDefaultBankAccount, { cancelUncompleted: false })
    GetDefaultBankAccount(
        ctx: StateContext<PurchaseStateModel>,
        action: GetDefaultBankAccount,
    ): Observable<BazaNcBankAccountGetDefaultByTypeResponse> {
        return this.bazaNcBankAccountsDataAccess.default(action.type, action.request).pipe(
            tap((response: BazaNcBankAccountGetDefaultByTypeResponse) => {
                switch (action.type) {
                    case BazaNcBankAccountType.CashIn:
                        ctx.patchState({ dwollaDefaultCashInAccount: response });
                        break;
                    case BazaNcBankAccountType.CashOut:
                        ctx.patchState({ dwollaDefaultCashOutAccount: response });
                        break;
                }
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    // add / withdraw funds
    @Action(TransferFunds, { cancelUncompleted: true })
    TransferFunds(ctx: StateContext<PurchaseStateModel>, action: TransferFunds): Observable<BazaDwollaPaymentDto> {
        return this.bazaNcTransferDataAccess.transfer(action.transferRequest).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(WithdrawFunds, { cancelUncompleted: true })
    WithdrawFunds(ctx: StateContext<PurchaseStateModel>, action: WithdrawFunds): Observable<BazaDwollaPaymentDto> {
        return this.bazaNcWithdrawDataAccess.withdraw(action.withdrawRequest).pipe(this.effectsUtil.tryCatchNone$());
    }

    // ach bank
    @Action(LoadBankAccount, { cancelUncompleted: true })
    LoadBankAccount(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcBankAccountAchDto> {
        return this.bazaNcBankAccountsDataAccess.ncAchBankAccount().pipe(
            tap((response: BazaNcBankAccountAchDto) => {
                ctx.patchState({ bankAccount: response });
            }),
            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'load_bank_account_fail')),
        );
    }

    // stripe card
    @Action(UnlinkCreditCardStripe, { cancelUncompleted: true })
    UnlinkCreditCardStripe(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcCreditCardUnlinkResponse> {
        return this.ccDataAccess
            .unlink()
            .pipe(this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'delete_credit_card_fail')));
    }

    @Action(LoadCreditCardStripe, { cancelUncompleted: true })
    LoadCreditCardStripe(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcCreditCardDto> {
        return this.ccDataAccess.get().pipe(
            tap((response: BazaNcCreditCardDto) => {
                ctx.patchState({ creditCardStripe: response });
            }),

            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'load_credit_card_fail')),
        );
    }

    @Action(UpsertCreditCardStripe, { cancelUncompleted: true })
    UpsertCreditCardStripe(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcCreditCardDto> {
        return this.ccDataAccess.upsert().pipe(
            tap((response: BazaNcCreditCardDto) => {
                ctx.patchState({
                    creditCardStripe: response,
                    stripeLink: null,
                });
            }),
            this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'save_credit_card_fail')),
        );
    }

    // reg-cf limits
    @Action(RecalculateLimits, { cancelUncompleted: true })
    RecalculateLimits(ctx: StateContext<PurchaseStateModel>, action: RecalculateLimits): Observable<RegulationLimitsDto> {
        return this.dataAccess.calculateRegulationLimits(action.regCfLimitRequest).pipe(
            tap((response: RegulationLimitsDto) => {
                ctx.patchState({ regCfLimit: response });
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }

    @Action(ResetRegCFLimitFromStats, { cancelUncompleted: true })
    ResetRegCFLimitFromStats(ctx: StateContext<PurchaseStateModel>, action: ResetRegCFLimitFromStats): void {
        ctx.patchState({
            regCfLimit: {
                limitCents: action.regCfStatLimits.accountTotalLimitCents,
                details: null,
            },
        });
    }

    // plaid links
    @Action(LoadPlaidPluginLink, { cancelUncompleted: true })
    LoadPlaidPluginLink(ctx: StateContext<PurchaseStateModel>): Observable<BazaPlaidLinkResponseDto> {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        return this.bazaNcBankAccountsDataAccess
            .link({
                withRedirectUrl: false,
            })
            .pipe(
                tap((response: BazaPlaidLinkResponseDto) => {
                    ctx.patchState({ plaidPluginLink: response });
                }),
                this.effectsUtil.tryCatchError$(this.wts.getI18nLabel(i18nBasePath, 'load_plaid_link_fail')),
            );
    }

    @Action(LoadPlaidLegacyLink, { cancelUncompleted: true })
    LoadPlaidLegacyLink(ctx: StateContext<PurchaseStateModel>): Observable<PurchaseFlowPlaidLinkDto> {
        return this.bankAccountDataAccessService.getPlaidLink().pipe(
            tap((response: PurchaseFlowPlaidLinkDto) => {
                ctx.patchState({ plaidLegacyLink: response.link });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    // stripe link
    @Action(LoadNCStripeLink, { cancelUncompleted: true })
    LoadNCStripeLink(ctx: StateContext<PurchaseStateModel>): Observable<BazaNcCreditCardLinkResponse> {
        return this.ccDataAccess.link().pipe(
            tap((response: BazaNcCreditCardLinkResponse) => {
                ctx.patchState({ stripeLink: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    // configs
    @Action(SetPFConfig, { cancelUncompleted: true })
    SetPFConfig(ctx: StateContext<PurchaseStateModel>, action: SetPFConfig): void {
        ctx.patchState({ pfConfig: action.pfConfig });
    }

    // #endregion
}
