<nz-layout
    class="app-layout"
    [ngClass]="{ 'with-dividend-alert': showDividendAlert$ | async }">
    <app-dividend-alert *ngIf="layout === 'full'"></app-dividend-alert>

    <app-header
        [(openedMenu)]="openedMenu"
        *ngIf="layout === 'full'"></app-header>

    <nz-content
        [ngClass]="{ 'ant-layout-content-mini': layout === 'mini' }"
        [ngClass]="{ 'ant-layout-content-mini-center': layout === 'mini-center' }">
        <div [ngClass]="{ 'ant-layout-wrap': wrapper }">
            <router-outlet></router-outlet>
        </div>
    </nz-content>

    <app-footer *ngIf="layout === 'full'"></app-footer>

    <app-footer-mini *ngIf="layout.includes('mini')"></app-footer-mini>
    <app-back-to-top></app-back-to-top>
</nz-layout>
