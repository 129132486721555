import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOperationType, TransactionState } from '@scaliolabs/baza-nc-shared';

/**
 * Filters Settings for Operation List (Investment)
 */
export class BazaNcIntegrationOperationStatusesInvestmentDto {
    @ApiModelProperty({
        type: 'string',
        example: [BazaNcOperationType.Investment],
        enum: [BazaNcOperationType.Investment],
    })
    type: BazaNcOperationType.Investment;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(TransactionState),
    })
    statuses: Array<TransactionState>;
}
