import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator';
import { CrudListSortDto } from './crud-list-sort.dto';
import { Type } from 'class-transformer';

export class CrudArrayRequestDto<T = any> {
    @ApiModelProperty({
        description: 'Sort order',
        required: false,
    })
    @ValidateNested()
    @Type(() => CrudListSortDto)
    @IsOptional()
    sort?: CrudListSortDto<T>;

    @ApiModelProperty({
        description: 'Search by query string',
        required: false,
    })
    @IsString()
    @IsOptional()
    queryString?: string;

    @ApiModelProperty({
        description: 'Include only set of fields',
        required: false,
        type: 'string',
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    includeFields?: Array<keyof T>;

    @ApiModelProperty({
        description: 'Exclude set of fields',
        required: false,
        type: 'string',
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    excludeFields?: Array<keyof T>;
}
