
export enum BazaNcOfferingStatus {
    Open = 'open',
    ComingSoon = 'coming-soon',
    Closed = 'closed',
}

export const ncOfferingStatusI18n = {
    [BazaNcOfferingStatus.Open]: 'Open',
    [BazaNcOfferingStatus.ComingSoon]: 'Coming Soon',
    [BazaNcOfferingStatus.Closed]: 'Closed',
};

