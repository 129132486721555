export const WebUiPaymentBankAccountEnI18n = {
    addMode: {
        plaid: 'Bank Account',
        subtitle: '',
    },
    editMode: {
        account: {
            name: 'Account holder name:',
            number: 'Account number:',
            type: 'Account type:',
        },
    },
};
