import { Observable } from 'rxjs';
import { PageDto } from '../dto/page.dto';

export enum PagesEndpointPaths {
    get = '/baza-content-types/pages/get/:url',
    getById = '/baza-content-types/pages/getById/:id',
}

export interface PagesEndpoint {
    get(url: string): Promise<PageDto> | Observable<PageDto>;
    getById(id: number): Promise<PageDto> | Observable<PageDto>;
}
