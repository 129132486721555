import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { Verification } from '../models';
import { LoadVerification, VerificationState } from '../store';

@Injectable({ providedIn: 'root' })
export class VerificationResolver implements Resolve<Verification> {
    constructor(private readonly store: Store) {}

    resolve(): Observable<Verification> {
        const verificationInfo = this.store.selectSnapshot(VerificationState.verification);
        return verificationInfo ? of(verificationInfo) : this.store.dispatch(new LoadVerification());
    }
}
