import { BazaWebBundleConfig } from '../baza-web-bundle.config';
import { BazaSentryNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';

export const BAZA_NG_SENTRY_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaSentryNgModuleForRootOptions = (config: BazaWebBundleConfig) => ({
    injects: [],
    useFactory: () => ({
        sentry: config.sentry
            ? {
                dsn: config.sentry.dsn,
                browserOptions: {
                    ...config.sentry.browserOptions,
                    environment: config.environment,
                },
            }
            : {
                dsn: undefined,
            },
    }),
});
