import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lastDigits',
})
export class LastDigitsPipe implements PipeTransform {
    transform(value: string, length: number = value?.length): string {
        if (!value) return '';
        return length < 4 ? value : `${'*'.repeat(length - 4)} ${value.substring(value.length - 4)}`;
    }
}
