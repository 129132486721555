import { ContactsSubjectCmsDto } from './contacts-subject-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class ContactsCmsDto {
    @ApiModelProperty({
        description: 'ID',
        example: 1,
    })
    id: number;

    @ApiModelProperty({
        description: 'Created At (ISO)',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Processed At (ISO)',
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        description: 'Sent via SMTP?',
    })
    sent = false;

    @ApiModelProperty({
        description: 'Subject (DTO)',
    })
    subject: ContactsSubjectCmsDto;

    @ApiModelProperty({
        description: 'Name (Last Name)',
    })
    name: string;

    @ApiModelProperty({
        description: 'Email to contact',
    })
    email: string;

    @ApiModelProperty({
        description: 'Message body',
    })
    message: string;
}
