import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { BazaReferralCodeDto } from '../dto/baza-referral-code.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaReferralCodeUsageDto } from '../dto/baza-referral-code-usage.dto';
import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';

export enum BazaReferralCodeCmsEndpointPaths {
    list = '/baza-core/cms/referral-codes/codes/list',
    getById = '/baza-core/cms/referral-codes/codes/getById',
    create = '/baza-core/cms/referral-codes/codes/create',
    edit = '/baza-core/cms/referral-codes/codes/edit',
    delete = '/baza-core/cms/referral-codes/codes/delete',
    exportListToCSV = '/baza-core/cms/referral-codes/codes/exportListToCSV',
}

export interface BazaReferralCodeCmsEndpoint {
    list(request: BazaReferralCodeCmsListRequest): Promise<BazaReferralCodeCmsListResponse> | Observable<BazaReferralCodeCmsListResponse>;
    getById(
        request: BazaReferralCodeCmsGetByIdRequest,
    ): Promise<BazaReferralCodeCmsGetByIdResponse> | Observable<BazaReferralCodeCmsGetByIdResponse>;
    create(request: BazaReferralCodeCmsCreateRequest): Promise<BazaReferralCodeDto> | Observable<BazaReferralCodeDto>;
    edit(request: BazaReferralCodeCmsEditRequest): Promise<BazaReferralCodeDto> | Observable<BazaReferralCodeDto>;
    delete(request: BazaReferralCodeCmsDeleteRequest): Promise<void> | Observable<void>;
    exportListToCSV(request: BazaReferralCodeCmsExportListToCSVRequest): Promise<string> | Observable<string>;
}

export class BazaReferralCodeCmsListRequest extends CrudListRequestDto {
    @ApiModelProperty({
        description: 'List of referral codes associated with account (should be one of account of campaign',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    accountId?: number;

    @ApiModelProperty({
        description: 'List of referral codes associated with campaign (should be one of account of campaign',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    campaignId?: number;
}

export class BazaReferralCodeCmsListResponse extends CrudListResponseDto<BazaReferralCodeDto> {
    @ApiModelProperty({
        type: BazaReferralCodeDto,
        isArray: true,
    })
    items: Array<BazaReferralCodeDto>;
}

export class BazaReferralCodeCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        description: 'If true method will returns full referral code usage statistics inside response',
    })
    @IsBoolean()
    @IsOptional()
    withFullStatistics?: boolean;
}

export class BazaReferralCodeCmsGetByIdResponse {
    @ApiModelProperty()
    referralCode: BazaReferralCodeDto;

    @ApiModelProperty({
        isArray: true,
        type: BazaReferralCodeUsageDto,
        required: false,
    })
    referralCodeStatistics?: Array<BazaReferralCodeUsageDto>;
}

export class BazaReferralCodeCmsEntityBody {
    @ApiModelProperty({
        description: 'Is active?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isActive: boolean;

    @ApiModelProperty({
        description: 'Is default? (account only)',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isDefault?: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    codeDisplay: string;
}

export class BazaReferralCodeCmsCreateRequest extends BazaReferralCodeCmsEntityBody {
    @ApiModelProperty({
        description: 'Associate referral code with account (one of account or campaign is allowed)',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    associatedWithAccountId?: number;

    @ApiModelProperty({
        description: 'Associate referral code with campaign (one of account or campaign is allowed)',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    associatedWithCampaignId?: number;
}

export class BazaReferralCodeCmsEditRequest extends BazaReferralCodeCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaReferralCodeCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaReferralCodeCmsExportListToCSVRequest extends CrudExportToCsvRequest<
    BazaReferralCodeDto,
    BazaReferralCodeCmsListRequest
> {}
