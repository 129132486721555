export enum BazaNcReportStatus {
    /**
     * Transaction is created, but Dwolla Payment is not processed yet
     */
    PendingPayment = 'PendingPayment',

    /**
     * Transaction is created and Dwolla Payment is processed
     */
    ReadyToReport = 'ReadyToReport',

    /**
     * Transaction is reported to NC
     */
    Reported = 'Reported',
}

/**
 * List of Report Status which can be updated from Dwolla
 */
export const bazaNcReportStatusesToSync: Array<BazaNcReportStatus> = [BazaNcReportStatus.PendingPayment];

/**
 * List of Report Status which can be sent to North Capital Escrow OPS
 */
export const bazaNcReportStatusesToSend: Array<BazaNcReportStatus> = [BazaNcReportStatus.ReadyToReport];

/**
 * List of Report Status which can be resent to North Capital Escrow OPS
 */
export const bazaNcReportStatusesToResend: Array<BazaNcReportStatus> = [BazaNcReportStatus.Reported];
