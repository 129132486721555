import { Observable } from 'rxjs';
import { BazaNcBootstrapDto } from '../dto/baza-nc-bootstrap.dto';

export enum BazaNcBootstrapEndpointPaths {
    Bootstrap = '/baza-nc/bootstrap',
}

export interface BazaNcBootstrapEndpoint {
    bootstrap(...args: unknown[]): Promise<BazaNcBootstrapDto> | Observable<BazaNcBootstrapDto>;
}
