// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BazaHttpHeaders {
    export const HTTP_HEADER_APP = 'X-Baza-Application';
    export const HTTP_HEADER_APP_ID = 'X-Baza-Application-Id';
    export const HTTP_HEADER_VERSION = 'X-Baza-Api-Version';
    export const HTTP_HEADER_VERSION_SUPPORTED = 'X-Baza-Api-Supported-Versions';
    export const HTTP_HEADER_VERSION_SKIP = 'SKIP';
    export const HTTP_HEADER_SKIP_MAINTENANCE = 'X-Baza-Skip-Maintenance';
    export const HTTP_HEADER_I18N_LANGUAGE = 'X-Baza-I18n-Language';
    export const HTTP_HEADER_LINK_FCM_TOKEN = 'X-Baza-Fcm-Token';
    export const HTTP_HEADER_LINK_APPLE_TOKEN = 'X-Baza-Apns-Token';
    export const HTTP_HEADER_UNLINK_FCM_TOKEN = 'X-Baza-Fcm-Token-Unlink';
    export const HTTP_HEADER_UNLINK_APPLE_TOKEN = 'X-Baza-Apns-Token-Unlink';
    export const HTTP_HEADER_CORRELATION_ID = 'X-Baza-Correlation-Id';
}
