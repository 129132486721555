import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { PersonalInformationApplyRequest } from './personal-information-apply-request';

export class AccountVerificationPersonalInformationDto extends PersonalInformationApplyRequest {
    @IsBoolean()
    @IsOptional()
    hasAdditionalDocument?: boolean;

    @IsBoolean()
    @IsOptional()
    hasSsnDocument?: boolean;

    @IsOptional()
    @IsString()
    ssnDocumentFileName?: string;

    @IsOptional()
    @IsString()
    ssnDocumentUrl?: string;

    @IsOptional()
    @IsString()
    ssnDocumentAwsKey?: string;

    @IsOptional()
    @IsString()
    ssnDocumentId?: string;

    @IsOptional()
    @IsString()
    additionalDocumentUrl?: string;

    @IsOptional()
    @IsString()
    additionalDocumentFileName?: string;

    @IsOptional()
    @IsString()
    additionalDocumentId?: string;
}
