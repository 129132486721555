import { BazaMaintenanceNgModuleForRootOptions } from '@scaliolabs/baza-core-ng';
import { BazaWebBundleConfig } from '../baza-web-bundle.config';

export const BAZA_NG_MAINTENANCE_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaMaintenanceNgModuleForRootOptions = (config) => ({
    deps: [],
    useFactory: () => ({
        skip: false,
        redirectTo: config.links.maintenanceUrl,
    }),
});
