import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { BazaNcIntegrationFavoriteEndpoint, BazaNcIntegrationFavoriteEndpointPaths, BazaNcIntegrationFavoriteExcludeRequest, BazaNcIntegrationFavoriteIncludeRequest } from '@scaliolabs/baza-nc-integration-shared';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcIntegrationFavoriteDataAccess implements BazaNcIntegrationFavoriteEndpoint {
    constructor(
        private readonly http: BazaDataAccessService,
    ) {}

    include(request: BazaNcIntegrationFavoriteIncludeRequest): Observable<void> {
        return this.http.post(BazaNcIntegrationFavoriteEndpointPaths.include, request);
    }

    exclude(request: BazaNcIntegrationFavoriteExcludeRequest): Observable<void> {
        return this.http.post(BazaNcIntegrationFavoriteEndpointPaths.exclude, request);
    }
}
