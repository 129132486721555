import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class NumberSchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty({
        required: false,
    })
    min?: number;

    @ApiModelProperty({
        required: false,
    })
    max?: number;
}

export class NumberSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.Number],
    })
    type: BazaNcIntegrationSchemaType.Number;

    @ApiModelProperty()
    payload: NumberSchemaDefinitionPayload;
}
