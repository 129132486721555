import { Injectable } from '@angular/core';
import { BazaWebBundleEnvironment } from '../models/baza-web-bundle-environment';

@Injectable({
    providedIn: 'root',
})
export class BazaNgWebEnvironment<T extends BazaWebBundleEnvironment = BazaWebBundleEnvironment> {
    private _current: BazaWebBundleEnvironment;

    set current(env: BazaWebBundleEnvironment) {
        this._current = env;
    }

    get current(): BazaWebBundleEnvironment {
        return JSON.parse(JSON.stringify(this._current));
    }
}
