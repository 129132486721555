<ng-container *ngIf="dataStream$ | async as data; else skeleton">
    <div class="purchase-payment">
        <div class="steps-content">
            <div
                nz-row
                nzGutter="30"
                class="purchase__row">
                <div
                    nz-col
                    nzXs="12"
                    class="purchase__info">
                    <div class="purchase__heading">
                        {{ wts?.getI18nLabel(i18nBasePath, 'title') }}
                    </div>

                    <ul class="purchase-checklist">
                        <li class="purchase-checklist__item">
                            <svg-icon-sprite
                                class="purchase-checklist__icon"
                                src="done"></svg-icon-sprite>
                            {{ wts?.getI18nLabel(i18nBasePath, 'checklist.accountVerified') }}
                        </li>

                        <li class="purchase-checklist__item">
                            <svg-icon-sprite
                                class="purchase-checklist__icon"
                                src="done"></svg-icon-sprite>
                            {{ wts?.getI18nLabel(i18nBasePath, 'checklist.documentSigned') }}
                        </li>

                        <li
                            *ngIf="paymentMethodAdded"
                            class="purchase-checklist__item">
                            <svg-icon-sprite
                                class="purchase-checklist__icon"
                                src="done"></svg-icon-sprite>
                            {{ wts?.getI18nLabel(i18nBasePath, 'checklist.paymentMethodAdded') }}
                        </li>
                    </ul>

                    <app-purchase-methods
                        [initData]="initData"
                        [isPurchaseAboveLimit]="isPurchaseAboveLimit"
                        [config]="config"
                        (updateSubmitBtnDisableStatus)="onUpdateSubmitBtnDisableStatus($event)"
                        (paymentMethodAdded)="paymentMethodAdded = $event"></app-purchase-methods>
                </div>

                <div
                    nz-col
                    nzXs="12"
                    class="purchase__summary">
                    <app-summary
                        [entity]="cart$ | async"
                        [numberOfShares]="numberOfShares$ | async"
                        [purchaseStart]="purchaseStart$ | async"></app-summary>
                </div>
            </div>
        </div>

        <div
            nz-row
            nzGutter="30"
            class="purchase__row">
            <div
                nz-col
                nzXs="12"
                class="purchase__info">
                <div class="steps-action-with-header">
                    <div
                        class="steps-note"
                        [innerHTML]="wts?.getI18nLabel(i18nBasePath, 'authorization.label') | sanitizeHtml"
                        appLinkify
                        [linkConfigPath]="authorizationLinkConfig"></div>

                    <div class="steps-action">
                        <button
                            nz-button
                            nzSize="large"
                            nzType="primary"
                            type="submit"
                            (click)="onFormSubmit(data.purchaseStart)"
                            [disabled]="isSubmitBtnDisabled">
                            {{ wts?.getI18nLabel(i18nBasePath, 'actions.submit') }}
                        </button>

                        <button
                            nz-button
                            nzSize="large"
                            nzType="default"
                            (click)="onBackClick(data.purchaseStart)">
                            {{ wts?.getI18nLabel(i18nBasePath, 'actions.back') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
