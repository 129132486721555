import { IsNotEmpty, IsString } from 'class-validator';
import { IssuerId } from '../models/issuer-id';

export class DeleteIssuerAccountRequest {
    @IsString()
    @IsNotEmpty()
    issuerId: IssuerId;

    @IsString()
    @IsNotEmpty()
    CustName?: string;
}
