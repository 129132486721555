import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOperationType } from '@scaliolabs/baza-nc-shared';
import { BazaDwollaPaymentStatus } from '@scaliolabs/baza-dwolla-shared';

/**
 * Filters Settings for Operation List (Transfer, i.e. Cash In)
 */
export class BazaNcIntegrationOperationStatusesTransferDto {
    @ApiModelProperty({
        type: 'string',
        example: [BazaNcOperationType.Transfer],
        enum: [BazaNcOperationType.Transfer],
    })
    type: BazaNcOperationType.Transfer;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaDwollaPaymentStatus),
    })
    statuses: Array<BazaDwollaPaymentStatus>;
}
