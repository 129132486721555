import {
    BazaNcIntegrationSchemaDefinition,
    bazaNcIntegrationSchemaDefinitionSwagger,
} from '../models/baza-nc-integration-schema-definition';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationSchemaTab } from '../models/baza-nc-integration-schema-tab';
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsObject,
    IsOptional,
    IsPositive,
    IsString,
    Min,
    ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { BazaNcOfferingRegulationType } from '@scaliolabs/baza-nc-shared';

export class BazaNcIntegrationSchemaDto implements CrudSortableEntity {
    @ApiModelProperty()
    @IsPositive()
    @IsNumber()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @Min(0)
    @IsNumber()
    @IsNotEmpty()
    sortOrder: number;

    @ApiModelProperty({
        description: 'defines type of regulation for the listing/offering, default type is RegA regulation',
    })
    @IsString()
    @IsOptional()
    @IsEnum(BazaNcOfferingRegulationType)
    regulationType?: BazaNcOfferingRegulationType;

    @ApiModelProperty({
        description: 'Unique autogenerated version (Update Id)',
    })
    @IsString()
    @IsOptional()
    updateId: string;

    @ApiModelProperty({
        description: 'Title (for CMS UI)',
    })
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description:
            'Display Name (i.e. Title which will be displayed in UI elements for users). Will be available only if Schema is marked as Public',
    })
    @IsString()
    @IsOptional()
    displayName?: string;

    @ApiModelProperty({
        description: 'Is Schema Locked for any changes?',
    })
    @IsBoolean()
    @IsNotEmpty()
    locked: boolean;

    @ApiModelProperty({
        description: 'Will be displayed in UI elements for users?',
    })
    @IsBoolean()
    @IsNotEmpty()
    published: boolean;

    @ApiModelProperty({
        ...bazaNcIntegrationSchemaDefinitionSwagger(),
        description: 'Schema Field Definitions',
    })
    @IsObject({ each: true })
    @IsArray()
    definitions: Array<BazaNcIntegrationSchemaDefinition>;

    @ApiModelProperty({
        description: 'Tabs',
        type: BazaNcIntegrationSchemaTab,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => BazaNcIntegrationSchemaTab)
    @IsNotEmpty()
    tabs: Array<BazaNcIntegrationSchemaTab>;
}
