import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { RequestAppBootstrapInitData, StartApp } from '@scaliolabs/sandbox-web/data-access';
import deepmerge from 'deepmerge';
import { BazaWebUiEnI18n } from '@scaliolabs/baza-web-ui-components';
import { BazaWebVFEnI18n } from '@scaliolabs/baza-web-verification-flow';
import { BazaWebAccountEnI18n, BazaWebPFEnI18n } from '@scaliolabs/baza-web-purchase-flow';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    constructor(
        private readonly translate: TranslateService,
        private readonly store: Store,
        private readonly wts: BazaWebUtilSharedService,
    ) {
        this.configureI18nTranslations();
        this.store.dispatch(new StartApp());
    }

    ngOnInit(): void {
        this.wts.refreshInitData$.pipe(untilDestroyed(this)).subscribe(() => {
            this.store.dispatch(new RequestAppBootstrapInitData());
        });
    }

    configureI18nTranslations() {
        const EnI18n = deepmerge.all([BazaWebUiEnI18n, BazaWebAccountEnI18n, BazaWebVFEnI18n, BazaWebPFEnI18n]);
        this.translate.setTranslation('en', EnI18n);
    }
}
