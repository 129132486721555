import { BazaContentDto } from '../../../../../shared/src';
import { CategoryListDto } from '../../../../categories/src';
import { BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class EventDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        required: false,
    })
    url?: string;

    @ApiModelProperty()
    dateCreatedAt: string;

    @ApiModelProperty()
    datePublishedAt?: string;

    @ApiModelProperty({
        required: false,
    })
    primaryCategory?: CategoryListDto;

    @ApiModelProperty({
        required: false,
        type: CategoryListDto,
        isArray: true,
    })
    additionalCategories: Array<CategoryListDto>;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    location?: string;

    @ApiModelProperty()
    link?: string;

    @ApiModelProperty()
    date: string;

    @ApiModelProperty({
        required: false,
    })
    imageUrl?: string;

    @ApiModelProperty({
        required: false,
    })
    thumbnailUrl?: string;

    @ApiModelProperty()
    contents: BazaContentDto;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
