import { IsEnum, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { NorthCapitalAccountId } from '../models/account-id';
import { CardType } from '../models/card-type';

export class AddCreditCardRequest {
    // TODO: Remove class-validator decorators for NC Request / NC Response / any other NC-related models
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    creditCardName: string;

    @IsString()
    @IsNotEmpty()
    creditCardNumber: string;

    @IsString()
    @IsNotEmpty()
    expirationDate: string;

    @IsPositive()
    @IsString()
    cvvNumber: string;

    @IsEnum(CardType)
    @IsNotEmpty()
    cardType: CardType;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;
}
