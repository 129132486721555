export const WebUiMarketingHeroEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="heroCTALink"></a>`,
            linkConfig: {
                key: `heroCTALink`,
                link: {
                    appLink: {
                        commands: ['/items'],
                        text: 'Get Started',
                    },
                },
            },
        },
    },
};
