import { BazaDwollaDistributionPaymentStatus } from '../models/baza-dwolla-distribution-payment-status';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    BazaDwollaDistributionPaymentHistoryActions,
    bazaDwollaDistributionPaymentHistoryActionsSwagger,
} from '../models/baza-dwolla-distribution-payment-history-action';

export class BazaDwollaDistributionHistoryDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaDistributionPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaDistributionPaymentStatus;

    @ApiModelProperty({
        ...bazaDwollaDistributionPaymentHistoryActionsSwagger(),
        description: 'Action & Action Details',
    })
    action: BazaDwollaDistributionPaymentHistoryActions;
}
