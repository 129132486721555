import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { EventCmsDto } from '../dto/event-cms.dto';
import { BazaContentDto } from '../../../../../shared/src';
import { Type } from 'class-transformer';

export enum EventsCmsEndpointPaths {
    create = '/baza-content-types/cms/events/create',
    update = '/baza-content-types/cms/events/update',
    delete = '/baza-content-types/cms/events/delete',
    list = '/baza-content-types/cms/events/list',
    getById = '/baza-content-types/cms/events/getById',
    setSortOrder = '/baza-content-types/cms/events/setSortOrder',
}

export interface EventsCmsEndpoint {
    create(request: EventsCmsCreateRequest): Promise<EventCmsDto> | Observable<EventCmsDto>;
    update(request: EventsCmsUpdateRequest): Promise<EventCmsDto> | Observable<EventCmsDto>;
    delete(request: EventsCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: EventsCmsListRequest): Promise<EventsCmsListResponse> | Observable<EventsCmsListResponse>;
    getById(request: EventsCmsGetByIdRequest): Promise<EventCmsDto> | Observable<EventCmsDto>;
    setSortOrder(request: EventsCmsSetSortOrderRequest): Promise<EventsCmsSetSortOrderResponse> | Observable<EventsCmsSetSortOrderResponse>;
}

export class EventsCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    datePublishedAt?: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    primaryCategoryId?: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    additionalCategoryIds?: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    link?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    date: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    imageAwsS3Key?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    thumbnailAwsS3Key?: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class EventsCmsCreateRequest extends EventsCmsEntityBody {}

export class EventsCmsUpdateRequest extends EventsCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class EventsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class EventsCmsListRequest extends CrudListRequestDto<EventCmsDto> {}

export class EventsCmsListResponse extends CrudListResponseDto<EventCmsDto> {
    @ApiModelProperty({
        type: EventCmsDto,
        isArray: true,
    })
    items: Array<EventCmsDto>;
}

export class EventsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class EventsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class EventsCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<EventCmsDto> {
    @ApiModelProperty()
    entity: EventCmsDto;

    @ApiModelProperty({
        type: EventCmsDto,
        isArray: true,
    })
    affected: Array<EventCmsDto>;
}
