import { BazaNcIntegrationFeedPostDto } from '@scaliolabs/baza-nc-integration-shared';

export class Feed {
    static readonly type = 'Feed';

    constructor(public lastId?: number) {}
}

export class ClearFeed {
    static readonly type = 'Clear Feed';
}

export class MarkAllAsRead {
    static readonly type = 'MarkAllAsRead';

    constructor(public lastSeenId: number) {}
}

export class Applause {
    static readonly type = 'Applause';

    constructor(public post: BazaNcIntegrationFeedPostDto) {}
}

export class Unapplause {
    static readonly type = 'Unapplause';

    constructor(public post: BazaNcIntegrationFeedPostDto) {}
}

export class FeedHasAnyUpdates {
    static readonly type = 'FeedHasAnyUpdates';
}
