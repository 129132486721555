import { IssuerId } from '../models/issuer-id';
import { IssuerType } from '../models/issuer-type';
import { NorthCapitalDate } from '../common-types';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class CreateOfferingRequest {
    @IsString()
    @IsNotEmpty()
    issuerId: IssuerId;

    @IsString()
    @IsNotEmpty()
    issueName: string;

    @IsEnum(IssuerType)
    @IsNotEmpty()
    issueType: IssuerType;

    @IsNumber()
    @IsNotEmpty()
    targetAmount: number;

    @IsNumber()
    @IsNotEmpty()
    minAmount: number;

    @IsNumber()
    @IsNotEmpty()
    maxAmount: number;

    @IsNumber()
    @IsNotEmpty()
    unitPrice: number;

    @IsString()
    @IsNotEmpty()
    startDate: NorthCapitalDate;

    @IsString()
    @IsNotEmpty()
    endDate: NorthCapitalDate;

    @IsString()
    @IsNotEmpty()
    offeringText: string;

    @IsString()
    @IsNotEmpty()
    stampingText: string;

    @IsString()
    @IsOptional()
    createdIPAddress?: NorthCapitalIpAddress;
}
