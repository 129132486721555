import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransactionUnifiedDto } from '@scaliolabs/sandbox-web/data-access';

@Component({
    selector: 'app-retry-nc-summary',
    templateUrl: './retry-nc-summary.component.html',
    styleUrls: ['./retry-nc-summary.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryNcSummaryComponent {
    @Input() transaction!: TransactionUnifiedDto;
}
