import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import type { AttachmentPayload } from '../models/attachment';
import { IsNotEmpty, IsObject, IsOptional, IsString } from 'class-validator';

export class AttachmentDto {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    s3ObjectId: string;

    @ApiModelProperty({
        description: 'Presigned time-limited url',
        required: false,
    })
    @IsString()
    @IsOptional()
    presignedUrl?: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    payload: AttachmentPayload;
}
