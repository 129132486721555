<div class="hero">
    <div class="container hero__container">
        <div
            nz-row
            nzGutter="30"
            class="hero__row">
            <div
                nz-col
                nzXs="12"
                class="hero__col hero__col-text">
                <div class="hero__text">
                    <div
                        class="hero__heading"
                        *ngIf="header"
                        [innerHtml]="header | sanitizeHtml"></div>
                    <div
                        class="hero__subheading"
                        *ngIf="description"
                        [innerHtml]="description | sanitizeHtml"></div>
                </div>

                <div class="hero__btn">
                    <button
                        nz-button
                        [class]="btnClass"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'btnCTA.label') | sanitizeHtml"
                        appLinkify
                        [linkConfigPath]="ctaLinkConfig"></button>
                </div>
            </div>
            <div
                nz-col
                nzXs="12"
                class="hero__col hero__col-img"></div>
        </div>
    </div>
</div>
