<nz-modal
    [nzTitle]="wts.getI18nLabel(i18nBasePath, 'title')"
    [nzAutofocus]="null"
    [nzClosable]="true"
    [nzFooter]="null"
    [nzVisible]="true"
    nzWrapClassName="payment-funds-notf"
    (nzOnCancel)="closeModal.emit()"
    #addFundsModalEl>
    <ng-container *nzModalContent>
        <div
            class="payment-funds__descr"
            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'content') | sanitizeHtml"></div>

        <button
            nz-button
            nzBlock
            nzSize="large"
            nzType="primary"
            (click)="closeModal.emit()">
            {{ wts.getI18nLabel(i18nBasePath, 'actions.accept') }}
        </button>
    </ng-container>
</nz-modal>
