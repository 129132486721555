import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from '@scaliolabs/baza-core-shared';
import { IsNotEmpty, IsObject, IsOptional, IsString } from 'class-validator';

export class BazaNcIntegrationListingCmsDocumentsDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Document Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Document Description',
    })
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty({
        type: AttachmentDto,
        isArray: true,
        description: 'Uploaded File',
        required: false,
    })
    @IsObject()
    @IsOptional()
    attachment?: AttachmentDto;

    @ApiModelProperty({
        type: String,
        description: 'Link to the file',
        required: false,
    })
    @IsString()
    @IsOptional()
    attachmentUrl?: string;
}
