import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcKycStatus } from '../../../account-verification';
import { BazaNcPurchaseFlowTransactionType } from '../../../purchase-flow';

export class BazaNcInvestorAccountStatusDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcKycStatus),
        description: 'KYC status for NC',
    })
    nc: BazaNcKycStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcKycStatus),
        description: 'KYC status for Dwolla',
    })
    dwolla: BazaNcKycStatus;

    @ApiModelProperty({
        type: 'string',
        description: 'Is Dwolla Customer created & available for Investor?',
    })
    isDwollaAvailable: boolean;

    @ApiModelProperty({
        type: 'boolean',
        description: 'Whether is a foreign investor or not',
    })
    isForeign: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        description: 'Default Payment Method',
        default: BazaNcPurchaseFlowTransactionType.ACH,
    })
    defaultPaymentMethod: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        type: 'boolean',
        description: 'Whether is a investor account has checked the Dwolla consent checkbox or not',
    })
    isDwollaConsentAccepted: boolean;
}
