import { OfferingId } from './offering-id';
import { EscrowAccountStatus } from './escrow-account-status';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';


export class EscrowAccountDetails {
    @IsNotEmpty()
    @IsString()
    offeringId: OfferingId;

    @IsEnum(EscrowAccountStatus)
    @IsNotEmpty()
    escrowAccountStatus: EscrowAccountStatus;
}
