import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Domicile } from '../../../transact-api';
import { AccountVerificationStep } from './../models/step.enum';

export class AccountVerificationFormResourcesStateDto {
    @ApiModelProperty({
        type: 'string',
    })
    title: string;

    @ApiModelProperty({
        type: 'string',
        description: 'State/Area. List of available states you may fetch with /list-states endpoint',
    })
    value: string;
}

export class AccountVerificationFormResourcesStateMappingDto {
    @ApiModelProperty({
        description: 'State',
    })
    state: string;

    @ApiModelProperty({
        description: 'Country',
    })
    country: string;
}

export class AccountVerificationFormResourcesCountryDto {
    @ApiModelProperty({
        type: 'boolean',
        description: 'In case of USA, do not display countries list',
    })
    isUSA: boolean;

    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    country: string;

    @ApiModelProperty({
        type: () => AccountVerificationFormResourcesStateDto,
        isArray: true,
    })
    states: Array<AccountVerificationFormResourcesStateDto>;
}

export class AccountVerificationFormResourcesCitizenshipLabelDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Domicile),
    })
    citizenship: Domicile;

    @ApiModelProperty({
        type: 'string',
    })
    label: string;
}

export class AccountVerificationFormResourcesDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountVerificationStep),
    })
    stepsOrder: Array<AccountVerificationStep>;

    @ApiModelProperty({
        type: () => AccountVerificationFormResourcesCitizenshipLabelDto,
        isArray: true,
    })
    citizenship: Array<AccountVerificationFormResourcesCitizenshipLabelDto>;

    @ApiModelProperty({
        type: () => AccountVerificationFormResourcesCountryDto,
        isArray: true,
        description: '[DEPRECATED] Usage of the field is deprecated since 3.4.3',
    })
    countries: Array<AccountVerificationFormResourcesCountryDto>;

    @ApiModelProperty({
        isArray: true,
        description: 'All available countries',
    })
    listCountries: Array<string>;

    @ApiModelProperty({
        type: () => AccountVerificationFormResourcesCountryDto,
        isArray: true,
        description:
            'List of prefetched states for residential address. By default returns list of U.S. state if no residential address set',
    })
    primCountryStates: Array<AccountVerificationFormResourcesStateDto>;

    @ApiModelProperty({
        required: false,
        type: AccountVerificationFormResourcesStateMappingDto,
        isArray: true,
        description: 'Additional contextual Country -> States mapping (for US states)',
    })
    statesMapping?: Array<AccountVerificationFormResourcesStateMappingDto>;
}
