import { BazaNcIntegrationFavoriteExcludeRequest, BazaNcIntegrationFavoriteIncludeRequest } from '@scaliolabs/baza-nc-integration-shared';

export class ClearItems {
    static readonly type = '[Item] Clear Items';
}

export class GetItem {
    static readonly type = '[Item] Get Item';

    constructor(public item: number | string) {}
}

export class GetItems {
    static readonly type = '[Item] Get Items';
}

export class ChangeItemsPage {
    static readonly type = '[Item] Change Items Page';

    constructor(public index: number) {}
}

export class GetFavorites {
    static readonly type = '[Item] Get Favorites';
}

export class AddFavorite {
    static readonly type = '[Item] Add Favorite';

    constructor(public itemRequest: BazaNcIntegrationFavoriteIncludeRequest) {}
}

export class RemoveFavorite {
    static readonly type = '[Item] Remove Favorite';

    constructor(public itemRequest: BazaNcIntegrationFavoriteExcludeRequest) {}
}

export class AddNotification {
    static readonly type = '[Item] Add Notification';

    constructor(public itemId: number) {}
}

export class RemoveNotification {
    static readonly type = '[Item] Remove Notification';

    constructor(public itemId: number) {}
}
