import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { generateRandomHexString } from '@scaliolabs/baza-core-shared';

export class DestroySessionDto {
    @ApiModelProperty({
        description: 'Session Lock Update Id. Use it to validate that your request is actually sent from same device',
        required: false,
        example: generateRandomHexString(8),
    })
    @IsString()
    @IsOptional()
    updateId?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    offeringId: string;
}
