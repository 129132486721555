import { NorthCapitalAccountId } from '../models/account-id';
import { OfferingId } from '../models/offering-id';
import { TradeId } from '../models/trade-id';
import { CloseId } from '../models/close-id';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalFloatAsString } from '../common-types/north-capital-float-as-string';

export class EditTradeRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsOptional()
    shares?: NorthCapitalFloatAsString;

    @IsString()
    @IsOptional()
    closeId?: CloseId;
}
