import { Application, BazaCore } from '@scaliolabs/baza-core-shared';
import { Injectable } from '@angular/core';
import { NgEnvironment } from './models/ng-environment';

@Injectable()
export class BazaNgCoreConfig {
    project: BazaCore.Project;
    application: Application;
    ngEnv: NgEnvironment;
    defaultMessageDurationMilliseconds: number;
    localStorageKeyPrefix: string;
    sessionStorageKeyPrefix: string;
}
