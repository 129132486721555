import { IsInt, IsOptional, IsPositive, IsString } from 'class-validator';

export class GetMassPaymentListForCustomerRequest {
    @IsPositive()
    @IsInt()
    @IsOptional()
    limit?: number;

    @IsPositive()
    @IsInt()
    @IsOptional()
    offset?: number;

    @IsString()
    @IsOptional()
    correlationId?: string;
}
