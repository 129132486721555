import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDeviceTokenType } from '../models/baza-device-token-type';

export class BazaDeviceTokenDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    accountId: number;

    @ApiModelProperty()
    createdAt: Date;

    @ApiModelProperty({
        description: 'Latest date where the device token was updated',
    })
    updatedAt: Date;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDeviceTokenType),
    })
    type: BazaDeviceTokenType;

    @ApiModelProperty({
        description: 'FCM / Apple device token',
    })
    token: string;
}
