import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingTestimonialDto } from '../dto/baza-nc-integration-listing-testimonial.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';

export enum BazaNcIntegrationListingTestimonialEndpointPaths {
    list = '/baza-nc-integration/listings/:ulid/testimonials/list',
    getByUlid = '/baza-nc-integration/listings/testimonials/:ulid',
}

export interface BazaNcIntegrationListingTestimonialEndpoint {
    list(
        listingUlid: string,
        request: BazaNcIntegrationListingTestimonialListRequest,
    ): Promise<BazaNcIntegrationListingTestimonialListResponse> | Observable<BazaNcIntegrationListingTestimonialListResponse>;
    getByUlid(ulid: string): Promise<BazaNcIntegrationListingTestimonialDto> | Observable<BazaNcIntegrationListingTestimonialDto>;
}

export class BazaNcIntegrationListingTestimonialListRequest extends CrudListRequestQueryDto {}

export class BazaNcIntegrationListingTestimonialListResponse extends CrudListResponseDto<BazaNcIntegrationListingTestimonialDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationListingTestimonialDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationListingTestimonialDto>;
}
