import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { DwollaAccountId } from '../models/account-id';

export class GetMassPaymentsForAccountRequest {
    @IsNotEmpty()
    @IsString()
    accountId: DwollaAccountId;

    @IsOptional()
    @IsPositive()
    @IsInt()
    limit?: number;

    @IsOptional()
    @IsPositive()
    @IsInt()
    offset?: number;
}
