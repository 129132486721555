import { CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { BazaAuthSessionAccountDto } from '../dto/baza-auth-session-account.dto';
import { BazaAuthSessionDto } from '../dto/baza-auth-session.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { AccountDto } from '../../../../baza-account/src';

export enum BazaAuthSessionCmsEndpointPaths {
    accounts = '/baza-auth-session/cms/accounts',
    sessions = '/baza-auth-session/cms/sessions',
}

export interface BazaAuthSessionCmsEndpoint {
    accounts(request: BazaAuthSessionCmsAccountsRequest): Promise<BazaAuthSessionCmsAccountsResponse> | Observable<BazaAuthSessionCmsAccountsResponse>;
    sessions(request: BazaAuthSessionCmsSessionsRequest): Promise<BazaAuthSessionCmsSessionsResponse> | Observable<BazaAuthSessionCmsSessionsResponse>;
}

export class BazaAuthSessionCmsAccountsRequest extends CrudListRequestDto<BazaAuthSessionAccountDto> {}

export class BazaAuthSessionCmsAccountsResponse extends CrudListResponseDto<BazaAuthSessionAccountDto> {
    @ApiModelProperty({
        type: BazaAuthSessionAccountDto,
        isArray: true,
    })
    items: Array<BazaAuthSessionAccountDto>;
}

export class BazaAuthSessionCmsSessionsRequest extends CrudListRequestDto<BazaAuthSessionDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export class BazaAuthSessionCmsSessionsResponse extends CrudListResponseDto<BazaAuthSessionDto> {
    @ApiModelProperty()
    account: AccountDto;

    @ApiModelProperty({
        type: BazaAuthSessionDto,
        isArray: true,
    })
    items: Array<BazaAuthSessionDto>;
}
