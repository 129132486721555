import { BazaMailTemplate } from '../baza-mail-template';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum BazaMailEndpointPaths {
    isReadyToUse = '/baza-core/mail/is-ready-to-use',
    mailTemplates = '/baza-core/mail/mail-templates',
    mailTemplatesOfTag = '/baza-core/mail/mail-templates/tag/:tag',
    mailTemplatesTags = '/baza-core/mail/mail-temglates/tags',
    mailTemplate = '/baza-core/mail/mail-templates/mail-template/:name',
}

export interface BazaMailEndpoint {
    isReadyToUse(): Promise<BazaMailIsReadyToUseResponse> | Observable<BazaMailIsReadyToUseResponse>;
    mailTemplates(): Promise<Array<BazaMailTemplate>> | Observable<Array<BazaMailTemplate>>;
    mailTemplatesOfTag(tag: string): Promise<Array<BazaMailTemplate>> | Observable<Array<BazaMailTemplate>>;
    mailTemplatesTags(): Promise<Array<string>> | Observable<Array<string>>;
    mailTemplate(name: string): Promise<BazaMailTemplate> | Observable<BazaMailTemplate>;
}

export class BazaMailIsReadyToUseResponse {
    @ApiModelProperty({
        description: 'Is Mail Transport fully configured to deliver emails?',
    })
    ready: boolean;

    @ApiModelProperty({
        description: 'Should 2FA be disabled due of insufficient Mail Transport configuration?',
    })
    disable2FA: boolean;
}
