import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class TextMultiSchemaDefinitionPayload extends SchemaDefinitionPayload {}

export class TextMultiSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.TextMulti],
    })
    type: BazaNcIntegrationSchemaType.TextMulti;

    @ApiModelProperty()
    payload: TextMultiSchemaDefinitionPayload;
}
