import { NorthCapitalAccountId } from '../models/account-id';
import { RiskProfile } from '../models/risk-profile';
import { InvestmentExperience } from '../models/investment-experience';
import { PrivOffExperience } from '../models/priv-off-experience';
import { NorthCapitalPercentage } from '../common-types/north-capital-percentage';
import { Education } from '../models/education';
import { NorthCapitalYesNoBoolean } from '../common-types';
import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsString, Max, Min } from 'class-validator';


export class CalculateSuitabilityRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsOptional()
    riskProfile?: RiskProfile;

    @IsEnum(InvestmentExperience)
    @IsOptional()
    investmentExperience?: InvestmentExperience;

    @IsEnum(PrivOffExperience)
    @IsOptional()
    privOffExperience?: PrivOffExperience;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctPrivSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctIlliquidSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctLiquidSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctRealEstate?: NorthCapitalPercentage;

    @IsInt()
    @IsOptional()
    timeHorizon?: number;

    @IsEnum(Education)
    @IsOptional()
    education?: Education;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    financialAdvisor?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsString()
    @IsOptional()
    investmentObjective?: string;

    @IsString()
    @IsOptional()
    additionalnotes?: string;
}
