import { BazaDwollaPaymentDto } from './baza-dwolla-payment.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountDto } from '@scaliolabs/baza-core-shared';
import { DwollaTransferId } from '../../../../dwolla-api/src';

export class BazaDwollaPaymentCmsDto extends BazaDwollaPaymentDto {
    @ApiModelProperty({
        description: 'Account',
    })
    account: AccountDto;

    @ApiModelProperty({
        description: 'Dwolla Transfer Id',
        type: 'string',
        example: 'c6a9f41b-0b7b-ed11-814a-d69963ad6ab7',
    })
    dwollaTransferId: DwollaTransferId;

    @ApiModelProperty({
        description: 'Dwolla Transfer Id of related ACH Transfer. Only for Dwolla Payments with ACH channel',
        type: 'string',
        example: 'e1eca779-0a7b-ed11-814a-d69963ad6ab7',
        required: false,
    })
    achDwollaTransferId?: DwollaTransferId;
}
