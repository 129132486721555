import { NgModule } from '@angular/core';
import { BlogCmsDataAccess } from './blog/blog-cms.data-access';
import { BlogDataAccess } from './blog/blog.data-access';
import { CategoriesCmsDataAccess } from './categories/categories-cms.data-access';
import { CategoriesDataAccess } from './categories/categories.data-access';
import { ContactsCmsDataAccess } from './contacts/contacts-cms.data-access';
import { ContactsSubjectCmsDataAccess } from './contacts/contacts-subject-cms.data-access';
import { ContactsDataAccess } from './contacts/contacts.data-access';
import { EventsCmsDataAccess } from './events/events-cms.data-access';
import { EventsDataAccess } from './events/events.data-access';
import { FaqCmsDataAccess } from './faq/faq-cms.data-access';
import { FaqDataAccess } from './faq/faq.data-access';
import { JobApplicationsDataAccess } from './jobs/job-applications.data-access';
import { JobsApplicationCmsDataAccess } from './jobs/jobs-application-cms.data-access';
import { JobsCmsDataAccess } from './jobs/jobs-cms.data-access';
import { JobsDataAccess } from './jobs/jobs.data-access';
import { NewsCmsDataAccess } from './news/news-cms.data-access';
import { NewsDataAccess } from './news/news.data-access';
import { NewslettersCmsDataAccess } from './newsletters/newsletters-cms.data-access';
import { NewslettersDataAccess } from './newsletters/newsletters.data-access';
import { PagesCmsDataAccess } from './pages/pages-cms.data-access';
import { PagesDataAccess } from './pages/pages.data-access';
import { TagsCmsDataAccess } from './tags/tags-cms.data-access';
import { TagsDataAccess } from './tags/tags.data-access';
import { TeamsMembersCmsDataAccess } from './teams/teams-members-cms.data-access';
import { TeamsMembersDataAccess } from './teams/teams-members.data-access';
import { TestimonialsCmsDataAccess } from './testimonials/testimonials-cms.data-access';
import { TestimonialsDataAccess } from './testimonials/testimonials.data-access';
import { TimelineCmsDataAccess } from './timeline/timeline-cms.data-access';
import { TimelineDataAccess } from './timeline/timeline.data-access';
import { MktPagesCmsDataAccess } from './mkt-pages/mkt-pages-cms.data-access';
import { MktPagesDataAccess } from './mkt-pages/mkt-pages.data-access';
import { BazaDataAccessModule } from '@scaliolabs/baza-core-data-access';

@NgModule({
    imports: [BazaDataAccessModule],
    providers: [
        CategoriesDataAccess,
        CategoriesCmsDataAccess,
        ContactsDataAccess,
        ContactsCmsDataAccess,
        ContactsSubjectCmsDataAccess,
        FaqDataAccess,
        FaqCmsDataAccess,
        NewslettersDataAccess,
        NewslettersCmsDataAccess,
        TeamsMembersDataAccess,
        TeamsMembersCmsDataAccess,
        TestimonialsDataAccess,
        TestimonialsCmsDataAccess,
        BlogDataAccess,
        BlogCmsDataAccess,
        EventsDataAccess,
        EventsCmsDataAccess,
        JobsDataAccess,
        JobApplicationsDataAccess,
        JobsApplicationCmsDataAccess,
        JobsCmsDataAccess,
        NewsDataAccess,
        NewsCmsDataAccess,
        PagesDataAccess,
        PagesCmsDataAccess,
        TagsDataAccess,
        TagsCmsDataAccess,
        TimelineDataAccess,
        TimelineCmsDataAccess,
        MktPagesCmsDataAccess,
        MktPagesDataAccess,
    ],
})
export class BazaContentTypesDataAccessModule {}
