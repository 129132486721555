import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import {
    BazaNcDividendPaymentHistoryActions,
    bazaNcDividendPaymentHistoryActionsSwagger,
} from '../models/baza-nc-dividend-payment-history-action';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcDividendHistoryDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        ...bazaNcDividendPaymentHistoryActionsSwagger(),
        description: 'Action & Action Details',
    })
    action: BazaNcDividendPaymentHistoryActions;
}
