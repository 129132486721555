import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountDto, Application, BazaRegistryPublicSchema, clientApplications, RegistryPublicNode } from '@scaliolabs/baza-core-shared';
import { BazaNcInvestorAccountDto } from '../../../investor-account';
import { BazaNcOfferingRegulationType } from '../../../offering';

export class BazaNcBootstrapDto {
    @ApiModelProperty({
        type: RegistryPublicNode,
        isArray: true,
    })
    registry: BazaRegistryPublicSchema;

    @ApiModelProperty({
        description: 'Account',
        required: false,
    })
    account?: AccountDto;

    @ApiModelProperty({
        description: 'Investor Account',
        required: false,
    })
    investorAccount?: BazaNcInvestorAccountDto;

    @ApiModelProperty({
        description: 'Client (application) used to register account',
        enum: [...clientApplications],
        required: false,
    })
    signedUpWithClient?: Application;

    @ApiModelProperty({
        description: 'Referral Code',
        required: false,
    })
    referralCode?: string;

    @ApiModelProperty({
        description: 'Referral Code (Copy-Paste text)',
        required: false,
    })
    referralCodeCopyText?: string;

    @ApiModelProperty({
        description: 'Referral Code used to register account',
        required: false,
    })
    signedUpWithReferralCode?: string;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        required: false,
        description: 'array of regulation types for offerings which are supported by the application',
    })
    supportedNcRegulationTypes?: Array<BazaNcOfferingRegulationType>;

    @ApiModelProperty({
        type: 'boolean',
        required: false,
        description: 'will be true if the investor already bought any shares regardless of the status of the trades',
    })
    isAnyAssetPurchased?: boolean;
}
