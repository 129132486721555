import { Observable } from 'rxjs';
import { DwollaEventTopic, LinkEntity } from '../../../../dwolla-api/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsIn, IsObject, IsString } from 'class-validator';

export enum BazaDwollaWebhookEndpointPaths {
    accept = '/baza-dwolla/webhooks/accept',
}

export interface BazaDwollaWebhookEndpoint {
    accept(request: BazaDwollaWebhookDto, ...args: unknown[]): Promise<void> | Observable<void>;
}

export class BazaDwollaWebhookDto {
    @ApiModelProperty()
    @IsString()
    id: string;

    @ApiModelProperty()
    @IsString()
    resourceId: string;

    @ApiModelProperty()
    @IsIn(Object.values(DwollaEventTopic))
    topic: DwollaEventTopic;

    @ApiModelProperty()
    @IsString()
    timestamp: string; // ISO Date (Example: 2022-07-11T19:14:18.776Z)

    @ApiModelProperty()
    @IsString()
    created: string; // ISO Date (Example: 2022-07-11T19:14:18.776Z)

    @ApiModelProperty()
    @IsObject()
    _links: LinkEntity;
}
