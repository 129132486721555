import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { GetServicePage } from './actions';
import { PageDto } from '@scaliolabs/baza-content-types-shared';
import { PagesDataAccess } from '@scaliolabs/baza-content-types-data-access';

export interface ServicePagesStateModel {
    servicePage: PageDto;
}

@Injectable()
@State<ServicePagesStateModel>({
    name: 'servicePages',
    defaults: {
        servicePage: undefined,
    },
})
export class ServicePagesState {
    constructor(private readonly effectsUtil: EffectsUtil, private readonly pagesDataAccess: PagesDataAccess) {}

    @Selector()
    static servicePage(state: ServicePagesStateModel) {
        return state.servicePage;
    }

    @Action(GetServicePage)
    getServicePage(ctx: StateContext<ServicePagesStateModel>, action: GetServicePage) {
        return this.pagesDataAccess.get(action.url).pipe(
            tap((response) => {
                return ctx.patchState({
                    servicePage: response,
                });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
