export const WebUiPaymentCardEnI18n = {
    addMode: {
        title: 'Card',
        feePrefix: '',
        feeSuffix: 'Card processing fee',
    },
    editMode: {
        title: 'Card',
        expiring: 'Expiring',
        cardDetails: {
            number: 'Card number:',
            expiring: 'Expiring:',
            feeSuffix: 'Card processing fee',
        },
    },
    alerts: {
        limitsWarning: 'Payment transactions above {{ maxAmount }} require ACH via linked bank account',
    },
};
