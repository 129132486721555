import { Injectable } from '@angular/core';
import { BlogDto, BlogEndpoint, BlogEndpointPaths, BlogListRequest, BlogListResponse } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class BlogDataAccess implements BlogEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    list(request: BlogListRequest): Observable<BlogListResponse> {
        return this.http.post(BlogEndpointPaths.list, request);
    }

    getById(id: number): Observable<BlogDto> {
        return this.http.get(BlogEndpointPaths.getById, { id });
    }
}
