export enum AuthErrorCodes {
    AuthEmailIsNotVerified = 'AuthEmailIsNotVerified',
    AuthInvalidCredentials = 'AuthInvalidCredentials',
    AuthInvalidJwt = 'AuthInvalidJwt',
    AuthInvalidRefreshTokenJwt = 'AuthInvalidRefreshTokenJwt',
    AuthJwtExpired = 'AuthJwtExpired',
    AuthSessionIsNotAvailable = 'AuthSessionIsNotAvailable',
    AuthAdminRoleIsRequired = 'AuthAdminRoleIsRequired',
    AuthRequiredRoleMismatch = 'AuthRequiredRoleMismatch',
    AuthAccountResetPasswordRequested = 'AuthAccountResetPasswordRequested',
    AuthInvalidRecaptchaToken = 'AuthInvalidRecaptchaToken',
    AuthUnknownDeviceStrategy = 'AuthUnknownDeviceStrategy',
    AuthRedisStrategyFail = 'AuthRedisStrategyFail',
    AuthAdminIsNotAllowedToSignInWebApp = 'AuthAdminIsNotAllowedToSignInWebApp',
}
