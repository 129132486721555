import { Directive, Input } from '@angular/core';
import { MktSectionInputInterface } from './mkt-section.interface';

@Directive()
export class MktSectionCommonFields implements MktSectionInputInterface {
    @Input()
    label?: string;

    @Input()
    header?: string;

    @Input()
    description?: string;
}
