import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestType } from '../enums/http-request-type.enum';
import { curry, isEmpty, isObject, isString, transform, trim } from 'lodash';

@Injectable()
export class WhiteSpaceInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.method !== HttpRequestType.GET && req.method !== HttpRequestType.DELETE && req.body && !isEmpty(req.body)) {
            // recursively find & trim all whitespaces from objects/arrays and complex structures e.g. nested objects using lodash
            const recursiveMapValues = curry((fn, obj) =>
                transform(obj, (acc, value, key) => {
                    if (isObject(value)) {
                        acc[String(key)] = recursiveMapValues(fn, value);
                    } else if (isString(value)) {
                        acc[String(key)] = fn(value);
                    } else {
                        acc[String(key)] = value;
                    }
                }),
            );
            const trimmedRequestBody = isString(req.body) ? trim(req.body) : recursiveMapValues(trim, req.body);
            req = req.clone({
                body: trimmedRequestBody,
            });
        }

        return next.handle(req);
    }
}
