import { TimelineDto } from '../dto/timeline.dto';
import { Observable } from 'rxjs';

export enum TimelineEndpointPaths {
    getAll = '/baza-content-types/timeline',
    getById = '/baza-content-types/timeline/:id',
}

export interface TimelineEndpoint {
    getAll(): Promise<Array<TimelineDto>> | Observable<TimelineDto>;
    getById(id: number): Promise<TimelineDto> | Observable<TimelineDto>;
}
