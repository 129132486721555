import { NgModule } from '@angular/core';
import { BazaMaintenanceCmsDataAccess } from './ng/baza-maintenance-cms.data-access';
import { BazaCmsDataAccessModule } from '../../../../ng/src';

// @dynamic
// @dynamic
@NgModule({
    imports: [BazaCmsDataAccessModule],
    providers: [BazaMaintenanceCmsDataAccess],
})
export class BazaMaintenanceCmsDataAccessModule {}
