import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DwollaAmount } from '@scaliolabs/baza-dwolla-shared';
import { IsBoolean, IsNotEmpty, IsObject, ValidateNested } from 'class-validator';

export class BazaNcDwollaCustomerDetailDto {
    @ApiModelProperty({
        description: 'To identify whether dwolla customer is created for an investor account',
    })
    @IsBoolean()
    @IsNotEmpty()
    isDwollaAvailable: boolean;

    @ApiModelProperty({
        description: 'Dwolla wallet balance',
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    balance: DwollaAmount;
}
