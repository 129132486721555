import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaDataAccessConfig } from './baza-data-access.config';

interface ForRootOptions {
    injects: Array<unknown>;
    useFactory: (...args: Array<unknown>) => BazaDataAccessConfig;
}

export { ForRootOptions as BazaNgEndpointModuleForRootOptions };

@NgModule({
    providers: [],
})
export class BazaDataAccessModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaDataAccessModule> {
        return {
            ngModule: BazaDataAccessModule,
            providers: [
                {
                    provide: BazaDataAccessConfig,
                    useFactory: (...injects) => options.useFactory(...injects),
                },
            ],
        };
    }
}
