export class BazaContentTypeTimelineConfig {
    withTagsSupport: boolean;
    withCategoriesSupport: boolean;
    withTextDescription: boolean;
    withHTMLDescription: boolean;
    withImageSupport: boolean;
    withSEO: boolean;
    withMetadata: boolean;
    sortOrder: 'ASC' | 'DESC';
}

export function bazaContentTypeTimelineDefaultConfig(): BazaContentTypeTimelineConfig {
    return {
        withTagsSupport: true,
        withCategoriesSupport: true,
        withTextDescription: true,
        withHTMLDescription: true,
        withMetadata: true,
        withImageSupport: true,
        withSEO: true,
        sortOrder: 'ASC',
    };
}
