import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { TimelineDto, TimelineEndpoint, TimelineEndpointPaths } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class TimelineDataAccess implements TimelineEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  getAll(): Observable<TimelineDto> {
    return this.http.get(TimelineEndpointPaths.getAll);
  }

  getById(id: number): Observable<TimelineDto> {
    return this.http.get(replacePathArgs(TimelineEndpointPaths.getById, { id }));
  }
}
