import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class BazaNcIntegrationListingDocumentsDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Document Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Document Description',
    })
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Document Attachment URL',
        required: false,
    })
    @IsString()
    @IsOptional()
    documentUrl?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Link Attachment URL',
        required: false,
    })
    @IsString()
    @IsOptional()
    attachmentUrl?: string;
}
