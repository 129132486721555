export class BazaContentTypeNewsConfig {
    maxFullImageWidth: number;
    maxPreviewImageWidth: number;
}

export function bazaContentTypeNewsDefaultConfig(): BazaContentTypeNewsConfig {
    return {
        maxFullImageWidth: 720,
        maxPreviewImageWidth: 320,
    };
}
