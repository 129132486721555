import { Observable } from 'rxjs';
import { AccountVerificationDto } from '../dto/account-verification.dto';
import { AccountVerificationFormResourcesDto } from '../dto/form-resources.dto';
import { ListStatesRequestDto } from '../dto/list-states-request.dto';
import { ListStatesResponseDto } from '../dto/list-states-response.dto';
import { AccountVerificationIsCompletedDto } from '../dto/is-completed.dto';
import { AccountVerificationInvestorProfileDto } from '../dto/investor-profile.dto';
import { PersonalInformationApplyRequest } from '../dto/personal-information-apply-request';

export enum BazaNcAccountVerificationEndpointPaths {
    index = '/baza-nc/account-verification',
    formResources = '/baza-nc/account-verification/formResources',
    listStates = '/baza-nc/account-verification/listStates',
    isCompleted = '/baza-nc/account-verification/isCompleted',
    uploadPersonalInformationSSNDocument = '/baza-nc/account-verification/uploadPersonalInformationSSNDocument',
    uploadPersonalInformationDocument = '/baza-nc/account-verification/uploadPersonalInformationDocument',
    usePreviouslyUploadedDocument = '/baza-nc/account-verification/usePreviouslyUploadedDocument',
    applyPersonalInformation = '/baza-nc/account-verification/applyPersonalInformation',
    applyInvestorProfile = '/baza-nc/account-verification/applyInvestorProfile',
}

export interface BazaNcAccountVerificationEndpoint {
    index(...args: unknown[]): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
    formResources(...args: unknown[]): Promise<AccountVerificationFormResourcesDto> | Observable<AccountVerificationFormResourcesDto>;
    listStates(request: ListStatesRequestDto): Promise<ListStatesResponseDto> | Observable<ListStatesResponseDto>;
    isCompleted(...args: unknown[]): Promise<AccountVerificationIsCompletedDto> | Observable<AccountVerificationIsCompletedDto>;
    uploadPersonalInformationSSNDocument(
        file: File | unknown,
        ...args: unknown[]
    ): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
    uploadPersonalInformationDocument(
        file: File | unknown,
        ...args: unknown[]
    ): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
    usePreviouslyUploadedDocument(...args: unknown[]): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
    applyPersonalInformation(
        request: PersonalInformationApplyRequest,
        ...args: unknown[]
    ): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
    applyInvestorProfile(
        request: AccountVerificationInvestorProfileDto,
        ...args: unknown[]
    ): Promise<AccountVerificationDto> | Observable<AccountVerificationDto>;
}
