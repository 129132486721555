<div class="invest-card">
    <nz-card [nzBordered]="false">
        <div class="invest-card__index">
            <div class="invest-card__icon">
                <svg-icon-sprite [src]="icon ?? ''"></svg-icon-sprite>
            </div>
        </div>

        <div class="invest-card__text">
            <div
                class="invest-card__title"
                *ngIf="header"
                [innerHtml]="header | sanitizeHtml"></div>
            <div
                class="invest-card__descr"
                *ngIf="description"
                [innerHtml]="description | sanitizeHtml"></div>
        </div>
    </nz-card>
</div>
