<nz-modal
    nzTitle="Summary"
    [nzAutofocus]="null"
    [nzClosable]="true"
    [nzFooter]="null"
    [nzVisible]="true"
    (nzOnCancel)="onClosed()">
    <ng-container *nzModalContent>
        <app-retry-nc-summary [transaction]="transaction"></app-retry-nc-summary>

        <ng-container *ngIf="dataStream$ | async as data; else skeleton">
            <app-retry-nc-list
                [bankAccountResponse]="data.bank"
                [creditCardResponse]="data.card"
                [limits]="data.limits"
                [selectedPaymentMethod]="selectedPaymentMethod"
                (paymentMethodChange)="onPaymentMethodChange($event)"></app-retry-nc-list>

            <a
                class="btn-retry-secondary"
                nz-button
                [routerLink]="['/account', 'payment-method']">
                Edit
            </a>

            <button
                class="btn-retry-primary"
                nz-button
                nzBlock
                nzSize="large"
                nzType="primary"
                (click)="onPay()">
                Pay now
            </button>
        </ng-container>
    </ng-container>
</nz-modal>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
