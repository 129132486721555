import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import { DestroySessionDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, LEGACY_NC_MODE_QUERY_PARAM } from '@scaliolabs/baza-web-utils';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CancelPurchase, PFConfig, PurchaseState, SetPFConfig } from './data-access';

@UntilDestroy()
@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseComponent implements OnInit {
    @Input()
    dwollaSupport = true;

    cart$ = this.store.select(PurchaseState.cart);
    currentTab: number;
    i18nBasePath = 'dwpf.parent';
    config: PFConfig;
    showLeaveConfirmationModal = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly notification: NzNotificationService,
        private readonly router: Router,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnInit(): void {
        this.checkLinksConfig(this.store.selectSnapshot(PurchaseState.cart)?.id);

        this.route.data.pipe(untilDestroyed(this)).subscribe((params: Params) => {
            this.currentTab = Number(params.tab) || 0;

            if (params.numberOfShares === null) {
                this.notification.warning(this.wts.getI18nLabel(this.i18nBasePath, 'warnings.pickShares'), '');
                this.router.navigate([this.config.pfLink, 'details'], {
                    queryParams: {
                        back: this.config?.back,
                        mode: !this.dwollaSupport && this.route.snapshot?.queryParams?.mode ? LEGACY_NC_MODE_QUERY_PARAM : null,
                    },
                });
            }
        });
    }

    cancelPurchase(item: BazaNcIntegrationListingsDto) {
        const data: DestroySessionDto = {
            offeringId: item.offeringId,
        };
        this.store.dispatch(new CancelPurchase(data));
    }

    checkLinksConfig(itemId?: number) {
        const routeQueryParams = this.route.snapshot?.queryParams;
        this.config = {
            pfLink: this.wts.getI18nLabel(this.i18nBasePath, 'links.pfLink'),
            vfLink: this.wts.getI18nLabel(this.i18nBasePath, 'links.vfLink'),
            back: routeQueryParams.back ?? this.wts.getI18nLabel(this.i18nBasePath, 'links.defaultRedirect', { id: itemId }),
            dwollaSupport: this.dwollaSupport,
        };
        this.store.dispatch(new SetPFConfig(this.config));
    }

    onConfirm() {
        this.router.navigateByUrl(this.config.back);
    }
}
