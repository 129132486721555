<div class="investor-profile">
    <div
        nz-row
        class="investor-profile__row">
        <div
            nz-col
            nzXs="12"
            class="investor-profile__col"
            *ngIf="initData?.investorAccount?.isAccountVerificationCompleted; else noAccountData">
            <div class="info-header">
                <div
                    class="info-header__title"
                    [innerHTML]="wts.getI18nLabel(i18nBasePath, 'title') | sanitizeHtml"></div>

                <div class="info-header__icon">
                    <a
                        nz-popover
                        nzPopoverPlacement="right"
                        [nzPopoverContent]="editPopoverContent">
                        <i class="icon icon-edit icon-wt"></i>
                    </a>
                    <ng-template #editPopoverContent>
                        <div [innerHTML]="wts.getI18nLabel(i18nBasePath, 'editPopoverLabel') | sanitizeHtml"></div>
                    </ng-template>
                </div>
            </div>

            <div
                class="investor-profile__kyc"
                *ngIf="verification?.kycStatus === KycStatus.Disapproved">
                <app-kyc-disapproved-alert (redirectCTAClicked)="redirectCTAClicked.emit()"></app-kyc-disapproved-alert>
            </div>

            <ng-container *ngIf="verification?.personalInformation as personalInformation">
                <div
                    class="investor-profile__subheading"
                    [innerHTML]="geti18nSectionTitle('personal') | sanitizeHtml"></div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('personal', 'name') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="fullName | sanitizeHtml"></div>
                </div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('personal', 'dob') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="verification?.personalInformation.dateOfBirth | sanitizeHtml"></div>
                </div>

                <div class="info-block">
                    <ng-container [ngSwitch]="personalInformation.ssn !== null">
                        <ng-container *ngSwitchCase="true">
                            <div
                                class="info-block__heading"
                                [innerHtml]="geti18nBlockLabel('personal', 'ssn') | sanitizeHtml"></div>
                            <div
                                class="info-block__descr"
                                [innerHtml]="verification?.personalInformation.ssn | sanitizeHtml"></div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div
                                class="info-block__heading"
                                [innerHtml]="geti18nBlockLabel('personal', 'uploaded-doc') | sanitizeHtml"></div>
                            <div class="info-block__descr">
                                <a
                                    [href]="personalInformation.ssnDocumentUrl"
                                    target="_blank">
                                    {{ personalInformation.ssnDocumentFileName }}
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('personal', 'phone') | sanitizeHtml"></div>
                    <div *ngIf="personalInformation.phone">
                        (+{{ personalInformation.phoneCountryCode }}) {{ personalInformation.phone }}
                    </div>
                </div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('personal', 'citizenship') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="personalInformation.citizenship | capitalize | sanitizeHtml"></div>
                </div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('personal', 'address') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="personalInformation.residentialCountry | capitalize | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="addressLineOne | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="addressLineTwo | sanitizeHtml"></div>
                </div>
            </ng-container>

            <nz-divider></nz-divider>

            <ng-container *ngIf="verification?.investorProfile as investorProfile">
                <div
                    class="investor-profile__subheading"
                    [innerHTML]="geti18nSectionTitle('investor') | sanitizeHtml"></div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('investor', 'accredited-status') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="investorProfile.isAccreditedInvestor ? 'Yes' : 'No'"></div>
                </div>

                <div class="info-block">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('investor', 'finra-association') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="investorProfile.isAssociatedWithFINRA ? 'Yes' : 'No'"></div>
                </div>

                <div
                    class="info-block"
                    *ngIf="wts.appSupportsRegCF(initData) && investorProfile.finraRelationship">
                    <div
                        class="info-block__heading"
                        [innerHtml]="geti18nBlockLabel('investor', 'finra-relation') | sanitizeHtml"></div>
                    <div
                        class="info-block__descr"
                        [innerHtml]="investorProfile.finraRelationship | sanitizeHtml"></div>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-template #noAccountData>
        <ng-container [ngSwitch]="initData?.investorAccount?.isAccountVerificationInProgress">
            <ng-container *ngSwitchCase="true">
                <nz-empty
                    class="info-empty"
                    [nzNotFoundContent]="titleTpl"
                    [nzNotFoundImage]="imageTpl"
                    [nzNotFoundFooter]="footerTpl">
                    <ng-template #imageTpl>
                        <svg-icon-sprite src="investor-profile-update"></svg-icon-sprite>
                    </ng-template>

                    <ng-template #titleTpl>
                        <div
                            class="info-empty__title"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.title')"></div>
                    </ng-template>
                    <ng-template #footerTpl>
                        <div
                            class="info-empty__descr"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.descr')"></div>
                        <button
                            nz-button
                            class="info-empty__btn"
                            (click)="redirectCTAClicked.emit()">
                            {{ wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.btnCTA.label') }}
                        </button>
                    </ng-template>
                </nz-empty>
            </ng-container>

            <ng-container *ngSwitchCase="false">
                <nz-empty
                    class="info-empty"
                    [nzNotFoundContent]="titleTpl"
                    [nzNotFoundImage]="imageTpl"
                    [nzNotFoundFooter]="footerTpl">
                    <ng-template #imageTpl>
                        <svg-icon-sprite src="investor-profile-empty"></svg-icon-sprite>
                    </ng-template>

                    <ng-template #titleTpl>
                        <div
                            class="info-empty__title"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.new.title')"></div>
                    </ng-template>
                    <ng-template #footerTpl>
                        <div
                            class="info-empty__descr"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.new.descr')"></div>
                        <button
                            nz-button
                            class="info-empty__btn"
                            (click)="redirectCTAClicked.emit()">
                            {{ wts.getI18nLabel(i18nBasePath, 'emptyStates.new.btnCTA.label') }}
                        </button>
                    </ng-template>
                </nz-empty>
            </ng-container>
        </ng-container>
    </ng-template>
</div>
