import { BazaContentTypes } from '../baza-content-types';
import { bazaContentTypeCategoriesCmsI18n } from '../../../../libs/categories/src';
import { bazaContentTypesContactsCmsEn18n } from '../../../../libs/contacts/src';
import { bazaContentTypesFaqCmsEn18n } from '../../../../libs/faq/src';
import { bazaContentTypesNewslettersCmsEnI18n } from '../../../../libs/newsletters/src';
import { bazaContentTypesTeamsCmsI18n } from '../../../../libs/teams/src';
import { bazaContentTypesTestimonialsCmsI18n } from '../../../../libs/testimonials/src';
import { bazaContentTypesBlogCmsI18n } from '../../../../libs/blog/src';
import { bazaContentTypesTagsCmsI18n } from '../../../../libs/tags/src';
import { bazaContentTypesNewsCmsI18n } from '../../../../libs/news/src';
import { bazaContentTypesJobsCmsI18n } from '../../../../libs/jobs/src';
import { bazaContentTypesEventsCmsI18n } from '../../../../libs/events/src';
import { bazaContentTypesPagesCmsI18n } from '../../../../libs/pages/src';
import { bazaContentTypesAclI18n } from './baza-content-types-acl.i18n';
import { bazaContentTypesTimelineCmsI18n } from '../../../../libs/timeline/src';
import { bazaContentTypesMktPageCmsI18n } from '../../../../libs/mkt-pages/src';

export const bazaContentTypesCmsI18n = {
    __acl: {
        ...bazaContentTypesAclI18n,
    },
    bazaContentTypes: {
        menu: {
            contentTypes: 'Content Types',
            taxonomy: 'Taxonomy',
            formData: 'Form Data',
        },
        [BazaContentTypes.Blogs.toLowerCase()]: bazaContentTypesBlogCmsI18n,
        [BazaContentTypes.Categories.toLowerCase()]: bazaContentTypeCategoriesCmsI18n,
        [BazaContentTypes.Contacts.toLowerCase()]: bazaContentTypesContactsCmsEn18n,
        [BazaContentTypes.Events.toLowerCase()]: bazaContentTypesEventsCmsI18n,
        [BazaContentTypes.Faq.toLowerCase()]: bazaContentTypesFaqCmsEn18n,
        [BazaContentTypes.Jobs.toLowerCase()]: bazaContentTypesJobsCmsI18n,
        [BazaContentTypes.News.toLowerCase()]: bazaContentTypesNewsCmsI18n,
        [BazaContentTypes.Feed.toLowerCase()]: {
            title: 'Feed',
        },
        [BazaContentTypes.Newsletters.toLowerCase()]: bazaContentTypesNewslettersCmsEnI18n,
        [BazaContentTypes.Pages.toLowerCase()]: bazaContentTypesPagesCmsI18n,
        [BazaContentTypes.Press.toLowerCase()]: {
            title: 'Press',
        },
        [BazaContentTypes.Marketing.toLowerCase()]: bazaContentTypesMktPageCmsI18n,
        [BazaContentTypes.Teams.toLowerCase()]: bazaContentTypesTeamsCmsI18n,
        [BazaContentTypes.Testimonials.toLowerCase()]: bazaContentTypesTestimonialsCmsI18n,
        [BazaContentTypes.Tags.toLowerCase()]: bazaContentTypesTagsCmsI18n,
        [BazaContentTypes.Timeline.toLowerCase()]: bazaContentTypesTimelineCmsI18n,
    },
};
