import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PaymentEditModule } from '@scaliolabs/baza-web-purchase-flow';
import { PaymentRadioComponent } from '@scaliolabs/baza-web-ui-components';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RetryNcListComponent } from './retry-nc-list/retry-nc-list.component';
import { RetryNcPaymentComponent } from './retry-nc-payment.component';
import { RetryNcSummaryComponent } from './retry-nc-summary/retry-nc-summary.component';

const COMPONENTS = [RetryNcPaymentComponent, RetryNcSummaryComponent, RetryNcListComponent];
const NZ_MODULES = [NzModalModule, NzButtonModule, NzCollapseModule, NzToolTipModule, NzPopoverModule, NzSkeletonModule];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [CommonModule, RouterModule, PaymentRadioComponent, PaymentEditModule, UtilModule, ...NZ_MODULES],
    exports: [...COMPONENTS],
})
export class RetryNcPaymentModule {}
