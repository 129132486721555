export enum BazaNcIntegrationFeedListFilter {
    // Display only public posts which has no associated listings
    PublicOnly = 'public-only',

    // Display only posts which has associated listings
    ListingOnly = 'listing-only',

    // Display only posts which has associated listings with purchases shares by current user
    PurchasedOnly = 'purchased-only',
}
