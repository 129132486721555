export const PayoutBankEnI18n = {
    title: 'Bank Account',
    hint: 'Used to withdraw funds from your wallet',
    alerts: {
        addPayoutMethod: 'Add a bank account in your Payout Method to receive dividends',
        withdrawalSupport: {
            label: 'Reach out to us at <a data-link="withdrawalLink"></a> to get instructions on withdrawal.',
            linkConfig: {
                key: 'withdrawalLink',
                link: {
                    extLink: {
                        link: 'bazaContentTypes.contacts.email',
                        isCMSLink: true,
                        isMailLink: true,
                    },
                },
            },
        },
        linkBank: 'You need to have linked bank account to withdraw from your balance.',
        walletSupport: {
            label: 'There was a failed attempt to create a Wallet. Please <a data-link="walletLink"></a> for technical support.',
            linkConfig: {
                key: 'walletLink',
                link: {
                    extLink: {
                        link: 'bazaContentTypes.contacts.email',
                        isCMSLink: true,
                        isMailLink: true,
                        text: 'contact us',
                    },
                },
            },
        },
    },
    actions: {
        update: 'Update',
    },
};
