import { BazaNcBankAccountDto } from '../dto/baza-nc-bank-account.dto';
import { ulid } from 'ulid';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsEnum, IsIn, IsNotEmpty, IsObject, IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { AccountTypeCheckingSaving } from '../../../transact-api';
import { BazaNcBankAccountType } from '../models/baza-nc-bank-account-type';
import { Observable } from 'rxjs';
import { BazaNcBankAccountErrorCodes } from '../error-codes/baza-nc-bank-account.error-codes';
import { Transform } from 'class-transformer';
import { BazaNcBankAccountExport } from '../models/baza-nc-bank-account-export';
import { BazaPlaidLinkRequestDto, BazaPlaidLinkResponseDto } from '@scaliolabs/baza-plaid-shared';
import { BazaNcBankAccountAchDto } from '../dto/baza-nc-bank-account-ach.dto';
import { BazaNcBankAccountIdentity } from '../models/baza-nc-bank-account-identity';

export enum BazaNcBankAccountEndpointPaths {
    list = '/baza-nc/bank-account/list',
    default = '/baza-nc/bank-account/defaults/:type',
    defaults = '/baza-nc/bank-account/defaults',
    get = '/baza-nc/bank-account/get/ulid/:ulid',
    add = '/baza-nc/bank-account/add',
    link = '/baza-nc/bank-account/link',
    linkOnSuccess = '/baza-nc/bank-account/linkOnSuccess',
    remove = '/baza-nc/bank-account/remove/:ulid',
    set = '/baza-nc/bank-account/set',
    export = '/baza-nc/bank-account/export',
    ncAchBankAccount = '/baza-nc/bank-account/ncAchBankAccount',
}

export interface BazaNcBankAccountEndpoint {
    list(...args: unknown[]): Promise<Array<BazaNcBankAccountDto>> | Observable<Array<BazaNcBankAccountDto>>;
    defaults(...args: unknown[]): Promise<Array<BazaNcBankAccountDto>> | Observable<Array<BazaNcBankAccountDto>>;
    default(
        type: BazaNcBankAccountType,
        request: BazaNcBankAccountGetDefaultByTypeRequest,
        ...args: unknown[]
    ): Promise<BazaNcBankAccountGetDefaultByTypeResponse> | Observable<BazaNcBankAccountGetDefaultByTypeResponse>;
    get(ulid: string, ...args: unknown[]): Promise<BazaNcBankAccountDto> | Observable<BazaNcBankAccountDto>;
    link(request: BazaPlaidLinkRequestDto, ...args: unknown[]): Promise<BazaPlaidLinkResponseDto> | Observable<BazaPlaidLinkResponseDto>;
    linkOnSuccess(
        request: BazaNcBankAccountLinkOnSuccessRequest,
        ...args: unknown[]
    ): Promise<BazaNcBankAccountLinkOnSuccessResponse> | Observable<BazaNcBankAccountLinkOnSuccessResponse>;
    add(request: BazaNcBankAccountAddRequest, ...args: unknown[]): Promise<BazaNcBankAccountDto> | Observable<BazaNcBankAccountDto>;
    remove(ulid: string, ...args: unknown[]): Promise<BazaNcBankAccountRemoveResponse> | Observable<BazaNcBankAccountRemoveResponse>;
    set(
        request: BazaNcBankAccountSetRequest,
        ...args: unknown[]
    ): Promise<Array<BazaNcBankAccountDto>> | Observable<Array<BazaNcBankAccountDto>>;
    export(request: BazaNcBankAccountExportRequest, ...args: unknown[]): Promise<BazaNcBankAccountDto> | Observable<BazaNcBankAccountDto>;
    ncAchBankAccount(...args: unknown[]): Promise<BazaNcBankAccountAchDto> | Observable<BazaNcBankAccountAchDto>;
}

export class BazaNcBankAccountAddRequest {
    @ApiModelProperty({
        required: false,
        default: false,
        description: 'If true, account will be set as default',
    })
    @IsBoolean()
    @IsOptional()
    setAsDefault?: boolean;

    @ApiModelProperty({
        description: 'Static Bank Account. Enable it to use Single Bank Account flow',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    static?: boolean;

    @ApiModelProperty({
        description:
            'Secure Account and Account Routing Numbers. Enabling this option will not allow later to set or export accounts later, because' +
            'all Bank Account Details will be stored directly on NC or Dwolla side. Enabling this option will make "exports" option as required',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    secure?: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountExport),
        description:
            'To which services Bank Account should be exported. If API fails to export Bank Account, Bank Account will not be created',
        required: false,
    })
    @IsEnum(BazaNcBankAccountExport, { each: true })
    @IsArray()
    @IsOptional()
    export?: Array<BazaNcBankAccountExport>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountType),
        description: 'Bank Account Type',
    })
    @IsIn(Object.values(BazaNcBankAccountType))
    type: BazaNcBankAccountType;

    @ApiModelProperty({
        type: 'string',
        description: 'Name. Could be any string and it could be used for UI. If not specified, Name will be autogenerated',
        example: "John Smith's Checking",
        required: false,
        minLength: 1,
        maxLength: 64,
    })
    @MinLength(1)
    @MaxLength(64)
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Account Holder Name',
        example: 'John Abraham',
    })
    @IsString()
    @IsNotEmpty()
    accountName: string;

    @ApiModelProperty({
        description: 'Nickname for the External Account',
        example: 'John Nick',
    })
    @IsString()
    @IsNotEmpty()
    accountNickName: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Account Number',
        example: '1234567890',
    })
    @IsString()
    @IsNotEmpty()
    accountNumber: string;

    @ApiModelProperty({
        type: 'string',
        example: '34543534',
        description: 'Routing Number',
    })
    @IsString()
    @IsNotEmpty()
    accountRoutingNumber: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountTypeCheckingSaving),
        description: 'Account type Checking / Saving',
        example: AccountTypeCheckingSaving.Checking,
    })
    @IsEnum(AccountTypeCheckingSaving)
    @IsNotEmpty()
    accountType: AccountTypeCheckingSaving;
}

export class BazaNcBankAccountSetRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        type: 'array',
        items: {
            type: 'string',
            enum: Object.values(BazaNcBankAccountType),
        },
        required: false,
        description:
            '(Optional) Specify for which operation types bank account should be set as default. API will automatically' +
            'adds additional bank account entities for missing types',
    })
    @IsEnum(BazaNcBankAccountType, { each: true })
    @IsArray()
    @IsOptional()
    types?: Array<BazaNcBankAccountType>;

    @ApiModelProperty({
        description: 'Static Bank Account. Enable it to use Single Bank Account flow',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    static?: boolean;
}

export class BazaNcBankAccountGetDefaultByTypeRequest {
    @ApiModelProperty({
        description: 'Enables / disables "options" array in response with list of all bank accounts of given type',
        example: '1',
        default: '0',
        required: false,
    })
    @IsBoolean()
    @Transform(({ value }) => ['true', '1', 'y'].includes((value || '').toString().toLowerCase()))
    @IsOptional()
    withOptions?: boolean;

    @ApiModelProperty({
        description: `If enabled the endpoint will fire error response with
            "${BazaNcBankAccountErrorCodes.BazaNcBankAccountNoDefault}" error code instead of
            returning { "isAvailable" false } for missing bank account case.`,
        example: '1',
        default: '0',
        required: false,
    })
    @IsBoolean()
    @Transform(({ value }) => ['true', '1', 'y'].includes((value || '').toString().toLowerCase()))
    @IsOptional()
    throwError?: boolean;
}

export class BazaNcBankAccountGetDefaultByTypeResponse {
    @ApiModelProperty({
        description: 'Is default bank account available?',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        description: 'List of all bank accounts of given type. The list will be represented only if "withOptions=1" query param provided',
        required: false,
        type: BazaNcBankAccountDto,
        isArray: true,
    })
    options?: Array<BazaNcBankAccountDto>;

    @ApiModelProperty({
        description: "Contains default bank account of given type (in case it's available)",
        required: false,
    })
    result?: BazaNcBankAccountDto;
}

export class BazaNcBankAccountRemoveResponse {
    @ApiModelProperty({
        description:
            'Returns ULID of default bank account of same bank account type group after applying changes.' +
            'If there are no bank accounts available anymore in group, there will be no value',
        required: false,
        example: ulid(),
    })
    defaultBankAccountUlid?: string;
}

export class BazaNcBankAccountExportRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountExport),
        description: 'To which services Bank Account should be (re-)exported',
    })
    @IsEnum(BazaNcBankAccountExport, { each: true })
    @IsArray()
    @IsNotEmpty()
    services: Array<BazaNcBankAccountExport>;

    @ApiModelProperty({
        required: false,
        default: false,
        description: 'If true, Bank Account will be set as default',
    })
    @IsBoolean()
    @IsOptional()
    setAsDefault?: boolean;
}

export class BazaNcBankAccountLinkOnSuccessRequest {
    @ApiModelProperty({
        description: 'Public Token returned as first argument from onSuccess callback',
    })
    @IsString()
    @IsNotEmpty()
    publicToken: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountType),
        description: 'Bank Account Type',
    })
    @IsIn(Object.values(BazaNcBankAccountType))
    type: BazaNcBankAccountType;

    @ApiModelProperty({
        required: false,
        default: false,
        description: 'If true, account will be set as default',
    })
    @IsBoolean()
    @IsOptional()
    setAsDefault?: boolean;

    @ApiModelProperty({
        description:
            'Secure Account and Account Routing Numbers. Enabling this option will not allow later to set or export accounts later, because' +
            'all Bank Account Details will be stored directly on NC or Dwolla side. Enabling this option will make "exports" option as required',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    secure?: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountExport),
        description:
            'To which services Bank Account should be exported. If API fails to export Bank Account, Bank Account will not be created',
        required: false,
    })
    @IsEnum(BazaNcBankAccountExport, { each: true })
    @IsArray()
    @IsOptional()
    export?: Array<BazaNcBankAccountExport>;

    @ApiModelProperty({
        description: 'Static Bank Account. Enable it to use Single Bank Account flow',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    static?: boolean;

    @ApiModelProperty({
        description:
            'Identity information which is received from Plaid Widget during onSuccess callback. If Identity' +
            'is not passed, API will try to use Plaid Identity API to fetch details. The second option is available' +
            'only if you have a Plaid account with Identity product purchased',
        required: false,
        default: false,
    })
    // TODO: Replace it with @ValidateNested object and test it properly with both Sandbox and Production Plaid Accounts
    @IsObject()
    @IsOptional()
    identity?: BazaNcBankAccountIdentity;
}

export class BazaNcBankAccountLinkOnSuccessResponse {
    @ApiModelProperty({
        description: 'If true, Bank Account already exists',
    })
    duplicate: boolean;

    @ApiModelProperty({
        description: 'Added Bank Account',
    })
    bankAccount: BazaNcBankAccountDto;
}
