import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { TradeId } from '../models/trade-id';
import { TransactionType } from '../models/transaction-type';

export class UpdateTradeTransactionTypeRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsEnum(TransactionType)
    @IsNotEmpty()
    transactionType: TransactionType;
}
