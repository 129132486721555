import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaPasswordDifficultLevel } from '../models/baza-password-validator';
import { BazaPasswordValidatorDto } from './baza-password-validator.dto';

export class BazaPasswordValidateDto {
    @ApiModelProperty({
        description: 'Is password passed all necessary checks',
    })
    isValid: boolean;

    @ApiModelProperty({
        description: 'Password difficulty',
        type: 'string',
        enum: Object.values(BazaPasswordDifficultLevel),
    })
    difficult: BazaPasswordDifficultLevel;

    @ApiModelProperty({
        description: 'Passed validators',
        type: BazaPasswordValidatorDto,
        isArray: true,
    })
    passed: Array<BazaPasswordValidatorDto>;

    @ApiModelProperty({
        description: 'Failed validators',
        type: BazaPasswordValidatorDto,
        isArray: true,
    })
    failed: Array<BazaPasswordValidatorDto>;
}
