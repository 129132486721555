import {
    DwollaBankAccountType,
    DwollaFundingSourceId,
    DwollaFundingSourceStatus,
    DwollaFundingSourceType,
} from '../../../../dwolla-api/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaDwollaEscrowFundingSourceDto {
    @ApiModelProperty()
    created: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Funding Source ID',
    })
    dwollaFundingSourceId: DwollaFundingSourceId;

    @ApiModelProperty({
        description: 'Is Selected as Escrow Funding Source?',
    })
    isSelectedAsEscrow: boolean;

    @ApiModelProperty({
        description: 'Can be selected as Escrow Funding Source?',
    })
    canBeSelectedAsEscrow: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DwollaFundingSourceType),
    })
    type: DwollaFundingSourceType;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DwollaFundingSourceStatus),
    })
    status: DwollaFundingSourceStatus;

    @ApiModelProperty()
    name: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DwollaBankAccountType),
        required: false,
    })
    bankAccountType?: DwollaBankAccountType;

    @ApiModelProperty({
        required: false,
    })
    bankName?: string;
}
