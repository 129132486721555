import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { FaqEndpoint, FaqEndpointPaths, FaqListResponse } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class FaqDataAccess implements FaqEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(): Observable<FaqListResponse> {
    return this.http.get(FaqEndpointPaths.list);
  }
}
