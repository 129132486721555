import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BazaNcCreditCardDto } from '../dto/baza-nc-credit-card.dto';

export enum BazaNcCreditCardEndpointPaths {
    link = '/baza-nc/credit-card/link',
    unlink = '/baza-nc/credit-card/unlink',
    get = '/baza-nc/credit-card/get',
    upsert = '/baza-nc/credit-card/upsert',
}

export interface BazaNcCreditCardEndpoint {
    link(...args: unknown[]): Promise<BazaNcCreditCardLinkResponse> | Observable<BazaNcCreditCardLinkResponse>;
    unlink(...args: unknown[]): Promise<BazaNcCreditCardUnlinkResponse> | Observable<BazaNcCreditCardUnlinkResponse>;
    get(...args: unknown[]): Promise<BazaNcCreditCardDto> | Observable<BazaNcCreditCardDto>;
    upsert(...args: unknown[]): Promise<BazaNcCreditCardDto> | Observable<BazaNcCreditCardDto>;
}

export class BazaNcCreditCardLinkResponse {
    @ApiModelProperty({
        description: 'NC Stripe URL for Iframe/Webview integrations',
    })
    link: string;
}

export class BazaNcCreditCardUnlinkResponse {
    @ApiModelProperty({
        description: 'Indicates was Credit Card actually deleted',
    })
    deleted: boolean;
}
