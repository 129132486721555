export class BazaContentTypeEventsConfig {
    maxFullImageWidth: number;
    maxThumbnailImageWidth: number;
}

export function bazaContentTypeEventsDefaultConfig(): BazaContentTypeEventsConfig {
    return {
        maxFullImageWidth: 720,
        maxThumbnailImageWidth: 320,
    };
}
