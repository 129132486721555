import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaInviteCodeDto {
    @ApiModelProperty({
        description: 'Code (to display & share)'
    })
    code: string;

    @ApiModelProperty({
        description: 'Is invite code already completely consumed?'
    })
    isConsumed: boolean;
}
