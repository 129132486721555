<div class="purchase-summary">
    <nz-card
        class="purchase-summary__card"
        [nzBordered]="true">
        <div class="purchase-summary__title">
            {{ wts.getI18nLabel(i18nBasePath, 'title') }}
        </div>

        <div class="purchase-summary__info">
            <div
                class="purchase-summary__preview"
                *ngIf="withImage">
                <div class="purchase-summary__img">
                    <img
                        class="img-responsive"
                        [src]="entity?.cover?.md"
                        [alt]="entity?.title" />
                </div>

                <div class="purchase-summary__subtitle">
                    {{ entity?.title }}
                </div>

                <nz-divider class="purchase__divider"></nz-divider>
            </div>

            <div class="purchase-summary__stats">
                <div class="purchase-summary__row">
                    <div
                        class="purchase-summary__label"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'numOfShares') | sanitizeHtml"></div>
                    <div
                        class="purchase-summary__text"
                        [innerHTML]="numberOfShares | number"></div>
                </div>

                <div
                    class="purchase-summary__row"
                    *ngIf="!maxSharesPerUserLimitReached">
                    <div
                        class="purchase-summary__label"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'pricePerShare') | sanitizeHtml"></div>
                    <div
                        class="purchase-summary__text"
                        [innerHTML]="entity?.pricePerShareCents / 100 | priceCents"></div>
                </div>

                <div
                    *ngIf="transactionFee && transactionFee > 0 && !maxSharesPerUserLimitReached"
                    class="purchase-summary__row">
                    <div class="purchase-summary__label purchase-summary__label-fee">
                        <div
                            class="purchase-summary__fee"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'transactionFee') | sanitizeHtml"></div>
                        <i
                            class="icon icon-info-circle icon-wt purchase-summary__icon"
                            nz-popover
                            [nzPopoverContent]="wts.getI18nLabel(i18nBasePath, 'transactionFeePopover')"
                            nzPopoverPlacement="right"></i>
                    </div>

                    <div class="purchase-summary__text">
                        {{
                            (transactionFeeCents
                                ? transactionFeeCents
                                : calculateTransactionFee((numberOfShares * entity?.pricePerShareCents) / 100, transactionFee)
                            ) | priceCents
                        }}
                    </div>
                </div>

                <div
                    class="purchase-summary__row"
                    *ngIf="fee && !maxSharesPerUserLimitReached">
                    <div
                        class="purchase-summary__label"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'paymentMethodFee') | sanitizeHtml"></div>
                    <div
                        class="purchase-summary__text"
                        [innerHTML]="fee / 100 | priceCents"></div>
                </div>

                <nz-divider class="purchase__divider"></nz-divider>

                <div class="purchase-summary__row">
                    <div
                        class="purchase-summary__label purchase-summary__label-total"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'total') | sanitizeHtml"></div>

                    <div class="purchase-summary__text purchase-summary__text-total">
                        {{
                            (transactionFeeCents
                                ? transactionFeeCents + calculateTotal(fee, numberOfShares * entity?.pricePerShareCents)
                                : calculateTransactionFee((numberOfShares * entity?.pricePerShareCents) / 100, transactionFee) +
                                  calculateTotal(fee, numberOfShares * entity?.pricePerShareCents)
                            ) | priceCents
                        }}
                    </div>
                </div>
            </div>
        </div>
    </nz-card>
</div>
