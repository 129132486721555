export const VFEnI18n = {
    links: {
        vfLink: `/verification`,
        defaultRedirect: `/account`,
        backLink: {
            confirmation: {
                title: 'Are you sure you want to exit?',
                text: 'All completed steps will be saved. You can continue verification again later.',
                cancelBtnText: `Cancel`,
                confirmBtnText: `Exit`,
            },
            text: 'Go Back',
        },
    },
    steps: {
        info: 'Personal Information',
        profile: 'Investor Profile',
    },
    notifications: {
        apply_investor_profile_success: 'Investor Profile successfully saved',
        apply_personal_info_success: 'Personal Information successfully saved',
        load_verification_fail: 'There was an error loading the verification information.',
        upload_doc_success: 'Additional Document successfully uploaded.',
        upload_doc_fail: 'There was an error uploading the additional document.',
        upload_ssn_doc_success: 'SSN Document successfully uploaded.',
        upload_ssn_doc_fail: 'There was an error uploading the SSN document.',
        verify_upload_doc: 'Please upload a passport to verify your identity.',
    },
};
