import { OrderStatus } from '../../../transact-api';
import { TransactionStatus } from '../../../transact-api';

/**
 * List of all available Transaction States
 */
export enum TransactionState {
    None = 'NONE',
    Created = 'CREATED',
    PendingPayment = 'PENDING_PAYMENT',
    PaymentRejected = 'PAYMENT_REJECTED',
    PaymentCancelled = 'PAYMENT_CANCELLED',
    PaymentReturned = 'PAYMENT_RETURNED',
    PaymentFunded = 'PAYMENT_FUNDED',
    PaymentConfirmed = 'INVESTMENT_IS_CONFIRMED',
    UnwindPending = 'UNWIND_PENDING',
    UnwindCanceled = 'UNWIND_CANCELLED',
    UnwindSettled = 'UNWIND_SETTLED',
}

export const TRANSACTION_INVESTMENTS_RETURNED_STATES: Array<TransactionState> = [TransactionState.PaymentReturned];

/**
 * List of statuses which can be reprocessed
 */
export const TRANSACTION_REPROCESS_AVAILABLE_STATES: Array<TransactionState> = [TransactionState.PaymentReturned];

/**
 * List of statuses which should be counted for assets (aggregated by Listings)
 */
export const TRANSACTION_INVESTMENTS_COUNTABLE_STATES: Array<TransactionState> = [
    TransactionState.PaymentFunded,
    TransactionState.PaymentConfirmed,
    TransactionState.PendingPayment,
];

/**
 * Transaction Status for Main Card of Listing in assets (aggregated by Listings and State)
 */
export const TRANSACTION_INVESTMENTS_COUNTABLE_STATES_GROUPS_MAIN_CARD: Array<TransactionState> = [
    TransactionState.PaymentFunded,
    TransactionState.PaymentConfirmed,
];

/**
 * List of statuses which should be counted for assets (aggregated by Listings and State)
 */
export const TRANSACTION_INVESTMENTS_COUNTABLE_STATES_GROUPS: Array<Array<TransactionState>> = [
    TRANSACTION_INVESTMENTS_COUNTABLE_STATES_GROUPS_MAIN_CARD,
    [TransactionState.PendingPayment],
];

/**
 * Map TransactionStatus -> TransactionState for Returned Payments
 */
export const mapNcTransactionStatusToTransactionState: Array<{
    transactionStatus: TransactionStatus;
    transactionState: TransactionState;
}> = [
    {
        transactionStatus: 'RETURNED',
        transactionState: TransactionState.PaymentReturned,
    },
    {
        transactionStatus: 'PENDING',
        transactionState: TransactionState.PendingPayment,
    },
];

/**
 * Map Order(Trade) Status -> TransactionStatus
 */
export const mapTransactionState: Array<{ match: OrderStatus[]; transactionState: TransactionState }> = [
    {
        match: [OrderStatus.Created],
        transactionState: TransactionState.Created,
    },
    {
        match: [OrderStatus.Pending],
        transactionState: TransactionState.PendingPayment,
    },
    {
        match: [OrderStatus.Funded],
        transactionState: TransactionState.PaymentFunded,
    },
    {
        match: [OrderStatus.Settled],
        transactionState: TransactionState.PaymentConfirmed,
    },
    {
        match: [OrderStatus.Canceled],
        transactionState: TransactionState.PaymentCancelled,
    },
    {
        match: [OrderStatus.Rejected],
        transactionState: TransactionState.PaymentRejected,
    },
    {
        match: [OrderStatus.UnwindCreated, OrderStatus.UnwindPending],
        transactionState: TransactionState.UnwindPending,
    },
    {
        match: [OrderStatus.UnwindCanceled],
        transactionState: TransactionState.UnwindCanceled,
    },
    {
        match: [OrderStatus.UnwindSettled],
        transactionState: TransactionState.UnwindSettled,
    },
];

/**
 * Returns TransactionState for given Order Status
 * @param ncOrderStatus
 */
export function ncOrderStatusToTransactionState(ncOrderStatus: OrderStatus): TransactionState {
    return mapTransactionState.find((c) => c.match.includes(ncOrderStatus)).transactionState;
}
