export enum BazaNcPayoffDistributionTransactionStatus {
    Draft = 'Draft',
    Started = 'Started',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Successful = 'Successful',
    Unknown = 'Unknown',
}

export const bazaNcPayoffDistributionNonDraftTransactionStatuses: Array<BazaNcPayoffDistributionTransactionStatus> = [
    BazaNcPayoffDistributionTransactionStatus.Started,
    BazaNcPayoffDistributionTransactionStatus.InProgress,
    BazaNcPayoffDistributionTransactionStatus.Failed,
    BazaNcPayoffDistributionTransactionStatus.Cancelled,
    BazaNcPayoffDistributionTransactionStatus.Successful,
];

export const bazaNcPayoffDistributionTransactionStatusesToProcess: Array<BazaNcPayoffDistributionTransactionStatus> = [
    BazaNcPayoffDistributionTransactionStatus.Draft,
    BazaNcPayoffDistributionTransactionStatus.Failed,
    BazaNcPayoffDistributionTransactionStatus.Cancelled,
    BazaNcPayoffDistributionTransactionStatus.Unknown,
];

export const bazaNcPayoffDistributionTransactionStatusesLocked: Array<BazaNcPayoffDistributionTransactionStatus> = [
    BazaNcPayoffDistributionTransactionStatus.InProgress,
    BazaNcPayoffDistributionTransactionStatus.Successful,
];
