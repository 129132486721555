import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { TradeId } from '../models/trade-id';
import { ArchiveStatus } from '../models/archive-status';

export class UpdateTradeArchiveStatusRequest {
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsEnum(ArchiveStatus)
    @IsNotEmpty()
    archiveStatus: ArchiveStatus;
}
