import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { NewsCmsDto } from '../dto/news-cms.dto';
import { BazaContentDto } from '../../../../../shared/src';
import { Type } from 'class-transformer';

export enum NewsCmsEndpointPaths {
    create = '/baza-content-types/cms/news/create',
    update = '/baza-content-types/cms/news/update',
    delete = '/baza-content-types/cms/news/delete',
    list = '/baza-content-types/cms/news/list',
    getById = '/baza-content-types/cms/news/getById',
    setSortOrder = '/baza-content-types/cms/news/setSortOrder',
}

export interface NewsCmsEndpoint {
    create(request: NewsCmsCreateRequest): Promise<NewsCmsDto> | Observable<NewsCmsDto>;
    update(request: NewsCmsUpdateRequest): Promise<NewsCmsDto> | Observable<NewsCmsDto>;
    delete(request: NewsCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: NewsCmsListRequest): Promise<NewsCmsListResponse> | Observable<NewsCmsListResponse>;
    getById(request: NewsCmsGetByIdRequest): Promise<NewsCmsDto> | Observable<NewsCmsDto>;
    setSortOrder(request: NewsCmsSetSortOrderRequest): Promise<NewsCmsSetSortOrderResponse> | Observable<NewsCmsSetSortOrderResponse>;
}

export class NewsCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    datePublishedAt: string;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    primaryCategoryId?: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    additionalCategoryIds?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    link?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    imageAwsS3Key?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    previewAwsS3Key?: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class NewsCmsCreateRequest extends NewsCmsEntityBody {}

export class NewsCmsUpdateRequest extends NewsCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class NewsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class NewsCmsListRequest extends CrudListRequestDto<NewsCmsDto> {}

export class NewsCmsListResponse extends CrudListResponseDto<NewsCmsDto> {
    @ApiModelProperty({
        type: NewsCmsDto,
        isArray: true,
    })
    items: Array<NewsCmsDto>;
}

export class NewsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class NewsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class NewsCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<NewsCmsDto> {
    @ApiModelProperty()
    entity: NewsCmsDto;

    @ApiModelProperty({
        type: NewsCmsDto,
        isArray: true,
    })
    affected: Array<NewsCmsDto>;
}
