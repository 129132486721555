import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class SliderSchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty()
    min: number;

    @ApiModelProperty()
    max: number;

    @ApiModelProperty()
    step: number;
}

export class SliderSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.Slider],
    })
    type: BazaNcIntegrationSchemaType.Slider;

    @ApiModelProperty()
    payload: SliderSchemaDefinitionPayload;
}
