import { NorthCapitalAccountId } from '../models/account-id';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UploadAccountDocumentRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsOptional()
    userfile0?: File | unknown;

    @IsString()
    @IsOptional()
    createdIpAddress?: string;
}
