export const WithdrawEnI18n = {
    contactLink: {
        label: `<a data-link="withdrawSupportConfig"></a>`,
        linkConfig: {
            key: 'withdrawSupportConfig',
            link: {
                extLink: {
                    link: 'bazaContentTypes.contacts.email',
                    text: 'contact us',
                    isCMSLink: true,
                    isMailLink: true,
                },
            },
        },
    },
};
