export enum BazaNcBankAccountErrorCodes {
    BazaNcBankAccountNotFound = 'BazaNcBankAccountNotFound',
    BazaNcBankAccountNoDefault = 'BazaNcBankAccountNoDefault',
    BazaNcBankAccountDuplicate = 'BazaNcBankAccountDuplicate',
    BazaNcBankAccountCannotBeExportedToNc = 'BazaNcBankAccountCannotBeExportedToNc',
    BazaNcBankAccountCannotBeExportedToDwolla = 'BazaNcBankAccountCannotBeExportedToDwolla',
    BazaNcBankAccountUnknownExportService = 'BazaNcBankAccountUnknownExportService',
    BazaNcBankAccountNoDwollaCustomerAvailable = 'BazaNcBankAccountNoDwollaCustomerAvailable',
    BazaNcBankAccountExportsAreRequired = 'BazaNcBankAccountExportsAreRequired',
    BazaNcBankAccountIsSecured = 'BazaNcBankAccountIsSecured',
    BazaNcBankAccountNoIdentity = 'BazaNcBankAccountNoIdentity',
}

export const bazaNcBankAccountErrorCodesI18n = {
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountNotFound]: 'Bank Account was not found',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountNoDefault]: 'No Bank Account available',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountDuplicate]: 'Bank Account already added',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountCannotBeExportedToNc]: 'Bank Account cannot be exported to North Capital',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountCannotBeExportedToDwolla]: 'Bank Account cannot be exported to Dwolla',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountUnknownExportService]: 'Bank Account cannot be exported to "{{ destination }}" service',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountNoDwollaCustomerAvailable]: "You don't have Dwolla account yet",
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountExportsAreRequired]: 'Exports option is required for secured Bank Accounts',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountIsSecured]: 'Bank Account is Secured and cannot be exported anymore',
    [BazaNcBankAccountErrorCodes.BazaNcBankAccountNoIdentity]: 'Failed to fetch Identity information about Bank Account',
};
