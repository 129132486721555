import { Injectable } from '@angular/core';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { JwtService } from '../services/jwt.service';

/**
 * Helper Interface for Route Data object
 * Implement it in Route's Data object or use it just as documentation source
 */
export interface BazaJwtRequiredNoAuthGuardRouteConfig {
    jwtRequireNoAuthGuard: Partial<BazaJwtRequiredNoAuthGuardConfig>;
}

/**
 * Configuration for JwtRequireNoAuthGuard
 *
 * You can override default configuration with Data route object, BAZA_CMS_BUNDLE_GUARD_REDIRECTS
 * or BAZA_WEB_BUNDLE_GUARD_CONFIGS global injectionable constants or with
 * bundle builder helpers.
 */
export interface BazaJwtRequiredNoAuthGuardConfig {
    /**
     * If redirect set, user will be redirected to specified route.
     * Additionally it can be set in route's data object
     */
    redirect?: (route: ActivatedRouteSnapshot) => {
        commands: any[];
        navigationExtras?: UrlCreationOptions;
    };
}

/**
 * Router guard will not allow user to visit route if user is alreadt signed in.
 * If user is signed in, he will be (by default) redirected to /
 * You can override redirect configuration with route's data or with module configuration.
 *
 * Guard can be used with canActivate or canActivateChild configurations.
 *
 * @see JwtService
 *
 * @example
 * Using JwtRequireNoAuthGuard + custom redirect configuration
 *
 * ```typescript
`* {
 *    path: 'my-component',
 *    component: MyComponent,
 *    canActivateChild: [JwtRequireNoAuthGuard],
 *    data: {
 *        // optionally you can override default guard configuration
 *        jwtRequireNoAuthGuard: {
 *            redirect: () => ({
 *              commands: ['/'],
 *            }),
 *        },
 *    } as BazaJwtRequiredNoAuthGuardRouteConfig,`
 * }
 * ```
 *
 * @example
 * You can replace redirects for JwtRequireNoAuthGuard on global level:
 *
 * ```typescript
 * import { BAZA_WEB_BUNDLE_GUARD_CONFIGS } from '@scaliolabs/baza-core-web`
 *
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.jwtRequireNoAuthGuard.redirect = () => ({
 *     commands: ['/'],
 *     navigationExtras: {
 *         queryParams: {
 *             signIn: 1,
 *         },
 *     },
 * });
 * ```
 */
@Injectable()
export class JwtRequireNoAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly jwtService: JwtService,
        private readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        if (this.jwtService.hasJwt()) {
            const moduleConfig = this.moduleConfig.requireNoAuthGuardConfig;
            const customConfig: Partial<BazaJwtRequiredNoAuthGuardConfig> = ((route.data || {}) as BazaJwtRequiredNoAuthGuardRouteConfig).jwtRequireNoAuthGuard;

            const guardConfig = {
                ...moduleConfig,
                ...customConfig,
            };

            if (guardConfig.redirect) {
                const redirectTo = guardConfig.redirect(route);

                return this.router.createUrlTree(
                    redirectTo.commands,
                    redirectTo.navigationExtras,
                );
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot): boolean | UrlTree {
        return this.canActivate(route);
    }
}
