import { isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BazaCmsDataAccessModule } from '@scaliolabs/baza-core-cms-data-access';
import { BazaDataAccessModule, BazaI18nDataAccessModule } from '@scaliolabs/baza-core-data-access';
import {
    BazaAuthNgModule,
    BazaI18nNgModule,
    BazaMaintenanceNgModule,
    BazaNgCoreModule,
    BazaPhoneCountryCodesNgModule,
    BazaRegistryNgModule,
    BazaSentryNgModule,
    BazaVersionNgModule,
} from '@scaliolabs/baza-core-ng';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { filter, take } from 'rxjs/operators';
import { BazaWebBundleConfig, BAZA_WEB_BUNDLE_CONFIG } from './baza-web-bundle.config';
import { BazaWebBundleModuleConfigs, defaultBazaWebBundleModuleConfigs } from './baza-web-bundle.module-configs';
import { BAZA_NG_AUTH_JWT_INTERCEPTOR_PROVIDER } from './providers/baza-ng-auth-jwt-interceptor.provider';
import { BAZA_NG_I18N_INTERCEPTOR_PROVIDER } from './providers/baza-ng-i18n-interceptor.provider';
import { BazaNgWebEnvironment } from './services/baza-ng-web.environment';

const NG_MODULES = [HttpClientModule];

const BAZA_MODULES = [
    BazaI18nDataAccessModule,
    BazaNgCoreModule,
    BazaAuthNgModule,
    BazaDataAccessModule,
    BazaI18nNgModule,
    TranslateModule,
    BazaMaintenanceNgModule,
    BazaVersionNgModule,
    BazaPhoneCountryCodesNgModule,
    BazaRegistryNgModule,
];

// @dynamic
@NgModule({
    imports: [...NG_MODULES, ...BAZA_MODULES],
    exports: [...NG_MODULES, ...BAZA_MODULES],
    providers: [BAZA_NG_AUTH_JWT_INTERCEPTOR_PROVIDER, BAZA_NG_I18N_INTERCEPTOR_PROVIDER],
})
export class BazaWebBundleModule {
    static forRoot(config: BazaWebBundleConfig): ModuleWithProviders<BazaWebBundleModule> {
        const moduleConfigs: BazaWebBundleModuleConfigs = {
            ...defaultBazaWebBundleModuleConfigs,
            ...config.moduleConfigs,
        };

        return {
            ngModule: BazaWebBundleModule,
            providers: [
                {
                    provide: BAZA_WEB_BUNDLE_CONFIG,
                    useValue: config,
                },
                {
                    provide: NZ_I18N,
                    useValue: config.nzI18n,
                },
                ...BazaNgCoreModule.forRoot(moduleConfigs.BazaNgCoreModule(config)).providers,
                ...BazaAuthNgModule.forRoot(moduleConfigs.BazaAuthNgModule(config)).providers,
                ...BazaDataAccessModule.forRoot(moduleConfigs.BazaNgEndpointModule(config)).providers,
                ...BazaCmsDataAccessModule.forRoot(moduleConfigs.BazaNgEndpointModule(config)).providers,
                ...BazaI18nNgModule.forRoot(moduleConfigs.BazaI18nNgModule(config)).providers,
                ...TranslateModule.forRoot(moduleConfigs.TranslateModule(config)).providers,
                ...BazaMaintenanceNgModule.forRoot(moduleConfigs.BazaMaintenanceNgModule(config)).providers,
                ...BazaVersionNgModule.forRoot(moduleConfigs.BazaVersionNgModule(config)).providers,
                ...BazaPhoneCountryCodesNgModule.forRoot(moduleConfigs.BazaPhoneCountryCodesNgModule(config)).providers,
                ...BazaSentryNgModule.forRoot(moduleConfigs.BazaSentryNgModule(config)).providers,
            ],
        };
    }

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        @Inject(BAZA_WEB_BUNDLE_CONFIG) private readonly moduleConfig: BazaWebBundleConfig,
        private readonly router: Router,
        private readonly ngEnvironmentService: BazaNgWebEnvironment,
    ) {
        this.initNgEnvironment();
        this.destroyInitialLoader();
    }

    private initNgEnvironment(): void {
        this.ngEnvironmentService.current = this.moduleConfig.environmentConfig;
    }

    private destroyInitialLoader(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    take(1),
                )
                .subscribe(() => {
                    if (window && window.document && window.document.getElementById) {
                        const initialLoader = window.document.getElementById('bazaInitialLoader');

                        if (initialLoader && initialLoader.remove) {
                            initialLoader.remove();
                        }
                    }
                });
        }
    }
}
