export const PurchaseDetailsEnI18n = {
    title: 'Purchase Details',
    description: 'Pick number of shares you would like to purchase and review your personal details.',
    subtitle: 'Listing Details',
    shares: {
        min: 'Minimum {{ min }}.',
        max: 'You can purchase up to {{ max }}',
    },
    actions: {
        next: 'Next',
    },
};
