import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { CategoryListDto } from '../../../../categories/src';

export class FaqDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    url: string;

    @ApiModelProperty()
    dateCreated: string;

    @ApiModelProperty()
    dateUpdated: string;

    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty({
        required: false,
    })
    categoryId?: number;

    @ApiModelProperty({
        required: false,
    })
    category?: CategoryListDto;

    @ApiModelProperty()
    question: string;

    @ApiModelProperty()
    answer: string;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
