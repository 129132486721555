<div class="text-section">
    <div class="container">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="text-section__col">
                <div class="text-section__wrap">
                    <div
                        class="text-section__heading"
                        [innerHtml]="label"></div>

                    <div
                        class="text-section__subheading"
                        *ngIf="header"
                        [innerHtml]="header | sanitizeHtml"></div>
                </div>
            </div>

            <div
                nz-col
                nzXs="12"
                class="text-section__col text-section__col-right">
                <div class="text-section__wrap">
                    <div
                        class="text-section__descr"
                        *ngIf="description"
                        [innerHtml]="description | sanitizeHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>
