import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, Matches } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    BazaDwollaInvestorAccountErrorCodes,
    bazaDwollaInvestorAccountErrorCodesEnI18n,
} from '../error-codes/baza-dwolla-investor-account.error-codes';

type NorthCapitalDate = string;
const NC_VALID_CITY_REGEX = new RegExp('^[^0-9]+$');

export class BazaDwollaInvestorAccountUpdatePersonalInformationRequest {
    @ApiModelProperty({
        description: 'investor account id',
        required: true,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    firstName?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    lastName?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    dateOfBirth?: NorthCapitalDate;

    @ApiModelProperty({
        type: 'string',
        required: false,
    })
    @IsString()
    @IsOptional()
    ssn?: string;

    @ApiModelProperty({
        type: 'string',
        required: false,
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsOptional()
    residentialCountry?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Should be a valid city name("New York", "Las Vegas").',
    })
    @IsString()
    @IsOptional()
    @Matches(NC_VALID_CITY_REGEX, {
        message: () =>
            bazaDwollaInvestorAccountErrorCodesEnI18n[
                BazaDwollaInvestorAccountErrorCodes.DwollaInvestorAccountUpdatePartyResidentialCityIsInvalid
            ],
    })
    residentialCity?: string;

    @ApiModelProperty({
        type: 'string',
        required: false,
        description: 'You can send empty string or "N/A" to implicit that state is not available for selected country',
    })
    @IsString()
    @IsOptional()
    residentialState?: string;

    @ApiModelProperty({
        type: 'string',
        required: false,
    })
    @IsString()
    @IsOptional()
    residentialZipCode?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    residentialStreetAddress1?: string;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsOptional()
    residentialStreetAddress2?: string;
}
