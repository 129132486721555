export enum BazaNcDividendTransactionStatus {
    Draft = 'Draft',
    Started = 'Started',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Successful = 'Successful',
    Unknown = 'Unknown',
}

export const bazaNcDividendNonDraftTransactionStatuses: Array<BazaNcDividendTransactionStatus> = [
    BazaNcDividendTransactionStatus.Started,
    BazaNcDividendTransactionStatus.InProgress,
    BazaNcDividendTransactionStatus.Failed,
    BazaNcDividendTransactionStatus.Cancelled,
    BazaNcDividendTransactionStatus.Successful,
];

export const bazaNcDividendTransactionStatusesToProcess: Array<BazaNcDividendTransactionStatus> = [
    BazaNcDividendTransactionStatus.Draft,
    BazaNcDividendTransactionStatus.Failed,
    BazaNcDividendTransactionStatus.Cancelled,
    BazaNcDividendTransactionStatus.Unknown,
];

export const bazaNcDividendTransactionStatusesLocked: Array<BazaNcDividendTransactionStatus> = [
    BazaNcDividendTransactionStatus.InProgress,
    BazaNcDividendTransactionStatus.Successful,
];
