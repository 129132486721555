export const VFCheckEnI18n = {
    newVf: {
        title: `Suitability Questionnaire`,
        descr: `<p>
       The SEC requires you to complete an investor questionnaire. All of your information is securely stored by our
       registered broker, North Capital.
        </p>
        <p>Your responses are confidential and they will never be sold or shared.</p>
        <p>
            This is a
            <strong>one-time process</strong>
            . Once completed you will be able to invest in all offerings.
        </p>`,
        actions: {
            continue: `Continue`,
        },
    },
    continueVf: {
        title: `Continue Investor Account Verification`,
        descr: `<p>
        To proceed with the Purchase, you need to finish verification of your Profile. You will be asked to provide details
        and answer a questionnaire about your Investment Profile.
    </p>

    <p>
        The information we collect will never be shared or sold. It is securely encrypted and only used for purchases on our
        platform.
    </p>`,
        actions: {
            continue: `Continue`,
        },
    },
};
