import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { OfferingId } from '../../../transact-api';
import { BazaNcOfferingStatus } from '../models/baza-nc-offering-status';

export class BazaNcOfferingListItemDto {
    @ApiModelProperty({
        description: 'ID (local id)',
    })
    id: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
        enum: Object.values(BazaNcOfferingStatus),
    })
    status: BazaNcOfferingStatus;

    @ApiModelProperty({
        type: 'string',
        description: 'North Capital Offering ID',
    })
    ncOfferingId: OfferingId;

    @ApiModelProperty({
        description: 'Offering Name',
    })
    ncOfferingName: string;
}
