import { IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { NorthCapitalNumberAsString } from '../common-types';
import { Type } from 'class-transformer';

export class CustodianWireDetails {
    description: string;

    receivingbank: string;

    accountnumber: NorthCapitalNumberAsString;

    routingnumber: NorthCapitalNumberAsString;

    reference: string;

    address1: string;

    address2?: string;

    city: string;

    state: string;

    zip: string;

    country: string;

    receivingfinancialinstitution: string;

    swiftcode: NorthCapitalNumberAsString;

    beneficiary: string;

    beneficiaryaccountnumber: NorthCapitalNumberAsString;
}

export class CustodianCheckDetails {
    description: string;

    mailto: string;

    address1: string;

    address2: string;

    city: string;

    state: string;

    zip: string;

    country: string;

    postaldescription: string;

    postaladdress: string;

    postalcity: string;

    postalstate: string;

    postalzip: string;

    postalcountry: string;
}

export class CustodianDetails {
    @IsString()
    @IsNotEmpty()
    name: string;

    @IsString()
    @IsNotEmpty()
    additionaldescription: string;

    @ValidateNested()
    @Type(() => CustodianWireDetails)
    @IsNotEmpty()
    wire: Partial<CustodianWireDetails>;

    @IsNotEmpty()
    check: Partial<CustodianCheckDetails>;
}
