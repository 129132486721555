import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcCreditCardDto,
    BazaNcCreditCardEndpoint,
    BazaNcCreditCardEndpointPaths,
    BazaNcCreditCardLinkResponse,
    BazaNcCreditCardUnlinkResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';

/**
 * Baza NC Credit Card Data Access Service
 */
@Injectable()
export class BazaNcCreditCardDataAccess implements BazaNcCreditCardEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns link for NC Stripe Integration. This link should be open in Iframe
     */
    link(): Observable<BazaNcCreditCardLinkResponse> {
        return this.http.get(BazaNcCreditCardEndpointPaths.link);
    }

    /**
     * Deletes current Credit Card attached to Investor Account
     * Will not throw an exceptions if Credit Card is not linked yet
     */
    unlink(): Observable<BazaNcCreditCardUnlinkResponse> {
        return this.http.delete(BazaNcCreditCardEndpointPaths.unlink);
    }

    /**
     * Returns Credit Card status and returns Credit Card DTO.
     * If Credit Card is not linked yet, it will returns { isAvailable: false } as response
     */
    get(): Observable<BazaNcCreditCardDto> {
        return this.http.get(BazaNcCreditCardEndpointPaths.get);
    }

    /**
     * Updates Credit Card status and returns Credit Card DTO.
     * If Credit Card is not linked yet, it will returns { isAvailable: false } as response
     */
    upsert(): Observable<BazaNcCreditCardDto> {
        return this.http.get(BazaNcCreditCardEndpointPaths.upsert);
    }
}
