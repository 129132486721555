import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { flatMap, skipWhile } from 'rxjs/operators';
import { PurchaseState, StartCart } from '@scaliolabs/baza-web-purchase-flow';
import { CART_IS_EMPTY, makeCancelingError } from './cart.constants';

@Injectable()
export class CartGuard implements CanActivate {
    constructor(private readonly store: Store) {
        this.store.dispatch(new StartCart());
    }

    canActivate(): Observable<boolean> {
        return this.store.select(PurchaseState.cart).pipe(
            skipWhile((p) => p === undefined),
            flatMap((response) => {
                return response !== null ? of(true) : throwError(makeCancelingError(new Error(CART_IS_EMPTY)));
            }),
        );
    }
}
