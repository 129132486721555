import { AbstractControl, ValidationErrors } from '@angular/forms';

export const zipcodeWhitespaceValidator = (): ((AbstractControl) => ValidationErrors | null) => {
    return (control: AbstractControl): ValidationErrors | null => {
        const targetValue = control.value;

        if (!targetValue) {
            return null;
        }
        if (targetValue.includes(' ')) {
            return { whiteSpace: true };
        } else if (targetValue.endsWith(' ')) {
            return { whiteSpace: true };
        } else if (targetValue.startsWith(' ')) {
            return { whiteSpace: true };
        } else {
            return null;
        }
    };
};
