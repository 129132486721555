import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { MktPageDto, MktPageEndpoint, MktPagesEndpointPaths } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class MktPagesDataAccess implements MktPageEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    get(): Observable<MktPageDto[]> {
        return this.http.get(MktPagesEndpointPaths.get);
    }
}
