import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class RateSchemaDefinitionPayload extends SchemaDefinitionPayload {
    @ApiModelProperty({
        description: 'Number of stars',
    })
    count: number;
}

export class RateSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.Rate],
    })
    type: BazaNcIntegrationSchemaType.Rate;

    @ApiModelProperty()
    payload: RateSchemaDefinitionPayload;
}
