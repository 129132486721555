import { DwollaUsStates } from '../models/dwolla-us-states';
import { DwollaBusinessType } from '../models/business-type';
import { IsString, IsNotEmpty, IsOptional, IsEnum } from 'class-validator';
import { CreatePersonalCustomerRequest } from './create-personal-customer.request';

export class ControllerAddress {
    @IsString()
    @IsNotEmpty()
    address1: string;

    @IsString()
    @IsOptional()
    address2?: string;

    @IsString()
    @IsOptional()
    address3?: string;

    @IsString()
    @IsNotEmpty()
    city: string;

    @IsEnum(DwollaUsStates)
    @IsNotEmpty()
    stateProvinceRegion: DwollaUsStates;

    @IsString()
    postalCode: string;

    @IsString()
    @IsNotEmpty()
    country: string;
}

export class ControllerPassport {
    @IsString()
    @IsOptional()
    number?: string;

    @IsString()
    @IsOptional()
    country?: string;
}

// controller is any individual who holds to control a company
export class BusinessCustomerController {
    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsString()
    @IsNotEmpty()
    title: string;

    @IsString()
    @IsNotEmpty()
    dateOfBirth: string;

    @IsString()
    @IsNotEmpty()
    address: ControllerAddress;

    @IsString()
    @IsOptional()
    ssn?: string;

    @IsOptional()
    passport?: ControllerPassport;
}

export class CreateBusinessCustomerRequest extends CreatePersonalCustomerRequest {
    @IsNotEmpty()
    controller: BusinessCustomerController;

    @IsString()
    @IsNotEmpty()
    businessClassification: string;

    @IsEnum(DwollaBusinessType)
    @IsNotEmpty()
    businessType: DwollaBusinessType;

    @IsString()
    @IsNotEmpty()
    businessName: string;

    // Employer Identification Number
    @IsString()
    @IsOptional()
    ein?: string;

}