import { Injectable } from '@angular/core';
import {
    BazaNcPurchaseFlowBankAccountEndpoint,
    BazaNcPurchaseFlowBankAccountEndpointPaths,
    PurchaseFlowBankAccountDto,
    PurchaseFlowPlaidLinkDto,
    PurchaseFlowSetBankAccountDetailsDto,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

/**
 * Data Access Service for Legacy Bank Account System
 * @deprecated Please use New Bank Account System
 */
@Injectable()
export class BazaNcPurchaseFlowBankAccountDataAccess implements BazaNcPurchaseFlowBankAccountEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns link to register bank account with Plaid
     * @deprecated Please use New Bank Account System
     */
    getPlaidLink(): Observable<PurchaseFlowPlaidLinkDto> {
        return this.http.get<PurchaseFlowPlaidLinkDto>(BazaNcPurchaseFlowBankAccountEndpointPaths.getPlaidLink);
    }

    /**
     * Returns bank account details fetched with Plaid
     * @deprecated Please use New Bank Account System
     */
    getBankAccount(): Observable<PurchaseFlowBankAccountDto> {
        return this.http.get<PurchaseFlowBankAccountDto>(BazaNcPurchaseFlowBankAccountEndpointPaths.getBankAccount);
    }

    /**
     * Manual setup bank account details
     * @deprecated Please use New Bank Account System
     */
    setBankAccount(request: PurchaseFlowSetBankAccountDetailsDto): Observable<PurchaseFlowBankAccountDto> {
        return this.http.post<PurchaseFlowBankAccountDto>(BazaNcPurchaseFlowBankAccountEndpointPaths.setBankAccount, request);
    }

    /**
     * Delete linked bank account
     * @deprecated Please use New Bank Account System
     */
    deleteBankAccount(): Observable<void> {
        return this.http.post<void>(BazaNcPurchaseFlowBankAccountEndpointPaths.deleteBankAccount);
    }
}
