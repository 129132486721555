import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaNgCoreConfig } from './baza-ng-core.config';
import { BazaNgApiOnlineStatusService } from './services/baza-ng-api-online-status.service';
import { BazaNgErrorHandlerService } from './services/baza-ng-error-handler.service';
import { BazaLocalStorageService } from './services/baza-local-storage.service';
import { BazaSessionStorageService } from './services/baza-session-storage.service';
import { BazaLoadingService } from './services/baza-loading.service';
import { BazaNgCurrentApplicationService } from './services/baza-ng-current-application.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BazaNzError } from './pipes/baza-nz-error';
import { BazaPasswordInputComponent } from './components/baza-password-input/baza-password-input.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BazaNgConfirmService } from './services/baza-ng-confirm.service';
import { XBazaApplicationHttpInterceptor } from './http-interceptors/x-baza-application.http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WhiteSpaceInterceptor } from './http-interceptors/white-space.interceptor';
import { BazaFormValidatorService } from './services/baza-form-validator.service';
import { BazaActionsFormBuilderService } from './services/baza-actions-form-builder.service';

interface ForRootOptions {
    deps: Array<unknown>;
    useFactory: (...deps) => BazaNgCoreConfig;
}

export { ForRootOptions as BazaNgCoreModuleForRootOptions };

// @dynamic
@NgModule({
    declarations: [BazaNzError, BazaPasswordInputComponent],
    providers: [
        BazaActionsFormBuilderService,
        BazaNgApiOnlineStatusService,
        BazaNgErrorHandlerService,
        BazaLocalStorageService,
        BazaSessionStorageService,
        BazaLoadingService,
        BazaNgCurrentApplicationService,
        BazaNgConfirmService,
        XBazaApplicationHttpInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: XBazaApplicationHttpInterceptor,
        },
        WhiteSpaceInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: WhiteSpaceInterceptor,
        },
        BazaFormValidatorService,
    ],
    exports: [BazaNzError, BazaPasswordInputComponent],
    imports: [CommonModule, TranslateModule, NzFormModule, NzInputModule, ReactiveFormsModule, NzIconModule],
})
export class BazaNgCoreModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaNgCoreModule> {
        return {
            ngModule: BazaNgCoreModule,
            providers: [
                {
                    provide: BazaNgCoreConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
