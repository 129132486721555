import { ContentBlockHTML } from './content-blocks/html.content-block';
import { ContentBlockYouTube } from './content-blocks/youtube.content-block';
import { ContentBlockPhotos } from './content-blocks/photos.content-block';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { IsArray } from 'class-validator';

export * from './content-blocks/html.content-block';
export * from './content-blocks/youtube.content-block';
export * from './content-blocks/photos.content-block';

export enum BazaContentTypeEditor {
    SimpleHTMLCkEditor = 'SimpleHTMLCkEditor',
    ComplexEditor = 'ComplexEditor',
}

export enum BazaContentBlock {
    HTML = 'HTML',
    YouTube = 'YouTube',
    Photos = 'Photos',
}

export class BazaContentBlockPairHtml {
    @ApiModelProperty({
        type: 'string',
        example: BazaContentBlock.HTML,
    })
    type: BazaContentBlock.HTML;

    @ApiModelProperty()
    payload: ContentBlockHTML;
}

export class BazaContentBlockPairYoutube {
    @ApiModelProperty({
        type: 'string',
        example: BazaContentBlock.YouTube,
    })
    type: BazaContentBlock.YouTube;

    @ApiModelProperty()
    payload: ContentBlockYouTube;
}

export class BazaContentBlockPairPhotos {
    @ApiModelProperty({
        type: 'string',
        example: BazaContentBlock.Photos,
    })
    type: BazaContentBlock.Photos;

    @ApiModelProperty()
    payload: ContentBlockPhotos;
}

export const allBazaContentBlockPairs = [BazaContentBlockPairHtml, BazaContentBlockPairYoutube, BazaContentBlockPairPhotos];

export type BazaContentBlockPair = BazaContentBlockPairHtml | BazaContentBlockPairYoutube | BazaContentBlockPairPhotos;

export class BazaContentDto {
    @ApiModelProperty({
        oneOf: [...allBazaContentBlockPairs.map((obj) => ({ $ref: getSchemaPath(obj) }))],
        isArray: true,
    })
    @IsArray()
    // TODO: fix type of each element
    blocks: Array<BazaContentBlockPair>;
}

export function emptyBazaContentDto(): BazaContentDto {
    return {
        blocks: [],
    };
}
