import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { AlertComponent, AlertType } from './alert.component';

export interface AlertServiceConfig {
    /**
     * Selector for host element of alert component
     * Default to #alertPlaceholder
     */
    hostSelector: string;
}

export const ALERT_MODULE_CONFIG = new InjectionToken<AlertServiceConfig>('ALERT_MODULE_CONFIG');

/**
 * Display alert
 */
@Injectable()
export class AlertService {
    private portalHost: DomPortalHost | undefined;

    constructor(
        @Inject(ALERT_MODULE_CONFIG)
        @Optional()
        private readonly config: AlertServiceConfig | undefined,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly injector: Injector,
        private readonly appRef: ApplicationRef,
    ) {}

    show(message: string, type: AlertType, appLevel: boolean = false) {
        if (this.portalHost) {
            return;
        }

        const selector = (this.config && this.config.hostSelector) || '#alertPlaceholder';

        this.portalHost = new DomPortalHost(document.querySelector(selector), this.componentFactoryResolver, this.appRef, this.injector);

        const portal = new ComponentPortal(AlertComponent);
        const component = portal.attach(this.portalHost);

        component.instance.message = message;
        component.instance.alertType = type;
        component.instance.appLevel = appLevel;
    }

    hide() {
        if (this.portalHost) {
            this.portalHost.detach();
            this.portalHost = undefined;
        }
    }
}
