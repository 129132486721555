import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcInvestorAccountDto } from '../dto/baza-nc-investor-account.dto';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcInvestorAccountCsvDto } from '../dto/baza-nc-investor-account-csv.dto';
import { NorthCapitalAccountId } from '../../../transact-api';
import { BazaNcInvestorAccountSyncStatusDto } from '../dto/baza-nc-investor-account-sync-status.dto';
import { BazaNcInvestorAccountPartyDetailsDto } from '../dto/baza-nc-investor-account-party-details.dto';
import { BazaNcInvestorAccountUpdatePersonalInformationRequest } from '../dto/baza-nc-investor-account-update-party-details.request';

export enum BazaNcInvestorAccountCmsEndpointPaths {
    getInvestorAccount = '/baza-nc/investor-account/cms/getInvestorAccount',
    getInvestorAccountByUserId = '/baza-nc/investor-account/cms/getInvestorAccountByUserId',
    getInvestorAccountPersonalInfo = '/baza-nc/investor-account/cms/getInvestorAccountPersonalInfo',
    updateInvestorAccountPersonalInfo = '/baza-nc/investor-account/cms/updateInvestorAccountPersonalInfo',
    listInvestorAccounts = '/baza-nc/investor-account/cms/listInvestorAccounts',
    addInvestorAccount = '/baza-nc/investor-account/cms/addInvestorAccount',
    removeInvestorAccount = '/baza-nc/investor-account/cms/removeInvestorAccount',
    exportInvestorAccountsToCsv = '/baza-nc/investor-account/cms/exportInvestorAccountsToCsv',
}

export interface BazaNcInvestorAccountCmsEndpoint {
    getInvestorAccount(
        request: NcGetInvestorAccountRequest,
    ): Promise<NcGetInvestorAccountResponse> | Observable<NcGetInvestorAccountResponse>;
    getInvestorAccountByUserId(
        request: NcGetInvestorAccountByUserIdRequest,
    ): Promise<NcGetInvestorAccountByUserIdResponse> | Observable<NcGetInvestorAccountByUserIdResponse>;
    listInvestorAccounts(
        request: NcListInvestorAccountRequest,
    ): Promise<NcListInvestorAccountResponse> | Observable<NcListInvestorAccountResponse>;
    addInvestorAccount(
        request: NcAddInvestorAccountRequest,
    ): Promise<NcAddInvestorAccountResponse> | Observable<NcAddInvestorAccountResponse>;
    removeInvestorAccount(
        request: NcRemoveInvestorAccountRequest,
    ): Promise<NcRemoveInvestorAccountResponse> | Observable<NcRemoveInvestorAccountResponse>;
    exportInvestorAccountsToCsv(request: NcExportToCsvInvestorAccountRequest): Promise<string> | Observable<string>;
    getInvestorAccountPersonalInfo(
        request: NcGetInvestorAccountPersonalInfoRequest,
    ): Promise<NcGetInvestorAccountPersonalInfoResponse> | Observable<NcGetInvestorAccountPersonalInfoResponse>;
    updateInvestorAccountPersonalInfo(request: BazaNcInvestorAccountUpdatePersonalInformationRequest): Promise<void> | Observable<void>;
}

export class NcGetInvestorAccountRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type NcGetInvestorAccountResponse = BazaNcInvestorAccountDto;

export class NcGetInvestorAccountByUserIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;
}

export class NcGetInvestorAccountPersonalInfoRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    isUpdate?: boolean;
}

export type NcGetInvestorAccountByUserIdResponse = BazaNcInvestorAccountDto;

export type NcGetInvestorAccountPersonalInfoResponse = BazaNcInvestorAccountPartyDetailsDto;

export class NcListInvestorAccountRequest extends CrudListRequestDto<BazaNcInvestorAccountDto> {}
export class NcListInvestorAccountResponse extends CrudListResponseDto<BazaNcInvestorAccountDto> {}

export class NcAddInvestorAccountRequest {
    @ApiModelProperty({
        description: 'Account ID',
        example: 1,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty({
        description: 'North Capital Account ID',
        example: 'A12345',
    })
    @IsString()
    @IsNotEmpty()
    ncAccountId: NorthCapitalAccountId;

    @ApiModelProperty({
        description: 'North Capital Party ID',
        example: 'P12345',
    })
    @IsString()
    @IsNotEmpty()
    ncPartyId: NorthCapitalAccountId;

    @ApiModelProperty({
        required: false,
        description: 'Automatically sync transactions & payment methods after adding investor account',
    })
    @IsBoolean()
    @IsOptional()
    sync?: boolean;
}

export class NcAddInvestorAccountResponse {
    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaNcInvestorAccountDto;

    @ApiModelProperty({
        description: 'Sync Results (only if "sync" is true)',
        required: false,
    })
    syncStatus?: BazaNcInvestorAccountSyncStatusDto;
}

export class NcRemoveInvestorAccountRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;
}

export type NcRemoveInvestorAccountResponse = void;

export class NcExportToCsvInvestorAccountRequest extends CrudExportToCsvRequest<
    BazaNcInvestorAccountCsvDto,
    NcListInvestorAccountRequest
> {}
