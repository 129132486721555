import { BazaDwollaPaymentType } from '../models/baza-dwolla-payment-type';
import { BazaDwollaPaymentPayload } from '../models/baza-dwolla-payment-payload';

/**
 * Correlation ID Factory
 * @param payload
 */
export function bazaDwollaCorrelationId(payload: BazaDwollaPaymentPayload): string {
    const type = payload.type;

    switch (payload.type) {
        default: {
            throw new Error(`Unknown type for Correlation ID Factory: "${type}"`);
        }

        case BazaDwollaPaymentType.PurchaseShares: {
            return `purchase_trade_${payload.ncTradeId}`;
        }

        case BazaDwollaPaymentType.Dividend: {
            return `baza_nc_dividend_${payload.dividendEntryUlid}`;
        }
    }
}
