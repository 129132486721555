<ng-container *ngIf="initData?.investorAccount?.isAccountVerificationCompleted; else noPaymentData">
    <div class="payment-methods">
        <app-payment-methods
            [initData]="initData"
            [config]="config?.paymentMethodsConfig"
            [header]="paymentMethodHeader"></app-payment-methods>
    </div>

    <div class="payout-methods">
        <app-payout-methods
            *ngIf="dwollaSupport && config?.payoutMethodsConfig?.showPayoutSection"
            [initData]="initData"
            [isAnyAssetPurchased]="config?.payoutMethodsConfig?.isAnyAssetPurchased"
            [header]="payoutMethodHeader"></app-payout-methods>
    </div>
</ng-container>

<ng-template #noPaymentData>
    <ng-container [ngSwitch]="initData?.investorAccount?.isAccountVerificationInProgress">
        <ng-container *ngSwitchCase="true">
            <nz-empty
                class="info-empty"
                [nzNotFoundContent]="titleTpl"
                [nzNotFoundImage]="imageTpl"
                [nzNotFoundFooter]="footerTpl">
                <ng-template #imageTpl>
                    <svg-icon-sprite src="payment-method-update"></svg-icon-sprite>
                </ng-template>

                <ng-template #titleTpl>
                    <div
                        class="info-empty__title"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.title')"></div>
                </ng-template>
                <ng-template #footerTpl>
                    <div
                        class="info-empty__descr"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.descr')"></div>
                    <button
                        nz-button
                        class="info-empty__btn"
                        (click)="redirectToVFClicked.emit()">
                        {{ wts.getI18nLabel(i18nBasePath, 'emptyStates.inProgress.btnCTA.label') }}
                    </button>
                </ng-template>
            </nz-empty>
        </ng-container>

        <ng-container *ngSwitchCase="false">
            <nz-empty
                class="info-empty"
                [nzNotFoundContent]="titleTpl"
                [nzNotFoundImage]="imageTpl"
                [nzNotFoundFooter]="footerTpl">
                <ng-template #imageTpl>
                    <svg-icon-sprite src="payment-method-empty"></svg-icon-sprite>
                </ng-template>

                <ng-template #titleTpl>
                    <div
                        class="info-empty__title"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.new.title')"></div>
                </ng-template>
                <ng-template #footerTpl>
                    <div
                        class="info-empty__descr"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'emptyStates.new.descr')"></div>
                </ng-template>
            </nz-empty>
        </ng-container>
    </ng-container>
</ng-template>
