import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcDividendDto,
    BazaNcDividendEndpoint,
    BazaNcDividendEndpointPaths,
    BazaNcDividendListRequest,
    BazaNcDividendListResponse,
    BazaNcDividendReprocessRequest,
    BazaNcDividendTotalAmountResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNcDividendDataAccess implements BazaNcDividendEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    list(request: BazaNcDividendListRequest): Observable<BazaNcDividendListResponse> {
        return this.http.post(BazaNcDividendEndpointPaths.list, request);
    }

    getByUlid(ulid: string): Observable<BazaNcDividendDto> {
        return this.http.get(
            replacePathArgs(BazaNcDividendEndpointPaths.getByUlid, {
                ulid,
            }),
        );
    }

    totalAmount(): Observable<BazaNcDividendTotalAmountResponse> {
        return this.http.get(BazaNcDividendEndpointPaths.totalAmount);
    }

    reprocess(request: BazaNcDividendReprocessRequest): Observable<BazaNcDividendDto> {
        return this.http.post(BazaNcDividendEndpointPaths.reprocess, request);
    }
}
