import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { BazaMailCustomerioLinkDto } from '../dto/baza-mail-customerio-link.dto';
import { Observable } from 'rxjs';

export enum BazaMailCustomerioLinEndpointPaths {
    list = '/baza-core/mail/customerio/links/list',
    listByTag = '/baza-core/mail/customerio/links/list/:tag',
    set = '/baza-core/mail/customerio/links/set',
    unset = '/baza-core/mail/customerio/links/unset',
}

export interface BazaMailCustomerioLinkEndpoint {
    list(): Promise<Array<BazaMailCustomerioLinkDto>> | Observable<Array<BazaMailCustomerioLinkDto>>;
    listByTag(tag: string): Promise<Array<BazaMailCustomerioLinkDto>> | Observable<Array<BazaMailCustomerioLinkDto>>;
    set(request: BazaMailCustomerioLinkSetRequest): Promise<BazaMailCustomerioLinkDto> | Observable<BazaMailCustomerioLinkDto>;
    unset(request: BazaMailCustomerioLinksUnsetRequest): Promise<void> | Observable<void>;
}

export class BazaMailCustomerioLinkSetRequest {
    @ApiModelProperty({
        description: 'Name of Mail Template',
        example: 'BazaAccountWebConfirmEmail',
    })
    @IsString()
    @IsNotEmpty()
    templateName: string;

    @ApiModelProperty({
        description: 'Transactional Message Customer.Io Id (Displayed at "Transactional" section)',
        example: 1,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    customerTemplateId: number;
}

export class BazaMailCustomerioLinksUnsetRequest {
    @ApiModelProperty({
        description: 'Name of Mail Template',
        example: 'BazaAccountWebConfirmEmail',
    })
    @IsString()
    @IsNotEmpty()
    templateName: string;
}
