import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';

import { BazaNcRangesNumber } from './ranges/baza-nc-ranges-number';
import { BazaNcRangesEnum } from './ranges/baza-nc-ranges-enum';
import { BazaNcRangesDate } from './ranges/baza-nc-ranges-date';

export type BazaNcSearchRanges = BazaNcRangesNumber | BazaNcRangesEnum | BazaNcRangesDate;

export const allBazaNcSearchRanges = [BazaNcRangesNumber, BazaNcRangesEnum, BazaNcRangesDate];

export const bazaNcSearchRangesSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: allBazaNcSearchRanges.map(($ref) => ({
        $ref: getSchemaPath($ref),
    })),
});
