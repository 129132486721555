import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

export class ListStatesRequestDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsNotEmpty()
    country: string;
}
