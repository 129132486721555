import { CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class CategoryListDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    isActive: boolean;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        required: false,
    })
    parentId?: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    url?: string;
}
