import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { TeamMemberDto } from './team-member.dto';

export class TeamMemberCmsDto extends TeamMemberDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty({
        required: false,
    })
    fullImageS3Key?: string;

    @ApiModelProperty({
        required: false,
    })
    previewImageS3Key?: string;
}
