import { BazaCreditCardType } from '../models/baza-credit-card-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaCreditCardDto {
    @ApiModelProperty({
        description: 'Credit Card Type',
        example: BazaCreditCardType.Visa,
        enum: Object.values(BazaCreditCardType),
    })
    type: BazaCreditCardType;

    @ApiModelProperty({
        description: 'Is credit card number valid?',
        example: true,
    })
    isCreditCardNumberValid: boolean;

    @ApiModelProperty({
        description: 'Gaps',
        type: 'number',
        isArray: true,
        example: [4, 8, 12],
    })
    gaps: Array<number>;

    @ApiModelProperty({
        description: 'Allowed Credit Card Lengths',
        type: 'number',
        isArray: true,
        example: [16, 18, 19],
    })
    lengths: Array<number>;

    @ApiModelProperty({
        description: 'Name for Security Code',
        type: 'string',
        example: 'CVV',
    })
    codeName: string;

    @ApiModelProperty({
        description: 'Length of Security Code',
        type: 'number',
        example: 3,
    })
    codeLength: number;
}
