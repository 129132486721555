import { Observable } from 'rxjs';
import { PhoneCountryCodesRepositoryDto } from '../dto/phone-country-codes-repository.dto';
import { PhoneCountryCodesDto } from '../dto/phone-country-codes.dto';

export enum BazaPhoneCountryCodesEndpointPaths {
    repository = '/baza/phone-country-codes/repository',
    getByNumericCode = '/baza/phone-country-codes/numeric-code/:numericCode',
}

export interface BazaPhoneCountryCodesEndpoint {
    repository(): Promise<PhoneCountryCodesRepositoryDto> | Observable<PhoneCountryCodesRepositoryDto>;
    getByNumericCode(numericCode: string): Promise<PhoneCountryCodesDto> | Observable<PhoneCountryCodesDto>;
}
