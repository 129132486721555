import { BrowserOptions } from '@sentry/browser';
import { NzI18nInterface } from 'ng-zorro-antd/i18n/nz-i18n.interface';
import { en_US } from 'ng-zorro-antd/i18n';
import { InjectionToken } from '@angular/core';
import { BazaWebBundleModuleConfigs } from './baza-web-bundle.module-configs';
import { BazaAuthNgConfig, NgEnvironment } from '@scaliolabs/baza-core-ng';
import { DEFAULT_BAZA_NG_AUTH_MODULE_CONFIG } from './module-configs/baza-ng-auth.module-config';
import { BazaWebBundleEnvironment } from './models/baza-web-bundle-environment';

type JwtStoragesConfig = Pick<BazaAuthNgConfig, 'jwtStorages'>;

export interface BazaWebBundleConfig<ENV extends BazaWebBundleEnvironment = BazaWebBundleEnvironment> {
    apiEndpoint: () => string;
    environment: NgEnvironment;
    environmentConfig: ENV;
    withI18n: boolean;
    builtOnVersion: string;
    links: {
        updateNeededUrl: string;
        maintenanceUrl: string;
    };
    sentry?: {
        dsn: string;
        browserOptions?: BrowserOptions;
    };
    nzI18n: NzI18nInterface;
    moduleConfigs: Partial<BazaWebBundleModuleConfigs>;
}

export class BazaWebBundleConfigBuilder<ENV extends BazaWebBundleEnvironment = BazaWebBundleEnvironment> {
    private readonly _config: BazaWebBundleConfig = {
        apiEndpoint: () => {
            throw new Error('No API endpoint set');
        },
        environment: NgEnvironment.Local,
        environmentConfig: {
            ngEnv: NgEnvironment.Local,
            apiEndpoint: undefined,
            enableAngularProduction: false,
        },
        links: {
            maintenanceUrl: '/service-pages/maintenance',
            updateNeededUrl: '/service-pages/update-needed',
        },
        withI18n: false,
        nzI18n: en_US,
        builtOnVersion: '1.0.0',
        moduleConfigs: {},
    };

    /**
     * Returns configuration for Baza Web Bundle
     */
    get config(): BazaWebBundleConfig {
        return this._config;
    }

    /**
     * Override default Module Configurations
     * @param moduleConfigs
     */
    withModuleConfigs(moduleConfigs: Partial<BazaWebBundleModuleConfigs>): BazaWebBundleConfigBuilder {
        this._config.moduleConfigs = {
            ...this._config.moduleConfigs,
            ...moduleConfigs,
        };

        return this;
    }

    /**
     * Sets an Environment configration
     * Environment configuration (which is stored by default in `environments/` should extends from
     * `BazaWebBundleEnvironment` interface
     * @see BazaWebBundleEnvironment
     * @see Env
     * @param env
     */
    withEnvironment(env: ENV): BazaWebBundleConfigBuilder {
        this._config.environmentConfig = env;
        this._config.environment = env.ngEnv;
        this._config.apiEndpoint = () => env.apiEndpoint;

        return this;
    }

    /**
     * Sets a current Environment
     * Usually it's not required to use this method - you should use `withEnvironment` instead
     * @see withEnvironment
     * @param ngEnv
     */
    withNgEnvironment(ngEnv: NgEnvironment): BazaWebBundleConfigBuilder {
        this.config.environment = ngEnv;

        return this;
    }

    /**
     * Sets Version
     * Web bundle should stored API Version which is used when the application developed & tested. Store it in separated
     * file and updates it on every next API version updates.
     * This version is used for Version interceptors.
     * @see withUpdateNeededPageUrl
     * @param version
     */
    withVersion(version: string): BazaWebBundleConfigBuilder {
        this.config.builtOnVersion = version;

        return this;
    }

    /**
     * Sets an API endpoint (base URL)
     * Usually it's not required to use this method - you should use `withEnvironment` instead
     * @see withEnvironment
     * @param apiEndpoint
     */
    withApiEndpoint(apiEndpoint: string): BazaWebBundleConfigBuilder {
        this.config.apiEndpoint = () => apiEndpoint;

        return this;
    }

    /**
     * Setup Sentry Configuration
     * @see withEnvironment
     * @param dsn
     * @param browserOptions
     */
    withSentry(dsn: string, browserOptions?: BrowserOptions): BazaWebBundleConfigBuilder {
        this.config.sentry = {
            ...this.config.sentry,
            dsn,
            browserOptions: {
                environment: this.config.environment,
                ...browserOptions,
            },
        };

        return this;
    }

    /**
     * Sets list of allowed (used) storages to store JWT token & payload
     * By default Baza is using Local Storage to store JWT token & payload
     * @param authNgJwtConfig
     */
    withJwtStorages(authNgJwtConfig: JwtStoragesConfig): BazaWebBundleConfigBuilder {
        this.config.moduleConfigs = {
            ...this.config.moduleConfigs,
            BazaAuthNgModule: () => ({
                useFactory: () => ({ ...DEFAULT_BAZA_NG_AUTH_MODULE_CONFIG, ...authNgJwtConfig }),
            }),
        };

        return this;
    }

    /**
     * Enables i18n support (using `ngx-translate` package)
     */
    withI18nSupport(): BazaWebBundleConfigBuilder {
        this.config.withI18n = true;

        return this;
    }

    /**
     * Disables i18n support (using `ngx-translate` package)
     */
    withoutI18nSupport(): BazaWebBundleConfigBuilder {
        this.config.withI18n = false;

        return this;
    }

    /**
     * Sets i18n support for Ng-Zorro
     * @param nzI18n
     */
    withNzI18n(nzI18n: NzI18nInterface): BazaWebBundleConfigBuilder {
        this._config.nzI18n = nzI18n;

        return this;
    }

    /**
     * Sets an URL for Maintenance Mode page. User will be redirect to this URL in case if API is reporting that current
     * Application is under Maintenance Mode.
     * @default /service-pages/maintenance
     * @param url
     */
    withMaintenancePageUrl(url: string): BazaWebBundleConfigBuilder {
        this._config.links.maintenanceUrl = url;

        return this;
    }

    /**
     * Sets an URL for Update Needed page. User will be redirected to this URL in case if API is reporting about Version
     * mismatch.
     * You should execute `location.reload(0)` on Update Needed page to force updating cached Angular application to
     * the latest version.
     * @default /service-pages/update-needed
     * @param url
     */
    withUpdateNeededPageUrl(url: string): BazaWebBundleConfigBuilder {
        this._config.links.updateNeededUrl = url;

        return this;
    }
}

const configBuilder = new BazaWebBundleConfigBuilder();

export function bazaWebBundleConfigBuilder(): BazaWebBundleConfigBuilder {
    return configBuilder;
}

export const BAZA_WEB_BUNDLE_CONFIG = new InjectionToken('BAZA_WEB_BUNDLE_CONFIG');
