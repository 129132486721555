import { CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { WhitelistAccountDto } from '../dto/whitelist-account.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsNotEmpty, IsNumber, IsString, MaxLength } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaAccountConstants } from '../../../../baza-account/src';

export enum WhitelistAccountEndpointPaths {
    list = '/baza-core/cms/whitelist-account/list',
    add = '/baza-core/cms/whitelist-account/add',
    remove = '/baza-core/cms/whitelist-account/remove',
    importCsv = '/baza-core/cms/whitelist-account/import-csv',
}

export interface WhitelistAccountEndpoint {
    list(request: WhitelistAccountListRequest): Promise<WhitelistAccountListResponse> | Observable<WhitelistAccountListResponse>;
    add(request: WhitelistAccountAddRequest): Promise<WhitelistAccountDto> | Observable<WhitelistAccountDto>;
    remove(request: WhitelistAccountRemoveRequest): Promise<void> | Observable<void>;
    importCsv(file: File | unknown): Promise<void> | Observable<void>;
}

export class WhitelistAccountListRequest extends CrudListRequestDto<WhitelistAccountDto> {}
export class WhitelistAccountListResponse extends CrudListResponseDto<WhitelistAccountDto> {}

export class WhitelistAccountAddRequest {
    @ApiModelProperty()
    @IsEmail()
    @IsString()
    @MaxLength(BazaAccountConstants.EMAIL_MAX_LENGTH)
    @IsNotEmpty()
    email: string;
}

export class WhitelistAccountRemoveRequest {
    @ApiModelProperty()
    @IsNumber()
    @IsNotEmpty()
    id: number;
}
