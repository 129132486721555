import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcDwollaCustomerDetailDto,
    BazaNcDwollaEndpoint,
    BazaNcDwollaEndpointPaths,
    BazaNcDwollaTouchResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcDwollaDataAccess implements BazaNcDwollaEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    touch(): Observable<BazaNcDwollaTouchResponse> {
        return this.http.post(BazaNcDwollaEndpointPaths.touch);
    }

    current(): Observable<BazaNcDwollaCustomerDetailDto> {
        return this.http.get(BazaNcDwollaEndpointPaths.current);
    }
}
