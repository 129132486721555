import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcInvestorAccountEndpoint,
    BazaNcInvestorAccountEndpointPaths,
    BazaNcInvestorAccountDto,
    BazaNcInvestorAccountStatusDto,
    BazaNcPurchaseFlowTransactionType,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNcInvestorAccountDataAccess implements BazaNcInvestorAccountEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns investor account details
     */
    current(): Observable<BazaNcInvestorAccountDto> {
        return this.http.get(BazaNcInvestorAccountEndpointPaths.current);
    }

    /**
     * Returns investor account verification statuses and whether is a foreign investor or not
     */
    status(): Observable<BazaNcInvestorAccountStatusDto> {
        return this.http.get(BazaNcInvestorAccountEndpointPaths.status);
    }

    /**
     * Sets default payment method of Investor
     * @param paymentMethod
     */
    setDefaultPaymentMethod(paymentMethod: BazaNcPurchaseFlowTransactionType): Observable<void> {
        return this.http.post(
            replacePathArgs(BazaNcInvestorAccountEndpointPaths.setDefaultPaymentMethod, {
                paymentMethod,
            }),
        );
    }
}
