import { BazaNcInvestorAccountDto } from '../dto/baza-nc-investor-account.dto';
import { Observable } from 'rxjs';
import { BazaNcInvestorAccountStatusDto } from '../dto/baza-nc-investor-account-status.dto';
import { BazaNcPurchaseFlowTransactionType } from '../../../purchase-flow';

export enum BazaNcInvestorAccountEndpointPaths {
    current = '/baza-nc/investor-account/current',
    status = '/baza-nc/investor-account/status',
    setDefaultPaymentMethod = '/baza-nc/investor-account/default-payment-method/:paymentMethod',
}

export interface BazaNcInvestorAccountEndpoint {
    current(...args: unknown[]): Promise<BazaNcInvestorAccountDto> | Observable<BazaNcInvestorAccountDto>;
    status(...args: unknown[]): Promise<BazaNcInvestorAccountStatusDto> | Observable<BazaNcInvestorAccountStatusDto>;
    setDefaultPaymentMethod(paymentMethod: BazaNcPurchaseFlowTransactionType, ...args: unknown[]): Promise<void> | Observable<void>;
}
