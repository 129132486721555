import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';
import { BazaNcDocusignDto } from '../dto/baza-nc-docusign.dto';
import { Observable } from 'rxjs';

export enum NcDocusignCmsEndpointPaths {
    getAll = '/baza-nc/docusign/getAll',
    getById = '/baza-nc/docusign/getById',
}

export interface BazaNcDocusignCmsEndpoint {
    getAll(): Promise<Array<BazaNcDocusignDto>> | Observable<Array<BazaNcDocusignDto>>;
    getById(request: NcDocusignCmsGetByIdRequest): Promise<BazaNcDocusignDto> | Observable<BazaNcDocusignDto>;
}

export class NcDocusignCmsGetByIdRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    id: string;
}
