import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { JwtService } from '../services/jwt.service';

/**
 * Helper Interface for Route Data object
 * Implement it in Route's Data object or use it just as documentation source
 */
export interface BazaJwtRequireAclGuardRouteConfig<T = any> {
    jwtRequireAclGuard: Partial<BazaJwtRequireAclGuardConfig> & {
        acl: Array<T>;
    };
}

/**
 * Configuration for JwtRequireAclGuard
 *
 * Default configuration can be replaced with route data, global injectable constant
 * or with bazaWebBundleConfigBuilder helper.
 *
 * @example
 * If you need to replace redirect URL on global level, you can do it somewhere
 * in you AppModule:
 *
 * ```typescript
 * import { BAZA_WEB_BUNDLE_GUARD_CONFIGS } from '@scaliolabs/baza-core-web`
 *
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireAclGuardConfig.redirect = (route: ActivatedRouteSnapshot, requestedACL: Array<MyAcl>, failedAccessNode?: any) => ({
 *     commands: ['/access-denied'],
 *     navigationExtras: {
 *         queryParams: {
 *             requestedACL,
 *             failedAccessNode,
 *         },
 *     },
 * });
 * ```
 *
 * @example
 * You can override configuration for guard with route object and BazaJwtRequireAclGuardRouteConfig
 * interface
 *
 * ```typescript
 * import { MyAcl } from '@scaliolabs/my-shared';
 * import { MyComponent } from './my/my.component';
 * import { ActivatedRouteSnapshot, Routes } from '@angular/router';
 * import { BazaJwtRequireAclGuardRouteConfig, JwtRequireAclGuard } from './libs/baza-core-ng/src';
 *
 * export const myRoutes: Routes = [
 * {
 *        path: 'my',
 *        component: MyComponent,
 *        canActivateChild: [JwtRequireAclGuard],
 *        data: {
 *            jwtRequireAclGuard: {
 *                acl: [MyAcl.MyFeature],
 *                redirect: (route: ActivatedRouteSnapshot, requestedACL: Array<MyAcl>, failedAccessNode?: MyAcl) => ({
 *                    commands: ['/access-denied'],
 *                    navigationExtras: {
 *                        queryParams: {
 *                            requestedACL,
 *                            failedAccessNode,
 *                        }
 *                    }
 *                }),
 *            },
 *        } as BazaJwtRequireAclGuardRouteConfig<MyAcl>,
 *    },
 * ];
 * ```
 *
 * @example
 * If you need to replace configuration on global level, you can override default
 * configuration with bazaWebBundleConfigBuilder.
 *
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               requireAclGuardConfig: {
 *                   // Your configuration
 *               },
 *               // Additional required configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 */
export class BazaJwtRequireAclGuardConfig<T = any> {
    redirect?: (
        route: ActivatedRouteSnapshot,
        requestedACL: Array<T>,
        failedAccessNode?: T,
    ) => {
        commands: any[];
        navigationExtras?: UrlCreationOptions;
    };
}

/**
 * Router guard which will not allow user to access route if he's not
 * an Admin or don't have required ACL.
 *
 * Required ACL's are configured `acl` array in Data object of route.
 *
 * Guard can be used with canActivate or canActivateChild configurations.
 *
 * @example
 * ```typescript
 *   import { Routes } from '@angular/router';
 *   import { JwtRequireAclGuard } from '@scaliolabs/baza-core-ng';
 *   import { MyComponent } from './components/my/my.component';
 *   import { MyAcl } from '@scaliolabs/my-shared';
 *
 *  export const myRoutes: Routes = [{
 *      path: 'my-route',
 *      component: MyComponent,
 *      canActivateChild: [
 *          JwtRequireAclGuard,
 *      ],
 *      data: {
 *          acl: [MyAcl.MyFeature],
 *      },
 * }];
 * ```
 */
@Injectable()
export class JwtRequireAclGuard<T = string> implements CanActivate, CanActivateChild {
    constructor(
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly jwtService: JwtService,
        private readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validateACL(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validateACL(childRoute);
    }

    private config(route: ActivatedRouteSnapshot): BazaJwtRequireAclGuardRouteConfig {
        const moduleConfig = this.moduleConfig.requireAclGuardConfig;
        const customConfig = ((route.data || {}) as BazaJwtRequireAclGuardRouteConfig).jwtRequireAclGuard;

        return {
            jwtRequireAclGuard: {
                ...moduleConfig,
                ...customConfig,
            },
        };
    }

    private validateACL(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const config = this.config(route);

        if (!Array.isArray(config.jwtRequireAclGuard.acl) || config.jwtRequireAclGuard.acl.length === 0) {
            throw new Error('No configuration set for JwtRequireAclGuard set!');
        }

        if (!this.jwtService.hasJwt()) {
            return this.fail(route);
        }

        for (const accessNode of config.jwtRequireAclGuard.acl) {
            if (!this.jwtService.jwtPayload.accountAcl.includes(accessNode)) {
                return this.fail(route, accessNode);
            }
        }

        return true;
    }

    private fail(route: ActivatedRouteSnapshot, failedAccessNode?: T): UrlTree | false {
        const config = this.config(route);

        if (config.jwtRequireAclGuard.redirect) {
            const url = config.jwtRequireAclGuard.redirect(route, config.jwtRequireAclGuard.acl, failedAccessNode);

            return this.router.createUrlTree(url.commands, url.navigationExtras);
        } else {
            return false;
        }
    }
}
