export enum AccountBalanceExitReason {
    DwollaBalanceAlreadyAvailable,
}

export enum DocuSignEvents {
    SigningComplete = 'signing_complete',
    Cancel = 'cancel',
    Decline = 'decline',
}

export enum PaymentEditModalsContentType {
    paymentAdd = 'paymentAdd',
    paymentList = 'paymentList',
}

export enum PaymentMethodType {
    bankAccount = 'bankAccount',
    creditCard = 'creditCard',
    accountBalance = 'accountBalance',
}
