import { PartyId } from '../models/party-id';
import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { EntityId } from '../models/entity-id';

export class RequestKycAmlRequest {
    @IsString()
    @IsNotEmpty()
    investorId: PartyId | EntityId | NorthCapitalAccountId;
}
