import { TransactionType } from '../../../transact-api';

export enum BazaNcPurchaseFlowTransactionType {
    /**
     * Using NC ACH Flow
     */
    ACH = 'ACH',

    /**
     * Using NC Credit Card Flow
     */
    CreditCard = 'CreditCard',

    /**
     * Using Dwolla Account Balance
     */
    AccountBalance = 'TBD',
}

export const purchaseFlowTransactionTypeToNcTransactionTypeMap: Array<{
    purchaseFlowTransactionType: BazaNcPurchaseFlowTransactionType;
    ncTransactionType: TransactionType;
}> = [
    {
        purchaseFlowTransactionType: BazaNcPurchaseFlowTransactionType.ACH,
        ncTransactionType: TransactionType.ACH,
    },
    {
        purchaseFlowTransactionType: BazaNcPurchaseFlowTransactionType.CreditCard,
        ncTransactionType: TransactionType.CreditCard,
    },
    {
        purchaseFlowTransactionType: BazaNcPurchaseFlowTransactionType.AccountBalance,
        ncTransactionType: TransactionType.TBD,
    },
];
