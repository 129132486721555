import { AccountDto } from './account.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class AccountCmsDto extends AccountDto {
    @ApiModelProperty()
    metadata: Record<string, unknown>;

    @ApiModelProperty({
        description: 'System Root account. Will not be displayed to common users',
        required: false,
    })
    isSystemRoot?: boolean;

    @ApiModelProperty({
        description: 'Customer.io ID',
        required: false,
    })
    customerIoId?: string;
}
