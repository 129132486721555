import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BazaWebUtilSharedService, MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { TextPlacement } from './models/text-image-config.interface';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
    selector: 'app-text-image',
    templateUrl: './text-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzGridModule],
})
export class TextImageComponent extends MktSectionCommonFields {
    @Input()
    index = 0;

    constructor(public readonly sanitizer: DomSanitizer, public readonly wts: BazaWebUtilSharedService) {
        super();
    }

    public get i18nBasePath() {
        return `uic.marketing.txtImg.section${this.index}`;
    }

    public get imgSafeStyle() {
        const imgPath = this.wts.getI18nLabel(this.i18nBasePath, 'img');
        if (imgPath) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${imgPath})`);
        }
        return null;
    }

    public isTextOnRight(direction: string) {
        return direction === TextPlacement.Right;
    }
}
