export enum NgEnvironment {
    Local = 'local',
    Prod = 'prod',
    UAT = 'uat',
    Preprod = 'preprod',
    Stage = 'stage',
    Test = 'test',
    E2e = 'e2e',
}

export const ngDevEnvironments: Array<NgEnvironment> = [NgEnvironment.Local];

export const ngTestEnvironments: Array<NgEnvironment> = [NgEnvironment.Test, NgEnvironment.E2e];

export const ngProdEnvironments: Array<NgEnvironment> = [NgEnvironment.Prod, NgEnvironment.UAT, NgEnvironment.Preprod];
