import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive } from 'class-validator';
import { Exclude, Type } from 'class-transformer';
import { BazaCommentStatus } from '../enum/baza-comments-status.enum';

export class BazaCommentsDto {
    @ApiModelProperty({
        description: 'Comment',
    })
    @IsNotEmpty()
    comment: string;

    @ApiModelProperty({
        description: 'Issuer Type',
    })
    @IsNotEmpty()
    issuerType: string;
}

export class BazaCommentsCreateRequest extends BazaCommentsDto {
    @ApiModelProperty({
        description: 'Entity Id',
    })
    @IsNotEmpty()
    @Type(() => Number)
    relationId: number;

    @Exclude()
    @IsOptional()
    accountId?: number;

    @Exclude()
    @IsOptional()
    relationName?: string;
}

export class BazaCommentsResponse extends BazaCommentsDto {
    @ApiModelProperty({
        description: 'Id',
    })
    @IsNotEmpty()
    id: number;

    @ApiModelProperty({
        description: 'User name',
    })
    @IsNotEmpty()
    userName: string;

    @ApiModelProperty({
        description: 'Created At',
    })
    @IsNotEmpty()
    createdAt: Date;

    @ApiModelProperty({
        description: 'Updated At',
    })
    @IsNotEmpty()
    updatedAt: Date;
}

export class BazaCommentsListRequest {
    @ApiModelProperty({
        description: 'Entity Id',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    entityId: number;

    @ApiModelProperty({
        description: 'Page Index',
        required: false,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    index: number;

    @ApiModelProperty({
        description: 'Page Size',
        required: false,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    size: number;
}

export class BazaCommentsListResponse {
    @ApiModelProperty({
        description: 'Page (from 1 to ...)',
        required: false,
    })
    @IsNotEmpty()
    @IsPositive()
    @IsInt()
    @Type(() => Number)
    pager: { index: number; size: number; total: number };

    @ApiModelProperty({
        description: 'List of comments',
    })
    comments: BazaCommentsResponse[];
}

export class BazaCommentsBodyDto {
    @ApiModelProperty({
        description: 'Comment Id',
    })
    @IsOptional()
    id?: number;

    @ApiModelProperty({
        description: 'Account Id',
    })
    @IsNotEmpty()
    accountId?: number;

    @ApiModelProperty({
        description: 'Target Entity to Relation',
    })
    relationTo: string;

    @ApiModelProperty({
        description: 'Relation Id',
    })
    relationId: number;

    @ApiModelProperty({
        description: 'Comment Status',
    })
    @IsNotEmpty()
    status: string | BazaCommentStatus;
}
