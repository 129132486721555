import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';

import { FooterMenuComponent } from './footer-menu.component';
import { UtilModule } from '@scaliolabs/baza-web-utils';

@NgModule({
    declarations: [FooterMenuComponent],
    exports: [FooterMenuComponent],
    imports: [CommonModule, NzMenuModule, RouterModule, UtilModule],
})
export class FooterMenuModule {}
