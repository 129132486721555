<ng-container *ngIf="verification$ | async as verificationInfo">
    <form
        nz-form
        nzLayout="vertical"
        [formGroup]="verificationInvestorForm"
        #investorFormEl>
        <div class="steps-content">
            <div
                nz-row
                nzGutter="30">
                <div
                    nz-col
                    nzXs="12"
                    class="verification__section">
                    <div
                        class="verification__title"
                        data-cy="verification-investor__title"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'title') | sanitizeHtml"></div>

                    <div
                        class="verification__subtitle verification__subtitle-radio"
                        [innerHTML]="wts.getI18nLabel(i18nFormFieldsPath, 'isAccreditedInvestor.label') | sanitizeHtml"></div>

                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="isAccreditedInvestorErrorTemplate">
                            <nz-radio-group
                                formControlName="isAccreditedInvestor"
                                nzName="isAccreditedInvestor">
                                <label
                                    nz-radio
                                    [nzValue]="true"
                                    data-cy="investor-accredited__radio-yes">
                                    <span
                                        class="ant-radio-title"
                                        [innerHTML]="
                                            wts.getI18nLabel(i18nFormFieldsPath, 'isAccreditedInvestor.yesOption.label') | sanitizeHtml
                                        "></span>

                                    <span
                                        class="ant-radio-description"
                                        [innerHTML]="
                                            wts.getI18nLabel(i18nFormFieldsPath, 'isAccreditedInvestor.yesOption.descr') | sanitizeHtml
                                        "></span>
                                </label>

                                <label
                                    nz-radio
                                    [nzValue]="false"
                                    data-cy="investor-accredited__radio-no">
                                    <span
                                        class="ant-radio-title"
                                        [innerHTML]="
                                            wts.getI18nLabel(i18nFormFieldsPath, 'isAccreditedInvestor.noOption.label') | sanitizeHtml
                                        "></span>

                                    <span
                                        class="ant-radio-description"
                                        [innerHTML]="
                                            wts.getI18nLabel(i18nFormFieldsPath, 'isAccreditedInvestor.noOption.descr') | sanitizeHtml
                                        "></span>
                                </label>
                            </nz-radio-group>
                        </nz-form-control>

                        <ng-template
                            #isAccreditedInvestorErrorTemplate
                            let-control>
                            <span data-cy="investor-accredited__error">
                                {{ getErrorMessage(control, 'isAccreditedInvestor') }}
                            </span>
                        </ng-template>
                    </nz-form-item>

                    <div
                        class="verification__subtitle verification__subtitle-radio"
                        [innerHTML]="wts.getI18nLabel(i18nFormFieldsPath, 'isAssociatedWithFINRA.label') | sanitizeHtml"></div>

                    <div class="verification-finra">
                        <div class="verification-finra__association">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="isAssociatedWithFINRAErrorTemplate">
                                    <nz-radio-group
                                        formControlName="isAssociatedWithFINRA"
                                        nzName="isAssociatedWithFINRA"
                                        (ngModelChange)="onFinraAssociationChange($event)">
                                        <label
                                            nz-radio
                                            [nzValue]="true"
                                            class="verification-finra__yes"
                                            [class.verification-finra__yes-required]="showFinraRelationship"
                                            [class.verification-finra__yes-error]="showFinraRelationship && finraRelationshipHasError"
                                            data-cy="investor-finra__radio-yes">
                                            <span
                                                [innerHTML]="
                                                    wts.getI18nLabel(i18nFormFieldsPath, 'isAssociatedWithFINRA.yesOption.label')
                                                "></span>
                                        </label>

                                        <label
                                            nz-radio
                                            [nzValue]="false"
                                            class="verification-finra__no"
                                            data-cy="investor-finra__radio-no-label">
                                            <span
                                                [innerHTML]="
                                                    wts.getI18nLabel(i18nFormFieldsPath, 'isAssociatedWithFINRA.noOption.label')
                                                "></span>
                                        </label>
                                    </nz-radio-group>
                                </nz-form-control>

                                <ng-template
                                    #isAssociatedWithFINRAErrorTemplate
                                    let-control>
                                    <span data-cy="investor-finra__error">
                                        {{ getErrorMessage(control, 'isAssociatedWithFINRA') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            class="verification-finra__relation"
                            *ngIf="showFinraRelationship">
                            <div
                                class="verification-finra__label"
                                [innerHTML]="wts.getI18nLabel(i18nFormFieldsPath, 'finraRelationship.label') | sanitizeHtml"></div>

                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="finraRelationshipErrorTemplate">
                                    <input
                                        formControlName="finraRelationship"
                                        id="finraRelationship"
                                        nz-input
                                        type="text"
                                        data-cy="investor-finra-relation__input" />
                                </nz-form-control>

                                <ng-template
                                    #finraRelationshipErrorTemplate
                                    let-control>
                                    <span data-cy="investor-finra-relation__error">
                                        {{ getErrorMessage(control, 'finraRelationship') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="verification__steps-action">
                <ng-container [ngSwitch]="showConsentCheckbox">
                    <ng-template
                        *ngSwitchCase="true"
                        [ngTemplateOutlet]="stepActionWithDwollaConsent"></ng-template>

                    <ng-template
                        *ngSwitchCase="false"
                        [ngTemplateOutlet]="stepActionWithoutDwollaConsent"></ng-template>
                </ng-container>
            </div>
        </div>

        <ng-template #stepActionWithoutDwollaConsent>
            <div class="steps-action steps-note">
                <ng-template [ngTemplateOutlet]="stepActionButtons"></ng-template>
            </div>
        </ng-template>

        <ng-template #stepActionWithDwollaConsent>
            <div class="steps-action-with-header">
                <div class="steps-note">
                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="dwollaConsentProvidedErrorTemplate">
                            <nz-checkbox-wrapper class="steps-checkbox">
                                <label
                                    nz-checkbox
                                    formControlName="dwollaConsentProvided"
                                    data-cy="investor-consent__label"></label>
                                <span
                                    class="steps-checkbox__desc steps-checkbox__desc-consent"
                                    [innerHTML]="wts.getI18nLabel(i18nFormFieldsPath, 'dwollaConsentProvided.descr') | sanitizeHtml"
                                    appLinkify
                                    [linkConfigPath]="consentLinksConfig"
                                    (click)="acceptConsent($event)"
                                    data-cy="investor-consent__descr"></span>
                            </nz-checkbox-wrapper>
                        </nz-form-control>

                        <ng-template
                            #dwollaConsentProvidedErrorTemplate
                            let-control>
                            <span data-cy="investor-consent__error">
                                {{ getErrorMessage(control, 'dwollaConsentProvided') }}
                            </span>
                        </ng-template>
                    </nz-form-item>
                </div>

                <div class="steps-action">
                    <ng-template [ngTemplateOutlet]="stepActionButtons"></ng-template>
                </div>
            </div>
        </ng-template>

        <ng-template #stepActionButtons>
            <button
                nz-button
                nzSize="large"
                nzType="primary"
                type="submit"
                (click)="onFormSubmit(investorFormEl)"
                data-cy="verification-investor__submit">
                {{ wts.getI18nLabel(i18nBasePath, 'steps.confirmBtnLabel') }}
            </button>

            <a
                nz-button
                nzSize="large"
                nzType="default"
                role="button"
                (click)="backToPreviousStep()"
                data-cy="verification-investor__back">
                {{ wts.getI18nLabel(i18nBasePath, 'steps.backBtnLabel') }}
            </a>
        </ng-template>
    </form>

    <app-verification-file-upload
        *ngIf="fileUploading"
        [verificationStatus]="verificationInfo.currentStep"
        (closeModal)="fileUploading = false"></app-verification-file-upload>
</ng-container>
