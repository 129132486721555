import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcDividendPaymentSource } from '../models/baza-nc-dividend-payment-source';
import { ulid } from 'ulid';
import { BazaNcInvestorAccountDto } from '../../../../investor-account';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import { OfferingId } from '../../../../transact-api';
import { DwollaTransferId } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcDividendFailureReasonDto } from './baza-nc-dividend-failure-reason.dto';

export class BazaNcDividendTransactionEntryDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: new Date().toISOString(),
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: new Date().toISOString(),
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: new Date().toISOString(),
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering ID',
        required: false,
    })
    offeringId?: OfferingId;

    @ApiModelProperty({
        description: 'Offering Title',
        required: false,
    })
    offeringTitle?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcDividendPaymentSource),
    })
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: BazaNcInvestorAccountDto;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaNcDividendPaymentStatus),
        example: BazaNcDividendPaymentStatus.Pending,
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        description: 'Can be updated (edited)?',
    })
    canBeUpdated: boolean;

    @ApiModelProperty({
        description: 'Can be deleted (edited)?',
    })
    canBeDeleted: boolean;

    @ApiModelProperty({
        description: 'Failure Reason (for Failed dividends only)',
    })
    failureReason?: BazaNcDividendFailureReasonDto;

    @ApiModelProperty({
        type: 'string',
        description: 'Dwolla Transfer ID (for Dwolla dividends)',
        required: false,
    })
    dwollaTransferId?: DwollaTransferId;
}
