import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum AttachmentImageType {
    PNG = 'png',
    JPEG = 'jpeg',
    WEBP = 'webp',
}

export enum AttachmentImageSize {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    XL = 'xl',
}

export class AttachmentSizeSetItem {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AttachmentImageSize),
    })
    size: AttachmentImageSize;

    @ApiModelProperty()
    maxWidth: number;
}

export class AttachmentThumbnailRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AttachmentImageType),
        required: false,
    })
    type?: AttachmentImageType;

    @ApiModelProperty({
        required: false,
    })
    id?: string;

    @ApiModelProperty({
        required: false,
    })
    maxWidth?: number;

    @ApiModelProperty({
        required: false,
    })
    maxHeight?: number;

    @ApiModelProperty({
        required: false,
        minimum: 0,
        maximum: 100,
    })
    quality?: number;

    /**
     * Sharp Resize options.
     * @see https://sharp.pixelplumbing.com/api-resize
     */
    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: ['contain', 'cover', 'fill', 'inside', 'outside'],
    })
    fit?: 'contain' | 'cover' | 'fill' | 'inside' | 'outside';

    @ApiModelProperty({
        required: false,
    })
    position?: number | string;

    @ApiModelProperty({
        required: false,
    })
    background?: string | {
        r?: number | undefined;
        g?: number | undefined;
        b?: number | undefined;
        alpha?: number | undefined;
    };

    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: ['nearest', 'cubic', 'mitchell', 'lanczos2', 'lanczos3'],
    })
    kernel?: 'nearest' | 'cubic' | 'mitchell' | 'lanczos2' | 'lanczos3';

    @ApiModelProperty({
        required: false,
    })
    fastShrinkOnLoad?: boolean;

    @ApiModelProperty({
        required: false,
    })
    withoutEnlargement?: boolean;
}

export class AttachmentThumbnailResponse {
    @ApiModelProperty({
        required: false,
    })
    id?: string;

    @ApiModelProperty()
    width: number;

    @ApiModelProperty()
    height: number;

    @ApiModelProperty()
    mime: string;

    @ApiModelProperty()
    s3ObjectId: string;

    @ApiModelProperty({
        required: false,
    })
    url?: string;
}
