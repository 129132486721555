import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { EventDto } from '../dto/event.dto';

export enum EventsEndpointPaths {
    list = '/baza-content-types/events/list',
    getAll = '/baza-content-types/events/all',
    getById = '/baza-content-types/events/id/:id',
    getByUrl = '/baza-content-types/events/url/:url',
}

export interface EventsEndpoint {
    list(request: EventsListRequest): Promise<EventsListResponse> | Observable<EventsListResponse>;
    getAll(): Promise<Array<EventDto>> | Observable<Array<EventDto>>;
    getById(id: number): Promise<EventDto> | Observable<EventDto>;
    getByUrl(url: string): Promise<EventDto> | Observable<EventDto>;
}

export class EventsListRequest extends CrudListRequestQueryDto {}

export class EventsListResponse extends CrudListResponseDto<EventDto> {
    @ApiModelProperty({
        type: EventDto,
        isArray: true,
    })
    items: Array<EventDto>;
}
