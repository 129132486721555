import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';

export enum BazaAclEndpointPaths {
    current = '/baza-core/baza-acl/current',
    getAcl = '/baza-core/baza-acl/getAcl',
    setAcl = '/baza-core/baza-acl/setAcl',
}

export interface AclEndpoint {
    current(...args: unknown[]): Promise<BazaAclCurrentResponse> | Observable<BazaAclCurrentResponse>;
    getAcl(request: BazaAclGetAclRequest, ...args: unknown[]): Promise<BazaAclGetAclResponse> | Observable<BazaAclGetAclResponse>;
    setAcl(request: BazaAclSetAclRequest, ...args: unknown[]): Promise<BazaAclSetAclResponse> | Observable<BazaAclSetAclResponse>;
}

export class BazaAclCurrentResponse {
    @ApiModelProperty()
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    acl: Array<string>;
}

export class BazaAclGetAclRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export class BazaAclGetAclResponse {
    @ApiModelProperty()
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    acl: Array<string>;
}

export class BazaAclSetAclRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty()
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    acl: Array<string>;
}

export class BazaAclSetAclResponse {
    @ApiModelProperty()
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    acl: Array<string>;
}
