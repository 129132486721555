import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { DwollaCustomerId, DwollaTransferId } from '../../../../dwolla-api/src';
import { BazaDwollaPaymentStatus } from './baza-dwolla-payment-status';

export enum BazaDwollaPaymentHistoryAction {
    DwollaTransferInitiated = 'DwollaTransferInitiated',
    DwollaTransferStatusUpdated = 'DwollaTransferStatusUpdated',
    DwollaTransferAchInitiated = 'DwollaTransferAchInitiated',
    DwollaTransferAchCancelled = 'DwollaTransferAchCancelled',
    DwollaTransferAchFailed = 'DwollaTransferAchFailed',
    DwollaTransferAchProcessed = 'DwollaTransferAchProcessed',
}

export class BazaDwollaPaymentHistoryDwollaTransferInitiatedAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferInitiated;
    dwollaCustomerId: DwollaCustomerId;
    dwollaTransferId: DwollaTransferId;
}

export class BazaDwollaPaymentalHistoryDwollaTransferStatusUpdatedAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferStatusUpdated;
    previousStatus: BazaDwollaPaymentStatus;
    newStatus: BazaDwollaPaymentStatus;
}

export class BazaDwollaPaymentalHistoryDwollaTransferAchInitiatedAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferAchInitiated;
    dwollaTransferId: DwollaTransferId;
    achDwollaTransferId: DwollaTransferId;
}

export class BazaDwollaPaymentalHistoryDwollaTransferAchCancelledAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferAchCancelled;
    previousStatus: BazaDwollaPaymentStatus;
    newStatus: BazaDwollaPaymentStatus;
}

export class BazaDwollaPaymentalHistoryDwollaTransferAchFailedAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferAchFailed;
    previousStatus: BazaDwollaPaymentStatus;
    newStatus: BazaDwollaPaymentStatus;
}

export class BazaDwollaPaymentalHistoryDwollaTransferAchProcessedAction {
    type: BazaDwollaPaymentHistoryAction.DwollaTransferAchProcessed;
    previousStatus: BazaDwollaPaymentStatus;
    newStatus: BazaDwollaPaymentStatus;
    individualAchId: string;
}

export type BazaDwollaPaymentHistoryActions =
    | BazaDwollaPaymentHistoryDwollaTransferInitiatedAction
    | BazaDwollaPaymentalHistoryDwollaTransferStatusUpdatedAction
    | BazaDwollaPaymentalHistoryDwollaTransferAchInitiatedAction
    | BazaDwollaPaymentalHistoryDwollaTransferAchCancelledAction
    | BazaDwollaPaymentalHistoryDwollaTransferAchFailedAction
    | BazaDwollaPaymentalHistoryDwollaTransferAchProcessedAction;

export const bazaDwollaPaymentHistorySwaggerExportedDtos = [
    BazaDwollaPaymentHistoryDwollaTransferInitiatedAction,
    BazaDwollaPaymentalHistoryDwollaTransferStatusUpdatedAction,
    BazaDwollaPaymentalHistoryDwollaTransferAchInitiatedAction,
    BazaDwollaPaymentalHistoryDwollaTransferAchCancelledAction,
    BazaDwollaPaymentalHistoryDwollaTransferAchFailedAction,
    BazaDwollaPaymentalHistoryDwollaTransferAchProcessedAction,
];

export const bazaDwollaPaymentHistoryActionsSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaDwollaPaymentHistorySwaggerExportedDtos.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
