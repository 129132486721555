import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';

export class StatsRequestDto {
    @ApiModelProperty({
        description: 'Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    offeringId: string;

    @ApiModelProperty({
        description: 'Requested total amount for transfer. If requestedAmountCents is set, you will receive information about available payment methods',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    requestedAmountCents?: number;

    @ApiModelProperty({
        description: 'If set as true, will not count reserved shares by current user',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    withReservedShares?: boolean;

    @ApiModelProperty({
        description: 'If set as true, will not count all reserved shares from all users',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    withReservedByOtherUsersShares?: boolean;
}
