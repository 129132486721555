import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaEnvironments } from '../../../../baza-common/src';

export enum RegistryType {
    String = 'string',
    JSON = 'json',
    Email = 'email',
    Phone = 'phone',
    Text = 'text',
    Html = 'html',
    Integer = 'integer',
    Double = 'double',
    Boolean = 'boolean',
    Image = 'image',
    File = 'file',
    HttpsLink = 'https-link',
    EmailRecipient = 'email-recipient',
    KeyValue = 'key-value',
}

export type RegistryDefaultValueCallable<V> = (env: BazaEnvironments, environment: any) => V;

export abstract class RegistryBaseNode<T extends RegistryType, V> {
    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    hint?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(RegistryType),
    })
    type: T;

    @ApiModelProperty()
    public: boolean;

    @ApiModelProperty()
    hiddenFromList: boolean;

    @ApiModelProperty({
        description: 'If defined, register key will be available only for some of environments',
        type: 'string',
        required: false,
        isArray: true,
        enum: Object.values(BazaEnvironments),
    })
    forEnvironments?: Array<BazaEnvironments>;

    @ApiModelProperty()
    value?: V;

    @ApiModelProperty()
    label?: string;

    @ApiModelProperty()
    defaults?: V | RegistryDefaultValueCallable<V>;

    @ApiModelProperty()
    optional?: boolean;
}

export class BazaRegistryNodeEmailRecipientCCValue {
    @ApiModelProperty({
        description: 'Name (To)',
    })
    name: string;

    @ApiModelProperty({
        description: 'Email (TO)',
    })
    email: string;
}

export class BazaRegistryNodeLabelValueObject {
    @ApiModelProperty({
        description: 'key',
    })
    key: string;
}

export class BazaRegistryNodeEmailRecipientValue {
    @ApiModelProperty({
        description: 'Name (To)',
    })
    name: string;

    @ApiModelProperty({
        description: 'Email (TO)',
    })
    email: string;

    @ApiModelProperty({
        description: 'CC',
        isArray: true,
        type: BazaRegistryNodeEmailRecipientCCValue,
    })
    cc: Array<BazaRegistryNodeEmailRecipientCCValue>;
}

export class RegistryNodeString extends RegistryBaseNode<RegistryType.String, string> {}
export class RegistryNodeJSON<T = any> extends RegistryBaseNode<RegistryType.JSON, T> {}
export class RegistryNodeEmail extends RegistryBaseNode<RegistryType.Email, string> {}
export class RegistryNodePhone extends RegistryBaseNode<RegistryType.Phone, string> {}
export class RegistryNodeText extends RegistryBaseNode<RegistryType.Text, string> {}
export class RegistryNodeHtml extends RegistryBaseNode<RegistryType.Html, string> {}
export class RegistryNodeInteger extends RegistryBaseNode<RegistryType.Integer, number> {}
export class RegistryNodeDouble extends RegistryBaseNode<RegistryType.Double, number> {}
export class RegistryNodeBoolean extends RegistryBaseNode<RegistryType.Boolean, boolean> {}
export class RegistryNodeImage extends RegistryBaseNode<RegistryType.Image, string> {}
export class RegistryNodeFile extends RegistryBaseNode<RegistryType.File, string> {}
export class RegistryNodeHttpsLink extends RegistryBaseNode<RegistryType.HttpsLink, string> {}
export class RegistryNodeEmailRecipient extends RegistryBaseNode<RegistryType.EmailRecipient, BazaRegistryNodeEmailRecipientValue> {}
export class RegistryNodeKeyValue extends RegistryBaseNode<RegistryType.KeyValue, BazaRegistryNodeLabelValueObject> {}
export type RegistryNode =
    | RegistryNodeString
    | RegistryNodeJSON
    | RegistryNodeEmail
    | RegistryNodePhone
    | RegistryNodeText
    | RegistryNodeHtml
    | RegistryNodeInteger
    | RegistryNodeDouble
    | RegistryNodeBoolean
    | RegistryNodeImage
    | RegistryNodeFile
    | RegistryNodeHttpsLink
    | RegistryNodeEmailRecipient
    | RegistryNodeKeyValue;

export class RegistrySchema {
    [key: string]: RegistrySchema | RegistryNode;
}

export class RegistryPublicNode {
    @ApiModelProperty({
        description: 'Path (unique identifier)',
    })
    id: string;

    @ApiModelProperty({
        type: () => RegistryBaseNode,
        description: 'Node',
    })
    node: RegistryNode;
}
