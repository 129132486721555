import { Injectable } from '@angular/core';
import { BazaPasswordEndpoint, BazaPasswordEndpointPaths, BazaPasswordResourcesDto, BazaPasswordValidateDto, BazaPasswordValidateRequest } from '@scaliolabs/baza-core-shared';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';
import { Observable } from 'rxjs';

@Injectable()
export class BazaPasswordDataAccess implements BazaPasswordEndpoint {
    constructor(
        private readonly dataAccess: BazaDataAccessService,
    ) {}

    resources(): Observable<BazaPasswordResourcesDto> {
        return this.dataAccess.get(BazaPasswordEndpointPaths.resources);
    }

    validate(request: BazaPasswordValidateRequest): Observable<BazaPasswordValidateDto> {
        return this.dataAccess.post(BazaPasswordEndpointPaths.validate, request);
    }
}
