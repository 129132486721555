import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  TimelineCmsCreateRequest,
  TimelineCmsDeleteRequest,
  TimelineCmsDto,
  TimelineCmsEndpoint,
  TimelineCmsEndpointPaths,
  TimelineCmsGetByIdRequest,
  TimelineCmsListRequest,
  TimelineCmsListResponse,
  TimelineCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class TimelineCmsDataAccess implements TimelineCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: TimelineCmsCreateRequest): Observable<TimelineCmsDto> {
    return this.http.post(TimelineCmsEndpointPaths.create, request);
  }

  update(request: TimelineCmsUpdateRequest): Observable<TimelineCmsDto> {
    return this.http.post(TimelineCmsEndpointPaths.update, request);
  }

  delete(request: TimelineCmsDeleteRequest): Observable<void> {
    return this.http.post(TimelineCmsEndpointPaths.delete, request);
  }

  list(request: TimelineCmsListRequest): Observable<TimelineCmsListResponse> {
    return this.http.post(TimelineCmsEndpointPaths.list, request);
  }

  getById(request: TimelineCmsGetByIdRequest): Observable<TimelineCmsDto> {
    return this.http.post(TimelineCmsEndpointPaths.getById, request);
  }
}
