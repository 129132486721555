import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaFormValidatorService } from '@scaliolabs/baza-core-ng';
import {
    BazaNcPurchaseFlowErrorCodes,
    CalculateRegulationLimitsRequestDto,
    PurchaseFlowInvestorRegulationLimitsDto,
} from '@scaliolabs/baza-nc-shared';
import {
    AMOUNT_VALIDATION_CONFIG,
    BazaWebUtilSharedService,
    UtilModule,
    ValidationsPreset,
    i18nValidationTypesEnum,
} from '@scaliolabs/baza-web-utils';
import { withLatestFrom } from 'rxjs';
import { PurchaseState, RecalculateLimits } from '../../data-access';
import { RegCFLimitForm } from './models';
import { CommonModule } from '@angular/common';
import { RegCfOverviewComponent, RegCfConsentComponent } from '@scaliolabs/baza-web-ui-components';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
    selector: 'app-regcf-limit',
    templateUrl: './regcf-limit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        UtilModule,
        ReactiveFormsModule,
        RegCfOverviewComponent,
        RegCfConsentComponent,
        NzModalModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzCheckboxModule,
        NzAlertModule,
        NzPopoverModule,
        NzInputNumberModule,
    ],
})
export class RegCFLimitComponent implements OnInit {
    @Input()
    offeringId: string;

    @Input()
    regCfLimits: PurchaseFlowInvestorRegulationLimitsDto;

    @Input()
    showLowRegCfLimitWarning = false;

    @Output()
    limitAccepted = new EventEmitter<void>();

    @Output()
    limitCancelled = new EventEmitter<void>();

    i18nBasePath = 'dwpf.regCfLimit';
    i18nFormFieldsPath = `${this.i18nBasePath}.form.fields`;
    regCfLimits$ = this.store.select(PurchaseState.regCfLimit);
    amountConfig = AMOUNT_VALIDATION_CONFIG;

    limitForm: RegCFLimitForm = this.fb.group({
        externalRegCfInvestments: [''],
        yearlyIncome: [''],
        netWorth: [''],
        saveInput: false,
        checkSpendMore: false,
    }) as RegCFLimitForm;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store,
        private readonly router: Router,
        private readonly cdr: ChangeDetectorRef,
        public readonly fvs: BazaFormValidatorService,
        public readonly wts: BazaWebUtilSharedService,
    ) {
        this.limitForm.reset();
    }

    ngOnInit(): void {
        this.populateForm();
    }

    public get amountValidationMaxLength() {
        return this.wts.dollarParse(this.amountConfig.maxValue.toString())?.length;
    }

    populateForm() {
        if (this.regCfLimits) {
            this.externalRegCfInvestmentsCtrl?.setValue((this.regCfLimits.externalRegCfInvestmentsCents ?? 0) / 100);

            if (this.regCfLimits.yearlyIncomeCents) {
                this.yearlyIncomeCtrl?.setValue(this.regCfLimits.yearlyIncomeCents / 100);
            }

            if (this.regCfLimits.netWorthCents) {
                this.netWorthCtrl?.setValue(this.regCfLimits.netWorthCents / 100);
            }

            if ((this.regCfLimits.yearlyIncomeCents && this.regCfLimits.netWorthCents) || this.showLowRegCfLimitWarning) {
                this.toggleAdditionalFields(false);
                this.checkSpendMoreCtrl.setValue(true);
            }

            this.markFormAsPristineUnTouched();
            this.limitForm.updateValueAndValidity();

            this.cdr.detectChanges();
        }
    }

    calculateLimit(saveInput = false) {
        if (this.fvs.isFormValid(this.limitForm)) {
            const data: CalculateRegulationLimitsRequestDto = { ...this.getFormData(), saveInput: saveInput };
            this.markFormAsPristineUnTouched();

            this.store
                .dispatch(new RecalculateLimits(data))
                .pipe(withLatestFrom(this.regCfLimits$))
                .subscribe(([_, response]) => {
                    if (saveInput) {
                        this.limitAccepted.next();
                    } else {
                        if (
                            response.details?.errorCode === BazaNcPurchaseFlowErrorCodes.RegulationCFLimitLessThanMinimumPurchaseAmount ||
                            response.details?.errorCode === BazaNcPurchaseFlowErrorCodes.RegulationInvestmentLimitReached
                        ) {
                            this.showLowRegCfLimitWarning = true;
                            this.checkSpendMoreCtrl.setValue(true);

                            if (!this.yearlyIncomeCtrl.value && !this.netWorthCtrl.value) {
                                this.onCheckSpendMoreChange();
                            }
                        } else {
                            this.showLowRegCfLimitWarning = false;
                        }
                    }

                    this.cdr.detectChanges();
                });
        }
    }

    public getFormData(): CalculateRegulationLimitsRequestDto {
        const data: CalculateRegulationLimitsRequestDto = {
            offeringId: this.offeringId,
            saveInput: false,
            externalRegCfInvestmentsCents:
                this.externalRegCfInvestmentsCtrl?.value !== null && this.externalRegCfInvestmentsCtrl?.value !== ''
                    ? Math.round(this.externalRegCfInvestmentsCtrl?.value * 100)
                    : null,
            yearlyIncomeCents:
                this.yearlyIncomeCtrl?.value !== null && this.yearlyIncomeCtrl?.value !== ''
                    ? Math.round(this.yearlyIncomeCtrl?.value * 100)
                    : null,
            netWorthCents:
                this.netWorthCtrl?.value !== null && this.netWorthCtrl?.value !== '' ? Math.round(this.netWorthCtrl?.value * 100) : null,
        };

        return data;
    }

    public getErrorMessage(control: UntypedFormControl, controlName: string): string {
        const validationsPreset: ValidationsPreset = new Map([
            ['yearlyIncome', [{ key: i18nValidationTypesEnum.required }]],
            ['netWorth', [{ key: i18nValidationTypesEnum.required }]],
        ]);

        return this.wts.geti18nValidationErrorMessages({
            control,
            controlName,
            i18nFormFieldsPath: this.i18nFormFieldsPath,
            i18nGenericValidationsPath: `${this.i18nBasePath}.form.fields.genericValidators`,
            validationsPreset,
        });
    }

    toggleAdditionalFields(reset = true) {
        if (reset) {
            this.yearlyIncomeCtrl.setErrors(null);
            this.yearlyIncomeCtrl.clearValidators();
            this.yearlyIncomeCtrl.updateValueAndValidity();

            this.netWorthCtrl.setErrors(null);
            this.netWorthCtrl.clearValidators();
            this.netWorthCtrl.updateValueAndValidity();

            this.yearlyIncomeCtrl.reset();
            this.netWorthCtrl.reset();
        } else {
            this.yearlyIncomeCtrl.setValidators(Validators.compose([Validators.required]));
            this.yearlyIncomeCtrl.updateValueAndValidity();

            this.netWorthCtrl.setValidators(Validators.compose([Validators.required]));
            this.netWorthCtrl.updateValueAndValidity();
        }

        this.cdr.detectChanges();
    }

    public goBack() {
        this.router.navigateByUrl(this.store.selectSnapshot(PurchaseState.pfConfig)?.back);
    }

    public onCheckSpendMoreChange() {
        this.toggleAdditionalFields();
        this.markFormAsPristineUnTouched();

        if (this.checkSpendMoreCtrl?.value === true) {
            this.toggleAdditionalFields(false);
        }
    }

    public markFormAsPristineUnTouched() {
        this.limitForm.markAsPristine();
        this.limitForm.markAsUntouched();

        this.cdr.detectChanges();
    }

    public onExternalRegCfInvestmentChange() {
        if (!this.limitForm.pristine && this.externalRegCfInvestmentsCtrl?.value === '') {
            this.externalRegCfInvestmentsCtrl?.setValue('0');
        }
    }

    // #region Utility Functions
    private get externalRegCfInvestmentsCtrl() {
        return this.limitForm?.controls?.externalRegCfInvestments;
    }

    private get yearlyIncomeCtrl() {
        return this.limitForm?.controls?.yearlyIncome;
    }

    private get netWorthCtrl() {
        return this.limitForm?.controls?.netWorth;
    }

    private get checkSpendMoreCtrl() {
        return this.limitForm?.controls?.checkSpendMore;
    }

    public get isModalClosable() {
        return this.regCfLimits?.externalRegCfInvestmentsCents !== null ? true : false;
    }

    public get showCheckSpendMoreSection() {
        return this.checkSpendMoreCtrl.value === true;
    }

    // #endregion
}
