import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { BazaNcCreditCardDto, BazaNcLimitsDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, PaymentButtonStyleEnum, PaymentCardConfig, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
    selector: 'app-payment-card',
    templateUrl: './payment-card.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, NzButtonModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentCardComponent {
    @Input()
    srcData?: BazaNcCreditCardDto;

    @Input()
    limits?: BazaNcLimitsDto;

    @Input()
    config?: PaymentCardConfig;

    @Output()
    upsertCard = new EventEmitter<void>();

    public i18nBasePath = 'uic.paymentCard';
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    public getPurchaseLimitWarningMsg(maxAmount: string) {
        return this.wts.getI18nLabel(this.i18nBasePath, 'alerts.limitsWarning', { maxAmount });
    }

    getCardIcon(brand: string) {
        return this.wts.getCardIcon(brand);
    }
}
