import { IsNotEmpty, IsString } from 'class-validator';
import { PartyId } from '../models/party-id';
import { DocumentId } from '../models/document-id';

export class DeletePartyDocumentRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsString()
    @IsNotEmpty()
    documentId: DocumentId;
}
