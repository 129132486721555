import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';

@UntilDestroy()
@Component({
    selector: 'app-verification-stepper',
    templateUrl: './stepper.component.html',
})
export class VerificationStepperComponent {
    currentTab: number;
    i18nBasePath = 'dwvf.vf';

    constructor(private readonly route: ActivatedRoute, public readonly wts: BazaWebUtilSharedService) {
        this.route.data.pipe(untilDestroyed(this)).subscribe((params: Params) => {
            this.currentTab = Number(params.tab) || 0;
        });
    }
}
