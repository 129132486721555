import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';

export class TagDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        required: false,
    })
    url?: string;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    seo: BazaSeoDto;
}
