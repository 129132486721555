import { BazaNcDividendPaymentStatus, BazaNcOperationType, TransactionState } from '@scaliolabs/baza-nc-shared';

export interface TransactionFilter {
    scopes?: Array<BazaNcOperationType>;
    states?: Array<TransactionState | BazaNcDividendPaymentStatus>;
}
export interface TransactionFilterBindings {
    scope?: string;
    state?: string;
    asset?: string;
    timePeriod?: string;
}

export enum DefaultFilterValue {
    All = 'All',
}
