import { CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationListingsDto } from '../../../../listings/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcSearchResponseDto extends CrudListResponseDto<BazaNcIntegrationListingsDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationListingsDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationListingsDto>;
}
