import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNumber, IsString } from 'class-validator';

export class CrudSetSortOrderByUlidRequestDto {
    @ApiModelProperty()
    @IsString()
    ulid: string;

    @ApiModelProperty()
    @IsNumber()
    setSortOrder: number;
}
