import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOfferingListItemDto } from '../../../offering';
import { TransactionState } from '../models/transaction-state';

export class NcOfferingInvestmentDto {
    @ApiModelProperty()
    ncOffering: BazaNcOfferingListItemDto;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
    })
    lastTransactionState: TransactionState;

    @ApiModelProperty()
    purchasedShares: number;

    @ApiModelProperty({
        example: '120.55',
    })
    purchasedAmountTotal: string;

    @ApiModelProperty({
        example: 12055,
    })
    purchasedAmountTotalCents: number;
}
