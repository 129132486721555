import { Component, Injectable, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BazaNcBankAccountIdentity, BazaNcBankAccountLinkOnSuccessRequest, BazaNcDwollaTouchResponse } from '@scaliolabs/baza-nc-shared';
import { IframeIntegrationComponent } from '@scaliolabs/baza-web-ui-components';
import { BazaWebUtilSharedService, EffectsUtil } from '@scaliolabs/baza-web-utils';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxPlaidLinkService, PlaidLinkHandler } from 'ngx-plaid-link';
import { BehaviorSubject, Observable, concatMap, filter, finalize, of, switchMap, tap } from 'rxjs';
import { AccountBalanceExitReason, PLAID_CONFIG } from '../models';
import {
    InvestorAccountTouch,
    LoadBankAccount,
    LoadCreditCardStripe,
    LoadNCStripeLink,
    LoadPlaidLegacyLink,
    LoadPlaidPluginLink,
    PlaidOnSuccessLink,
    PurchaseState,
    UnlinkCreditCardStripe,
    UpsertCreditCardStripe,
} from '../store';

/**
 * A shared service for PF related functionality
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class PFSharedService {
    showDwollaAccountCreationError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly store: Store,
        private readonly notification: NzNotificationService,
        private readonly plaidLinkService: NgxPlaidLinkService,
        private readonly modalService: NzModalService,
        private readonly wts: BazaWebUtilSharedService,
        private readonly effectsUtil: EffectsUtil,
    ) {}

    // #region Plaid Flow (New - Plugin Approach) - Used to link cash-in / cash-out accounts
    public processPlaidPluginFlow(config?: {
        onSuccess?: (publicToken: string, identity: BazaNcBankAccountIdentity) => void;
        onExit?: () => void;
    }) {
        let plaidLinkHandler: PlaidLinkHandler;
        this.store
            .dispatch(new LoadPlaidPluginLink())
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    const plaidResponse = this.store.selectSnapshot(PurchaseState.plaidPluginLink);
                    if (plaidResponse) {
                        this.plaidLinkService
                            .createPlaid(
                                Object.assign(
                                    {},
                                    {
                                        ...PLAID_CONFIG,
                                        token: plaidResponse?.linkToken,
                                        env: plaidResponse?.environment,
                                        onSuccess: (token: string, identity: BazaNcBankAccountIdentity) => {
                                            plaidLinkHandler?.destroy();
                                            config?.onSuccess && config.onSuccess(token, identity);
                                        },
                                        onExit: () => {
                                            plaidLinkHandler?.destroy();
                                            config?.onExit && config.onExit();
                                        },
                                    },
                                ),
                            )
                            .then((handler: PlaidLinkHandler) => {
                                plaidLinkHandler = handler;
                                handler.open();
                            })
                            .catch(() => {
                                plaidLinkHandler?.destroy();
                                this.notification.error('Could not link payment', '');
                            });
                    } else {
                        this.notification.error('Could not link payment', '');
                    }
                }),
            )
            .subscribe();
    }

    public processDwollaLinkBankAccount(data: BazaNcBankAccountLinkOnSuccessRequest, onSuccess: () => void): void {
        this.store.dispatch(new PlaidOnSuccessLink(data)).subscribe(() => {
            onSuccess();
        });
    }
    // #endregion

    // #region Plaid Flow (Legacy - iFrame Approach) - Used to link ACH Plaid bank account
    public processPlaidLegacyFlow(): Observable<boolean> {
        let modalRef: NzModalRef<IframeIntegrationComponent<string>, any>;
        const i18nErrorMsg = this.wts.getI18nLabel('dwpf.notifications', 'plaid_link_fail');

        return this.store.dispatch(new LoadPlaidLegacyLink()).pipe(
            switchMap(() => {
                const plaidLink = this.store.selectSnapshot(PurchaseState.plaidLegacyLink);
                modalRef = this.createACHPlaidModal(plaidLink);
                return modalRef.componentInstance.iframeEvent.pipe(
                    switchMap((result) => {
                        modalRef.close();
                        return this.processIFrameEvent(result, 'plaid_link_fail');
                    }),
                    filter((success) => success),
                    concatMap(() => this.store.dispatch(new LoadBankAccount())),
                );
            }),
            this.effectsUtil.tryCatchRethrownErrorNotify$(i18nErrorMsg),
            finalize(() => {
                modalRef.close();
            }),
        );
    }

    createACHPlaidModal(link: string) {
        return this.modalService.create({
            nzClassName: 'ant-modal-transparent',
            nzClosable: false,
            nzContent: IframeIntegrationComponent,
            nzComponentParams: {
                url: link,
                classList: 'iframe-ach',
            } as Partial<IframeIntegrationComponent<string>>,
            nzFooter: null,
            nzWidth: 360,
        });
    }
    // #endregion

    // #region Stripe Flow (iFrame Approach) - Used to link CC
    public unlinkAndProcessStripeCCFlow(tplTitle: TemplateRef<Component>) {
        return this.store.dispatch(new UnlinkCreditCardStripe()).pipe(
            concatMap(() => this.store.dispatch(new LoadCreditCardStripe())),
            switchMap(() => {
                this.wts.refreshInitData$.next();
                return this.processStripeCCFlow(tplTitle);
            }),
        );
    }

    public processStripeCCFlow(tplTitle: TemplateRef<Component>): Observable<boolean> {
        let modalRef: NzModalRef<IframeIntegrationComponent<string>, any>;
        const i18nErrorMsg = this.wts.getI18nLabel('dwpf.notifications', 'stripe_link_fail');

        return this.store.dispatch(new LoadNCStripeLink()).pipe(
            switchMap(() => {
                const plaidLink = this.store.selectSnapshot(PurchaseState.stripeLink)?.link;
                modalRef = this.createStripeCCModal(tplTitle, plaidLink);
                return modalRef.componentInstance.iframeEvent.pipe(
                    switchMap((result) => {
                        modalRef.close();
                        return this.processIFrameEvent(result, 'stripe_link_fail');
                    }),
                    filter((success) => success),
                    concatMap(() => this.store.dispatch(new UpsertCreditCardStripe())),
                );
            }),
            this.effectsUtil.tryCatchRethrownErrorNotify$(i18nErrorMsg),
            finalize(() => {
                modalRef.close();
            }),
        );
    }

    createStripeCCModal = (tplTitle: TemplateRef<Component>, link: string) => {
        return this.modalService.create({
            nzTitle: tplTitle,
            nzContent: IframeIntegrationComponent,
            nzClassName: 'stripe-modal',
            nzComponentParams: {
                url: link,
                classList: 'iframe-stripe',
            } as Partial<IframeIntegrationComponent<string>>,
            nzFooter: null,
            nzWidth: 480,
            nzClosable: true,
        });
    };
    // #endregion

    // #region Process iFrame Events (For ACH Plaid and Stripe CC)
    processIFrameEvent(result: string, i18nErrorMsgKey: string): Observable<boolean> {
        const jsonRes = JSON.parse(result);
        const i18nErrorMsg = this.wts.getI18nLabel('dwpf.notifications', i18nErrorMsgKey);

        if (!jsonRes || jsonRes['error']) {
            this.notification.error(i18nErrorMsg, '');
        } else if (jsonRes['statusCode'] && (jsonRes['statusCode'] ?? '').toString() === '101') {
            return of(true);
        }

        return of(false);
    }

    processCCIFrameEvent(result: string): Observable<boolean> {
        const i18nErrorMsg = this.wts.getI18nLabel('dwpf.notifications', 'stripe_link_fail');

        return this.processIFrameEvent(result, 'stripe_link_fail').pipe(
            filter((success) => success),
            concatMap(() => this.store.dispatch(new UpsertCreditCardStripe())),
            this.effectsUtil.tryCatchRethrownErrorNotify$(i18nErrorMsg),
        );
    }
    // #endregion

    // #region Dwolla Account Creation Flow
    public processAccountBalanceCreation({
        isDwollaAvailable,
        onExit,
        onInvestorTouched,
    }: {
        isDwollaAvailable: boolean;
        onExit: (reason: AccountBalanceExitReason) => void;
        onInvestorTouched: (Purchase: BazaNcDwollaTouchResponse | null) => void;
    }): void {
        if (isDwollaAvailable) {
            return onExit(AccountBalanceExitReason.DwollaBalanceAlreadyAvailable);
        }
        this.store
            .dispatch(new InvestorAccountTouch())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                onInvestorTouched(this.store.selectSnapshot(PurchaseState.dwollaInvestorTouchResponse));
            });
    }
    // #endregion
}
