import { IsNotEmpty, IsString } from 'class-validator';
import { IssuerId } from '../models/issuer-id';

export class SearchOfferingRequest {
    @IsString()
    @IsNotEmpty()
    searchKeyword: string;

    @IsString()
    @IsNotEmpty()
    issuerId: IssuerId;
}
