import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CrudExportToCsvField<DTO> {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    field: keyof DTO;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsOptional()
    disabled?: boolean;
}
