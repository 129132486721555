import { PaymentButtonStyleEnum } from './pf-vf-account-lib.enums';

export const AMOUNT_VALIDATION_CONFIG: AmountValidationConfig = {
    minValue: 0.1,
    maxValue: 1000000,
    precision: 2,
    step: 0.1,
    precisionMode: 'toFixed',
};

export const CARD_BRAND_ICONS: Array<CardBrandIconPath> = [
    { brand: 'Visa', icon: 'visa' },
    { brand: 'Mastercard', icon: 'mastercard' },
    { brand: 'Discover', icon: 'discover' },
    { brand: 'JCB', icon: 'jcb' },
    { brand: 'American Express', icon: 'american-express' },
    { brand: 'UnionPay', icon: 'union-pay' },
    { brand: 'Diners Club', icon: 'diners-club' },
];

export interface CardBrandIconPath {
    brand: string;
    icon: string;
}

export interface AmountValidationConfig {
    minValue: number;
    maxValue: number;
    step: number;
    precision: number;
    precisionMode: 'cut' | 'toFixed';
}
export interface PaymentAccountBalanceUsageConfig {
    showAccountBalance?: boolean;
}

export interface PaymentAccountPayoutUsageConfig {
    showPayoutSection?: boolean;
}
export interface PaymentBankUsageConfig {
    showBankAccount?: boolean;
}

export interface PaymentCardUsageConfig {
    showCreditCard?: boolean;
}

export interface PaymentBankAccountConfig extends PaymentBankUsageConfig {
    isEditModeOn?: boolean;
    isAddModeOn?: boolean;
    style?: PaymentButtonStyleEnum;
}
export interface PaymentCardConfig extends PaymentCardUsageConfig {
    isPurchaseAboveLimit?: boolean;
    isEditModeOn?: boolean;
    isAddModeOn?: boolean;
    style?: PaymentButtonStyleEnum;
}

export interface PaymentBankItemConfig extends PaymentBankUsageConfig {
    sectionHeader?: string;
}

export interface PaymentCardItemConfig extends PaymentCardUsageConfig {
    sectionHeader?: string;
}

export interface PaymentAccountBalanceItemConfig extends PaymentAccountBalanceUsageConfig {
    showCashInAccount?: boolean;
}
