import { AbstractControl, ValidationErrors } from '@angular/forms';

export const onlyNumbersValidator = (): ((AbstractControl) => ValidationErrors | null) => {
    return (control: AbstractControl): ValidationErrors | null => {
        const targetValue = control.value;

        if (!targetValue) {
            return null;
        }

        const regex = RegExp(/^[0-9]+$/gm);

        return regex.test(targetValue) ? null : { onlynumbers: true };
    };
};
