import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationSchemaDto } from '../dto/baza-nc-integration-schema.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import {
    BazaNcIntegrationSchemaDefinition,
    bazaNcIntegrationSchemaDefinitionSwagger,
} from '../models/baza-nc-integration-schema-definition';
import { Observable } from 'rxjs';
import { BazaNcIntegrationSchemaTab } from '../models/baza-nc-integration-schema-tab';
import { Type } from 'class-transformer';
import { BazaNcOfferingRegulationType } from '@scaliolabs/baza-nc-shared';

export enum BazaNcIntegrationSchemaCmsEndpointPaths {
    create = '/baza-nc-integration/cms/schema/create',
    update = '/baza-nc-integration/cms/schema/update',
    rename = '/baza-nc-integration/cms/schema/rename',
    delete = '/baza-nc-integration/cms/schema/delete',
    setSortOrder = '/baza-nc-integration/cms/schema/setSortOrder',
    list = '/baza-nc-integration/cms/schema/list',
    getById = '/baza-nc-integration/cms/schema/getById',
    supportedRegulationTypes = '/baza-nc-integration/cms/schema/supportedRegulations',
}

export interface BazaNcIntegrationSchemaCmsEndpoint {
    create(request: BazaNcIntegrationSchemaCmsCreateRequest): Promise<BazaNcIntegrationSchemaDto> | Observable<BazaNcIntegrationSchemaDto>;
    update(request: BazaNcIntegrationSchemaCmsUpdateRequest): Promise<BazaNcIntegrationSchemaDto> | Observable<BazaNcIntegrationSchemaDto>;
    rename(request: BazaNcIntegrationSchemaCmsRenameRequest): Promise<BazaNcIntegrationSchemaDto> | Observable<BazaNcIntegrationSchemaDto>;
    delete(request: BazaNcIntegrationSchemaCmsDeleteRequest): Promise<void> | Observable<void>;
    list(
        request: BazaNcIntegrationSchemaCmsListRequest,
    ): Promise<BazaNcIntegrationSchemaCmsListResponse> | Observable<BazaNcIntegrationSchemaCmsListResponse>;
    getById(
        request: BazaNcIntegrationSchemaCmsGetByIdRequest,
    ): Promise<BazaNcIntegrationSchemaDto> | Observable<BazaNcIntegrationSchemaDto>;
    setSortOrder(request: BazaNcIntegrationSchemaCmsSetSortOrderRequest): Promise<void> | Observable<void>;
    supportedRegulationTypes():
        | Promise<BazaNcIntegrationSchemaCmsSupportedRegulationsResponse>
        | Observable<BazaNcIntegrationSchemaCmsSupportedRegulationsResponse>;
}

export class BazaNcIntegrationSchemaCmsEntityBody {
    @ApiModelProperty({
        description: 'Will be displayed in UI elements for users?',
    })
    @IsBoolean()
    @IsNotEmpty()
    published: boolean;

    @ApiModelProperty({
        description: 'Will define type of regulation for the listing/offering, default type is RegA regulation',
        type: 'string',
        enum: Object.values(BazaNcOfferingRegulationType),
        default: BazaNcOfferingRegulationType.RegA,
    })
    @IsEnum(BazaNcOfferingRegulationType)
    @IsNotEmpty()
    regulationType?: BazaNcOfferingRegulationType = BazaNcOfferingRegulationType.RegA;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description:
            'Display Name (i.e. Title which will be displayed in UI elements for users). Will be available only if Schema is marked as Public',
    })
    @IsString()
    @IsOptional()
    displayName?: string;

    @ApiModelProperty({
        ...bazaNcIntegrationSchemaDefinitionSwagger(),
    })
    @IsArray()
    @IsNotEmpty()
    definitions: Array<BazaNcIntegrationSchemaDefinition>;

    @ApiModelProperty({
        required: false,
        type: BazaNcIntegrationSchemaTab,
        isArray: true,
        description: 'Tabs',
    })
    @ValidateNested({ each: true })
    @Type(() => BazaNcIntegrationSchemaTab)
    @IsArray()
    @IsOptional()
    tabs?: Array<BazaNcIntegrationSchemaTab>;
}

export class BazaNcIntegrationSchemaCmsCreateRequest extends BazaNcIntegrationSchemaCmsEntityBody {}

export class BazaNcIntegrationSchemaCmsUpdateRequest extends BazaNcIntegrationSchemaCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationSchemaCmsRenameRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class BazaNcIntegrationSchemaCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationSchemaCmsListRequest extends CrudListRequestDto<BazaNcIntegrationSchemaDto> {}

export class BazaNcIntegrationSchemaCmsListResponse extends CrudListResponseDto<BazaNcIntegrationSchemaDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationSchemaDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationSchemaDto>;
}

export class BazaNcIntegrationSchemaCmsSupportedRegulationsResponse {
    @ApiModelProperty({
        isArray: true,
    })
    items: Array<BazaNcOfferingRegulationType>;
}

export class BazaNcIntegrationSchemaCmsSupportedRegulationTypesDto extends BazaNcIntegrationSchemaCmsSupportedRegulationsResponse {}

export class BazaNcIntegrationSchemaCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationSchemaCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}
