import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcDividendTransactionDto } from '../dto/baza-nc-dividend-transaction.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ulid } from 'ulid';
import { Observable } from 'rxjs';
import { BazaNcDividendTransactionStatus } from '../models/baza-nc-dividend-transaction-status';
import { BazaNcDividendTransactionCsvDto } from '../dto/baza-nc-dividend-transaction-csv.dto';
import { BazaNcDividendFailureReasonDto } from '../dto/baza-nc-dividend-failure-reason.dto';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';

export enum BazaNcDividendTransactionCmsEndpointPaths {
    create = '/baza-nc/cms/dividend/transaction/create',
    delete = '/baza-nc/cms/dividend/transaction/delete',
    list = '/baza-nc/cms/dividend/transaction/list',
    getByUlid = '/baza-nc/cms/dividend/transaction/getByUlid',
    process = '/baza-nc/cms/dividend/transaction/process',
    reprocess = '/baza-nc/cms/dividend/transaction/reprocess',
    sync = '/baza-nc/cms/dividend/transaction/sync',
    sendProcessConfirmation = '/baza-nc/cms/dividend/transaction/sendProcessConfirmation',
    exportToCsv = '/baza-nc/cms/dividend/transaction/exportToCsv',
}

export interface BazaNcDividendTransactionCmsEndpoint {
    create(
        request: BazaNcDividendTransactionCmsCreateRequest,
    ): Promise<BazaNcDividendTransactionDto> | Observable<BazaNcDividendTransactionDto>;
    delete(request: BazaNcDividendTransactionCmsDeleteRequest): Promise<void> | Observable<void>;
    list(
        request: BazaNcDividendTransactionCmsListRequest,
    ): Promise<BazaNcDividendTransactionCmsListResponse> | Observable<BazaNcDividendTransactionCmsListResponse>;
    getByUlid(
        request: BazaNcDividendTransactionCmsGetByUlidRequest,
    ): Promise<BazaNcDividendTransactionDto> | Observable<BazaNcDividendTransactionDto>;
    sendProcessConfirmation(
        request: BazaNcDividendTransactionCmsSendProcessConfirmationRequest,
        ...args: unknown[]
    ): Promise<void> | Observable<void>;
    process(
        request: BazaNcDividendTransactionCmsProcessRequest,
        ...args: unknown[]
    ): Promise<BazaNcDividendTransactionCmsProcessResponse> | Observable<BazaNcDividendTransactionCmsProcessResponse>;
    reprocess(
        request: BazaNcDividendTransactionCmsReprocessRequest,
    ): Promise<BazaNcDividendTransactionCmsReprocessResponse> | Observable<BazaNcDividendTransactionCmsReprocessResponse>;
    sync(request: BazaNcDividendTransactionCmsSyncRequest): Promise<void> | Observable<void>;
    exportToCsv(request: BazaNcDividendTransactionCmsExportToCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcDividendTransactionCmsCreateRequest {
    @ApiModelProperty({
        description: 'Title for Dividend Transaction',
    })
    @IsString()
    @IsNotEmpty()
    title: string;
}

export class BazaNcDividendTransactionCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionCmsListRequest extends CrudListRequestDto<BazaNcDividendTransactionDto> {}

export class BazaNcDividendTransactionCmsListResponse extends CrudListResponseDto<BazaNcDividendTransactionDto> {}

export class BazaNcDividendTransactionCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionCmsSendProcessConfirmationRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionCmsProcessRequest {
    @ApiModelProperty({
        description: 'Token from Confirmation email',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    token: string;

    @ApiModelProperty({
        description: 'Current Admin Account password',
    })
    @IsString()
    password: string;

    @ApiModelProperty({
        description: 'If true, API will wait for finishing transaction before returning 200 OK response',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean = false;
}

export class BazaNcDividendTransactionCmsProcessResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendTransactionStatus),
    })
    newStatus: BazaNcDividendTransactionStatus;
}

export class BazaNcDividendTransactionCmsExportToCsvRequest extends CrudExportToCsvRequest<
    BazaNcDividendTransactionCsvDto,
    BazaNcDividendTransactionCmsListRequest
> {}

export class BazaNcDividendTransactionCmsReprocessRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Entries ULID (ID)',
        example: ulid(),
        isArray: true,
    })
    @IsString({ each: true })
    @IsArray()
    @IsNotEmpty()
    entryULIDs: Array<string>;
}

export class BazaNcDividendTransactionCmsReprocessResponseEntry {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        description: 'Failure Reason (if failed)',
    })
    failureReason?: BazaNcDividendFailureReasonDto;
}

export class BazaNcDividendTransactionCmsReprocessResponse {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendTransactionStatus),
    })
    newStatus: BazaNcDividendTransactionStatus;

    @ApiModelProperty({
        type: BazaNcDividendTransactionCmsReprocessResponseEntry,
        isArray: true,
        description: 'Updated entries statuses and possible failure reasons',
    })
    entries?: Array<BazaNcDividendTransactionCmsReprocessResponseEntry>;
}

export class BazaNcDividendTransactionCmsSyncRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;

    @ApiModelProperty({
        description: 'If true, API will wait for finishing transaction before returning 200 OK response',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean;
}
