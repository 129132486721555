import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { BazaNcBootstrapDto, BazaNcBootstrapEndpoint, BazaNcBootstrapEndpointPaths } from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcBootstrapDataAccess implements BazaNcBootstrapEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    bootstrap(): Observable<BazaNcBootstrapDto> {
        return this.http.get(BazaNcBootstrapEndpointPaths.Bootstrap);
    }
}
