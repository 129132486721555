import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * The AutocompleteOffDirective turns off the autocomplete attribute for all input fields within the element.
 *
 * @example
 * <form appAutocompleteOff>
 *   <input type="text">
 *   <input type="password">
 * </form>
 */
@Directive({
    selector: '[appAutocompleteOff]',
})
export class AutocompleteOffDirective implements AfterViewInit {
    constructor(private readonly element: ElementRef) {}

    ngAfterViewInit() {
        this.element.nativeElement.querySelectorAll('input').forEach((item) => {
            item.setAttribute('autocomplete', 'new-password');
        });
    }
}
