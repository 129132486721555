import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { Observable, of } from 'rxjs';
import { BootstrapState, RequestAppBootstrapInitData } from '../store';

@Injectable({ providedIn: 'root' })
export class BootstrapResolver implements Resolve<BazaNcBootstrapDto> {
    constructor(private readonly store: Store) {}

    resolve(): Observable<BazaNcBootstrapDto> {
        const initData = this.store.selectSnapshot(BootstrapState.initData);
        return initData ? of(initData) : this.store.dispatch(new RequestAppBootstrapInitData());
    }
}
