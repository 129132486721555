import { NgModule } from '@angular/core';
import { BazaDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { BazaNcIntegrationFavoriteDataAccess } from './ng/baza-nc-integration-favorite.data-access';

@NgModule({
    imports: [
        BazaDataAccessModule,
    ],
    providers: [
        BazaNcIntegrationFavoriteDataAccess,
    ],
})
export class BazaNcIntegrationFavoriteDataAccessModule {
}