import { LinkEntity } from './dto/models/link';

export function isDwollaResponse(input: DwollaErrorResponse): boolean {
    return !!input && typeof input === 'object' && typeof input._embedded === 'object';
}

export function isDwollaSuccess(input: DwollaErrorResponse): boolean {
    return isDwollaResponse(input) && !input._embedded.errors;
}

export function isDwollaError(input: DwollaErrorResponse): boolean {
    return !!input && typeof input === 'object' && !!input.code && !!input.message;
}

export function isDwollaValidationError(input: any): boolean {
    return (
        !!input &&
        typeof input === 'object' &&
        input.code === 'ValidationError' &&
        input._embedded &&
        Array.isArray(input._embedded.errors) &&
        input._embedded.errors.length > 0
    );
}

export class DwollaBaseResponse<T = LinkEntity> {
    _links: T;
}

export class DwollaErrorResponse {
    code: string;
    message: string;
    _embedded?: {
        errors: [
            {
                code: string;
                message: string;
                path: string;
                _links: LinkEntity;
            },
        ];
    };
    _links?: unknown;
}
