import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  JobApplicationCmsDeleteRequest,
  JobApplicationCmsEndpoint,
  JobApplicationCmsEndpointPaths,
  JobApplicationCmsGetByIdRequest,
  JobApplicationCmsListRequest,
  JobApplicationCmsListResponse,
  JobApplicationDto,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class JobsApplicationCmsDataAccess implements JobApplicationCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: JobApplicationCmsListRequest): Observable<JobApplicationCmsListResponse> {
    return this.http.post(JobApplicationCmsEndpointPaths.list, request);
  }

  getById(request: JobApplicationCmsGetByIdRequest): Observable<JobApplicationDto> {
    return this.http.post(JobApplicationCmsEndpointPaths.getById, request);
  }

  delete(request: JobApplicationCmsDeleteRequest): Observable<void> {
    return this.http.post(JobApplicationCmsEndpointPaths.delete, request);
  }
}
