import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Application } from '../../../../baza-common/src';

export class BazaMaintenanceDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
        description: 'Disabled applications',
    })
    enabled: Array<Application>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
        description: 'Applications which can be disabled',
    })
    scope: Array<Application>;
}
