import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsNumberString } from 'class-validator';
import { BazaDwollaPaymentDto } from '@scaliolabs/baza-dwolla-shared';
import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ulid } from 'ulid';

export enum BazaNcTransferEndpointPaths {
    list = '/baza-nc/transfer/list',
    transfer = '/baza-nc/transfer',
    reprocess = '/baza-nc/transfer/reprocess',
}

export interface BazaNcTransferEndpoint {
    list(
        request: BazaNcTransferListRequest,
        ...args: unknown[]
    ): Promise<BazaNcTransferListResponse> | Observable<BazaNcTransferListResponse>;
    transfer(request: BazaNcTransferRequest, ...args: unknown[]): Promise<BazaDwollaPaymentDto> | Observable<BazaDwollaPaymentDto>;
    reprocess(
        request: BazaNcTransferReprocessRequest,
        ...args: unknown[]
    ): Promise<BazaDwollaPaymentDto> | Observable<BazaDwollaPaymentDto>;
}

export class BazaNcTransferListRequest extends CrudListRequestQueryDto {}

export class BazaNcTransferListResponse extends CrudListResponseDto<BazaDwollaPaymentDto> {
    @ApiModelProperty({
        type: BazaDwollaPaymentDto,
        isArray: true,
    })
    items: Array<BazaDwollaPaymentDto>;
}

export class BazaNcTransferRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'The amount to be transferred from Bank Account to Dwolla Customer Balance',
        example: '14.33',
        required: true,
    })
    @IsNumberString()
    @IsNotEmpty()
    amount: string;
}

export class BazaNcTransferReprocessRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'The ULID of the failed Transfer(Cash-In) request',
        example: ulid(),
        required: true,
    })
    @IsNotEmpty()
    ulid: string;
}
