import { BazaCreditCardType } from '../models/baza-credit-card-type';
import { BazaCreditCardDto } from '../dto/baza-credit-card.dto';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const creditCardTypeLib = require('credit-card-type');

interface CreditCardLibDto {
    niceType: BazaCreditCardType;
    type: string;
    gaps: Array<number>;
    lengths: Array<number>;
    code: {
        name: string;
        size: number;
    };
}

export function bazaCreditCardLUHN(cardNumber: string): boolean {
    let sum = 0;

    for (let i = 0; i < cardNumber.length; i++) {
        let intVal = parseInt(cardNumber.substr(i, 1));

        if (i % 2 == 0) {
            intVal *= 2;
            if (intVal > 9) {
                intVal = 1 + (intVal % 10);
            }
        }

        sum += intVal;
    }

    return (sum % 10) == 0;
}

export function bazaCreditCardDetect(cardNumber: string): BazaCreditCardDto | false {
    const detected: CreditCardLibDto = creditCardTypeLib(cardNumber)[0];

    if (detected) {
        return {
            type: detected.niceType,
            isCreditCardNumberValid: bazaCreditCardLUHN(cardNumber),
            gaps: detected.gaps,
            lengths: detected.lengths,
            codeName: detected.code.name,
            codeLength: detected.code.size,
        };
    } else {
        return false;
    }
}
