import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcSearchEndpoint,
    BazaNcSearchEndpointPaths,
    BazaNcSearchRangesResponseDto,
    BazaNcSearchRequestBaseDto,
    BazaNcSearchRequestDto,
    BazaNcSearchResponseDto,
} from '@scaliolabs/baza-nc-integration-shared';
import { Observable } from 'rxjs';

/**
 * Baza NC Integration Search Data-Access Service
 */
@Injectable()
export class BazaNcIntegrationSearchDataAccess implements BazaNcSearchEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Search & Filter Listings
     */
    search(request: BazaNcSearchRequestDto): Observable<BazaNcSearchResponseDto> {
        return this.http.post(BazaNcSearchEndpointPaths.search, request);
    }

    /**
     * Search & Filter Listings (Returns Array of Listing ULIDs only)
     */
    searchULIDs(request: BazaNcSearchRequestBaseDto): Observable<Array<string>> {
        return this.http.post(BazaNcSearchEndpointPaths.searchULIDs, request);
    }

    /**
     * Search & Filter Listings (Returns Array of Offering IDs only)
     */
    searchOfferingIds(request: BazaNcSearchRequestBaseDto): Observable<Array<string>> {
        return this.http.post(BazaNcSearchEndpointPaths.searchOfferingIds, request);
    }

    /**
     * Returns possible values for filters
     */
    ranges(): Observable<BazaNcSearchRangesResponseDto> {
        return this.http.get(BazaNcSearchEndpointPaths.ranges);
    }
}
