import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    AccountVerificationDto,
    AccountVerificationFormResourcesDto,
    AccountVerificationInvestorProfileDto,
    AccountVerificationIsCompletedDto,
    BazaNcAccountVerificationEndpoint,
    BazaNcAccountVerificationEndpointPaths,
    ListStatesRequestDto,
    ListStatesResponseDto,
    PersonalInformationApplyRequest,
} from '@scaliolabs/baza-nc-shared';

@Injectable()
export class BazaNcAccountVerificationDataAccess implements BazaNcAccountVerificationEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    index(): Observable<AccountVerificationDto> {
        return this.ngEndpoint.get<AccountVerificationDto>(BazaNcAccountVerificationEndpointPaths.index);
    }

    formResources(): Observable<AccountVerificationFormResourcesDto> {
        return this.ngEndpoint.get<AccountVerificationFormResourcesDto>(BazaNcAccountVerificationEndpointPaths.formResources);
    }

    listStates(request: ListStatesRequestDto): Observable<ListStatesResponseDto> {
        return this.ngEndpoint.post<ListStatesResponseDto>(BazaNcAccountVerificationEndpointPaths.listStates, request);
    }

    isCompleted(): Observable<AccountVerificationIsCompletedDto> {
        return this.ngEndpoint.get<AccountVerificationIsCompletedDto>(BazaNcAccountVerificationEndpointPaths.isCompleted);
    }

    uploadPersonalInformationSSNDocument(file: File): Observable<AccountVerificationDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.ngEndpoint.post<AccountVerificationDto>(
            BazaNcAccountVerificationEndpointPaths.uploadPersonalInformationSSNDocument,
            formData,
        );
    }

    uploadPersonalInformationDocument(file: File): Observable<AccountVerificationDto> {
        const formData = new FormData();

        formData.append('file', file);

        return this.ngEndpoint.post<AccountVerificationDto>(
            BazaNcAccountVerificationEndpointPaths.uploadPersonalInformationDocument,
            formData,
        );
    }

    usePreviouslyUploadedDocument(): Promise<AccountVerificationDto> | Observable<AccountVerificationDto> {
        return this.ngEndpoint.post<AccountVerificationDto>(BazaNcAccountVerificationEndpointPaths.usePreviouslyUploadedDocument);
    }

    applyPersonalInformation(request: PersonalInformationApplyRequest): Observable<AccountVerificationDto> {
        return this.ngEndpoint.post<AccountVerificationDto>(BazaNcAccountVerificationEndpointPaths.applyPersonalInformation, request);
    }

    applyInvestorProfile(request: AccountVerificationInvestorProfileDto): Observable<AccountVerificationDto> {
        return this.ngEndpoint.post<AccountVerificationDto>(BazaNcAccountVerificationEndpointPaths.applyInvestorProfile, request);
    }
}
