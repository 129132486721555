import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '@scaliolabs/baza-core-shared';
import { ContactsSubjectCmsDto } from '../dto/cms/contacts-subject-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';

export enum ContactsSubjectCmsEndpointPaths {
    list = '/baza-content-types/contacts/subject/cms/list',
    getById = '/baza-content-types/contacts/subject/cms/getById',
    create = '/baza-content-types/contacts/subject/cms/create',
    update = '/baza-content-types/contacts/subject/cms/update',
    delete = '/baza-content-types/contacts/subject/cms/delete',
    setSortOrder = '/baza-content-types/contacts/subject/cms/setSortOrder',
}

export interface ContactsSubjectCmsEndpoint {
    list(request: ContactsSubjectCmsListRequest): Promise<ContactsSubjectCmsListResponse> | Observable<ContactsSubjectCmsListResponse>;
    getById(request: ContactsSubjectCmsGetByIdRequest): Promise<ContactsSubjectCmsResponse> | Observable<ContactsSubjectCmsResponse>;
    create(request: ContactsSubjectCmsCreateRequest): Promise<ContactsSubjectCmsCreateResponse> | Observable<ContactsSubjectCmsCreateResponse>;
    update(request: ContactsSubjectCmsUpdateRequest): Promise<ContactsSubjectCmsUpdateResponse> | Observable<ContactsSubjectCmsUpdateResponse>;
    delete(request: ContactsSubjectCmsDeleteRequest): Promise<void> | Observable<void>;
    setSortOrder(request: ContactsSubjectCmsSetSortOrderRequest): Promise<ContactsSubjectCmsSetSortOrderResponse> | Observable<ContactsSubjectCmsSetSortOrderResponse>;
}

export class BazaContentTypesContactsSubjectCmsEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    subject: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;
}

export class ContactsSubjectCmsListRequest extends CrudListRequestDto<ContactsSubjectCmsDto> {
}

export class ContactsSubjectCmsListResponse extends CrudListResponseDto<ContactsSubjectCmsDto> {
    @ApiModelProperty({
        type: ContactsSubjectCmsDto,
        isArray: true,
    })
    items: Array<ContactsSubjectCmsDto>;
}

export class ContactsSubjectCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ContactsSubjectCmsResponse = ContactsSubjectCmsDto;

export class ContactsSubjectCmsCreateRequest extends BazaContentTypesContactsSubjectCmsEntityBody {}
export type ContactsSubjectCmsCreateResponse = ContactsSubjectCmsDto;

export class ContactsSubjectCmsUpdateRequest extends BazaContentTypesContactsSubjectCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type ContactsSubjectCmsUpdateResponse = ContactsSubjectCmsDto;

export class ContactsSubjectCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ContactsSubjectCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {
}

export class ContactsSubjectCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<ContactsSubjectCmsDto> {
    @ApiModelProperty({
        type: ContactsSubjectCmsDto,
        isArray: true,
    })
    affected: Array<ContactsSubjectCmsDto>;
}
