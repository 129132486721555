import { OfferingId } from '../models/offering-id';
import { NorthCapitalAccountId } from '../models/account-id';
import { IsNotEmpty, IsString } from 'class-validator';
import { TradeId } from '../models/trade-id';

export class ResendSubscriptionDocumentsRequest {
    @IsNotEmpty()
    @IsString()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;
}
