import { DwollaTransferStatus } from '../../../../dwolla-api/src';

export enum BazaDwollaDistributionPaymentStatus {
    Pending = 'Pending',
    Processed = 'Processed',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export const bazaDwollaDistributionNonDraftPaymentStatuses: Array<BazaDwollaDistributionPaymentStatus> = [
    BazaDwollaDistributionPaymentStatus.Processed,
    BazaDwollaDistributionPaymentStatus.Failed,
    BazaDwollaDistributionPaymentStatus.Cancelled,
];

export const bazaDwollaDistributionProcessedPaymentStatuses: Array<BazaDwollaDistributionPaymentStatus> = [
    BazaDwollaDistributionPaymentStatus.Failed,
    BazaDwollaDistributionPaymentStatus.Cancelled,
    BazaDwollaDistributionPaymentStatus.Processed,
];
export const bazaDwollaDistributionReprocessablePaymentStatuses: Array<BazaDwollaDistributionPaymentStatus> = [
    BazaDwollaDistributionPaymentStatus.Failed,
    BazaDwollaDistributionPaymentStatus.Cancelled,
];

export const bazaDwollaDistributionUpdatablePaymentStatuses: Array<BazaDwollaDistributionPaymentStatus> = [
    BazaDwollaDistributionPaymentStatus.Pending,
    ...bazaDwollaDistributionReprocessablePaymentStatuses,
];

export const bazaDwollaDistributionMapDwollaTransferToPaymentStatuses: Array<{
    dwolla: DwollaTransferStatus;
    status: BazaDwollaDistributionPaymentStatus;
}> = [
    {
        dwolla: DwollaTransferStatus.Pending,
        status: BazaDwollaDistributionPaymentStatus.Pending,
    },
    {
        dwolla: DwollaTransferStatus.Processed,
        status: BazaDwollaDistributionPaymentStatus.Processed,
    },
    {
        dwolla: DwollaTransferStatus.Failed,
        status: BazaDwollaDistributionPaymentStatus.Failed,
    },
    {
        dwolla: DwollaTransferStatus.Cancelled,
        status: BazaDwollaDistributionPaymentStatus.Cancelled,
    },
];
