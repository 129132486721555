import { UntypedFormControl } from '@angular/forms';

/**
 * Form validation get i18n error interface
 */
export interface i18nValidationCheckReq {
    /**
     * Form control
     */
    control?: UntypedFormControl;
    /**
     * Control name to get i18n key
     */
    formCtrlName?: string;
    /**
     * i18n config field rules path
     */
    fieldPath?: string;
    /**
     * i18n config form generic validations rules path
     */
    genericValidationsPath?: string;
    /**
     * Custom validators
     */
    customValidationTypes?: Array<i18nValidationTypesEnum>;
}

/**
 * Validator type
 */
export enum i18nValidationTypesEnum {
    required = 'required',
    email = 'email',
    password = 'password',
    minlength = 'minlength',
    maxlength = 'maxlength',
    onlynumbers = 'onlynumbers',
    whiteSpace = 'whiteSpace',
    restrictedChars = 'restrictedChars',
    isAdult = 'isAdult',
    routingNumber = 'routingNumber',
}

export type ValidatorMapping = {
    key: i18nValidationTypesEnum;
    params?: { [key: string]: unknown };
};

export type ValidationsPreset = Map<string, ValidatorMapping[]>;
