import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BazaI18nNgConfig } from './baza-i18n-ng.config';
import { TranslateModule } from '@ngx-translate/core';
import { BazaI18nNgService } from './services/baza-i18n-ng.service';
import { BazaI18nNgNxHttpLoader } from './baza-i18n-ng.nx-http-loader';
import { DefaultCurrentLanguageDetector } from './detectors/default-current-language.detector';
import { BazaI18nNgDefaultService } from './services/baza-i18n-ng-default.service';
import { BazaI18nPrefetchResolver } from './resolvers/baza-i18n-prefetch.resolver';
import { LocalStorageCurrentLanguageDetector } from './detectors/local-storage-current-language.detector';
import { BazaI18nDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { BazaNgCoreModule } from '../../baza-common/lib/baza-ng-core.module';

interface ForRootOptions {
    deps: Array<unknown>;
    useFactory: (...args) => BazaI18nNgConfig;
}

export { ForRootOptions as BazaI18nNgModuleForRootOptions };

const detectors = [LocalStorageCurrentLanguageDetector, DefaultCurrentLanguageDetector];

// @dynamic
@NgModule({
    imports: [CommonModule, TranslateModule, BazaI18nDataAccessModule, BazaNgCoreModule],
    providers: [BazaI18nNgService, BazaI18nNgNxHttpLoader, BazaI18nNgDefaultService, BazaI18nPrefetchResolver, ...detectors],
})
export class BazaI18nNgModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaI18nNgModule> {
        return {
            ngModule: BazaI18nNgModule,
            providers: [
                {
                    provide: BazaI18nNgConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
