import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { UtilModule } from '@scaliolabs/baza-web-utils';

import { ItemCardComponent } from './item-card.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FavoriteButtonModule } from '../favorite-btn';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';

@NgModule({
    declarations: [ItemCardComponent],
    exports: [ItemCardComponent],
    imports: [
        CommonModule,
        NzAlertModule,
        NzButtonModule,
        NzCardModule,
        NzGridModule,
        NzProgressModule,
        RouterModule,
        FavoriteButtonModule,
        UtilModule,
        NzToolTipModule,
        NestedEllipsisModule,
    ],
})
export class ItemCardModule {}
