import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowErrorCodes } from '../error-codes/baza-nc-purchase-flow.error-codes';

export class ValidateResponseDto {
    @ApiModelProperty({
        description: 'Session request can be performed',
    })
    success: boolean;

    @ApiModelProperty({
        description: 'PurchaseFlowError code or undefined if request can be actually performed',
        enum: Object.values(BazaNcPurchaseFlowErrorCodes),
        type: 'string',
        required: false,
    })
    errorCode?: BazaNcPurchaseFlowErrorCodes;

    @ApiModelProperty({
        description: 'Error message',
        required: false,
    })
    message?: string;

    @ApiModelProperty({
        description: 'How many shares can be purchased by user (special case for OnlyXAmountOfSharesAvailableForPurchase, YouHadPreviousPurchaseAndManOnlyPurchaseUpToXShares and YouCanPurchaseUpToXShares error codes)',
        required: false,
    })
    mayPurchase?: number;
}
