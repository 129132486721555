import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgEnvironment } from '@scaliolabs/baza-core-ng';
import { getBazaProjectCodeName } from '@scaliolabs/baza-core-shared';
import { BAZA_WEB_BUNDLE_GUARD_CONFIGS, BazaWebBundleModule, bazaWebBundleConfigBuilder } from '@scaliolabs/baza-core-web';
import { PurchaseStateModule } from '@scaliolabs/baza-web-purchase-flow';
import { AlertModule, LoaderModule, StorageModule, UtilModule } from '@scaliolabs/baza-web-utils';
import { AuthStateModule, BootstrapStateModule, CartGuard, FeedStateModule } from '@scaliolabs/sandbox-web/data-access';
import { RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { BUILT_ON_API_VERSION } from './app.version';
import { TransferHttpResponseInterceptor } from './interceptor';
import { VerificationStateModule } from '@scaliolabs/baza-web-verification-flow';

if (environment.sentry) {
    bazaWebBundleConfigBuilder().withSentry(environment.sentry.dsn, environment.sentry.browserOptions);
}

const config = bazaWebBundleConfigBuilder()
    .withVersion(BUILT_ON_API_VERSION)
    .withNgEnvironment(environment.ngEnv)
    .withApiEndpoint(environment.apiEndpoint)
    .withoutI18nSupport()
    .withJwtStorages({
        jwtStorages: {
            localStorage: {
                enabled: true,
                jwtKey: `${getBazaProjectCodeName()}WebJwt`,
                jwtPayloadKey: `${getBazaProjectCodeName()}JwtWebPayload`,
            },
            sessionStorage: {
                enabled: false,
            },
            documentCookieStorage: {
                enabled: false,
            },
        },
    }).config;

BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireNoAuthGuardConfig.redirect = () => ({
    commands: ['/items'],
});

const ngZorroConfig: NzConfig = {
    notification: {
        nzDuration: 5000,
        nzMaxStack: 1,
    },
    select: {
        nzSuffixIcon: 'angle-down',
    },
};

// set default meta tags
export function metaFactory(): MetaLoader {
    return new MetaStaticLoader({
        applicationName: 'Scalio Investment Platform',
        pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
        pageTitleSeparator: ' | ',
        defaults: {
            title: 'Scalio Investment Platform',
            description: '',
            'og:type': 'website',
            'og:locale': 'en_US',
        },
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AlertModule.forRoot({ hostSelector: '#alertPlaceholder' }),
        AppRoutingModule,
        AuthStateModule,
        BazaWebBundleModule.forRoot(config),
        BootstrapStateModule,
        BrowserAnimationsModule,
        BrowserModule,
        BrowserModule.withServerTransition({ appId: 'sandbox' }),
        BrowserTransferStateModule,
        PurchaseStateModule,
        VerificationStateModule,
        HttpClientModule,
        IconSpriteModule.forRoot({ path: 'assets/sprites/icons.svg' }),
        LoaderModule,
        MetaModule.forRoot({
            provide: MetaLoader,
            useFactory: metaFactory,
        }),
        NgxsModule.forRoot([], {
            developmentMode: environment.ngEnv === NgEnvironment.Local,
        }),
        NgxPlaidLinkModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
        }),
        RouterModule,
        StorageModule,
        UtilModule,
        FeedStateModule,
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: en_US,
        },
        {
            provide: NZ_CONFIG,
            useValue: ngZorroConfig,
        },
        CartGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TransferHttpResponseInterceptor,
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptchaSiteKey },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
