import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountSettingsDto } from './account-settings.dto';
import { AccountRole } from '../models/account-role';
import { Application, clientApplications } from '../../../../baza-common/src';

export class AccountDto<T = AccountSettingsDto> {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    dateCreated: string;

    @ApiModelProperty()
    dateUpdated: string;

    @ApiModelProperty()
    lastSignIn: string;

    @ApiModelProperty({
        description: 'Client (application) used to register account',
        enum: [...clientApplications],
    })
    signedUpWithClient: Application;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty()
    isEmailConfirmed: boolean;

    @ApiModelProperty()
    isDeactivated: boolean;

    @ApiModelProperty()
    fullName: string;

    @ApiModelProperty({
        required: false,
    })
    firstName?: string;

    @ApiModelProperty({
        required: false,
    })
    lastName?: string;

    @ApiModelProperty({
        description: 'Phone Number. Should not be used for 2FA',
        required: false,
    })
    phone?: string;

    @ApiModelProperty()
    profileImages: Array<string>;

    @ApiModelProperty()
    role: AccountRole;

    @ApiModelProperty()
    settings: T;

    @ApiModelProperty({
        description: 'Managed User accounts are not able to change password, reset password or change email',
    })
    isManagedUserAccount: boolean;
}
