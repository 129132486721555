import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Application, BazaMaintenanceDto } from '@scaliolabs/baza-core-shared';
import { BazaMaintenanceCmsDataAccess } from '@scaliolabs/baza-core-cms-data-access';

@Injectable()
export class MaintenanceGuard implements CanActivate {
    constructor(private router: Router, private service: BazaMaintenanceCmsDataAccess) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.service.status().pipe(
            map((response: BazaMaintenanceDto) => {
                const isMaintenanceEnabled: boolean = response.enabled?.includes(Application.WEB);

                return isMaintenanceEnabled || this.router.createUrlTree(['']);
            }),
        );
    }
}
