<div class="banner">
    <div class="container banner__container">
        <div
            nz-row
            nzGutter="30"
            class="banner__row">
            <div
                nz-col
                nzXs="12"
                class="banner__col">
                <div
                    class="banner__subtitle"
                    [innerHtml]="label"></div>

                <div
                    class="banner__heading"
                    *ngIf="header"
                    [innerHtml]="header | sanitizeHtml"></div>

                <div
                    class="banner__subheading"
                    *ngIf="description"
                    [innerHtml]="description | sanitizeHtml"></div>

                <div class="banner__btn">
                    <button
                        nz-button
                        [class]="btnClass"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'btnCTA.label') | sanitizeHtml"
                        appLinkify
                        [linkConfigPath]="ctaLinkConfig"></button>
                </div>
            </div>
        </div>
    </div>
</div>
