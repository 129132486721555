export enum BazaNcPurchaseFlowErrorCodes {
    OfferingIsComingSoon = 'OfferingIsComingSoon',
    OfferingIsClosed = 'OfferingIsClosed',
    SessionCanNotBeStarted = 'SessionCanNotBeStarted',
    DocumentsAreNotSigned = 'DocumentsAreNotSigned',
    TradeSessionNotFound = 'TradeSessionNotFound',
    NoBankAccount = 'NoBankAccount',
    NoCreditCard = 'NoCreditCard',
    NorthCapitalAccountIsNotReady = 'NorthCapitalAccountIsNotReady',
    AmountMismatch = 'AmountMismatch',
    UnknownTransactionType = 'UnknownTransactionType',
    InvalidPaymentReprocess = 'InvalidPaymentReprocess',
    InvalidRoutingNumber = 'InvalidRoutingNumber',
    CreditCardIsInvalid = 'CreditCardIsInvalid',
    CreditCardIsNotSupported = 'CreditCardIsNotSupported',
    CreditCardIsExpired = 'CreditCardIsExpired',
    CreditCardIsOutOfRange = 'CreditCardIsOutOfRange',
    PurchaseFlowLimitsViolation = 'PurchaseFlowLimitsViolation',
    InvalidCardDetails = 'InvalidCardDetails',
    DocuSignUrlsAreDisabledForCurrentEnvironment = 'DocuSignUrlsAreDisabledForCurrentEnvironment',
    InvestorPurchaseDeniedException = 'InvestorPurchaseDeniedException',
    LegacyBankAccountSystemDisabled = 'LegacyBankAccountSystemDisabled',
    UnknownPurchaseFlowStrategy = 'UnknownPurchaseFlowStrategy',
    NoDwollaCustomer = 'NoDwollaCustomer',
    DwollaInsufficientFunds = 'DwollaInsufficientFunds',
    NotEnoughShares = 'NotEnoughShares',
    LegacyCreditCardDeprecated = 'LegacyCreditCardDeprecated',
    AccreditedInvestorAccountOnly = 'AccreditedInvestorAccountOnly',
    InvalidOfferingRegulationType = 'InvalidOfferingRegulationType',
    RegulationInvestmentLimitReached = 'RegulationInvestmentLimitReached',
    PurchaseLimitedToUsResident = 'PurchaseLimitedToUsResident',
    RegulationCFLimitLessThanMinimumPurchaseAmount = 'RegulationCFLimitLessThanMinimumPurchaseAmount',
    RegulationAPlusLimitLessThanMinimumPurchaseAmount = 'RegulationAPlusLimitLessThanMinimumPurchaseAmount',
    RegulationAPlusHouseholdIncomeOrNetWorthEmpty = 'RegulationAPlusHouseholdIncomeOrNetWorthEmpty',
}

export const bazaNcPurchaseFlowErrorCodesI18n: Record<BazaNcPurchaseFlowErrorCodes, string> = {
    [BazaNcPurchaseFlowErrorCodes.OfferingIsClosed]: 'Offering is closed',
    [BazaNcPurchaseFlowErrorCodes.OfferingIsComingSoon]: 'Offering is not open for purchases yet',
    [BazaNcPurchaseFlowErrorCodes.DocumentsAreNotSigned]: 'Documents are not signed yet',
    [BazaNcPurchaseFlowErrorCodes.TradeSessionNotFound]:
        'Your purchase session has timed out. Your shares will be released and no charges will be processed. Please proceed to the buy screen to make a purchase',
    [BazaNcPurchaseFlowErrorCodes.NoBankAccount]: 'Please link your bank account in order to submit your investment.',
    [BazaNcPurchaseFlowErrorCodes.NoCreditCard]: 'Please add your credit card in order to submit your investment.',
    [BazaNcPurchaseFlowErrorCodes.NorthCapitalAccountIsNotReady]:
        'There is no North Capital account associated with {{ projectName }} account yet',
    [BazaNcPurchaseFlowErrorCodes.AmountMismatch]: 'Amount does not suit with requested number of shares',
    [BazaNcPurchaseFlowErrorCodes.UnknownTransactionType]: 'Unknown transaction type',
    [BazaNcPurchaseFlowErrorCodes.InvalidPaymentReprocess]:
        'Payment Reprocess request is invalid, only failed (returned ) payments can be reprocessed',
    [BazaNcPurchaseFlowErrorCodes.InvalidRoutingNumber]: 'Invalid Routing Number. Please enter a valid routing number and try again.',
    [BazaNcPurchaseFlowErrorCodes.CreditCardIsInvalid]: 'Invalid Credit Card Number',
    [BazaNcPurchaseFlowErrorCodes.CreditCardIsNotSupported]: '{{ creditCard }} card is not supported',
    [BazaNcPurchaseFlowErrorCodes.CreditCardIsExpired]: 'You have entered an expired credit card',
    [BazaNcPurchaseFlowErrorCodes.CreditCardIsOutOfRange]: 'You have entered an invalid credit card',
    [BazaNcPurchaseFlowErrorCodes.PurchaseFlowLimitsViolation]:
        'Total purchase amount exceeds available limits for selected payment method',
    [BazaNcPurchaseFlowErrorCodes.InvalidCardDetails]: 'Invalid card details',
    [BazaNcPurchaseFlowErrorCodes.DocuSignUrlsAreDisabledForCurrentEnvironment]:
        'DocuSign URLs are disabled for current environments. Please note that every DocuSign URL costs ~$3.5 each.',
    [BazaNcPurchaseFlowErrorCodes.LegacyBankAccountSystemDisabled]: 'Legacy bank account system is disabled',
    [BazaNcPurchaseFlowErrorCodes.UnknownPurchaseFlowStrategy]: 'Unknown Purchase Flow strategy',
    [BazaNcPurchaseFlowErrorCodes.NoDwollaCustomer]: "You don't have Dwolla Customer yet",
    [BazaNcPurchaseFlowErrorCodes.DwollaInsufficientFunds]: 'Insufficient funds',
    [BazaNcPurchaseFlowErrorCodes.NotEnoughShares]: 'Offering doesn`t have enough shares',
    [BazaNcPurchaseFlowErrorCodes.SessionCanNotBeStarted]: 'Session cannot be started',
    [BazaNcPurchaseFlowErrorCodes.InvestorPurchaseDeniedException]: 'Purchase denied',
    [BazaNcPurchaseFlowErrorCodes.LegacyCreditCardDeprecated]: 'Update required',
    [BazaNcPurchaseFlowErrorCodes.AccreditedInvestorAccountOnly]: `Only Accredited Investors are allowed to view the offering financial data or purchase from it`,
    [BazaNcPurchaseFlowErrorCodes.InvalidOfferingRegulationType]: `Invalid Offering regulation type exception`,
    [BazaNcPurchaseFlowErrorCodes.RegulationInvestmentLimitReached]: `Insufficient Investment limits for this purchase`,
    [BazaNcPurchaseFlowErrorCodes.PurchaseLimitedToUsResident]: `Unavailable for purchase. This offering is currently exclusive to U.S. residents only.`,
    [BazaNcPurchaseFlowErrorCodes.RegulationCFLimitLessThanMinimumPurchaseAmount]: `Your Reg CF limit remainder is less than the minimum purchase amount`,
    [BazaNcPurchaseFlowErrorCodes.RegulationAPlusLimitLessThanMinimumPurchaseAmount]: `Your Reg A+ limit remainder is less than the minimum purchase amount`,
    [BazaNcPurchaseFlowErrorCodes.RegulationAPlusHouseholdIncomeOrNetWorthEmpty]: `Your Household Income and Net worth values are empty , please update them to continue`,
};
