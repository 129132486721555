import { IsEnum, IsInt, IsNotEmpty, IsPositive, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { CardType } from '../models/card-type';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class UpdateCreditCardRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    creditCardName: string;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    creditCardNumber: string;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    expirationDate: string;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    cvvNumber: string;

    @IsEnum(CardType)
    @IsNotEmpty()
    cardType: CardType;

    @IsString()
    @IsNotEmpty()
    updatedIpAddress: NorthCapitalIpAddress;
}
