import { IsArray, IsDefined, IsEnum, IsNotEmptyObject, IsOptional, ValidateNested } from 'class-validator';
import { Link } from '../models/link';
import { DwollaClearing } from '../models/transfer-details';
import { DwollaACHDetails } from '../models/dwolla-ach-details';
import { DwollaMassPaymentItem } from '../models/dwolla-mass-payment-item';
import { DwollaMassPaymentStatus } from '../models/dwolla-mass-payment-status';
import { Type } from 'class-transformer';

export class CreateMassPaymentRequest {
    @IsDefined()
    @IsNotEmptyObject()
    '_links': Record<'source', Link>;

    @ValidateNested({ each: true })
    @Type(() => DwollaMassPaymentItem)
    @IsArray()
    items: Array<DwollaMassPaymentItem>;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    metadata?: {
        [key: string]: string;
    };

    @IsEnum(DwollaMassPaymentStatus)
    @IsOptional()
    status?: DwollaMassPaymentStatus.Deferred;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    clearing?: DwollaClearing;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    achDetails?: DwollaACHDetails;

    @IsDefined()
    @IsNotEmptyObject()
    @IsOptional()
    correlationId?: string;
}
