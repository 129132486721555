<nz-modal
    nzClosable="false"
    [nzAutofocus]="null"
    [nzClosable]="false"
    [nzFooter]="null"
    [nzTitle]="nzModalTitle"
    [nzVisible]="true"
    [nzWidth]="480"
    #paymentEditModal>
    <ng-template #nzModalTitle>{{ modalTitle }}</ng-template>

    <ng-container *nzModalContent>
        <app-payment-header
            [isBackBtnVisible]="modalContentType !== MODAL_CONTENT_TYPE.paymentList"
            (backClicked)="onBackClick()"
            (closeClicked)="handlePaymentEditCancel.emit()"></app-payment-header>

        <ng-container [ngSwitch]="modalContentType">
            <app-payment-edit-list
                *ngSwitchCase="'paymentList'"
                [dwollaCustomerDetailResponse]="dwollaCustomerDetail"
                [bankAccountResponse]="bankAccountResponse"
                [creditCardResponse]="creditCardResponse"
                [isPurchaseAboveLimit]="isPurchaseAboveLimit"
                [selectedPaymentMethod]="selectedPaymentMethod"
                [hasEnoughFunds]="hasEnoughFunds"
                [config]="config"
                [initData]="initData"
                (modalTitleChange)="onModalTitleChange($event)"
                (paymentMethodChange)="onPaymentMethodChange($event)"
                (addUpdatePaymentMethodClick)="onAddUpdatePaymentMethodClick($event)"></app-payment-edit-list>

            <app-payment-edit-add
                *ngSwitchCase="'paymentAdd'"
                [paymentMethodType]="paymentMethodType"
                [invalidFormScrollContainerRef]="paymentEditModal"
                (modalTitleChange)="onModalTitleChange($event)"
                (submitForm)="onSubmitAddForm($event)"></app-payment-edit-add>
        </ng-container>
    </ng-container>
</nz-modal>
