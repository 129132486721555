export const WebUiMarketingTextImageEnI18n = {
    section0: {
        img: `'/assets/images/section1.jpeg'`,
        txtPlacement: `left`,
    },

    section1: {
        img: `'/assets/images/section2.jpeg'`,
        txtPlacement: `right`,
    },
};
