import { BazaPasswordResourcesDto } from '../dto/baza-password-resources.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';
import { BazaPasswordValidateDto } from '../dto/baza-password-validate.dto';

export enum BazaPasswordEndpointPaths {
    resources = '/baza/password/resources',
    validate = '/baza/password/validate',
}

export interface BazaPasswordEndpoint {
    resources(): Promise<BazaPasswordResourcesDto> | Observable<BazaPasswordResourcesDto>;
    validate(request: BazaPasswordValidateRequest): Promise<BazaPasswordValidateDto> | Observable<BazaPasswordValidateDto>;
}

export class BazaPasswordValidateRequest {
    @ApiModelProperty({
        description: 'Password. Please do not provide null, undefined or empty value - you will receive Nest.JS validation error instead',
    })
    @IsString()
    @IsNotEmpty()
    password: string;
}
