import { NgModule } from '@angular/core';
import { BazaPortfolioDataAccess } from './ng/baza-nc-integration-portfolio.data-access';
import { BazaDataAccessModule } from '@scaliolabs/baza-core-data-access';

@NgModule({
    imports: [
        BazaDataAccessModule,
    ],
  providers: [
      BazaPortfolioDataAccess,
  ]
})
export class BazaNcIntegrationPortfolioDataAccessModule {}
