import { BazaNcPurchaseFlowTransactionType } from '../models/baza-nc-purchase-flow-transaction-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class PurchaseFlowLimitsDto {
    @ApiModelProperty({
        description: 'Is payment method available for requested amount?',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        description: 'Transaction Type',
        enum: Object.values(BazaNcPurchaseFlowTransactionType),
        type: 'string',
    })
    transactionType: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description: 'Amount with fees (in cents)',
    })
    amountWithFeesCents: number;

    @ApiModelProperty({
        description: 'Transaction fee (in cents)',
    })
    transactionFeesCents: number;

    @ApiModelProperty({
        description: 'Transaction fees.',
    })
    transactionFees: number;

    @ApiModelProperty({
        description: 'Fee (in cents)',
    })
    feeCents: number;

    @ApiModelProperty({
        description: 'Maximum limit for transaction type (in cents)',
    })
    maximumAmountCents: number;
}
