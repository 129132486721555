import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BAZA_CRUD_CONSTANTS, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { IsOptional, IsString, Length } from 'class-validator';
import { RewardShareSessionDto } from '../dto/reward-share-session.dto';
import { RewardShareDto } from '../dto/reward-share.dto';
import { Observable } from 'rxjs';

export enum RewardShareCmsEndpointPaths {
    createSession = '/baza-nc/reward-share/cms/createSession',
    uploadFromCsv = '/baza-nc/reward-share/cms/uploadFromCsv',
    getAllRewardShareSession = '/baza-nc/reward-share/cms/getAllRewardShareSession',
    getRewardSharesBySession = '/baza-nc/reward-share/cms/getRewardSharesBySession',
}

export interface BazaNcRewardShareCmsEndpoint {
    createRewardShareSession(
        request: CreateRewardShareSessionCmsRequest,
        ...args: unknown[]
    ): Promise<RewardShareSessionDto> | Observable<RewardShareSessionDto>;
    getAllRewardShareSession(
        request: GetRewardShareSessionCmsRequest,
        ...args: unknown[]
    ): Promise<GetRewardShareSessionCmsResponse> | Observable<GetRewardShareSessionCmsResponse>;
    getRewardSharesBySession(
        request: GetRewardSharesBySessionIdCmsRequest,
        ...args: unknown[]
    ): Promise<GetRewardSharesBySessionIdCmsResponse> | Observable<GetRewardSharesBySessionIdCmsResponse>;
    uploadFromCsv(
        file: File | unknown,
        request: UploadRewardSharesFromCsvCmsRequest,
        ...args: unknown[]
    ): Promise<UploadRewardSharesFromCsvCmsResponse> | Observable<UploadRewardSharesFromCsvCmsResponse>;
}

export class UploadRewardSharesFromCsvCmsRequest {
    @ApiModelProperty({
        description: 'Reward share session id',
        required: true,
    })
    @IsString()
    public rewardShareSessionId: string;

    @ApiModelProperty({
        description: 'Delimiter (separator) for output CSV',
        default: BAZA_CRUD_CONSTANTS.csvDefaultDelimiter,
        required: false,
    })
    @Length(1)
    @IsString()
    @IsOptional()
    public delimiter?: string;
}

export class CreateRewardShareSessionCmsRequest {
    @ApiModelProperty({
        description: 'Reward share session name',
        required: true,
    })
    @IsString()
    public name: string;

    @ApiModelProperty({
        description: 'Reward share session description',
        required: false,
    })
    @IsOptional()
    @IsString()
    public description?: string;
}

export class GetRewardShareSessionCmsRequest extends CrudListRequestDto<RewardShareSessionDto> {}
export class GetRewardShareSessionCmsResponse extends CrudListResponseDto<RewardShareSessionDto> {}

export class GetRewardSharesBySessionIdCmsRequest extends CrudListRequestDto<RewardShareDto> {
    @ApiModelProperty({
        description: 'Reward share session id',
        required: true,
    })
    @IsString()
    public sessionId: string;
}

export class GetRewardSharesBySessionIdCmsResponse extends CrudListResponseDto<RewardShareDto> {}

export class UploadRewardShareFromCsvCmsErrorResponse {
    @ApiModelProperty({
        description: 'Invalid fields names',
        type: [String],
    })
    public readonly invalidFields: string[];

    @ApiModelProperty({
        description: 'CSV row index, where error was found',
    })
    public readonly rowIndex: number;

    constructor(invalidFields: string[], rowIndex: number) {
        this.invalidFields = invalidFields;
        this.rowIndex = ++rowIndex;
    }
}

export class UploadRewardSharesFromCsvCmsResponse {
    @ApiModelProperty({
        description: 'Amount of successfully processed CSV rows',
    })
    public readonly processedSuccessfully: number;

    constructor(processedSuccessfully: number) {
        this.processedSuccessfully = processedSuccessfully;
    }
}

export class GetAllRewardShareSessionCmsRequest {}
