import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageService } from './storage.service';
import { getLocalStorage } from './storage.utils';

@NgModule({
    imports: [CommonModule],
    declarations: [],
    exports: [],
    providers: [{ provide: 'LOCALSTORAGE', useFactory: getLocalStorage }, StorageService],
})
export class StorageModule {}
