import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BazaNgCoreConfig } from '../baza-ng-core.config';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { BazaHttpHeaders } from '@scaliolabs/baza-core-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { isPlatformBrowser } from '@angular/common';
import { NAVIGATOR } from '@ng-web-apis/common';

@Injectable()
export class XBazaApplicationHttpInterceptor implements HttpInterceptor {
    constructor(
        @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
        @Inject(NAVIGATOR) private navigator: Navigator,
        private moduleConfig: BazaNgCoreConfig,
        private ngEndpoint: BazaDataAccessService,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        const headers: { [name: string]: string | string[] } = {
            [BazaHttpHeaders.HTTP_HEADER_APP]: this.moduleConfig.application,
        };

        if (isPlatformBrowser(this.platformId) && this.navigator.userAgent) {
            headers[BazaHttpHeaders.HTTP_HEADER_APP_ID] = this.navigator.userAgent;
        }

        return next.handle(
            req.clone({
                setHeaders: headers,
            }),
        );
    }
}
