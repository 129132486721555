import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcPayoffDistributionCmsDto } from '../dto/baza-nc-payoff-distribution-cms.dto';
import { BazaNcPayoffDistributionCmsCsvDto } from '../dto/baza-nc-payoff-distribution-cms-csv.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ulid } from 'ulid';

export enum BazaNcPayoffDistributionCmsEndpointPaths {
    list = '/baza-nc/cms/payoff-distribution/list',
    getByUlid = '/baza-nc/cms/payoff-distribution/getByUlid',
    delete = '/baza-nc/cms/payoff-distribution/delete',
    exportToCsv = '/baza-nc/cms/payoff-distribution/exportToCsv',
}

export interface BazaNcPayoffDistributionCmsEndpoint {
    list(request: BazaNcPayoffDistributionCmsListRequest): Promise<BazaNcPayoffDistributionCmsListResponse> | Observable<BazaNcPayoffDistributionCmsListResponse>;
    getByUlid(request: BazaNcPayoffDistributionCmsGetByUlidRequest): Promise<BazaNcPayoffDistributionCmsDto> | Observable<BazaNcPayoffDistributionCmsDto>;
    delete(request: BazaNcPayoffDistributionCmsDeleteRequest): Promise<void> | Observable<void>;
    exportToCsv(request: BazaNcPayoffDistributionCmsExportToCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcPayoffDistributionCmsListRequest extends CrudListRequestDto<BazaNcPayoffDistributionCmsDto> {
    @ApiModelProperty({
        description: 'Investor Account Id',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    investorAccountId?: number;
}

export class BazaNcPayoffDistributionCmsListResponse extends CrudListResponseDto<BazaNcPayoffDistributionCmsDto> {}

export class BazaNcPayoffDistributionCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionCmsExportToCsvRequest extends CrudExportToCsvRequest<BazaNcPayoffDistributionCmsCsvDto, BazaNcPayoffDistributionCmsListRequest> {}
