<div class="hiw-card">
    <div class="hiw-card__index">
        <div
            class="hiw-card__number"
            [innerHtml]="index"></div>
    </div>

    <div class="hiw-card__text">
        <div
            class="hiw-card__title"
            [innerHtml]="header || ''"></div>

        <div
            class="hiw-card__descr"
            [innerHtml]="description || ''"></div>
    </div>
</div>
