import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcBankAccountAddRequest,
    BazaNcBankAccountDto,
    BazaNcBankAccountEndpoint,
    BazaNcBankAccountEndpointPaths,
    BazaNcBankAccountExportRequest,
    BazaNcBankAccountGetDefaultByTypeRequest,
    BazaNcBankAccountGetDefaultByTypeResponse,
    BazaNcBankAccountLinkOnSuccessRequest,
    BazaNcBankAccountLinkOnSuccessResponse,
    BazaNcBankAccountRemoveResponse,
    BazaNcBankAccountSetRequest,
    BazaNcBankAccountType,
} from '@scaliolabs/baza-nc-shared';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';
import { BazaPlaidLinkRequestDto, BazaPlaidLinkResponseDto } from '@scaliolabs/baza-plaid-shared';
import { BazaNcBankAccountAchDto } from '@scaliolabs/baza-nc-shared';

/**
 * Bank Account Management for Baza-Nc
 */
@Injectable()
export class BazaNcBankAccountsDataAccess implements BazaNcBankAccountEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns list of all available bank accounts
     */
    list(): Observable<Array<BazaNcBankAccountDto>> {
        return this.http.get(BazaNcBankAccountEndpointPaths.list);
    }

    /**
     * Returns list of bank accounts for each BankNcAccountType which are selected as default
     * Please note that user may didn't select bank account for some types yet - in this case there will be no
     * bank account with this type in response available
     */
    defaults(): Observable<Array<BazaNcBankAccountDto>> {
        return this.http.get(BazaNcBankAccountEndpointPaths.defaults);
    }

    /**
     * Returns default bank account of given type
     * If bank account is not available for given type and request.throwError is false, the endpoint will returns { isAvailable: false }
     * If bank account is not available for given type and request.throwError is true, the endpoint throw an error message with "BazaNcBankAccountNoDefault" error code
     * You can also receive list of bank accounts of given type if you enable request.withOptions
     * @see BazaNcBankAccountGetDefaultByTypeRequest
     * @param type
     * @param request
     */
    default(
        type: BazaNcBankAccountType,
        request: BazaNcBankAccountGetDefaultByTypeRequest,
    ): Observable<BazaNcBankAccountGetDefaultByTypeResponse> {
        const url = replacePathArgs(BazaNcBankAccountEndpointPaths.default, {
            type,
        });

        return this.http.get(url, {
            withOptions: request.withOptions ? '1' : '0',
            throwError: request.throwError ? '1' : '0',
        });
    }

    /**
     * Returns Bank Account by ULID
     * @param ulid
     */
    get(ulid: string): Observable<BazaNcBankAccountDto> {
        return this.http.get(replacePathArgs(BazaNcBankAccountEndpointPaths.get, { ulid }));
    }

    /**
     * Adds a Bank Account (MANUAL)
     * Use `setAsDefault: true` to automatically set Bank Account as Default, i.e. use this account instantly for Purchase Flow / Future Payments
     * @param request
     */
    add(request: BazaNcBankAccountAddRequest): Observable<BazaNcBankAccountDto> {
        return this.http.post(BazaNcBankAccountEndpointPaths.add, request);
    }

    /**
     * Returns Plaid Link Token
     * You should use the Link Token for displaying Plaid Link widget. Use `linkOnSuccess` endpoint for handling
     * onSuccess callback
     * @param request
     */
    link(request: BazaPlaidLinkRequestDto): Observable<BazaPlaidLinkResponseDto> {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        return this.http.get(BazaNcBankAccountEndpointPaths.link, request);
    }

    /**
     * Plaid Link On-Success Handler
     * The method returns Bank Account. Additionally it warns if there is a duplicate account already exists
     * @param request
     */
    linkOnSuccess(request: BazaNcBankAccountLinkOnSuccessRequest): Observable<BazaNcBankAccountLinkOnSuccessResponse> {
        return this.http.post(BazaNcBankAccountEndpointPaths.linkOnSuccess, request);
    }

    /**
     * Removes Bank Account by ULID
     * If it's possible, a first Bank Account in list of Bank Accounts of same type will be selected as default
     * @param ulid
     */
    remove(ulid: string): Observable<BazaNcBankAccountRemoveResponse> {
        return this.http.delete(replacePathArgs(BazaNcBankAccountEndpointPaths.remove, { ulid }));
    }

    /**
     * Set Bank Account as default for given Bank Account Type
     * @param request
     */
    set(request: BazaNcBankAccountSetRequest): Observable<Array<BazaNcBankAccountDto>> {
        return this.http.post(BazaNcBankAccountEndpointPaths.set, request);
    }

    /**
     * Exports Bank Account to external services (North Capital or Dwolla)
     * Only one Bank Account could be exported to NC at once. Additional export calls will mark previosly exported
     * bank accounts as not exported to NC. Also, you can export only Cash-In Bank Accounts which will be used
     * for Cash-In operations or for purchasing shares using legacy API
     * Any number of Bank Accounts of any type could be exported to Dwolla.
     * @param request
     */
    export(request: BazaNcBankAccountExportRequest): Observable<BazaNcBankAccountDto> {
        return this.http.post(BazaNcBankAccountEndpointPaths.export, request);
    }

    /**
     * Returns Bank Account Details for ACH transactions which are currently set on NC
     * These details will be immediately used for next purchasing shares session
     * It's recommended to use this endpoint instead of `default` for displaying actual data on NC side instead of
     * possible data on Baza side
     */
    ncAchBankAccount(): Observable<BazaNcBankAccountAchDto> {
        return this.http.get(BazaNcBankAccountEndpointPaths.ncAchBankAccount);
    }
}
