export const VFFileUploadEnI18n = {
    title: 'Additional Information Required',
    descr: 'Please upload an image (png, jpg, jpeg, pdf) of a government issued ID (Drivers License, Passport, ID Card) to complete the verification.',
    actions: {
        btnUpload: {
            label: 'Upload',
            hint: 'New',
        },
        btnConfirm: {
            label: 'Confirm',
        },
    },
};
