import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { Domicile } from '../models/domicile';
import { EmpStatus } from '../models/emp-status';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { NorthCapitalYesNoBoolean } from '../common-types/north-capital-yes-no-boolean';
import { PartyId } from '../models/party-id';

export class UpdatePartyRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsEnum(Domicile)
    @IsOptional()
    domicile?: Domicile;

    @IsString()
    @IsOptional()
    firstName?: string;

    @IsString()
    @IsOptional()
    middleInitial?: string;

    @IsString()
    @IsOptional()
    lastName?: string;

    @IsString()
    @IsOptional()
    socialSecurityNumber?: string;

    @IsString()
    @IsOptional()
    dob?: string;

    @IsString()
    @IsOptional()
    primCountry?: string;

    @IsString()
    @IsOptional()
    primAddress1?: string;

    @IsString()
    @IsOptional()
    primAddress2?: string;

    @IsString()
    @IsOptional()
    primCity?: string;

    @IsString()
    @IsOptional()
    primState?: string;

    @IsString()
    @IsOptional()
    primZip?: string;

    @IsString()
    @IsOptional()
    emailAddress?: string;

    @IsString()
    @IsOptional()
    emailAddress2?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsInt()
    @IsOptional()
    phone2?: number;

    @IsString()
    @IsOptional()
    occupation?: string;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    associatedPerson?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    empCountry?: string;

    @IsString()
    @IsOptional()
    empAddress1?: string;

    @IsString()
    @IsOptional()
    empAddress2?: string;

    @IsString()
    @IsOptional()
    empCity?: string;

    @IsString()
    @IsOptional()
    empState?: string;

    @IsString()
    @IsOptional()
    empZip?: string;

    @IsString()
    @IsOptional()
    empName?: string;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    invest_to?: NorthCapitalYesNoBoolean;

    @IsNumber()
    @IsOptional()
    currentAnnIncome?: number;

    @IsNumber()
    @IsOptional()
    avgAnnIncome?: number;

    @IsNumber()
    @IsOptional()
    currentHouseholdIncome?: number;

    @IsNumber()
    @IsOptional()
    avgHouseholdIncome?: number;

    @IsNumber()
    @IsOptional()
    householdNetworth?: number;

    @IsEnum(KYCStatus)
    @IsOptional()
    KYCstatus?: KYCStatus;

    @IsEnum(AmlStatus)
    @IsOptional()
    AMLstatus?: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: string;

    @IsString()
    @IsOptional()
    tags?: string; // example: tag1,tag2,tag3

    @IsString()
    @IsOptional()
    createdIpAddress?: NorthCapitalIpAddress;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsEnum(EmpStatus)
    @IsOptional()
    empStatus?: EmpStatus;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;
}
