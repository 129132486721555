<nz-select
    class="country-picker__select"
    [compareWith]="onCompare"
    [nzCustomTemplate]="customTemplate"
    [nzDropdownClassName]="'country-picker'"
    [nzDropdownMatchSelectWidth]="false"
    [nzOptionHeightPx]="44"
    [ngModel]="phoneCode"
    (ngModelChange)="onCodeChange($event)">
    <nz-option
        nzCustomContent
        nzDisabled>
        <nz-input-group [nzPrefix]="iconSearch">
            <input
                nz-input
                placeholder="Search"
                type="text"
                [ngModel]="searchBy"
                (ngModelChange)="onSearch($event)"
                (focusout)="onFocusOut()" />
        </nz-input-group>
    </nz-option>

    <ng-container *ngIf="(codes$ | async)?.length; else codeEmptyState">
        <nz-option
            nzCustomContent
            nzLabel="+{{ code.phoneCode }}"
            [nzValue]="code"
            *ngFor="let code of codes$ | async">
            <svg-icon-sprite
                class="country-picker__img"
                src="/assets/sprites/countries.svg#{{ code.countryCode.toLowerCase() }}"></svg-icon-sprite>
            {{ code.countryName }} (+{{ code.phoneCode }})
        </nz-option>
    </ng-container>

    <ng-template #codeEmptyState>
        <nz-option
            nzCustomContent
            nzDisabled>
            <nz-empty></nz-empty>
        </nz-option>
    </ng-template>
</nz-select>

<ng-template #customTemplate>
    <svg-icon-sprite
        class="country-picker__img"
        src="/assets/sprites/countries.svg#{{ phoneCode.countryCode.toLowerCase() }}"></svg-icon-sprite>

    +{{ phoneCode.phoneCode }}
</ng-template>

<ng-template #iconSearch>
    <i class="icon icon-search"></i>
</ng-template>
