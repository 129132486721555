import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BazaPhoneCountryCodesDataAccess } from '@scaliolabs/baza-core-data-access';
import { PhoneCountryCodesRepositoryDto } from '@scaliolabs/baza-core-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetPhoneCodes, SearchPhoneCodes } from './actions';

export interface PhoneCodeStateModel {
    items: PhoneCountryCodesRepositoryDto;
    search: string;
}

@State<PhoneCodeStateModel>({
    name: 'bnccode',
    defaults: {
        items: [],
        search: '',
    },
})
@Injectable()
export class PhoneCodeState {
    constructor(private readonly dataAccess: BazaPhoneCountryCodesDataAccess, private readonly effectsUtil: EffectsUtil) {}

    @Selector()
    static items(state: PhoneCodeStateModel): PhoneCountryCodesRepositoryDto {
        if (state) {
            if (state.search) {
                return state.items.filter((item) => {
                    return item.countryName.toLowerCase().includes(state.search.toLowerCase());
                });
            } else {
                return state.items;
            }
        } else {
            return [];
        }
    }

    @Action(SearchPhoneCodes, { cancelUncompleted: true })
    searchPhoneCodes(ctx: StateContext<PhoneCodeStateModel>, action: SearchPhoneCodes) {
        ctx.patchState({
            search: action.search,
        });
    }

    @Action(GetPhoneCodes, { cancelUncompleted: true })
    getPhoneCodes(ctx: StateContext<PhoneCodeStateModel>): Observable<PhoneCountryCodesRepositoryDto> {
        return this.dataAccess.repository().pipe(
            tap((response) => {
                ctx.patchState({ items: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
