import { Injectable } from '@angular/core';
import { BazaRegistryEndpoint, BazaRegistryEndpointPaths, BazaRegistryCurrentSchemaResponse, BazaRegistryGetSchemaRecordResponse, BazaRegistryGetSchemaRecordRequest, BazaRegistryUpdateSchemaRecordRequest, BazaRegistryUpdateSchemaRecordResponse, BazaRegistryPublicSchema } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaRegistryDataAccess implements BazaRegistryEndpoint {
    constructor(
        private readonly ngEndpoint: BazaDataAccessService,
    ) {}

    publicSchema(): Observable<BazaRegistryPublicSchema> {
        return this.ngEndpoint.get<BazaRegistryPublicSchema>(BazaRegistryEndpointPaths.publicSchema);
    }

    currentSchema(): Observable<BazaRegistryCurrentSchemaResponse> {
        return this.ngEndpoint.get<BazaRegistryCurrentSchemaResponse>(BazaRegistryEndpointPaths.currentSchema);
    }

    getSchemaRecord(request: BazaRegistryGetSchemaRecordRequest): Observable<BazaRegistryGetSchemaRecordResponse> {
        return this.ngEndpoint.post<BazaRegistryGetSchemaRecordResponse>(BazaRegistryEndpointPaths.getSchemaRecord, request);
    }

    updateSchemaRecord(request: BazaRegistryUpdateSchemaRecordRequest): Observable<BazaRegistryUpdateSchemaRecordResponse> {
        return this.ngEndpoint.post<BazaRegistryUpdateSchemaRecordResponse>(BazaRegistryEndpointPaths.updateSchemaRecord, request);
    }
}
