export enum LinkType {
    Owner = 'owner',
    Manager = 'manager',
    Member = 'member',
    Officer = 'officer',
    Director = 'director',
    Spouse = 'spouse',
    Beneficiary = 'beneficiary',
    Trustee = 'trustee',
    Custodian = 'custodian',
    Parentco = 'parentco',
    Subsidiary = 'subsidiary',
    Other = 'other',
    AcGroup = 'acgroup',
    Advisor = 'advisor',
    Attorney = 'attorney',
    Proxy = 'proxy',
}
