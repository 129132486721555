import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDate, IsEnum, IsIn, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { AccountDto } from '../../../../baza-account/src';
import { CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { BazaDeviceTokenDto } from '../dto/baza-device-token.dto';
import { BazaDeviceTokenDestroyScope } from '../models/baza-device-token-destroy-scope';
import { BazaDeviceTokenType } from '../models/baza-device-token-type';

export enum BazaDeviceTokenCmsEndpointPaths {
    GetAll = '/baza-device-token/cms/getAll',
    Link = '/baza-device-token/cms/link',
    Unlink = '/baza-device-token/cms/unlink',
}

export interface BazaDeviceTokenCmsEndpoint {
    getAll(request: BazaDeviceTokenGetAllRequest): Promise<BazaDeviceTokenGetAllResponse> | Observable<BazaDeviceTokenGetAllResponse>;
    link(request: BazaDeviceTokenLinkRequest): Promise<BazaDeviceTokenLinkResponse> | Observable<BazaDeviceTokenLinkResponse>;
    unlink(request: BazaDeviceTokenUnlinkRequest): Promise<BazaDeviceTokenUnlinkResponse> | Observable<BazaDeviceTokenUnlinkResponse>;
}

// GetAll

export class BazaDeviceTokenGetAllRequest extends CrudListRequestDto<BazaDeviceTokenDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;
}

export class BazaDeviceTokenGetAllResponse extends CrudListResponseDto<BazaDeviceTokenDto> {
    @ApiModelProperty()
    account: AccountDto;

    @ApiModelProperty({
        type: BazaDeviceTokenDto,
        isArray: true,
    })
    items: Array<BazaDeviceTokenDto>;
}

// Link
export class BazaDeviceTokenLinkRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty()
    @IsEnum(BazaDeviceTokenType)
    @IsNotEmpty()
    type: BazaDeviceTokenType;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    token: string;
}

export class BazaDeviceTokenLinkResponse extends BazaDeviceTokenDto {}

// Unlink
export class BazaDeviceTokenUnlinkRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty()
    @IsEnum(BazaDeviceTokenType)
    @IsNotEmpty()
    type: BazaDeviceTokenType;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    token: string;
}

export class BazaDeviceTokenUnlinkResponse extends BazaDeviceTokenDto {}

// Destroy
export class BazaDeviceTokenDestroyRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    accountId?: number;

    @ApiModelProperty({
        description: 'Device token destruction scope',
        required: false,
    })
    @IsIn([...Object.values(BazaDeviceTokenType), 'All'])
    @IsOptional()
    scope?: BazaDeviceTokenDestroyScope;

    @ApiModelProperty({
        description: 'Date where the latest update occurred',
        required: false,
    })
    @IsDate()
    @IsOptional()
    beforeDate?: Date;
}

export class BazaDeviceTokenDestroyResponse {
    @ApiModelProperty({
        description: 'Number of destroyed device tokens',
    })
    affected: number;
}
