import { IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { DwollaBankAccountType } from '../models/dwolla-bank-account-type';
import { DwollaFundingSourceChannel } from '../models/dwolla-funding-source';
import { CreateFundingSourceRequest } from './create-funding-source.request';

interface onDemandAuthorization {
    href: string;
}

export class CreateBankAccountFundingSourceRequest extends CreateFundingSourceRequest {
    @Length(9)
    @IsNumberString()
    @IsNotEmpty()
    routingNumber: string;

    @IsNumberString()
    @IsNotEmpty()
    accountNumber: string;

    @IsEnum(DwollaBankAccountType)
    @IsNotEmpty()
    bankAccountType: DwollaBankAccountType;

    @IsOptional()
    _links?: {
        ['on-demand-authorization']: onDemandAuthorization;
    };

    @IsOptional()
    @IsString()
    plaidToken?: string;

    @IsEnum(DwollaFundingSourceChannel)
    @IsOptional()
    channels?: DwollaFundingSourceChannel;
}
