import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Application, BazaEnvironments } from '../../../../../baza-common/src';

export type Version = string;

export class VersionDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Current API version',
        example: '1.0.0',
    })
    current: Version;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        example: ['1.0.0', '0.0.9', '0.0.8'],
    })
    supported: Array<Version>;

    @ApiModelProperty({
        type: 'string',
        description: 'Current BAZA_ENV environment value',
        enum: Object.values(BazaEnvironments),
    })
    bazaEnv: BazaEnvironments;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
        description: 'Requested application',
    })
    application: Application;
}
