import { IsEnum, IsInt, IsOptional, IsString, Max, Min } from 'class-validator';
import { RiskProfile } from './risk-profile';
import { InvestmentExperience } from './investment-experience';
import { PrivOffExperience } from './priv-off-experience';
import { NorthCapitalPercentage, NorthCapitalYesNoBoolean } from '../common-types';
import { Education } from './education';

export class Suitability {
    @IsString()
    @IsOptional()
    riskProfile?: RiskProfile;

    @IsEnum(InvestmentExperience)
    @IsOptional()
    investmentExperience?: InvestmentExperience;

    @IsEnum(PrivOffExperience)
    @IsOptional()
    privOffExperience?: PrivOffExperience;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctPrivSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctIlliquidSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctLiquidSecurities?: NorthCapitalPercentage;

    @Min(0)
    @Max(100)
    @IsInt()
    @IsOptional()
    pctRealEstate?: NorthCapitalPercentage;

    @IsInt()
    @IsOptional()
    timeHorizon?: number;

    @IsEnum(Education)
    @IsOptional()
    education?: Education;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    financialAdvisor?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsString()
    @IsOptional()
    investmentObjective?: string;

    @IsString()
    @IsOptional()
    additionalnotes?: string;
}
