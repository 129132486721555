import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaNcPayoffDistributionTransactionStatus } from '../models/baza-nc-payoff-distribution-transaction-status';

export class BazaNcPayoffDistributionTransactionCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: '12/01/2022',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: '12/01/2022',
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: '12/01/2022',
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaNcPayoffDistributionTransactionStatus),
        example: BazaNcPayoffDistributionTransactionStatus.Draft,
    })
    status: BazaNcPayoffDistributionTransactionStatus;

    @ApiModelProperty({
        description: 'Transaction Title',
        example: 'PayoffDistribution Transaction 1',
    })
    title: string;
}
