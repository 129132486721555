import { BazaNcPayoffDistributionHistoryDto } from './baza-nc-payoff-distribution-history.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaNcPayoffDistributionPaymentSource } from '../models/baza-nc-payoff-distribution-payment-source';
import { BazaNcPayoffDistributionPaymentStatus } from '../models/baza-nc-payoff-distribution-payment-status';
import { OfferingId } from '../../../../transact-api';

export class BazaNcPayoffDistributionDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcPayoffDistributionPaymentSource),
    })
    source: BazaNcPayoffDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaNcPayoffDistributionPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        type: BazaNcPayoffDistributionHistoryDto,
        isArray: true,
        description: 'Payment status updates history',
    })
    history: Array<BazaNcPayoffDistributionHistoryDto>;
}
