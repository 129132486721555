import { IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { DwollaCustomerType } from '../models/customer-type';

export class CreateReceiveOnlyCustomerRequest {
    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @IsEnum(DwollaCustomerType)
    @IsNotEmpty()
    type?: DwollaCustomerType;

    @IsString()
    @IsOptional()
    businessName?: string;

    @IsString()
    @IsOptional()
    ipAddress?: string;
}
