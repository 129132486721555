import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { TradeId } from '../models/trade-id';

export class DeleteTradeRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    tradeId: TradeId;

    @IsString()
    @IsOptional()
    errDesc?: string;
}
