import { Observable } from 'rxjs';
import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { JobDto } from '../dto/job.dto';

export enum JobEndpointPaths {
    list = '/baza-content-types/jobs/list',
    getAll = '/baza-content-types/jobs/all',
    getById = '/baza-content-types/jobs/id/:id',
    getByUrl = '/baza-content-types/jobs/url/:url',
}

export interface JobEndpoint {
    list(request: JobsListRequest): Promise<JobsListResponse> | Observable<JobsListResponse>;
    getAll(): Promise<Array<JobDto>> | Observable<Array<JobDto>>;
    getById(id: number): Promise<JobDto> | Observable<JobDto>;
    getByUrl(url: string): Promise<JobDto> | Observable<JobDto>;
}

export class JobsListRequest extends CrudListRequestQueryDto {}

export class JobsListResponse extends CrudListResponseDto<JobDto> {
    @ApiModelProperty({
        type: JobDto,
        isArray: true,
    })
    items: Array<JobDto>;
}
