<nz-modal
    [nzAutofocus]="null"
    [nzClosable]="true"
    nzClassName="ant-modal-sm"
    [nzFooter]="footerTpl"
    (nzOnCancel)="cancel.emit()"
    [nzVisible]="true"
    nzWrapClassName="confirm-modal">
    <ng-container *nzModalTitle>
        <ng-content select="[modalTitle]"></ng-content>
    </ng-container>

    <ng-container *nzModalContent>
        <ng-content select="[modalContent]"></ng-content>
    </ng-container>

    <ng-template #footerTpl>
        <button
            nz-button
            (click)="cancel.emit()">
            {{ cancelBtnText }}
        </button>

        <button
            nz-button
            nzType="primary"
            [nzDanger]="confirmBtnDanger"
            (click)="confirm.emit()">
            {{ confirmBtnText }}
        </button>
    </ng-template>
</nz-modal>
