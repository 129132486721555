<div class="payment-header">
    <a
        *ngIf="isClosable"
        class="ant-modal-close-x"
        role="button"
        (click)="closeClicked.emit()">
        <i class="icon icon-close payment-header__icon"></i>
    </a>

    <a
        *ngIf="isBackBtnVisible"
        class="ant-modal-close-x"
        role="button"
        (click)="backClicked.emit()">
        <i class="icon icon-angle-left payment-header__icon"></i>
    </a>
</div>
