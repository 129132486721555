import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export enum NewsletterEndpointPaths {
    subscribe = '/baza-content-types/newsletters/subscribe',
    unsubscribe = '/baza-content-types/newsletters/unsubscribe'
}

export interface NewsletterEndpoint {
    subscribe(request: NewsletterSubscribeRequest): Promise<void> | Observable<void>;
    unsubscribe(request: NewsletterUnsubscribeRequest): Promise<void> | Observable<void>;
}

export class NewsletterSubscribeRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty({
        example: 'Jhon.Doe@gmail.com',
        required: false,
    })
    @IsEmail({}, {
        message: "Invalid email address, Please enter a valid email."
    })
    @IsNotEmpty()
    email: string;
}

export class NewsletterUnsubscribeRequest {
    @ApiModelProperty({
        example: 'Jhon.Doe@gmail.com',
        required: false,
    })
    @IsEmail({}, {
        message: "Invalid email address, Please enter a valid email."
    })
    @IsNotEmpty()
    email: string;
}
