import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '../../../../../ng/src';
import { Observable } from 'rxjs';
import { Auth2FAConfigResponse, Auth2FASettingsEndpoint, Auth2FASettingsEndpointPaths, Auth2FAUpdateSettingsRequest } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaAuth2FASettingsDataAccess implements Auth2FASettingsEndpoint {
    constructor(
        private readonly http: BazaDataAccessService,
    ) {
    }

    config(): Observable<Auth2FAConfigResponse> {
        return this.http.post(Auth2FASettingsEndpointPaths.config);
    }

    enable2FAEmail(request: Auth2FAUpdateSettingsRequest): Observable<void> {
        return this.http.post(Auth2FASettingsEndpointPaths.enable2FAEmail, request);
    }

    disable2FAEmail(request: Auth2FAUpdateSettingsRequest): Observable<void> {
        return this.http.post(Auth2FASettingsEndpointPaths.disable2FAEmail, request);
    }

    enable2FAGoogle(request: Auth2FAUpdateSettingsRequest): Observable<void> {
        return this.http.post(Auth2FASettingsEndpointPaths.enable2FAGoogle, request);
    }

    disable2FAGoogle(request: Auth2FAUpdateSettingsRequest): Observable<void> {
        return this.http.post(Auth2FASettingsEndpointPaths.disable2FAGoogle, request);
    }
}
