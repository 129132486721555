import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

export enum BazaSocialNetworks {
    LinkedIn = 'linkedin',
    Twitter = 'twitter',
    GitHub = 'github',
    Other = 'other',
}

export class BazaSocialNetworkDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaSocialNetworks),
    })
    @IsEnum(BazaSocialNetworks)
    @IsNotEmpty()
    name: BazaSocialNetworks;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    url: string;
}

export type BazaSocialNetworksDto = Array<BazaSocialNetworkDto>;
