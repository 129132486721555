<div class="container verification">
    <div
        nz-row
        nzGutter="30">
        <div
            nz-col
            nzXs="12"
            class="verification__steps">
            <nz-steps
                nzSize="small"
                [nzCurrent]="currentTab">
                <nz-step [nzTitle]="wts.getI18nLabel(i18nBasePath, 'steps.info')"></nz-step>
                <nz-step [nzTitle]="wts.getI18nLabel(i18nBasePath, 'steps.profile')"></nz-step>
            </nz-steps>
        </div>
    </div>

    <ng-container *ngIf="currentTab === 0">
        <ng-content select="[target=info]"></ng-content>
    </ng-container>

    <ng-container *ngIf="currentTab === 1">
        <ng-content select="[target=investor]"></ng-content>
    </ng-container>
</div>
