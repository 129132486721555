import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentDto } from '@scaliolabs/baza-core-shared';
import { TimelineDto } from './timeline.dto';

export class TimelineCmsDto extends TimelineDto {
    @ApiModelProperty({
        description: 'Image Attachment (Optional)',
        required: false,
    })
    imageAttachment?: AttachmentDto;
}
