import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    ContactsSubjectCmsCreateRequest,
    ContactsSubjectCmsCreateResponse,
    ContactsSubjectCmsDeleteRequest,
    ContactsSubjectCmsEndpoint,
    ContactsSubjectCmsEndpointPaths,
    ContactsSubjectCmsGetByIdRequest,
    ContactsSubjectCmsListRequest,
    ContactsSubjectCmsListResponse,
    ContactsSubjectCmsResponse,
    ContactsSubjectCmsUpdateResponse,
    ContactsSubjectCmsUpdateRequest,
    ContactsSubjectCmsSetSortOrderResponse,
    ContactsSubjectCmsSetSortOrderRequest,
} from '@scaliolabs/baza-content-types-shared';

@Injectable()
export class ContactsSubjectCmsDataAccess implements ContactsSubjectCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    list(request: ContactsSubjectCmsListRequest): Observable<ContactsSubjectCmsListResponse> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.list, request);
    }

    getById(request: ContactsSubjectCmsGetByIdRequest): Observable<ContactsSubjectCmsResponse> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.getById, request);
    }

    create(request: ContactsSubjectCmsCreateRequest): Observable<ContactsSubjectCmsCreateResponse> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.create, request);
    }

    update(request: ContactsSubjectCmsUpdateRequest): Observable<ContactsSubjectCmsUpdateResponse> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.update, request);
    }

    delete(request: ContactsSubjectCmsDeleteRequest): Observable<void> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.delete, request);
    }

    setSortOrder(request: ContactsSubjectCmsSetSortOrderRequest): Observable<ContactsSubjectCmsSetSortOrderResponse> {
        return this.http.post(ContactsSubjectCmsEndpointPaths.setSortOrder, request);
    }
}
