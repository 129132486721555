import { Injectable } from '@angular/core';

@Injectable()
export class BazaMaintenanceNgConfig {
    /**
     * Skip Maintenance check (useful for CMS)
     */
    skip: boolean;

    /**
     * Redirect to
     */
    redirectTo?: string;
}
