export const ADD_FAVORITE_FAIL = 'There was an error during the process.';
export const ADD_FAVORITE_SUCCESS = 'Item successfully added to Favorites';
export const ADD_NOTIFICATION_FAIL = 'There was an error adding the Notification.';
export const ADD_NOTIFICATION_SUCCESS_CONTENT = '';
export const ADD_NOTIFICATION_SUCCESS_TITLE = "You're on the list! We'll let you know the moment this offering is live.";
export const ITEM_NOT_FOUND = 'Item not found.';
export const REMOVE_FAVORITE_FAIL = 'There was an error during the process.';
export const REMOVE_FAVORITE_SUCCESS = 'Item successfully removed from Favorites';
export const REMOVE_NOTIFICATION_FAIL = 'There was an error removing the Notification.';
export const REMOVE_NOTIFICATION_SUCCESS_CONTENT = '';
export const REMOVE_NOTIFICATION_SUCCESS_TITLE =
    "Are you sure you don't want early access? Click “Notify Me” if you want to put your name back on the list.";
