import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import {
    BazaNcIntegrationFavoriteDataAccess,
    BazaNcIntegrationListingsDataAccess,
    BazaNcIntegrationSearchDataAccess,
    BazaNcIntegrationSubscriptionDataAccess,
} from '@scaliolabs/baza-nc-integration-data-access';
import {
    BazaNcIntegrationListingsListResponse,
    BazaNcIntegrationSubscribedResponse,
    BazaNcIntegrationSubscribeStatus,
    BazaNcIntegrationUnsubscribedResponse,
} from '@scaliolabs/baza-nc-integration-shared';
import {
    AddFavorite,
    AddNotification,
    ChangeItemsPage,
    ClearItems,
    GetFavorites,
    GetItem,
    GetItems,
    RemoveFavorite,
    RemoveNotification,
} from './actions';
import { ItemDto, Paginator } from '../../models';
import {
    ADD_FAVORITE_FAIL,
    ADD_FAVORITE_SUCCESS,
    ADD_NOTIFICATION_FAIL,
    ADD_NOTIFICATION_SUCCESS_CONTENT,
    ADD_NOTIFICATION_SUCCESS_TITLE,
    ITEM_NOT_FOUND,
    REMOVE_FAVORITE_FAIL,
    REMOVE_FAVORITE_SUCCESS,
    REMOVE_NOTIFICATION_FAIL,
    REMOVE_NOTIFICATION_SUCCESS_CONTENT,
    REMOVE_NOTIFICATION_SUCCESS_TITLE,
} from './messages';

export interface ItemStateModel {
    current?: ItemDto;
    items: ItemDto[];
    favorites: ItemDto[];
    paging: Paginator;
}

@Injectable()
@State<ItemStateModel>({
    name: 'item',
    defaults: {
        current: undefined,
        items: undefined,
        favorites: undefined,
        paging: {
            index: 1,
            size: 0,
            total: 0,
        },
    },
})
export class ItemState {
    constructor(
        private readonly dataAccess: BazaNcIntegrationListingsDataAccess,
        private readonly effectsUtil: EffectsUtil,
        private readonly notification: NzNotificationService,
        private readonly notificationDataAccess: BazaNcIntegrationSubscriptionDataAccess,
        private readonly favoriteDataAccess: BazaNcIntegrationFavoriteDataAccess,
        private readonly searchDataAccess: BazaNcIntegrationSearchDataAccess,
    ) {}

    @Selector()
    static current(state: ItemStateModel) {
        return state.current;
    }

    @Selector()
    static items(state: ItemStateModel) {
        return state?.items || [];
    }

    @Selector()
    static paging(state: ItemStateModel) {
        return state.paging;
    }

    @Selector()
    static favorites(state: ItemStateModel) {
        return state?.favorites || [];
    }

    @Action(ClearItems)
    clearItems(ctx: StateContext<ItemStateModel>): Observable<void> | ItemStateModel {
        return ctx.patchState({
            items: [],
            paging: {
                index: 1,
                size: 12,
                total: 0,
            },
        });
    }

    @Action(GetItem)
    getItem(ctx: StateContext<ItemStateModel>, action: GetItem) {
        ctx.patchState({
            current: null,
        });

        const param = !isNaN(+action.item);

        if (param) {
            return this.dataAccess.getById({ id: +action.item }).pipe(
                tap((response) => {
                    return ctx.patchState({
                        current: response,
                    });
                }),
                this.effectsUtil.tryCatchError$(ITEM_NOT_FOUND),
            );
        } else {
            return this.dataAccess.getBySlug({ slug: action.item.toString() }).pipe(
                tap((response) => {
                    return ctx.patchState({
                        current: response,
                    });
                }),
                this.effectsUtil.tryCatchError$(ITEM_NOT_FOUND),
            );
        }
    }

    @Action(GetItems)
    getItems(ctx: StateContext<ItemStateModel>) {
        const state = ctx.getState();

        return this.searchDataAccess
            .search({
                index: state.paging.index,
                size: state.paging.size,
            })
            .pipe(
                tap((response) =>
                    ctx.patchState({
                        items: response.items,
                        paging: response.pager,
                    }),
                ),
                this.effectsUtil.tryCatchNone$(),
            );
    }

    @Action(ChangeItemsPage)
    changeItemsPage(ctx: StateContext<ItemStateModel>, action: ChangeItemsPage) {
        const state = ctx.getState();

        ctx.patchState({
            paging: {
                ...state.paging,
                index: action.index,
            },
        });

        return this.getItems(ctx);
    }

    @Action(AddNotification)
    addNotification(ctx: StateContext<ItemStateModel>, action: AddNotification): Observable<BazaNcIntegrationSubscribedResponse> {
        return this.notificationDataAccess.subscribe({ listingId: action.itemId }).pipe(
            tap(() => {
                const ctxProperty = ctx.getState().current;
                this.notification.success(ADD_NOTIFICATION_SUCCESS_TITLE, ADD_NOTIFICATION_SUCCESS_CONTENT);

                return ctx.patchState({
                    current: {
                        ...ctxProperty,
                        subscribeStatus: BazaNcIntegrationSubscribeStatus.Subscribed,
                    },
                });
            }),
            this.effectsUtil.tryCatchError$(ADD_NOTIFICATION_FAIL),
        );
    }

    @Action(RemoveNotification)
    removeNotification(ctx: StateContext<ItemStateModel>, action: RemoveNotification): Observable<BazaNcIntegrationUnsubscribedResponse> {
        return this.notificationDataAccess.unsubscribe({ listingId: action.itemId }).pipe(
            tap(() => {
                const ctxProperty = ctx.getState().current;
                this.notification.success(REMOVE_NOTIFICATION_SUCCESS_TITLE, REMOVE_NOTIFICATION_SUCCESS_CONTENT);

                return ctx.patchState({
                    current: {
                        ...ctxProperty,
                        subscribeStatus: BazaNcIntegrationSubscribeStatus.NotSubscribed,
                    },
                });
            }),
            this.effectsUtil.tryCatchError$(REMOVE_NOTIFICATION_FAIL),
        );
    }

    @Action(GetFavorites)
    getFavorites(ctx: StateContext<ItemStateModel>): Observable<BazaNcIntegrationListingsListResponse> {
        return this.dataAccess.list({ size: -1, isFavorite: true }).pipe(
            tap((response) => {
                ctx.patchState({
                    favorites: response.items,
                });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(AddFavorite)
    addFavorite(ctx: StateContext<ItemStateModel>, action: AddFavorite): Observable<void> {
        return this.favoriteDataAccess.include(action.itemRequest).pipe(
            tap(() => {
                // update current item
                const ctxItem = ctx.getState().current;

                if (ctxItem && ctxItem.id === action.itemRequest.listingId) {
                    ctx.patchState({
                        current: {
                            ...ctxItem,
                            isFavorite: true,
                        },
                    });
                }

                // update items list
                const ctxItems = ctx.getState().items.map((stateItem) => {
                    const item = Object.assign({}, stateItem);

                    if (item.id === action.itemRequest.listingId) {
                        item.isFavorite = true;
                    }
                    return item;
                });

                // update items list
                ctx.patchState({ items: ctxItems });

                // update favorites
                const ctxFavorites = ctx.getState().favorites?.map((stateItem) => {
                    const item = Object.assign({}, stateItem);

                    if (item.id === action.itemRequest.listingId) {
                        item.isFavorite = true;
                    }
                    return item;
                });

                // update favorite list
                return ctx.patchState({
                    favorites: ctxFavorites,
                });
            }),
            this.effectsUtil.tryCatch$(ADD_FAVORITE_SUCCESS, ADD_FAVORITE_FAIL),
        );
    }

    @Action(RemoveFavorite)
    removeFavorite(ctx: StateContext<ItemStateModel>, action: RemoveFavorite): Observable<void> {
        return this.favoriteDataAccess.exclude(action.itemRequest).pipe(
            tap(() => {
                // update current property
                const ctxItem = ctx.getState().current;

                if (ctxItem && ctxItem.id === action.itemRequest.listingId) {
                    ctx.patchState({
                        current: {
                            ...ctxItem,
                            isFavorite: false,
                        },
                    });
                }

                if (ctx.getState().items) {
                    // update properties list
                    const ctxItems = ctx.getState().items.map((stateItem) => {
                        const item = Object.assign({}, stateItem);

                        if (item.id === action.itemRequest.listingId) {
                            item.isFavorite = false;
                        }
                        return item;
                    });

                    // update properties list
                    ctx.patchState({ items: ctxItems });
                }

                // update favorites
                if (ctx.getState().favorites) {
                    ctx.setState(
                        patch({
                            favorites: removeItem<ItemDto>((fav) => fav.id === action.itemRequest.listingId),
                        }),
                    );
                }
            }),
            this.effectsUtil.tryCatch$(REMOVE_FAVORITE_SUCCESS, REMOVE_FAVORITE_FAIL),
        );
    }
}
