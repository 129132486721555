/**
 * Dwolla Event Topics
 * @see https://developers.dwolla.com/api-reference/events#transfers-1
 */
export enum DwollaEventTopic {
    // == Dwolla Master Account Topics ==

    // Accounts
    account_suspended = 'account_suspended',
    account_activated = 'account_activated',

    // Funding Sources
    funding_source_added = 'funding_source_added',
    funding_source_removed = 'funding_source_removed',
    funding_source_verified = 'funding_source_verified',
    funding_source_unverified = 'funding_source_unverified',
    funding_source_negative = 'funding_source_negative',
    funding_source_updated = 'funding_source_updated',
    microdeposits_added = 'microdeposits_added',
    microdeposits_failed = 'microdeposits_failed',
    microdeposits_completed = 'microdeposits_completed',
    microdeposits_maxattempts = 'microdeposits_maxattempts',

    // Transfers
    bank_transfer_created = 'bank_transfer_created',
    bank_transfer_creation_failed = 'bank_transfer_creation_failed',
    bank_transfer_cancelled = 'bank_transfer_cancelled',
    bank_transfer_failed = 'bank_transfer_failed',
    bank_transfer_completed = 'bank_transfer_completed',
    transfer_created = 'transfer_created',
    transfer_cancelled = 'transfer_cancelled',
    transfer_failed = 'transfer_failed',
    transfer_completed = 'transfer_completed',
    card_transfer_created = 'card_transfer_created',
    card_transfer_cancelled = 'card_transfer_cancelled',
    card_transfer_failed = 'card_transfer_failed',
    card_transfer_completed = 'card_transfer_completed',

    // Mass Payments
    mass_payment_created = 'mass_payment_created',
    mass_payment_completed = 'mass_payment_completed',
    mass_payment_cancelled = 'mass_payment_cancelled',

    // Statements
    statement_created = 'statement_created',

    // == Customer Account Event Topics ==

    // Customers
    customer_created = 'customer_created',
    customer_kba_verification_needed = 'customer_kba_verification_needed',
    customer_kba_verification_failed = 'customer_kba_verification_failed',
    customer_kba_verification_passed = 'customer_kba_verification_passed',
    customer_verification_document_needed = 'customer_verification_document_needed',
    customer_verification_document_uploaded = 'customer_verification_document_uploaded',
    customer_verification_document_failed = 'customer_verification_document_failed',
    customer_verification_document_approved = 'customer_verification_document_approved',
    customer_reverification_needed = 'customer_reverification_needed',
    customer_verified = 'customer_verified',
    customer_suspended = 'customer_suspended',
    customer_activated = 'customer_activated',
    customer_deactivated = 'customer_deactivated',

    // Beneficial Owners
    customer_beneficial_owner_created = 'customer_beneficial_owner_created',
    customer_beneficial_owner_removed = 'customer_beneficial_owner_removed',
    customer_beneficial_owner_verification_document_needed = 'customer_beneficial_owner_verification_document_needed',
    customer_beneficial_owner_verification_document_uploaded = 'customer_beneficial_owner_verification_document_uploaded',
    customer_beneficial_owner_verification_document_failed = 'customer_beneficial_owner_verification_document_failed',
    customer_beneficial_owner_verification_document_approved = 'customer_beneficial_owner_verification_document_approved',
    customer_beneficial_owner_reverification_needed = 'customer_beneficial_owner_reverification_needed',
    customer_beneficial_owner_verified = 'customer_beneficial_owner_verified',

    // Funding Sources
    customer_funding_source_added = 'customer_funding_source_added',
    customer_funding_source_removed = 'customer_funding_source_removed',
    customer_funding_source_verified = 'customer_funding_source_verified',
    customer_funding_source_unverified = 'customer_funding_source_unverified',
    customer_funding_source_negative = 'customer_funding_source_negative',
    customer_funding_source_updated = 'customer_funding_source_updated',
    customer_microdeposits_added = 'customer_microdeposits_added',
    customer_microdeposits_failed = 'customer_microdeposits_failed',
    customer_microdeposits_completed = 'customer_microdeposits_completed',
    customer_microdeposits_maxattempts = 'customer_microdeposits_maxattempts',

    // Transfers
    customer_bank_transfer_created = 'customer_bank_transfer_created',
    customer_bank_transfer_creation_failed = 'customer_bank_transfer_creation_failed',
    customer_bank_transfer_cancelled = 'customer_bank_transfer_cancelled',
    customer_bank_transfer_failed = 'customer_bank_transfer_failed',
    customer_bank_transfer_completed = 'customer_bank_transfer_completed',
    customer_transfer_created = 'customer_transfer_created',
    customer_transfer_cancelled = 'customer_transfer_cancelled',
    customer_transfer_failed = 'customer_transfer_failed',
    customer_transfer_completed = 'customer_transfer_completed',

    // Mass Payments
    customer_mass_payment_created = 'customer_mass_payment_created',
    customer_mass_payment_completed = 'customer_mass_payment_completed',
    customer_mass_payment_cancelled = 'customer_mass_payment_cancelled',
    customer_balance_inquiry_completed = 'customer_balance_inquiry_completed',

    // Labels
    customer_label_created = 'customer_label_created',
    customer_label_ledger_entry_created = 'customer_label_ledger_entry_created',
    customer_label_removed = 'customer_label_removed',
}
