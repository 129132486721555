import { BazaNcDwollaCustomerDetailDto } from '../dto/baza-nc-dwolla-customer-detail.dto';
import { Observable } from 'rxjs';
import { BazaNcDwollaTouchResponse } from '../dto/baza-nc-dwolla-touch-response.dto';

export enum BazaNcDwollaEndpointPaths {
    touch = '/baza-nc/dwolla/touch',
    current = '/baza-nc/dwolla/current',
}

export interface BazaNcDwollaEndpoint {
    touch(...args: unknown[]): Promise<BazaNcDwollaTouchResponse> | Observable<BazaNcDwollaTouchResponse>;
    current(...args: unknown[]): Promise<BazaNcDwollaCustomerDetailDto> | Observable<BazaNcDwollaCustomerDetailDto>;
}
