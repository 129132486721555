import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NzCardModule } from 'ng-zorro-antd/card';

@Component({
    selector: 'app-invest-card',
    templateUrl: './invest-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, IconSpriteModule, NzCardModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvestCardComponent extends MktSectionCommonFields {
    @Input()
    icon?: string;
}
