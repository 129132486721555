import { NavigationExtras } from '@angular/router';
import { RequireOnlyOne } from './require-only-one';

/**
 * Base interface for link configuration
 */
interface BaseLink {
    /**
     * The text to be displayed for the link
     */
    text?: string;

    /**
     * The target attribute for the link
     */
    target?: string;
}

/**
 * Interface for a link that navigates to an internal route in the app
 */
export interface AppLink extends BaseLink {
    /**
     * The commands to be passed to the router for navigation
     */
    commands: unknown[];

    /**
     * Additional options for the navigation
     * @see {@link https://angular.io/api/router/NavigationExtras}
     */
    navExtras?: NavigationExtras;
}

/**
 * Interface for a link that navigates to an external url
 */
export interface ExtLink extends BaseLink {
    /**
     * The url to navigate to
     */
    link: string;

    /**
     * Indicates if the link is a CMS link
     */
    isCMSLink?: boolean;

    /**
     * Indicates if the link is a mail link
     */
    isMailLink?: boolean;
}

/**
 * Interface for a link configuration that can be either an AppLink or an ExtLink
 */
interface LinkConfiguration {
    /**
     * The AppLink configuration
     */
    appLink?: AppLink;

    /**
     * The ExtLink configuration
     */
    extLink?: ExtLink;
}

/**
 * Type that represents a LinkConfig, which can be either an AppLink or an ExtLink
 */
export type LinkConfig = RequireOnlyOne<LinkConfiguration>;

/**
 * Type that represents a NavigationLink, which can be a LinkConfig, AppLink or ExtLink
 */
export type NavigationLink = LinkConfig | AppLink | ExtLink;

export enum LinkTypeEnum {
    AppLink = 'appLink',
    ExtLink = 'extLink',
}
