import { ContactsSubjectDto } from '../dto/public/contacts-subject.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsEmail, IsInt, IsNotEmpty, IsPositive, IsString, MaxLength } from 'class-validator';
import { BazaContentTypesContactsConstants } from '../contants/baza-content-types-contacts.constants';

export enum ContactsEndpointPaths {
    subjects = '/baza-content-types/contacts/subjects',
    send = '/baza-content-types/contacts/send',
}

export interface ContactsEndpoint {
    subjects(): Promise<ContactsSubjectsResponse> | Observable<ContactsSubjectsResponse>;
    send(request: ContactsSendRequest): Promise<void> | Observable<void>;
}

export type ContactsSubjectsResponse = Array<ContactsSubjectDto>;

export class ContactsSendRequest {
    @ApiModelProperty({
        description: 'Subject Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    subjectId: number;

    @ApiModelProperty({
        description: 'Name (Last Name)',
    })
    @MaxLength(BazaContentTypesContactsConstants.VALIDATION_NAME_MAX_LENGTH)
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        description: 'Email to contact',
    })
    @MaxLength(BazaContentTypesContactsConstants.VALIDATION_EMAIL_MAX_LENGTH)
    @IsEmail()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty({
        description: 'Message body',
    })
    @MaxLength(BazaContentTypesContactsConstants.VALIDATION_MESSAGE_MAX_LENGTH)
    @IsString()
    @IsNotEmpty()
    message: string;
}
