import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { EmpStatus } from '../models/emp-status';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { Domicile } from '../models/domicile';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { NorthCapitalYesNoBoolean } from '../common-types';

export class CreatePartyRequest {
    @IsEnum(Domicile)
    @IsNotEmpty()
    domicile: Domicile;

    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsOptional()
    middleInitial?: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsString()
    @IsOptional()
    socialSecurityNumber?: string;

    @IsString()
    @IsNotEmpty()
    dob: string;

    @IsString()
    @IsNotEmpty()
    primCountry: string;

    @IsString()
    @IsNotEmpty()
    primAddress1: string;

    @IsString()
    @IsOptional()
    primAddress2?: string;

    @IsString()
    @IsNotEmpty()
    primCity: string;

    @IsString()
    @IsNotEmpty()
    primState: string;

    @IsString()
    @IsNotEmpty()
    primZip: string;

    @IsString()
    @IsNotEmpty()
    emailAddress: string;

    @IsString()
    @IsOptional()
    emailAddress2?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsInt()
    @IsOptional()
    phone2?: number;

    @IsString()
    @IsOptional()
    occupation?: string;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    associatedPerson?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    empCountry?: string;

    @IsString()
    @IsOptional()
    empAddress1?: string;

    @IsString()
    @IsOptional()
    empAddress2?: string;

    @IsString()
    @IsOptional()
    empCity?: string;

    @IsEnum(EmpStatus)
    @IsOptional()
    empState?: EmpStatus;

    @IsString()
    @IsOptional()
    empZip?: string;

    @IsString()
    @IsOptional()
    empName?: string;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    invest_to?: NorthCapitalYesNoBoolean;

    @IsNumber()
    @IsOptional()
    currentAnnIncome?: number;

    @IsNumber()
    @IsOptional()
    avgAnnIncome?: number;

    @IsNumber()
    @IsOptional()
    currentHouseholdIncome?: number;

    @IsNumber()
    @IsOptional()
    avgHouseholdIncome?: number;

    @IsNumber()
    @IsOptional()
    householdNetworth?: number;

    @IsEnum(KYCStatus)
    @IsOptional()
    KYCstatus?: KYCStatus;

    @IsEnum(AmlStatus)
    @IsOptional()
    AMLstatus?: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: string;

    @IsString()
    @IsOptional()
    tags?: string; // example: tag1,tag2,tag3

    @IsString()
    @IsOptional()
    createdIpAddress?: NorthCapitalIpAddress;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsEnum(EmpStatus)
    @IsOptional()
    empStatus?: EmpStatus;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;
}
