import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcOperationEndpoint,
    BazaNcOperationEndpointPaths,
    BazaNcOperationListRequest,
    BazaNcOperationListResponse,
    BazaNcOperationStatsResponse,
    BazaNcOperationSumResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';

/**
 * Data-Access Service for Baza NC Operations
 */
@Injectable()
export class BazaNcOperationDataAccess implements BazaNcOperationEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns list of operations. The endpoint returns combined list of
     * purchases, withdraw and transfer operations in single list
     * @param request
     */
    list(request: BazaNcOperationListRequest): Observable<BazaNcOperationListResponse> {
        return this.http.post(BazaNcOperationEndpointPaths.list, request);
    }

    /**
     * Returns Operation Stats for Investor Account
     */
    stats(): Observable<BazaNcOperationStatsResponse> {
        return this.http.get(BazaNcOperationEndpointPaths.stats);
    }

    /**
     * Sums transactions based on the provided request.
     * @param {BazaNcOperationListRequest} request - The request object containing the criteria for summing transactions.
     * @returns {Observable<BazaNcOperationSumResponse>} - An observable that emits the sum of transactions.
     */
    sumTransactions(request: BazaNcOperationListRequest): Observable<BazaNcOperationSumResponse> {
        return this.http.post(BazaNcOperationEndpointPaths.sumOperations, request);
    }
}
