import { AbstractControl, ValidationErrors } from '@angular/forms';

export const isAdultValidator = (): ((AbstractControl) => ValidationErrors | null) => {
    return (control: AbstractControl): ValidationErrors | null => {
        const targetValue = control.value;

        if (!targetValue) {
            return null;
        }

        const value = new Date(targetValue);

        return new Date(value.getFullYear() + 18, value.getMonth() - 1, value.getDay()) <= new Date() ? null : { isAdult: true };
    };
};
