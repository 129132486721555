import { CommonModule } from '@angular/common';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PhoneCountryCode } from './models/phone-code.interface';
import { GetPhoneCodes, PhoneCodeState, PhoneCodeStateModule, SearchPhoneCodes } from './store';
@UntilDestroy()
@Component({
    selector: 'app-phone-code',
    templateUrl: './phone-code.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, FormsModule, PhoneCodeStateModule, RouterModule, NzEmptyModule, NzInputModule, NzSelectModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PhoneCodeComponent implements OnInit {
    codes$ = this.store.select(PhoneCodeState.items);

    @Input()
    numericCode: string;

    phoneCode: PhoneCountryCode;

    @Output()
    codeChange = new EventEmitter<PhoneCountryCode>();

    public searchBy: string;

    constructor(private readonly store: Store, private readonly cdRef: ChangeDetectorRef) {
        this.store.dispatch(new GetPhoneCodes());
    }

    ngOnInit(): void {
        this.codes$.pipe(untilDestroyed(this)).subscribe((codes) => {
            // set default phoneCode
            if (codes.length && !this.phoneCode) {
                this.phoneCode = codes.find((code) => code.numericCode === this.numericCode);
                this.codeChange.emit(this.phoneCode);
            }
        });
    }

    public onCodeChange(code: PhoneCountryCode) {
        this.phoneCode = code;
        this.codeChange.emit(this.phoneCode);
    }

    public onFocusOut() {
        // for some reason there is an issue with updating state of input
        // and we need to update it manually
        this.cdRef.detectChanges();
    }

    public onCompare = (o1: PhoneCountryCode, o2: PhoneCountryCode) => (o1 && o2 ? o1.countryCode === o2.countryCode : o1 === o2);

    public onSearch(search: string) {
        this.store.dispatch(new SearchPhoneCodes(search));
    }
}
