import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class PhoneCountryCodesDto {
    @ApiModelProperty({
        description: 'Numeric code (ID)',
        example: '248',
    })
    numericCode: string;

    @ApiModelProperty({
        description: 'Phone code',
        example: '358',
    })
    phoneCode: string;

    @ApiModelProperty({
        description: 'Country name',
        example: 'Aland Islands',
    })
    countryName: string;

    @ApiModelProperty({
        description: 'Country code',
        example: 'AX',
    })
    countryCode: string;

    @ApiModelProperty({
        description: 'Unicode',
        example: 'U+1F1E6 U+1F1FD',
    })
    unicode: string;
}
