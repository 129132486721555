import { Observable } from 'rxjs';
import { BazaNcLimitsDto } from '../dto/baza-nc-limits.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { PurchaseFlowLimitsDto } from '../dto/purchase-flow-limits.dto';

export enum BazaNcPurchaseFlowLimitsEndpointPaths {
    limits = '/baza-nc/purchase-flow/limits',
    limitsForPurchase = '/baza-nc/purchase-flow/limitsForPurchase',
}

export interface BazaNcPurchaseFlowLimitsEndpoint {
    limits(): Promise<BazaNcLimitsDto> | Observable<BazaNcLimitsDto>;
    limitsForPurchase(
        request: BazaNcPurchaseFlowLimitsForPurchaseRequest,
    ): Promise<BazaNcPurchaseFlowLimitsForPurchaseResponse> | Observable<BazaNcPurchaseFlowLimitsForPurchaseResponse>;
}

export class BazaNcPurchaseFlowLimitsForPurchaseRequest {
    @ApiModelProperty({
        description: 'Returns details about available payment methods with given amount (in cents)',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    requestedAmountCents: number;

    @ApiModelProperty({
        description: 'Unique OfferingId',
    })
    @IsString()
    @IsOptional()
    offeringId?: string;
}

export type BazaNcPurchaseFlowLimitsForPurchaseResponse = Array<PurchaseFlowLimitsDto>;
