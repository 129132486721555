import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { IsNumber, IsOptional, IsString } from 'class-validator';

export enum JobApplicationEndpointPaths {
    send = '/baza-content-types/job-applications/send',
}

export interface JobApplicationEndpoint {
    send(request: JobApplicationSendRequest): Promise<void> | Observable<void>;
}

export class JobApplicationSendRequest {
    @ApiModelProperty()
    @IsNumber()
    jobId: number;

    @ApiModelProperty()
    @IsString()
    firstName: string;

    @ApiModelProperty()
    @IsString()
    lastName: string;

    @ApiModelProperty()
    @IsString()
    email: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    phone?: string;
}
