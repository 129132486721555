import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDate, IsOptional, IsString } from 'class-validator';

export class RewardShareSessionDto {
    @ApiModelProperty({
        description: 'Reward share session id',
        required: true,
    })
    @IsString()
    public id: string;

    @ApiModelProperty({
        description: 'Reward share session name',
        required: true,
    })
    @IsString()
    public name: string;

    @ApiModelProperty({
        description: 'Reward share session description',
        required: false,
    })
    @IsOptional()
    @IsString()
    public description?: string;

    @ApiModelProperty({
        description: 'Reward share session createdAt',
        required: true,
    })
    @IsDate()
    public createdAt: Date;

    @ApiModelProperty({
        description: 'Reward share session updatedAt',
        required: true,
    })
    @IsDate()
    public updatedAt: Date;
}
