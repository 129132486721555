import { Injectable } from '@angular/core';
import {
    BazaNcLimitsDto,
    BazaNcPurchaseFlowLimitsEndpoint,
    BazaNcPurchaseFlowLimitsEndpointPaths,
    BazaNcPurchaseFlowLimitsForPurchaseRequest,
    BazaNcPurchaseFlowLimitsForPurchaseResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class BazaNcPurchaseFlowLimitsDataAccess implements BazaNcPurchaseFlowLimitsEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    limits(): Observable<BazaNcLimitsDto> {
        return this.ngEndpoint.post<BazaNcLimitsDto>(BazaNcPurchaseFlowLimitsEndpointPaths.limits);
    }

    limitsForPurchase(request: BazaNcPurchaseFlowLimitsForPurchaseRequest): Observable<BazaNcPurchaseFlowLimitsForPurchaseResponse> {
        return this.ngEndpoint.post<BazaNcPurchaseFlowLimitsForPurchaseResponse>(
            BazaNcPurchaseFlowLimitsEndpointPaths.limitsForPurchase,
            request,
        );
    }
}
