import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcDocusignDto {
    @ApiModelProperty({
        description: 'DocuSign Template Id',
    })
    id: string;

    @ApiModelProperty({
        description: 'Combined Id + Name which is accepted by Transact API',
    })
    combinedId: string;

    @ApiModelProperty({
        description: 'DocuSign Template Name (Title)',
    })
    name: string;
}
