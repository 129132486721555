import { Inject, Pipe, PipeTransform, PLATFORM_ID, Renderer2 } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { extract, OembedData } from 'oembed-parser';
import { isPlatformServer } from '@angular/common';

@Pipe({
    name: 'mediaEmbed',
})
export class MediaEmbedPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: string) {}

    async transform(unparsedHtml: string): Promise<SafeHtml> {
        if (isPlatformServer(this.platformId)) {
            return unparsedHtml;
        }

        const parsedHtml = await this.parseEmbeddedHtml(unparsedHtml);

        const wrapper = this.renderer.createElement('DIV') as HTMLDivElement;

        parsedHtml.forEach((child) => this.renderer.appendChild(wrapper, child));

        return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
    }

    /**
     * Splits HTML string into multiple child nodes and looks
     * at each of them for embedded media content that can be extracted.
     *
     * @param unparsedHtml inner HTML passed from component template
     * @returns collection of DOM elements
     */
    private async parseEmbeddedHtml(unparsedHtml: string): Promise<Element[]> {
        const parsed: Element[] = [];

        const parser = new DOMParser();

        const nodes = Array.from(parser.parseFromString(unparsedHtml, 'text/html').body.children);

        for (const node of nodes) {
            if (node.tagName === 'FIGURE' && node.classList.contains('media')) {
                const oembed = node.querySelector('oembed');

                if (!oembed) {
                    parsed.push(node);
                    continue;
                }

                const { html: extractedHtml } = (await extract(oembed.getAttribute('url'))) as OembedData & { html: string };

                if (!extractedHtml) {
                    parsed.push(node);
                    continue;
                }

                const iframe = parser.parseFromString(extractedHtml, 'text/html').querySelector('iframe');
                parsed.push(iframe);
            } else {
                parsed.push(node);
            }
        }

        return parsed;
    }
}
