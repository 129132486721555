import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import { PurchaseFlowDto, StatsDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzCardModule, NzDividerModule, NzPopoverModule],
})
export class SummaryComponent implements OnChanges {
    @Input()
    entity: BazaNcIntegrationListingsDto;

    @Input()
    purchaseStart: PurchaseFlowDto;

    @Input()
    stats: StatsDto;

    @Input()
    numberOfShares: number;

    @Input()
    withImage = true;

    transactionFeeCents?: number;
    transactionFee: number;
    maxSharesPerUserLimitReached: boolean;

    fee: number;
    // TODO: CB:15Mar2022: Do we load total from BE or is it calculated?
    total: number;

    public i18nBasePath = 'uic.summary';

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.calculateStats();
    }

    calculateStats() {
        if (this.purchaseStart) {
            this.fee = this.purchaseStart.fee;
            this.total = this.purchaseStart.total;

            this.transactionFee = this.purchaseStart.transactionFees;
            this.transactionFeeCents = this.purchaseStart.transactionFeesCents / 100;
        }

        if (this.stats) {
            const { availableTransactionTypes = [] } = this.stats || {};
            this.transactionFee = availableTransactionTypes.length && availableTransactionTypes[0].transactionFees;
        }

        if (this.stats?.canPurchase === 0 || this.numberOfShares > this.stats?.canPurchase) {
            this.numberOfShares = 0;
            this.maxSharesPerUserLimitReached = true;
        }
    }

    calculateTotal(fee: number, amount: number) {
        return fee ? (fee + amount) / 100 : amount / 100;
    }

    calculateTransactionFee(amount: number, percentage: number): number {
        return (amount * percentage) / 100;
    }
}
