import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AttachmentImageSetDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { BazaNcOfferingStatus, OfferingId } from '@scaliolabs/baza-nc-shared';

export class BazaNcIntegrationListingsListItemDto implements CrudSortableEntity {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    cover: AttachmentImageSetDto;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty({
        type: AttachmentImageSetDto,
        isArray: true,
    })
    images: Array<AttachmentImageSetDto>;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
        enum: Object.values(BazaNcOfferingStatus),
    })
    status: BazaNcOfferingStatus;

    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    offeringId: OfferingId;

    @ApiModelProperty({
        description: 'Price Per Share (as cents)',
    })
    pricePerShareCents: number;

    @ApiModelProperty({
        description: 'Number of shares available to purchase (total)',
    })
    numSharesAvailable: number;

    @ApiModelProperty({
        description: 'Total percents funded',
    })
    percentFunded: number;

    @ApiModelProperty({
        description: 'Total amount (as cents)',
    })
    totalSharesValueCents: number;

    @ApiModelProperty({
        description: 'Minimal number of shares to start purchase',
    })
    numSharesMin: number;

    @ApiModelProperty({
        description: 'slug',
        required: false,
    })
    slug?: string;
}
