import { AbstractControl, ValidatorFn } from '@angular/forms';

export function mismatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (group: AbstractControl) => {
        const control = group.get(controlName);
        const matchingControl = group.get(matchingControlName);
        matchingControl.setErrors(null);

        if (matchingControl.errors && !matchingControl.errors.mismatch) {
            return null;
        } else if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mismatch: true });
            return { mismatch: true };
        }

        return null;
    };
}
