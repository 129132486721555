import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { OfferingId } from '../models/offering-id';

export class CreateEscrowAccountRequest {
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsNotEmpty()
    overFundingAmount: string;

    @IsString()
    @IsOptional()
    bankName?: string;

    @IsString()
    @IsOptional()
    offeringAccountNumber?: string;

    @IsString()
    @IsOptional()
    accountFullName?: string;
}
