import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';

type PropertyField = string;

export enum BazaNcSearchFields {
    Title = 'title',
    DateCreatedAt = 'dateCreatedAt',
    DateUpdatedAt = 'dateUpdatedAt',
    SortOrder = 'sortOrder',
    Status = 'status',
    PricePerShareCents = 'pricePerShareCents',
    NumSharesAvailable = 'numSharesAvailable',
    PercentFunded = 'percentFunded',
    TotalSharesValueCents = 'totalSharesValueCents',
    NumSharesMin = 'numSharesMin',
    SharesToJoinEliteInvestors = 'sharesToJoinEliteInvestors',
    CurrentValueCents = 'ncCurrentValueCents',
    StartDate = 'ncStartDate',
    EndDate = 'ncEndDate',
    RemainingShares = 'ncRemainingShares',
    Schema = 'schema',
    SchemaId = 'schemaId',
}

export type BazaNcSearchField = BazaNcSearchFields | PropertyField;

export const bazaNcSearchFieldSwagger: () => SchemaObjectMetadata = () => ({
    type: 'string',
    enum: [...Object.values(BazaNcSearchFields), '(or any other field from listing properties)'],
});

export const bazaNcSearchStringFields: Array<BazaNcSearchField> = [BazaNcSearchFields.Title, BazaNcSearchFields.Schema];

export const bazaNcSearchNumberFields: Array<BazaNcSearchField> = [
    BazaNcSearchFields.SortOrder,
    BazaNcSearchFields.PricePerShareCents,
    BazaNcSearchFields.NumSharesAvailable,
    BazaNcSearchFields.PercentFunded,
    BazaNcSearchFields.TotalSharesValueCents,
    BazaNcSearchFields.NumSharesMin,
    BazaNcSearchFields.SharesToJoinEliteInvestors,
    BazaNcSearchFields.CurrentValueCents,
    BazaNcSearchFields.RemainingShares,
    BazaNcSearchFields.SchemaId,
];

export const bazaNcSearchBooleanFields: Array<BazaNcSearchField> = [];

export const bazaNcSearchDateFields: Array<BazaNcSearchField> = [
    BazaNcSearchFields.DateCreatedAt,
    BazaNcSearchFields.DateUpdatedAt,
    BazaNcSearchFields.StartDate,
    BazaNcSearchFields.EndDate,
];

export const bazaNcSearchStatusFields: Array<BazaNcSearchField> = [BazaNcSearchFields.Status];
