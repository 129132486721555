import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountTypeCheckingSaving } from '../../../transact-api';

export class PurchaseFlowSetBankAccountDetailsDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Account Holder Full Name or Issuer Account Holder Full Name',
    })
    @IsString()
    @IsNotEmpty()
    accountName: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Account Holder Nick Name (optional)',
        required: false,
    })
    @IsString()
    @IsOptional()
    accountNickName?: string;

    @ApiModelProperty({
        type: 'number',
        description: 'External Bank Account - Routing Number. Please check example for successful request',
        example: '011401533',
    })
    @IsString()
    @IsNotEmpty()
    accountRoutingNumber: string;

    @ApiModelProperty({
        type: 'number',
        description: 'External Bank Account - Account Number. Please check example for successful request',
        example: '1111222233330000',
    })
    @IsString()
    @IsNotEmpty()
    accountNumber: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountTypeCheckingSaving),
        description: 'Account type Checking / Saving. By default Checking',
    })
    @IsEnum(AccountTypeCheckingSaving)
    @IsNotEmpty()
    accountType: AccountTypeCheckingSaving;
}
