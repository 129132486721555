import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcDividendCmsDto } from '../dto/baza-nc-dividend-cms.dto';
import { BazaNcDividendCmsCsvDto } from '../dto/baza-nc-dividend-cms-csv.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { ulid } from 'ulid';

export enum BazaNcDividendCmsEndpointPaths {
    list = '/baza-nc/cms/dividend/list',
    getByUlid = '/baza-nc/cms/dividend/getByUlid',
    delete = '/baza-nc/cms/dividend/delete',
    exportToCsv = '/baza-nc/cms/dividend/exportToCsv',
}

export interface BazaNcDividendCmsEndpoint {
    list(request: BazaNcDividendCmsListRequest): Promise<BazaNcDividendCmsListResponse> | Observable<BazaNcDividendCmsListResponse>;
    getByUlid(request: BazaNcDividendCmsGetByUlidRequest): Promise<BazaNcDividendCmsDto> | Observable<BazaNcDividendCmsDto>;
    delete(request: BazaNcDividendCmsDeleteRequest): Promise<void> | Observable<void>;
    exportToCsv(request: BazaNcDividendCmsExportToCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcDividendCmsListRequest extends CrudListRequestDto<BazaNcDividendCmsDto> {
    @ApiModelProperty({
        description: 'Investor Account Id',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    investorAccountId?: number;
}

export class BazaNcDividendCmsListResponse extends CrudListResponseDto<BazaNcDividendCmsDto> {}

export class BazaNcDividendCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendCmsExportToCsvRequest extends CrudExportToCsvRequest<BazaNcDividendCmsCsvDto, BazaNcDividendCmsListRequest> {}
