import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MktPageDto } from '@scaliolabs/baza-content-types-shared';
import { BazaWebUtilSharedService, MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { InvestCardComponent } from '../invest-card';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
    selector: 'app-invest',
    templateUrl: './invest.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NzGridModule, UtilModule, InvestCardComponent],
})
export class InvestComponent extends MktSectionCommonFields {
    @Input()
    index = 0;

    @Input()
    investSections: MktPageDto[];

    constructor(public readonly wts: BazaWebUtilSharedService) {
        super();
    }

    public get i18nBasePath() {
        return `uic.marketing.invest.section${this.index}`;
    }

    public get investIcons() {
        return this.wts.getI18nLabel(this.i18nBasePath, 'icons');
    }

    public getIcon(index: number): string {
        if (this.investIcons.length > 0 && this.investIcons[`${index}`]) {
            return this.investIcons[`${index}`];
        }

        return null;
    }
}
