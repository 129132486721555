import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BazaNcBankAccountAchDto, BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, PaymentButtonStyleEnum, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PaymentItemModule } from '../payment-item/payment-item.module';

@Component({
    selector: 'app-payment-item-bank',
    templateUrl: './payment-item-bank.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, PaymentItemModule, NzButtonModule],
})
export class PaymentItemBankComponent {
    @Input()
    initData?: BazaNcBootstrapDto;

    @Input()
    bankAccountResponse?: BazaNcBankAccountAchDto;

    @Output()
    upsertBankAccount = new EventEmitter<void>();

    i18nBasePath = 'uic.paymentItemBank';
    i18nBasePathNew = 'uic.paymentItemBank.';
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
