import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BazaNcDataAccessModule } from '@scaliolabs/baza-nc-data-access';
import {
    BackLinkComponent,
    ConfirmModalComponent,
    IframeIntegrationComponent,
    KycDisapprovedAlertComponent,
    PaymentAccountBalanceComponent,
    PaymentBankAccountComponent,
    PaymentCardComponent,
    PaymentHeaderComponent,
    PersonalInfoComponent,
    RegCfConsentComponent,
    RegCfOverviewComponent,
    SummaryComponent,
} from '@scaliolabs/baza-web-ui-components';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { PurchaseAgreementComponent } from './agreement/agreement.component';
import { PurchaseStateModule } from './data-access';
import { PurchaseDetailsComponent } from './details/details.component';
import { PurchaseDoneComponent } from './done/done.component';
import { PurchaseMethodsComponent } from './methods/methods.component';
import { PurchasePaymentComponent } from './payment/payment.component';
import { PurchaseComponent } from './purchase.component';
import { PaymentEditModule, RegCFLimitComponent, WithdrawComponent } from './ui-components';

const NZ_MODULES = [
    NzAlertModule,
    NzButtonModule,
    NzGridModule,
    NzInputNumberModule,
    NzModalModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzStepsModule,
];
@NgModule({
    declarations: [
        PurchaseComponent,
        PurchaseDetailsComponent,
        PurchaseAgreementComponent,
        PurchasePaymentComponent,
        PurchaseMethodsComponent,
        PurchaseDoneComponent,
    ],
    imports: [
        ...NZ_MODULES,
        BackLinkComponent,
        BazaNcDataAccessModule,
        CommonModule,
        FormsModule,
        IframeIntegrationComponent,
        PaymentEditModule,
        PaymentHeaderComponent,
        PersonalInfoComponent,
        PurchaseStateModule,
        ReactiveFormsModule,
        RouterModule,
        UtilModule,
        WithdrawComponent,
        RegCFLimitComponent,
        RegCfOverviewComponent,
        RegCfConsentComponent,
        SummaryComponent,
        KycDisapprovedAlertComponent,
        ConfirmModalComponent,
        PaymentAccountBalanceComponent,
        PaymentBankAccountComponent,
        PaymentCardComponent,
    ],
    exports: [
        PurchaseAgreementComponent,
        PurchaseComponent,
        PurchaseDetailsComponent,
        PurchaseDoneComponent,
        PurchaseMethodsComponent,
        PurchasePaymentComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PurchaseFlowModule {}
