<form
    class="ant-form-inverse"
    nz-form
    nzLayout="vertical"
    [formGroup]="addFundsForm"
    #addFundsFormEl>
    <div class="add-funds-form">
        <nz-form-item *ngIf="addFundsError; else transferAmountWarningMessage">
            <nz-alert
                class="payment-funds__alert payment-funds__alert-error"
                data-cy="add-funds-form-error"
                [nzMessage]="transferAmountError"
                [nzType]="addFundsError?.type"></nz-alert>
        </nz-form-item>

        <ng-template #transferAmountWarningMessage>
            <nz-form-item>
                <nz-alert
                    class="payment-funds__alert payment-funds__alert-warning"
                    data-cy="add-funds-form-warning"
                    [nzMessage]="wts.getI18nLabel(i18nBasePath, 'alerts.amountWarning')"
                    nzType="warning"></nz-alert>
            </nz-form-item>
        </ng-template>

        <nz-form-item>
            <nz-form-label nzFor="transferAmount">
                {{ wts.getI18nLabel(i18nFormFieldsPath, 'amount.label') }}
            </nz-form-label>

            <nz-form-control [nzErrorTip]="transferAmountErrorTemplate">
                <nz-input-number
                    class="payment-funds__input"
                    formControlName="transferAmount"
                    id="transferAmount"
                    name="transferAmount"
                    type="number"
                    [nzPrecisionMode]="amountConfig.precisionMode"
                    [nzMin]="amountConfig.minValue"
                    [nzMax]="10000"
                    [nzStep]="amountConfig.step"
                    [nzPrecision]="amountConfig.precision"
                    [nzFormatter]="wts.dollarFormat"
                    [nzParser]="wts.dollarParse"
                    appAmountMask
                    [maxLength]="amountValidationMaxLength"></nz-input-number>
            </nz-form-control>
            <ng-template
                #transferAmountErrorTemplate
                let-control>
                <span data-cy="add-funds-form-input-error">
                    {{ getErrorMessage(control, 'amount') }}
                </span>
            </ng-template>
        </nz-form-item>
    </div>
</form>

<ng-template #transferAmountError>
    <ng-container *ngIf="addFundsError">
        <p
            [innerHtml]="addFundsError?.text | sanitizeHtml"
            appLinkify
            [linkConfigPath]="contactLinkConfig"></p>
    </ng-container>
</ng-template>
