import { AccountDto } from '../../../../baza-account/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaAuthSessionAccountDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty({
        description: 'Account',
    })
    account: AccountDto;

    @ApiModelProperty({
        description: 'Created At (ISO Date String)',
    })
    createdAt: string;

    @ApiModelProperty({
        description: 'Last Sign In (ISO Date String)',
        required: false,
    })
    lastSignIn?: string;

    @ApiModelProperty({
        description: 'Total count of sign in',
    })
    authCountAttempts: number;

    @ApiModelProperty({
        description: 'Total count of sign in',
    })
    authCountSignIn: number;
}
