import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { ExternalAccountType } from '../models/external-account-type';

export class GetExternalAccountRequest {
    @IsEnum(ExternalAccountType)
    @IsNotEmpty()
    types: ExternalAccountType;

    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;
}
