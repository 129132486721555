import { AiRequestStatus } from '../models/ai-request-status';
import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

export class UpdateAiRequestRequest {
    @IsString()
    @IsNotEmpty()
    airequestId: string;

    @IsEnum(AiRequestStatus)
    @IsNotEmpty()
    aiRequestStatus: AiRequestStatus;
}
