import { ErrorHandler, Injectable } from '@angular/core';
import { BazaSentryNgService } from '../services/baza-sentry-ng.service';

@Injectable()
export class BazaSentryNgErrorHandler implements ErrorHandler {
    constructor(private readonly service: BazaSentryNgService) {}

    handleError(error: unknown): void {
        this.service.captureException(error);

        throw error;
    }
}
