import { ApiModelProperty } from "@nestjs/swagger/dist/decorators/api-model-property.decorator";
import { AttachmentDto } from '@scaliolabs/baza-core-shared';

export class ContentBlockPhotos {
    @ApiModelProperty()
    images: Array<AttachmentDto>;

    @ApiModelProperty()
    heightInPixels?: number;

    @ApiModelProperty()
    widthInPixels?: number;
}
