import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RegCFConsentForm } from './models';
import { NzButtonModule } from 'ng-zorro-antd/button';

@UntilDestroy()
@Component({
    selector: 'app-regcf-consent',
    templateUrl: './regcf-consent.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, FormsModule, ReactiveFormsModule, NzFormModule, NzCheckboxModule, NzModalModule, NzButtonModule],
})
export class RegCfConsentComponent {
    @Output()
    consentAccepted = new EventEmitter<void>();

    @Output()
    consentCancelled = new EventEmitter<void>();

    i18nBasePath = 'uic.regCfConsent';

    consentForm: RegCFConsentForm = this.fb.group({
        accepted: [false],
    }) as RegCFConsentForm;

    constructor(private readonly fb: UntypedFormBuilder, public readonly wts: BazaWebUtilSharedService) {
        this.consentForm.reset();
    }

    public get clauses() {
        return this.wts.getI18nLabel(this.i18nBasePath, 'clauses') ?? [];
    }

    public get isSubmitDisabled() {
        return !this.consentForm.controls.accepted.value;
    }

    getClauseLinkConfigPath(index: number): string {
        return `${this.i18nBasePath}.clauses.${index}.linkConfig`;
    }

    getClauseLabel(index: number) {
        return this.wts.getI18nLabel(this.i18nBasePath, `clauses.${index}.label`) ?? ``;
    }
}
