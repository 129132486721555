import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  FaqCmsCreateRequest,
  FaqCmsCreateResponse,
  FaqCmsEndpoint,
  FaqCmsEndpointPaths,
  FaqCmsGetByIdRequest,
  FaqCmsGetByIdResponse,
  FaqCmsListRequest,
  FaqCmsListResponse,
  FaqCmsRemoveRequest,
  FaqCmsRemoveResponse,
  FaqCmsSetSortOrderRequest,
  FaqCmsSetSortOrderResponse,
  FaqCmsUpdateRequest,
  FaqCmsUpdateResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class FaqCmsDataAccess implements FaqCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: FaqCmsListRequest): Observable<FaqCmsListResponse> {
    return this.http.post(FaqCmsEndpointPaths.list, request);
  }

  create(request: FaqCmsCreateRequest): Observable<FaqCmsCreateResponse> {
    return this.http.post(FaqCmsEndpointPaths.create, request);
  }

  update(request: FaqCmsUpdateRequest): Observable<FaqCmsUpdateResponse> {
    return this.http.post(FaqCmsEndpointPaths.update, request);
  }

  remove(request: FaqCmsRemoveRequest): Observable<FaqCmsRemoveResponse> {
    return this.http.post(FaqCmsEndpointPaths.remove, request);
  }

  getById(request: FaqCmsGetByIdRequest): Observable<FaqCmsGetByIdResponse> {
    return this.http.post(FaqCmsEndpointPaths.getById, request);
  }

  setSortOrder(request: FaqCmsSetSortOrderRequest): Observable<FaqCmsSetSortOrderResponse> {
    return this.http.post(FaqCmsEndpointPaths.setSortOrder, request);
  }
}
