import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { BazaAuthDataAccessModule } from '@scaliolabs/baza-core-data-access';

import { AuthState } from './state';
import { AccountStateModule } from '../account';

@NgModule({
    imports: [AccountStateModule, BazaAuthDataAccessModule, NgxsModule.forFeature([AuthState]), NzNotificationModule],
})
export class AuthStateModule {}
