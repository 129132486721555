import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaAccountRegisterOptions {
    @ApiModelProperty({
        description: 'Default referral code for current account',
        required: false,
    })
    referralCode?: string;

    @ApiModelProperty({
        description: 'Copy & Paste Text for Referral Code',
        required: false,
    })
    referralCodeCopyText?: string;

    @ApiModelProperty({
        description: 'Is Registration w/o Referral or Invite Codes allowed?',
    })
    areNonCodeRegistrationsAllowed: boolean;

    @ApiModelProperty({
        description: 'Is Registration with Invite Codes allowed?',
    })
    areInviteCodeRegistrationsAllowed: boolean;

    @ApiModelProperty({
        description: 'Is Registration with Referral Codes allowed?',
    })
    areReferralCodeRegistrationsAllowed: boolean;
}
