// You may override default environments with generics
export enum BazaEnvironments {
    Local = 'local', // .env
    E2e = 'e2e', // e2e.env
    Test = 'test', // test.env
    Stage = 'stage', // stage.env
    Production = 'production', // production.env
    UAT = 'uat', // uat.env,
    Preprod = 'preprod', // preprod.env
    Hotfix = 'hotfix',
}

export interface BazaEnvironmentsConfig<E = BazaEnvironments> {
    testEnvironments: Array<E>;
    localEnvironments: Array<E>;
    productionEnvironments: Array<E>;
    uatEnvironments: Array<E>;
    publicProductionEnvironments: Array<E>;
    sentryAllowedEnvironments: Array<E>;
}

export function defaultBazaEnvironmentsConfig(): BazaEnvironmentsConfig {
    return {
        testEnvironments: [BazaEnvironments.E2e],
        localEnvironments: [BazaEnvironments.Local],
        productionEnvironments: [BazaEnvironments.UAT, BazaEnvironments.Production, BazaEnvironments.Preprod],
        uatEnvironments: [BazaEnvironments.UAT, BazaEnvironments.Preprod],
        publicProductionEnvironments: [BazaEnvironments.Production],
        sentryAllowedEnvironments: [
            BazaEnvironments.Test,
            BazaEnvironments.Stage,
            BazaEnvironments.UAT,
            BazaEnvironments.Production,
            BazaEnvironments.Preprod,
        ],
    };
}

export const BAZA_ENVIRONMENTS_CONFIG: BazaEnvironmentsConfig = defaultBazaEnvironmentsConfig();
