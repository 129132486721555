import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { BazaSeoDto, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { PageCmsDto } from '../dto/page-cms.dto';
import { BazaContentDto } from '../../../../../shared/src';
import { Type } from 'class-transformer';

export enum PagesCmsEndpointPaths {
    create = '/baza-content-types/cms/pages/create',
    update = '/baza-content-types/cms/pages/update',
    delete = '/baza-content-types/cms/pages/delete',
    list = '/baza-content-types/cms/pages/list',
    getById = '/baza-content-types/cms/pages/getById',
}

export interface PagesCmsEndpoint {
    create(request: PagesCmsCreateRequest): Promise<PageCmsDto> | Observable<PageCmsDto>;
    update(request: PagesCmsUpdateRequest): Promise<PageCmsDto> | Observable<PageCmsDto>;
    delete(request: PagesCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: PagesCmsListRequest): Promise<PagesCmsListResponse> | Observable<PagesCmsListResponse>;
    getById(request: PagesCmsGetByIdRequest): Promise<PageCmsDto> | Observable<PageCmsDto>;
}

export class PagesCmsEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    pageUniqueSid?: string;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    primaryCategoryId?: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    additionalCategoryIds?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    headerImageAwsS3Key?: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class PagesCmsCreateRequest extends PagesCmsEntityBody {}

export class PagesCmsUpdateRequest extends PagesCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class PagesCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class PagesCmsListRequest extends CrudListRequestDto<PageCmsDto> {}

export class PagesCmsListResponse extends CrudListResponseDto<PageCmsDto> {
    @ApiModelProperty({
        type: PageCmsDto,
        isArray: true,
    })
    items: Array<PageCmsDto>;
}

export class PagesCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
