import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DwollaCustomerStatus } from '../models/customer-status';

export class BazaDwollaCreatePersonalCustomerResponse {
    @ApiModelProperty({
        type: 'string',
        description: 'Dwolla Customer Status',
        enum: Object.values(DwollaCustomerStatus),
    })
    status: DwollaCustomerStatus;
}
