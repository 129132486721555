import { IsBoolean, IsNotEmpty } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class AccountVerificationIsCompletedDto {
    @ApiModelProperty({
        type: 'boolean',
        description: 'Is account verification process already completed?'
    })
    @IsBoolean()
    @IsNotEmpty()
    isCompleted: boolean;
}
