import { ExternalAccountType } from '../models/external-account-type';
import { NorthCapitalAccountId } from '../models/account-id';
import { IssuerId } from '../models/issuer-id';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { AccountTypeCheckingSaving } from '../models/account-type-checking-saving';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UpdateExternalAccountRequest {
    @IsString()
    @IsOptional()
    accountId?: NorthCapitalAccountId;

    @IsString()
    @IsOptional()
    issuerId?: IssuerId;

    @IsEnum(ExternalAccountType)
    @IsNotEmpty()
    types: ExternalAccountType;

    @IsString()
    @IsNotEmpty()
    ExtAccountfullname: string;

    @IsString()
    @IsNotEmpty()
    Extnickname: string;

    @IsString()
    @IsNotEmpty()
    ExtRoutingnumber: string;

    @IsString()
    @IsNotEmpty()
    ExtAccountnumber: string;

    @IsString()
    @IsOptional()
    updatedIpAddress?: NorthCapitalIpAddress;

    @IsEnum(AccountTypeCheckingSaving)
    @IsOptional()
    accountType?: AccountTypeCheckingSaving;
}
