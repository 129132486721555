import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcDividendPaymentStatus, TransactionState } from '@scaliolabs/baza-nc-shared';
import { IsArray, IsBoolean, IsIn, IsOptional } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcIntegrationPortfolioAssetDto } from '../dto/baza-nc-integration-portfolio-asset.dto';
import { BazaNcIntegrationPortfolioStatementsDto } from '../dto/baza-nc-integration-portfolio-document.dto';
import { BazaNcIntegrationPortfolioTotalStatsDto } from '../dto/baza-nc-integration-portfolio-total-stats.dto';
import { BazaNcIntegrationPortfolioTransactionInvestmentEntityDto } from '../dto/baza-nc-integration-portfolio-transaction-investment-entity.dto';
import {
    BazaNcIntegrationPortfolioTransactionDto,
    bazaNcIntegrationPortfolioTransactionDtoSwagger,
} from '../dto/baza-nc-integration-portfolio-transaction.dto';
import { BazaNcIntegrationPortfolioTransactionType } from '../models/baza-nc-integration-portfolio-transaction-type';

export enum PortfolioEndpointPaths {
    total = '/baza-nc-integration/portfolio/total',
    assets = '/baza-nc-integration/portfolio/assets',
    assetsTotal = '/baza-nc-integration/portfolio/assets-total',
    transactions = '/baza-nc-integration/portfolio/transactions',
    statements = '/baza-nc-integration/portfolio/statements',
}

export interface BazaNcIntegrationPortfolioEndpoint {
    total(...args: unknown[]): Promise<BazaNcIntegrationPortfolioTotalStatsDto> | Observable<BazaNcIntegrationPortfolioTotalStatsDto>;
    assets(
        request: BazaNcIntegrationPortfolioAssetsRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationPortfolioAssetsResponse> | Observable<BazaNcIntegrationPortfolioAssetsResponse>;
    assetsTotal(
        request: BazaNcIntegrationPortfolioAssetsRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationPortfolioAssetsResponse> | Observable<BazaNcIntegrationPortfolioAssetsResponse>;
    transactions(
        request: BazaNcIntegrationPortfolioTransactionsRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationPortfolioTransactionsResponse> | Observable<BazaNcIntegrationPortfolioTransactionsResponse>;
    statements(
        request: BazaNcIntegrationPortfolioStatementsRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationPortfolioStatementsResponse> | Observable<BazaNcIntegrationPortfolioStatementsResponse>;
}

export class BazaNcIntegrationPortfolioAssetsRequest extends CrudListRequestDto<BazaNcIntegrationPortfolioAssetDto> {
    @ApiModelProperty({
        description: 'Reverse list order',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    reverse?: boolean;
}

export class BazaNcIntegrationPortfolioAssetsResponse extends CrudListResponseDto<BazaNcIntegrationPortfolioAssetDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationPortfolioAssetDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationPortfolioAssetDto>;
}

export class BazaNcIntegrationPortfolioTransactionsRequest extends CrudListRequestDto<BazaNcIntegrationPortfolioTransactionInvestmentEntityDto> {
    @ApiModelProperty({
        description: 'Reverse list order',
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    reverse?: boolean;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationPortfolioTransactionType),
        description: 'Scopes',
        required: false,
    })
    @IsArray()
    @IsIn(Object.values(BazaNcIntegrationPortfolioTransactionType), { each: true })
    @IsOptional()
    scopes?: Array<BazaNcIntegrationPortfolioTransactionType>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values({ ...TransactionState, ...BazaNcDividendPaymentStatus }),
        description: 'Transaction States',
        required: false,
    })
    @IsArray()
    @IsIn(Object.values({ ...TransactionState, ...BazaNcDividendPaymentStatus }), { each: true })
    @IsOptional()
    states?: Array<TransactionState | BazaNcDividendPaymentStatus>;
}

export class BazaNcIntegrationPortfolioTransactionsResponse extends CrudListResponseDto<BazaNcIntegrationPortfolioTransactionDto> {
    @ApiModelProperty({
        ...bazaNcIntegrationPortfolioTransactionDtoSwagger(),
        isArray: true,
    })
    items: Array<BazaNcIntegrationPortfolioTransactionDto>;
}

export class BazaNcIntegrationPortfolioStatementsRequest extends CrudListRequestDto<BazaNcIntegrationPortfolioStatementsDto> {
    @ApiModelProperty({
        description: 'Reverse list order',
        required: false,
    })
    reverse?: boolean;
}

export class BazaNcIntegrationPortfolioStatementsResponse extends CrudListResponseDto<BazaNcIntegrationPortfolioStatementsDto> {
    @ApiModelProperty({
        type: BazaNcIntegrationPortfolioStatementsDto,
        isArray: true,
    })
    items: Array<BazaNcIntegrationPortfolioStatementsDto>;
}
