import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { NewslettersDto } from '../dto/newsletters.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { NewslettersCsvDto } from '../dto/newsletters-csv.dto';

export enum NewsletterCmsEndpointPaths {
    list = '/baza-content-types/cms/newsletters/list',
    exportToCSV = '/baza-content-types/cms/newsletters/exportToCSV',
    add = '/baza-content-types/cms/newsletters/add',
    remove = '/baza-content-types/cms/newsletters/remove',
}

export interface NewsletterCmsEndpoint {
    list(request: NewsletterCmsListRequest): Promise<NewsletterCmsListResponse> | Observable<NewsletterCmsListResponse>;
    exportToCSV(request: NewsletterCmsExportToCsvExportRequest): Promise<string> | Observable<string>;
    add(request: NewsletterCmsAddRequest): Promise<NewslettersDto> | Observable<NewslettersDto>;
    remove(request: NewsletterCmsRemoveRequest): Promise<void> | Observable<void>;
}

export class NewsletterCmsListRequest extends CrudListRequestDto<NewslettersDto> {}
export class NewsletterCmsListResponse extends CrudListResponseDto<NewslettersDto> {}

export class NewsletterCmsExportToCsvExportRequest extends CrudExportToCsvRequest<NewslettersCsvDto, NewsletterCmsListRequest> {}

export class NewsletterCmsAddRequest {
    @ApiModelProperty()
    @IsString()
    @IsOptional()
    name?: string;

    @ApiModelProperty()
    @IsEmail()
    @IsNotEmpty()
    email: string;
}

export class NewsletterCmsRemoveRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    email: string;
}
