import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { BlogCmsDto } from '../dto/blog-cms.dto';
import { Observable } from 'rxjs';
import { BazaContentDto } from '../../../../../shared/src';
import { Type } from 'class-transformer';

export enum BlogCmsEndpointPaths {
    create = '/baza-content-types/cms/blog/create',
    update = '/baza-content-types/cms/blog/update',
    delete = '/baza-content-types/cms/blog/delete',
    getById = '/baza-content-types/cms/blog/getById',
    list = '/baza-content-types/cms/blog/list',
    setSortOrder = '/baza-content-types/cms/blog/setSortOrder',
}

export interface BlogCmsEndpoint {
    create(request: BlogCmsCreateRequest): Promise<BlogCmsDto> | Observable<BlogCmsDto>;
    update(request: BlogCmsUpdateRequest): Promise<BlogCmsDto> | Observable<BlogCmsDto>;
    delete(request: BlogCmsDeleteRequest): Promise<void> | Observable<void>;
    getById(request: BlogCmsGetByIdRequest): Promise<BlogCmsDto> | Observable<BlogCmsDto>;
    list(request: BlogCmsListRequest): Promise<BlogCmsListResponse> | Observable<BlogCmsListResponse>;
    setSortOrder(request: BlogCmsSetSortOrderRequest): Promise<BlogCmsSetSortOrderResponse> | Observable<BlogCmsSetSortOrderResponse>;
}

export class BlogCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    datePublishedAt?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    url?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    primaryCategoryId?: number;

    @ApiModelProperty()
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    additionalCategoryIds?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    headerImgS3Key?: string;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    thumbnailImgS3Key?: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class BlogCmsCreateRequest extends BlogCmsEntityBody {}

export class BlogCmsUpdateRequest extends BlogCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BlogCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BlogCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BlogCmsListRequest extends CrudListRequestDto<BlogCmsDto> {}

export class BlogCmsListResponse extends CrudListResponseDto<BlogCmsDto> {}

export class BlogCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class BlogCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<BlogCmsDto> {
    @ApiModelProperty()
    entity: BlogCmsDto;

    @ApiModelProperty({
        type: BlogCmsDto,
        isArray: true,
    })
    affected: Array<BlogCmsDto>;
}
