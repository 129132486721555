import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message } from './http.interface';

export const getErrorMessage = (error: string | HttpErrorResponse | Error) => {
    let message = '';

    if (typeof error === 'string') {
        message = error;
    } else if (error instanceof HttpErrorResponse || typeof error === 'undefined') {
        message = 'Unknown Error';
    } else {
        message = error.message || 'Unknown Error';
    }

    return {
        text: message,
        type: 'error',
    } as Message;
};

export const getSuccessMessage = (message: string) => {
    return {
        text: message,
        type: 'success',
    } as Message;
};

export const getWarningMessage = (message: string) => {
    return {
        text: message,
        type: 'warning',
    } as Message;
};

export const catchErrorMessage = ($: Observable<any>) => $.pipe(catchError((error) => of(getErrorMessage(error))));
