import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaMaintenanceNgConfig } from './baza-maintenance-ng.config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { XBazaSkipMaintenanceHttpInterceptor } from './http-interceptors/x-baza-skip-maintenance.http-interceptor';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { BazaMaintenanceCmsDataAccessModule } from '@scaliolabs/baza-core-cms-data-access';

interface ForRootOptions {
    deps: Array<unknown>;
    useFactory: (...deps) => BazaMaintenanceNgConfig;
}

export { ForRootOptions as BazaMaintenanceNgModuleForRootOptions };

// @dynamic
@NgModule({
    imports: [BazaMaintenanceCmsDataAccessModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: XBazaSkipMaintenanceHttpInterceptor, multi: true }, MaintenanceGuard],
})
export class BazaMaintenanceNgModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaMaintenanceNgModule> {
        return {
            ngModule: BazaMaintenanceNgModule,
            providers: [
                {
                    provide: BazaMaintenanceNgConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
