import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { patch, updateItem } from '@ngxs/store/operators';
import {
    BazaNcIntegrationFeedPostDto,
    FeedApplauseResponse,
    FeedHasAnyUpdatesResponse,
    FeedListResponse,
    FeedMarkAllAsReadResponse,
    FeedUnapplauseResponse,
} from '@scaliolabs/baza-nc-integration-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { Applause, ClearFeed, Feed, FeedHasAnyUpdates, MarkAllAsRead, Unapplause } from './actions';
import { BazaNcIntegrationFeedDataAccess } from '@scaliolabs/baza-nc-integration-data-access';

export interface FeedStateModel {
    hasAnyUpdates: boolean;
    posts: BazaNcIntegrationFeedPostDto[];
    total: number;
    isEndOfFeed: boolean;
}

@Injectable()
@State<FeedStateModel>({
    name: 'stable',
    defaults: {
        hasAnyUpdates: false,
        posts: undefined,
        total: 0,
        isEndOfFeed: false,
    },
})
export class FeedState {
    constructor(private readonly effectsUtil: EffectsUtil, private readonly feedDataAccess: BazaNcIntegrationFeedDataAccess) {}

    @Selector()
    static posts(state: FeedStateModel): BazaNcIntegrationFeedPostDto[] {
        return state.posts;
    }

    @Selector()
    static total(state: FeedStateModel): number {
        return state.total;
    }

    @Selector()
    static isEndOfFeed(state: FeedStateModel): boolean {
        return state.isEndOfFeed;
    }

    @Selector()
    static hasAnyUpdates(state: FeedStateModel): boolean {
        return state.hasAnyUpdates;
    }

    @Action(Feed, { cancelUncompleted: true })
    getFeed(ctx: StateContext<FeedStateModel>, action: Feed): Observable<FeedListResponse> {
        return this.feedDataAccess
            .list({
                lastId: action.lastId,
            })
            .pipe(
                tap((response: FeedListResponse) => {
                    ctx.patchState({
                        posts: action.lastId ? [...ctx.getState().posts, ...response.posts] : response.posts,
                        total: response.total,
                        isEndOfFeed: response.isEndOfFeed,
                    });
                }),
                this.effectsUtil.tryCatchNone$(),
            );
    }

    @Action(ClearFeed)
    clearFeed(ctx: StateContext<FeedStateModel>): void {
        ctx.patchState({
            posts: undefined,
        });
    }

    @Action(MarkAllAsRead, { cancelUncompleted: true })
    markAllAsRead(ctx: StateContext<FeedStateModel>, action: MarkAllAsRead): Observable<FeedMarkAllAsReadResponse> {
        return this.feedDataAccess.markAllAsRead(action).pipe(
            tap(() => {
                ctx.patchState({
                    hasAnyUpdates: false,
                });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(Applause, { cancelUncompleted: true })
    applause(ctx: StateContext<FeedStateModel>, action: Applause): Observable<FeedApplauseResponse> {
        return this.feedDataAccess.applause({ id: action.post.id }).pipe(
            tap((response: FeedApplauseResponse) => {
                ctx.setState(
                    patch<FeedStateModel>({
                        posts: updateItem(
                            (post) => post.id === action.post.id,
                            patch({ isApplause: !action.post.isApplause, applause: response.applause }),
                        ),
                    }),
                );
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(Unapplause, { cancelUncompleted: true })
    unapplause(ctx: StateContext<FeedStateModel>, action: Unapplause): Observable<FeedUnapplauseResponse> {
        return this.feedDataAccess.unapplause({ id: action.post.id }).pipe(
            tap((response: FeedUnapplauseResponse) => {
                ctx.setState(
                    patch<FeedStateModel>({
                        posts: updateItem(
                            (post) => post.id === action.post.id,
                            patch({ isApplause: !action.post.isApplause, applause: response.applause }),
                        ),
                    }),
                );
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(FeedHasAnyUpdates, { cancelUncompleted: true })
    hasAnyUpdates(ctx: StateContext<FeedStateModel>): Observable<FeedHasAnyUpdatesResponse> {
        return this.feedDataAccess.hasAnyUpdates().pipe(
            tap((response: FeedHasAnyUpdatesResponse) => {
                ctx.patchState({
                    hasAnyUpdates: response.hasAnyUpdates,
                });
            }),
        );
    }
}
