import { Injectable } from '@angular/core';
import type { TranslateLoader } from '@ngx-translate/core/lib/translate.loader';
import { BAZA_COMMON_I18N_CONFIG } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaI18nNgConfig } from './baza-i18n-ng.config';
import { BazaI18nNgService } from './services/baza-i18n-ng.service';

@Injectable()
export class BazaI18nNgNxHttpLoader implements TranslateLoader {
    constructor(private readonly moduleConfig: BazaI18nNgConfig, private readonly i18nService: BazaI18nNgService) {}

    getTranslation(lang: string): Observable<any> {
        return this.i18nService.fetchTranslations((lang || BAZA_COMMON_I18N_CONFIG.defaultLanguage) as any);
    }
}
