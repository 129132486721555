import { BazaInviteCodeCmsDto } from '../dto/baza-invite-code-cms.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { BazaInviteCodeModels } from '../models/baza-invite-code.models';
import { Observable } from 'rxjs';
import { CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';

export enum BazaInviteCodeCmsEndpointPaths {
    getById = '/baza-core/cms/invite-codes/getById',
    listOfSharedInviteCodes = '/baza-core/cms/invite-codes/listOfSharedInviteCodes',
    createInviteCode = '/baza-core/cms/invite-codes/createInviteCode',
    updateInviteCode = '/baza-core/cms/invite-codes/updateInviteCode',
    deleteInviteCode = '/baza-core/cms/invite-codes/deleteInviteCode',
}

export interface BazaInviteCodeCmsEndpoint {
    getById(request: BazaInviteCodeGetByIdRequest): Promise<BazaInviteCodeCmsDto> | Observable<BazaInviteCodeCmsDto>;
    listOfSharedInviteCodes(
        request: BazaListOfSharedInviteCodesRequest,
    ): Promise<BazaListOfSharedInviteCodesResponse> | Observable<BazaListOfSharedInviteCodesResponse>;
    createInviteCode(request: BazaCreateInviteCodeRequest): Promise<BazaInviteCodeCmsDto> | Observable<BazaInviteCodeCmsDto>;
    updateInviteCode(request: BazaUpdateInviteCodeRequest): Promise<BazaInviteCodeCmsDto> | Observable<BazaInviteCodeCmsDto>;
    deleteInviteCode(request: BazaDeleteInviteCodeRequest): Promise<void> | Observable<void>;
}

export class BazaInviteCodeCmsEntityBody {
    @ApiModelProperty({
        description: 'Code (to display & share)',
    })
    @IsString()
    code: string;

    @ApiModelProperty({
        description: 'ID of user who owns the code',
        required: false,
    })
    @IsNumber()
    @IsOptional()
    ownerUserId?: number;

    @ApiModelProperty({
        description: `How many times code can be used? (${BazaInviteCodeModels.UNLIMITED_CONSUME_LIMIT} for unlimited usage)`,
        required: false,
    })
    @IsNumber()
    @IsOptional()
    consumeLimit?: number;
}

export class BazaInviteCodeGetByIdRequest {
    @ApiModelProperty({
        description: 'ID of invite code',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaListOfSharedInviteCodesRequest extends CrudListRequestDto<BazaInviteCodeCmsDto> {}

export class BazaListOfSharedInviteCodesResponse extends CrudListResponseDto<BazaInviteCodeCmsDto> {
    @ApiModelProperty({
        type: BazaInviteCodeCmsDto,
        isArray: true,
    })
    items: Array<BazaInviteCodeCmsDto>;
}

export class BazaCreateInviteCodeRequest extends BazaInviteCodeCmsEntityBody {}

export class BazaUpdateInviteCodeRequest extends BazaInviteCodeCmsEntityBody {
    @ApiModelProperty({
        description: 'ID of invite code',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaDeleteInviteCodeRequest {
    @ApiModelProperty({
        description: 'ID of invite code',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}
