import { NorthCapitalAccountId } from '../models/account-id';
import { IsNotEmpty, IsString } from 'class-validator';

export class UploadVerificationDocumentRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsNotEmpty()
    userfile: File | unknown;
}
