import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import {
    Equals,
    IsArray,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsPositive,
    IsString,
    ValidateIf,
} from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto, CrudListSortDto } from '@scaliolabs/baza-core-shared';
import { RecurringTransactionDto } from '../dto/recurring-transaction.dto';
import { BazaNcPurchaseFlowTransactionType } from '../../../purchase-flow';
import { NcTransactionDto } from '../../../transaction';
import { BazaNcRecurringTransactionStatus } from '../models/baza-nc-recurring-transaction-status';
import { Type } from 'class-transformer';

export enum BazaNcRecurringTransactionsEndpointPaths {
    create = '/baza-nc/purchase-flow/recurring-transactions/create',
    update = '/baza-nc/purchase-flow/recurring-transactions/update',
    list = '/baza-nc/purchase-flow/recurring-transactions/list',
    trades = '/baza-nc/purchase-flow/recurring-transactions/trades',
    allTrades = '/baza-nc/purchase-flow/recurring-transactions/all-trades',
    triggerHandler = '/baza-nc/purchase-flow/recurring-transactions/trigger-handler',
}

export interface BazaNcRecurringTransactionsEndpoint {
    create(
        request: BazaNcRecurringTransactionRequest,
        ...args: unknown[]
    ): Promise<RecurringTransactionDto> | Observable<RecurringTransactionDto>;

    update(
        request: BazaNcRecurringTransactionUpdateRequest,
        ...args: unknown[]
    ): Promise<RecurringTransactionDto> | Observable<RecurringTransactionDto>;

    list(
        request: ListRecurringTransactionsRequest,
        ...args: unknown[]
    ): Promise<ListRecurringTransactionsResponse> | Observable<ListRecurringTransactionsResponse>;

    trades(
        request: ListTradesForRecurringTransactionRequest,
        ...args: unknown[]
    ): Promise<ListTradesForRecurringTransactionResponse> | Observable<ListTradesForRecurringTransactionResponse>;

    allTrades(
        request: ListAllRecurringTradesRequest,
        ...args: unknown[]
    ): Promise<ListAllRecurringTradesResponse> | Observable<ListAllRecurringTradesResponse>;
}

export class ListRecurringTransactionsResponse extends CrudListResponseDto<RecurringTransactionDto> {}

export class ListRecurringTransactionsRequest {
    @ApiModelProperty({
        description: 'Page (from 1 to ...)',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    index?: number;

    @ApiModelProperty({
        description: 'Items per page. Provide 0 or negative value to get all entities',
        required: false,
    })
    @IsInt()
    @IsOptional()
    size?: number;

    @ApiModelProperty({
        description: 'Sort order',
        required: false,
    })
    @Type(() => CrudListSortDto)
    @IsOptional()
    sort?: CrudListSortDto<RecurringTransactionDto>;

    @ApiModelProperty({
        description: 'Filter records by Date From',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateFrom?: string;

    @ApiModelProperty({
        description: 'Filter records by Date To',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateTo?: string;
}

export class ListTradesForRecurringTransactionResponse extends CrudListResponseDto<NcTransactionDto> {}

export class ListTradesForRecurringTransactionRequest extends CrudListRequestDto<NcTransactionDto> {
    @ApiModelProperty({
        description: 'Recurring Transaction id which should be provided in order to get the desired result',
    })
    @IsNumber()
    recurringTransactionId: number;
}

export class ListAllRecurringTradesRequest extends CrudListRequestDto<NcTransactionDto> {}
export class ListAllRecurringTradesResponse extends CrudListResponseDto<NcTransactionDto> {}

export class BazaNcRecurringTransactionUpdateRequest {
    @ApiModelProperty({
        description: 'Recurring Transaction id',
    })
    @IsNumber()
    id: number;

    @ApiModelProperty({
        description: 'type of the transaction ACH, CreditCard . required if no tradeId was passed',
    })
    @IsEnum(BazaNcPurchaseFlowTransactionType)
    @IsOptional()
    transactionType?: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description:
            'update the status of the recurring transaction -- user can only stop (Stopped) or cancel it (Cancelled) and not start it again',
    })
    @IsEnum(BazaNcRecurringTransactionStatus)
    @IsOptional()
    status?: BazaNcRecurringTransactionStatus;
}

export class BazaNcTriggerRecurringTransactionHandlerRequest {
    @ApiModelProperty({
        description:
            'will mark recurring transaction as ready for process by updating its date --- for testing purposes only (available on non-prod envs)',
    })
    @IsNumber()
    @IsOptional()
    recurringTransactionId?: number;
}

export class BazaNcRecurringTransactionRequest {
    @ApiModelProperty({
        description: 'Nc Trade Id - will be sent to create recurring based on existing trade',
    })
    @IsString()
    @IsOptional()
    tradeId?: string;

    @ApiModelProperty({
        description: 'Number of shares for recurring transaction - will be required if tradeId is not passed',
    })
    @IsNumber()
    @IsPositive()
    @ValidateIf((o) => !o.tradeId)
    numberOfShares?: number;

    @ApiModelProperty({
        description: 'type of the transaction ACH, CreditCard . required if no tradeId was passed',
    })
    @IsEnum(BazaNcPurchaseFlowTransactionType)
    @ValidateIf((o) => !o.tradeId)
    transactionType?: BazaNcPurchaseFlowTransactionType;

    @ApiModelProperty({
        description: 'offering id , required if no tradeId was passed',
    })
    @IsString()
    @ValidateIf((o) => !o.tradeId)
    offeringId?: string;
}
