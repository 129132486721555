import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { BazaSentryNgConfig } from './baza-sentry-ng.config';
import { BazaSentryNgService } from './services/baza-sentry-ng.service';
import { BazaSentryNgErrorHandler } from './error-handlers/baza-sentry-ng.error-handler';

interface ForRootOptions {
    injects: Array<unknown>;
    useFactory: (...injects) => BazaSentryNgConfig;
}

export { ForRootOptions as BazaSentryNgModuleForRootOptions };

// @dynamic
@NgModule({
    providers: [],
})
export class BazaSentryNgModule {
    static forRoot(forRootOptions: ForRootOptions): ModuleWithProviders<BazaSentryNgModule> {
        return {
            ngModule: BazaSentryNgModule,
            providers: [
                BazaSentryNgService,
                {
                    provide: ErrorHandler,
                    useClass: BazaSentryNgErrorHandler,
                },
                {
                    provide: BazaSentryNgConfig,
                    useFactory: (...injects) => forRootOptions.useFactory(...injects),
                },
            ],
        };
    }
}
