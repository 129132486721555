export enum SignedStatus {
    Changed = 'Changed',
    Completed = 'Completed',
    Created = 'Created',
    Declined = 'Declined',
    Deleted = 'Deleted',
    Delivered = 'Delivered',
    Processing = 'Processing',
    Sent = 'Sent',
    Signed = 'Signed',
    TimedOut = 'TimedOut',
    Voided = 'Voided',
}