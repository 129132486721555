import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';

export const BAZA_NC_INTEGRATION_ALLOW_REQUIRED_FLAG_FOR: Array<BazaNcIntegrationSchemaType> = Object.values(
    BazaNcIntegrationSchemaType,
).filter(
    (next) =>
        ![BazaNcIntegrationSchemaType.KeyValue, BazaNcIntegrationSchemaType.DependantArray, BazaNcIntegrationSchemaType.TextMulti].includes(
            next,
        ),
);

export const BAZA_NC_INTEGRATION_DEFAULT_ALLOWED_SCHEMA_FIELD_TYPES_FOR_DETAILS: Array<BazaNcIntegrationSchemaType> = [
    BazaNcIntegrationSchemaType.Text,
    BazaNcIntegrationSchemaType.TextArea,
    BazaNcIntegrationSchemaType.HTML,
    BazaNcIntegrationSchemaType.Email,
    BazaNcIntegrationSchemaType.Select,
    BazaNcIntegrationSchemaType.Checkbox,
    BazaNcIntegrationSchemaType.Date,
    BazaNcIntegrationSchemaType.DateRange,
    BazaNcIntegrationSchemaType.Time,
    BazaNcIntegrationSchemaType.Number,
    BazaNcIntegrationSchemaType.Rate,
    BazaNcIntegrationSchemaType.Slider,
    BazaNcIntegrationSchemaType.Switcher,
];

interface SchemaConfig {
    allowedDefinitionTypes: Array<BazaNcIntegrationSchemaType>;
    allowedSchemaFieldTypesForDetails: Array<BazaNcIntegrationSchemaType>;
    allowedRequiredFlagForTypes: Array<BazaNcIntegrationSchemaType>;
}

const BAZA_NC_INTEGRATION_SCHEMA_CONFIG: SchemaConfig = {
    allowedDefinitionTypes: Object.values(BazaNcIntegrationSchemaType),
    allowedRequiredFlagForTypes: [...BAZA_NC_INTEGRATION_ALLOW_REQUIRED_FLAG_FOR],
    allowedSchemaFieldTypesForDetails: [...BAZA_NC_INTEGRATION_DEFAULT_ALLOWED_SCHEMA_FIELD_TYPES_FOR_DETAILS],
};

export function bazaNcIntegrationSchemaConfig(update?: Partial<SchemaConfig>): SchemaConfig {
    if (update) {
        Object.assign(BAZA_NC_INTEGRATION_SCHEMA_CONFIG, update);
    }

    return BAZA_NC_INTEGRATION_SCHEMA_CONFIG;
}
