import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { MoneyAsCents, moneyAsCentsSchemaDefinition } from '../types/money-as-cents.type';
import { PercentsIntegerType } from '../types/percents-integer.type';
import { TransactionState, NcTransactionDto } from '@scaliolabs/baza-nc-shared';
import { IsOptional } from 'class-validator';
import { BazaNcIntegrationListingsDto } from '../../../../listings/src';

export class BazaNcIntegrationPortfolioAssetDto {
    @ApiModelProperty({
        description: 'Should be displayed as primary card? (Primary cards displays full information including dividends)',
        example: true,
    })
    isPrimaryCard: boolean;

    @ApiModelProperty({
        description: 'Linked Listing',
        isArray: false,
    })
    listing: BazaNcIntegrationListingsDto;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 50000,
        description: 'Total Purchased Amount As Per Purchase Value.',
    })
    totalPurchasedAmountCents: MoneyAsCents;

    @ApiModelProperty({
        type: 'number',
        description: 'Total Purchased Shares',
    })
    totalPurchasedShares: number;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10000,
        description: 'Purchase Price (Total)',
    })
    purchasePriceCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10800,
        description: 'Current Value (from CMS)',
    })
    currentValueCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10800,
        description: 'Price Per Share (per unit)',
    })
    currentPricePerShareCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10800,
        description: 'Price Per Share (average between all units)',
    })
    avgPricePerShareCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10800,
        description: 'Total purchased amount (total number of shares * current price per share)',
    })
    totalCurrentValueCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 10800,
        description: 'Return Value in Cents',
    })
    totalReturnValueCents: MoneyAsCents;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 4,
        description: 'Return in Percentage',
    })
    totalReturnPercent: PercentsIntegerType;

    @ApiModelProperty({
        ...moneyAsCentsSchemaDefinition(),
        example: 0,
        description: 'Dividends',
    })
    dividendsCents: MoneyAsCents;

    @ApiModelProperty({
        type: 'string',
        description: 'State value for the most recent transaction linked to provided asset.',
        example: TransactionState.PendingPayment,
        enum: Object.values(TransactionState),
        isArray: false,
    })
    @IsOptional()
    state: TransactionState;

    @ApiModelProperty({
        description: 'will return a transaction dto which is for the last failed transaction for the user on the specified offering',
        required: false,
        nullable: true,
    })
    lastFailedTransaction?: NcTransactionDto;
}
