import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { CheckboxSchemaDefinition } from '../schema-definitions/checkbox.schema-definition';
import { DateSchemaDefinition } from '../schema-definitions/date.schema-definition';
import { DateRangeSchemaDefinition } from '../schema-definitions/date-range.schema-definition';
import { EmailSchemaDefinition } from '../schema-definitions/email.schema-definition';
import { FileSchemaDefinition } from '../schema-definitions/file.schema-definition';
import { FileMultiSchemaDefinition } from '../schema-definitions/file-multi.schema-definition';
import { HtmlSchemaDefinition } from '../schema-definitions/html.schema-definition';
import { ImageSchemaDefinition } from '../schema-definitions/image.schema-definition';
import { ImageMultiSchemaDefinition } from '../schema-definitions/image-multi.schema-definition';
import { NumberSchemaDefinition } from '../schema-definitions/number.schema-definition';
import { RateSchemaDefinition } from '../schema-definitions/rate.schema-definition';
import { SelectSchemaDefinition } from '../schema-definitions/select.schema-definition';
import { SliderSchemaDefinition } from '../schema-definitions/slider.schema-definition';
import { SwitcherSchemaDefinition } from '../schema-definitions/switcher.schema-definition';
import { TextSchemaDefinition } from '../schema-definitions/text.schema-definition';
import { TextAreaSchemaDefinition } from '../schema-definitions/text-area.schema-definition';
import { TimeSchemaDefinition } from '../schema-definitions/time.schema-definition';
import { KeyValueSchemaDefinition } from '../schema-definitions/key-value.schema-definition';
import { DependentArraySchemaDefinition } from '../schema-definitions/dependent-array.schema-definition';
import { TextMultiSchemaDefinition } from '../schema-definitions/text-multi.schema-definition';

export type BazaNcIntegrationSchemaDefinition =
    | CheckboxSchemaDefinition
    | DateSchemaDefinition
    | DateRangeSchemaDefinition
    | EmailSchemaDefinition
    | FileSchemaDefinition
    | FileMultiSchemaDefinition
    | HtmlSchemaDefinition
    | ImageSchemaDefinition
    | ImageMultiSchemaDefinition
    | NumberSchemaDefinition
    | RateSchemaDefinition
    | SelectSchemaDefinition
    | SliderSchemaDefinition
    | SwitcherSchemaDefinition
    | TextSchemaDefinition
    | TextAreaSchemaDefinition
    | TimeSchemaDefinition
    | KeyValueSchemaDefinition
    | DependentArraySchemaDefinition
    | TextMultiSchemaDefinition;

export const bazaNcIntegrationSchemaDefinitionSwaggerDefinitions = [
    CheckboxSchemaDefinition,
    DateSchemaDefinition,
    DateRangeSchemaDefinition,
    EmailSchemaDefinition,
    FileSchemaDefinition,
    FileMultiSchemaDefinition,
    HtmlSchemaDefinition,
    ImageSchemaDefinition,
    ImageMultiSchemaDefinition,
    NumberSchemaDefinition,
    RateSchemaDefinition,
    SelectSchemaDefinition,
    SliderSchemaDefinition,
    SwitcherSchemaDefinition,
    TextSchemaDefinition,
    TextAreaSchemaDefinition,
    TimeSchemaDefinition,
    KeyValueSchemaDefinition,
    DependentArraySchemaDefinition,
    TextMultiSchemaDefinition,
];

export const bazaNcIntegrationSchemaDefinitionSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaNcIntegrationSchemaDefinitionSwaggerDefinitions.map((model) => ({
        $ref: getSchemaPath(model),
    })),
});
