import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsBoolean, IsEmail, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import {
    BazaSeoDto,
    CrudListRequestDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { JobCmsDto } from '../dto/job-cms.dto';
import { BazaContentDto } from '../../../../../shared/src';
import { Type } from 'class-transformer';

export enum JobCmsEndpointPaths {
    create = '/baza-content-types/cms/jobs/create',
    update = '/baza-content-types/cms/jobs/update',
    delete = '/baza-content-types/cms/jobs/delete',
    list = '/baza-content-types/cms/jobs/list',
    getById = '/baza-content-types/cms/jobs/getById',
    setSortOrder = '/baza-content-types/cms/jobs/setSortOrder',
}

export interface JobCmsEndpoint {
    create(request: JobsCmsCreateRequest): Promise<JobCmsDto> | Observable<JobCmsDto>;
    update(request: JobsCmsUpdateRequest): Promise<JobCmsDto> | Observable<JobCmsDto>;
    delete(request: JobsCmsDeleteRequest): Promise<void> | Observable<void>;
    list(request: JobsCmsListRequest): Promise<JobsCmsListResponse> | Observable<JobsCmsListResponse>;
    getById(request: JobsCmsGetByIdRequest): Promise<JobCmsDto> | Observable<JobCmsDto>;
    setSortOrder(request: JobsCmsSetSortOrderRequest): Promise<JobsCmsSetSortOrderResponse> | Observable<JobsCmsSetSortOrderResponse>;
}

export class JobCmsEntityBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPublished: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    datePublishedAt: string;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsOptional()
    primaryCategoryId?: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    additionalCategoryIds?: Array<number>;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    team?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    location?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    link?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsEmail()
    @IsString()
    @IsOptional()
    jobSpecificEmail?: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    shortContents: string;

    @ApiModelProperty()
    @ValidateNested()
    @Type(() => BazaContentDto)
    @IsNotEmpty()
    contents: BazaContentDto;

    @ApiModelProperty()
    @Type(() => BazaSeoDto)
    @IsNotEmpty()
    seo: BazaSeoDto;
}

export class JobsCmsCreateRequest extends JobCmsEntityBody {}

export class JobsCmsUpdateRequest extends JobCmsEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class JobsCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class JobsCmsListRequest extends CrudListRequestDto<JobCmsDto> {}

export class JobsCmsListResponse extends CrudListResponseDto<JobCmsDto> {
    @ApiModelProperty({
        type: JobCmsDto,
        isArray: true,
    })
    items: Array<JobCmsDto>;
}

export class JobsCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class JobsCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class JobsCmsSetSortOrderResponse extends CrudSetSortOrderResponseDto<JobCmsDto> {
    @ApiModelProperty()
    entity: JobCmsDto;

    @ApiModelProperty({
        type: JobCmsDto,
        isArray: true,
    })
    affected: Array<JobCmsDto>;
}
