import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountTypeCheckingSaving } from '../../../transact-api';

export class BazaNcBankAccountAchDetailsDto {
    @ApiModelProperty({ type: 'string' })
    @IsString()
    @IsNotEmpty()
    accountName: string;

    @ApiModelProperty({ type: 'string' })
    @IsString()
    @IsOptional()
    accountNickName: string;

    @ApiModelProperty({ type: 'string' })
    @IsString()
    @IsNotEmpty()
    accountRoutingNumber: string;

    @ApiModelProperty({ type: 'string' })
    @IsString()
    @IsNotEmpty()
    accountNumber: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountTypeCheckingSaving),
    })
    @IsEnum(AccountTypeCheckingSaving)
    @IsNotEmpty()
    accountType: AccountTypeCheckingSaving;
}

export class BazaNcBankAccountAchDto {
    @ApiModelProperty({
        description: 'Indicates that Plaid bank account is available',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        type: () => BazaNcBankAccountAchDetailsDto,
    })
    details?: BazaNcBankAccountAchDetailsDto;
}
