import { TransactionType } from './transaction-type';

export enum BazaPurchaseFlowTransactionType {
    /**
     * Using NC ACH Flow
     */
    ACH = 'ACH',

    /**
     * Using NC Credit Card Flow
     */
    CreditCard = 'CreditCard',

    /**
     * Using Dwolla Account Balance
     */
    AccountBalance = 'TBD',
}

export const purchaseFlowTransactionTypeToNcTransactionTypeMap: Array<{
    purchaseFlowTransactionType: BazaPurchaseFlowTransactionType;
    ncTransactionType: TransactionType;
}> = [
    {
        purchaseFlowTransactionType: BazaPurchaseFlowTransactionType.ACH,
        ncTransactionType: TransactionType.ACH,
    },
    {
        purchaseFlowTransactionType: BazaPurchaseFlowTransactionType.CreditCard,
        ncTransactionType: TransactionType.CreditCard,
    },
    {
        purchaseFlowTransactionType: BazaPurchaseFlowTransactionType.AccountBalance,
        ncTransactionType: TransactionType.TBD,
    },
];
