import { IsEnum, IsInt, IsOptional, IsString } from 'class-validator';
import { Domicile } from '../models/domicile';
import { EntityType } from '../models/entity-type';
import { NorthCapitalDate, NorthCapitalYesNoBoolean } from '../common-types';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class UpdateEntityRequest {
    @IsEnum(Domicile)
    @IsOptional()
    domicile?: Domicile;

    @IsString()
    @IsOptional()
    entityName?: string;

    @IsEnum(EntityType)
    @IsOptional()
    entityType?: EntityType;

    @IsString()
    @IsOptional()
    entityDesc?: string;

    @IsString()
    @IsOptional()
    ein?: string;

    @IsString()
    @IsOptional()
    primCountry?: string;

    @IsString()
    @IsOptional()
    primAddress1?: string;

    @IsString()
    @IsOptional()
    primAddress2?: string;

    @IsString()
    @IsOptional()
    primCity?: string;

    @IsString()
    @IsOptional()
    primState?: string;

    @IsString()
    @IsOptional()
    primZip?: string;

    @IsString()
    @IsOptional()
    emailAddress?: string;

    @IsString()
    @IsOptional()
    emailAddress2?: string;

    @IsInt()
    @IsOptional()
    phone?: number;

    @IsInt()
    @IsOptional()
    phone2?: number;

    @IsInt()
    @IsOptional()
    totalAssets?: number;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    ownersAI?: NorthCapitalYesNoBoolean;

    @IsEnum(KYCStatus)
    @IsOptional()
    KYCstatus?: KYCStatus;

    @IsEnum(AmlStatus)
    @IsOptional()
    AMLstatus?: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    tags?: string;

    @IsString()
    @IsOptional()
    createdIpAddress?: NorthCapitalIpAddress;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsString()
    @IsOptional()
    formationDate?: NorthCapitalDate;
}
