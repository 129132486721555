import { BazaAuthNgConfig, BazaAuthNgModuleForRootOptions, BazaJwtHttpInterceptorConfig, BazaJwtRequireAclGuardConfig, BazaJwtRequiredAuthGuardConfig, BazaJwtRequiredNoAuthGuardConfig, BazaJwtRequireRoleGuardConfig, BazaJwtVerifyGuardConfig } from '@scaliolabs/baza-core-ng';
import { BazaWebBundleConfig } from '../baza-web-bundle.config';

export const BAZA_WEB_BUNDLE_GUARD_CONFIGS: {
    verifyJwtGuardConfig: BazaJwtVerifyGuardConfig;
    jwtHttpInterceptorConfig: BazaJwtHttpInterceptorConfig;
    requireRoleGuardConfig: BazaJwtRequireRoleGuardConfig;
    requireAclGuardConfig: BazaJwtRequireAclGuardConfig;
    requireAuthGuardConfig: BazaJwtRequiredAuthGuardConfig;
    requireNoAuthGuardConfig: BazaJwtRequiredNoAuthGuardConfig;
} = {
    verifyJwtGuardConfig: {
        verifySameJwtThrottle: 10 /* seconds */ * 1000,
    },
    jwtHttpInterceptorConfig: {
        redirect: () => ({
            commands: ['/auth'],
        }),
    },
    requireRoleGuardConfig: {
        redirect: () => ({
            commands: ['/auth'],
        }),
    },
    requireAclGuardConfig: {
        redirect: () => ({
            commands: ['/auth'],
        }),
    },
    requireAuthGuardConfig: {
        redirect: () => ({
            commands: ['/auth'],
        }),
    },
    requireNoAuthGuardConfig: {
        redirect: () => ({
            commands: ['/'],
        }),
    },
};

export const DEFAULT_BAZA_NG_AUTH_MODULE_CONFIG: BazaAuthNgConfig = {
    autoRefreshToken: true,
    jwtStorages: {
        sessionStorage: {
            enabled: true,
            jwtKey: 'jwt',
            jwtPayloadKey: 'jwtPayload',
        },
        localStorage: {
            enabled: false,
        },
        documentCookieStorage: {
            enabled: false,
        },
    },
    verifyJwtGuardConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.verifyJwtGuardConfig,
    jwtHttpInterceptorConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.jwtHttpInterceptorConfig,
    requireRoleGuardConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireRoleGuardConfig,
    requireAclGuardConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireAclGuardConfig,
    requireAuthGuardConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireAuthGuardConfig,
    requireNoAuthGuardConfig: BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireNoAuthGuardConfig,
}


export const BAZA_NG_AUTH_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaAuthNgModuleForRootOptions = () => ({
    useFactory: () => DEFAULT_BAZA_NG_AUTH_MODULE_CONFIG,
});
