export enum BazaNorthCapitalAccountVerificationErrorCodes {
    NcAccountVerificationDocumentCannotBeDeleted = 'NcAccountVerificationDocumentCannotBeDeleted',
    NcAccountVerificationDocumentWithGivenSIDNotFound = 'NcAccountVerificationDocumentWithGivenSIDNotFound',
    NcAccountVerificationInvalidDocumentExtension = 'NcAccountVerificationInvalidDocumentExtension',
    NcAccountVerificationLiquidSummaryIsNot100PercentsTotal = 'NcAccountVerificationLiquidSummaryIsNot100PercentsTotal',
    NcAccountVerificationNoPartyIdAvailable = 'NcAccountVerificationNoPartyIdAvailable',
    NcAccountVerificationNoSsnDocumentWasUploadedBefore = 'NcAccountVerificationNoSsnDocumentWasUploadedBefore',
    NcAccountVerificationInvestorDOBIsInvalid = 'NcAccountVerificationInvestorDOBIsInvalid',
    NcAccountVerificationInvestorDOBShouldBeAbove18 = 'NcAccountVerificationInvestorDOBShouldBeAbove18',
    NcAccountVerificationInvestorDOBYearShouldBeAfter1900 = 'NcAccountVerificationInvestorDOBYearShouldBeAfter1900',
    NcAccountVerificationResidentialCityIsInvalid = 'NcAccountVerificationCityIsInvalid',
    NcAccountVerificationZipCodeDoesNotHave5NumericDigits = 'NcAccountVerificationZipCodeDoesNotHave5NumericDigits',
    NcInvalidSSNException = 'NcInvalidSSNException',
}

// NOTE: override following error message with `bazaAppExceptionOverrideMessage` helper

export const bazaNcAccountVerificationErrorCodesI18n = {
    [BazaNorthCapitalAccountVerificationErrorCodes.NcAccountVerificationResidentialCityIsInvalid]:
        'Please enter a valid residential city name.',
};
