import { Injectable } from '@angular/core';
import {
    BazaNcPurchaseFlowCreditCardDto,
    BazaNcPurchaseFlowCreditCardEndpoint,
    BazaNcPurchaseFlowCreditCardEndpointPaths,
    BazaNcPurchaseFlowDeleteCreditCardResponse,
    BazaNcPurchaseFlowGetCreditCardResponse,
    BazaNcPurchaseFlowSetCreditCardRequest,
    BazaNcPurchaseFlowValidateCreditCardRequest,
    BazaNcPurchaseFlowValidateCreditCardResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

/**
 * Baza NC Purchase Flow Credit Card API Data Access Service
 * @deprecated Use new Baza NC Credit Card API instead of Purchase Flow implementation
 */
@Injectable()
export class BazaNcPurchaseFlowCreditCardDataAccess implements BazaNcPurchaseFlowCreditCardEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    setCreditCard(request: BazaNcPurchaseFlowSetCreditCardRequest): Observable<BazaNcPurchaseFlowCreditCardDto> {
        return this.ngEndpoint.post<BazaNcPurchaseFlowCreditCardDto>(BazaNcPurchaseFlowCreditCardEndpointPaths.setCreditCard, request);
    }

    getCreditCard(): Observable<BazaNcPurchaseFlowGetCreditCardResponse> {
        return this.ngEndpoint.post<BazaNcPurchaseFlowGetCreditCardResponse>(BazaNcPurchaseFlowCreditCardEndpointPaths.getCreditCard);
    }

    deleteCreditCard(): Observable<BazaNcPurchaseFlowDeleteCreditCardResponse> {
        return this.ngEndpoint.post<BazaNcPurchaseFlowDeleteCreditCardResponse>(BazaNcPurchaseFlowCreditCardEndpointPaths.deleteCreditCard);
    }

    validateCreditCard(request: BazaNcPurchaseFlowValidateCreditCardRequest): Observable<BazaNcPurchaseFlowValidateCreditCardResponse> {
        return this.ngEndpoint.post<BazaNcPurchaseFlowValidateCreditCardResponse>(
            BazaNcPurchaseFlowCreditCardEndpointPaths.validateCreditCard,
            request,
        );
    }
}
