import { Injectable } from '@angular/core';
import {
    BazaNcTransactionEndpoint,
    BazaNcTransactionEndpointPaths,
    BazaNcTransactionGetByIdRequest,
    BazaNcTransactionGetByIdResponse,
    BazaNcTransactionInvestmentsListRequest,
    BazaNcTransactionInvestmentsResponse,
    BazaNcTransactionListRequest,
    BazaNcTransactionListResponse,
} from '@scaliolabs/baza-nc-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcTransactionsDataAccess implements BazaNcTransactionEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    list(request: BazaNcTransactionListRequest): Observable<BazaNcTransactionListResponse> {
        return this.ngEndpoint.post(BazaNcTransactionEndpointPaths.list, request);
    }

    getById(request: BazaNcTransactionGetByIdRequest): Observable<BazaNcTransactionGetByIdResponse> {
        return this.ngEndpoint.post(BazaNcTransactionEndpointPaths.getById, request);
    }

    investments(request: BazaNcTransactionInvestmentsListRequest): Observable<BazaNcTransactionInvestmentsResponse> {
        return this.ngEndpoint.post(BazaNcTransactionEndpointPaths.investments, request);
    }
}
