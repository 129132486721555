import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudListSortOrder } from '@scaliolabs/baza-core-shared';
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { BazaNcSearchField, bazaNcSearchFieldSwagger } from '../models/baza-nc-search-field';
import { BazaNcSearchOperators, bazaNcSearchOperatorsSwagger } from '../models/baza-nc-search-operators';

export class BazaNcSearchRequestSort {
    @ApiModelProperty({
        type: 'string',
        description: 'Field to sort',
        ...bazaNcSearchFieldSwagger(),
    })
    @IsString()
    @IsNotEmpty()
    field: BazaNcSearchField;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(CrudListSortOrder),
        description: 'Sort order',
    })
    @IsEnum(CrudListSortOrder)
    @IsNotEmpty()
    order: CrudListSortOrder;
}

export class BazaNcSearchRequestBaseDto {
    @ApiModelProperty({
        description: 'Filters',
        ...bazaNcSearchOperatorsSwagger(),
        isArray: true,
    })
    @IsArray()
    @IsOptional()
    // TODO : fix the type of filter.
    filter?: Array<BazaNcSearchOperators>;
}

export class BazaNcSearchRequestDto extends BazaNcSearchRequestBaseDto {
    @ApiModelProperty({
        description: 'Page (from 1 to ...)',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    index?: number;

    @ApiModelProperty({
        description: 'Items per page. Provide 0 or negative value to get all entities',
        required: false,
    })
    @IsInt()
    @IsOptional()
    size?: number;

    @ApiModelProperty({
        type: BazaNcSearchRequestSort,
        isArray: true,
        description: 'Sort Order Commands',
    })
    @ValidateNested({ each: true })
    @Type(() => BazaNcSearchRequestSort)
    @IsArray()
    @IsOptional()
    sort?: Array<BazaNcSearchRequestSort>;
}
