export enum ProjectLanguage {
    EN = 'en',
}

export interface BazaCommonI18nConfig {
    defaultLanguage: ProjectLanguage;
    defaultMomentLanguage: string;
    languages: Array<ProjectLanguage>;
}

export function defaultBazaCommonI18nConfig(): BazaCommonI18nConfig {
    return {
        defaultLanguage: ProjectLanguage.EN,
        defaultMomentLanguage: 'en',
        languages: [
            ProjectLanguage.EN,
        ],
    };
}

export const BAZA_COMMON_I18N_CONFIG: BazaCommonI18nConfig = defaultBazaCommonI18nConfig();
