import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { NorthCapitalAccountId, OfferingId, OrderStatus, TradeId } from '../../../transact-api';
import { TransactionState } from '../models/transaction-state';
import { BazaNcOperationType } from '../../../operation/lib/models/baza-nc-operation-type';

export class NcTransactionDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty({
        description: 'Created at',
        type: 'string',
    })
    createdAt: string;

    @ApiModelProperty({
        description: 'North Capital Account Id',
        type: 'string',
    })
    ncAccountId: NorthCapitalAccountId;

    @ApiModelProperty({
        description: 'Account name',
    })
    accountId: number;

    @ApiModelProperty({
        description: 'Account name',
    })
    investorAccountId: number;

    @ApiModelProperty({
        description: 'Account name',
    })
    accountFullName: string;

    @ApiModelProperty({
        description: 'North Capital Offering Id',
        type: 'string',
    })
    ncOfferingId: OfferingId;

    @ApiModelProperty({
        description: 'North Capital Offering Name',
        required: false,
    })
    ncOfferingName?: string;

    @ApiModelProperty({
        description: 'North Capital TradeId',
        type: 'string',
    })
    ncTradeId: TradeId;

    @ApiModelProperty({
        description: 'North Capital order state',
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    ncOrderStatus: OrderStatus;

    @ApiModelProperty({
        description: 'Total amount (in U.S. cents)',
    })
    amountCents: number;

    @ApiModelProperty({
        description: 'Fee (in U.S. cents)',
    })
    feeCents: number;

    @ApiModelProperty({
        description: 'Total (in U.S. cents)',
    })
    totalCents: number;

    @ApiModelProperty({
        description: 'Number of shared purchases',
    })
    shares: number;

    @ApiModelProperty({
        description: 'Price per shared (in U.S. cents)',
    })
    pricePerShareCents: number;

    @ApiModelProperty({
        description: 'Transaction fee (in U.S. cents)',
    })
    transactionFeesCents: number;

    @ApiModelProperty({
        description: 'Transaction state',
        type: 'string',
        enum: Object.values(TransactionState),
    })
    state: TransactionState;

    @ApiModelProperty({
        description: 'Transaction operation type',
        type: 'string',
        enum: Object.values(BazaNcOperationType),
    })
    operationType: BazaNcOperationType;

    @ApiModelProperty({
        description: 'Purchase name',
    })
    name: string;
}
