import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DwollaAmount } from '../../../../dwolla-api/src';

export class BazaDwollaCustomerBalanceDto {
    @ApiModelProperty({
        description: 'To identify whether dwolla customer is created for an account',
    })
    isDwollaAvailable: boolean;

    @ApiModelProperty({
        description: 'Dwolla wallet balance (if available)',
        required: false,
    })
    balance?: DwollaAmount;
}
