import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { RegistryNode, RegistryPublicNode, RegistrySchema } from '../models/registry.model';

export enum BazaRegistryEndpointPaths {
    publicSchema = '/baza-core/baza-registry/publicSchema',
    currentSchema = '/baza-core/baza-registry/currentSchema',
    updateSchemaRecord = '/baza-core/baza-registry/updateSchemaRecord',
    getSchemaRecord = '/baza-core/baza-registry/getSchemaRecord',
}

export interface BazaRegistryEndpoint {
    publicSchema(): Promise<BazaRegistryPublicSchema> | Observable<BazaRegistryPublicSchema>;
    currentSchema(): Promise<BazaRegistryCurrentSchemaResponse> | Observable<BazaRegistryCurrentSchemaResponse>;
    getSchemaRecord(
        request: BazaRegistryGetSchemaRecordRequest,
    ): Promise<BazaRegistryGetSchemaRecordResponse> | Observable<BazaRegistryGetSchemaRecordResponse>;
    updateSchemaRecord(
        request: BazaRegistryUpdateSchemaRecordRequest,
    ): Promise<BazaRegistryUpdateSchemaRecordResponse> | Observable<BazaRegistryUpdateSchemaRecordResponse>;
}

export type BazaRegistryPublicSchema = Array<RegistryPublicNode>;

export type BazaRegistryCurrentSchemaResponse = RegistrySchema;

export class BazaRegistryGetSchemaRecordRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    path: string;
}

export type BazaRegistryGetSchemaRecordResponse = RegistryNode;

export class BazaRegistryUpdateSchemaRecordRequest {
    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    path: string;

    @ApiModelProperty()
    @IsOptional()
    @IsString()
    label?: string;

    @ApiModelProperty()
    @IsNotEmpty()
    value: unknown;
}

export type BazaRegistryUpdateSchemaRecordResponse = RegistryNode;
