export const PurchaseAgreementEnI18n = {
    title: 'Sign Agreement',
    messages: {
        disclaimer:
            'We need to collect your signature for our purchase agreement to proceed with your purchase. You will be able to review your purchase details before submitting.',
        pdfConsent: 'Please, read and sign PDF Doc',
        success: 'You have successfully signed the Subscription Agreement!',
    },
    actions: {
        sign: 'Sign Agreement',
        back: 'Back',
        next: 'Next',
    },
};
