import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BazaFormValidatorService } from '@scaliolabs/baza-core-ng';
import { BazaWebUtilSharedService, CARD_BRAND_ICONS } from '@scaliolabs/baza-web-utils';
import { NzModalComponent } from 'ng-zorro-antd/modal';
import { LoadNCStripeLink, PurchaseState, PFSharedService, PaymentMethodType } from '../../../data-access';

@UntilDestroy()
@Component({
    selector: 'app-payment-edit-add',
    templateUrl: './add.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentEditAddComponent implements OnInit {
    @Input()
    paymentMethodType: PaymentMethodType;

    @Input()
    invalidFormScrollContainerRef: NzModalComponent | HTMLElement = null;

    @Output()
    modalTitleChange: EventEmitter<string> = new EventEmitter();

    @Output()
    submitForm: EventEmitter<PaymentMethodType> = new EventEmitter();

    stripeCCLink$ = this.store.select(PurchaseState.stripeLink);
    cardBrandIcons = CARD_BRAND_ICONS;
    public i18nBasePath = 'dwpf.pymtEdit.add';

    constructor(
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly wts: BazaWebUtilSharedService,
        private readonly dss: PFSharedService,
        private readonly store: Store,
    ) {
        this.store.dispatch(new LoadNCStripeLink());
    }

    ngOnInit() {
        const modalTitle = this.wts.getI18nLabel(this.i18nBasePath, 'card.title');
        this.modalTitleChange.emit(modalTitle);
    }

    onIFrameEvent(iframeEvent: string) {
        this.dss
            .processCCIFrameEvent(iframeEvent)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.submitForm.emit(PaymentMethodType.creditCard);
            });
    }
}
