import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GENERIC_RETRY_STRATEGIES_INJECT } from '../util/generic-retry-strategy.util';

@Injectable()
export class BazaNgApiOnlineStatusService {
    private _isUp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() {
        GENERIC_RETRY_STRATEGIES_INJECT.apiOnlineStatusService = this;
    }

    get isUp$(): Observable<boolean> {
        return this._isUp$;
    }

    markApiAsOnline(): void {
        this._isUp$.next(true);
    }

    markApiAsOffline(): void {
        this._isUp$.next(false);
    }
}
