import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto } from '@scaliolabs/baza-core-shared';
import { BazaNcIntegrationPerkDto } from '../dto/baza-nc-integration-perk.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString } from 'class-validator';

export enum BazaNcIntegrationPerkCmsEndpointPaths {
    create = '/baza-nc-integration/cms/perks/create',
    update = '/baza-nc-integration/cms/perks/update',
    delete = '/baza-nc-integration/cms/perks/delete',
    getById = '/baza-nc-integration/cms/perks/getById',
    list = '/baza-nc-integration/cms/perks/list',
    setSortOrder = '/baza-nc-integration/cms/perks/setSortOrder',
}

export interface BazaNcIntegrationPerkCmsEndpoint {
    create(request: BazaNcIntegrationPerkCmsCreateRequest): Promise<BazaNcIntegrationPerkDto> | Observable<BazaNcIntegrationPerkDto>;
    update(request: BazaNcIntegrationPerkCmsUpdateRequest): Promise<BazaNcIntegrationPerkDto> | Observable<BazaNcIntegrationPerkDto>;
    delete(request: BazaNcIntegrationPerkCmsDeleteRequest): Promise<void> | Observable<void>;
    getById(request: BazaNcIntegrationPerkCmsGetByIdRequest): Promise<BazaNcIntegrationPerkDto> | Observable<BazaNcIntegrationPerkDto>;
    list(request: BazaNcIntegrationPerkCmsListRequest): Promise<BazaNcIntegrationPerkCmsListResponse> | Observable<BazaNcIntegrationPerkCmsListResponse>;
    setSortOrder(request: BazaNcIntegrationPerkCmsSetSortOrderRequest): Promise<void> | Observable<void>;
}

export class BazaNcIntegrationPerkEntityBody {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty({
        description: 'Could be used for displaying icons in applications or something like tag',
        required: false,
    })
    @IsString()
    @IsOptional()
    type?: string;

    @ApiModelProperty({
        description: 'Is perk available for Elite Investors Members?',
    })
    @IsBoolean()
    @IsNotEmpty()
    isForEliteInvestors: boolean;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    @IsObject()
    @IsNotEmpty()
    metadata: Record<string, string>;
}

export class BazaNcIntegrationPerkCmsCreateRequest extends BazaNcIntegrationPerkEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationPerkCmsUpdateRequest extends BazaNcIntegrationPerkEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationPerkCmsDeleteRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationPerkCmsGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class BazaNcIntegrationPerkCmsListRequest extends CrudListRequestDto<BazaNcIntegrationPerkDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    listingId: number;
}

export class BazaNcIntegrationPerkCmsListResponse extends CrudListResponseDto<BazaNcIntegrationPerkDto> {}

export class BazaNcIntegrationPerkCmsSetSortOrderRequest extends CrudSetSortOrderRequestDto {}
