import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BazaPhoneCountryCodesDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { BackLinkComponent, ConfirmModalComponent, PhoneCodeComponent } from '@scaliolabs/baza-web-ui-components';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { IMaskModule } from 'angular-imask';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { VerificationCheckComponent } from './check/check.component';
import { VerificationStateModule } from './data-access';
import { VerificationFileUploadComponent } from './file-upload/file-upload.component';
import { VerificationInfoComponent } from './stepper-info/stepper-info.component';
import { VerificationInvestorComponent } from './stepper-investor/stepper-investor.component';
import { VerificationStepperComponent } from './stepper/stepper.component';
import { VerificationComponent } from './verification.component';

const NZ_MODULES = [
    NzAlertModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDividerModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzInputNumberModule,
    NzModalModule,
    NzNotificationModule,
    NzPopoverModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzStepsModule,
    NzToolTipModule,
    NzUploadModule,
];
@NgModule({
    declarations: [
        VerificationCheckComponent,
        VerificationComponent,
        VerificationFileUploadComponent,
        VerificationInfoComponent,
        VerificationInvestorComponent,
        VerificationStepperComponent,
    ],
    imports: [
        BackLinkComponent,
        BazaPhoneCountryCodesDataAccessModule,
        CommonModule,
        VerificationStateModule,
        FormsModule,
        IMaskModule,
        PhoneCodeComponent,
        ReactiveFormsModule,
        RouterModule,
        UtilModule,
        ConfirmModalComponent,
        ...NZ_MODULES,
    ],
    providers: [],
    exports: [
        VerificationCheckComponent,
        VerificationComponent,
        VerificationFileUploadComponent,
        VerificationInfoComponent,
        VerificationInvestorComponent,
        VerificationStepperComponent,
    ],
})
export class VerificationFlowModule {}
