import { IsEnum, IsNotEmpty } from 'class-validator';
import { DwollaBankAccountType } from '../models/dwolla-bank-account-type';
import { DwollaFundingSourceChannel } from '../models/dwolla-funding-source';
import { CreateFundingSourceRequest } from './create-funding-source.request';

export class CreateVANFundingSourceRequest extends CreateFundingSourceRequest {
    @IsNotEmpty()
    'type': 'virtual';

    @IsEnum(DwollaFundingSourceChannel)
    @IsNotEmpty()
    bankAccountType: DwollaBankAccountType;
}
