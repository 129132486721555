import { Observable } from 'rxjs';
import {
    BazaNcOperationListItemsResponse,
    BazaNcOperationListRequest,
    BazaNcOperationStatsResponse,
    BazaNcOperationType,
    bazaNcOperationTypeAliases,
} from '@scaliolabs/baza-nc-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationOperationStatusesInvestmentDto } from '../dto/statuses-investment';
import { BazaNcIntegrationOperationStatusesDividendDto } from '../dto/statuses-dividend';
import { BazaNcIntegrationOperationStatusesWithdrawDto } from '../dto/statuses-withdraw';
import { BazaNcIntegrationOperationStatusesTransferDto } from '../dto/statuses-transfer';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { IsArray, IsOptional, IsString } from 'class-validator';

/**
 * Paths collection for Baza NC Integration Operation API
 */
export enum BazaNcIntegrationOperationEndpointPaths {
    list = '/baza-nc-integration/operation/list',
    stats = '/baza-nc-integration/operation/stats',
    filters = '/baza-nc-integration/operation/filters',
}

/**
 * Endpoints definitions for Baza NC Integration Operation API
 */
export interface BazaNcIntegrationOperationEndpoint {
    list(
        request: BazaNcIntegrationOperationListRequest,
        ...args: unknown[]
    ): Promise<BazaNcOperationListItemsResponse> | Observable<BazaNcOperationListItemsResponse>;
    stats(...args: unknown[]): Promise<BazaNcOperationStatsResponse> | Observable<BazaNcOperationStatsResponse>;
    filters(...args: unknown[]): Promise<BazaNcIntegrationOperationFiltersDto> | Observable<BazaNcIntegrationOperationFiltersDto>;
}

/**
 * Statuses & Other filters settings (union for all Operation types)
 */
export type BazaNcIntegrationOperationFiltersStatuses =
    | BazaNcIntegrationOperationStatusesInvestmentDto
    | BazaNcIntegrationOperationStatusesDividendDto
    | BazaNcIntegrationOperationStatusesWithdrawDto
    | BazaNcIntegrationOperationStatusesTransferDto;

/**
 * Statuses & Other filters settings (as Array)
 */
export const bazaNcIntegrationOperationFiltersStatusesModels = [
    BazaNcIntegrationOperationStatusesInvestmentDto,
    BazaNcIntegrationOperationStatusesDividendDto,
    BazaNcIntegrationOperationStatusesWithdrawDto,
    BazaNcIntegrationOperationStatusesTransferDto,
];

/**
 * Listing Entry for BazaNcIntegrationOperationFiltersDto
 */
export class BazaNcIntegrationOperationFiltersListingEntry {
    @ApiModelProperty({
        description: 'Listing Title',
        example: 'Listing 1',
    })
    title: string;

    @ApiModelProperty({
        description: 'Schema for Listing (Display Name)',
        example: 'Schema A',
    })
    schema: string;

    @ApiModelProperty({
        description: 'Offering ID of Listing (could be used for "offeringIds" filter)',
        example: '536433',
    })
    offeringId: string;
}

/**
 * Filters settings for given Operation Type
 * (Baza NC Integration Operation API -> List endpoint)
 */
export class BazaNcIntegrationOperationFiltersOperationType {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcOperationType),
        description: 'Operation Type',
        example: BazaNcOperationType.Withdraw,
    })
    type: BazaNcOperationType;

    @ApiModelProperty({
        description: 'Alias for Operation Type (could be used for FE i18n)',
        example: bazaNcOperationTypeAliases[BazaNcOperationType.Withdraw],
    })
    alias: string;

    @ApiModelProperty({
        description: 'Total number of entries for this Operation Type',
    })
    count: number;

    @ApiModelProperty({
        description:
            'Listings with operations of given type available. You can use "title" for "listings" filter option of "offeringId" for "offeringIds" option',
        type: BazaNcIntegrationOperationFiltersListingEntry,
        isArray: true,
    })
    listings: Array<BazaNcIntegrationOperationFiltersListingEntry>;

    @ApiModelProperty({
        description: 'Public Schema with operations of given type available (values could be used directly to "list" endpoint)',
        type: 'string',
        isArray: true,
    })
    schemas: Array<string>;
}

/**
 * List request DTO for List
 */
export class BazaNcIntegrationOperationListRequest extends BazaNcOperationListRequest {
    @ApiModelProperty({
        description:
            'Filter by Listings. This filter will be applied only to Investments and Dividends operations; for other types filter will be ignored',
        type: 'string',
        isArray: true,
        example: 'Listing 1,Listing 2',
        required: false,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    listings?: Array<string>;

    @ApiModelProperty({
        description:
            'Filter by Schema (Display Name). This filter will be applied only to Investments and Dividends operations; for other types filter will be ignored',
        type: 'string',
        isArray: true,
        example: 'Schema A,Schema B',
        required: false,
    })
    @IsString({ each: true })
    @IsArray()
    @IsOptional()
    schema?: Array<string>;
}

/**
 * Filters settings for given Operation Type
 * Can be used for FE implementation to disable/enable different Filters options
 */
export class BazaNcIntegrationOperationFiltersDto {
    @ApiModelProperty({
        description: 'Total count of all operations',
    })
    count: number;

    @ApiModelProperty({
        type: BazaNcIntegrationOperationFiltersOperationType,
        isArray: true,
        description: 'Available Operation Types',
    })
    types: Array<BazaNcIntegrationOperationFiltersOperationType>;

    @ApiModelProperty({
        description:
            'Listings with operations available. You can use "title" for "listings" filter option of "offeringId" for "offeringIds" option',
        type: BazaNcIntegrationOperationFiltersListingEntry,
        isArray: true,
    })
    listings: Array<BazaNcIntegrationOperationFiltersListingEntry>;

    @ApiModelProperty({
        description: 'All available Public Schema (values could be used directly to "list" endpoint)',
        type: 'string',
        isArray: true,
    })
    schemas: Array<string>;

    @ApiModelProperty({
        description: 'Can Filter By Statuses? (record should exists for given Operation Type + have items in statuses array)',
        anyOf: bazaNcIntegrationOperationFiltersStatusesModels.map(($ref) => ({
            $ref: getSchemaPath($ref),
        })),
        isArray: true,
    })
    canFilterByStatuses: Array<BazaNcIntegrationOperationFiltersStatuses>;

    @ApiModelProperty({
        description: 'List of Operation Types which could have filter by Listings',
        type: 'string',
        isArray: true,
        enum: Object.values(BazaNcOperationType),
        example: [BazaNcOperationType.Investment, BazaNcOperationType.Dividend],
    })
    canFilterByListings: Array<BazaNcOperationType>;

    @ApiModelProperty({
        description: 'List of Operation Types which could have filter by Schema',
        type: 'string',
        isArray: true,
        enum: Object.values(BazaNcOperationType),
        example: [BazaNcOperationType.Investment, BazaNcOperationType.Dividend],
    })
    canFilterBySchema: Array<BazaNcOperationType>;
}
