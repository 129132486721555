import { Injectable } from '@angular/core';

export enum BazaVersionNgStrategy {
    Disabled,
    ProvideVersion,
    SkipVersion,
}

type BazaVersionNgStrategyConfig =
    | { type: BazaVersionNgStrategy.Disabled }
    | { type: BazaVersionNgStrategy.SkipVersion }
    | { type: BazaVersionNgStrategy.ProvideVersion; version: string; redirectTo?: unknown[] };

@Injectable()
export class BazaVersionNgConfig {
    strategy: BazaVersionNgStrategyConfig;
    xHttpHeaderVersion: string;
    xHttpHeaderSkipVersion?: string;
}
