import { Observable } from 'rxjs';
import { TeamMemberDto } from '../dto/team-member.dto';

export enum TeamsMemberEndpointPaths {
    getAll = '/baza-content-types/teams/getAll',
    getById = '/baza-content-types/teams/getById/:id',
    getByUrl = '/baza-content-types/teams/getByUrl/:url',
}

export interface TeamsMemberEndpoint {
    getAll(): Promise<TeamsMemberGetAllResponse> | Observable<TeamsMemberGetAllResponse>;
    getById(id: number): Promise<TeamMemberDto> | Observable<TeamMemberDto>;
    getByUrl(url: string): Promise<TeamMemberDto> | Observable<TeamMemberDto>;
}

export type TeamsMemberGetAllResponse = Array<TeamMemberDto>;
