import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { ArchiveStatus } from '../models/archive-status';

export class UpdateAccountArchiveStatusRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsEnum(ArchiveStatus)
    @IsNotEmpty()
    archiveStatus: ArchiveStatus;
}
