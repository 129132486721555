import { Injectable } from '@angular/core';
import { AuthMasterPasswordDto, AuthMasterPasswordEndpoint, AuthMasterPasswordEndpointPaths } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaAuthMasterPasswordDataAccess implements AuthMasterPasswordEndpoint {
    constructor(
        private readonly ngEndpoint: BazaDataAccessService,
    ) {}

    enable(): Observable<void> {
        return this.ngEndpoint.post<void>(AuthMasterPasswordEndpointPaths.enable);
    }

    disable(): Observable<void> {
        return this.ngEndpoint.post<void>(AuthMasterPasswordEndpointPaths.disable);
    }

    current(): Observable<AuthMasterPasswordDto> {
        return this.ngEndpoint.post<AuthMasterPasswordDto>(AuthMasterPasswordEndpointPaths.current);
    }

    refresh(): Observable<AuthMasterPasswordDto> {
        return this.ngEndpoint.post<AuthMasterPasswordDto>(AuthMasterPasswordEndpointPaths.refresh);
    }
}
