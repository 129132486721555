/**
 * Identity information passed from Plaid Widget in `accounts` object of `onSuccess(public_token, accounts)` onSuccess callback
 * TODO: Add @ValidateNested decorators and finish description of object. Please test it with both Production and Sandbox Plaid
 * accounts
 */
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsObject, IsOptional, IsString } from 'class-validator';

export enum BazaNcBankAccountIdentityAccountSubtype {
    Checking = 'checking',
    Savings = 'savings',
}

export class BazaNcBankAccountIdentityAccount {
    @ApiModelProperty({
        example: 'g8zB5bZvwKimPPpa13nKfBr61EwM6Dt4e5AZn',
        description: 'ID of Account',
    })
    @IsString()
    @IsNotEmpty()
    id: string;

    @ApiModelProperty({
        description: 'Mask',
        example: '0000',
    })
    mask: string;

    @ApiModelProperty({
        description: 'Name',
        example: 'Plaid Checking',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        description: 'Subtype',
        example: BazaNcBankAccountIdentityAccountSubtype.Checking,
        type: 'string',
    })
    @IsEnum(BazaNcBankAccountIdentityAccountSubtype)
    @IsString()
    @IsNotEmpty()
    subtype: BazaNcBankAccountIdentityAccountSubtype;

    @ApiModelProperty({
        description: 'Type',
        example: 'depository',
        // TODO: Replace it with ENUM
    })
    @IsString()
    @IsNotEmpty()
    type: string;

    @ApiModelProperty({
        description: 'Verification Status',
        required: false,
        // TODO: Replace it with ENUM
    })
    @IsString()
    @IsOptional()
    verification_status?: string;

    @ApiModelProperty({
        required: false,
        // TODO: Replace it with ENUM
    })
    @IsString()
    @IsOptional()
    class_type?: string;
}

export class BazaNcBankAccountIdentityAccountInstitution {
    @ApiModelProperty({
        description: 'Institution ID',
        example: '"ins_56"',
    })
    @IsString()
    @IsNotEmpty()
    institution_id: string;

    @ApiModelProperty({
        description: 'Institution Name',
        example: 'Chase',
    })
    @IsString()
    @IsNotEmpty()
    name: string;
}

export class BazaNcBankAccountIdentity {
    @ApiModelProperty({
        description: 'Selected Account',
    })
    @IsObject()
    @IsNotEmpty()
    account: BazaNcBankAccountIdentityAccount;

    @ApiModelProperty({
        type: BazaNcBankAccountIdentityAccount,
        isArray: true,
        description: 'Available Accounts',
    })
    @IsObject({ each: true })
    @IsNotEmpty()
    accounts?: Array<BazaNcBankAccountIdentityAccount>;

    @ApiModelProperty({
        description: 'Institution Details',
    })
    @IsObject()
    @IsNotEmpty()
    institution: BazaNcBankAccountIdentityAccountInstitution;

    @ApiModelProperty({
        description: 'Public Token provided by Plaid',
        example: 'public-sandbox-7b4ec792-ba03-4e3a-9a25-88b2646d907a',
    })
    @IsString()
    @IsNotEmpty()
    public_token: string;

    @ApiModelProperty({
        description: 'Link Session Id provided by Plaid',
        example: '5a64a810-c79c-46db-beae-f737336c463a',
        required: false,
    })
    @IsString()
    @IsOptional()
    link_session_id?: string;

    @ApiModelProperty({
        description: 'Status',
        // TODO: Finish description,
    })
    @IsString()
    @IsOptional()
    status?: string;

    @ApiModelProperty({
        description: 'Transfer Status',
        // TODO: Finish description,
    })
    @IsString()
    @IsOptional()
    transfer_status?: string;
}
