import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Domicile } from '../../../transact-api';

export class BazaNcCmsAccountVerificationFormResourcesStateDto {
    @ApiModelProperty({
        type: 'string',
    })
    title: string;

    @ApiModelProperty({
        type: 'string',
        description: 'State/Area. List of available states you may fetch with /list-states endpoint',
    })
    value: string;
}

export class BazaNcCmsAccountVerificationFormResourcesCountryDto {
    @ApiModelProperty({
        type: 'boolean',
        description: 'In case of USA, do not display countries list',
    })
    isUSA: boolean;

    @ApiModelProperty({
        type: 'string',
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    country: string;

    @ApiModelProperty({
        type: BazaNcCmsAccountVerificationFormResourcesStateDto,
        isArray: true,
    })
    states: Array<BazaNcCmsAccountVerificationFormResourcesStateDto>;
}

export class BazaNcCmsAccountVerificationFormResourcesCitizenshipLabelDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Domicile),
    })
    citizenship: Domicile;

    @ApiModelProperty({
        type: 'string',
    })
    label: string;
}

export class BazaNcCmsAccountVerificationFormResourcesDto {
    @ApiModelProperty({
        isArray: true,
        description: 'All available countries',
    })
    listCountries: Array<string>;

    @ApiModelProperty({
        type: BazaNcCmsAccountVerificationFormResourcesCitizenshipLabelDto,
        isArray: true,
    })
    citizenship: Array<BazaNcCmsAccountVerificationFormResourcesCitizenshipLabelDto>;

    @ApiModelProperty({
        type: BazaNcCmsAccountVerificationFormResourcesCountryDto,
        isArray: true,
        description: 'List of prefetched US states',
    })
    primCountryStates: Array<BazaNcCmsAccountVerificationFormResourcesStateDto>;
}
