import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BazaNcDataAccessModule } from '@scaliolabs/baza-nc-data-access';
import { PurchaseState } from './state';
import { BazaNcIntegrationListingsDataAccessModule } from '@scaliolabs/baza-nc-integration-data-access';

@NgModule({
    imports: [NgxsModule.forFeature([PurchaseState]), BazaNcDataAccessModule, BazaNcIntegrationListingsDataAccessModule],
})
export class PurchaseStateModule {}
