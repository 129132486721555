import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
    BazaNcBankAccountType,
    BazaNcBootstrapDto,
    BazaNcDwollaCustomerDetailDto,
    BazaNcWithdrawRequest,
} from '@scaliolabs/baza-nc-shared';
import { BazaLinkUtilSharedService, BazaWebUtilSharedService, Message, UtilModule } from '@scaliolabs/baza-web-utils';
import { Observable, catchError, filter, of, tap } from 'rxjs';
import { GetDefaultBankAccount, LoadAccountBalance, PurchaseState, PFSharedService, WithdrawFunds } from '../../data-access';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    BackLinkComponent,
    PaymentHeaderComponent,
    WithdrawFundsModalComponent,
    WithdrawFundsNotificationComponent,
} from '@scaliolabs/baza-web-ui-components';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
    selector: 'app-withdraw',
    templateUrl: './withdraw.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        UtilModule,
        BackLinkComponent,
        PaymentHeaderComponent,
        WithdrawFundsModalComponent,
        WithdrawFundsNotificationComponent,
        NzSkeletonModule,
        NzModalModule,
    ],
})
export class WithdrawComponent implements OnInit {
    @Input()
    initData: BazaNcBootstrapDto;

    @Output()
    withdrawTriggerEnableToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    dwollaDefaultCashOutAccount$ = this.store.select(PurchaseState.dwollaDefaultCashOutAccount);
    dwollaCustomerDetail$: Observable<BazaNcDwollaCustomerDetailDto>;
    withdrawFundsError$: Observable<Message | undefined>;

    withdrawFundsForm: UntypedFormGroup;
    isWithdrawFundsModalVisible = false;
    isWithdrawFundsNotificationVisible = false;
    i18nBasePath = 'withdraw';

    constructor(
        private readonly store: Store,
        private readonly fb: UntypedFormBuilder,
        public readonly dss: PFSharedService,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {
        this.withdrawTriggerEnableToggle.emit(false);
        this.resetWithdrawFundsForm();
        this.loadData();
    }

    ngOnInit(): void {
        this.dwollaCustomerDetail$ = this.store.select(PurchaseState.dwollaCustomerDetail).pipe(
            filter((res) => !!res),
            tap((res) => {
                if (this.wts.isDwollaCashOutAccountLinked(this.initData) && +(res?.balance?.value ?? '0') >= 0.01) {
                    this.withdrawTriggerEnableToggle.emit(true);
                }
            }),
        );
    }

    loadData() {
        this.store.dispatch([new LoadAccountBalance(), new GetDefaultBankAccount(BazaNcBankAccountType.CashOut, { throwError: false })]);
    }

    onWithdrawFundsClicked() {
        this.loadData();
        this.openWithdrawFundsPopup();
    }

    onWithdrawFundsCancel() {
        this.isWithdrawFundsModalVisible = false;
        this.resetWithdrawFundsForm();
    }

    onSubmitWithdrawFundsForm() {
        const withdrawRequest: BazaNcWithdrawRequest = {
            amount: this.withdrawFundsForm.get('withdrawAmount').value.toString(),
        };

        this.withdrawFundsError$ = this.store.dispatch(new WithdrawFunds(withdrawRequest)).pipe(
            tap((result) => {
                if (result?.type === 'error') return;

                this.store.dispatch(new LoadAccountBalance());
                this.onWithdrawFundsCancel();
                this.displayWithdrawFundsNotification();
            }),
            catchError(() =>
                of({
                    type: 'error',
                    text: this.wts.getI18nLabel('uic.withdrawFunds', 'form.alerts.withdrawalError.label'),
                } as Message),
            ),
        );
    }

    displayWithdrawFundsNotification() {
        this.isWithdrawFundsNotificationVisible = true;
    }

    private openWithdrawFundsPopup() {
        this.resetWithdrawFundsForm();
        this.isWithdrawFundsModalVisible = true;
    }

    private resetWithdrawFundsForm(): void {
        this.withdrawFundsForm = this.fb.group({
            withdrawAmount: [null, [Validators.required]],
        });

        this.withdrawFundsError$ = null;
    }
}
