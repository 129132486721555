import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcReportDto } from '../dto/baza-nc-report.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BazaNcReportStatus } from '../models/baza-nc-report-status';
import { BazaNcRequestLogDto } from '../dto/baza-nc-request-log.dto';
import { BazaNcWebhookLogDto } from '../dto/baza-nc-webhook-log.dto';

export enum BazaNcReportCmsEndpointPaths {
    list = '/baza-nc/report/cms/list',
    getById = '/baza-nc/report/cms/getById',
    sendOne = '/baza-nc/report/cms/sendOne',
    sendMany = '/baza-nc/report/cms/sendMany',
    resendOne = '/baza-nc/report/cms/resendOne',
    exportToCsv = '/baza-nc/report/cms/exportToCsv',
    sync = '/baza-nc/report/cms/sync',
    syncAll = '/baza-nc/report/cms/syncAll',
}

export enum BazaNcNcRequestLogEndpointPaths {
    // TODO: move nc request logs to appropriate module
    ncRequestLogs = '/baza-nc/third-parties/nc-api-requests/list',
    truncateNcRequestLogs = '/baza-nc/third-parties/nc-api-requests',
}

export enum BazaNcNcWebhookLogEndpointPaths {
    // TODO: move nc webhook logs to appropriate module
    findNcWebhookLogById = '/baza-nc/third-parties/nc-webhooks/:id',
    ncWebhookLogs = '/baza-nc/third-parties/nc-webhooks/list',
    truncateNcWebhookLogs = '/baza-nc/third-parties/nc-webhooks',
}

export interface BazaNcReportCmsEndpoint {
    list(request: BazaNcReportCmsListRequest): Promise<BazaNcReportCmsListResponse> | Observable<BazaNcReportCmsListResponse>;
    getById(request: BazaNcReportCmsGetByIdRequest): Promise<BazaNcReportDto> | Observable<BazaNcReportDto>;
    sendOne(request: BazaNcReportCmsSendOneRequest): Promise<BazaNcReportDto> | Observable<BazaNcReportDto>;
    sendMany(
        request: BazaNcReportCmsSendManyRequest,
    ): Promise<BazaNcReportCmsSendManyResponse> | Observable<BazaNcReportCmsSendManyResponse>;
    resendOne(request: BazaNcReportCmsResendOneRequest): Promise<BazaNcReportDto> | Observable<BazaNcReportDto>;
    exportToCsv(request: BazaNcReportCmsExportToCsvRequest): Promise<string> | Observable<string>;
    sync(request: BazaNcReportCmsSyncRequest): Promise<BazaNcReportDto> | Observable<BazaNcReportDto>;
    syncAll(request: BazaNcReportCmsSyncAllRequest): Promise<void> | Observable<void>;
}

export class BazaNcReportCmsListRequest extends CrudListRequestDto<BazaNcReportDto> {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcReportStatus),
        example: [BazaNcReportStatus.Reported],
        isArray: true,
        description: 'Filter by statuses',
        required: false,
    })
    @IsEnum(BazaNcReportStatus, { each: true })
    @IsArray()
    @IsOptional()
    statuses?: Array<BazaNcReportStatus>;
}

export class BazaNcRequestLogsListRequest extends CrudListRequestDto<BazaNcRequestLogDto> {}

export class BazaNcRequestLogsListResponse extends CrudListResponseDto<BazaNcRequestLogDto> {}

export class BazaNcWebhookLogsListRequest extends CrudListRequestDto<BazaNcWebhookLogDto> {}

export class BazaNcWebhookLogsListResponse extends CrudListResponseDto<BazaNcWebhookLogDto> {}

export class BazaNcReportCmsListResponse extends CrudListResponseDto<BazaNcReportDto> {
    @ApiModelProperty({
        type: BazaNcReportDto,
        isArray: true,
    })
    items: Array<BazaNcReportDto>;

    @ApiModelProperty({
        description: 'Total count of Records which are ready to be sent to NC',
        example: 10,
    })
    totalRecordsToSend: number;
}

export class BazaNcReportCmsGetByIdRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    ulid: string;
}

export class BazaNcReportCmsSendOneRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    ulid: string;
}

export class BazaNcReportCmsResendOneRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    ulid: string;
}

export class BazaNcReportCmsSendManyRequest {
    @ApiModelProperty({
        description: 'Filter records by Date From',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateFrom?: string;

    @ApiModelProperty({
        description: 'Filter records by Date To',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateTo?: string;
}

export class BazaNcReportCmsSendManyResponse {
    @ApiModelProperty({
        description: 'Amount of Reports sent to NC Escrow Ops',
    })
    affected: number;
}

export class BazaNcReportCmsExportToCsvRequest {
    @ApiModelProperty({
        description: 'Filter records by Date From',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateFrom?: string;

    @ApiModelProperty({
        description: 'Filter records by Date To',
        example: new Date().toISOString(),
        required: false,
    })
    @IsDateString()
    @IsOptional()
    dateTo?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcReportStatus),
        example: [BazaNcReportStatus.Reported],
        isArray: true,
        description: 'Filter by statuses',
        required: false,
    })
    @IsEnum(BazaNcReportStatus, { each: true })
    @IsArray()
    @IsOptional()
    statuses?: Array<BazaNcReportStatus>;
}

export class BazaNcReportCmsSyncRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    @IsString()
    ulid: string;
}

export class BazaNcReportCmsSyncAllRequest {
    @ApiModelProperty({
        description: 'Run as async request',
    })
    @IsBoolean()
    @IsNotEmpty()
    async: boolean;
}
