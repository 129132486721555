import { Injectable } from '@angular/core';
import {
    FeedApplauseRequest,
    FeedApplauseResponse,
    BazaNcIntegrationFeedEndpoint,
    FeedEndpointPaths,
    FeedGetByIdRequest,
    FeedGetByIdResponse,
    FeedHasAnyUpdatesResponse,
    FeedListRequest,
    FeedListResponse,
    FeedMarkAllAsReadRequest,
    FeedMarkAllAsReadResponse,
    FeedUnapplauseRequest,
    FeedUnapplauseResponse,
    BazaNcIntegrationFeedGetBySlugRequest,
    BazaNcIntegrationFeedPostDto,
} from '@scaliolabs/baza-nc-integration-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';

@Injectable()
export class BazaNcIntegrationFeedDataAccess implements BazaNcIntegrationFeedEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    list(request?: FeedListRequest): Observable<FeedListResponse> {
        return this.ngEndpoint.post<FeedListResponse>(FeedEndpointPaths.list, request);
    }

    getById(request: FeedGetByIdRequest): Observable<FeedGetByIdResponse> {
        return this.ngEndpoint.post<FeedGetByIdResponse>(FeedEndpointPaths.getById, request);
    }

    applause(request: FeedApplauseRequest): Observable<FeedApplauseResponse> {
        return this.ngEndpoint.post<FeedApplauseResponse>(FeedEndpointPaths.applause, request);
    }

    unapplause(request: FeedUnapplauseRequest): Observable<FeedUnapplauseResponse> {
        return this.ngEndpoint.post<FeedUnapplauseResponse>(FeedEndpointPaths.unapplause, request);
    }

    markAllAsRead(request: FeedMarkAllAsReadRequest): Observable<FeedMarkAllAsReadResponse> {
        return this.ngEndpoint.post<FeedMarkAllAsReadResponse>(FeedEndpointPaths.markAllAsRead, request);
    }

    hasAnyUpdates(): Observable<FeedHasAnyUpdatesResponse> {
        return this.ngEndpoint.post<FeedHasAnyUpdatesResponse>(FeedEndpointPaths.hasAnyUpdates);
    }

    getBySlug(request: BazaNcIntegrationFeedGetBySlugRequest, ...args: unknown[]): Observable<BazaNcIntegrationFeedPostDto> {
        return this.ngEndpoint.post(FeedEndpointPaths.getBySlug, request);
    }
}
