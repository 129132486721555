export const VFInfoEnI18n = {
    title: 'Personal Information',
    descr: 'We collect your information to perform Know Your Customer (KYC) and Anti-Money Laundering (AML) compliance. Please provide correct legal information to verify your account.',
    subtitle: 'You may only use characters A to Z, 0 to 9, and simple punctuation to fill in information.',
    form: {
        fields: {
            firstName: {
                label: 'First Name',
            },
            lastName: {
                label: 'Last Name',
            },
            phone: {
                label: 'Phone Number',
                subtitle: 'We need your phone number as a backup means of contact. It will not be shared with any third party services.',
                validators: {
                    onlynumbers: 'Only numbers are accepted',
                    maxlength: 'Phone Number has to be maximum of {max} digits',
                },
            },
            residentialCountry: {
                label: 'Country',
                selectPlaceholder: 'Select One',
            },
            residentialStreetAddress1: {
                label: 'Street Address',
            },
            residentialStreetAddress2: {
                label: 'Street Address 2',
                hint: '(optional)',
            },
            residentialCity: {
                label: 'City',
            },
            residentialState: {
                label: 'State',
                selectPlaceholder: 'Select One',
            },
            residentialZipCode: {
                label: 'Zip Code',
                validators: {
                    minlength: 'Your ZIP code does not have {{ length }} numeric digits',
                    maxlength: 'Your ZIP code does not have {{ length }} numeric digits',
                    onlynumbers: 'Your ZIP code does not have {{ length }} numeric digits',
                    whiteSpace: 'Your ZIP code does not have {{ length }} numeric digits',
                },
            },
            dateOfBirth: {
                label: 'Date of Birth',
                validators: {
                    isAdult: 'Investor should be {{ age }} or older',
                },
            },
            citizenship: {
                label: 'Citizenship',
                selectPlaceholder: 'Select One',
            },
            ssn: {
                label: 'Social Security Number',
                validators: {
                    minlength: 'Social Security Number has to be a minimum of {{ length }} symbols',
                },
                hint: 'We need your SSN for identity verification and to protect against fraud and money laundering. It will be securely stored, never shared, and used only for verification purposes.',
            },
            ssnCheckbox: {
                label: `I don't have a SSN`,
            },
            fileUpload: {
                descr: 'International investors are required to provide a passport to verify their identity. Please upload an image (png, jpg, jpeg, pdf) of your passport below.',
                docLabel: 'Uploaded Document:',
                btnText: 'Upload',
            },
            submitBtn: {
                label: 'Next',
            },
        },
        genericValidators: {
            required: '{{ fieldLabel }} is required',
            restrictedChars: 'Special characters are not allowed, only spaces and hyphen (-) are permitted',
        },
        sections: {
            address: {
                title: 'Residential Address',
                descr: 'Your physical address. PO Boxes and mail drops are not valid.',
            },
        },
    },
};
