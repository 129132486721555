import { Injectable } from '@angular/core';
import { BAZA_COMMON_I18N_CONFIG, ProjectLanguage } from '@scaliolabs/baza-core-shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BazaI18nDetector } from '../baza-i18n-detector';
import { DefaultCurrentLanguageDetector } from '../detectors/default-current-language.detector';
import { LocalStorageCurrentLanguageDetector } from '../detectors/local-storage-current-language.detector';

@Injectable()
export class BazaI18nNgDefaultService {
    private _detectors: Array<BazaI18nDetector> = [this.detectorLocalStorage, this.detectorDefault];

    constructor(
        private readonly detectorLocalStorage: LocalStorageCurrentLanguageDetector,
        private readonly detectorDefault: DefaultCurrentLanguageDetector,
    ) {}

    get detectors(): Array<BazaI18nDetector> {
        return [...this._detectors];
    }

    set detectors(detectors: Array<BazaI18nDetector>) {
        this._detectors = [...detectors];
    }

    detectDefaultLanguage(): Observable<ProjectLanguage> {
        const detectors = this.detectors;

        return combineLatest(
            this._detectors.map((d) =>
                d.detect().pipe(
                    map((result) => ({
                        detector: d,
                        result,
                    })),
                ),
            ),
        ).pipe(
            map((results) => {
                for (const detector of detectors) {
                    const found = results.find((d) => d.detector === detector);

                    if (found && found.result) {
                        return found.result;
                    }
                }

                return BAZA_COMMON_I18N_CONFIG.defaultLanguage;
            }),
        );
    }
}
