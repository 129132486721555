import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-payment-item-header',
    templateUrl: './payment-item-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentItemHeaderComponent {
    @ViewChild(TemplateRef)
    content: TemplateRef<HTMLElement>;
}
