import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BlogCmsCreateRequest,
    BlogCmsDeleteRequest,
    BlogCmsDto,
    BlogCmsEndpoint,
    BlogCmsEndpointPaths,
    BlogCmsGetByIdRequest,
    BlogCmsListRequest,
    BlogCmsListResponse,
    BlogCmsSetSortOrderRequest,
    BlogCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { BlogCmsSetSortOrderResponse } from '@scaliolabs/baza-content-types-shared';

@Injectable()
export class BlogCmsDataAccess implements BlogCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    create(request: BlogCmsCreateRequest): Observable<BlogCmsDto> {
        return this.http.post(BlogCmsEndpointPaths.create, request);
    }

    update(request: BlogCmsUpdateRequest): Observable<BlogCmsDto> {
        return this.http.post(BlogCmsEndpointPaths.update, request);
    }

    delete(request: BlogCmsDeleteRequest): Observable<void> {
        return this.http.post(BlogCmsEndpointPaths.delete, request);
    }

    getById(request: BlogCmsGetByIdRequest): Observable<BlogCmsDto> {
        return this.http.post(BlogCmsEndpointPaths.getById, request);
    }

    list(request: BlogCmsListRequest): Observable<BlogCmsListResponse> {
        return this.http.post(BlogCmsEndpointPaths.list, request);
    }

    setSortOrder(request: BlogCmsSetSortOrderRequest): Observable<BlogCmsSetSortOrderResponse> {
        return this.http.post(BlogCmsEndpointPaths.setSortOrder, request);
    }
}
