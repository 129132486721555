import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '../../../../baza-crud/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaReferralCodeUsageDto } from '../dto/baza-referral-code-usage.dto';
import { BazaReferralCodeUsageCsvDto } from '../dto/baza-referral-code-usage-csv.dto';

export enum ReferralCodeUsageCmsEndpointPaths {
    listByReferralCode = '/baza-core/cms/referral-codes/codes/listByReferralCode',
    exportListByReferralCodeToCSV = '/baza-core/cms/referral-codes/codes/exportListByReferralCodeToCSV',
    listByReferralCampaign = '/baza-core/cms/referral-codes/codes/listByReferralCampaign',
    exportListByReferralCampaignToCSV = '/baza-core/cms/referral-codes/codes/exportListByReferralCampaignToCSV',
    listByReferralAccount = '/baza-core/cms/referral-codes/codes/listByReferralAccount',
    exportListByReferralAccountToCSV = '/baza-core/cms/referral-codes/codes/exportListByReferralAccountToCSV',
}

export interface BazaReferralCodeUsageCmsEndpoint {
    listByReferralCode(
        request: BazaReferralCodeCmsUsageByCodeListRequest,
    ): Promise<BazaReferralCodeCmsUsageByCodeListResponse> | Observable<BazaReferralCodeCmsUsageByCodeListResponse>;
    exportListByReferralCodeToCSV(request: BazaReferralCodeCmsUsageByCodeExportListToCSVRequest): Promise<string> | Observable<string>;
    listByReferralCampaign(
        request: BazaReferralCodeCmsUsageByCampaignListRequest,
    ): Promise<BazaReferralCodeCmsUsageByCampaignListResponse> | Observable<BazaReferralCodeCmsUsageByCampaignListResponse>;
    exportListByReferralCampaignToCSV(
        request: BazaReferralCodeCmsUsageByCampaignExportListToCSVRequest,
    ): Promise<string> | Observable<string>;
    listByReferralAccount(
        request: BazaReferralCodeCmsUsageByAccountListRequest,
    ): Promise<BazaReferralCodeCmsUsageByAccountListResponse> | Observable<BazaReferralCodeCmsUsageByAccountListResponse>;
    exportListByReferralAccountToCSV(
        request: BazaReferralCodeCmsUsageByAccountExportListToCSVRequest,
    ): Promise<string> | Observable<string>;
}

export class BazaReferralCodeCmsUsageByCodeListRequest extends CrudListRequestDto {
    @ApiModelProperty({
        description: 'Referral Code',
    })
    @IsString()
    @IsNotEmpty()
    referralCode: string;
}

export class BazaReferralCodeCmsUsageByCodeListResponse extends CrudListResponseDto<BazaReferralCodeUsageDto> {
    @ApiModelProperty({
        type: BazaReferralCodeUsageDto,
        isArray: true,
    })
    items: Array<BazaReferralCodeUsageDto>;
}

export class BazaReferralCodeCmsUsageByCampaignListRequest extends CrudListRequestDto {
    @ApiModelProperty({
        description: 'Referral Campaign ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    campaignId: number;

    @ApiModelProperty({
        description: 'Include only selected referral codes',
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    filterByReferralCodeIds?: Array<number>;
}

export class BazaReferralCodeCmsUsageByCampaignListResponse extends CrudListResponseDto<BazaReferralCodeUsageDto> {
    @ApiModelProperty({
        type: BazaReferralCodeUsageDto,
        isArray: true,
    })
    items: Array<BazaReferralCodeUsageDto>;
}

export class BazaReferralCodeCmsUsageByCampaignExportListToCSVRequest extends CrudExportToCsvRequest<
    BazaReferralCodeUsageCsvDto,
    BazaReferralCodeCmsUsageByCampaignListRequest
> {}

export class BazaReferralCodeCmsUsageByAccountListRequest extends CrudListRequestDto {
    @ApiModelProperty({
        description: 'Referral Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    accountId: number;

    @ApiModelProperty({
        description: 'Include only selected referral codes',
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsOptional()
    filterByReferralCodeIds?: Array<number>;
}

export class BazaReferralCodeCmsUsageByAccountListResponse extends CrudListResponseDto<BazaReferralCodeUsageDto> {
    @ApiModelProperty({
        type: BazaReferralCodeUsageDto,
        isArray: true,
    })
    items: Array<BazaReferralCodeUsageDto>;
}

export class BazaReferralCodeCmsUsageByAccountExportListToCSVRequest extends CrudExportToCsvRequest<
    BazaReferralCodeUsageCsvDto,
    BazaReferralCodeCmsUsageByAccountListRequest
> {}

export class BazaReferralCodeCmsUsageByCodeExportListToCSVRequest extends CrudExportToCsvRequest<
    BazaReferralCodeUsageCsvDto,
    BazaReferralCodeCmsUsageByCodeListRequest
> {
    @ApiModelProperty({
        description: 'Referral Code',
    })
    @IsString()
    @IsNotEmpty()
    referralCode: string;
}
