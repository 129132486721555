import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOfferingStatus } from '@scaliolabs/baza-nc-shared';
import { BazaNcRangesNumber } from '../models/ranges/baza-nc-ranges-number';
import { BazaNcSearchRanges, bazaNcSearchRangesSwagger } from '../models/baza-nc-search-ranges';
import { BazaNcRangesEnum } from '../models/ranges/baza-nc-ranges-enum';
import { BazaNcRangesDate } from '../models/ranges/baza-nc-ranges-date';

export class BazaNcSearchRangesResponseDto {
    // Fields from Properties (Schema)
    [property: string]: BazaNcSearchRanges;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering status',
    })
    status: BazaNcRangesEnum<BazaNcOfferingStatus>;

    @ApiModelProperty({
        description: 'Date Created At',
    })
    dateCreatedAt: BazaNcRangesDate;

    @ApiModelProperty({
        description: 'Date Updated At',
    })
    dateUpdatedAt: BazaNcRangesDate;

    @ApiModelProperty({
        description: 'Price Per Share (as cents)',
    })
    pricePerShareCents: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Current Value (as cents)',
    })
    currentValueCents: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Number of shares available to purchase (total)',
    })
    numSharesAvailable: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Total percents funded',
    })
    percentFunded: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Total amount (as cents)',
    })
    totalSharesValueCents: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Minimal number of shares to start purchase',
    })
    numSharesMin: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Number of shares required to automatically join user to Elite Investors',
    })
    sharesToJoinEliteInvestors?: BazaNcRangesNumber;

    @ApiModelProperty({
        description: 'Start Date',
    })
    startDate: BazaNcRangesDate;

    @ApiModelProperty({
        description: 'End Date',
    })
    endDate: BazaNcRangesDate;

    @ApiModelProperty({
        description: 'List of available Schema',
    })
    schemas: BazaNcRangesEnum;

    // This field is for Swagger documentation only
    @ApiModelProperty({
        description: 'Example field from Properties (Schema)',
        ...bazaNcSearchRangesSwagger(),
    })
    examplePropertiesField?: BazaNcSearchRanges;
}
