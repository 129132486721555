import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  TestimonialCmsDto,
  TestimonialsCmsCreateRequest,
  TestimonialsCmsDeleteRequest,
  TestimonialsCmsEndpoint,
  TestimonialsCmsEndpointPaths,
  TestimonialsCmsGetByIdRequest,
  TestimonialsCmsListRequest,
  TestimonialsCmsListResponse,
  TestimonialsCmsSetSortOrderRequest,
  TestimonialsCmsSetSortOrderResponse,
  TestimonialsCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class TestimonialsCmsDataAccess implements TestimonialsCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: TestimonialsCmsCreateRequest): Observable<TestimonialCmsDto> {
    return this.http.post(TestimonialsCmsEndpointPaths.create, request);
  }

  update(request: TestimonialsCmsUpdateRequest): Observable<TestimonialCmsDto> {
    return this.http.post(TestimonialsCmsEndpointPaths.update, request);
  }

  delete(request: TestimonialsCmsDeleteRequest): Observable<void> {
    return this.http.post(TestimonialsCmsEndpointPaths.delete, request);
  }

  list(request: TestimonialsCmsListRequest): Observable<TestimonialsCmsListResponse> {
    return this.http.post(TestimonialsCmsEndpointPaths.list, request);
  }

  getById(request: TestimonialsCmsGetByIdRequest): Observable<TestimonialCmsDto> {
    return this.http.post(TestimonialsCmsEndpointPaths.getById, request);
  }

  setSortOrder(request: TestimonialsCmsSetSortOrderRequest): Observable<TestimonialsCmsSetSortOrderResponse> {
    return this.http.post(TestimonialsCmsEndpointPaths.setSortOrder, request);
  }
}
