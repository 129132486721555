import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { UtilModule } from '@scaliolabs/baza-web-utils';

import { FooterComponent } from './footer.component';
import { FooterMenuModule } from '../footer-menu';

@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    imports: [CommonModule, NzGridModule, NzLayoutModule, RouterModule, FooterMenuModule, UtilModule],
})
export class FooterModule {}
