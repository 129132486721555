import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazaWebUtilSharedService, MktSectionCommonFields, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
    selector: 'app-cta',
    templateUrl: './cta.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzButtonModule],
})
export class CTAComponent extends MktSectionCommonFields {
    @Input()
    index = 0;

    @Input()
    btnClass = 'ant-btn-primary';

    constructor(public readonly wts: BazaWebUtilSharedService) {
        super();
    }

    public get i18nBasePath() {
        return `uic.marketing.cta.section${this.index}`;
    }

    public get ctaLinkConfig() {
        return `${this.i18nBasePath}.btnCTA.linkConfig`;
    }
}
