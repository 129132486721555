import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@Component({
    selector: 'app-kyc-disapproved-alert',
    templateUrl: './kyc-disapproved-alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzAlertModule],
})
export class KycDisapprovedAlertComponent {
    @Output()
    redirectCTAClicked = new EventEmitter<void>();

    i18nBasePath = 'uic.kycDisapprovedAlert';

    @HostListener('window:click', ['$event.target'])
    handleClick(targetEl: HTMLElement) {
        if (targetEl && targetEl?.classList?.contains('edit-link')) {
            this.redirectCTAClicked.emit();
        }
    }

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
