import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DividendAlertState } from '@scaliolabs/sandbox-web/data-access';

@Component({
    selector: 'app-dividend-alert',
    templateUrl: './dividend-alert.component.html',
    styleUrls: ['./dividend-alert.component.less'],
})
export class DividendAlertComponent {
    showDividendAlert$ = this.store.select(DividendAlertState.show);

    constructor(private readonly router: Router, private readonly store: Store) {}

    navigateToAccount(): void {
        this.router.navigate(['account', 'payment-method-dwolla'], { fragment: 'payout-method' });
    }
}
