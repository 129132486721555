// Should be equal to niceType: https://github.com/braintree/credit-card-type

export enum BazaCreditCardType {
    Visa = 'Visa',
    MasterCard = 'Mastercard',
    Diners = 'Diners Club',
    Maestro = 'Maestro',
    Electron = 'Elo',
    UnionPay = 'UnionPay',
    AmericanExpress = 'American Express',
    Discover = 'Discover',
    JCB = 'JCB',
    MIR = 'Mir',
    Hiper = 'Hiper',
    Hipercard = 'Hipercard',
}
