import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional } from 'class-validator';
import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { RecurringTransactionDto } from '../dto/recurring-transaction.dto';
import { Observable } from 'rxjs';
import { BazaNcRecurringTransactionStatus } from '../models/baza-nc-recurring-transaction-status';
import { BazaRecurringTransactionMetadataInterface } from '../interface/baza-recurring-transaction-metadata.interface';

export enum BazaNcIntegrationRecurringTransactionsCmsEndpointPaths {
    getByOfferingId = '/baza-nc/cms/recurring-transactions/transactions/',
    getMetadata = '/baza-nc/cms/recurring-transactions/transactions/logs/',
    list = '/baza-nc/cms/recurring-transactions/list',
    listDistinct = '/baza-nc/cms/recurring-transactions/list-distinct',
    update = '/baza-nc/cms/recurring-transactions/update',
}

export interface BazaNcIntegrationRecurringTransactionsCmsEndpoint {
    list(
        request: BazaRecurringTransactionsListCmsRequest,
    ): Promise<BazaRecurringTransactionsListCmsResponse> | Observable<BazaRecurringTransactionsListCmsResponse>;

    listDistinct(
        request: BazaRecurringTransactionsDistinctListCmsRequest,
    ): Promise<BazaRecurringTransactionsListDistinctCmsResponse> | Observable<BazaRecurringTransactionsListDistinctCmsResponse>;

    getByOfferingId(
        request: BazaRecurringTransactionsGetByOfferingIdCmsRequest,
        offeringId?: string,
    ): Promise<BazaRecurringTransactionsGetByOfferingIdCmsResponse> | Observable<BazaRecurringTransactionsGetByOfferingIdCmsResponse>;

    getMetadata(
        request: BazaRecurringTransactionsGetByIdCmsRequest,
        id?: number,
    ): Promise<BazaRecurringTransactionsGetByIdCmsResponse> | Observable<BazaRecurringTransactionsGetByIdCmsResponse>;

    update(
        request: BazaRecurringTransactionUpdateCmsRequest,
        ...args: unknown[]
    ): Promise<BazaRecurringTransactionUpdateCmsResponse> | Observable<BazaRecurringTransactionUpdateCmsResponse>;
}

export class BazaRecurringTransactionCmsDto extends RecurringTransactionDto {
    @ApiModelProperty({
        description: 'Recurring Transaction ID',
    })
    id: number;

    @ApiModelProperty({
        description: 'Nc Offering ID',
    })
    ncOfferingId: string;

    @ApiModelProperty({
        description: 'Offering Name',
    })
    offeringName: string;

    @ApiModelProperty({
        description: 'Investor Account Name',
    })
    investorAccount: string;

    @ApiModelProperty({
        description: 'Recurring Transaction Status',
    })
    status: BazaNcRecurringTransactionStatus;

    @ApiModelProperty({
        description: 'Recurring Transaction Metadata',
    })
    metadata?: BazaRecurringTransactionMetadataInterface;
}

export class BazaRecurringTransactionDistinctListCmsDto {
    @ApiModelProperty({
        description: 'Recurring Transaction ID',
    })
    id: number;

    @ApiModelProperty({
        description: 'Nc Offering ID',
    })
    ncOfferingId: string;

    @ApiModelProperty({
        description: 'Offering Name',
    })
    offeringName: string;

    @ApiModelProperty({
        description: 'Max Amount',
    })
    ncMaxAmount: number;

    @ApiModelProperty({
        description: 'Created At',
    })
    createdAt: Date;
}

export class BazaRecurringTransactionUpdateCmsRequest {
    @ApiModelProperty({
        description: 'Recurring Transaction ID',
    })
    @IsNumber()
    id: number;

    @ApiModelProperty({
        description: 'Status',
        enumName: 'BazaNcRecurringTransactionStatus',
    })
    @IsEnum(BazaNcRecurringTransactionStatus)
    status: BazaNcRecurringTransactionStatus;
}

export class BazaRecurringTransactionsListCmsRequest extends CrudListRequestDto<BazaRecurringTransactionCmsDto> {
    @ApiModelProperty({
        description: 'Flag to load relations',
    })
    @IsBoolean()
    @IsOptional()
    withRelations? = true;
}
export class BazaRecurringTransactionsDistinctListCmsRequest {
    @ApiModelProperty({
        description: 'index',
        default: 1,
    })
    @IsNotEmpty()
    index = 1;

    @ApiModelProperty({
        description: 'size',
        default: 1,
    })
    @IsNotEmpty()
    size = 1;

    @ApiModelProperty({
        description: 'sort',
        default: 'ASC',
    })
    @IsNotEmpty()
    @IsOptional()
    sort?: 'ASC' | 'DESC';
}

export class BazaRecurringTransactionsGetByOfferingIdCmsRequest extends CrudListRequestDto<BazaRecurringTransactionCmsDto> {
    @ApiModelProperty({
        description: 'Offering ID',
    })
    @IsOptional()
    ncOfferingId?: string;
}

export class BazaRecurringTransactionsGetByIdCmsRequest extends CrudListRequestDto<BazaRecurringTransactionCmsDto> {
    @ApiModelProperty({
        description: 'Recurring Transaction ID',
    })
    @IsNotEmpty()
    id: number;
}

export class BazaRecurringTransactionsListDistinctCmsResponse extends CrudListResponseDto<BazaRecurringTransactionDistinctListCmsDto> {}

export class BazaRecurringTransactionsListCmsResponse extends CrudListResponseDto<BazaRecurringTransactionCmsDto> {}

export class BazaRecurringTransactionsGetByOfferingIdCmsResponse extends CrudListResponseDto<BazaRecurringTransactionCmsDto> {}

export class BazaRecurringTransactionsGetByIdCmsResponse extends CrudListResponseDto<BazaRecurringTransactionCmsDto> {}

export class BazaRecurringTransactionUpdateCmsResponse extends BazaRecurringTransactionCmsDto {}
