import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaAuthSessionRecordType } from '../models/baza-auth-session-record-type';

export class BazaAuthSessionDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaAuthSessionRecordType),
    })
    type: BazaAuthSessionRecordType;

    @ApiModelProperty({
        description: 'Signed In At (ISO Date String)',
    })
    date: string;

    @ApiModelProperty({
        description: 'IP Address',
    })
    ip: string;

    @ApiModelProperty({
        description: 'User-Agent (Device)',
    })
    userAgent: string;

    @ApiModelProperty({
        description: 'Operation System (parsed from User-Agent)',
    })
    os: string;

    @ApiModelProperty({
        description: 'Browser (parsed from User-Agent)',
    })
    browser: string;
}
