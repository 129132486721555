import { LegacyPlaidConfig } from 'ngx-plaid-link';

export interface DwollaPlaidLinkResponse {
    linkToken: string;
    environment: string;
}

export const PLAID_CONFIG: LegacyPlaidConfig = {
    apiVersion: 'v2',
    env: 'sandbox',
    key: null,
    onExit: () => null,
    onSuccess: () => null,
    product: ['auth'],
    selectAccount: false,
    token: null,
    webhook: '',
};
