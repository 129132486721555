import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcOfferingRegulationType } from '../../../offering';

export class PurchaseFlowInvestorRegulationLimitsDto {
    @ApiModelProperty({
        description: 'account total limit for RegCf regulation limits in cents',
    })
    accountTotalLimitCents?: number;

    @ApiModelProperty({
        description: 'Yearly income for investor (saved on NC) in cents',
    })
    yearlyIncomeCents?: number;

    @ApiModelProperty({
        description: 'Current household income for investor (saved on NC) in cents',
    })
    currentHouseholdIncome?: number;

    @ApiModelProperty({
        description: 'Investor net worth (saved on NC) in cents',
    })
    netWorthCents?: number;

    @ApiModelProperty({
        description: 'Investor external RegCf investments in cents',
    })
    externalRegCfInvestmentsCents?: number;

    @ApiModelProperty({
        description: 'Regulation Type for the current offering',
    })
    offeringRegulationType?: BazaNcOfferingRegulationType;
}
