import { Injectable } from '@angular/core';
import { BazaPhoneCountryCodesDataAccess } from '@scaliolabs/baza-core-data-access';
import { BehaviorSubject, Observable } from 'rxjs';
import { PhoneCountryCodesRepositoryDto } from '@scaliolabs/baza-core-shared';
import { retryWhen, tap } from 'rxjs/operators';
import { BazaPhoneCountryCodesNgConfig } from '../baza-phone-country-codes-ng.config';
import { genericRetryStrategy } from '../../../baza-common';

@Injectable()
export class BazaPhoneCountryCodesService {
    private _repository$: BehaviorSubject<PhoneCountryCodesRepositoryDto> = new BehaviorSubject<PhoneCountryCodesRepositoryDto>([]);

    constructor(
        private readonly moduleConfig: BazaPhoneCountryCodesNgConfig,
        private readonly endpoint: BazaPhoneCountryCodesDataAccess,
    ) {
    }

    get ttlMilliseconds(): number {
        return this.moduleConfig.ttlMilliseconds;
    }

    get repository(): PhoneCountryCodesRepositoryDto {
        return this._repository$.getValue();
    }

    set repository(newRepository: PhoneCountryCodesRepositoryDto) {
        this._repository$.next(newRepository);
    }

    get repository$(): Observable<PhoneCountryCodesRepositoryDto> {
        return this._repository$.asObservable();
    }

    refresh(): Observable<PhoneCountryCodesRepositoryDto> {
        return this.endpoint.repository().pipe(
            retryWhen(genericRetryStrategy()),
            tap((response) => this.repository = response),
        );
    }
}
