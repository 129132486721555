<div class="personal">
    <div class="info-header">
        <div
            class="info-header__title"
            [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'title')"></div>
        <div class="info-header__icon">
            <a
                nz-popover
                nzPopoverPlacement="right"
                [nzPopoverContent]="tooltipTemplate">
                <i class="icon icon-edit icon-wt"></i>
            </a>

            <ng-template #tooltipTemplate>
                <p>
                    {{ wts.getI18nLabel(this.i18nBasePath, 'popover.message') }}
                </p>

                <a (click)="editPersonalInformation()">
                    {{ wts.getI18nLabel(this.i18nBasePath, 'popover.actions.edit') }}
                </a>
            </ng-template>
        </div>
    </div>

    <ul class="info-list">
        <li class="info-list__item">
            <div
                class="info-list__label"
                [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'details.name') | sanitizeHtml"></div>
            <div
                class="info-list__text"
                [innerHTML]="info?.firstName + (info?.lastName ? ' ' + info?.lastName : '')"></div>
        </li>

        <li class="info-list__item">
            <div
                class="info-list__label"
                [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'details.dateOfBirth') | sanitizeHtml"></div>
            <div
                class="info-list__text"
                [innerHTML]="info?.dateOfBirth"></div>
        </li>

        <li class="info-list__item">
            <div
                class="info-list__label"
                [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'details.address') | sanitizeHtml"></div>
            <div
                class="info-list__text"
                [innerHTML]="formattedAddress"></div>
        </li>

        <li class="info-list__item">
            <ng-container [ngSwitch]="!!info?.ssn">
                <ng-container *ngSwitchCase="true">
                    <div
                        class="info-list__label"
                        [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'details.ssn') | sanitizeHtml"></div>
                    <div
                        class="info-list__text"
                        [innerHTML]="info?.ssn"></div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div
                        class="info-list__label"
                        [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'details.document') | sanitizeHtml"></div>
                    <div class="info-list__text">
                        <a
                            [href]="info?.ssnDocumentUrl"
                            target="_blank">
                            {{ info?.ssnDocumentFileName }}
                        </a>
                    </div>
                </ng-container>
            </ng-container>
        </li>
    </ul>
</div>
