import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * The AppearDirective emits an event when the associated element appears on the viewport (i.e when it becomes visible to the user).
 * The directive listens to scroll and resize events, and checks the visibility of the element every time one of these events occur.
 * The directive uses a debouncer to avoid emitting the event too many times.
 *
 * @example
 * <div appElementAppear (appear)="onAppear()"></div>
 *
 * onAppear() {
 *   console.log('Element has appeared on screen');
 * }
 */
@UntilDestroy()
@Directive({
    selector: '[appElementAppear]',
})
export class AppearDirective implements AfterViewInit, OnDestroy {
    @Output()
    public appear: EventEmitter<void> = new EventEmitter<void>();

    private debouncer: Subject<void> = new Subject<void>();

    private elementPos: number;
    private elementHeight: number;

    private scrollPos: number;
    private windowHeight: number;

    private subscriptionScroll: Subscription;
    private subscriptionResize: Subscription;

    constructor(private element: ElementRef) {
        this.debouncer.pipe(untilDestroyed(this), debounceTime(100)).subscribe(() => this.appear.emit());
    }

    saveDimensions() {
        this.elementPos = this.getOffsetTop(this.element.nativeElement);
        this.elementHeight = this.element.nativeElement.offsetHeight;
        this.windowHeight = window.innerHeight;
    }

    saveScrollPos() {
        this.scrollPos = window.scrollY;
    }

    getOffsetTop(element) {
        let offsetTop = element.offsetTop || 0;
        if (element.offsetParent) {
            offsetTop += this.getOffsetTop(element.offsetParent);
        }
        return offsetTop;
    }

    checkVisibility() {
        this.saveDimensions();
        if (this.isVisible()) {
            this.debouncer.next();
        }
    }

    isVisible() {
        return this.scrollPos >= this.elementPos || this.scrollPos + this.windowHeight >= this.elementPos + this.elementHeight;
    }

    subscribe() {
        this.subscriptionScroll = fromEvent(window, 'scroll').subscribe(() => {
            this.saveScrollPos();
            this.checkVisibility();
        });
        this.subscriptionResize = fromEvent(window, 'resize').subscribe(() => {
            this.saveDimensions();
            this.checkVisibility();
        });
    }

    unsubscribe() {
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }
        if (this.subscriptionResize) {
            this.subscriptionResize.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe();
    }
}
