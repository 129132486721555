import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationFeedPostDto } from '../dto/baza-nc-integration-feed-post.dto';
import { IsIn, IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcIntegrationFeedListFilter } from '../models/baza-nc-integration-feed-list-filter';

export enum FeedEndpointPaths {
    list = '/baza-nc-integration/feed/list',
    getById = '/baza-nc-integration/feed/getById',
    applause = '/baza-nc-integration/feed/applause',
    unapplause = '/baza-nc-integration/feed/unapplause',
    markAllAsRead = '/baza-nc-integration/feed/markAllAsRead',
    hasAnyUpdates = '/baza-nc-integration/feed/hasAnyUpdates',
    s3ImageUrl = '/baza-nc-integration/feed/image/:sid',
    s3VideoUrl = '/baza-nc-integration/feed/video/:sid',
    getBySlug = '/baza-nc-integration/feed/getBySlug/:slug',
}

export interface BazaNcIntegrationFeedEndpoint {
    list(request: FeedListRequest, user?: unknown): Promise<FeedListResponse> | Observable<FeedListResponse>;
    getById(request: FeedGetByIdRequest, user?: unknown): Promise<FeedGetByIdResponse> | Observable<FeedGetByIdResponse>;
    applause(request: FeedApplauseRequest, user?: unknown): Promise<FeedApplauseResponse> | Observable<FeedApplauseResponse>;
    unapplause(request: FeedUnapplauseRequest, user?: unknown): Promise<FeedUnapplauseResponse> | Observable<FeedUnapplauseResponse>;
    markAllAsRead(
        request: FeedMarkAllAsReadRequest,
        user?: unknown,
    ): Promise<FeedMarkAllAsReadResponse> | Observable<FeedMarkAllAsReadResponse>;
    hasAnyUpdates(user?: unknown): Promise<FeedHasAnyUpdatesResponse> | Observable<FeedHasAnyUpdatesResponse>;
    s3ImageUrl?(sid: string, ...args: unknown[]): Promise<void> | Observable<void>;
    s3VideoUrl?(sid: string, ...args: unknown[]): Promise<void> | Observable<void>;
    getBySlug(
        request: BazaNcIntegrationFeedGetBySlugRequest,
        ...args: unknown[]
    ): Promise<BazaNcIntegrationFeedPostDto> | Observable<BazaNcIntegrationFeedPostDto>;
}

export class BazaNcIntegrationFeedGetBySlugRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    slug: string;
}

export class FeedListRequest {
    @ApiModelProperty({
        description: 'Provide last visible / fetched id of feed to provide proper pagination',
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    lastId?: number;

    @ApiModelProperty({
        description: 'How many posts you need to fetch (i.e. limit)',
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    take?: number;

    @ApiModelProperty({
        description:
            'Filter mode. ' +
            '"public-only" – Display only public posts which has no associated listings. ' +
            '"listing-only" - Display only posts which has associated listings. ' +
            '"purchased-only" – Display only posts which has associated listings with purchases shares by current user',
        type: 'string',
        required: false,
        enum: Object.values(BazaNcIntegrationFeedListFilter),
    })
    @IsIn(Object.values(BazaNcIntegrationFeedListFilter))
    @IsOptional()
    filter?: BazaNcIntegrationFeedListFilter;
}

export class FeedListResponse {
    @ApiModelProperty({
        description: 'List of posts',
        type: BazaNcIntegrationFeedPostDto,
        isArray: true,
    })
    posts: Array<BazaNcIntegrationFeedPostDto>;

    @ApiModelProperty({
        description: 'Total count of posts',
    })
    total: number;

    @ApiModelProperty({
        description: 'Indicated that there is no more posts available and no additional pagination needed',
    })
    isEndOfFeed: boolean;
}

export class FeedGetByIdRequest {
    @ApiModelProperty({
        description: 'Id of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class FeedGetByIdResponse {
    @ApiModelProperty({
        description: 'Post',
        type: BazaNcIntegrationFeedPostDto,
    })
    post: BazaNcIntegrationFeedPostDto;
}

export class FeedApplauseRequest {
    @ApiModelProperty({
        description: 'Id of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class FeedApplauseResponse {
    @ApiModelProperty({
        description:
            'Indicates is applause applied or not. Usually applause can be marked as not applied because of duplicate applause on the same post',
    })
    isApplied: boolean;

    @ApiModelProperty({
        description:
            'Total applause count of the post. It can be more than just +1 from the current value because of parallel applause requests within the current applause request',
    })
    applause: number;
}

export class FeedUnapplauseRequest {
    @ApiModelProperty({
        description: 'Id of post',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class FeedUnapplauseResponse {
    @ApiModelProperty({
        description:
            'Indicates is unapplause applied or not. Usually unapplause can be marked as not applied because of duplicate unapplause on the same post',
    })
    isApplied: boolean;

    @ApiModelProperty({
        description:
            'Total applause count of the post. It can be less than just -1 from the current value because of parallel applause requests within the current applause request',
    })
    applause: number;
}

export class FeedMarkAllAsReadRequest {
    @ApiModelProperty({
        description: 'ID of last fetched (seen) post',
    })
    @IsNumber()
    lastSeenId: number;
}

export class FeedMarkAllAsReadResponse {
    success: boolean;
}

export class FeedHasAnyUpdatesResponse {
    @ApiModelProperty({
        description: 'Indicates that there are some updates in feed',
    })
    hasAnyUpdates: boolean;

    @ApiModelProperty({
        description: 'ID of last fetched (seen) post',
        required: false,
    })
    lastSeenId?: number;
}
