import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class CheckboxSchemaDefinitionPayload extends SchemaDefinitionPayload {
}

export class CheckboxSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.Checkbox],
    })
    type: BazaNcIntegrationSchemaType.Checkbox;

    @ApiModelProperty()
    payload: CheckboxSchemaDefinitionPayload;
}

