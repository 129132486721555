import { Injectable } from '@angular/core';
import { BazaDataAccessConfig } from './baza-data-access.config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { withoutEndingSlash } from '../utils/without-ending-slash.util';
import { withoutTrailingSlash } from '../utils/without-trailing-slash.util';
import { ngBazaError } from '../utils/ng-baza-error.util';

@Injectable({
    providedIn: 'root',
})
export class BazaDataAccessService
{
    constructor(
        private readonly moduleConfig: BazaDataAccessConfig,
        private readonly http: HttpClient,
    ) {}

    get baseUrl(): string {
        return withoutEndingSlash(this.moduleConfig.apiEndpointUrl);
    }

    url(endpoint: any): string {
        return `${this.baseUrl}/${withoutTrailingSlash(endpoint as any)}`;
    }

    path(path: string, args: Record<string, unknown>): string {
        let interpolated = path;

        for (const key of Object.keys(args)) {
            while (interpolated.includes(`/:${key}/`)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                interpolated = interpolated.replace(`/:${key}/`, args[key]);
            }

            while (interpolated.endsWith(`/:${key}`)) {
                interpolated = `${interpolated.slice(0, interpolated.length - key.length - 2)}/${args[key]}`;
            }
        }

        return interpolated;
    }

    get<R, T = any>(endpoint: T, request?: any): Observable<R> {
        return this.http.get<R>(this.url(endpoint), {
            params: request,
        }).pipe(
            ngBazaError(),
        );
    }

    post<R, T = any>(endpoint: T, request: any = {}, options?: any): Observable<R> {
        return this.http.post<R>(this.url(endpoint), request, options).pipe(
            ngBazaError(),
        );
    }

    patch<R, T = any>(endpoint: T, request: any = {}): Observable<R> {
        return this.http.patch<R>(this.url(endpoint), request).pipe(
            ngBazaError(),
        );
    }

    put<R, T = any>(endpoint: T, request: any): Observable<R> {
        return this.http.put<R>(this.url(endpoint), request).pipe(
            ngBazaError(),
        );
    }

    head<R, T = any>(endpoint: T): Observable<R> {
        return this.http.head<R>(this.url(endpoint)).pipe(
            ngBazaError(),
        );
    }

    delete<R, T = any>(endpoint: T): Observable<R> {
        return this.http.delete<R>(this.url(endpoint)).pipe(
            ngBazaError(),
        );
    }
}
