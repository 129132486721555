import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDwollaDistributionPaymentSource } from '../models/baza-dwolla-distribution-payment-source';
import { BazaDwollaDistributionPaymentStatus } from '../models/baza-dwolla-distribution-payment-status';
import { ulid } from 'ulid';

export class BazaDwollaDistributionTransactionEntryCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'Date Created At',
        example: '12/01/2022',
    })
    dateCreatedAt: string;

    @ApiModelProperty({
        description: 'Date Updated At',
        example: '12/01/2022',
        required: false,
    })
    dateUpdatedAt?: string;

    @ApiModelProperty({
        description: 'Date Processed At',
        example: '12/01/2022',
        required: false,
    })
    dateProcessedAt?: string;

    @ApiModelProperty({
        description: 'Date',
        example: '12/01/2022',
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaDwollaDistributionPaymentSource),
    })
    source: BazaDwollaDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Account ID)',
    })
    ncAccountId: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Party ID)',
    })
    ncPartyId: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Status',
        enum: Object.values(BazaDwollaDistributionPaymentStatus),
        example: BazaDwollaDistributionPaymentStatus.Pending,
    })
    status: BazaDwollaDistributionPaymentStatus;
}
