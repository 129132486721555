import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DwollaAmount } from '@scaliolabs/baza-dwolla-shared';
import { BazaWebUtilSharedService, PaymentButtonStyleEnum, UtilModule } from '@scaliolabs/baza-web-utils';
import { PaymentAccountBalanceConfig } from './models';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
    selector: 'app-payment-account-balance',
    templateUrl: './payment-account-balance.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzButtonModule],
})
export class PaymentAccountBalanceComponent {
    @Input()
    srcData?: DwollaAmount;

    @Input()
    config?: PaymentAccountBalanceConfig;

    public i18nBasePath = 'uic.paymentAccountBalance';
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
