import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { AccountType } from '../models/account-type';
import { EntityType } from '../models/entity-type';
import { DomesticYN } from '../models/domestic-yn';
import { KYCStatus } from '../models/kyc-status';
import { AmlStatus } from '../models/aml-status';
import { NorthCapitalDateTime, NorthCapitalYesNoBoolean } from '../common-types';
import { SuitabilityScore } from '../models/suitability-score';
import { ApprovalStatus } from '../models/approval-status';
import { NorthCapitalAccountId } from '../models/account-id';
import { AccreditedStatus } from '../models/accredited-status';
import { Allow } from '../models/allow';

export class UpdateAccountRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    accountRegistration: string; /* Please do not make it optional. NC do reset it if you don't provide it */

    @IsEnum(AccountType)
    @IsOptional()
    type?: AccountType;

    @IsEnum(EntityType)
    @IsOptional()
    entityType?: EntityType;

    @IsEnum(DomesticYN)
    @IsOptional()
    domesticYN?: DomesticYN;

    @IsString()
    @IsOptional()
    streetAddress1?: string;

    @IsString()
    @IsOptional()
    streetAddress2?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    state?: string;

    @IsString()
    @IsOptional()
    zip?: string;

    @IsString()
    @IsOptional()
    country?: string;

    @IsString()
    @IsOptional()
    email?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsInt()
    @IsOptional()
    taxID?: number;

    @IsEnum(KYCStatus)
    @IsOptional()
    KYCstatus?: KYCStatus;

    @IsEnum(AmlStatus)
    @IsOptional()
    AMLstatus?: AmlStatus;

    @IsString()
    @IsOptional()
    AMLdate?: NorthCapitalDateTime;

    @IsEnum(SuitabilityScore)
    @IsOptional()
    suitabilityScore?: SuitabilityScore;

    @IsString()
    @IsOptional()
    suitabilityDate?: NorthCapitalDateTime;

    @IsString()
    @IsOptional()
    suitabilityApprover?: string;

    @IsEnum(AccreditedStatus)
    @IsOptional()
    AccreditedStatus?: AccreditedStatus;

    @IsEnum(Allow)
    @IsOptional()
    Allow?: Allow;

    @IsString()
    @IsOptional()
    AIdate?: NorthCapitalDateTime;

    @IsNumber()
    @IsOptional()
    '506cLimit'?: number;

    @IsInt()
    @IsOptional()
    accountTotalLimit?: number;

    @IsInt()
    @IsOptional()
    singleInvestmentLimit?: number;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    associatedAC?: NorthCapitalYesNoBoolean;

    @IsEnum(NorthCapitalYesNoBoolean)
    @IsOptional()
    syndicate?: NorthCapitalYesNoBoolean;

    @IsString()
    @IsOptional()
    tags?: string;

    @IsString()
    @IsOptional()
    notes?: string;

    @IsEnum(ApprovalStatus)
    @IsOptional()
    approvalStatus?: ApprovalStatus;

    @IsString()
    @IsOptional()
    approvalPrincipal?: string;

    @IsString()
    @IsOptional()
    approvalLastReview?: NorthCapitalDateTime;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;
}
