import { Observable } from 'rxjs';
import { CategoriesDto } from '../dto/categories.dto';
import { CategoryDto } from '../dto/category.dto';

export enum CategoriesEndpointPaths {
    get = '/baza-content-types/categories/get/:urlOrId',
    getAll = '/baza-content-types/categories/getAll',
}

export interface CategoriesEndpoint {
    get(urlOrId: string | number): Promise<CategoryDto> | Observable<CategoryDto>;
    getAll(): Promise<CategoriesDto> | Observable<CategoriesDto>;
}
