import { NgModule } from '@angular/core';
import { BazaDataAccessModule } from '../../../../ng/src/lib/baza-data-access.module';
import { BazaAuthDataAccess } from './ng/baza-auth.data-access';
import { BazaAuthMasterPasswordDataAccess } from './ng/baza-auth-master-password.data-access';
import { BazaAuth2FADataAccess } from './ng/baza-auth-2fa.data-access';
import { BazaAuth2FASettingsDataAccess } from './ng/baza-auth-2fa-settings.data-access';

@NgModule({
    imports: [BazaDataAccessModule],
    providers: [BazaAuthDataAccess, BazaAuthMasterPasswordDataAccess, BazaAuth2FADataAccess, BazaAuth2FASettingsDataAccess],
})
export class BazaAuthDataAccessModule {}
