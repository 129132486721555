import { DwollaTransferStatus } from '@scaliolabs/baza-dwolla-shared';

export enum BazaNcDividendPaymentStatus {
    Pending = 'Pending',
    Processed = 'Processed',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export const bazaNcDividendNonDraftPaymentStatuses: Array<BazaNcDividendPaymentStatus> = [
    BazaNcDividendPaymentStatus.Processed,
    BazaNcDividendPaymentStatus.Failed,
    BazaNcDividendPaymentStatus.Cancelled,
];

export const bazaNcDividendProcessedPaymentStatuses: Array<BazaNcDividendPaymentStatus> = [
    BazaNcDividendPaymentStatus.Failed,
    BazaNcDividendPaymentStatus.Cancelled,
    BazaNcDividendPaymentStatus.Processed,
];
export const bazaNcDividendReprocessablePaymentStatuses: Array<BazaNcDividendPaymentStatus> = [
    BazaNcDividendPaymentStatus.Failed,
    BazaNcDividendPaymentStatus.Cancelled,
];

export const bazaNcDividendUpdatablePaymentStatuses: Array<BazaNcDividendPaymentStatus> = [
    BazaNcDividendPaymentStatus.Pending,
    ...bazaNcDividendReprocessablePaymentStatuses,
];

export const bazaNcDividendMapDwollaTransferToPaymentStatuses: Array<{
    dwolla: DwollaTransferStatus;
    status: BazaNcDividendPaymentStatus;
}> = [
    {
        dwolla: DwollaTransferStatus.Pending,
        status: BazaNcDividendPaymentStatus.Pending,
    },
    {
        dwolla: DwollaTransferStatus.Processed,
        status: BazaNcDividendPaymentStatus.Processed,
    },
    {
        dwolla: DwollaTransferStatus.Failed,
        status: BazaNcDividendPaymentStatus.Failed,
    },
    {
        dwolla: DwollaTransferStatus.Cancelled,
        status: BazaNcDividendPaymentStatus.Cancelled,
    },
];
