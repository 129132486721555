import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaCreditCardType } from '@scaliolabs/baza-core-shared';

/**
 * Credit Card DTO (Purchase Flow)
 * @deprecated Use new Baza NC Credit Card API instead of Purchase Flow implementation
 */
export class BazaNcPurchaseFlowCreditCardDto {
    @ApiModelProperty({
        description: 'Credit Card Type',
        example: BazaCreditCardType.Visa,
    })
    creditCardType: BazaCreditCardType;

    @ApiModelProperty({
        description: 'Credit Card Number',
        example: '12345567890123456',
    })
    creditCardNumber: string;

    @ApiModelProperty({
        description: 'Credit Card Holder Name',
        example: 'JOHN DOE',
    })
    creditCardholderName: string;

    @ApiModelProperty({
        type: 'number',
        description: 'Expire Month (1 - Jan, 2 - Feb, ..., 12 - Dec)',
        example: 6,
        minimum: 1,
        maximum: 12,
    })
    creditCardExpireMonth: number;

    @ApiModelProperty({
        type: 'number',
        description: 'Expire Year (20, 21, 22, ...)',
    })
    creditCardExpireYear: number;
}
