import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const STATE_KEY_PREFIX = 'http_requests:';

@Injectable()
export class TransferHttpResponseInterceptor implements HttpInterceptor {
    constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: string) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const key = makeStateKey<HttpResponse<Record<string, unknown>>>(STATE_KEY_PREFIX + req.url);

        if (isPlatformBrowser(this.platformId)) {
            // Reusing transferred response from server
            const cachedResponse = this.transferState.get(key, null);
            if (cachedResponse) {
                this.transferState.remove(key); // cached response should be used for the very first time
                return of(
                    new HttpResponse({
                        body: cachedResponse.body,
                        status: 200,
                        statusText: 'OK (from server)',
                        // headers are not transferred by current implementation.
                    }),
                );
            }
            return next.handle(req);
        } else {
            // Saving response to be transferred to browser
            return next.handle(req).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse && (event.status === 200 || event.status === 201)) {
                        const response = {
                            body: event.body,
                        };
                        this.transferState.set(key, response);
                    }
                }),
            );
        }
    }
}
