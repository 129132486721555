export enum BazaNorthCapitalOpenApi {
    BazaNorthCapitalWebhooks = 'Baza NC Webhooks',
    BazaNorthCapitalAccountVerification = 'Baza NC Account Verification',
    BazaNorthCapitalPurchaseFlow = 'Baza NC Purchase Flow',
    BazaNorthCapitalPurchaseFlowBankAccount = 'Baza NC Purchase Flow (Bank Account)',
    BazaNorthCapitalPurchaseFlowCreditCard = 'Baza NC Purchase Flow (Credit Card)',
    BazaNorthCapitalPurchaseFlowLimits = 'Baza NC Purchase Flow (Limits)',
    BazaNorthCapitalTransactions = 'Baza NC Transactions',
    BazaNorthCapitalInvestorAccount = 'Baza NC Investor Account',
    BazaNorthCapitalTaxDocument = 'Baza NC Tax Document',
    BazaNorthCapitalDividend = 'Baza NC Dividend',
    BazaNorthCapitalPayoffDistribution = 'Baza NC PayOff Distribution',
    BazaNorthCapitalBankAccounts = 'Baza NC Bank Accounts',
    BazaNorthCapitalDwolla = 'Baza NC Dwolla Integration',
    BazaNorthCapitalWithdraw = 'Baza NC Withdraw',
    BazaNorthCapitalTransfer = 'Baza NC Transfer',
    BazaNorthCapitalBootstrap = 'Baza NC Bootstrap',
    BazaNorthCapitalOperation = 'Baza NC Operation',
    BazaNorthCapitalCreditCard = 'Baza NC Credit Card',
    BazaNorthCapitalRecurringTransactions = 'Baza NC Recurring Transactions',
}

export enum BazaNorthCapitalCMSOpenApi {
    BazaNorthCapitalE2e = 'Baza NC E2E Helpers',
    BazaNorthCapitalInvestorAccountCMS = 'Baza NC Investor Account CMS',
    BazaNorthCapitalAccountVerificationCMS = 'Baza NC Account Verification CMS',
    BazaNorthCapitalTransactionsCMS = 'Baza NC Transactions CMS',
    BazaNorthCapitalOfferingCMS = 'Baza NC Offering CMS',
    BazaNorthCapitalSyncCMS = 'Baza NC Sync CMS',
    BazaNorthCapitalTaxDocumentCMS = 'Baza NC Tax Document CMS',
    BazaNorthCapitalDocusignCMS = 'Baza NC DocuSign CMS',
    BazaNorthCapitalDividendCMS = 'Baza NC Dividend CMS',
    BazaNorthCapitalDividendTransactionCMS = 'Baza NC Dividend Transaction CMS',
    BazaNorthCapitalDividendTransactionEntriesCMS = 'Baza NC Dividend Transaction Entries CMS',
    BazaNorthCapitalPayoffDistributionCMS = 'Baza NC PayOff Distribution CMS',
    BazaNorthCapitalPayoffDistributionTransactionCMS = 'Baza NC PayOff Distribution Transaction CMS',
    BazaNorthCapitalPayoffDistributionTransactionEntriesCMS = 'Baza NC PayOff Distribution Transaction Entries CMS',
    BazaNorthCapitalDwollaCMS = 'Baza NC Dwolla Integration CMS',
    BazaNorthCapitalReportCMS = 'Baza NC Daily Report CMS',
    BazaNorthCapitalRecurringTransactionCMS = 'Baza NC Recurring Transaction CMS',
}

export const bazaNorthCapitalApiTags: Array<string> = [
    ...Object.values(BazaNorthCapitalOpenApi),
    ...Object.values(BazaNorthCapitalCMSOpenApi),
];
