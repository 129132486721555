import { Injectable } from '@angular/core';
import {
    BazaNcPurchaseFlowEndpoint,
    BazaNcPurchaseFlowEndpointPaths,
    CalculateRegulationLimitsRequestDto,
    DestroySessionDto,
    OfferingId,
    PurchaseFlowCurrentDto,
    PurchaseFlowDestroyResponse,
    PurchaseFlowDto,
    PurchaseFlowPersonalInfoDto,
    PurchaseFlowSessionDto,
    PurchaseFlowSubmitResponse,
    RegulationLimitsDto,
    ReProcessPaymentDto,
    ReProcessPaymentResponse,
    StatsDto,
    StatsRequestDto,
    SubmitPurchaseFlowDto,
    ValidateResponseDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNcPurchaseFlowDataAccess implements BazaNcPurchaseFlowEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns current status of Purchase Flow and Purchase Flow DTO, if session is started
     * Also updates TTL of session, if available.
     * @param offeringId
     */
    current(offeringId: OfferingId): Observable<PurchaseFlowCurrentDto> {
        return this.http.get(replacePathArgs(BazaNcPurchaseFlowEndpointPaths.current, { offeringId }));
    }

    /**
     * Stats for Purchase Flow
     * @param request
     */
    stats(request: StatsRequestDto): Observable<StatsDto> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.stats, request);
    }

    /**
     * Calculate regulation limits for offering
     * @param request
     */
    calculateRegulationLimits(request: CalculateRegulationLimitsRequestDto): Observable<RegulationLimitsDto> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.calculateRegulationLimits, request);
    }

    /**
     * Validates session request before submitting it to North Capital
     * @param request
     */
    validate(request: PurchaseFlowSessionDto): Observable<ValidateResponseDto> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.validate, request);
    }

    /**
     * Returns existing session or creates a new purchase flow session
     * @param request
     * @param args
     */
    session(request: PurchaseFlowSessionDto): Observable<PurchaseFlowDto> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.session, request);
    }

    /**
     * Submits (Finishes) Purchase. Current Purchase Flow session will be destroyed on successful Submit.
     * @param request
     * @param args
     */
    submit(request: SubmitPurchaseFlowDto): Observable<PurchaseFlowSubmitResponse> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.submit, request);
    }

    /**
     * Destroys all existing Purchase Flow Sessions with given parameters
     * @param request
     * @param args
     */
    destroy(request: DestroySessionDto): Observable<PurchaseFlowDestroyResponse> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.destroy, request);
    }

    /**
     * Helper method which returns data for Personal Information block
     */
    getPersonalInfo(): Observable<PurchaseFlowPersonalInfoDto> {
        return this.http.get(BazaNcPurchaseFlowEndpointPaths.getPersonalInfo);
    }

    /**
     * Reprocesses Failed Payment
     * @param request
     */
    reprocessPayment(request: ReProcessPaymentDto): Observable<ReProcessPaymentResponse> {
        return this.http.post(BazaNcPurchaseFlowEndpointPaths.reprocessPayment, request);
    }
}
