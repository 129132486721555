import {
    BAZA_PASSWORD_MAX_LENGTH,
    BAZA_PASSWORD_MIN_LENGTH,
    BAZA_PASSWORD_MIN_OPTIONAL_TESTS_TO_PASS,
    BAZA_PASSWORD_MIN_PHRASE_LENGTH,
    BAZA_PASSWORD_RESOURCES_DIGITS,
    BAZA_PASSWORD_RESOURCES_LOWERCASE,
    BAZA_PASSWORD_RESOURCES_SPECIAL,
    BAZA_PASSWORD_RESOURCES_UPPERCASE,
} from '../constants/baza-password.constants';

export enum BazaPasswordValidator {
    BazaMinCharacters = '{{ minPasswordLength }} characters',
    BazaMaxCharacters = '{{ maxPasswordLength }} characters',
    Baza1UppercaseCharacter = '1 uppercase letter',
    Baza1LowercaseCharacter = '1 lowercase letter',
    Baza1Number = '1 number',
    Baza1SpecialCharacter = '1 special character',
}

export enum BazaPasswordMandatory {
    /**
     * Validator is required and password validation WILL fails if input does not match requirements
     */
    Mandatory = 'Mandatory',

    /**
     * Validator is recommended and password validation WILL NOT fails if input does not match requirements
     */
    Recommended = 'Recommended',

    /**
     * Validator is optional and password validation WILL NOT fails if input does not match requirements
     * It works same as Recommended, but differences between Optional and Recommended can be used for UI reasons
     */
    Optional = 'Optional',
}

export enum BazaPasswordDifficultLevel {
    Easy = 'easy',
    Normal = 'normal',
    Hard = 'hard',
}

export interface BazaPasswordValidatorFunc {
    type: BazaPasswordValidator;
    mandatory: BazaPasswordMandatory;
    validator: (input: string) => boolean;
}

export interface BazaPasswordValidatorInjects {
    allowPassphrases: boolean;
    minPasswordLength: number;
    maxPasswordLength: number;
    minPhraseLength: number;
    minOptionalTestsToPass: number;
    resourcesUppercase: string;
    resourcesLowercase: string;
    resourcesDigits: string;
    resourcesSpecial: string;
}

/**
 * Mandatory/Recommended/Optional configuration for different password validators
 */
export const bazaPasswordValidatorsMandatory: Array<{
    type: BazaPasswordValidator;
    mandatory: BazaPasswordMandatory | string;
}> = [
    {
        type: BazaPasswordValidator.BazaMinCharacters,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
    {
        type: BazaPasswordValidator.BazaMaxCharacters,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
    {
        type: BazaPasswordValidator.Baza1UppercaseCharacter,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
    {
        type: BazaPasswordValidator.Baza1LowercaseCharacter,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
    {
        type: BazaPasswordValidator.Baza1Number,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
    {
        type: BazaPasswordValidator.Baza1SpecialCharacter,
        mandatory: BazaPasswordMandatory.Mandatory,
    },
];

/**
 * Default password validators
 * Used by `bazaPasswordConfigTool`
 * @see bazaPasswordConfigTool
 */
export function bazaPasswordValidatorFactory(type: BazaPasswordValidator): BazaPasswordValidatorFunc {
    switch (type) {
        default: {
            throw new Error(`Unknown password validator "${type}"`);
        }

        case BazaPasswordValidator.BazaMinCharacters: {
            return {
                type: BazaPasswordValidator.BazaMinCharacters,
                validator: (input) => input.length >= BAZA_PASSWORD_VALIDATOR_INJECTS.minPasswordLength,
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }

        case BazaPasswordValidator.BazaMaxCharacters: {
            return {
                type: BazaPasswordValidator.BazaMaxCharacters,
                validator: (input) => input.length <= BAZA_PASSWORD_VALIDATOR_INJECTS.maxPasswordLength,
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }

        case BazaPasswordValidator.Baza1UppercaseCharacter: {
            return {
                type: BazaPasswordValidator.Baza1UppercaseCharacter,
                validator: (input) => BAZA_PASSWORD_VALIDATOR_INJECTS.resourcesUppercase.split('').some((char) => input.includes(char)),
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }

        case BazaPasswordValidator.Baza1LowercaseCharacter: {
            return {
                type: BazaPasswordValidator.Baza1LowercaseCharacter,
                validator: (input) => BAZA_PASSWORD_VALIDATOR_INJECTS.resourcesLowercase.split('').some((char) => input.includes(char)),
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }

        case BazaPasswordValidator.Baza1Number: {
            return {
                type: BazaPasswordValidator.Baza1Number,
                validator: (input) => BAZA_PASSWORD_VALIDATOR_INJECTS.resourcesDigits.split('').some((char) => input.includes(char)),
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }

        case BazaPasswordValidator.Baza1SpecialCharacter: {
            return {
                type: BazaPasswordValidator.Baza1SpecialCharacter,
                validator: (input) => BAZA_PASSWORD_VALIDATOR_INJECTS.resourcesSpecial.split('').some((char) => input.includes(char)),
                mandatory: BazaPasswordMandatory.Mandatory,
            };
        }
    }
}

/**
 * Exported resource which is used for Baza Password validations
 */
export const BAZA_PASSWORD_VALIDATOR_INJECTS: BazaPasswordValidatorInjects & {
    validators: Array<BazaPasswordValidatorFunc>;
} = {
    allowPassphrases: true,
    minPasswordLength: BAZA_PASSWORD_MIN_LENGTH,
    maxPasswordLength: BAZA_PASSWORD_MAX_LENGTH,
    minPhraseLength: BAZA_PASSWORD_MIN_PHRASE_LENGTH,
    minOptionalTestsToPass: BAZA_PASSWORD_MIN_OPTIONAL_TESTS_TO_PASS,
    resourcesUppercase: BAZA_PASSWORD_RESOURCES_UPPERCASE,
    resourcesLowercase: BAZA_PASSWORD_RESOURCES_LOWERCASE,
    resourcesDigits: BAZA_PASSWORD_RESOURCES_DIGITS,
    resourcesSpecial: BAZA_PASSWORD_RESOURCES_SPECIAL,
    validators: Object.values(BazaPasswordValidator).map((next) => bazaPasswordValidatorFactory(next)),
};
