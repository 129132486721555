import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { OfferingId } from '../models/offering-id';
import { NorthCapitalNumberAsString } from '../common-types';
import { NorthCapitalFloatAsString } from '../common-types/north-capital-float-as-string';

export class UpdateEscrowAccountRequest {
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsString()
    @IsOptional()
    idNumber?: string;

    @IsNumber()
    @IsNotEmpty()
    overFundingAmount?: number;

    @IsString()
    @IsOptional()
    escrowAccountNumber?: NorthCapitalNumberAsString;

    @IsString()
    @IsOptional()
    routingNumber?: NorthCapitalNumberAsString;

    @IsString()
    @IsOptional()
    requestid?: string;

    @IsString()
    @IsOptional()
    overfundingamount?: NorthCapitalFloatAsString;

    @IsString()
    @IsNotEmpty()
    bankName?: string;

    @IsString()
    @IsOptional()
    offeringAccountNumber?: string;

    @IsString()
    @IsOptional()
    accountFullName?: string;
}
