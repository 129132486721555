import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import {
    BazaNcBootstrapDto,
    BazaNcPurchaseFlowLimitsForPurchaseResponse,
    BazaNcPurchaseFlowTransactionType,
    PurchaseFlowDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaLinkUtilSharedService, BazaWebUtilSharedService, LoaderService } from '@scaliolabs/baza-web-utils';
import { Observable, combineLatest, filter, map, of, switchMap, take, withLatestFrom } from 'rxjs';
import { GetLimitsForPurchase, PatchStartPurchase, PaymentMethodsConfig, PurchaseState, SubmitPurchase } from '../data-access';

@Component({
    selector: 'app-purchase-payment',
    templateUrl: './payment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasePaymentComponent implements OnInit, OnDestroy {
    @Input()
    initData?: BazaNcBootstrapDto;

    @Input()
    config?: PaymentMethodsConfig;

    cart$ = this.store.select(PurchaseState.cart);
    purchaseStart$ = this.store.select(PurchaseState.purchaseStart);
    numberOfShares$ = this.store.select(PurchaseState.numberOfShares);
    limitsForPurchase$ = this.store.select(PurchaseState.limitsForPurchase);

    isPurchaseAboveLimit = false;
    isSubmitBtnDisabled = true;

    paymentMethodAdded = false;
    i18nBasePath = 'dwpf.payment';
    pfConfig = this.store.selectSnapshot(PurchaseState.pfConfig);
    dataStream$: Observable<{
        purchaseStart?: PurchaseFlowDto;
        number?: number;
        entity?: BazaNcIntegrationListingsDto;
    }>;

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly loader: LoaderService,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnInit(): void {
        this.checkConfig();
        this.initializeDataStream();
    }

    initializeDataStream() {
        this.loader.show();

        this.dataStream$ = combineLatest([this.purchaseStart$, this.numberOfShares$, this.cart$]).pipe(
            map(([purchaseStart, number, entity]) => ({
                purchaseStart,
                number,
                entity,
            })),
            filter((pair) => !!pair.entity && !!pair.number && !!pair.purchaseStart),
            take(1),
            switchMap((pair) => {
                const total = (pair.number || 1) * pair.entity.pricePerShareCents;

                return this.store.dispatch(new GetLimitsForPurchase(total, pair.entity.offeringId)).pipe(
                    filter((res) => !!res),
                    withLatestFrom(this.limitsForPurchase$),
                    take(1),
                    switchMap(([_, limitsForPurchase]) => {
                        if (this.config?.showCreditCard) {
                            this.validateLimitsForPurchase(pair.purchaseStart, limitsForPurchase);
                        }
                        return of(pair);
                    }),
                );
            }),
        );
    }

    ngOnDestroy(): void {
        this.loader.hide();
    }

    checkConfig() {
        const defaultConfig: PaymentMethodsConfig = {
            showAccountBalance: this.pfConfig?.dwollaSupport,
            showBankAccount: true,
            showCreditCard: true,
        };

        this.config = this.wts.mergeConfig(defaultConfig, this.config);
    }

    // onFormSubmit
    public onFormSubmit(purchaseStart: PurchaseFlowDto): void {
        this.store.dispatch(new SubmitPurchase(purchaseStart.id)).subscribe(() => {
            this.wts.refreshInitData$.next();
            this.navigateToStep('done');
        });
    }

    /**
     * Utility method to validate the purchaseLimit
     */
    private validateLimitsForPurchase(purchaseStart: PurchaseFlowDto, limitsForPurchase: BazaNcPurchaseFlowLimitsForPurchaseResponse) {
        const { amount, transactionFeesCents } = purchaseStart;
        const totalCents = amount + transactionFeesCents;

        const ccLimits = limitsForPurchase.find((limit) => limit.transactionType === BazaNcPurchaseFlowTransactionType.CreditCard);
        this.isPurchaseAboveLimit =
            !ccLimits?.isAvailable ||
            // Based on active Payment method CC fees are added and Total changes.
            // So, Manually verifying if total goes above limit? Yes, restrict the CC.
            totalCents + ccLimits?.feeCents > ccLimits?.maximumAmountCents;
    }

    public onBackClick(purchaseStart: PurchaseFlowDto): void {
        purchaseStart.fee = null;
        this.store.dispatch(new PatchStartPurchase(purchaseStart)).subscribe(() => {
            this.navigateToStep('agreement');
        });
    }

    get authorizationLinkConfig(): string {
        return `${this.i18nBasePath}.authorization.linkConfig`;
    }

    onUpdateSubmitBtnDisableStatus(isDisabled: boolean) {
        this.isSubmitBtnDisabled = isDisabled;
    }

    navigateToStep(url: string) {
        this.router.navigate([this.pfConfig?.pfLink, url], {
            queryParamsHandling: 'preserve',
        });
    }
}
