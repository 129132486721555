import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { Observable } from 'rxjs';

export enum BazaInviteCodeEndpointPaths {
    validateInviteCode = '/baza-core/invite-codes/validateInviteCode',
    requestForInviteCodes = '/baza-core/invite-codes/requestForInviteCodes',
    countInviteCodes = '/baza-core/invite-codes/countInviteCodes/:code',
}

export interface BazaInviteCodeEndpoint {
    validateInviteCode(
        request: BazaValidateInviteCodeRequest,
    ): Promise<BazaValidateInviteCodeResponse> | Observable<BazaValidateInviteCodeResponse>;
    requestForInviteCodes(
        request: BazaRequestForInviteCodesRequest,
    ): Promise<BazaRequestForInviteCodesResponse> | Observable<BazaRequestForInviteCodesResponse>;
    countInviteCode(request: string): Promise<BazaInviteCodeCountResponse> | Observable<BazaInviteCodeCountResponse>;
}

export class BazaValidateInviteCodeRequest {
    @IsString()
    @IsNotEmpty()
    @ApiModelProperty({
        description: 'Invite code to validate',
    })
    code: string;
}

export class BazaValidateInviteCodeResponse {
    @ApiModelProperty({
        description: 'Indicated that the invite code can be used',
    })
    canBeUsed: boolean;

    @ApiModelProperty({
        description: 'Indicated that the invite code is exists',
    })
    isExists: boolean;

    @ApiModelProperty({
        description: 'Indicated that the invite code is completely consumed',
        required: false,
    })
    isConsumed?: boolean;
}

export class BazaRequestForInviteCodesRequest {
    @IsEmail(
        {},
        {
            message: 'Invalid format. Please, enter a valid e-mail address.',
        },
    )
    @IsNotEmpty()
    @ApiModelProperty({
        description: 'Email',
    })
    email: string;
}

export class BazaRequestForInviteCodesResponse {
    @ApiModelProperty({
        description: 'Indicated that actual email about request is sent',
    })
    isOrgNotifiedWithEmail: boolean;
}

export class BazaInviteCodeCountResponse {
    @ApiModelProperty({
        description: 'Returns the number of times the code was used',
    })
    total: number;
}
