import { NorthCapitalAccountId } from '../models/account-id';
import { TransactionType } from '../models/transaction-type';
import { RRApprovalStatus } from '../models/rr-approval-status';
import { PrincipalApprovalStatus } from '../models/principal-approval-status';
import { NorthCapitalDate } from '../common-types';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { OfferingId } from '../models/offering-id';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class CreateTradeRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;

    @IsEnum(TransactionType)
    @IsNotEmpty()
    transactionType: TransactionType;

    @IsString()
    @IsNotEmpty()
    transactionUnits: string;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;

    @IsString()
    @IsOptional()
    field1?: string;

    @IsString()
    @IsOptional()
    field2?: string;

    @IsString()
    @IsOptional()
    field3?: string;

    // @IsEnum(RRApprovalStatus)
    @IsOptional()
    RRApprovalStatus?: RRApprovalStatus;

    @IsString()
    @IsOptional()
    RRName?: string;

    @IsString()
    @IsOptional()
    RRApprovalDate?: NorthCapitalDate;

    // @IsEnum(PrincipalApprovalStatus)
    @IsOptional()
    PrincipalApprovalStatus?: PrincipalApprovalStatus;

    @IsString()
    @IsOptional()
    PrincipalName?: string;

    @IsString()
    @IsOptional()
    PrincipalDate?: NorthCapitalDate;

    @IsString()
    @IsOptional()
    closeId?: string;
}
