import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BazaNcCreditCardDto, BazaNcLimitsDto, PurchaseFlowBankAccountDto } from '@scaliolabs/baza-nc-shared';
import { PaymentMethodType } from '@scaliolabs/baza-web-purchase-flow';

@Component({
    selector: 'app-retry-nc-list',
    templateUrl: './retry-nc-list.component.html',
    styleUrls: ['./retry-nc-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryNcListComponent {
    @Input()
    selectedPaymentMethod!: PaymentMethodType;

    @Input()
    bankAccountResponse!: PurchaseFlowBankAccountDto;

    @Input()
    creditCardResponse!: BazaNcCreditCardDto;

    @Input()
    limits!: BazaNcLimitsDto;

    @Output()
    paymentMethodChange = new EventEmitter<PaymentMethodType>();

    PAYMENT_METHOD_TYPE = PaymentMethodType;

    get hasBankAccount(): boolean {
        return this.bankAccountResponse?.isAvailable;
    }

    get hasCreditCard(): boolean {
        return this.creditCardResponse?.isAvailable;
    }

    get hasMultiplePaymentMethods(): boolean {
        return this.hasBankAccount && this.hasCreditCard;
    }

    get formattedCardPaymentFee(): string {
        if (!this.limits) {
            return '';
        }

        const { creditCardPaymentsFee } = this.limits;
        return `+ ${creditCardPaymentsFee}% Fee`;
    }

    onBankAccountSelect() {
        this.paymentMethodChange.emit(PaymentMethodType.bankAccount);
    }

    onCreditCardSelect() {
        this.paymentMethodChange.emit(PaymentMethodType.creditCard);
    }
}
