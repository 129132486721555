import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { DwollaCustomerId, DwollaTransferId } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcPayoffDistributionPaymentStatus } from './baza-nc-payoff-distribution-payment-status';

export enum BazaNcPayoffDistributionPaymentHistoryAction {
    PaidWithNC = 'PaidWithNC',
    DwollaTransferInitiated = 'DwollaTransferInitiated',
    DwollaTransferStatusUpdated = 'DwollaTransferStatusUpdated',
}

export class BazaNcPayoffDistributionPaymentHistoryPaidWithNcAction {
    type: BazaNcPayoffDistributionPaymentHistoryAction.PaidWithNC;
}

export class BazaNcPayoffDistributionPaymentHistoryDwollaTransferInitiatedAction {
    type: BazaNcPayoffDistributionPaymentHistoryAction.DwollaTransferInitiated;
    dwollaCustomerId: DwollaCustomerId;
    dwollaTransferId: DwollaTransferId;
}

export class BazaNcPayoffDistributionPaymentHistoryDwollaTransferStatusUpdatedAction {
    type: BazaNcPayoffDistributionPaymentHistoryAction.DwollaTransferStatusUpdated;
    dwollaTransferId: DwollaTransferId;
    previousStatus: BazaNcPayoffDistributionPaymentStatus;
    newStatus: BazaNcPayoffDistributionPaymentStatus;
}

export type BazaNcPayoffDistributionPaymentHistoryActions =
    | BazaNcPayoffDistributionPaymentHistoryPaidWithNcAction
    | BazaNcPayoffDistributionPaymentHistoryDwollaTransferInitiatedAction
    | BazaNcPayoffDistributionPaymentHistoryDwollaTransferStatusUpdatedAction;

export const bazaNcPayoffDistributionPaymentHistorySwaggerExportedDtos = [
    BazaNcPayoffDistributionPaymentHistoryPaidWithNcAction,
    BazaNcPayoffDistributionPaymentHistoryDwollaTransferInitiatedAction,
];

export const bazaNcPayoffDistributionPaymentHistoryActionsSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaNcPayoffDistributionPaymentHistorySwaggerExportedDtos.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
