import { DocumentId } from '../models/document-id';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UpdateDocumentForOfferingRequest {
    @IsString()
    @IsNotEmpty()
    documentId: DocumentId;

    @IsString()
    @IsNotEmpty()
    documentTitle: string;

    @IsString()
    @IsNotEmpty()
    documentFileReferenceCode: string;

    @IsString()
    @IsNotEmpty()
    file_name: string;

    @IsString()
    @IsOptional()
    updatedIpAddress?: string;
}
