import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationFeedPostType } from '../models/baza-nc-integration-feed-post-type';
import { bazaSeoDefault, BazaSeoDto, CrudSortableEntity } from '@scaliolabs/baza-core-shared';
import { TagDto } from '@scaliolabs/baza-content-types-shared';
import { BazaNcIntegrationFeedDisplay } from '../models/baza-nc-integration-feed-display';
import { BazaNcIntegrationListingsListItemDto } from '../../../../listings/src';

export class BazaNcIntegrationFeedCmsPostDto implements CrudSortableEntity {
    @ApiModelProperty({
        description: 'ID of the post',
    })
    id: number;

    @ApiModelProperty({
        description: 'Sort Order',
    })
    sortOrder: number;

    @ApiModelProperty({
        description: 'Date published',
    })
    datePublishedAt: string;

    @ApiModelProperty({
        description: 'Is post published?',
    })
    isPublished: boolean;

    @ApiModelProperty({
        description: 'Display post to non-authenticated users',
    })
    allowPublicAccess: boolean;

    @ApiModelProperty({
        description: 'Post Type & Additional payload',
        type: 'string',
        enum: Object.values(BazaNcIntegrationFeedPostType),
    })
    type: BazaNcIntegrationFeedPostType;

    @ApiModelProperty({
        description: 'Title of the post',
    })
    title: string;

    @ApiModelProperty({
        description: 'Small text for post preview',
        required: false,
    })
    intro?: string;

    @ApiModelProperty({
        description: 'Full contents of the post. Contains HTML.',
        required: false,
    })
    contents?: string;

    @ApiModelProperty({
        description: '(S3 Object Id) Image which should be displayed in feed. Image will also be presented when previewVideoUrl is specified',
        required: false,
    })
    previewImage?: string;

    @ApiModelProperty({
        description: '(S3 Object Id) Video which should be displayed in feed. Use "previewImageUrl" value for video preview and do not display video if "previewImageUrl" is not set',
        required: false,
    })
    previewVideo?: string;

    @ApiModelProperty({
        description: 'Amount of applause',
        required: true,
    })
    applause: number;

    @ApiModelProperty({
        description: 'Tags',
        type: TagDto,
        isArray: true,
    })
    tags: Array<TagDto>;

    @ApiModelProperty({
        default: bazaSeoDefault(),
    })
    seo: BazaSeoDto;

    @ApiModelProperty({
        description: 'Associated Listing Id',
        required: false,
    })
    listingId?: number;

    @ApiModelProperty({
        description: 'Associated Listing',
        required: false,
    })
    listing?: BazaNcIntegrationListingsListItemDto;

    @ApiModelProperty({
        description: 'Display mode for post associated with listing',
        required: false,
        type: 'string',
        enum: Object.values(BazaNcIntegrationFeedDisplay),
    })
    display?: BazaNcIntegrationFeedDisplay;
}
