import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BazaNcBootstrapDto, BazaNcCreditCardDto, BazaNcLimitsDto } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, CARD_BRAND_ICONS, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ConfirmModalComponent } from '../confirm-modal';
import { PaymentItemModule } from '../payment-item/payment-item.module';

@Component({
    selector: 'app-payment-item-card',
    templateUrl: './payment-item-card.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, PaymentItemModule, NzButtonModule, ConfirmModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentItemCardComponent {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    creditCardResponse?: BazaNcCreditCardDto;

    @Input()
    limits?: BazaNcLimitsDto;

    @Output()
    addCreditCard = new EventEmitter<TemplateRef<Component>>();

    @Output()
    unlinkCreditCard = new EventEmitter<TemplateRef<Component>>();

    i18nBasePath = 'uic.paymentItemCard';
    deleteCCConfirmVisible = false;
    cardBrandIcons = CARD_BRAND_ICONS;
    @ViewChild('tplStripeModalTitle') tplStripeModalTitle: TemplateRef<Component>;

    constructor(public readonly wts: BazaWebUtilSharedService) {}

    onAddCreditCard(): void {
        this.addCreditCard.emit(this.tplStripeModalTitle);
    }

    onUnlinkCreditCard(): void {
        this.deleteCCConfirmVisible = false;
        this.unlinkCreditCard.emit(this.tplStripeModalTitle);
    }

    getCardIcon(brand: string) {
        return this.wts.getCardIcon(brand);
    }
}
