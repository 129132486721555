import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsInt, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { TradeId } from '../../../transact-api';
import { Type } from 'class-transformer';

export class ImportTradeHistoryResponseTradeDto {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @ApiModelProperty()
    @IsInt()
    @IsNotEmpty()
    amount: number;

    @ApiModelProperty()
    @IsInt()
    @IsNotEmpty()
    numberOfShares: number;
}

export class ImportTradeHistoryResponse {
    @ApiModelProperty({
        type: () => ImportTradeHistoryResponseTradeDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => ImportTradeHistoryResponseTradeDto)
    @IsArray()
    @IsNotEmpty()
    trades: Array<ImportTradeHistoryResponseTradeDto>;
}
