import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BazaContentTypesDataAccessModule } from '@scaliolabs/baza-content-types-data-access';

import { FaqState } from './state';

@NgModule({
    imports: [BazaContentTypesDataAccessModule, NgxsModule.forFeature([FaqState])],
})
export class FaqStateModule {}
