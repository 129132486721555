import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  JobCmsDto,
  JobCmsEndpoint,
  JobCmsEndpointPaths,
  JobsCmsCreateRequest,
  JobsCmsDeleteRequest,
  JobsCmsGetByIdRequest,
  JobsCmsListRequest,
  JobsCmsListResponse,
  JobsCmsSetSortOrderRequest,
  JobsCmsSetSortOrderResponse,
  JobsCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class JobsCmsDataAccess implements JobCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: JobsCmsCreateRequest): Observable<JobCmsDto> {
    return this.http.post(JobCmsEndpointPaths.create, request);
  }

  update(request: JobsCmsUpdateRequest): Observable<JobCmsDto> {
    return this.http.post(JobCmsEndpointPaths.update, request);
  }

  delete(request: JobsCmsDeleteRequest): Observable<void> {
    return this.http.post(JobCmsEndpointPaths.delete, request);
  }

  list(request: JobsCmsListRequest): Observable<JobsCmsListResponse> {
    return this.http.post(JobCmsEndpointPaths.list, request);
  }

  getById(request: JobsCmsGetByIdRequest): Observable<JobCmsDto> {
    return this.http.post(JobCmsEndpointPaths.getById, request);
  }

  setSortOrder(request: JobsCmsSetSortOrderRequest): Observable<JobsCmsSetSortOrderResponse> {
    return this.http.post(JobCmsEndpointPaths.setSortOrder, request);
  }
}
