import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    MarketingPagesCmsCreateRequest,
    MarketingPagesCmsDeleteRequest,
    MarketingPagesCmsGetByIdRequest,
    MarketingPagesCmsListRequest,
    MarketingPagesCmsListResponse,
    MarketingPagesCmsUpdateRequest,
    MktPageCmsDto,
    MktPageCmsEndpoint,
    MktPagesCmsEndpointPaths,
} from '@scaliolabs/baza-content-types-shared';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class MktPagesCmsDataAccess implements MktPageCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    create(request: MarketingPagesCmsCreateRequest): Observable<MktPageCmsDto> {
        return this.http.post(MktPagesCmsEndpointPaths.create, request);
    }

    update(request: MarketingPagesCmsUpdateRequest): Observable<MktPageCmsDto> {
        return this.http.post(MktPagesCmsEndpointPaths.update, request);
    }

    delete(request: MarketingPagesCmsDeleteRequest): Observable<void> {
        return this.http.post(MktPagesCmsEndpointPaths.delete, request);
    }

    list(request: MarketingPagesCmsListRequest): Observable<MarketingPagesCmsListResponse> {
        return this.http.post(MktPagesCmsEndpointPaths.list, request);
    }

    getById(request: MarketingPagesCmsGetByIdRequest): Observable<MktPageCmsDto> {
        return this.http.post(MktPagesCmsEndpointPaths.getById, request);
    }
}
