import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcDividendPaymentStatus, BazaNcOperationType } from '@scaliolabs/baza-nc-shared';

/**
 * Filters Settings for Operation List (Dividend)
 */
export class BazaNcIntegrationOperationStatusesDividendDto {
    @ApiModelProperty({
        type: 'string',
        example: [BazaNcOperationType.Dividend],
        enum: [BazaNcOperationType.Dividend],
    })
    type: BazaNcOperationType.Dividend;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaNcDividendPaymentStatus),
    })
    statuses: Array<BazaNcDividendPaymentStatus>;
}
