import { ulid } from 'ulid';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDateString, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, MaxLength, MinLength } from 'class-validator';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcDividendTransactionEntryDto } from '../dto/baza-nc-dividend-transaction-entry.dto';
import { BazaNcDividendPaymentSource } from '../models/baza-nc-dividend-payment-source';
import { Observable } from 'rxjs';
import { OfferingId } from '../../../../transact-api';
import { BazaNcDividendImportCsvEntryErrorDto } from '../dto/baza-nc-dividend-import-csv-entry-error.dto';
import { BazaNcDividendTransactionEntryCsvDto } from '../dto/baza-nc-dividend-transaction-entry-csv.dto';

export enum BazaNcDividendTransactionEntryCmsEndpointPaths {
    create = '/baza-nc/cms/dividend/entry/create',
    update = '/baza-nc/cms/dividend/entry/update',
    delete = '/baza-nc/cms/dividend/entry/delete',
    getByUlid = '/baza-nc/cms/dividend/entry/getByUlid',
    list = '/baza-nc/cms/dividend/entry/list',
    importCsv = '/baza-nc/cms/dividend/entry/importCsv',
    exportToCsv = '/baza-nc/cms/dividend/entry/exportToCsv',
}

export interface BazaNcDividendTransactionEntryCmsEndpoint {
    create(
        request: BazaNcDividendTransactionEntryCmsCreateRequest,
    ): Promise<BazaNcDividendTransactionEntryDto> | Observable<BazaNcDividendTransactionEntryDto>;
    update(
        request: BazaNcDividendTransactionEntryCmsUpdateRequest,
    ): Promise<BazaNcDividendTransactionEntryDto> | Observable<BazaNcDividendTransactionEntryDto>;
    delete(request: BazaNcDividendTransactionEntryCmsDeleteRequest): Promise<void> | Observable<void>;
    getByUlid(
        request: BazaNcDividendTransactionEntryCmsGetByUlidRequest,
    ): Promise<BazaNcDividendTransactionEntryDto> | Observable<BazaNcDividendTransactionEntryDto>;
    list(
        request: BazaNcDividendTransactionEntryCmsListRequest,
    ): Promise<BazaNcDividendTransactionEntryCmsListResponse> | Observable<BazaNcDividendTransactionEntryCmsListResponse>;
    importCsv(
        uploadedCsvFile: File | unknown,
        request: BazaNcDividendTransactionEntryImportCsvRequest,
    ): Promise<BazaNcDividendTransactionEntryImportCsvResponse> | Observable<BazaNcDividendTransactionEntryImportCsvResponse>;
    exportToCsv(request: BazaNcDividendTransactionEntryExportCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcDividendTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    @IsDateString()
    @IsNotEmpty()
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering Id',
        required: false,
    })
    @IsString()
    @IsOptional()
    offeringId?: OfferingId;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amountCents: number;
}

export class BazaNcDividendTransactionEntryCmsCreateRequest extends BazaNcDividendTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcDividendPaymentSource),
    })
    @IsEnum(BazaNcDividendPaymentSource)
    @IsNotEmpty()
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;

    @ApiModelProperty({
        description: 'Dividend Transaction ULID',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    dividendTransactionUlid: string;
}

export class BazaNcDividendTransactionEntryCmsUpdateRequest extends BazaNcDividendTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionEntryCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionEntryCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcDividendTransactionEntryCmsListRequest extends CrudListRequestDto<BazaNcDividendTransactionEntryDto> {
    @ApiModelProperty({
        type: 'string',
        description: 'Dividend Transaction ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    dividendTransactionUlid: string;
}

export class BazaNcDividendTransactionEntryCmsListResponse extends CrudListResponseDto<BazaNcDividendTransactionEntryDto> {
    @ApiModelProperty({
        type: BazaNcDividendTransactionEntryDto,
        isArray: true,
    })
    items: Array<BazaNcDividendTransactionEntryDto>;
}

export class BazaNcDividendTransactionEntryImportCsvRequest {
    @ApiModelProperty({
        description: 'Dividend Transaction',
    })
    @IsString()
    @IsNotEmpty()
    dividendTransactionUlid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentSource),
        description: 'Payment Source (Type) for Dividends',
    })
    @IsEnum(BazaNcDividendPaymentSource)
    @IsNotEmpty()
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Delimiter used for source CSV file',
        default: ',',
        required: false,
    })
    @IsString()
    @MaxLength(1)
    @MinLength(1)
    @IsOptional()
    csvDelimiter?: string;
}

export class BazaNcDividendTransactionEntryImportCsvResponse {
    @ApiModelProperty({
        description: 'Is CSV file successfully imported without errors?',
    })
    success: boolean;

    @ApiModelProperty({
        description: 'Errors',
        type: BazaNcDividendImportCsvEntryErrorDto,
        isArray: true,
    })
    errors?: Array<BazaNcDividendImportCsvEntryErrorDto>;
}

export class BazaNcDividendTransactionEntryExportCsvRequest extends CrudExportToCsvRequest<
    BazaNcDividendTransactionEntryCsvDto,
    BazaNcDividendTransactionEntryCmsListRequest
> {}
