import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { Store } from '@ngxs/store';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { BazaContentTypesDataAccessModule } from '@scaliolabs/baza-content-types-data-access';

import { ContactUsState } from './state';
import { ContactUsStartApp } from './actions';

@NgModule({
    imports: [NgxsModule.forFeature([ContactUsState]), BazaContentTypesDataAccessModule, NzNotificationModule],
})
export class ContactUsStateModule {
    constructor(private store: Store) {
        this.store.dispatch(new ContactUsStartApp());
    }
}
