import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { PurchaseState } from '../store';

@Injectable({ providedIn: 'root' })
export class SharePickerResolver implements Resolve<number> {
    constructor(private readonly store: Store) {}

    resolve(): Observable<number> {
        return of(this.store.selectSnapshot(PurchaseState.numberOfShares));
    }
}
