import { Observable } from 'rxjs';
import { BazaDwollaPaymentStatus } from '../models/baza-dwolla-payment-status';
import { CrudListRequestQueryDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDwollaPaymentDto } from '../dto/baza-dwolla-payment.dto';
import { BazaDwollaPaymentType } from '../models/baza-dwolla-payment-type';

export enum BazaDwollaPaymentEndpointPaths {
    list = '/baza/dwolla-payment/list',
    get = '/baza/dwolla-payment/get/:ulid',
}

export interface BazaDwollaPaymentEndpoint {
    list(
        request: BazaDwollaPaymentalListRequest,
        ...args: unknown[]
    ): Promise<BazaDwollaPaymentalListResponse> | Observable<BazaDwollaPaymentalListResponse>;
    get(ulid: string, ...args: unknown[]): Promise<BazaDwollaPaymentDto> | Observable<BazaDwollaPaymentDto>;
}

export class BazaDwollaPaymentalListRequest extends CrudListRequestQueryDto {
    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaDwollaPaymentStatus),
        required: false,
    })
    status?: Array<BazaDwollaPaymentStatus>;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaDwollaPaymentType),
        required: false,
    })
    types?: Array<BazaDwollaPaymentType>;

    @ApiModelProperty({
        type: 'string',
        required: false,
    })
    ncTradeId?: string;
}

export class BazaDwollaPaymentalListResponse extends CrudListResponseDto<BazaDwollaPaymentDto> {
    @ApiModelProperty({
        type: BazaDwollaPaymentDto,
        isArray: true,
    })
    items: Array<BazaDwollaPaymentDto>;
}
