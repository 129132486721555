import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountRole } from '../../../../baza-account/src';

export class JwtPayload<ACL = string> {
    @ApiModelProperty()
    accountId: number;

    @ApiModelProperty()
    accountEmail: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountRole),
    })
    accountRole: AccountRole;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
    })
    accountAcl: Array<ACL>;

    @ApiModelProperty({
        description: 'Managed User accounts are not able to change password, reset password or change email',
    })
    isManagedUserAccount: boolean;

    @ApiModelProperty()
    iat?: number;

    @ApiModelProperty()
    exp?: number;
}
