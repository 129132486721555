import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsInt, IsNotEmpty, IsNumber, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import {
    GetAccountResponse,
    GetPartyResponse,
    NorthCapitalAccountId,
    OrderStatus,
    PartyId,
    TradeId,
    TransactionStatus,
} from '../../../transact-api';
import { BazaNcOfferingDto } from '../../../offering';
import { TransactionState } from '../../../transaction';
import { ulid } from 'ulid';
import { DwollaFailureCodes } from '@scaliolabs/baza-dwolla-shared';
import { NorthCapitalTopic } from '../../../nc-kafka-topics';

export enum BazaNcE2eEndpointPaths {
    touchTrade = '/baza-nc/e2e/touchTrade',
    updateTradeStatus = '/baza-nc/e2e/updateTradeStatus',
    getTradeStatus = '/baza-nc/e2e/getTradeStatus',
    setTradeStatus = '/baza-nc/e2e/setTradeStatus',
    enableMaskConfig = '/baza-nc/e2e/enableMaskConfig',
    disableMaskConfig = '/baza-nc/e2e/disableMaskConfig',
    createExampleOffering = '/baza-nc/e2e/createExampleOffering',
    enableTransactionFee = '/baza-nc/e2e/enableTransactionFee',
    disableTransactionFee = '/baza-nc/e2e/disableTransactionFee',
    updateCCFundMoveStatus = '/baza-nc/e2e/updateCCFundMoveStatus',
    updateExternalFundMoveStatus = '/baza-nc/e2e/updateExternalFundMoveStatus',
    enableDwollaAutoTouch = '/baza-nc/e2e/enableDwollaAutoTouch',
    disableDwollaAutoTouch = '/baza-nc/e2e/disableDwollaAutoTouch',
    setFundingSourceName = '/baza-nc/e2e/setFundingSourceName',
    simulateNcWebhook = '/baza-nc/e2e/simulateNcWebhook/:topic',
    enableLBASync = '/baza-nc/e2e/enableLBASync',
    disableLBASync = '/baza-nc/e2e/disableBASync',
    getNcAccount = '/baza-nc/e2e/getNcAccount',
    getNcParty = '/baza-nc/e2e/getNcParty',
}

export interface BazaNcE2eEndpoint {
    updateTradeStatus(request: BazaNcE2eUpdateTradeStatusRequest): Promise<void> | Observable<void>;
    getTradeStatus(
        request: BazaNcE2eGetTradeStatusRequest,
    ): Promise<BazaNcE2eGetTradeStatusResponse> | Observable<BazaNcE2eGetTradeStatusResponse>;
    setTradeStatus(request: BazaNcE2eSetTradeStatusRequest): Promise<void> | Observable<void>;
    enableMaskConfig(): Promise<void> | Observable<void>;
    disableMaskConfig(): Promise<void> | Observable<void>;
    createExampleOffering(...args: unknown[]): Promise<BazaNcOfferingDto> | Observable<BazaNcOfferingDto>;
    enableTransactionFeeFeature(): Promise<void> | Observable<void>;
    disableTransactionFeeFeature(): Promise<void> | Observable<void>;
    updateCCFundMoveStatus(request: BazaNcE2eUpdateCCFundMoveStatusRequest): Promise<void> | Observable<void>;
    updateExternalFundMoveStatus(request: BazaNcE2eUpdateExternalFundMoveStatusRequest): Promise<void> | Observable<void>;
    enableDwollaAutoTouch(): Promise<void> | Observable<void>;
    disableDwollaAutoTouch(): Promise<void> | Observable<void>;
    setFundingSourceName(request: BazaNcE2eSetFundingSourceNameRequest): Promise<void> | Observable<void>;
    simulateNcWebhook(payload: unknown, topic: NorthCapitalTopic): Promise<void> | Observable<void>;
    enableLBASync(): Promise<void> | Observable<void>;
    disableLBASync(): Promise<void> | Observable<void>;
    getNcAccount(request: BazaNcE2eGetNcAccountRequest): Promise<GetAccountResponse> | Observable<GetAccountResponse>;
    getNcParty(request: BazaNcE2eGetNcPartyRequest): Promise<GetPartyResponse> | Observable<GetPartyResponse>;
}

export class BazaNcE2eUpdateTradeStatusRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    @IsEnum(OrderStatus)
    @IsNotEmpty()
    orderStatus: OrderStatus;
}

export class BazaNcE2eGetTradeStatusRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;
}

export class BazaNcE2eGetTradeStatusResponse {
    @ApiModelProperty({
        type: 'string',
    })
    tradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    ncOrderStatus: OrderStatus;
}

export class BazaNcE2eSetTradeStatusRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    @IsEnum(OrderStatus)
    @IsNotEmpty()
    orderStatus: OrderStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
    })
    @IsEnum(TransactionState)
    @IsNotEmpty()
    transactionState: TransactionState;
}

export class BazaNcE2eUpdateCCFundMoveStatusRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @ApiModelProperty({
        type: 'string',
    })
    @IsNotEmpty()
    fundMoveStatus: TransactionStatus;
}

export class BazaNcE2eUpdateExternalFundMoveStatusRequest {
    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    tradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @ApiModelProperty({
        type: 'string',
    })
    @IsNotEmpty()
    fundMoveStatus: TransactionStatus;
}

export class BazaNcE2eCancelDwollaTransferRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcE2eSetFundingSourceNameRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;

    @ApiModelProperty({
        enum: Object.values(DwollaFailureCodes),
        description: 'Name of Funding Source. Use Dwolla Failure Codes to simulate different issues',
    })
    @IsString()
    @IsNotEmpty()
    name: string;
}

export class BazaNcE2eGetNcAccountRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'NC Account ID',
        example: 'A123456',
    })
    @IsString()
    @IsNotEmpty()
    ncAccountId: NorthCapitalAccountId;
}

export class BazaNcE2eGetNcPartyRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'NC Party ID',
        example: 'P123456',
    })
    @IsString()
    @IsNotEmpty()
    ncPartyId: PartyId;
}
