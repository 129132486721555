export class BazaContentTypeNewslettersConfig {
    /**
     * Enable Name field for CMS and subscribe requests
     */
    withName: boolean;

    /**
     * Enable Welcome email when user subscribed
     */
    sendWelcomeEmail: boolean;
}

export function bazaContentTypeNewslettersDefaultConfig(): BazaContentTypeNewslettersConfig {
    return {
        withName: true,
        sendWelcomeEmail: true,
    };
}
