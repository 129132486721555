<div class="hiw">
    <div class="container hiw__container">
        <div
            nz-row
            nzGutter="30"
            class="hiw__row">
            <div
                nz-col
                nzXs="12"
                class="hiw__col">
                <div class="hiw__text">
                    <div
                        class="hiw__heading"
                        [innerHtml]="header ?? ''"></div>
                    <div
                        class="hiw__subheading"
                        [innerHtml]="description ?? ''"></div>
                </div>
            </div>
        </div>
        <div
            nz-row
            nzGutter="30"
            class="hiw__row">
            <div
                nz-col
                nzXs="12"
                class="hiw__col hiw__col-cards"
                *ngFor="let card of hiwSections; let i = index">
                <app-how-it-works-card
                    [index]="i + 1"
                    [header]="card.header ?? ''"
                    [description]="card.description ?? ''"></app-how-it-works-card>
            </div>
        </div>
    </div>
</div>
