import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean,  IsOptional } from 'class-validator';

export class SessionDocusignRequestDto {
    @ApiModelProperty({
        description: 'Request DocuSign URL (2$ per each request)'
    })
    @IsBoolean()
    @IsOptional()
    requestDocuSignUrl: boolean;
}
