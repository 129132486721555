import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ConfirmationEmailExpiryResendStrategy } from '../../../../baza-account/src';
import { IsEmail, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaAccountE2eConfirmationTokenDto } from '../dto/baza-account-e2e-confirmation-token.dto';

export enum BazaAccountE2eErrorCodes {
    BazaE2eIsNotTestEnvironment = 'BazaE2eIsNotTestEnvironment',
}

export enum BazaAccountE2eEndpointPaths {
    changeEmailConfirmationExpiryStrategy = '/baza-e2e/account/changeStrategy',
    expireConfirmationToken = '/baza-e2e/account/expireConfirmationToken',
    getAccountConfirmationToken = '/baza-e2e/account/accountConfirmationToken',
}

export interface BazaAccountE2eEndpoint {
    changeEmailConfirmationExpiryStrategy(
        request: BazaAccountE2eChangeEmailConfirmationExpiryStrategyRequest,
    ): Promise<void> | Observable<void>;
    expireConfirmationToken(request: BazaAccountE2eExpireConfirmationTokenRequest): Promise<void> | Observable<void>;
    getAccountConfirmationToken(
        request: BazaAccountE2eGetConfirmationTokenRequest,
    ): Promise<BazaAccountE2eConfirmationTokenDto> | Observable<BazaAccountE2eConfirmationTokenDto>;
}

export class BazaAccountE2eChangeEmailConfirmationExpiryStrategyRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'strategy used to change the dafault behaviour on email confirmation token expiry',
        isArray: true,
    })
    @IsEnum(ConfirmationEmailExpiryResendStrategy)
    @IsNotEmpty()
    strategy: ConfirmationEmailExpiryResendStrategy;
}

export class BazaAccountE2eExpireConfirmationTokenRequest {
    @ApiModelProperty({
        description: 'Then confirmation token generate current user',
    })
    @IsString()
    @IsNotEmpty()
    token: string;
}

export class BazaAccountE2eGetConfirmationTokenRequest {
    @ApiModelProperty({
        description: 'user account email',
    })
    @IsEmail()
    @IsNotEmpty()
    email: string;
}
