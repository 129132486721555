import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaPhoneCountryCodesNgConfig } from './baza-phone-country-codes-ng.config';
import { BazaPhoneCountryCodesResolver } from './resolvers/baza-phone-country-codes.resolver';
import { BazaPhoneCountryCodesService } from './services/baza-phone-country-codes.service';
import { BazaPhoneCountryCodesDataAccessModule } from '@scaliolabs/baza-core-data-access';

interface ForRootOptions {
    deps?: Array<unknown>;
    useFactory: (...injects) => BazaPhoneCountryCodesNgConfig;
}

export { ForRootOptions as BazaPhoneCountryCodesNgModuleForRootOptions };

// @dynamic
@NgModule({
    imports: [BazaPhoneCountryCodesDataAccessModule],
    providers: [BazaPhoneCountryCodesResolver, BazaPhoneCountryCodesService],
})
export class BazaPhoneCountryCodesNgModule {
    static forRoot(options: ForRootOptions): ModuleWithProviders<BazaPhoneCountryCodesNgModule> {
        return {
            ngModule: BazaPhoneCountryCodesNgModule,
            providers: [
                {
                    provide: BazaPhoneCountryCodesNgConfig,
                    deps: options.deps,
                    useFactory: options.useFactory,
                },
            ],
        };
    }
}
