import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

/**
 * @see https://ogp.me/
 */
export class BazaSeoCardDto {
    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    description: string;

    @ApiModelProperty()
    url: string;

    @ApiModelProperty()
    coverImageUrl: string;
}
