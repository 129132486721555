import { IsEnum, IsNotEmpty, IsNumberString, IsString } from 'class-validator';
import { DwollaBankAccountType } from '../models/dwolla-bank-account-type';

export class CreateFundingSourceForAccountRequest {
    @IsNumberString()
    @IsNotEmpty()
    routingNumber: string;

    @IsNumberString()
    @IsNotEmpty()
    accountNumber: string;

    @IsEnum(DwollaBankAccountType)
    @IsNotEmpty()
    bankAccountType: DwollaBankAccountType;

    @IsString()
    @IsNotEmpty()
    name: string;
}
