import { BazaNcIntegrationPortfolioTransactionInvestmentDto } from './baza-nc-integration-portfolio-transaction-investment.dto';
import { BazaNcIntegrationPortfolioTransactionDividendDto } from './baza-nc-integration-portfolio-transaction-dividend.dto';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';

export type BazaNcIntegrationPortfolioTransactionDto =
    | BazaNcIntegrationPortfolioTransactionInvestmentDto
    | BazaNcIntegrationPortfolioTransactionDividendDto;

export const bazaNcIntegrationPortfolioExportedSwaggerDtos = [
    BazaNcIntegrationPortfolioTransactionInvestmentDto,
    BazaNcIntegrationPortfolioTransactionDividendDto,
];

export const bazaNcIntegrationPortfolioTransactionDtoSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaNcIntegrationPortfolioExportedSwaggerDtos.map(($ref) => ({
        $ref: getSchemaPath($ref),
    })),
});
