import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEmail, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class BazaFormMailRecipientCCDto {
    @ApiModelProperty({
        description: 'Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        description: 'Email',
    })
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;
}

export class BazaFormMailRecipientDto {
    @ApiModelProperty({
        description: 'Name',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        description: 'Email',
    })
    @IsEmail()
    @IsString()
    @IsNotEmpty()
    email: string;

    @ApiModelProperty({
        description: 'CC',
        type: BazaFormMailRecipientCCDto,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => BazaFormMailRecipientCCDto)
    @IsArray()
    @IsNotEmpty()
    cc: Array<BazaFormMailRecipientCCDto>;
}

export function bazaMailRecipientsToTOField(input: Array<BazaFormMailRecipientDto | BazaFormMailRecipientCCDto>): string {
    return input.map((recipient) => (recipient.name ? `"${recipient.name}" <${recipient.email}>` : recipient.email)).join(',');
}
