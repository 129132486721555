import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DwollaFailureCodes } from '@scaliolabs/baza-dwolla-shared';

export class BazaNcDividendFailureReasonDto {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DwollaFailureCodes),
        description:
            'Failure Code (https://developers.dwolla.com/concepts/transfer-failures#list-of-possible-return-codes-descriptions-and-actions) Or Baza Error Code',
        example: DwollaFailureCodes.R03,
    })
    code: DwollaFailureCodes | string;

    @ApiModelProperty({
        description: 'Human-Readable message (short)',
        example: 'No Account/Unable to Locate Account ',
    })
    reason: string;

    @ApiModelProperty({
        description: 'Human-Readable message (detailed)',
        example: 'Account number structure is valid, but doesn’t match individual identified in entry or is not an open account.',
        required: false,
    })
    details?: string;
}
