import { BazaProgressStatusCategoryType } from '../../types/baza-progress-status-category.type';
import { IsOptional, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaGetInProgressItemsRequest {
    @ApiModelProperty({
        description: 'filter by category of the progress items',
    })
    @IsOptional()
    @IsString()
    category?: BazaProgressStatusCategoryType;
}
