import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { RefNum } from '../models/ref-num';

export class GetCcFundMoveInfoRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    RefNum: RefNum;
}
