<div
    class="bullets"
    [class.bullets-img]="!isTextBullets">
    <div class="bullets__container">
        <div
            nz-row
            class="bullets__row">
            <ng-container *ngIf="bullets && bullets.length >= 0">
                <ng-container [ngSwitch]="isTextBullets">
                    <ng-container *ngSwitchCase="true">
                        <!-- Text Bullets -->
                        <div
                            *ngFor="let text of bullets"
                            class="bullets__col"
                            nz-col
                            nzXs="12">
                            <span
                                class="bullets__text"
                                [innerHtml]="text | sanitizeHtml"></span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <!-- Image Bullets -->
                        <div
                            class="bullets__label"
                            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'label') | sanitizeHtml"></div>
                        <div class="bullets__image">
                            <div
                                *ngFor="let image of bullets"
                                class="bullets__imgcol">
                                <img
                                    class="bullets__img-content"
                                    src="{{ image }}"
                                    alt="" />
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
