import { IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { ArchiveStatus } from '../models/archive-status';
import { PartyId } from '../models/party-id';

export class UpdatePartyEntityArchiveStatusRequest {
    @IsString()
    @IsNotEmpty()
    partyId: PartyId;

    @IsEnum(ArchiveStatus)
    @IsNotEmpty()
    archiveStatus: ArchiveStatus;
}
