import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    CategoriesCmsEndpoint,
    CategoriesCmsEndpointPaths,
    CategoriesDto,
    CategoryDto,
    CategoriesCmsCreateRequest,
    CategoriesDeleteRequest,
    CategoriesUpdateRequest,
    CategoriesCmsSetSortOrderRequest,
    BazaContentTypesCmsSetSortOrderResponse,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class CategoriesCmsDataAccess implements CategoriesCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    create(request: CategoriesCmsCreateRequest): Observable<CategoryDto> {
        return this.http.post(CategoriesCmsEndpointPaths.create, request);
    }

    update(request: CategoriesUpdateRequest): Observable<CategoryDto> {
        return this.http.post(CategoriesCmsEndpointPaths.update, request);
    }

    delete(request: CategoriesDeleteRequest): Observable<void> {
        return this.http.post(CategoriesCmsEndpointPaths.delete, request);
    }

    getAll(): Observable<CategoriesDto> {
        return this.http.get(CategoriesCmsEndpointPaths.getAll);
    }

    getById(id: number): Observable<CategoryDto> {
        return this.http.get(
            replacePathArgs(CategoriesCmsEndpointPaths.getById, {
                id,
            }),
        );
    }

    setSortOrder(request: CategoriesCmsSetSortOrderRequest): Observable<BazaContentTypesCmsSetSortOrderResponse> {
        return this.http.post(CategoriesCmsEndpointPaths.setSortOrder, request);
    }
}
