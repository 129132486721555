import { Injectable } from '@angular/core';
import { BrowserOptions } from '@sentry/browser';

@Injectable()
export class BazaSentryNgConfig {
    sentry?: {
        dsn: string;
        browserOptions?: BrowserOptions;
    };
}
