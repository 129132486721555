export const PurchaseDoneEnI18n = {
    title: 'Your order has been submitted!',
    descr: 'We are verifying the order and will notify you when it has been completed. This typically takes 24-48 hours.',
    support: {
        descr: `If you have any questions please reach out via <a data-link="contactLink"></a>`,
        linkConfig: {
            key: 'contactLink',
            link: {
                appLink: {
                    commands: ['', { outlets: { modal: ['contact-us'] } }],
                    text: 'Contact Us',
                },
            },
        },
    },
    actions: {
        proceedToPortfolio: {
            label: `<a data-link="portfolioLink"></a>`,
            linkConfig: {
                key: 'portfolioLink',
                link: {
                    appLink: {
                        commands: ['/portfolio'],
                        text: 'Proceed to my portfolio',
                    },
                },
            },
        },
    },
};
