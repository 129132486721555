export enum BazaNcDividendImportCsvEntryError {
    Exception = 'Exception',
    NotEnoughData = 'NotEnoughData',
    InvestorAccountNotFound = 'InvestorAccountNotFound',
    FailedToParseAmount = 'FailedToParseAmount',
    AmountIsLessThanZero = 'AmountIsLessThanZero',
    FailedToParseDate = 'FailedToParseDate',
    DateIsInFuture = 'DateIsInFuture',
    OfferingNotFound = 'OfferingNotFound',
}
