import { ModuleWithProviders, NgModule } from '@angular/core';
import { BazaRegistryDataAccessModule } from '@scaliolabs/baza-core-data-access';
import { BazaRegistryNgResolve } from './baza-registry-ng.resolve';
import { BazaRegistryNgService } from './baza-registry-ng.service';

// @dynamic
@NgModule({
    imports: [
        BazaRegistryDataAccessModule,
    ],
    providers: [
        BazaRegistryNgResolve,
        BazaRegistryNgService,
    ],
})
export class BazaRegistryNgModule {
    static forRoot(): ModuleWithProviders<BazaRegistryNgModule> {
        return {
            ngModule: BazaRegistryNgModule,
        };
    }
}
