<span class="radio-payment">
    <span [ngClass]="isDisabled ? 'ant-radio ant-radio-disabled' : isSelected ? 'ant-radio ant-radio-checked' : 'ant-radio'">
        <input
            class="ant-radio-input"
            type="radio"
            [disabled]="isDisabled" />

        <span class="ant-radio-inner"></span>
    </span>
</span>
