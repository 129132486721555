import { PurchaseFlowDto } from './purchase-flow.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class PurchaseFlowCurrentDto {
    @ApiModelProperty({
        description: 'Is Purchase Flow started?',
    })
    isAvailable: boolean;

    @ApiModelProperty({
        description: 'Purchase Flow Session (if isAvailabel is true)',
        required: false,
    })
    session?: PurchaseFlowDto;
}
