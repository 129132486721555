import { Observable } from 'rxjs';
import { BlogDto } from '../dto/blog.dto';
import {
    CrudListRequestQueryDto,
    CrudListResponseDto,
    CrudSetSortOrderRequestDto,
    CrudSetSortOrderResponseDto,
} from '@scaliolabs/baza-core-shared';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export enum BlogEndpointPaths {
    list = '/baza-content-types/blog/list',
    getById = '/baza-content-types/blog/getById/:id',
}

export interface BlogEndpoint {
    list(request: BlogListRequest): Promise<BlogListResponse> | Observable<BlogListResponse>;
    getById(id: number): Promise<BlogDto> | Observable<BlogDto>;
}

export class BlogListRequest extends CrudListRequestQueryDto {}
export class BlogListResponse extends CrudListResponseDto<BlogDto> {}

export class BlogSetSortOrderRequest extends CrudSetSortOrderRequestDto {}

export class BlogSetSortOrderResponse extends CrudSetSortOrderResponseDto<BlogDto> {
    @ApiModelProperty()
    entity: BlogDto;

    @ApiModelProperty({
        type: BlogDto,
        isArray: true,
    })
    affected: Array<BlogDto>;
}
