import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    TagCmsDto,
    TagsCmsCreateRequest,
    TagsCmsDeleteRequest,
    TagsCmsEndpoint,
    TagsCmsGetByIdRequest,
    TagsCmsListRequest,
    TagsCmsListResponse,
    TagsCmsSetSortOrderResponse,
    TagsCmsSetSortOrderRequest,
    TagsCmsUpdateRequest,
    TagsCmsEndpointPaths,
    TagsListRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class TagsCmsDataAccess implements TagsCmsEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    create(request: TagsCmsCreateRequest): Observable<TagCmsDto> {
        return this.http.post(TagsCmsEndpointPaths.create, request);
    }

    update(request: TagsCmsUpdateRequest): Observable<TagCmsDto> {
        return this.http.post(TagsCmsEndpointPaths.update, request);
    }

    delete(request: TagsCmsDeleteRequest): Observable<void> {
        return this.http.post(TagsCmsEndpointPaths.delete, request);
    }

    list(request: TagsCmsListRequest): Observable<TagsCmsListResponse> {
        return this.http.post(TagsCmsEndpointPaths.list, request);
    }

    getAll(request?: TagsListRequest): Observable<Array<TagCmsDto>> {
        return this.http.post(TagsCmsEndpointPaths.getAll, request);
    }

    getById(request: TagsCmsGetByIdRequest): Observable<TagCmsDto> {
        return this.http.post(TagsCmsEndpointPaths.getById, request);
    }

    setSortOrder(request: TagsCmsSetSortOrderRequest): Observable<TagsCmsSetSortOrderResponse> {
        return this.http.post(TagsCmsEndpointPaths.setSortOrder, request);
    }
}
