import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { JobDto, JobEndpoint, JobEndpointPaths, JobsListRequest, JobsListResponse } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class JobsDataAccess implements JobEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: JobsListRequest): Observable<JobsListResponse> {
    return this.http.get(JobEndpointPaths.list, request);
  }

  getAll(): Observable<Array<JobDto>> {
    return this.http.get(JobEndpointPaths.getAll);
  }

  getById(id: number): Observable<JobDto> {
    return this.http.get(replacePathArgs(JobEndpointPaths.getById, { id }));
  }

  getByUrl(url: string): Observable<JobDto> {
    return this.http.get(replacePathArgs(JobEndpointPaths.getByUrl, { url }));
  }
}
