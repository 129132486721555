import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudSortableEntity } from '../entities/crud-sortable-entity';

export class CrudSetSortOrderResponseDto<
    T extends CrudSortableEntity = {
        id: number;
        sortOrder: number;
    },
> {
    @ApiModelProperty({
        description: 'Entity which was sort ordered',
    })
    entity: T;

    @ApiModelProperty({
        description: 'Affected entities',
    })
    affected: Array<T>;
}
