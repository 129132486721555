import { Injectable } from '@angular/core';
import { AuthEndpoint, AuthEndpointPaths, AuthRequest, AuthResponse, AuthVerifyCredentials, InvalidateAllTokensResponse, InvalidateByRefreshTokenRequest, InvalidateByRefreshTokenResponse, InvalidateTokenRequest, InvalidateTokenResponse, RefreshTokenRequest, RefreshTokenResponse, VerifyRequest, VerifyResponse } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '../../../../../ng/src/lib/baza-data-access.service';

@Injectable()
export class BazaAuthDataAccess implements AuthEndpoint {
    constructor(
        private readonly http: BazaDataAccessService,
    ) {}

    auth(request: AuthRequest): Observable<AuthResponse> {
        return this.http.post(AuthEndpointPaths.auth, request);
    }

    verifyCredentials(request: AuthVerifyCredentials): Observable<boolean> {
        return this.http.post(AuthEndpointPaths.verifyCredentials, request);
    }

    refreshToken(request: RefreshTokenRequest): Observable<RefreshTokenResponse> {
        return this.http.post(AuthEndpointPaths.refreshToken, request);
    }

    verify(request: VerifyRequest): Observable<VerifyResponse> {
        return this.http.post(AuthEndpointPaths.verify, request);
    }

    invalidateAllTokens(): Observable<InvalidateAllTokensResponse> {
        return this.http.post(AuthEndpointPaths.invalidateAllTokens);
    }

    invalidateToken(request: InvalidateTokenRequest): Observable<InvalidateTokenResponse> {
        return this.http.post(AuthEndpointPaths.invalidateToken, request);
    }

    invalidateByRefreshToken(request: InvalidateByRefreshTokenRequest): Observable<InvalidateByRefreshTokenResponse> {
        return this.http.post(AuthEndpointPaths.invalidateByRefreshToken, request);
    }
}
