import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaMaintenanceDto } from '../dto/baza-maintenance.dto';
import { BazaMaintenanceMessageDto } from '../dto/baza-maintenance-message.dto';
import { Application } from '../../../../baza-common/src';

export enum BazaMaintenanceCmsEndpointPaths {
    status = '/baza-maintenance/status',
    enable = '/baza-maintenance/enable',
    disable = '/baza-maintenance/disable',
    getMessage = '/baza-maintenance/getMessage',
    setMessage = '/baza-maintenance/setMessage',
}

export interface BazaMaintenanceCmsEndpoint {
    status(): Promise<BazaMaintenanceDto> | Observable<BazaMaintenanceDto>;
    enable(request: BazaMaintenanceCmsEnableRequest): Promise<BazaMaintenanceDto> | Observable<BazaMaintenanceDto>;
    disable(request: BazaMaintenanceCmsDisableRequest): Promise<BazaMaintenanceDto> | Observable<BazaMaintenanceDto>;
    getMessage(): Promise<BazaMaintenanceMessageDto> | Observable<BazaMaintenanceMessageDto>;
    setMessage(request: BazaMaintenanceSetMessageRequest): Promise<BazaMaintenanceMessageDto> | Observable<BazaMaintenanceMessageDto>;
}

export class BazaMaintenanceCmsEnableRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
    })
    @IsEnum(Application, { each: true })
    @IsArray()
    @IsNotEmpty()
    applications: Array<Application>;
}

export class BazaMaintenanceCmsDisableRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
    })
    @IsEnum(Application, { each: true })
    @IsArray()
    @IsNotEmpty()
    applications: Array<Application>;
}

export class BazaMaintenanceSetMessageRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    message: string;
}
