import { NorthCapitalRequestStatus } from '../../../transact-api';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';

export class BazaNcRequestLogDto {
    @ApiModelProperty({
        description: 'ULID',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        description: 'NC request date',
        example: new Date().toISOString(),
    })
    requestDate: string;

    @ApiModelProperty({
        description: 'NC response date',
        example: new Date().toISOString(),
    })
    responseDate: string;

    @ApiModelProperty({
        description: 'the endpoint called',
    })
    uri: string;

    @ApiModelProperty({
        description: 'similar to correlation id used on api side',
    })
    requestId: string;

    @ApiModelProperty({
        description: 'unique id used for each request',
    })
    correlationId: string;

    @ApiModelProperty({
        description: 'retry time for the request',
    })
    attempts: number;

    @ApiModelProperty({
        description: 'response time in seconds',
    })
    responseTimeSeconds: number;

    @ApiModelProperty({
        description: 'response status of the nc request',
        enum: Object.values(NorthCapitalRequestStatus),
    })
    status: NorthCapitalRequestStatus;
}
