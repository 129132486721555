import { ExternalAccountType } from '../models/external-account-type';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { IssuerId } from '../models/issuer-id';

export class DeleteExternalAccountRequest {
    @IsEnum(ExternalAccountType)
    @IsNotEmpty()
    types: ExternalAccountType;

    @IsString()
    @IsOptional()
    accountId?: NorthCapitalAccountId;

    @IsString()
    @IsOptional()
    issuerId?: IssuerId;
}
