<nz-back-top [nzTemplate]="tpl">
    <ng-template #tpl>
        <div class="ant-back-top-inner">
            <i
                nz-icon
                nzType="up"
                class="ant-back-top-inner__icon"></i>
        </div>
    </ng-template>
</nz-back-top>
