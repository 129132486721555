import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { OfferingId } from '../../../transact-api';

export class BazaNcOfferingDetailsDto {
    @ApiModelProperty()
    ncOfferingId: OfferingId;

    @ApiModelProperty()
    targetAmount: number;

    @ApiModelProperty()
    minAmount: number;

    @ApiModelProperty()
    maxAmount: number;

    @ApiModelProperty()
    unitPrice: number;

    @ApiModelProperty()
    startDate: string;

    @ApiModelProperty()
    endDate: string;
}
