import { NorthCapitalAccountId } from '../models/account-id';
import { IsNotEmpty, IsString } from 'class-validator';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';

export class RequestCustodialAccountRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsNotEmpty()
    createdIpAddress: NorthCapitalIpAddress;
}
