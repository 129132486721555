import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { BazaError, isBazaErrorResponse } from '@scaliolabs/baza-core-shared';

export interface BazaNgError<T = any> {
    error: BazaError<T>;
    navigateToNotFound: boolean;
}

export interface HttpNgError<T = any> {
    error: T;
    navigateToNotFound: boolean;
}

interface PipeOptions {
    navigateToNotFound?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class BazaNgErrorHandlerService
{
    private readonly _bazaErrors$: Subject<BazaNgError> = new Subject<BazaNgError>();
    private readonly _httpErrors$: Subject<HttpNgError> = new Subject<HttpNgError>();

    get bazaErrors$(): Observable<BazaNgError> {
        return this._bazaErrors$.asObservable();
    }

    get httpErrors$(): Observable<HttpNgError> {
        return this._httpErrors$.asObservable();
    }

    pipe<T>(options: PipeOptions = {
        navigateToNotFound: false,
    }): (input: Observable<T>) => Observable<T> {
        return (input: Observable<T>) => input.pipe(
            catchError((err) => {
                this.handleError(err, options);

                return throwError(err);
            }),
        );
    }

    private handleError(err: any, pipeOptions: PipeOptions): void {
        if ((err instanceof HttpErrorResponse) && !! (err.error)) {
            this.handleHttpErrorResponse(err, pipeOptions);
        } else {
            Sentry.captureException(err);
        }
    }

    private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse, pipeOptions: PipeOptions): void {
        if (isBazaErrorResponse(httpErrorResponse.error)) {
            this.handlerApiError(httpErrorResponse.error, pipeOptions);
        } else {
            this.handlerHttpError(httpErrorResponse.error, pipeOptions);
        }
    }

    private handlerApiError(error: BazaError, pipeOptions: PipeOptions): void {
        this._bazaErrors$.next({
            error,
            navigateToNotFound: pipeOptions.navigateToNotFound,
        });

        Sentry.captureException(error);
    }

    private handlerHttpError(error: any, pipeOptions: PipeOptions): void {
        this._httpErrors$.next({
            error,
            navigateToNotFound: pipeOptions.navigateToNotFound,
        });

        Sentry.captureException(error);
    }
}
