import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { TagDto, TagEndpoint, TagEndpointPaths } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class TagsDataAccess implements TagEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  getAll(): Observable<Array<TagDto>> {
    return this.http.get(TagEndpointPaths.getAll);
  }

  getById(id: number): Observable<TagDto> {
    return this.http.get(replacePathArgs(TagEndpointPaths.getById, { id }));
  }

  getByUrl(url: string): Observable<TagDto> {
    return this.http.get(replacePathArgs(TagEndpointPaths.getByUrl, { url }));
  }
}
