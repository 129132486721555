import { Injectable } from '@angular/core';
import { BrowserOptions } from '@sentry/browser';
import { BazaSentryNgConfig } from '../baza-sentry-ng.config';
import * as Sentry from '@sentry/angular';

@Injectable()
export class BazaSentryNgService {
    private ready = false;

    constructor(private readonly moduleConfig: BazaSentryNgConfig) {}

    get sentry() {
        this.init();

        return Sentry;
    }

    init(): void {
        if (!this.ready) {
            this.setupSentry({
                ...this.moduleConfig.sentry.browserOptions,
                dsn: this.moduleConfig.sentry.dsn,
            });

            this.ready = true;
        }
    }

    setupSentry(browserOptions: BrowserOptions): void {
        Sentry.init(browserOptions);
    }

    captureException(exception: unknown): void {
        this.init();

        Sentry.captureException(exception);
    }
}
