import { IsEmail, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { NorthCapitalIpAddress } from '../common-types/north-capital-ip-address';
import { IssuerStatus } from '../models/issuer-status';

export class UpdateIssuerRequest {
    @IsString()
    @IsNotEmpty()
    issuerName: string;

    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsEmail()
    @IsNotEmpty()
    email: string;

    @IsPositive()
    @IsInt()
    @IsOptional()
    phoneNumber?: number;

    @IsEnum(IssuerStatus)
    @IsOptional()
    issuerStatus?: IssuerStatus;

    @IsString()
    @IsOptional()
    updatedIpAddress?: NorthCapitalIpAddress;
}
