import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { NorthCapitalDate } from '../../../transact-api';
import { Type } from 'class-transformer';

export class PurchaseFlowPersonalInfoAddressDto {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialStreetAddress1: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialStreetAddress2: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialCity: string;

    @ApiModelProperty({
        description: 'State/Area. List of available states you may fetch with /list-states endpoint',
    })
    @IsString()
    @IsNotEmpty()
    residentialState: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    residentialZipCode: string;

    @ApiModelProperty({
        description: 'Country name (like "USA" or "Russia"). Available values are located in /form-resources endpoint in "countries" array',
    })
    @IsString()
    @IsNotEmpty()
    residentialCountry: string;
}

export class PurchaseFlowPersonalInfoDto {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    dateOfBirth: NorthCapitalDate;

    @ApiModelProperty({
        type: () => PurchaseFlowPersonalInfoAddressDto,
    })
    @ValidateNested()
    @Type(() => PurchaseFlowPersonalInfoAddressDto)
    @IsNotEmpty()
    address: PurchaseFlowPersonalInfoAddressDto;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    hasSsn: boolean;

    @ApiModelProperty()
    @IsString()
    @IsOptional()
    ssn?: string;
}
