import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export const ngBazaError = () => (input: Observable<any>) => input.pipe(
    catchError((err: HttpErrorResponse) => throwError(
        (!! err && typeof err === 'object' && err.error)
            ? err.error
            : err,
    )),
);
