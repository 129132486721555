import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { DwollaTransferId } from '../../../../dwolla-api/src';
import { BazaDwollaWebhookDto } from '../../../../dwolla-webhook/src';

export enum BazaDwollaE2eEndpointPaths {
    runSandboxSimulations = '/baza-dwolla/e2e/runSandboxSimulations',
    simulateWebhookEvent = '/baza-dwolla/e2e/simulateWebhookEvent',
    transferFundsFromMasterAccount = '/baza-dwolla/e2e/transferFundsFromMasterAccount',
    getTransferValue = '/baza-dwolla/e2e/getTransferValue',
}

export interface BazaDwollaE2eEndpoint {
    runSandboxSimulations(): Promise<void> | Observable<void>;
    simulateWebhookEvent(event: BazaDwollaWebhookDto): Promise<void> | Observable<void>;
    transferFundsFromMasterAccount(request: BazaDwollaE2eTransferFundsFromMasterAccountRequest): Promise<void> | Observable<void>;
    getTransferValue(
        request: BazaDwollaE2eGetTransferValueRequest,
    ): Promise<BazaDwollaE2eGetTransferValueResponse> | Observable<BazaDwollaE2eGetTransferValueResponse>;
}

export class BazaDwollaE2eTransferFundsFromMasterAccountRequest {
    @ApiModelProperty({
        description: 'Dwolla Customer Id',
        example: 'FC451A7A-AE30-4404-AB95-E3553FCD733F',
    })
    @IsString()
    dwollaCustomerId: string;

    @ApiModelProperty({
        description: 'Amount',
        example: '50.00',
    })
    @IsString()
    amount: string;
}

export class BazaDwollaE2eGetTransferValueRequest {
    @ApiModelProperty({
        description: 'Dwolla Transfer Id',
        type: 'string',
    })
    @IsString()
    dwollaTransferId: DwollaTransferId;
}

export class BazaDwollaE2eGetTransferValueResponse {
    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;
}
