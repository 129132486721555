import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcWithdrawEndpoint,
    BazaNcWithdrawEndpointPaths,
    BazaNcWithdrawListRequest,
    BazaNcWithdrawListResponse,
    BazaNcWithdrawReprocessRequest,
    BazaNcWithdrawRequest,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { BazaDwollaPaymentDto } from '@scaliolabs/baza-dwolla-shared';

/**
 * Data-Access for Baza NC Withdraw
 */
@Injectable()
export class BazaNcWithdrawalDataAccess implements BazaNcWithdrawEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    /**
     * Returns list of withdraw requests
     * @param request
     */
    list(request: BazaNcWithdrawListRequest): Observable<BazaNcWithdrawListResponse> {
        return this.http.get(BazaNcWithdrawEndpointPaths.list, request);
    }

    /**
     * Withdraw available balance
     * Withdrawing available balance from Dwolla account to an external bank account
     * @param request
     */
    withdraw(request: BazaNcWithdrawRequest): Observable<BazaDwollaPaymentDto> {
        return this.http.post(BazaNcWithdrawEndpointPaths.withdraw, request);
    }

    /**
     * Reprocess failed withdrawals
     * Will reprocess the failed withdrawal request
     * @param request
     */
    reprocess(request: BazaNcWithdrawReprocessRequest): Observable<BazaDwollaPaymentDto> {
        return this.http.post(BazaNcWithdrawEndpointPaths.reprocess, request);
    }
}
