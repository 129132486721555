import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { FooterMiniComponent } from './footer-mini.component';

@NgModule({
    declarations: [FooterMiniComponent],
    exports: [FooterMiniComponent],
    imports: [CommonModule, NzGridModule, NzLayoutModule, RouterModule],
})
export class FooterMiniModule {}
