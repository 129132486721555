import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { OrderStatus, TradeId } from '../../../transact-api';
import { TransactionState } from '../../../transaction';

export class SynAllTradeStatusesResultEntry {
    @ApiModelProperty()
    ncTradeId: TradeId;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    @IsEnum(OrderStatus)
    @IsNotEmpty()
    origOrderStatus: OrderStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
    })
    @IsEnum(TransactionState)
    @IsNotEmpty()
    origState: TransactionState;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(OrderStatus),
    })
    @IsEnum(OrderStatus)
    @IsNotEmpty()
    headOrderStatus: OrderStatus;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(TransactionState),
    })
    @IsEnum(TransactionState)
    @IsNotEmpty()
    headState: TransactionState;

    @ApiModelProperty({
        required: false,
    })
    error?: unknown;
}

export class SyncAllTradeErrorEntry {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    ncTradeId: TradeId;

    @ApiModelProperty()
    @IsNotEmpty()
    error: unknown;
}

export class SynAllTradeStatusesResult {
    @ApiModelProperty({
        type: () => SynAllTradeStatusesResultEntry,
        isArray: true,
    })
    @IsArray()
    @IsNotEmpty()
    updatedNcTransactions: Array<SynAllTradeStatusesResultEntry | SyncAllTradeErrorEntry>;
}
