import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharePickerResolver } from './data-access';
import { PurchaseDoneComponent } from './done/done.component';
import { PurchaseComponent } from './purchase.component';

export const getConfigurableRoutes: (baseComponent, doneComponent) => Routes = (baseComponent, doneComponent) => [
    {
        path: '',
        data: {
            layout: 'mini',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'details',
            },
            {
                path: 'details',
                component: baseComponent,
                data: {
                    tab: '0',
                    meta: {
                        title: 'Purchase Details',
                    },
                },
            },
            {
                path: 'agreement',
                component: baseComponent,
                resolve: {
                    numberOfShares: SharePickerResolver,
                },
                data: {
                    tab: '1',
                    meta: {
                        title: 'Sign Agreement',
                    },
                },
            },
            {
                path: 'payment',
                component: baseComponent,
                resolve: {
                    numberOfShares: SharePickerResolver,
                },
                data: {
                    tab: '2',
                    meta: {
                        title: 'Submit Payment',
                    },
                },
            },
            {
                path: 'done',
                component: doneComponent,
                data: {
                    layout: 'mini-center',
                    meta: {
                        title: 'Done',
                    },
                },
            },
        ],
    },
];

const routes: Routes = getConfigurableRoutes(PurchaseComponent, PurchaseDoneComponent);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PurchaseRoutingModule {}
