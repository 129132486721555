import { AccountDto } from '../../../../baza-account/src';
import { BazaReferralCodeDto } from './baza-referral-code.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaReferralAccountDto extends AccountDto {
    @ApiModelProperty({
        type: BazaReferralCodeDto,
        required: false,
    })
    referralCode?: BazaReferralCodeDto;

    @ApiModelProperty({
        description: 'Signed up with referral code',
        required: false,
    })
    signedUpWithReferralCode?: string;
}
