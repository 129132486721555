import { EventDto } from './event.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class EventCmsDto extends EventDto {
    @ApiModelProperty()
    isPublished: boolean;

    @ApiModelProperty()
    imageAwsS3Key: string;

    @ApiModelProperty()
    thumbnailAwsS3Key: string;
}
