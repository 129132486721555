import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrowLeftOutline, ArrowRightOutline } from '@ant-design/icons-angular/icons';
import { ImageCarouselComponent } from './image-carousel.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';

@NgModule({
    declarations: [ImageCarouselComponent],
    exports: [ImageCarouselComponent],
    imports: [CommonModule, NzCarouselModule, NzImageModule, NzIconModule.forChild([ArrowLeftOutline, ArrowRightOutline])],
})
export class ImageCarouselModule {}
