import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsOptional } from 'class-validator';

export class BazaNcIntegrationListingsSeoDto {
    @ApiModelProperty()
    @IsOptional()
    pageTitle?: string;

    @ApiModelProperty()
    @IsOptional()
    slug?: string;
}
