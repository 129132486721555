export const WebUiMarketingBulletsEnI18n = {
    textBullets: {
        section0: {
            bullets: [`Arcu mauris lectus`, `Arcu mauris lectus`, `Arcu mauris lectus`, `Arcu mauris lectus`],
        },
    },
    imageBullets: {
        section0: {
            label: `As seen on`,
            images: [
                `/assets/images/marketwatch.png`,
                `/assets/images/yahoo-finance.png`,
                `/assets/images/bloomberg.png`,
                `/assets/images/benzinga.png`,
            ],
        },
    },
};
