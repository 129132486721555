import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal/modal-ref';

export interface BazaNgConfirmConfig {
    message: string;
    messageArgs?: any;
    title?: string;
    titleArgs?: any;
    confirmText?: string;
    rejectText?: string;
    confirm?: (modalRef: NzModalRef) => Promise<unknown> | void;
    reject?: (modalRef: NzModalRef) => Promise<unknown> | void;
    withoutTitleTranslate?: boolean;
    withoutMessageTranslate?: boolean;
    withoutConfirmTextTranslate?: boolean;
    withoutRejectTextTranslate?: boolean;
}

@Injectable()
export class BazaNgConfirmService {
    constructor(private readonly nzModal: NzModalService, private readonly translate: TranslateService) {}

    open(config: BazaNgConfirmConfig): void {
        const nzTitle = config.title
            ? config.withoutTitleTranslate
                ? config.title
                : this.translate.instant(config.title, config.titleArgs)
            : this.translate.instant(this.i18n('title'));

        const nzOkText = config.confirmText
            ? config.withoutConfirmTextTranslate
                ? config.confirmText
                : this.translate.instant(config.confirmText)
            : this.translate.instant(this.i18n('confirmText'));

        const nzCancelText = config.rejectText
            ? config.withoutRejectTextTranslate
                ? config.rejectText
                : this.translate.instant(config.rejectText)
            : this.translate.instant(this.i18n('rejectText'));

        const modalRef = this.nzModal.confirm({
            nzTitle,
            nzOkText,
            nzCancelText,
            nzClosable: true,
            nzClassName: 'baza-ng-common-confirm-modal',
            nzContent: config.withoutMessageTranslate ? config.message : this.translate.instant(config.message, config.messageArgs),
            nzOnOk: config.confirm ? config.confirm : undefined,
            nzOnCancel: config.reject ? config.reject : () => modalRef.close(),
        });
    }

    private i18n(key: string): string {
        return `baza.common.services.bazaNgConfirm.${key}`;
    }
}
