import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaDwollaDistributionPaymentSource } from '../models/baza-dwolla-distribution-payment-source';
import { BazaDwollaDistributionPaymentStatus } from '../models/baza-dwolla-distribution-payment-status';

export class BazaDwollaDistributionCmsCsvDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaDwollaDistributionPaymentSource),
    })
    source: BazaDwollaDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        description: 'Investor Account',
    })
    investorAccount: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Account ID)',
    })
    ncAccountId: string;

    @ApiModelProperty({
        description: 'Investor Account (NC Party ID)',
    })
    ncPartyId: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaDistributionPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaDwollaDistributionPaymentStatus;

    @ApiModelProperty({
        description: 'Offering ID',
        required: false,
    })
    ncOfferingId?: string;

    @ApiModelProperty({
        description: 'Offering',
        required: false,
    })
    offering?: string;
}
