import { Injectable } from '@angular/core';
import { BazaRegistryDataAccess } from '@scaliolabs/baza-core-data-access';
import { Resolve } from '@angular/router';
import { BazaRegistryPublicSchema } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaRegistryNgService } from './baza-registry-ng.service';
import { tap } from 'rxjs/operators';
import { BAZA_NG_PROJECT_INJECT } from '../../baza-common';

@Injectable()
export class BazaRegistryNgResolve implements Resolve<BazaRegistryPublicSchema> {
    constructor(private readonly endpoint: BazaRegistryDataAccess, private readonly service: BazaRegistryNgService) {}

    resolve(): Observable<BazaRegistryPublicSchema> {
        return this.endpoint.publicSchema().pipe(
            tap((next) => (this.service.publicSchema = next)),
            tap((next) => {
                const clientName = next.find((c) => c.node.name === 'Client Name');

                if (clientName) {
                    BAZA_NG_PROJECT_INJECT.clientName = clientName.node.value as string;
                }
            }),
        );
    }
}
