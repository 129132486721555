<ng-container *ngIf="stripeCCLink$ | async as stripeCCLink; else skeleton">
    <div *ngIf="paymentMethodType === 'creditCard'">
        <div class="card-icon card-icon-flex">
            <ng-container *ngFor="let cardBrandIcon of cardBrandIcons">
                <div class="card-icon__item">
                    <svg-icon-sprite [src]="cardBrandIcon.icon"></svg-icon-sprite>
                </div>
            </ng-container>
        </div>

        <app-iframe-integration
            [url]="stripeCCLink?.link"
            [classList]="'iframe-stripe'"
            (iframeEvent)="onIFrameEvent($event)"></app-iframe-integration>
    </div>
</ng-container>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
