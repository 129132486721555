import { BazaI18nNgConfig, BazaI18nNgModuleForRootOptions, BazaI18nNgService } from '@scaliolabs/baza-core-ng';
import { TranslateLoader } from '@ngx-translate/core';
import { BazaI18nNgNxHttpLoader } from '@scaliolabs/baza-core-ng';
import { TranslateModuleConfig } from '@ngx-translate/core/public_api';
import { BAZA_COMMON_I18N_CONFIG, getBazaProjectCodeName } from '@scaliolabs/baza-core-shared';
import { BazaWebBundleConfig } from '../baza-web-bundle.config';

export const BAZA_NG_I18_MODULE_CONFIG: (config: BazaWebBundleConfig) => BazaI18nNgModuleForRootOptions = () => ({
    deps: [],
    useFactory: () => ({
        localStorageKey: `web${getBazaProjectCodeName().toLowerCase()}I18n`,
        reloadBrowserOnLanguageChange: true,
    }),
});

export const NX_I18N_MODULE_CONFIG: (config: BazaWebBundleConfig) => TranslateModuleConfig = () => ({
    defaultLanguage: BAZA_COMMON_I18N_CONFIG.defaultLanguage,
    loader: {
        provide: TranslateLoader,
        useClass: BazaI18nNgNxHttpLoader,
        deps: [BazaI18nNgConfig, BazaI18nNgService],
    },
});
