export enum CoreAcl {
    // Complete control over all CMS
    SystemRoot = 'SystemRoot',

    // Auth Master Password
    BazaAuthMasterPassword = 'BazaAuthMasterPassword',

    // ACL management
    BazaAcl = 'BazaAcl',
    BazaSetAcl = 'BazaSetAcl',

    // Registry
    BazaRegistry = 'BazaRegistry',
    BazaRegistryManagement = 'BazaRegistryManagement',

    // Sitemap
    BazaSitemap = 'BazaSitemap',
}
