import { BazaDwollaPaymentStatus } from '../models/baza-dwolla-payment-status';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDwollaPaymentHistoryActions, bazaDwollaPaymentHistoryActionsSwagger } from '../models/baza-dwolla-payment-history-action';

export class BazaDwollaPaymentHistoryDto {
    @ApiModelProperty({
        type: 'string',
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaDwollaPaymentStatus),
        description: 'Dwolla Payment Status',
    })
    status: BazaDwollaPaymentStatus;

    @ApiModelProperty({
        ...bazaDwollaPaymentHistoryActionsSwagger(),
        description: 'Action & Action Details',
    })
    action?: BazaDwollaPaymentHistoryActions;
}
