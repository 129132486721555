export class BazaContentTypeBlogConfig {
    maxFullImageWidth: number;
    maxPreviewImageWidth: number;
}

export function bazaContentTypeBlogDefaultConfig(): BazaContentTypeBlogConfig {
    return {
        maxFullImageWidth: 720,
        maxPreviewImageWidth: 320,
    };
}
