// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BazaAccountConstants {
    export const TOKEN_LENGTH = 16;
    export const TOKEN_DIGITS_ONLY_LENGTH = 6;

    export const V_PASSWORD_MIN = 3;
    export const V_PASSWORD_MAX = 32;

    // You can override default mail link generators here
    export const mailLinkGenerators = {
        confirmEmailCmsLink: (token: string) => `?confirmEmail=1&token=${token}`,
        confirmEmailWebLink: (token: string) => `/mail/confirm-email?token=${token}`,
        resetPasswordCmsLink: (token: string) => `/auth/reset-password?token=${token}`,
        resetPasswordWebLink: (token: string) => `/mail/reset-password?token=${token}`,
        changeEmailCmsLink: (token: string) => `?changeEmail=1&token=${token}`,
        changeEmailWebLink: (token: string) => `/mail/change-email?token=${token}`,
        deactivateAccountLink: (token: string) => `/mail/deactivate-account?token=${token}`,
    };

    export const jwtMaxDevicesPerAccount = 15;

    export const USER_NAME_MAX_LENGTH = 60;
    export const EMAIL_MAX_LENGTH = 255;
}
