import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaDwollaCreatePersonalCustomerResponse, CreateDocumentRequest } from '@scaliolabs/baza-dwolla-shared';
import { IsBoolean, IsInt, IsNotEmpty, IsPositive } from 'class-validator';
import { Observable } from 'rxjs';
import { BazaNcDwollaTouchResponse } from '../dto/baza-nc-dwolla-touch-response.dto';
import {} from 'multer';

export enum BazaNcDwollaCmsEndpointPaths {
    touch = '/baza-nc/dwolla/cms/touch',
    touchAllInvestors = '/baza-nc/dwolla/cms/touch/all-investors',
    retryVerification = '/baza-nc/dwolla/cms/retryVerification',
    createDocument = '/baza-nc/dwolla/cms/createDocument',
}

export interface BazaNcDwollaCmsEndpoint {
    touch(
        request: BazaNcTouchDwollaCustomerRequest,
    ): Promise<BazaNcTouchDwollaCustomerResponse> | Observable<BazaNcTouchDwollaCustomerResponse>;
    touchAllInvestors(request: BazaNcDwollaCmsTouchAllRequest): Promise<void> | Observable<void>;
    retryVerificationForPersonalCustomer(
        body: BazaNcTouchDwollaCustomerRequest,
    ): Promise<BazaDwollaCreatePersonalCustomerResponse> | Observable<BazaDwollaCreatePersonalCustomerResponse>;
    createDocumentForCustomer(file: Express.Multer.File, request: CreateDocumentRequest): Promise<void> | Observable<void>;
}

export class BazaNcTouchDwollaCustomerRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;
}

export class BazaNcTouchDwollaCustomerResponse extends BazaNcDwollaTouchResponse {}

export class BazaNcDwollaCmsTouchAllRequest {
    @ApiModelProperty({
        description: 'Run in Async mode?',
    })
    @IsBoolean()
    @IsNotEmpty()
    async: boolean;
}
