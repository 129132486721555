import { BazaNcDividendHistoryDto } from './baza-nc-dividend-history.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ulid } from 'ulid';
import { BazaNcDividendPaymentSource } from '../models/baza-nc-dividend-payment-source';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import { OfferingId } from '../../../../transact-api';

export class BazaNcDividendDto {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcDividendPaymentSource),
    })
    source: BazaNcDividendPaymentSource;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering ID',
        required: false,
    })
    offeringId?: OfferingId;

    @ApiModelProperty({
        description: 'Offering Title',
        required: false,
    })
    offeringTitle?: string;

    @ApiModelProperty({
        description: 'Amount (as string)',
        example: '50.00',
    })
    amount: string;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    amountCents: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcDividendPaymentStatus),
        description: 'Payment Status',
    })
    status: BazaNcDividendPaymentStatus;

    @ApiModelProperty({
        description: 'Can be reprocessed?',
    })
    canBeReprocessed: boolean;

    @ApiModelProperty({
        type: BazaNcDividendHistoryDto,
        isArray: true,
        description: 'Payment status updates history',
    })
    history: Array<BazaNcDividendHistoryDto>;
}
