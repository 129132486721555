import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { BazaNcIntegrationSchemaType } from './baza-nc-integration-schema-type';

export class BazaNcIntegrationSchemaTabField {
    @ApiModelProperty({
        description: 'Title of the definition',
    })
    @IsString()
    @IsNotEmpty()
    name: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcIntegrationSchemaType),
        description: 'Schema type',
    })
    @IsEnum(BazaNcIntegrationSchemaType)
    @IsNotEmpty()
    type: BazaNcIntegrationSchemaType;

    @ApiModelProperty({
        description: 'Property key',
    })
    @IsString()
    @IsNotEmpty()
    key: string;

    @ApiModelProperty({
        description: 'Required or not',
    })
    @IsBoolean()
    @IsNotEmpty()
    required: string;

    @ApiModelProperty({
        description: 'Value of the definition',
    })
    value: unknown;
}
