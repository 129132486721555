import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { bazaSeoKnownMetaTags } from '../models/baza-seo-known-meta-tags';

export class BazaSeoMetaDto {
    @ApiModelProperty({
        description: 'META name',
        enum: bazaSeoKnownMetaTags,
    })
    name: string;

    @ApiModelProperty({
        description: 'Contents of META',
    })
    contents: string;
}
