import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-add-funds-notification',
    templateUrl: './add-funds-notification.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzModalModule, NzButtonModule],
})
export class DwollaAddFundsNotificationComponent {
    @Output()
    closeModal = new EventEmitter<void>();

    i18nBasePath = 'uic.addFunds.notification';

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
