export const PurchaseMethodsEnI18n = {
    title: `Payment Method`,
    errors: {
        insufficientFunds: `There are insufficient funds in your account to complete your purchase. Please choose another payment method below.`,
    },
    actions: {
        editMethod: `Edit your payment method`,
        submit: `Submit Payment`,
        back: `Back`,
    },
    alerts: {
        confirmUpdate: {
            title: `Card Details`,
        },
    },
};
