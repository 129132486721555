<div class="retry-summary">
    <h4 class="retry-summary__title">{{ transaction?.totalCents | priceCents }}</h4>

    <nz-collapse
        class="retry-summary-collapse"
        [nzBordered]="false">
        <nz-collapse-panel
            class="retry-summary-collapse__panel"
            [nzActive]="true"
            [nzHeader]="summaryHeader">
            <ng-template [ngTemplateOutlet]="summaryDetails"></ng-template>
        </nz-collapse-panel>
    </nz-collapse>

    <ng-template #summaryHeader>
        <span>{{ transaction?.name }}, {{ transaction?.shares }} shares</span>
    </ng-template>

    <ng-template #summaryDetails>
        <ul class="retry-summary-menu">
            <li class="retry-summary-menu__item">
                <span>Price per share</span>
                <span>{{ transaction?.pricePerShareCents | priceCents }}</span>
            </li>
            <li class="retry-summary-menu__item item-transaction">
                <span class="item-transaction__fee">
                    Transaction fee

                    <div
                        class="item-transaction__hint"
                        nz-popover
                        nzPopoverPlacement="right"
                        nzPopoverContent="This is a due diligence fee paid to cover costs associated with investigating, evaluating and purchasing underlying portfolio assets">
                        <i class="icon icon-info-circle icon-wt"></i>
                    </div>
                </span>
                <span>{{ transaction?.transactionFeesCents | priceCents }}</span>
            </li>
        </ul>
    </ng-template>
</div>
