import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  NewsletterEndpoint,
  NewsletterEndpointPaths,
  NewsletterSubscribeRequest,
  NewsletterUnsubscribeRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class NewslettersDataAccess implements NewsletterEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  subscribe(request: NewsletterSubscribeRequest): Observable<void> {
    return this.http.post(NewsletterEndpointPaths.subscribe, request);
  }

  unsubscribe(request: NewsletterUnsubscribeRequest): Observable<void> {
    return this.http.post(NewsletterEndpointPaths.unsubscribe, request);
  }
}
