import { CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcDividendDto } from '../dto/baza-nc-dividend.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Observable } from 'rxjs';
import { BazaNcDividendPaymentStatus } from '../models/baza-nc-dividend-payment-status';
import { IsNotEmpty, IsString } from 'class-validator';
import { ulid } from 'ulid';

export enum BazaNcDividendEndpointPaths {
    list = '/baza-nc/dividend/list',
    getByUlid = '/baza-nc/dividend/get/:ulid',
    totalAmount = '/baza-nc/dividend/total',
    reprocess = '/baza-nc/dividend/reprocess',
}

export interface BazaNcDividendEndpoint {
    list(
        request: BazaNcDividendListRequest,
        ...args: unknown[]
    ): Promise<BazaNcDividendListResponse> | Observable<BazaNcDividendListResponse>;
    getByUlid(ulid: string, ...args: unknown[]): Promise<BazaNcDividendDto> | Observable<BazaNcDividendDto>;
    totalAmount(...args: unknown[]): Promise<BazaNcDividendTotalAmountResponse> | Observable<BazaNcDividendTotalAmountResponse>;
    reprocess(request: BazaNcDividendReprocessRequest, ...args: unknown[]): Promise<BazaNcDividendDto> | Observable<BazaNcDividendDto>;
}

export class BazaNcDividendListRequest extends CrudListRequestDto<BazaNcDividendDto> {
    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(BazaNcDividendPaymentStatus),
        required: false,
    })
    status?: Array<BazaNcDividendPaymentStatus>;
}

export class BazaNcDividendListResponse extends CrudListResponseDto<BazaNcDividendDto> {
    @ApiModelProperty({
        type: BazaNcDividendDto,
        isArray: true,
    })
    items: Array<BazaNcDividendDto>;
}

export class BazaNcDividendTotalAmountResponse {
    @ApiModelProperty({
        example: '12.55',
        description: 'Total Amount',
    })
    totalAmount: string;

    @ApiModelProperty({
        example: 1255,
        description: 'Total Amount (as cents)',
    })
    totalAmountCents: number;
}

export class BazaNcDividendReprocessRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}
