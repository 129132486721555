import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import { StorageService } from '@scaliolabs/baza-web-utils';
import { Observable, of } from 'rxjs';
import { PurchaseState, SelectEntity } from '../store';

@Injectable({ providedIn: 'root' })
export class CartResolver implements Resolve<BazaNcIntegrationListingsDto> {
    constructor(private readonly store: Store, private readonly storageService: StorageService) {}

    resolve(): Observable<BazaNcIntegrationListingsDto> {
        const entityId = this.storageService.getObject<number>('cart');
        const cartInfo = this.store.selectSnapshot(PurchaseState.cart);
        return entityId && !cartInfo ? this.store.dispatch(new SelectEntity(entityId)) : of(cartInfo);
    }
}
