export const WebUiAddFundsModalEnI18n = {
    title: 'Transfer Funds To Account Balance',
    cashIn: {
        heading: 'Transfer From',
        actions: {
            edit: 'Edit',
        },
    },
    actions: {
        transfer: 'Transfer Funds',
    },
};
