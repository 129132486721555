import { RecurringTransactionFrequency } from '../../../transact-api';
import { BazaNcPurchaseFlowTransactionType } from '../../../purchase-flow';
import { BazaNcRecurringTransactionStatus } from '../models/baza-nc-recurring-transaction-status';
import { BazaRecurringTransactionMetadataInterface } from '../interface/baza-recurring-transaction-metadata.interface';

export class RecurringTransactionDto {
    id: number;
    shares: number;
    amountCents: number;
    recurringFrequency: RecurringTransactionFrequency;
    transactionType: BazaNcPurchaseFlowTransactionType;
    metadata?: BazaRecurringTransactionMetadataInterface;
    updatedAt?: Date;
    lastTransactionDate?: Date;
    createdAt: Date;
    ncOfferingId: string;
    status: BazaNcRecurringTransactionStatus;
    ncTradeId?: string;
    offeringName: string;
}
