import { AbstractControl, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { bazaValidatePassword, isBazaErrorResponse, isEmpty } from '@scaliolabs/baza-core-shared';
import { BazaPasswordDataAccess } from '@scaliolabs/baza-core-data-access';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * Shared service which returns ReactiveFormsModule validators for Sign In like forms.
 * Please note that extended validation from baza-password package is not added by
 * default here. You additional resources from baza-password package for Sign Up
 * form. You should not use extended password validation for Sign In because
 * some of accounts are managed by DevOps side and these accounts may have
 * non-standard passwords.
 */
@Injectable()
export class AuthValidatorsService {
    constructor(
        private readonly passwordDataAccess: BazaPasswordDataAccess,
    ) {
    }

    /**
     * Validators for Email field
     */
    emailValidators(): ValidatorFn[] {
        return [
            Validators.required,
            Validators.email,
        ];
    }

    /**
     * Password Validator with extended OWASP rules
     */
    passwordValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (isEmpty(control.value)) {
                return null;
            }

            const response = bazaValidatePassword(control.value);

            if (response.isValid) {
                return null;
            } else {
                return {
                    password: true,
                };
            }
        };
    }

    /**
     * Validators for Password field
     * @deprecated
     */
    passwordValidators(): ValidatorFn[] {
        return this.signInValidators();
    }

    /**
     * Validators for Sign In password field
     */
    signInValidators(): ValidatorFn[] {
        return [
            Validators.required,
        ];
    }

    /**
     * Validators for Sign Up password field
     */
    signUpValidators(): ValidatorFn[] {
        return [
            this.passwordValidator(),
        ];
    }

    /**
     * Async password validator with extended OWASP rules (using API)
     */
    asyncPasswordValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (isEmpty(control.value)) {
                return of(null);
            }

            return this.passwordDataAccess.validate({
                password: control.value,
            }).pipe(
                map((response) => {
                    if (isBazaErrorResponse(response)) {
                        return {
                            password: true,
                        };
                    } else {
                        if (response.isValid) {
                            return null;
                        } else {
                            return {
                                password: true,
                            };
                        }
                    }
                })
            );
        };
    }

    /**
     * Validator for new + repeat password (Reset Password and Sign Up form).
     */
    newAndRepeatPasswordAsyncValidator(): AsyncValidatorFn {
        return async (form) => {
            const formValue: {
                newPassword: string;
                repeatPassword: string;
            } = form.value;

            if (! formValue.newPassword || ! formValue.repeatPassword) {
                return null;
            } else if (formValue.newPassword !== formValue.repeatPassword) {
                return {
                    passwordsAreNotSame: true,
                };
            }

            return null;
        };
    }
}
