import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { NewsDto, NewsEndpoint, NewsEndpointPaths, NewsListRequest, NewsListResponse } from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class NewsDataAccess implements NewsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: NewsListRequest): Observable<NewsListResponse> {
    return this.http.get(NewsEndpointPaths.list, request);
  }

  getById(id: number): Observable<NewsDto> {
    return this.http.get(replacePathArgs(NewsEndpointPaths.getById, { id }));
  }

  getByUrl(url: string): Observable<NewsDto> {
    return this.http.get(replacePathArgs(NewsEndpointPaths.getByUrl, { url }));
  }
}
