import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  NewsletterCmsAddRequest,
  NewsletterCmsEndpoint,
  NewsletterCmsEndpointPaths,
  NewsletterCmsExportToCsvExportRequest,
  NewsletterCmsListRequest,
  NewsletterCmsListResponse,
  NewsletterCmsRemoveRequest,
  NewslettersDto,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class NewslettersCmsDataAccess implements NewsletterCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  list(request: NewsletterCmsListRequest): Observable<NewsletterCmsListResponse> {
    return this.http.post(NewsletterCmsEndpointPaths.list, request);
  }

  exportToCSV(request: NewsletterCmsExportToCsvExportRequest): Observable<string> {
    return this.http.post(NewsletterCmsEndpointPaths.exportToCSV, request, { responseType: 'text' });
  }

  add(request: NewsletterCmsAddRequest): Observable<NewslettersDto> {
    return this.http.post(NewsletterCmsEndpointPaths.add, request);
  }

  remove(request: NewsletterCmsRemoveRequest): Observable<void> {
    return this.http.post(NewsletterCmsEndpointPaths.remove, request);
  }
}
