import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaNcIntegrationPerkDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty({
        description: 'Could be used for displaying icons in applications or something like tag',
        required: false,
    })
    type?: string;

    @ApiModelProperty({
        description: 'Is perk available for Elite Investors Members?',
    })
    isForEliteInvestors: boolean;

    @ApiModelProperty({
        description: 'Metadata key-value object',
        example: {
            foo: 'bar',
            bar: 'baz',
        },
    })
    metadata: Record<string, string>;
}
