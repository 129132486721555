export const WebUiPaymentItemCardEnI18n = {
    title: 'Card',
    details: {
        number: 'Card number:',
        expiring: 'Expiring:',
        fee: 'card processing fee',
    },
    alerts: {
        limit: 'Purchases above {{ limit }} require the use of a linked bank account.',
        confirmUpdate: {
            title: `Update Credit Card`,
            descr: `Are you sure you would like to update your credit card? <br/> <br/> Please note that previously added credit card will be deleted.`,
            cancelBtnText: `Cancel`,
            confirmBtnText: `Confirm`,
        },
    },
    addMode: 'Card Details',
    actions: {
        update: 'Update',
    },
};
