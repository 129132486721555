import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsNumber, IsString } from 'class-validator';
import { BazaNcSearchOperator } from '../baza-nc-search-operator';
import { BazaNcSearchField, bazaNcSearchFieldSwagger } from '../baza-nc-search-field';

export class BazaNcSearchOperatorLessThan {
    @ApiModelProperty({
        description: 'Field',
        ...bazaNcSearchFieldSwagger(),
    })
    @IsString()
    @IsNotEmpty()
    field: BazaNcSearchField;

    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcSearchOperator.LessThan],
    })
    @IsEnum([BazaNcSearchOperator.LessThan])
    @IsNotEmpty()
    type: BazaNcSearchOperator.LessThan;

    @ApiModelProperty()
    @IsNumber()
    @IsNotEmpty()
    value: number;
}
