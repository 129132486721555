import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '../../../../../ng/src';
import {
    Auth2FAEndpoint,
    Auth2FAEndpointPaths,
    Auth2FAMethodsRequest,
    Auth2FAMethodsResponse,
    Auth2FARequest,
    Auth2FAVerifyRequest,
    AuthResponse,
    AuthGoogle2FAQrCodeRequest,
    AuthGoogle2FAQrCodeResponse,
} from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';

@Injectable()
export class BazaAuth2FADataAccess implements Auth2FAEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    auth2FA(request: Auth2FARequest): Observable<void> {
        return this.http.post(Auth2FAEndpointPaths.auth2FA, request);
    }

    auth2FAVerify(request: Auth2FAVerifyRequest): Observable<AuthResponse> {
        return this.http.post(Auth2FAEndpointPaths.auth2FAVerify, request);
    }

    auth2FAMethods(request: Auth2FAMethodsRequest): Observable<Auth2FAMethodsResponse> {
        return this.http.post(Auth2FAEndpointPaths.auth2FAMethods, request);
    }

    generate2FAGoogleQrCode(request: AuthGoogle2FAQrCodeRequest): Observable<AuthGoogle2FAQrCodeResponse> {
        return this.http.post(Auth2FAEndpointPaths.generate2FAGoogleQrCode, request);
    }
}
