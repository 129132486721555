export const DwollaPaymentEditListEnI18n = {
    title: {
        singular: 'Payment Method',
        plural: 'Payment Methods',
    },
    balance: {
        title: 'Account Balance',
        prefix: 'Current balance:',
        hint: '1-2 business days',
        errors: {
            insufficientFunds:
                'There are insufficient funds in your account to complete your purchase. Please choose another payment method below.',
        },
    },
    bank: {
        title: 'Bank Account',
        subtitle: '',
        actions: {
            update: 'Update',
        },
    },
    card: {
        title: 'Card',
        feePrefix: '',
        feeSuffix: 'Card processing fee',
        limitsWarning: 'Payment transactions above {{ maxAmount }} require ACH via linked bank account',
        actions: {
            update: 'Update',
        },
        alerts: {
            confirmUpdate: {
                title: `Update Credit Card`,
                descr: ` Are you sure you would like to update your credit card? <br/> <br/> Please note that previously added credit card will be deleted.`,
                cancelBtnText: `Cancel`,
                confirmBtnText: `Confirm`,
            },
        },
    },
};
