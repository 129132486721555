import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AccountVerificationStep } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, LEGACY_NC_MODE_QUERY_PARAM } from '@scaliolabs/baza-web-utils';
import { Observable, combineLatest, distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs';
import { VFConfig, Verification, VerificationState } from '../data-access';

@Component({
    selector: 'app-verification-check',
    templateUrl: './check.component.html',
})
export class VerificationCheckComponent {
    verification$ = this.store.select(VerificationState.verification);
    vfConfig$ = this.store.select(VerificationState.vfConfig);

    hasStarted = true;
    navigationUrl: string;
    i18nBasePath = 'dwvf.check';
    result$: Observable<{
        verification: Verification;
        vfConfig: VFConfig;
    }>;
    showDisclaimer = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        public readonly wts: BazaWebUtilSharedService,
    ) {
        this.checkVerificationStatus();
    }

    checkVerificationStatus() {
        this.result$ = combineLatest([this.verification$, this.vfConfig$]).pipe(
            distinctUntilChanged(),
            map(([verification, vfConfig]) => ({
                verification,
                vfConfig,
            })),
            filter((pair) => !!pair.verification && !!pair.vfConfig),
            take(1),
            tap((pair) => {
                const queryParams = {
                    back: pair.vfConfig?.back,
                    redirect: pair.vfConfig?.redirect ?? null,
                    mode: !pair.vfConfig?.dwollaSupport && this.route.snapshot?.queryParams?.mode ? LEGACY_NC_MODE_QUERY_PARAM : null,
                };

                if (pair.verification && pair.verification?.currentStep) {
                    switch (pair.verification?.currentStep) {
                        case AccountVerificationStep.Completed:
                            this.router.navigateByUrl(pair.vfConfig.redirect);
                            break;

                        case AccountVerificationStep.Citizenship:
                        case AccountVerificationStep.DateOfBirth:
                        case AccountVerificationStep.LegalName:
                        case AccountVerificationStep.ResidentialAddress:
                        case AccountVerificationStep.SSN:
                            this.showDisclaimer = true;
                            this.populateNavigationUrl([pair.vfConfig.vfLink, 'info'], queryParams);
                            break;

                        case AccountVerificationStep.RequestDocuments:
                        case AccountVerificationStep.RequestSSNDocument:
                        case AccountVerificationStep.InvestorProfile:
                            this.showDisclaimer = true;
                            this.populateNavigationUrl([pair.vfConfig.vfLink, 'investor'], queryParams);
                            break;

                        default:
                            this.hasStarted = false;
                            this.showDisclaimer = true;
                            this.populateNavigationUrl([pair.vfConfig.vfLink, 'info'], queryParams);
                            break;
                    }
                }
            }),
            shareReplay(),
        );
    }

    populateNavigationUrl(commands: unknown[], queryParams?: Params) {
        const routerTree = this.router.createUrlTree(commands, {
            queryParams: queryParams,
        });
        this.navigationUrl = this.router.serializeUrl(routerTree);
    }

    continueCheckVerification() {
        this.router.navigateByUrl(this.navigationUrl);
    }
}
