import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class AuthMasterPasswordDto {
    @ApiModelProperty({
        description: 'Is Master Password enabled?',
    })
    enabled: boolean;

    @ApiModelProperty({
        description: 'Current password (is Master Password is enabled)',
        required: false,
    })
    current?: string;
}
