import { ulid } from 'ulid';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsDateString, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString, MaxLength, MinLength } from 'class-validator';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { BazaNcPayoffDistributionTransactionEntryDto } from '../dto/baza-nc-payoff-distribution-transaction-entry.dto';
import { BazaNcPayoffDistributionPaymentSource } from '../models/baza-nc-payoff-distribution-payment-source';
import { Observable } from 'rxjs';
import { OfferingId } from '../../../../transact-api';
import { BazaNcPayoffDistributionImportCsvEntryErrorDto } from '../dto/baza-nc-payoff-distribution-import-csv-entry-error.dto';
import { BazaNcPayoffDistributionTransactionEntryCsvDto } from '../dto/baza-nc-payoff-distribution-transaction-entry-csv.dto';

export enum BazaNcPayoffDistributionTransactionEntryCmsEndpointPaths {
    create = '/baza-nc/cms/payoff-distribution/entry/create',
    update = '/baza-nc/cms/payoff-distribution/entry/update',
    delete = '/baza-nc/cms/payoff-distribution/entry/delete',
    getByUlid = '/baza-nc/cms/payoff-distribution/entry/getByUlid',
    list = '/baza-nc/cms/payoff-distribution/entry/list',
    importCsv = '/baza-nc/cms/payoff-distribution/entry/importCsv',
    exportToCsv = '/baza-nc/cms/payoff-distribution/entry/exportToCsv',
}

export interface BazaNcPayoffDistributionTransactionEntryCmsEndpoint {
    create(
        request: BazaNcPayoffDistributionTransactionEntryCmsCreateRequest,
    ): Promise<BazaNcPayoffDistributionTransactionEntryDto> | Observable<BazaNcPayoffDistributionTransactionEntryDto>;
    update(
        request: BazaNcPayoffDistributionTransactionEntryCmsUpdateRequest,
    ): Promise<BazaNcPayoffDistributionTransactionEntryDto> | Observable<BazaNcPayoffDistributionTransactionEntryDto>;
    delete(request: BazaNcPayoffDistributionTransactionEntryCmsDeleteRequest): Promise<void> | Observable<void>;
    getByUlid(
        request: BazaNcPayoffDistributionTransactionEntryCmsGetByUlidRequest,
    ): Promise<BazaNcPayoffDistributionTransactionEntryDto> | Observable<BazaNcPayoffDistributionTransactionEntryDto>;
    list(
        request: BazaNcPayoffDistributionTransactionEntryCmsListRequest,
    ):
        | Promise<BazaNcPayoffDistributionTransactionEntryCmsListResponse>
        | Observable<BazaNcPayoffDistributionTransactionEntryCmsListResponse>;
    importCsv(
        uploadedCsvFile: File | unknown,
        request: BazaNcPayoffDistributionTransactionEntryImportCsvRequest,
    ):
        | Promise<BazaNcPayoffDistributionTransactionEntryImportCsvResponse>
        | Observable<BazaNcPayoffDistributionTransactionEntryImportCsvResponse>;
    exportToCsv(request: BazaNcPayoffDistributionTransactionEntryExportCsvRequest): Promise<string> | Observable<string>;
}

export class BazaNcPayoffDistributionTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    @IsDateString()
    @IsNotEmpty()
    date: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Offering Id',
        required: false,
    })
    @IsString()
    @IsOptional()
    offeringId?: OfferingId;

    @ApiModelProperty({
        description: 'Amount (as cents)',
        example: 5000,
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    amountCents: number;
}

export class BazaNcPayoffDistributionTransactionEntryCmsCreateRequest extends BazaNcPayoffDistributionTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        type: 'string',
        description: 'Payment Source',
        enum: Object.values(BazaNcPayoffDistributionPaymentSource),
    })
    @IsEnum(BazaNcPayoffDistributionPaymentSource)
    @IsNotEmpty()
    source: BazaNcPayoffDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Investor Account ID',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;

    @ApiModelProperty({
        description: 'PayoffDistribution Transaction ULID',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    payoffDistributionTransactionUlid: string;
}

export class BazaNcPayoffDistributionTransactionEntryCmsUpdateRequest extends BazaNcPayoffDistributionTransactionEntryCmsEntityBody {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionEntryCmsDeleteRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionEntryCmsGetByUlidRequest {
    @ApiModelProperty({
        description: 'ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    ulid: string;
}

export class BazaNcPayoffDistributionTransactionEntryCmsListRequest extends CrudListRequestDto<BazaNcPayoffDistributionTransactionEntryDto> {
    @ApiModelProperty({
        type: 'string',
        description: 'PayoffDistribution Transaction ULID (ID)',
        example: ulid(),
    })
    @IsString()
    @IsNotEmpty()
    payoffDistributionTransactionUlid: string;
}

export class BazaNcPayoffDistributionTransactionEntryCmsListResponse extends CrudListResponseDto<BazaNcPayoffDistributionTransactionEntryDto> {
    @ApiModelProperty({
        type: BazaNcPayoffDistributionTransactionEntryDto,
        isArray: true,
    })
    items: Array<BazaNcPayoffDistributionTransactionEntryDto>;
}

export class BazaNcPayoffDistributionTransactionEntryImportCsvRequest {
    @ApiModelProperty({
        description: 'PayoffDistribution Transaction',
    })
    @IsString()
    @IsNotEmpty()
    payoffDistributionTransactionUlid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcPayoffDistributionPaymentSource),
        description: 'Payment Source (Type) for PayoffDistributions',
    })
    @IsEnum(BazaNcPayoffDistributionPaymentSource)
    @IsNotEmpty()
    source: BazaNcPayoffDistributionPaymentSource;

    @ApiModelProperty({
        description: 'Delimiter used for source CSV file',
        default: ',',
        required: false,
    })
    @IsString()
    @MaxLength(1)
    @MinLength(1)
    @IsOptional()
    csvDelimiter?: string;
}

export class BazaNcPayoffDistributionTransactionEntryImportCsvResponse {
    @ApiModelProperty({
        description: 'Is CSV file successfully imported without errors?',
    })
    success: boolean;

    @ApiModelProperty({
        description: 'Errors',
        type: BazaNcPayoffDistributionImportCsvEntryErrorDto,
        isArray: true,
    })
    errors?: Array<BazaNcPayoffDistributionImportCsvEntryErrorDto>;
}

export class BazaNcPayoffDistributionTransactionEntryExportCsvRequest extends CrudExportToCsvRequest<
    BazaNcPayoffDistributionTransactionEntryCsvDto,
    BazaNcPayoffDistributionTransactionEntryCmsListRequest
> {}
