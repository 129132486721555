import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
  NewsCmsCreateRequest,
  NewsCmsDeleteRequest,
  NewsCmsDto,
  NewsCmsEndpoint,
  NewsCmsEndpointPaths,
  NewsCmsGetByIdRequest,
  NewsCmsListRequest,
  NewsCmsListResponse,
  NewsCmsSetSortOrderRequest,
  NewsCmsSetSortOrderResponse,
  NewsCmsUpdateRequest,
} from '@scaliolabs/baza-content-types-shared';
import { Observable } from 'rxjs';

@Injectable()
export class NewsCmsDataAccess implements NewsCmsEndpoint {
  constructor(private readonly http: BazaDataAccessService) {}

  create(request: NewsCmsCreateRequest): Observable<NewsCmsDto> {
    return this.http.post(NewsCmsEndpointPaths.create, request);
  }

  update(request: NewsCmsUpdateRequest): Observable<NewsCmsDto> {
    return this.http.post(NewsCmsEndpointPaths.update, request);
  }

  delete(request: NewsCmsDeleteRequest): Observable<void> {
    return this.http.post(NewsCmsEndpointPaths.delete, request);
  }

  list(request: NewsCmsListRequest): Observable<NewsCmsListResponse> {
    return this.http.post(NewsCmsEndpointPaths.list, request);
  }

  getById(request: NewsCmsGetByIdRequest): Observable<NewsCmsDto> {
    return this.http.post(NewsCmsEndpointPaths.getById, request);
  }

  setSortOrder(request: NewsCmsSetSortOrderRequest): Observable<NewsCmsSetSortOrderResponse> {
    return this.http.post(NewsCmsEndpointPaths.setSortOrder, request);
  }
}
