import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString, IsNumberString } from 'class-validator';

export class DwollaAmount {
    @ApiModelProperty({
        description: 'The amount value',
        example: '0.00',
    })
    @IsNumberString()
    @IsNotEmpty()
    value: string;

    @ApiModelProperty({
        description: 'The currency name',
        example: 'USD',
    })
    @IsString()
    @IsNotEmpty()
    currency: string;
}
