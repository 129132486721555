import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcSearchOperator } from '../baza-nc-search-operator';
import { IsArray, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { BazaNcSearchField, bazaNcSearchFieldSwagger } from '../baza-nc-search-field';

export class BazaNcSearchOperatorNotIn<T = any> {
    @ApiModelProperty({
        description: 'Field',
        ...bazaNcSearchFieldSwagger(),
    })
    @IsString()
    @IsNotEmpty()
    field: BazaNcSearchField;

    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcSearchOperator.NotIn],
    })
    @IsEnum([BazaNcSearchOperator.NotIn])
    @IsNotEmpty()
    type: BazaNcSearchOperator.NotIn;

    @ApiModelProperty()
    @IsArray()
    @IsNotEmpty()
    values: Array<T>;
}
