import { Injectable } from '@angular/core';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import {
    BazaNcTaxDocumentDto,
    BazaNcTaxDocumentEndpoint,
    BazaNcTaxDocumentEndpointPaths,
    BazaNcTaxDocumentGetRequest,
    BazaNcTaxDocumentListRequest,
    BazaNcTaxDocumentListResponse,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { replacePathArgs } from '@scaliolabs/baza-core-shared';

@Injectable()
export class BazaNcTaxDocumentDataAccess implements BazaNcTaxDocumentEndpoint {
    constructor(private readonly http: BazaDataAccessService) {}

    get(request: BazaNcTaxDocumentGetRequest): Observable<BazaNcTaxDocumentDto> {
        return this.http.get(
            replacePathArgs(BazaNcTaxDocumentEndpointPaths.get, {
                id: request.id,
            }),
        );
    }

    list(request: BazaNcTaxDocumentListRequest): Observable<BazaNcTaxDocumentListResponse> {
        return this.http.get(BazaNcTaxDocumentEndpointPaths.list, request);
    }
}
