import { IsEnum, IsInt, IsOptional, IsPositive } from 'class-validator';
import { DwollaMassPaymentItemStatus } from '../models/dwolla-mass-payment-item-status';

export class GetItemListForMassPaymentRequest {
    @IsPositive()
    @IsInt()
    @IsOptional()
    limit?: number;

    @IsPositive()
    @IsInt()
    @IsOptional()
    offset?: number;

    @IsEnum(DwollaMassPaymentItemStatus)
    @IsOptional()
    status?: DwollaMassPaymentItemStatus;
}
