import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BlogDto } from './blog.dto';

export class BlogCmsDto extends BlogDto {
    @ApiModelProperty()
    headerImgS3Key: string;

    @ApiModelProperty()
    thumbnailImgS3Key: string;
}
