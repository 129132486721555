import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalAccountId } from '../models/account-id';
import { DocumentId } from '../models/document-id';

export class GetAiDocumentRequest {
    @IsString()
    @IsNotEmpty()
    accountId: NorthCapitalAccountId;

    @IsString()
    @IsOptional()
    documentId?: DocumentId;
}
