import { BazaDwollaEscrowFundingSourceDto } from '../dto/baza-dwolla-escrow-funding-source.dto';
import { Observable } from 'rxjs';
import { DwollaFundingSourceId } from '../../../../dwolla-api/src';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty, IsString } from 'class-validator';

export enum BazaDwollaEscrowCmsEndpointPaths {
    list = '/baza-dwolla/cms/escrow/list',
    set = '/baza-dwolla/cms/escrow/set',
}

export interface BazaDwollaEscrowCmsEndpoint {
    list(): Promise<Array<BazaDwollaEscrowFundingSourceDto>> | Observable<Array<BazaDwollaEscrowFundingSourceDto>>;
    set(request: BazaDwollaEscrowCmsSetRequest): Promise<BazaDwollaEscrowFundingSourceDto> | Observable<BazaDwollaEscrowFundingSourceDto>;
}

export class BazaDwollaEscrowCmsSetRequest {
    @ApiModelProperty({
        type: 'string',
        description: 'Funding Source ID',
    })
    @IsString()
    @IsNotEmpty()
    dwollaFundingSourceId: DwollaFundingSourceId;
}
