import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { DwollaCustomerId, DwollaTransferId } from '@scaliolabs/baza-dwolla-shared';
import { BazaNcDividendPaymentStatus } from './baza-nc-dividend-payment-status';

export enum BazaNcDividendPaymentHistoryAction {
    PaidWithNC = 'PaidWithNC',
    DwollaTransferInitiated = 'DwollaTransferInitiated',
    DwollaTransferStatusUpdated = 'DwollaTransferStatusUpdated',
}

export class BazaNcDividendPaymentHistoryPaidWithNcAction {
    type: BazaNcDividendPaymentHistoryAction.PaidWithNC;
}

export class BazaNcDividendPaymentHistoryDwollaTransferInitiatedAction {
    type: BazaNcDividendPaymentHistoryAction.DwollaTransferInitiated;
    dwollaCustomerId: DwollaCustomerId;
    dwollaTransferId: DwollaTransferId;
}

export class BazaNcDividendPaymentHistoryDwollaTransferStatusUpdatedAction {
    type: BazaNcDividendPaymentHistoryAction.DwollaTransferStatusUpdated;
    dwollaTransferId: DwollaTransferId;
    previousStatus: BazaNcDividendPaymentStatus;
    newStatus: BazaNcDividendPaymentStatus;
}

export type BazaNcDividendPaymentHistoryActions =
    | BazaNcDividendPaymentHistoryPaidWithNcAction
    | BazaNcDividendPaymentHistoryDwollaTransferInitiatedAction
    | BazaNcDividendPaymentHistoryDwollaTransferStatusUpdatedAction;

export const bazaNcDividendPaymentHistorySwaggerExportedDtos = [
    BazaNcDividendPaymentHistoryPaidWithNcAction,
    BazaNcDividendPaymentHistoryDwollaTransferInitiatedAction,
];

export const bazaNcDividendPaymentHistoryActionsSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaNcDividendPaymentHistorySwaggerExportedDtos.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
