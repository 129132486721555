import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BazaPortfolioDataAccess } from '@scaliolabs/baza-nc-integration-data-access';
import {
    BazaNcIntegrationPortfolioAssetsResponse,
    BazaNcIntegrationPortfolioStatementsResponse,
} from '@scaliolabs/baza-nc-integration-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { Asset, itemsOnPage, Paginator, PortfolioTotal, Statement, Transaction } from '../../models';
import { LOAD_ASSETS_FAIL, LOAD_STATEMENTS_FAIL, LOAD_TOTALS_FAIL, LOAD_TRANSACTIONS_FAIL } from './messages';
import {
    ChangeTransactionFilter,
    ChangeTransactionFilterBindings,
    ChangeTransactionsPage,
    GetAssets,
    GetStatements,
    GetTotals,
    GetTransactions,
} from './actions';
import { TransactionFilter, TransactionFilterBindings } from '../../models';
import {
    BazaNcOperationDto,
    BazaNcOperationListResponse,
    BazaNcOperationPayloadDividendDto,
    BazaNcOperationPayloadInvestmentDto,
    BazaNcOperationPayloadTransferDto,
    BazaNcOperationPayloadWithdrawDto,
    BazaNcOperationType,
} from '@scaliolabs/baza-nc-shared';
import { BazaNcOperationDataAccess } from '@scaliolabs/baza-nc-data-access';

export interface PortfolioStateModel {
    assets: Asset[];
    statements: Statement[];
    totals: PortfolioTotal;
    transactions: Transaction[];
    transactionsPaging: Paginator;
    transactionFilter: TransactionFilter;
    transactionFilterBindings: TransactionFilterBindings;
}

const initState = {
    name: 'portfolio',
    defaults: {
        assets: null,
        statements: null,
        totals: null,
        transactions: null,
        transactionsPaging: {
            index: 1,
            size: itemsOnPage,
            total: 0,
        },
        transactionFilter: undefined,
        transactionFilterBindings: undefined,
    },
};

@State<PortfolioStateModel>(initState)
@Injectable()
export class PortfolioState {
    constructor(
        private readonly dataAccess: BazaPortfolioDataAccess,
        private readonly operationDataAccess: BazaNcOperationDataAccess,
        private readonly effectsHelper: EffectsUtil,
    ) {}

    @Selector()
    static assets(state: PortfolioStateModel): Asset[] {
        return state.assets;
    }

    @Selector()
    static statements(state: PortfolioStateModel): Statement[] {
        return state.statements;
    }

    @Selector()
    static totals(state: PortfolioStateModel): PortfolioTotal {
        return state.totals;
    }

    @Selector()
    static transactions(state: PortfolioStateModel): Transaction[] {
        return state.transactions;
    }

    @Selector()
    static transactionsPaging(state: PortfolioStateModel) {
        return state.transactionsPaging;
    }

    @Selector()
    static transactionFilter(state: PortfolioStateModel) {
        return state.transactionFilter;
    }

    @Selector()
    static transactionFilterBindings(state: PortfolioStateModel) {
        return state.transactionFilterBindings;
    }

    @Action(GetAssets, { cancelUncompleted: true })
    getAssets(ctx: StateContext<PortfolioStateModel>): Observable<BazaNcIntegrationPortfolioAssetsResponse> {
        return this.dataAccess.assets({ size: -1 }).pipe(
            tap((response: BazaNcIntegrationPortfolioAssetsResponse) => {
                return ctx.patchState({ assets: response.items });
            }),
            this.effectsHelper.tryCatchError$(LOAD_ASSETS_FAIL),
        );
    }

    @Action(GetTransactions, { cancelUncompleted: true })
    getTransactions(ctx: StateContext<PortfolioStateModel>): Observable<BazaNcOperationListResponse> {
        const state = ctx.getState();

        return this.operationDataAccess
            .list({
                index: state.transactionsPaging.index,
                size: state.transactionsPaging.size,
                types: state.transactionFilter.scopes,
            })
            .pipe(
                tap((response: BazaNcOperationListResponse) => {
                    return ctx.patchState({
                        transactions: response.items.map((next) => this.populate(next)),
                        transactionsPaging: response.pager,
                    });
                }),
                this.effectsHelper.tryCatchError$(LOAD_TRANSACTIONS_FAIL),
            );
    }

    @Action(GetStatements, { cancelUncompleted: true })
    getStatements(ctx: StateContext<PortfolioStateModel>): Observable<BazaNcIntegrationPortfolioStatementsResponse> {
        return this.dataAccess.statements({ size: -1 }).pipe(
            tap((response: BazaNcIntegrationPortfolioStatementsResponse) => {
                return ctx.patchState({ statements: response.items });
            }),
            this.effectsHelper.tryCatchError$(LOAD_STATEMENTS_FAIL),
        );
    }

    @Action(GetTotals, { cancelUncompleted: true })
    getTotals(ctx: StateContext<PortfolioStateModel>): Observable<PortfolioTotal> {
        return this.dataAccess.total().pipe(
            tap((response: PortfolioTotal) => {
                return ctx.patchState({ totals: response });
            }),
            this.effectsHelper.tryCatchError$(LOAD_TOTALS_FAIL),
        );
    }

    @Action(ChangeTransactionsPage)
    changeTransactionsPage(ctx: StateContext<PortfolioStateModel>, action: ChangeTransactionsPage) {
        const state = ctx.getState();

        ctx.patchState({
            transactionsPaging: {
                ...state.transactionsPaging,
                index: action.index,
            },
        });

        return this.getTransactions(ctx);
    }

    @Action(ChangeTransactionFilter)
    changeTransactionFilter(ctx: StateContext<PortfolioStateModel>, action: ChangeTransactionFilter) {
        ctx.patchState({
            transactionFilter: {
                scopes: action.transactionFilter.scopes,
                states: action.transactionFilter.states,
            },
            transactionsPaging: initState.defaults.transactionsPaging,
        });

        return this.getTransactions(ctx);
    }

    @Action(ChangeTransactionFilterBindings)
    changeTransactionFilterBindings(ctx: StateContext<PortfolioStateModel>, action: ChangeTransactionFilterBindings) {
        ctx.patchState({
            transactionFilterBindings: action.transactionFilterBindings,
        });
    }

    populate(next: BazaNcOperationDto): Transaction {
        switch (next.payload.type) {
            case BazaNcOperationType.Investment:
                return this.populateInvestmentEntity(next);

            case BazaNcOperationType.Dividend:
                return this.populateDividendEntity(next);

            case BazaNcOperationType.Transfer:
            case BazaNcOperationType.Withdraw:
                return this.populateTransferOrWithdrawEntity(next);

            default:
                return this.populateInvestmentEntity(next);
        }
    }

    populateInvestmentEntity(operation: BazaNcOperationDto): Transaction {
        const payload = operation.payload as BazaNcOperationPayloadInvestmentDto;

        return {
            id: payload.id,
            name: payload.offeringTitle,
            amountCents: operation.amountCents,
            createdAt: operation.date,
            totalCents: payload.totalCents,
            transactionFeesCents: payload.transactionFeesCents,
            shares: payload.shares,
            pricePerShareCents: payload.pricePerShareCents,
            state: payload.state,
            type: payload.type,
        };
    }

    populateDividendEntity(operation: BazaNcOperationDto): Transaction {
        const payload = operation.payload as BazaNcOperationPayloadDividendDto;

        return {
            ulid: payload.ulid,
            name: payload.offeringTitle,
            amountCents: operation.amountCents,
            createdAt: operation.date,
            state: payload.status,
            type: payload.type,
        };
    }

    private populateTransferOrWithdrawEntity(operation: BazaNcOperationDto): Transaction {
        const payload = operation.payload as BazaNcOperationPayloadWithdrawDto | BazaNcOperationPayloadTransferDto;

        return {
            amountCents: operation.amountCents,
            createdAt: operation.date,
            state: payload.status,
            type: payload.type,
        };
    }
}
