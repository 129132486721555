import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BazaMaintenanceNgConfig } from '../baza-maintenance-ng.config';
import { BazaHttpHeaders } from '@scaliolabs/baza-core-shared';
import { catchError } from 'rxjs/operators';
import { BazaMaintenanceErrorCodes } from '@scaliolabs/baza-core-shared';
import { Router } from '@angular/router';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class XBazaSkipMaintenanceHttpInterceptor implements HttpInterceptor {
    constructor(
        private moduleConfig: BazaMaintenanceNgConfig,
        private router: Router,
        private zone: NgZone,
        private ngEndpoint: BazaDataAccessService,
    ) {}

    /**
     * Adds a `X-Baza-Skip-Maintenance` HTTP header for requests in case if Module Configuration has `skip: true`
     * configuration. Also controls Redirect URL for all failed requests when Maintenance Mode is enabled for
     * current Application.
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        const nextWithSkip = this.moduleConfig.skip
            ? next.handle(
                  req.clone({
                      setHeaders: {
                          [BazaHttpHeaders.HTTP_HEADER_SKIP_MAINTENANCE]: 'SKIP',
                      },
                  }),
              )
            : next.handle(req);

        return nextWithSkip.pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.error?.code === BazaMaintenanceErrorCodes.BazaMaintenanceServiceUnavailable) {
                    /**
                     * zone run is used for a case if it is navigating to the same route,
                     * and we need to trigger component's lifehooks
                     */
                    this.zone.run(() => {
                        this.router.navigate([this.moduleConfig.redirectTo]);
                    });
                }

                return throwError(err);
            }),
        );
    }
}
