import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsNotEmpty } from 'class-validator';
import { DwollaDocumentType } from '../models/dwolla-document-type';

export class CreateDocumentRequest {
    @ApiModelProperty()
    @IsNotEmpty()
    userId: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(DwollaDocumentType),
    })
    @IsNotEmpty()
    documentType: DwollaDocumentType;
}
