import { BazaPasswordValidateDto } from '../dto/baza-password-validate.dto';
import { BAZA_PASSWORD_VALIDATOR_INJECTS, BazaPasswordDifficultLevel, BazaPasswordValidator } from '../models/baza-password-validator';
import owasp from 'owasp-password-strength-test';
import { replaceTags } from '../../../../baza-common/src';

export function bazaValidatePassword(password: string): BazaPasswordValidateDto {
    owasp.config({
        allowPassphrases: BAZA_PASSWORD_VALIDATOR_INJECTS.allowPassphrases,
        maxLength: BAZA_PASSWORD_VALIDATOR_INJECTS.maxPasswordLength,
        minLength: BAZA_PASSWORD_VALIDATOR_INJECTS.minPasswordLength,
        minPhraseLength: BAZA_PASSWORD_VALIDATOR_INJECTS.minPhraseLength,
        minOptionalTestsToPass: BAZA_PASSWORD_VALIDATOR_INJECTS.minOptionalTestsToPass,
    });

    const owaspResponse = owasp.test(password);
    const failed: Array<BazaPasswordValidator> = [];
    const passed: Array<BazaPasswordValidator> = [];

    for (const v of BAZA_PASSWORD_VALIDATOR_INJECTS.validators) {
        if (v.validator(password)) {
            passed.push(v.type);
        } else {
            failed.push(v.type);
        }
    }

    return {
        isValid: failed.length === 0,
        difficult: (() => {
            if (failed.length === 0 && owaspResponse.isPassphrase && owaspResponse.strong) {
                return BazaPasswordDifficultLevel.Hard;
            } else if (failed.length === 0) {
                return BazaPasswordDifficultLevel.Normal;
            } else {
                return BazaPasswordDifficultLevel.Easy;
            }
        })(),
        failed: failed.map((v) => ({
            type: v,
            label: replaceTags(v, BAZA_PASSWORD_VALIDATOR_INJECTS),
        })),
        passed: passed.map((v) => ({
            type: v,
            label: replaceTags(v, BAZA_PASSWORD_VALIDATOR_INJECTS),
        })),
    };
}
