import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class WhitelistAccountDto {
    @ApiModelProperty()
    id: number;

    @ApiModelProperty()
    email: string;

    @ApiModelProperty({
        required: false,
    })
    dateCreated: string;

    @ApiModelProperty({
        required: false,
    })
    dateUpdated: string;
}
