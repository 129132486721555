import { BazaNcIntegrationSchemaType } from '../models/baza-nc-integration-schema-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaDefinitionPayload } from './_.schema-definition';

export class ImageMultiSchemaDefinitionPayload extends SchemaDefinitionPayload {
}

export class ImageMultiSchemaDefinition {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationSchemaType.ImageMulti],
    })
    type: BazaNcIntegrationSchemaType.ImageMulti;

    @ApiModelProperty()
    payload: ImageMultiSchemaDefinitionPayload;
}
