import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { AccountTypeCheckingSaving } from '../../../transact-api';
import { BazaNcBankAccountType } from '../models/baza-nc-bank-account-type';
import { ulid } from 'ulid';
import { BazaNcBankAccountSource } from '../models/baza-nc-bank-account-source';
import { BazaNcBankAccountExport } from '../models/baza-nc-bank-account-export';

export class BazaNcBankAccountDto {
    @ApiModelProperty({
        type: 'string',
        description: 'ULID',
        example: ulid(),
    })
    ulid: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountType),
        description: 'Bank Account Type. Each Bank Account Type will have one Bank Account selected as default',
    })
    type: BazaNcBankAccountType;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountExport),
        description: 'To which services Bank Account is exported',
    })
    exported: Array<BazaNcBankAccountExport>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(BazaNcBankAccountSource),
        description: 'Method used to add Bank Account',
    })
    source: BazaNcBankAccountSource;

    @ApiModelProperty({
        description: 'Is Bank Account default for this type?',
        example: false,
    })
    isDefault: boolean;

    @ApiModelProperty({
        type: 'string',
        description: 'Name. Could be any string and it could be used for UI',
        example: "John Smith's Checking",
    })
    name: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Account Holder Name',
        example: 'John Abraham',
    })
    accountName: string;

    @ApiModelProperty({
        description: 'Nickname for the External Account',
        example: 'John Nick',
    })
    accountNickName: string;

    @ApiModelProperty({
        type: 'string',
        description: 'Account Number',
        example: '1234567890',
    })
    accountNumber: string;

    @ApiModelProperty({
        type: 'string',
        example: '34543534',
        description: 'Routing Number',
    })
    accountRoutingNumber: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(AccountTypeCheckingSaving),
        description: 'Account type Checking / Saving',
        example: AccountTypeCheckingSaving.Checking,
    })
    accountType: AccountTypeCheckingSaving;
}
