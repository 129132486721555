import { BazaDwollaPaymentType } from './baza-dwolla-payment-type';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { IsNumberString, IsOptional } from 'class-validator';

export class BazaDwollaPaymentPurchaseSharesPayload {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaDwollaPaymentType.PurchaseShares],
    })
    type: BazaDwollaPaymentType.PurchaseShares;

    @ApiModelProperty({
        default: 'NC Trade Id',
    })
    ncTradeId: string;
}

export class BazaDwollaPaymentDividendPayload {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaDwollaPaymentType.Dividend],
    })
    type: BazaDwollaPaymentType.Dividend;

    @ApiModelProperty({
        default: 'Dividend Entry ULID',
    })
    dividendEntryUlid: string;
}

export class BazaDwollaPaymentWithdrawalPayload {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaDwollaPaymentType.Withdrawal],
    })
    type: BazaDwollaPaymentType.Withdrawal;

    @ApiModelProperty({
        type: 'string',
        description: 'The amount to be withdrawn',
        example: '14.33',
        required: false,
    })
    @IsNumberString()
    @IsOptional()
    amount?: string;

    @ApiModelProperty({
        type: 'string',
        description: 'the Cashout Bank Account Funding Srouce Id',
        required: true,
    })
    @IsNumberString()
    cashOutFundingSourceId: string;
}

export class BazaDwollaPaymentCashInPayload {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaDwollaPaymentType.CashIn],
    })
    type: BazaDwollaPaymentType.CashIn;

    @ApiModelProperty({
        type: 'string',
        description: 'The amount to be transfered from bank account to user balance',
        example: '14.33',
        required: true,
    })
    @IsNumberString()
    @IsOptional()
    amount: string;

    @ApiModelProperty({
        type: 'string',
        description: 'the CashIn Bank Account Funding Srouce Id',
        required: true,
    })
    @IsNumberString()
    cashInFundingSourceId: string;
}

export type BazaDwollaPaymentPayload =
    | BazaDwollaPaymentPurchaseSharesPayload
    | BazaDwollaPaymentDividendPayload
    | BazaDwollaPaymentWithdrawalPayload
    | BazaDwollaPaymentCashInPayload;

export const bazaDwollaPaymentPayloadSwaggerExportedDtos = [
    BazaDwollaPaymentPurchaseSharesPayload,
    BazaDwollaPaymentDividendPayload,
    BazaDwollaPaymentWithdrawalPayload,
    BazaDwollaPaymentCashInPayload,
];

export const bazaDwollaPaymentPayloadSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaDwollaPaymentPayloadSwaggerExportedDtos.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
