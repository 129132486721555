export const WebUiPersonalInfoEnI18n = {
    title: 'Personal Info',
    details: {
        name: 'Name:',
        dateOfBirth: 'Date of birth:',
        address: 'Address:',
        ssn: 'Social Security Number:',
        document: 'Uploaded Document:',
    },
    popover: {
        message: 'This is your Investor Account information. You can review it and make changes if needed.',
        actions: {
            edit: 'Edit',
        },
    },
};
