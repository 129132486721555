import { Observable } from 'rxjs';
import { CrudExportToCsvRequest, CrudListRequestDto, CrudListResponseDto } from '@scaliolabs/baza-core-shared';
import { NcTransactionDto } from '../..';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { NcTransactionCsvDto } from '../dto/nc-transaction-csv.dto';
import { TransactionState } from '../models/transaction-state';

export enum BazaNcTransactionCmsEndpointPaths {
    list = '/baza-nc/transactions/cms/list',
    getById = '/baza-nc/transactions/cms/getById',
    exportToCsv = '/baza-nc/transactions/cms/exportToCsv',
}

export interface BazaNcTransactionCmsEndpoint {
    list(request: ListTransactionsCmsRequest): Promise<ListTransactionsCmsResponse> | Observable<ListTransactionsCmsResponse>;
    getById(request: GetByIdTransactionCmsRequest): Promise<GetByIdTransactionCmsResponse> | Observable<GetByIdTransactionCmsResponse>;
    exportToCsv(request: ExportToCsvTransactionCmsRequest): Promise<string> | Observable<string>;
}

export class ListTransactionsCmsRequest extends CrudListRequestDto<NcTransactionDto> {
    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsPositive()
    @IsOptional()
    accountId?: number;

    @ApiModelProperty({
        required: false,
        description: 'Display only submitted / not submitted transactions',
    })
    @IsBoolean()
    @IsOptional()
    isSubmitted?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    ncOfferingId?: string;

    @ApiModelProperty({
        type: 'string',
        isArray: true,
        enum: Object.values(TransactionState),
        required: false,
    })
    @IsEnum(TransactionState)
    @IsOptional()
    status?: Array<TransactionState>;
}

export class ListTransactionsCmsResponse extends CrudListResponseDto<NcTransactionDto> {}

export class GetByIdTransactionCmsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type GetByIdTransactionCmsResponse = NcTransactionDto;

export class ExportToCsvTransactionCmsRequest extends CrudExportToCsvRequest<NcTransactionCsvDto, ListTransactionsCmsRequest> {}
