import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerificationResolver, FormResourcesResolver } from './data-access';
import { VerificationComponent } from './verification.component';
import { VerificationCheckComponent } from './check/check.component';
import { VerificationStepperComponent } from './stepper/stepper.component';

export const getConfigurableRoutes: (baseComponent, checkComponent, stepperComponent) => Routes = (
    baseComponent,
    checkComponent,
    stepperComponent,
) => [
    {
        path: '',
        component: baseComponent,
        resolve: {
            verification: VerificationResolver,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'check',
            },
            {
                path: 'check',
                component: checkComponent,
                data: {
                    layout: 'mini-center',
                    wrapper: true,
                },
            },
            {
                path: 'info',
                component: stepperComponent,
                resolve: {
                    formResources: FormResourcesResolver,
                },
                data: {
                    tab: '0',
                    layout: 'mini',
                    meta: {
                        title: 'Personal Information',
                    },
                },
            },
            {
                path: 'investor',
                component: stepperComponent,
                data: {
                    tab: '1',
                    layout: 'mini',
                    meta: {
                        title: 'Investor Profile',
                    },
                },
            },
        ],
    },
];

const routes: Routes = getConfigurableRoutes(VerificationComponent, VerificationCheckComponent, VerificationStepperComponent);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class VerificationRoutingModule {}
