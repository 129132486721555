import { CoreAcl } from '../../../baza-acl/src';

export enum BazaCoreACL {
    Core = 'core',
    Account = 'account',
    InviteCodes = 'invite-codes',
    ReferralCodes = 'referral-codes',
    Mail = 'mail',
}

export interface BazaAclConfig {
    groups: Array<BazaAclGroupConfig>;
}

export interface BazaAclGroupConfig {
    group: string;
    nodes: Array<string>;
}

export function defaultBazaAclGroups(): Array<BazaAclGroupConfig> {
    return [
        {
            group: BazaCoreACL.Core,
            nodes: Object.values(CoreAcl),
        },
    ];
}

export function defaultBazaAclConfig(): BazaAclConfig {
    return {
        groups: defaultBazaAclGroups(),
    };
}

export const BAZA_ACL_CONFIG: BazaAclConfig = defaultBazaAclConfig();

export function configureBazaAcl(config: Partial<BazaAclConfig>): void {
    const newConfig: BazaAclConfig = {
        ...defaultBazaAclConfig(),
        groups: [...defaultBazaAclGroups(), ...config.groups],
    };

    Object.assign(BAZA_ACL_CONFIG, newConfig);
}
