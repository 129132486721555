import { getSchemaPath } from '@nestjs/swagger/dist/utils/get-schema-path.util';
import { SchemaObjectMetadata } from '@nestjs/swagger/dist/interfaces/schema-object-metadata.interface';
import { BazaDwollaDistributionPaymentStatus } from './baza-dwolla-distribution-payment-status';
import { DwollaCustomerId, DwollaTransferId } from '../../../../dwolla-api/src';

export enum BazaDwollaDistributionPaymentHistoryAction {
    PaidWithDwolla = 'PaidWithDwolla',
    DwollaTransferInitiated = 'DwollaTransferInitiated',
    DwollaTransferStatusUpdated = 'DwollaTransferStatusUpdated',
}

export class BazaDwollaDistributionPaymentHistoryPaidWithNcAction {
    type: BazaDwollaDistributionPaymentHistoryAction.PaidWithDwolla;
}

export class BazaDwollaDistributionPaymentHistoryDwollaTransferInitiatedAction {
    type: BazaDwollaDistributionPaymentHistoryAction.DwollaTransferInitiated;
    dwollaCustomerId: DwollaCustomerId;
    dwollaTransferId: DwollaTransferId;
}

export class BazaDwollaDistributionPaymentHistoryDwollaTransferStatusUpdatedAction {
    type: BazaDwollaDistributionPaymentHistoryAction.DwollaTransferStatusUpdated;
    dwollaTransferId: DwollaTransferId;
    previousStatus: BazaDwollaDistributionPaymentStatus;
    newStatus: BazaDwollaDistributionPaymentStatus;
}

export type BazaDwollaDistributionPaymentHistoryActions =
    | BazaDwollaDistributionPaymentHistoryPaidWithNcAction
    | BazaDwollaDistributionPaymentHistoryDwollaTransferInitiatedAction
    | BazaDwollaDistributionPaymentHistoryDwollaTransferStatusUpdatedAction;

export const bazaDwollaDistributionPaymentHistorySwaggerExportedDtos = [
    BazaDwollaDistributionPaymentHistoryPaidWithNcAction,
    BazaDwollaDistributionPaymentHistoryDwollaTransferInitiatedAction,
];

export const bazaDwollaDistributionPaymentHistoryActionsSwagger: () => SchemaObjectMetadata = () => ({
    oneOf: bazaDwollaDistributionPaymentHistorySwaggerExportedDtos.map((next) => ({
        $ref: getSchemaPath(next),
    })),
});
