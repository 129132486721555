import { Injectable } from '@angular/core';
import {
    BazaNcRecurringTransactionRequest,
    BazaNcRecurringTransactionsEndpoint,
    BazaNcRecurringTransactionsEndpointPaths,
    BazaNcRecurringTransactionUpdateRequest,
    ListAllRecurringTradesRequest,
    ListAllRecurringTradesResponse,
    ListRecurringTransactionsRequest,
    ListRecurringTransactionsResponse,
    ListTradesForRecurringTransactionRequest,
    ListTradesForRecurringTransactionResponse,
    RecurringTransactionDto,
} from '@scaliolabs/baza-nc-shared';
import { Observable } from 'rxjs';
import { BazaDataAccessService } from '@scaliolabs/baza-core-data-access';

@Injectable()
export class BazaNcRecurringTransactionsDataAccess implements BazaNcRecurringTransactionsEndpoint {
    constructor(private readonly ngEndpoint: BazaDataAccessService) {}

    create(request: BazaNcRecurringTransactionRequest): Observable<RecurringTransactionDto> {
        return this.ngEndpoint.post<RecurringTransactionDto>(BazaNcRecurringTransactionsEndpointPaths.create, request);
    }

    update(request: BazaNcRecurringTransactionUpdateRequest): Observable<RecurringTransactionDto> {
        return this.ngEndpoint.post<RecurringTransactionDto>(BazaNcRecurringTransactionsEndpointPaths.update, request);
    }
    list(request: ListRecurringTransactionsRequest): Observable<ListRecurringTransactionsResponse> {
        return this.ngEndpoint.post<ListRecurringTransactionsResponse>(BazaNcRecurringTransactionsEndpointPaths.list, request);
    }

    trades(request: ListTradesForRecurringTransactionRequest): Observable<ListTradesForRecurringTransactionResponse> {
        return this.ngEndpoint.post<ListTradesForRecurringTransactionResponse>(BazaNcRecurringTransactionsEndpointPaths.trades, request);
    }

    allTrades(request: ListAllRecurringTradesRequest): Observable<ListAllRecurringTradesResponse> {
        return this.ngEndpoint.post<ListAllRecurringTradesResponse>(BazaNcRecurringTransactionsEndpointPaths.allTrades, request);
    }
}
