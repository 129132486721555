import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AccountRole } from '@scaliolabs/baza-core-shared';
import { Observable } from 'rxjs';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { JwtService } from '../services/jwt.service';
import { BazaJwtRequireRoleGuardConfig, BazaJwtRequireRoleGuardRouteConfig } from './jwt-require-role.guard';

/**
 * Helper Interface for Route Data object
 * Implement it in Route's Data object or use it just as documentation source
 */
export interface JwtRequireUserGuardRouteConfig {
    jwtRequireUserGuard: Partial<BazaJwtRequireRoleGuardConfig>;
}

/**
 * Route guard which will requires User role from current user to access.
 *
 * JwtRequireUserGuard uses JwtRequireRoleGuard configuration. If you
 * need to change default redirects or any kind of configuration for JwtRequireRoleGuard,
 * you should do it with implementing JwtRequireUserGuardRouteConfig interface
 * in route's Data object or with JwtRequireRoleGuard configuration.
 *
 * Guard can be used with canActivate or canActivateChild configurations.
 *
 * @see JwtRequireRoleGuard
 * @see BazaJwtRequireRoleGuardConfig
 * @see BazaJwtRequireRoleGuardRouteConfig
 * @see JwtRequireUserGuardRouteConfig
 *
 * @example
 * Change redirect specifically for route and specifically for JwtRequireUserGuard:
 *
 * ```typescript
 import { MyComponent } from './my/my.cpmponent';
 import { ActivatedRouteSnapshot, Routes } from '@angular/router';
 import { JwtRequireUserGuard, JwtRequireUserGuardRouteConfig } from './libs/baza-core-ng/src';

 * export const myRoutes: Routes = [
 * {
 *        path: 'my',
 *        component: MyComponent,
 *        canActivateChild: [JwtRequireUserGuard],
 *        data: {
 *            jwtRequireUserGuard: {
 *                redirect: (route: ActivatedRouteSnapshot) => ({
 *                    commands: ['/'],
 *                    navigationExtras: {
 *                        queryParams: {
 *                            login: 1,
 *                        },
 *                    },
 *                }),
 *            },
 *        } as JwtRequireUserGuardRouteConfig,
 *    },
 * ];
 * ```
 *
 * @example
 * Change redirect on global level.
 *
 * ```typescript
 * import { BAZA_WEB_BUNDLE_GUARD_CONFIGS } from './libs/baza-core-web/src';
 * import { ActivatedRouteSnapshot } from '@angular/router';
 * import { AccountRole } from './libs/baza-core-shared/src';
 *
 * BAZA_WEB_BUNDLE_GUARD_CONFIGS.requireRoleGuardConfig.redirect= (route: ActivatedRouteSnapshot, requestedRoles: Array<AccountRole>) => {
 *    if (requestedRoles.includes(AccountRole.User)) {
 *        return {
 *            commands: ['/'],
 *            navigationExtras: {
 *                queryParams: {
 *                    login: 1,
 *                    user: 1,
 *                },
 *            },
 *        };
 *    } else {
 *        return {
 *            commands: ['/'],
 *            navigationExtras: {
 *                queryParams: {
 *                    login: 1,
 *                    user: 1,
 *                },
 *            },
 *        };
 *    }
 * };
 * ```
 *
 * @deprecated
 */
@Injectable()
export class JwtRequireUserGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly jwtService: JwtService,
        private readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validatedRoles(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validatedRoles(childRoute);
    }

    private config(route: ActivatedRouteSnapshot): JwtRequireUserGuardRouteConfig {
        const moduleConfig = this.moduleConfig.requireRoleGuardConfig;
        const customConfig = ((route.data || {}) as BazaJwtRequireRoleGuardRouteConfig).jwtRequireRoleGuard;

        return {
            jwtRequireUserGuard: {
                ...moduleConfig,
                ...customConfig,
            },
        };
    }

    private validatedRoles(route: ActivatedRouteSnapshot): boolean | UrlTree {
        if (!this.jwtService.hasJwt()) {
            return this.fail(route);
        }

        if (this.jwtService.jwtPayload.accountRole !== AccountRole.User) {
            return this.fail(route);
        }

        return true;
    }

    private fail(route: ActivatedRouteSnapshot): UrlTree | false {
        const config = this.config(route);

        if (config.jwtRequireUserGuard.redirect) {
            const url = config.jwtRequireUserGuard.redirect(route, [AccountRole.User]);

            return this.router.createUrlTree(url.commands, url.navigationExtras);
        } else {
            return false;
        }
    }
}
