import { I18nDto } from '../dto/i18n.dto';
import { Observable } from 'rxjs';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsEnum, IsNotEmpty, IsOptional } from 'class-validator';
import { Application, ProjectLanguage } from '../../../../baza-common/src';

export enum BazaI18nEndpointPaths {
    get = '/baza-i18n/get',
}

export interface I18nEndpoint {
    get(request: BazaI18nGetRequest, ...args: unknown[]): Promise<BazaI18nGetResponse> | Observable<BazaI18nGetResponse>;
}

export class BazaI18nGetRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(Application),
    })
    @IsEnum(Application)
    @IsNotEmpty()
    app: Application;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProjectLanguage),
        required: false,
    })
    @IsEnum(ProjectLanguage)
    @IsOptional()
    language?: ProjectLanguage;
}

export type BazaI18nGetResponse = I18nDto;
