import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcPurchaseFlowErrorCodes } from '../error-codes/baza-nc-purchase-flow.error-codes';

export class PurchaseFlowErrorDto {
    @ApiModelProperty({
        description: 'Error code',
        enum: Object.values(BazaNcPurchaseFlowErrorCodes),
        type: 'string',
    })
    errorCode: BazaNcPurchaseFlowErrorCodes;

    @ApiModelProperty({
        description: 'Error message from session. Consider to not use it in client applications'
    })
    message: string;

    @ApiModelProperty({
        description: 'How many shares can be purchased by user (special case for OnlyXAmountOfSharesAvailableForPurchase, YouHadPreviousPurchaseAndManOnlyPurchaseUpToXShares and YouCanPurchaseUpToXShares error codes)'
    })
    mayPurchase?: number;
}
