<section class="payment-block">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngIf="dwollaDefaultCashOutAccount$ | async as cashOutAccount; else skeleton">
        <div
            nz-row
            class="payment-block__row payment-block__row-bank">
            <div
                nz-col
                nzXs="12">
                <app-bank-account-payout
                    [initData]="initData"
                    [isAnyAssetPurchased]="isAnyAssetPurchased"
                    [dwollaDefaultCashOutAccount]="cashOutAccount"
                    (refreshCashOutData)="onRefreshCashOutData()"></app-bank-account-payout>
            </div>
        </div>
    </ng-container>
</section>

<ng-template #skeleton>
    <div
        nz-row
        class="payment-block__row">
        <div
            nz-col
            nzXs="12">
            <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
    </div>
</ng-template>
