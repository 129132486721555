import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BazaWebUtilSharedService, PaymentBankAccountConfig, PaymentButtonStyleEnum, UtilModule } from '@scaliolabs/baza-web-utils';
import { BazaNcBankAccountAchDto } from '@scaliolabs/baza-nc-shared';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
    selector: 'app-payment-bank-account',
    templateUrl: './payment-bank-account.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, NzButtonModule],
})
export class PaymentBankAccountComponent {
    @Input()
    srcData?: BazaNcBankAccountAchDto;

    @Input()
    config?: PaymentBankAccountConfig;

    @Output()
    upsertBank = new EventEmitter<void>();

    i18nBasePath = 'uic.paymentBank';
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    constructor(public readonly wts: BazaWebUtilSharedService) {}
}
