import { BazaMailTemplate } from '../baza-mail-template';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';

export class BazaMailCustomerioLinkDto {
    @ApiModelProperty({
        description: 'Mail Template Definition and Documentation',
    })
    template: BazaMailTemplate;

    @ApiModelProperty({
        description: 'Transactional Message Customer.Io Id which is currently set & used (Displayed at "Transactional" section)',
        required: false,
        example: 1,
    })
    customerIoId?: number;

    @ApiModelProperty({
        description: 'Transactional Message Customer.IO Id which is defined in code',
        required: false,
        example: 1,
    })
    customerIoHardcodedId?: number;
}
